import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DynamicFormControlModel, DynamicFormService, DynamicInputModel } from '@ng-dynamic-forms/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslatingBase } from '../../base-component/ComponentBase';


// path2D fix
interface Path2DConstructor {
  prototype: Path2D;

  new(): Path2D;
  new(d: string): Path2D;
  new(path: Path2D): Path2D;
}
declare var Path2D: Path2DConstructor;

@Component({
  selector: 'app-rear-udsparing',
  templateUrl: './rear-udsparing.component.html',
  styleUrls: ['./rear-udsparing.component.css']
})
export class RearUdsparingComponent extends TranslatingBase implements OnInit {

  @Input() defaults: any;

  @ViewChild('udspCanvas') canvasRef: ElementRef;

  minABFG = 70;

  maxWidthCoordinate = 800;

  measuresForm: DynamicFormControlModel[] = [
    new DynamicInputModel({
      id: 'ab',
      value: this.minABFG,
      prefix: 'AB',
      disabled: true,
      inputType: 'number'
    }),
    new DynamicInputModel({
      id: 'bc',
      prefix: 'BC',
      min: 0,
      inputType: 'number'
    }),
    new DynamicInputModel({
      id: 'cd',
      prefix: 'CD',
      min: 10,
      inputType: 'number'
    }),
    new DynamicInputModel({
      id: 'de',
      prefix: 'DE',
      min: 10,
      inputType: 'number'
    }),
    new DynamicInputModel({
      id: 'fg',
      prefix: 'FG',
      min: 0,
      inputType: 'number'
    }),
    new DynamicInputModel({
      id: 'gh',
      value: this.minABFG,
      disabled: true,
      prefix: 'GH',
      inputType: 'number'
    })
  ];

  formGroup: UntypedFormGroup;
  rootValues: any = {};
  outOfBounds = false;
  udsparing: any = {};

  constructor(private formService: DynamicFormService, translateService: TranslateService) {
    super(translateService);
  }

  ngOnInit() {
    this.formGroup = this.formService.createFormGroup(this.measuresForm);
  }

  udsparingInit() {

    if (this.defaults.existing) {
      // Check at mål går op
      const widthDiff = this.defaults['width'] - (this.minABFG * 2) - this.defaults['bc'] - this.defaults['de'] - this.defaults['fg'];
      if (widthDiff > 0) {
        this.defaults['de'] = this.defaults['de'] + widthDiff;
      }
    } else {
      // beregn DE
      this.defaults['de'] = this.defaults['width'] - (this.minABFG * 2) - 20;
      this.defaults['bc'] = 10;
      this.defaults['fg'] = 10;


      // beregn bc_fg
      const bcfg = (this.defaults['width'] - this.defaults['de'] - this.defaults['ab'] - this.defaults['gh']) / 2;
      this.defaults['bc'] = Math.ceil(bcfg);
      this.defaults['fg'] = Math.floor(bcfg);

    }
    this.measuresForm.forEach(ctrl => {
      if (ctrl.id === 'ab' || ctrl.id === 'gh') {
        this.defaults[ctrl.id] = this.minABFG;
      }
      (ctrl as DynamicInputModel).value = this.defaults[ctrl.id];
    });

    this.rootValues = this.defaults;
    this.drawUdsparing();
  }

  drawUdsparing() {
    this.outOfBounds = (this.rootValues['de'] !== 200 || this.rootValues['bc'] !== 150);

    const ctx: CanvasRenderingContext2D =
      this.canvasRef.nativeElement.getContext('2d');
    ctx.textBaseline = 'bottom';
    ctx.textAlign = 'center';

    // hent værdier fra controls
    const values = this.rootValues;

    // offset values
    const offsetX = 200;
    const offsetY = 30;

    ctx.lineWidth = 2;
    const outerPath = new Path2D('M' + offsetX + ' ' + offsetY +
      ' h ' + '90' +
      ' C ' + '290 70 290 70 320 70' +
      ' h ' + '30' +
      ' l ' + '-33.33 100' +
      ' h ' + '150' +
      ' l ' + '33.33 -100' +
      ' h ' + '30' +
      ' C ' + '560 70 560 70 560 30' +
      ' h ' + '90' +
      ' l ' + '-100 300' +
      ' h ' + '-450' +
      ' l ' + '100 -300' +
      ' M ' + offsetX + ' ' + offsetY +
      ' v ' + '70' +
      ' h ' + '150 ' +
      ' v ' + '-30' +
      ' v ' + '29.5' +
      ' l ' + '-33 99.5' +
      ' v ' + '-29.5' +
      ' v ' + '29.5' +
      ' m ' + '0 0.5' +
      ' h ' + '150' +
      ' v ' + '-30' +
      ' v ' + '30' +
      ' m ' + '0 -0.5' +
      ' l ' + '33.33 -100' +
      ' v ' + '-30' +
      ' v ' + '30' +
      ' h ' + '150' +
      ' v ' + '-70' +
      ' v ' + '70' +
      ' l ' + '-100 300' +
      ' v ' + '-70' +
      ' v ' + '70' +
      ' h ' + '-450' +
      ' v ' + '-70' +
      ' v ' + '70' +
      ' l ' + '100 -300');

    // w 700 h 700
    // clear canvas
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, 700, 700);
    ctx.fillStyle = 'black';

    ctx.strokeStyle = '#939393';
    ctx.stroke(outerPath);

    // tilføj tekst til tegningen
    ctx.fillStyle = 'black';
    ctx.font = '18px Arial';
    ctx.textBaseline = 'alphabetic';
    ctx.fillText('A', offsetX, offsetY - 9);
    ctx.fillText('B', offsetX + 90, offsetY - 9);
    ctx.fillText('C', 350, offsetY + 30);
    ctx.fillText('D', 310, offsetY + 130);
    ctx.fillText('E', 460, offsetY + 130);
    ctx.fillText('F', 500, offsetY + 30);
    ctx.fillText('G', 560, offsetY - 9);
    ctx.fillText('H', 650, offsetY - 9);

    // Streger og værdier
    const textOffset = 10;
    const lineOffset = 12;
    ctx.font = '13px Arial';
    ctx.beginPath();
    ctx.textBaseline = 'bottom';
    ctx.textAlign = 'center';
    ctx.fillText(values.ab, offsetX + textOffset + (values.ab) / 2, offsetY - lineOffset);
    ctx.moveTo(offsetX + 90 + textOffset, offsetY - 3);
    ctx.lineTo(offsetX + 90 + textOffset, offsetY + -15);
    ctx.moveTo(offsetX + 90 + textOffset, offsetY - 9);
    ctx.lineTo(350 - textOffset, offsetY - 9);
    ctx.moveTo(350 - textOffset, offsetY - 3);
    ctx.lineTo(350 - textOffset, offsetY + -15);
    ctx.fillText(values.bc, offsetX + 90 + (60) / 2, offsetY - lineOffset);
    ctx.fillText(values.cd, offsetX + 90 + (60) / 2, offsetY + 30 + 60);
    ctx.fillText(values.de, 310 + 75, offsetY + 130);
    ctx.moveTo(500 + textOffset, offsetY - 3);
    ctx.lineTo(500 + textOffset, offsetY + -15);
    ctx.moveTo(500 + textOffset, offsetY - 9);
    ctx.lineTo(560 - textOffset, offsetY - 9);
    ctx.moveTo(560 - textOffset, offsetY - 3);
    ctx.lineTo(560 - textOffset, offsetY + -15);
    ctx.fillText(values.fg, 500 + 30, offsetY - lineOffset);
    ctx.fillText(values.gh, 560 + 45, offsetY - lineOffset);
    ctx.closePath();
    ctx.stroke();
  }

  changeHandler(event) {

    const bcCtrl = this.measuresForm.filter((e) => {
      return e.id === 'bc';
    })[0] as DynamicInputModel;
    const fgCtrl = this.measuresForm.filter((e) => {
      return e.id === 'fg';
    })[0] as DynamicInputModel;
    const deCtrl = this.measuresForm.filter((e) => {
      return e.id === 'de';
    })[0] as DynamicInputModel;

    let bc, fg;
    // regn mål ud
    if (event.model.id === 'de') {
      const base = parseInt(this.defaults['width'], 10) - event.control.value - this.defaults['ab'] - this.defaults['gh'];
      bc = Math.ceil(base / 2);
      fg = Math.floor(base / 2);

      if (bc >= 0 && fg >= 0) {
        bcCtrl.value = bc;
        fgCtrl.value = fg;
      } else {
        (event.model as DynamicInputModel).value = parseInt(this.defaults['width'], 10) - (this.minABFG * 2);
        bcCtrl.value = 0;
        fgCtrl.value = 0;
      }
    }

    if (event.model.id === 'bc') {
      const newfg = this.defaults['width'] - event.control.value - parseInt(deCtrl.value + '', 10) - (this.minABFG * 2);
      if (newfg >= 0) {
        fgCtrl.value = newfg;
      } else {
        fgCtrl.value = 0;
        (event.model as DynamicInputModel).value = this.defaults['width'] - parseInt(fgCtrl.value + '', 10) - parseInt(deCtrl.value + '', 10) - (this.minABFG * 2);
      }
    }

    if (event.model.id === 'fg') {
      const newbc = this.defaults['width'] - event.control.value - parseInt(deCtrl.value + '', 10) - (this.minABFG * 2);
      if (newbc >= 0) {
        bcCtrl.value = newbc;
      } else {
        bcCtrl.value = 0;
        (event.model as DynamicInputModel).value = this.defaults['width'] - parseInt(bcCtrl.value + '', 10) - parseInt(deCtrl.value + '', 10) - (this.minABFG * 2);
      }
    }

    this.measuresForm.forEach(ctrl => {
      const c = (ctrl as DynamicInputModel);
      this.rootValues[c.id] = parseInt(c.value + '', 10);
    });

    this.drawUdsparing();
  }

  public getUdsparing() {
    const canvasElm: HTMLCanvasElement = this.canvasRef.nativeElement;

    const croppedCanvas = RearUdsparingComponent.cropCanvas(canvasElm, 0, 0, this.maxWidthCoordinate, canvasElm.height);
    console.log('crop coords', 0, 0, this.maxWidthCoordinate, canvasElm.height);
    return {
      ab: this.rootValues['ab'],
      bc: this.rootValues['bc'],
      cd: this.rootValues['cd'],
      de: this.rootValues['de'],
      fg: this.rootValues['fg'],
      gh: this.rootValues['gh'],
      image: croppedCanvas.toDataURL(),
      special: this.outOfBounds
    };
  }

  private static cropCanvas(sourceCanvas, left, top, width, height) {
    const destCanvas = document.createElement('canvas');
    destCanvas.width = width;
    destCanvas.height = height;
    destCanvas.getContext('2d').drawImage(
      sourceCanvas,
      left, top, width, height,  // source rect with content to crop
      0, 0, width, height);      // newCanvas, same size as source rect
    return destCanvas;
  }

}
