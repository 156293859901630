import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { faGlobe } from '@fortawesome/free-solid-svg-icons/faGlobe';

import { AdminProductsLanguageService } from '../admin/admin-products/admin-products-language.service';
import { Locale, LocaleService } from '../services';


@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.css']
})
export class LanguageSelectorComponent implements OnInit {
  @Input() compact = false;

  faGlobe = faGlobe;

  languages: Locale[];

  constructor(
      public localeService: LocaleService,
      private translateService: TranslateService,
      private adminProductsLanguageService: AdminProductsLanguageService
  ) {
  }

  ngOnInit() {
    this.languages = this.localeService.getSupportedLanguages();
  }

  setLanguage(localeId: string) {
    this.localeService.setLanguage(localeId);
    this.translateService.use(localeId);

    this.adminProductsLanguageService.currentLocale = this.localeService.getLanguageById(localeId);
  }
}
