<div class="container">
    <div class="row mb-3">
        <div class="col-sm">
            <div class="text-center">
                <img alt="Unit-Partner Shop" class="up-logo-img" src="assets/images/UniPartner_Shop-Logo.png">
            </div>
            <h3 class="text-center">Opret Bruger</h3>
        </div>
    </div>
    <div class="row">
        <div class="col-sm">
            <div *blockUI="'blockCVRBtn'"
                 class="card">
                <div class="card-body">
                    <form>
                        <div class="row mb-3">
                            <div class="col-md-3">
                                <label class="form-label" for="userEmail">Email</label>
                                <input [(ngModel)]="formData.email" class="form-control" id="userEmail" name="email" placeholder="Email" type="email">
                            </div>
                            <div class="col-md-3">
                                <label class="form-label" for="userEmail">Navn</label>
                                <input [(ngModel)]="formData.name" class="form-control" id="userName" name="name" placeholder="Navn" type="text">
                            </div>
                            <div class="col-md-3">
                                <label class="form-label" for="pw">Password</label>
                                <input [(ngModel)]="formData.password" class="form-control" id="pw" name="pw" placeholder="Password" type="password">
                            </div>
                            <div class="col-md-3">
                                <label class="form-label" for="pw2">Gentag Password</label>
                                <input [(ngModel)]="formData.repeatPW" class="form-control" id="pw2" name="pw2" placeholder="Gentag Password"
                                       type="password">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="form-check">
                                <input [(ngModel)]="termsAccepted" class="form-check-input" id="acceptTerms" name="termsAccepted" type="checkbox"
                                       value="">
                                <label class="form-check-label" for="acceptTerms">
                                    Jeg accepterer <a (click)="modalRef = modalService.show(termsModal)" href="javascript:void(0)">handelsbetingelserne</a>
                                </label>
                            </div>
                        </div>
                        <div class="row">
                            <div (click)="confirmFields()" class="btn btn-primary float-end">Færdiggør oprettelse
                                <fa-icon [icon]="faCheck"></fa-icon>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #confirmModal>
    <div class="modal-header">
        <h4 class="modal-title">Opret bruger</h4>
        <button (click)="modalRef.hide()" aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
    </div>
    <div class="modal-body">
        <p>Efter oprettelsen er brugere aktiveret med det samme og det vil være muligt at logge ind på Unit-Partner Shop.<br><br>
            God fornøjelse.<br><br>
            Med venlig hilsen<br>
            Unit-Partner ApS</p>
    </div>
    <div class="modal-footer">
        <div class="modal-footer">
            <button (click)="createUser();"
                    class="btn btn-success"
                    type="button">Opret bruger
            </button>
            <button (click)="modalRef.hide()"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                    type="button">Annullér
            </button>
        </div>
    </div>
</ng-template>

<ng-template #termsModal>
    <div class="modal-header">
        <h4 class="modal-title">Betingelser og vilkår</h4>
        <button (click)="modalRef.hide()" aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
    </div>
    <div class="modal-body">

        <h3>Cookie- og privatlivspolitik</h3>
        <h5>Introduktion</h5>
        <p>Når du besøger vores website indsamles der oplysninger om dig, som bruges til at tilpasse og forbedre vores
            indhold og til at øge værdien af de annoncer, der vises på siden. Hvis du ikke ønsker, at der indsamles
            oplysninger, bør du slette dine cookies (se vejledning) og undlade videre brug af websitet. Nedenfor har vi
            uddybet, hvilke informationer der indsamles, deres formål og hvilke tredjeparter, der har adgang til dem.</p>
        <h5>Cookies</h5>
        <p>Websitet anvender ”cookies”, der er en tekstfil, som gemmes på din computer, mobil el. tilsvarende med det formål
            at genkende den, huske indstillinger, udføre statistik og målrette annoncer. Cookies kan ikke indeholde skadelig
            kode som f.eks. virus.</p>
        <p>Det er muligt at slette eller blokere for cookies. Se vejledning: https://minecookies.org/cookiehandtering/</p>
        <p>Hvis du sletter eller blokerer cookies vil annoncer kunne blive mindre relevante for dig og optræde hyppigere. Du
            kan desuden risikere at websitet ikke fungerer optimalt samt at der er indhold, du ikke kan få adgang til.</p>

        <h3>Personoplysninger</h3>

        <h5>Generelt</h5>
        <p>Personoplysninger er alle slags informationer, der i et eller andet omfang kan henføres til dig. Når du benytter
            vores website indsamler og behandler vi en række sådanne informationer. Det sker f.eks. ved alm. tilgang af
            indhold, hvis du tilmelder dig vores nyhedsbrev, deltager i konkurrencer eller undersøgelser, registrerer dig som
            bruger eller abonnent, øvrig brug af services eller foretager køb via websitet.</p>
        <p>Vi indsamler og behandler typisk følgende typer af oplysninger: Et unikt ID og tekniske oplysninger om din
            computer, tablet eller mobiltelefon, dit IP-nummer, geografisk placering, samt hvilke sider du klikker på
            (interesser). I det omfang du selv giver eksplicit samtykke hertil og selv indtaster informationerne behandles
            desuden: Navn, telefonnummer, e-mail, adresse og betalingsoplysninger. Det vil typisk være i forbindelse med
            oprettelse af login eller ved køb.</p>

        <h5>Sikkerhed</h5>
        <p>Vi har truffet tekniske og organisatoriske foranstaltninger mod, at dine oplysninger hændeligt eller ulovligt
            bliver slettet, offentliggjort, fortabt, forringet eller kommer til uvedkommendes kendskab, misbruges eller i
            øvrigt behandles i strid med lovgivningen.</p>
        <h5>Formål</h5>
        <p>Oplysningerne bruges til at identificere dig som bruger og vise dig de annoncer, som vil have størst
            sandsynlighed for at være relevante for dig, at registrere dine køb og betalinger, samt at kunne levere de
            services, du har efterspurgt, som f.eks. at fremsende et nyhedsbrev. Herudover anvender vi oplysningerne til at
            optimere vores services og indhold.</p>
        <h5>Periode for opbevaring</h5>
        <p>Oplysningerne opbevares i det tidsrum, der er tilladt i henhold til lovgivningen, og vi sletter dem, når de ikke
            længere er nødvendige. Perioden afhænger af karakteren af oplysningen og baggrunden for opbevaring. Det er derfor
            ikke muligt at angive en generel tidsramme for, hvornår informationer slettes.</p>

        <h5>Videregivelse af oplysninger</h5>
        <p>Data om din brug af websitet, hvilke annoncer, du modtager og evt. klikker på, geografisk placering, køn og
            alderssegment m.v. videregives til tredjeparter i det omfang disse oplysninger er kendt. Du kan se hvilke
            tredjeparter, der er tale om, i afsnittet om ”Cookies” ovenfor. Oplysningerne anvendes til målretning af
            annoncering.</p>

        <p>Vi benytter herudover en række tredjeparter til opbevaring og behandling af data. Disse behandler udelukkende
            oplysninger på vores vegne og må ikke anvende dem til egne formål.</p>

        <p>Videregivelse af personoplysninger som navn og e-mail m.v. vil kun ske, hvis du giver samtykke til det. Vi
            anvender kun databehandlere i EU eller i lande, der kan give dine oplysninger en tilstrækkelig beskyttelse.</p>

        <h5>Indsigt og klager</h5>
        <p>Du har ret til at få oplyst, hvilke personoplysninger, vi behandler om dig. Du kan desuden til enhver tid gøre
            indsigelse mod, at oplysninger anvendes. Du kan også tilbagekalde dit samtykke til, at der bliver behandlet
            oplysninger om dig. Hvis de oplysninger, der behandles om dig, er forkerte har du ret til at de bliver rettet
            eller slettet. Henvendelse herom kan ske til: [ xxxxx@xxxxxx.dk ]. Hvis du vil klage over vores behandling af dine
            personoplysninger, har du også mulighed for at tage kontakt til Datatilsynet.</p>

        <h5>Udgiver</h5>
        <p>Websitet ejes og publiceres af:<br>
            <br>
            Unit-Partner ApS<br>
            Viengevej 6A<br>
            DK-8240 Risskov<br>
            Telefon: +45 89 37 77 77<br>
            Email: kundeservice@unitpartner.dk
        </p>
    </div>
</ng-template>
