import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { LocaleService } from '../../services';


@Component({
  selector: 'app-info-editor-locale',
  templateUrl: './info-editor-locale.component.html',
  styleUrls: ['./info-editor-locale.component.css']
})
export class InfoEditorLocaleComponent implements OnInit {
  @Input('group') group: UntypedFormGroup;

  displayLanguage: string;

  constructor(
      private localeService: LocaleService
  ) {
  }

  ngOnInit() {
    this.displayLanguage = this.localeService.getLanguageById(this.group.value.lang).name;
  }

}
