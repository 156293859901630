// Determines whether all predicates are true
import { AbstractControl } from '@angular/forms';

const toArray = <T>(v: T | T[]) => Array.isArray(v) ? v : [v];
type IsTrueOperators = 'contains' | 'startsWith' | 'equals' | '!contains' | '!startsWith' | '!equals';
type IsTruePredicate = [any, IsTrueOperators, string | string[], 'matchAny' | 'matchAll'] | [any, IsTrueOperators, string | string[]];

export const isTrue = (...predicates: IsTruePredicate[]): boolean => {
  for (const predicate of predicates) {
    const value = (predicate[0] || '').toUpperCase();
    const matchValues = toArray(predicate[2]);
    let singleMatcher: (v: string) => boolean;

    let operator = predicate[1];
    let negate = false;
    if (operator && operator.startsWith('!')) {
      operator = operator.substring(1) as IsTrueOperators;
      negate = true;
    }

    switch (operator) {
      case 'contains':
        singleMatcher = v => value.indexOf(v.toUpperCase()) > -1;
        break;

      case 'startsWith':
        singleMatcher = v => value.indexOf(v.toUpperCase()) === 0;
        break;

      case 'equals':
        singleMatcher = v => value === v.toUpperCase();
        break;

      default:
        throw new Error(`Unsupported operator '${predicate[1]}'.`);
    }

    if (negate) {
      const oldSingleMatcher = singleMatcher;
      singleMatcher = v => !oldSingleMatcher(v);
    }

    let result: boolean;
    if (predicate[3] === 'matchAll')
      result = matchValues.every(v => singleMatcher(v || ''));
    else
      result = matchValues.some(v => singleMatcher(v || ''));

    if (!result)
      return false;
  }

  return true;
};

export const coalesce = <T>(...values: T[]) => values.filter(v => v !== undefined && v !== null)[0] || undefined;

export const toNumber: (v: any) => number | undefined = v => {
  if (v === null || v === undefined)
    return undefined;

  const n = Number(v);
  if (isNaN(n) || !isFinite(n))
    return undefined;

  return n;
};

export const enableControl = (control: AbstractControl) => {
  if (!control.enabled)
    control.enable();
};

export const disableControl = (control: AbstractControl) => {
  if (!control.disabled)
    control.disable();
};

export const setValue = (control: AbstractControl, value: any) => {
  if (control && control.value !== value)
    control.setValue(value === undefined ? null : value);
};
