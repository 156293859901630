import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { LocaleService } from '../../../../services';

@Component({
  selector: 'app-admin-new-component-locale',
  templateUrl: './admin-new-component-locale.component.html',
  styleUrls: ['./admin-new-component-locale.component.css']
})
export class AdminNewComponentLocaleComponent implements OnInit {

  @Input() group: UntypedFormGroup;
  @Input() canBeDeleted: boolean;

  @Output() delete = new EventEmitter();

  displayLanguage: string;

  constructor(private localeService: LocaleService) { }

  ngOnInit() {
    this.displayLanguage = this.localeService.getLanguageById(this.group.value.lang).name;
  }

  deleteLocale() {
    this.delete.emit(null);
  }

}
