import { Component, HostBinding, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FieldComponentBase } from '../FieldComponentBase';


@Component({
  selector: 'app-select',
  template: `
      <div class="mb-3">
          <label *ngIf="field.label" [for]="field.name" class="form-label">{{ translate('fieldLabel', field.label) }}</label>
          <div [formGroup]="group" class="input-group">
              <div *ngIf="field.prefix" class="input-group-text">{{ translate('prefix', field.prefix)}}</div>
              <select [id]="field.name" [formControlName]="field.name" [ngClass]="{'is-invalid': control.errors && control.dirty }" class="form-select">
                  <option [ngValue]="null" disabled selected>{{ 'Vælg' | translate }} {{ translate('fieldLabel', field.label) }}...</option>
                  <option *ngFor="let item of field.options" [ngValue]="item.value">{{ translate('itemLabel', item.label) }}</option>
              </select>
              <div *ngIf="field.suffix" class="input-group-text">{{ translate('suffix', field.suffix) }}</div>
              <ng-container *ngFor="let validation of field.validations;" ngProjectAs="div">
                  <div [class.d-block]="group.get(field.name).touched && group.get(field.name).hasError(validation.name)" class="invalid-feedback w-100">
                      {{ translate('validations', validation.message) }}
                  </div>
              </ng-container>
          </div>
          <div *ngIf="control.enabled">
              <small *ngIf="field.helptext" [ngClass]="field.helptextClass" class="form-text text-muted">{{ translate('helptext', field.helptext) }}</small>
              <small *ngIf="field.helpHTML" [innerHTML]="field.helpHTML" [ngClass]="field.helptextClass" class="form-text text-muted"></small>
          </div>
      </div>
  `,
  styles: []
})
export class SelectComponent extends FieldComponentBase implements OnInit {

  @HostBinding('class') class;

  control: UntypedFormControl;

  constructor(translateService: TranslateService) {
    super(translateService);
  }

  ngOnInit() {
    this.class = this.field.classes || 'col-sm';
    this.control = this.group.get(this.field.name) as UntypedFormControl;
  }

}
