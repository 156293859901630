<div class="container" *ngIf="productsPerCategory">
    <div class="row mb-3">

        <div class="col">
            <app-breadcrumbs [currentBreadcrumb]="adminAllProductsBreadcrumb"></app-breadcrumbs>
        </div>

    </div>

    <ng-container *ngFor="let products of productsPerCategory">
        <div class="row mt-4">
            <div class="col-12 fw-bold">
                <app-product-path [categoryId]="products[0].categoryId"></app-product-path>
            </div>
            <div class="col fw-bold">Varenummer</div>
            <div class="col-4 fw-bold">Name (DA)</div>
            <div class="col fw-bold text-end">Pris (DA)</div>
            <div class="col fw-bold">Samlet leveringsstr.</div>
            <div class="col-1 fw-bold">2x palle?</div>
            <div class="col-1 fw-bold">Handling</div>
        </div>
        <div *ngFor="let product of products; let i = index" [class.bg-light]="i % 2 === 0" class="row">
            <div [innerText]="product.itemNumber" class="col small"></div>
            <div class="col-4">
                <ng-container *ngIf="product.name">
                    {{ product.name }}
                </ng-container>
                <div *ngIf="!product.name" class="fst-italic text-danger">
                    Ukendt
                </div>
            </div>
            <div class="col text-end">
                <ng-container *ngIf="product.price">
                    {{ product.price | number: '1.2-2' }} DKK
                </ng-container>
                <div *ngIf="!product.price" class="fst-italic text-danger">
                    Ukendt
                </div>
            </div>
            <div [ngSwitch]="product.oneDeliverySize" class="col">
                <div *ngSwitchCase="0" class="text-warning">0 = smådel</div>
                <ng-container *ngSwitchCase="255">Hel palle</ng-container>
                <ng-container *ngSwitchDefault>
                    {{ product.oneDeliverySize }}/{{ (freightSetting.oneDelivery.palletCapacity * (product.oneDeliveryDoublePallet ? 2 : 1)) }}
                </ng-container>
            </div>
            <div class="col-1">
                <ng-container *ngIf="product.oneDeliveryDoublePallet">Ja</ng-container>
                <ng-container *ngIf="!product.oneDeliveryDoublePallet">-</ng-container>
            </div>
            <div class="col-1">
                <button [routerLink]="'/admin/' + productsUrl + '/edit/' + product.id" class="btn btn-sm btn-primary my-2">Ret</button>
            </div>
        </div>
    </ng-container>
</div>
