import { Injectable } from '@angular/core';
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import { APIService } from './api.service';


@Injectable({
  providedIn: 'root'
})
export class ReportToApiMIssingTranslationHandler implements MissingTranslationHandler {

  private _alreadySeenKeyLanguageCombinations: any = {};

  public constructor(private _apiService: APIService) { }

  public handle(params: MissingTranslationHandlerParams) {

    if (environment.production)
      return;

    const language = params.translateService.currentLang;
    const key = params.key;

    if (this._alreadySeenKeyLanguageCombinations[`${language}|${key}`])
      return;

    console.log(`Missing localization of '${key}' in '${language}'`);

    this._apiService
      .addMissingTranslation(language, key)
      .subscribe({ error: (err: any) => console.error('Exception reporting missing translation: ', err) });

    this._alreadySeenKeyLanguageCombinations[`${language}|${key}`] = true;

  }

}
