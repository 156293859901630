<div class="container">
    <div class="row mb-3">

        <div class="col-12">
            <app-breadcrumbs [currentBreadcrumb]="careSetBreadcrumb"></app-breadcrumbs>
        </div>

    </div>
    <div class="row">
        <div class="col-4">
            <app-dynamic-form (change)="handleFormChanged()" [autoFocusFirst]="true" [rows]="model"></app-dynamic-form>
            <div class="text-end">
                <app-amount-step (approved)="amountApproved($event)" [(amount)]="amount" [disable]="formIsInvalid"></app-amount-step>
            </div>
        </div>
        <div *ngFor="let careSet of productTypes" class="col-4">
            <div (click)="setType(careSet[OptionProperty.articleNumber])" [class.border-3]="isActive(careSet[OptionProperty.articleNumber])"
                 [class.border-success]="isActive(careSet[OptionProperty.articleNumber])" class="card">
                <img [alt]="careSetBreadcrumb.nameId | translate" class="card-img-top" src="/assets/images/others/careSet.png"/>
                <div class="card-body">
                    <h5 [translate]="OtherProductShortName.careSet + '.' + careSet[OptionProperty.articleNumber]"></h5>
                    <div [innerText]="OtherProductShortName.careSet + '.' + careSet[OptionProperty.articleNumber] + 'description' | translate"></div>
                </div>
            </div>
        </div>

    </div>
</div>
