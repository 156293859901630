import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons/faWindowClose';

import { APIService, ImageService, Locale, LocaleService } from '../../services';
import { TranslatingBase } from '../../base-component/ComponentBase';


@Component({
  selector: 'app-admin-category',
  templateUrl: './admin-category.component.html',
  styleUrls: ['./admin-category.component.css']
})
export class AdminCategoryComponent extends TranslatingBase implements OnInit {
  faCheck = faCheck;
  faWindowClose = faWindowClose;

  unsupportedLocales: Locale[] = [];

  categoryForm: UntypedFormGroup;

  categoryId: number;

  imgUrl;
  imgFile: File;

  constructor(
      private _fb: UntypedFormBuilder,
      private toastrService: ToastrService,
      private api: APIService,
      private translateService: TranslateService,
      private route: ActivatedRoute,
      private localeService: LocaleService,
      private imageService: ImageService,
  ) {
    super(translateService);
  }

  async ngOnInit() {
    this.categoryForm = this._fb.group({
      id: [null],
      order: [null],
      locale: this._fb.array([])
    });

    this.unsupportedLocales = this.localeService.getSupportedLanguages();

    this.categoryId = parseInt(this.route.snapshot.paramMap.get('categoryId'), 10);
    await this.loadCategory(this.categoryId);
  }

  initCategoryFormLocales(localeId: string, name = '', description = '', freightRate: number = null, id?: number) {
    return this._fb.group({
      id: [id],
      lang: [localeId],
      name: [name, [Validators.required]],
      description: [description],
      freightRate: [freightRate]
    });
  }

  addLocale(locale: Locale | string, name?: string, description?: string, freightRate?: number, id?: number) {
    const localeId = typeof locale === 'string' ? locale : locale.id;

    const localesControl = <UntypedFormArray>this.categoryForm.controls['locale'];
    localesControl.push(this.initCategoryFormLocales(localeId, name, description, freightRate, id));

    this.unsupportedLocales.splice(this.unsupportedLocales.findIndex(l => l.id === localeId), 1);
  }

  deleteLocale(locale: UntypedFormGroup) {
    const localesControl = <UntypedFormArray>this.categoryForm.controls['locale'];
    localesControl.controls.splice(localesControl.controls.findIndex(l => l.value === locale.value), 1);
    this.categoryForm.value.locale.splice(this.categoryForm.value.locale.findIndex(l => l.lang === locale.value.lang), 1);

    const removedLocale = this.localeService.getLanguageById(locale.value.lang);
    this.unsupportedLocales.push(removedLocale);
  }

  showValidationMsg(formGroup: UntypedFormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: UntypedFormControl = <UntypedFormControl>formGroup.controls[key];

        if (Object.keys(control).includes('controls')) {
          const formGroupChild: UntypedFormGroup = <UntypedFormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }

        control.markAsTouched();
      }
    }
  }

  async updateCategory() {
    if (!this.categoryForm.valid) {
      this.showValidationMsg(this.categoryForm);
      return;
    }

    // Save category and clear category form
    await this.api.saveCategory(this.categoryForm.value);
    await this.api.saveCategoryImage(this.imgFile, this.categoryId);

    await this.toastrService.success(this.translate('Category_edited'), null, { timeOut: 10000 });
  }

  async onFileChosen(event: Event): Promise<void> {
    const { imgFile, imgUrl } = await this.imageService.onFileChosen(event);
    if (imgFile !== null && imgUrl !== null) {
      this.imgFile = imgFile;
      this.imgUrl = imgUrl;
    }
  }

  private async loadCategory(categoryId: number) {
    const category = await this.api.getCategoryForEditing(categoryId);

    this.categoryForm.controls.id.setValue(category.id);
    this.categoryForm.controls.order.setValue(category.order);
    category.locale.forEach(locale => {
      this.addLocale(locale.lang, locale.name, locale.description, locale.freightRate, locale.id);
    });

    const image = await this.api.getCategoryImage(categoryId);
    this.imgUrl = image.encImg;
    this.imgFile = await this.imageService.urlToFile(this.imgUrl, 'categoryimage');
  }

}
