<div class="card mb-2">
    <div *ngIf="optionsForm" class="card-body">
        <form [formGroup]="optionsForm" novalidate>
            <div class="row mb-3">

                <div [class.col-md-3]="!_gripEnabled" [class.col-md-2]="_gripEnabled">
                    <label class="form-label" for="width">{{'Bredde' | translate}}</label>
                    <div class="input-group">
                        <input class="form-control text-end" [formControlName]="OptionProperty.width" id="width" placeholder="" type="number">
                        <span class="input-group-text">mm</span>
                    </div>
                    <div *ngIf="width.invalid && (width.dirty || width.touched)" class="text-danger small">
                        <div *ngIf="width.errors?.['required']"
                             [translateParams]="{fieldName: 'Bredde' | translate}"
                             [translate]="'Validate.required.errorText'"></div>
                        <div *ngIf="width.errors?.['pattern']"
                             [translateParams]="{fieldName: 'Bredde' | translate}"
                             [translate]="'Validate.pattern.integer'"></div>
                        <div *ngIf="width.errors?.['min']"
                             [translateParams]="{fieldName: 'Bredde' | translate, minValue: this.minimumSize, unit: 'mm'}"
                             [translate]="'Validate.min.errorText'"></div>
                        <div *ngIf="width.errors?.['max']"
                             [translateParams]="{fieldName: 'Bredde' | translate, maxValue: Constants.purewoodFrontMaxSize, unit: 'mm'}"
                             [translate]="'Validate.max.errorText'"></div>
                    </div>
                </div>

                <div [class.col-md-3]="!_gripEnabled" [class.col-md-2]="_gripEnabled">
                    <label class="form-label" for="height">{{'Højde' | translate}}</label>
                    <div class="input-group">
                        <input class="form-control text-end" [formControlName]="OptionProperty.height" id="height" placeholder="" type="number">
                        <span class="input-group-text">mm</span>
                    </div>
                    <div *ngIf="height.invalid && (height.dirty || height.touched)" class="text-danger small">
                        <div *ngIf="height.errors?.['required']"
                             [translateParams]="{fieldName: 'Højde' | translate}"
                             [translate]="'Validate.required.errorText'"></div>
                        <div *ngIf="height.errors?.['pattern']"
                             [translateParams]="{fieldName: 'Højde' | translate}"
                             [translate]="'Validate.pattern.integer'"></div>
                        <div *ngIf="height.errors?.['min']"
                             [translateParams]="{fieldName: 'Højde' | translate, minValue: this.minimumSize, unit: 'mm'}"
                             [translate]="'Validate.min.errorText'"></div>
                        <div *ngIf="height.errors?.['max']"
                             [translateParams]="{fieldName: 'Højde' | translate, maxValue: Constants.purewoodFrontMaxSize, unit: 'mm'}"
                             [translate]="'Validate.max.errorText'"></div>
                    </div>
                </div>

                <div [class.col-md-3]="!_gripEnabled" [class.col-md-2]="_gripEnabled">
                    <label class="form-label" for="frontDrillType">{{ 'fronts.drilling.label' | translate }}</label>
                    <select [formControlName]="OptionProperty.frontDrillType" class="form-control" id="frontDrillType">
                        <option value="none">-</option>
                        <option *ngFor="let d of frontDrillTypes" value="{{ d.value }}">{{ d.label + '.short' | translate }}</option>
                    </select>
                </div>

                <div class="col-md-3" [class.d-none]="!_gripEnabled">
                    <label class="form-label" for="frontGrip">{{ 'fronts.grip.label' | translate }}</label>
                    <div class="input-group">
                        <select [formControlName]="OptionProperty.frontGrip" class="form-control" id="frontGrip">
                            <option value="none">-</option>
                            <option *ngFor="let g of frontGrips" value="{{ g.value }}">{{ g.label + '.short' | translate }}</option>
                            <option value="custom" *ngIf="customGripEnabled" [innerText]="'Own Design' | translate"></option>
                        </select>
                        <span *ngIf="showX" class="input-group-text" [innerText]="'fronts.grip.withXEqualTo' | translate"></span>
                        <input *ngIf="showX" class="form-control text-end" [formControlName]="OptionProperty.frontXGrip" placeholder=""
                               type="number">
                        <span *ngIf="showX" class="input-group-text">mm</span>
                    </div>
                    <div *ngIf="frontXGrip.invalid && (frontXGrip.dirty || frontXGrip.touched)" class="text-danger small">
                        <div *ngIf="frontXGrip.errors?.['required']"
                             [translateParams]="{fieldName: 'X'}"
                             [translate]="'Validate.required.errorText'"></div>
                        <div *ngIf="frontXGrip.errors?.['min']"
                             [translateParams]="{fieldName: 'X', minValue: getMinimum(), unit: 'mm'}"
                             [translate]="'Validate.min.errorText'"></div>
                        <div *ngIf="frontXGrip.errors?.['max']"
                             [translateParams]="{fieldName: 'X', maxValue: getMaximum(), unit: 'mm'}"
                             [translate]="'Validate.max.errorText'"></div>
                    </div>
                </div>

                <div class="col-md-2">
                    <label class="form-label" for="amount">{{'UTILITY.AMOUNT' | translate}}</label>
                    <div class="input-group">
                        <input class="form-control text-end" formControlName="amount" id="amount" placeholder="" type="number"
                               (keydown)="addRowOnTab($event)">
                    </div>
                    <div *ngIf="amount.invalid && (amount.dirty || amount.touched)" class="text-danger small">
                        <div *ngIf="amount.errors?.['required']"
                             [translateParams]="{fieldName: 'UTILITY.AMOUNT' | translate}"
                             [translate]="'Validate.required.errorText'"></div>
                        <div *ngIf="amount.errors?.['pattern']"
                             [translateParams]="{fieldName: 'UTILITY.AMOUNT' | translate}"
                             [translate]="'Validate.pattern.integer'"></div>
                        <div *ngIf="amount.errors?.['min']"
                             [translateParams]="{fieldName: 'UTILITY.AMOUNT' | translate, minValue: 1, unit: 'pcs' | translate}"
                             [translate]="'Validate.min.errorText'"></div>
                    </div>
                </div>

                <div class="col-md-1">
                    <label class="form-label">&nbsp;</label>
                    <div class="pt-1">
                        <app-remove-button (delete)="deleteOption()" class="pt-2"></app-remove-button>
                    </div>
                </div>

            </div>
        </form>
    </div>
</div>
