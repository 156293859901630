import { TranslateService } from '@ngx-translate/core';

import { PriceCalculatorService } from '../price.calculator.service';
import { CartItem } from './CartItem';
import { ITypedAddCartItem } from '../../../../../wdcommon/ICartItem';
import { ICompanyAdjustments } from '../../../../../wdcommon/ICompany';


export class AdditionalCartItem extends CartItem {

  constructor(
      translateService: TranslateService,
      private priceService: PriceCalculatorService,
      additional: ITypedAddCartItem,
      private companyAdjustmentsProvider: () => Promise<ICompanyAdjustments>
  ) {
    super(translateService, additional);
  }

  public get breakageFeeIncluded(): boolean {
    return this.options.breakageFeeCount && this.amount % this.options.breakageFeeCount !== 0;
  }

  protected async calculatePrice(): Promise<number> {
    const companyAdjustments = await this.companyAdjustmentsProvider();
    const price = await this.priceService.CalculateAdditional(this, companyAdjustments);

    if (typeof (price) === 'number')
      return price;

    console.error(`Unable to calculate price for additional ${this.itemno} got: ${price}.`);
    return 0;
  }

}
