import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { UserService } from '../services';


@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(private router: Router, private userService: UserService) { }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.userService.getUser()) {
      this.router.navigateByUrl('/login').then();
      return Promise.resolve(false);
    }

    const res = await this.userService.checkUserIsValid();
    if (res && this.userService.isAdmin()) {
      return true;
    }
    await this.router.navigateByUrl('/');
    return false;
  }
}
