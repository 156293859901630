<div class="container">
    <div class="row mb-3">

        <div class="col-12">
            <app-breadcrumbs [currentBreadcrumb]="notheggerBreadcrumb"></app-breadcrumbs>
        </div>

    </div>
    <div class="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-5 g-4">
        <ng-container *ngFor="let notheggerRow of notheggerProductRows">

            <div *ngFor="let product of notheggerRow" class="col">

                <div (click)="gotoProduct(product)" *ngIf="product.type !== 'placeholder'" class="card wd-card h-100">
                    <div *ngIf="!product.enabled" class="watermark-text">
                        {{'Products.TemporaryUnavailable' | translate }}
                    </div>
                    <div class="card-header">{{ product.name | translate }}</div>
                    <div class="card-body">
                        <img [src]="product.img" alt="" class="card-img-top">
                        <p *ngIf="product.description" class="card-text">{{ product.description | translate }}</p>
                    </div>
                    <ng-container *ngIf="asAdmin && (product.type === 'drawer' || product.type === 'other')">
                        <div class="card-footer">
                            Tilgængelighed
                            <button [tooltip]="'Gør denne vare ' + product.enabled ? 'utilgængelig' : 'tilgængelig'"
                                    class="btn btn-success btn-sm float-end">
                                <fa-icon (click)="alterAvailability(product)" [icon]="product.enabled ? faCheckSquare : faSquare"></fa-icon>
                            </button>
                        </div>
                    </ng-container>
                </div>

            </div>

        </ng-container>
    </div>
</div>


<ng-template #specialsModal>
    <div class="modal-header">
        <h4 class="modal-title">{{ 'HOME.SPECIALS_TITLE' | translate }}</h4>
        <button (click)="closeSpecialModal()" aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
    </div>
    <div class="modal-body px-4">
        <div class="container">
            <div class="row row-cols-1 row-cols-md-2 row-cols-xl-3 row-cols-xxl-4 g-4">
                <ng-container *ngFor="let specialsRow of specialsProductRows">

                    <div *ngFor="let special of specialsRow" class="col">

                        <div (click)="gotoProduct(special)" *ngIf="special.type !== 'placeholder'" class="card wd-card h-100">
                            <div *ngIf="!special.enabled" class="watermark-text">
                                {{'Products.TemporaryUnavailable' | translate }}
                            </div>
                            <div class="card-header">{{ special.name | translate }}</div>
                            <div class="card-body">
                                <img [src]="special.img" alt="" class="img-fluid">
                                <p class="card-text">{{ special.description | translate }}</p>
                            </div>
                            <ng-container *appAdminViewOnly>
                                <div class="card-footer">
                                    Tilgængelighed
                                    <button [tooltip]="'Gør denne vare ' + (special.enabled ? 'utilgængelig' : 'tilgængelig')"
                                            class="btn btn-success btn-sm float-end">
                                        <fa-icon (click)="alterAvailability(special)" [icon]="special.enabled ? faCheckSquare : faSquare"></fa-icon>
                                    </button>
                                </div>
                            </ng-container>
                        </div>

                        <div *ngIf="special.type === 'placeholder'" class="card border-0"></div>

                    </div>

                </ng-container>
            </div>

        </div>
    </div>
</ng-template>

