<div class="container">
    <div *ngIf="category" class="row mb-3">

        <div class="col-12">
            <app-breadcrumbs [breadcrumbs]="breadcrumbs" [currentBreadcrumb]="breadcrumbs[categoryPrefix + category.id]"></app-breadcrumbs>
        </div>
        <div *ngIf="category.description" [innerHtml]="category.description" class="col-12 col-md-9 col-lg-7 col-xl-6 m-3 alert alert-primary"
             role="alert"></div>

    </div>
    <div *ngIf="subcategories && category" class="row">

        <div *ngFor="let subcategory of subcategories" class="col-12 col-lg-6 col-xl-4 col-xxl-3 mt-3">
            <div [routerLink]="breadcrumbs[categoryPrefix + category.id].url + '/' + subcategory.id" class="card up-card h-100 clickable">
                <img *ngIf="subcategory.imageUrl" [alt]="subcategory.name" [src]="subcategory.imageUrl" class="card-img-top">
                <div class="card-body">
                    <div [innerText]="subcategory.name" class="card-title"></div>
                    <div [innerHTML]="subcategory.description" class="card-text"></div>
                </div>
            </div>
        </div>

    </div>
    <div *ngIf="products && category" class="row">

        <div *ngFor="let product of products" class="col-12 col-lg-6 col-xl-4 mt-3">
            <div [routerLink]="breadcrumbs[categoryPrefix + category.id].url + '/' + productPrefix + product.id"
                 class="card up-card up-card-h h-100 clickable">
                <div class="row g-0">
                    <div class="col-md-8">
                        <div class="card-body">
                            <div class="card-title">
                                <div [innerText]="product.name"></div>
                                <app-item-number [product]="product"></app-item-number>
                            </div>
                            <app-can-purchase-view>
                                <div class="card-text authorized">
                                    <div class="d-flex justify-content-end">
                                        <app-product-price [product]="product" [brandId]="brandId"></app-product-price>
                                        <app-add-to-basket [product]="product" class="ms-3 my-1"></app-add-to-basket>
                                    </div>
                                </div>
                            </app-can-purchase-view>
                        </div>
                    </div>
                    <div class="col-md-4 d-flex align-items-center">
                        <img *ngIf="product.imageUrl" [alt]="product.name" [src]="product.imageUrl" class="card-img">
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div *ngIf="category && subcategories && subcategories.length === 0 && products && products.length === 0"
         [innerText]="'GeneralProducts.NoProducts' | translate" class="mt-3 alert alert-primary" role="alert"></div>

</div>
