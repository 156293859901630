<div class="row py-3 border-bottom">
    <div [ngClass]="discountRow ? 'col-md-4 col-lg-5 col-xl-7' : 'col-md-5 col-lg-6 col-xl-8 d-flex'" class="col-12 d-flex">
        <app-order-layout-image [item]="item" class="me-3"></app-order-layout-image>
        <div class="flex-grow-1">
            <div class="d-flex align-items-start">
                <div class="fw-bold mb-1 me-2 d-inline" style="font-size: 17px">{{ itemName }}</div>
                <small [innerText]="item.itemno" [title]="'UTILITY.ITEM_NO' | translate" class="badge rounded-pill bg-success fw-light"></small>
            </div>
            <div *ngIf="item.description" class="small" [innerText]="item.description"></div>
            <div *ngIf="item.breakageFeeIncluded" [translateParams]="{count: item.options.breakageFeeCount}" [translate]="'OrderItem.BreakageFee'"
                 class="small fst-italic"></div>
            <div *ngIf="item.comments || item.options.comment">
                <small class="fst-italic">
                    <fa-icon [icon]="faComment"></fa-icon>
                    "{{ item.comments || item.options.comment }}"
                </small>
            </div>

            <ng-container *ngIf="item.type === CartItemType.drawer">
                <div [collapse]="shouldBeCollapsed(item.itemno)" class="card-body p-1 border m-3">
                    <table class="table table-sm table-hover">
                        <thead class="bg-secondary text-white">
                        <tr>
                            <th translate="Order.Option"></th>
                            <th translate="UTILITY.CHOICE"></th>
                            <th class="text-end"><span translate="UTILITY.PRICE"></span></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let od of item.orderDetails">
                            <td>{{ od.name }}</td>
                            <td>{{ od.detail }}</td>
                            <td class="text-end">
                                <ng-container *ngIf="od.price !== 0">
                                    {{ od.price | number: '1.2-2' }} {{ 'USED_CURRENCY' | translate }}
                                </ng-container>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </ng-container>
        </div>
    </div>
    <div *ngIf="discountRow" class="col-2 col-md-1 text-end text-nowrap mt-3 mt-md-0">
        {{ (item.discountPercentage ? (item.discountPercentage * 100 | number: '1.1-2') + ' %' : '') }}
    </div>
    <div [ngClass]="discountRow ? 'col-10' : 'col-12'" class="col-md-7 col-lg-6 col-xl-4 mt-3 mt-md-0">
        <div class="row">
            <ng-container *ngIf="isInCart">
                <div class="col-6">
                    <div class="input-group input-group-sm flex-grow-1">
                        <input (change)="updateCount($event)" [min]="item.minAmount" [step]="item.amountStep" [value]="item.amount"
                               class="form-control text-end" type="number">
                        <div class="input-group-text" *ngIf="isUserLoggedIn">&times;
                            <ng-container *ngIf="item.pricePer">
                                {{ item.pricePer | number: '1.2-2'}} {{ 'USED_CURRENCY' | translate }}
                            </ng-container>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!isInCart">
                <div class="col-6 text-end text-nowrap d-flex">
                    <span class="flex-grow-1">{{ item.amount }}x</span>
                    <span *ngIf="item.pricePer && isUserLoggedIn"
                          class="ps-2 text-muted flex-nowrap">{{ item.pricePer | number: '1.2-2'}} {{ 'USED_CURRENCY' | translate }}</span>
                </div>
            </ng-container>
            <ng-container *ngIf="item.priceTotal">
                <div class="col-6 text-end fw-bold text-nowrap">
                    <ng-container *ngIf="isUserLoggedIn">
                        {{ item.priceTotal | number: '1.2-2'}} {{ 'USED_CURRENCY' | translate }}
                    </ng-container>
                    <ng-container *ngIf="!isUserLoggedIn">
                        {{ 'logInToSeePrices' | translate }}
                    </ng-container>
                </div>
            </ng-container>
            <ng-container *ngIf="!item.priceTotal">
                <div class="col-6 text-center fst-italic small">
                    * {{ 'PRICE_WILL_FOLLOW' | translate }} *
                </div>
            </ng-container>
        </div>
        <div class="mt-3 text-end no-print">
            <ng-container *ngIf="item.type === CartItemType.drawer">
                <button (click)="toggleCollapse(item.itemno)" class="btn btn-sm btn-outline-primary ms-2 view-more-button" [ngClass]="shouldBeCollapsed(item.itemno) ? 'collapsed' : 'expanded'" type="button">
                    <ng-container *ngIf="shouldBeCollapsed(item.itemno)">
                        <fa-icon [icon]="faChevronDown"></fa-icon>&nbsp;
                        <span translate="CART.Vis"></span>
                    </ng-container>
                    <ng-container *ngIf="!shouldBeCollapsed(item.itemno)">
                        <fa-icon [icon]="faChevronUp"></fa-icon>&nbsp;
                        <span translate="CART.Skjul"></span>
                    </ng-container>
                </button>
                <button *ngIf="isInCart" [routerLink]="'/order/edit/' + item.itemno" class="btn btn-sm btn-outline-primary ms-2" type="button">
                    <fa-icon [icon]="faEdit"></fa-icon>
                    <span class="ms-1" translate="CART.Ret"></span>
                </button>
                <button (click)="setRepeatDrawer()" class="btn btn-sm btn-outline-primary ms-2" type="button">
                    <fa-icon [icon]="faRedo"></fa-icon>&nbsp;
                    <span translate="CART.Repeat"></span>
                </button>
            </ng-container>
            <ng-container *ngIf="item.type === CartItemType.fronts">
                <button *ngIf="isInCart" [routerLink]="'/' + productsUrl + '/purewood/front-edit/' + item.itemno"
                        class="btn btn-sm btn-outline-primary ms-2" type="button">
                    <fa-icon [icon]="faEdit"></fa-icon>
                    <span class="ms-1" translate="CART.Ret"></span>
                </button>
                <button (click)="setRepeatFront()" class="btn btn-sm btn-outline-primary ms-2" type="button">
                    <fa-icon [icon]="faRedo"></fa-icon>
                    <span class="ms-1" translate="CART.Repeat"></span>
                </button>
            </ng-container>
            <app-remove-button (delete)="deleteCartItem()" *ngIf="isInCart" [disabled]="item.amount <= item.minAmount" [outline]="true" class="ms-2">
                <span class="ms-1" translate="UTILITY.DELETE"></span>
            </app-remove-button>
        </div>
    </div>
</div>
