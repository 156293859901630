import { TranslateService } from '@ngx-translate/core';

import { HettichService, PriceCalculatorService } from '../';
import { Cart } from './CartItem';
import { DrawerCartItem } from './DrawerCartItem';
import { OtherCartItem } from './OtherCartItem';
import { CartItemType } from '../../../../../wdcommon/ICartItem';
import { ICompanyAdjustments } from '../../../../../wdcommon/ICompany';


export class SynchronisationBarAdapterCartItem extends OtherCartItem {
  constructor(
      private ____translateService: TranslateService,
      private __priceService: PriceCalculatorService,
      private __companyAdjustmentsProvider: () => Promise<ICompanyAdjustments>,
      private __hettichService: HettichService,
  ) {
    super(
        ____translateService,
        __priceService,
        __companyAdjustmentsProvider,
        {
          brandId: 'hettich',
          type: CartItemType.hettichRunnerAddOn,
          itemno: '9219966',
          name: ____translateService.instant('CARTDETAILS.SynchronisationBarAdapter'),
          description: ____translateService.instant('tilEgennavn', { egennavn: 'push to open systemer' }),
          amount: 0,
        },
        __hettichService,
        items => this.calcAmountOfSynchronisationBarAdapter(items));
    this.setHettichBrandId();
  }

  private calcAmountOfSynchronisationBarAdapter(items: Cart) {
    let drawersWithSynchronisationBars = 0;
    for (const drawer of items.filter((i) => i.type === CartItemType.drawer)) {
      if (drawer instanceof DrawerCartItem) {
        drawersWithSynchronisationBars += (drawer.needsSynchronisationBarAdapters() ? drawer.amount : 0);
      }
    }

    return drawersWithSynchronisationBars * 2;
  }
}
