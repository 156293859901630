import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UtilitiesService } from '../services';


@Component({
  selector: 'app-login',
  templateUrl: './unsupported-browser.component.html',
  styleUrls: ['./unsupported-browser.component.css']
})
export class UnsupportedBrowserComponent implements OnInit {

  constructor(private router: Router, private utilities: UtilitiesService) {
  }

  ngOnInit() {
    if (!this.utilities.isInternetExplorer(navigator))
      this.router.navigateByUrl('/');
  }

}
