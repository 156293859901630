import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { OptionProperty } from '../../../../../wdcommon/IProduct';
import { OtherProductBaseComponent } from '../other-product-base/other-product-base.component';
import { APIService, CartService, ModelLoaderService } from '../../services';
import { FormRowIds } from '../../dynamicForm/standard-rows';


@Component({
  selector: 'app-veneer-product-base',
  template: `<p>Extend veneer product from this!</p>`
})
export class VeneerProductBaseComponent extends OtherProductBaseComponent {

  constructor(
      private __cartService: CartService,
      private __modelLoader: ModelLoaderService,
      private __toastrService: ToastrService,
      private __api: APIService,
      private __router: Router,
      private ___translateService: TranslateService,
  ) {
    super(__cartService, __modelLoader, __toastrService, __api, __router, ___translateService);
  }

  handleFormChanged() {
    const surfaceControl = this.form.getControl(OptionProperty.surfaceTreatment);
    if (this.isSurfaceTreatmentNeeded()) {
      surfaceControl.enable();
    } else {
      surfaceControl.disable();
    }

    const shelfHolesControl = this.form.getControl(OptionProperty.shelfHoles);
    if (shelfHolesControl) {
      if (parseInt(this.form.getControl(OptionProperty.shelves).value, 10) > 0) {
        shelfHolesControl.enable();
      } else {
        shelfHolesControl.disable();
      }
    }

    super.handleFormChanged();
  }

  protected isSurfaceTreatmentNeeded(): boolean {
    const veneerControlValue = this.form.getControl(OptionProperty.veneer).value;

    for (const row of this.model) {
      if (row.id !== FormRowIds.veneerTreatmentRow) {
        continue;
      }
      for (const field of row.fields || []) {
        if (field.name !== OptionProperty.veneer) {
          continue;
        }

        for (const option of field.options) {
          if (option.value !== veneerControlValue) {
            continue;
          }
          return (option.index < 100);
        }
        break;
      }
      break;
    }
    return false;
  }

}
