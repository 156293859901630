import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';

import { APIService } from '../../services';


@Component({
  selector: 'app-company-user',
  templateUrl: './company-user.component.html',
  styleUrls: ['./company-user.component.css']
})
export class CompanyUserComponent implements OnInit {
  @ViewChild('confirmModal') confirmModal;

  faCheck = faCheck;

  modalRef: BsModalRef;
  confirmHash;
  termsAccepted;
  formData = {
    email: '',
    name: '',
    password: '',
    repeatPW: ''
  };

  constructor(
      public modalService: BsModalService,
      private toastrService: ToastrService,
      private route: ActivatedRoute,
      private api: APIService,
      private router: Router
  ) {
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.confirmHash = params['hash'];
    });
  }


  confirmFields() {
    if (this.formData.email === '') {
      return this.toastrService.error('Der skal indtastes en email!');
    } else if (this.formData.name === '') {
      return this.toastrService.error('Der skal indtastes et navn!');
    } else if (this.formData.password === '') {
      return this.toastrService.error('Der skal indtastes et password!');
    } else if (this.formData.repeatPW === '') {
      return this.toastrService.error('Gentag venligst passwordet!');
    } else if (this.termsAccepted === false) {
      return this.toastrService.error('Handelsbetingelserne skal godkendes!');
    } else if (this.formData.password !== this.formData.repeatPW) {
      return this.toastrService.error('De indtastede passwords er ens!');
    }

    this.modalRef = this.modalService.show(this.confirmModal);
  }

  createUser() {
    this.api.confirmCompanyUser(this.formData.name, this.formData.email, this.formData.password, this.confirmHash)
        .subscribe(async (r: any) => {
          if (r.err) {
            this.toastrService.error('Ugyldig anmodning, hvis dette er en fejl kontakt da din firma-administrator eller kundeservice!');
          } else {
            this.toastrService.success('Brugeren er oprettet');
          }
          await this.router.navigateByUrl('/');
          this.modalRef.hide();
        });
  }

}
