import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { ITypedAddCartItem } from '../../../../../wdcommon/ICartItem';
import { IOption, OptionProperty, OtherProductShortName } from '../../../../../wdcommon/IProduct';
import { DynamicFormComponent } from '../../dynamicForm/dynamic-form/dynamic-form.component';
import { FormRow } from '../../dynamicForm/dynaform.interfaces';
import { APIService, CartService, ModelLoaderService } from '../../services';
import { TranslatingBase } from '../ComponentBase';


@Component({
  selector: 'app-other-product-base',
  template: `<p>Extend other product from this!</p>`
})
export class OtherProductBaseComponent extends TranslatingBase implements OnInit, AfterViewInit {

  @ViewChild(DynamicFormComponent) form: DynamicFormComponent;

  formIsInvalid = true;
  model: FormRow[] = [];
  productOptions: IOption[];
  amount = 1;
  otherProductShortName: OtherProductShortName;

  constructor(
      private _cartService: CartService,
      private _modelLoader: ModelLoaderService,
      private _toastrService: ToastrService,
      private _api: APIService,
      private _router: Router,
      private __translateService: TranslateService,
  ) {
    super(__translateService);
  }

  ngOnInit() {
    this.amount = 1;
    this.model = this._modelLoader.load(this.otherProductShortName);
  }

  async ngAfterViewInit() {
    this.productOptions = await this._api.getProductOptions();
    for (const row of this.model) {
      for (const field of row.fields || []) {
        switch (field.name) {
          case OptionProperty.cornerJoin:
          case OptionProperty.surfaceTreatment:
          case OptionProperty.plinth:
          case OptionProperty.shelfHoles:
          case OptionProperty.typeOfWood:
          case OptionProperty.veneer:
            field.options = this.getOptions(field.name);
            break;
        }
      }
    }
  }

  handleFormChanged() {
    this.formIsInvalid = this.form.invalid;
  }

  amountApproved(approved: boolean) {
    if (approved) {
      this.finalizeOrder();
    }
  }

  protected finalizeOrder() {
  }

  protected async addToCart(orderObj: ITypedAddCartItem) {
    await this._cartService.addItem(orderObj);
    this._toastrService.success(this.translate('Cart.AddedToCart'));
  }

  protected async goto(url: string) {
    await this._router.navigateByUrl(url);
  }

  private getOptions(withProperty: OptionProperty) {
    return this.productOptions
        .filter((o) => (o.property === withProperty) && (o.types.indexOf(this.otherProductShortName) > -1));
  }

}
