<div class="bgimage"></div>

<div class="container login-container">
    <div class="row">
        <div class="col-sm-6 offset-sm-3">
            <div class="card shadow-sm p-3 mb-5 bg-white rounded">
                <div class="card-body">
                    <div class="text-center">
                        <img alt="Unit-Partner Shop" class="up-logo-img" routerLink="/" src="assets/images/UniPartner_Shop-Logo.png">
                    </div>

                    <p>Siden understøtter desværre ikke Internet Explorer.</p>
                    <p>Vi anbefaler at du bruger
                        <a href="https://chrome.com">Google Chrome</a>,
                        <a href="https://microsoftedge.com">Microsoft Edge</a> eller
                        <a href="https://www.mozilla.org/da/firefox/new/">Firefox</a>.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
