import { TranslateService } from '@ngx-translate/core';

import { ITypedAddCartItem } from '../../../../../wdcommon/ICartItem';
import { CartItem } from './CartItem';
import { PriceCalculatorService } from '../price.calculator.service';


export class CabinetCarcassCartItem extends CartItem {

  constructor(
      translateService: TranslateService,
      private priceService: PriceCalculatorService,
      extra: ITypedAddCartItem
  ) {
    super(translateService, extra);
  }

  protected async calculatePrice(): Promise<number> {
    const price = this.priceService.CalculateCarcass(this);

    if (typeof (price) === 'number') {
      return price;
    }

    console.error(`Unable to calculate price for cabinet carcass ${this.itemno} got: ${price}.`);
    return 0;
  }
}
