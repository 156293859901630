import { Validators } from '@angular/forms';

import { OptionProperty } from '../../../../../wdcommon/IProduct';
import { FieldConfig, FormRow } from '../../dynamicForm/dynaform.interfaces';
import { StandardTypeOfWoodField } from '../../dynamicForm/standard-fields';


const standardTypeOfWoodField: FieldConfig = Object.assign({}, StandardTypeOfWoodField, {classes: 'col-12'});

export const MODEL: FormRow[] = [{
  id: 'type-row',
  show: true,
  fields: [{
    type: 'select',
    name: 'productType',
    label: 'Type',
    classes: 'col-12',
    options: [],
    translate: {
      itemLabel: false
    },
    validations: [{
      name: 'required',
      validator: Validators.required,
      message: 'Organiser.required.Type'
    }]
  }]
}, {
  id: 'variable-row',
  show: true,
  fields: [{
    type: 'select',
    name: 'variableWidth',
    label: 'SlackDrawer.VariableWidth.Label',
    classes: 'col-12',
    options: []
  }]
}, {
  id: 'width-row',
  show: true,
  fields: [{
    type: 'input',
    classes: 'col-12',
    inputType: 'number',
    name: OptionProperty.width,
    min: 500,
    max: 1100,
    suffix: 'mm',
    label: 'Bredde',
    placeholder: 'SlackDrawer.placeholder.Width',
    disabled: true,
    show: true,
    validations: [{
      name: 'required',
      validator: Validators.required,
      message: 'Organiser.required.Width'
    }, {
      name: 'pattern',
      validator: Validators.pattern('\\d*'),
      message: 'Bredde skal indtastes som et heltal'
    }]
  }]
}, {
  id: 'wood-row',
  show: true,
  fields: [standardTypeOfWoodField]
}, {
  id: 'coupling-row',
  fields: [{
    type: 'checkbox',
    inputType: 'checkbox',
    name: 'coupling',
    label: 'SlacksDrawer.coupling.label',
    containerClass: 'col-12'
  }]
}];
