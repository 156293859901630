import { TranslateService } from '@ngx-translate/core';

import { PriceCalculatorService } from '../';
import { CartItem } from './CartItem';
import { ITypedAddCartItem } from '../../../../../wdcommon/ICartItem';
import { ICompanyAdjustments } from '../../../../../wdcommon/ICompany';


export class RunnerCartItem extends CartItem {

  constructor(
      translateService: TranslateService,
      private priceService: PriceCalculatorService,
      runner: ITypedAddCartItem,
      private companyAdjustmentsProvider: () => Promise<ICompanyAdjustments>,
  ) {
    super(translateService, runner);
  }

  protected async calculatePrice(): Promise<number> {
    const companyAdjustments = await this.companyAdjustmentsProvider();

    const price: number = await this.priceService.CalculateRunner(this.itemno, companyAdjustments);

    if (typeof price === 'number') {
      return price;
    }

    console.error(`Unable to calculate price for runner ${this.itemno} got: ${price}.`);
    return 0;
  }
}
