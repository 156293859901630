<div class="container">
    <div class="row mb-3">
        <div class="col-sm">
            <h3 *ngIf="isNew()">{{ 'Opret nyt produkt' | translate }}</h3>
            <h3 *ngIf="isEdit()">{{ 'Rediger produkt' | translate }}</h3>
            <h3 *ngIf="isCopy()">Kopier produkt</h3>
            <app-product-path *ngIf="product && isEdit()" [categoryId]="product.categoryId || categoryId"></app-product-path>
        </div>
        <div class="col-sm-2 text-end">
            <button appBackButton class="btn btn-primary">
                <fa-icon [icon]="faWindowClose"></fa-icon>
                {{ 'UTILITY.CANCEL' | translate }}
            </button>
        </div>
    </div>

    <div class="row pb-2">
        <div class="col-sm">

            <form (ngSubmit)="createProduct()" [formGroup]="productForm" novalidate>

                <div class="row"
                     formArrayName="locale">
                    <div *ngFor="let locale of productForm.get('locale')['controls']; let i = index"
                         class="col-sm-6 mb-4">
                        <app-admin-new-component-locale (delete)="deleteLocale(locale)"
                                                        [canBeDeleted]="productForm.get('locale')['controls'].length > 1"
                                                        [group]="productForm.get('locale')['controls'][i]">
                        </app-admin-new-component-locale>
                    </div>
                </div>

                <div *ngIf="unsupportedLocales && unsupportedLocales.length > 0" class="mb-4">
                    Tilføj:
                    <button (click)="addLocale(locale)" *ngFor="let locale of unsupportedLocales" class="btn btn-light btn-sm ms-2">
                        {{locale.name}}
                    </button>
                </div>

                <div class="mb-3">
                    <label class="form-label">{{'Varenummer' | translate}}</label>
                    <input class="form-control" formControlName="itemNumber" required type="text">
                    <small *ngIf="productForm?.controls?.itemNumber?.errors?.cannotContainSpace" translate="Products.ItemNumber.SpaceError"></small>
                </div>

                <div class="mb-3">
                    <label class="form-label">{{'Prisen er pr' | translate}}</label>
                    <div class="form-check">
                        <input [value]=false class="form-check-input" formControlName="isSet" name="isSet" type="radio"/>
                        <label class="form-check-label">Stk.</label>
                    </div>
                    <div class="form-check">
                        <input [value]=true class="form-check-input" formControlName="isSet" name="isSet" type="radio"/>
                        <label class="form-check-label">Sæt</label>
                    </div>
                </div>

                <div class="mb-3">
                    <label class="form-label">Samlet fragt</label>
                    <div class="d-flex justify-content-between">
                        <div>
                            <div class="form-check">
                                <input [value]=true class="form-check-input" formControlName="fullPallet" id="fullPalletTrue" name="fullPallet"
                                       type="radio"/>
                                <label class="form-check-label" for="fullPalletTrue">Fylder hel palle</label>
                            </div>
                            <div class="form-check">
                                <input [value]=false class="form-check-input" formControlName="fullPallet" id="fullPalletFalse" name="fullPallet"
                                       type="radio"/>
                                <label class="form-check-label" for="fullPalletFalse">Fylder en del af en palle</label>
                            </div>
                            <div class="form-text">Hvis dette produkt fylder en hel palle i sig selv, så vælg "Fylder hel palle".</div>
                        </div>
                        <div class="mx-3">
                            <label class="form-label" for="oneDeliverySize">Størrelse på delt(e) palle(r)</label>
                            <div class="input-group">
                                <input [max]="sizeMax" class="form-control"
                                       formControlName="oneDeliverySize" id="oneDeliverySize" min="0" name="oneDeliverySize" type="number"/>
                                <span class="input-group-text">af {{ sizeMax }}</span>
                            </div>
                            <div class="form-text">Hvor meget denne vare fylder på en palle ved samlet levering.</div>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" formControlName="oneDeliveryDoublePallet" id="oneDeliveryDoublePallet"
                                   name="oneDeliveryDoublePallet" type="checkbox"/>
                            <label class="form-check-label" for="oneDeliveryDoublePallet">Fylder på to paller</label>
                            <div *ngIf="freightSetting" class="form-text">Hvis en længde på produktet
                                overstiger {{ freightSetting.oneDelivery.doublePalletLimit }} mm, så skal der sættes flueben her.
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mb-3">
                    <label class="form-label">{{'Link til yderligere info' | translate}}</label>
                    <input class="form-control" formControlName="link" type="url">
                </div>

                <div class="mb-3">
                    <label class="form-label">{{'Relaterede produkter' | translate}}</label>
                    <app-related-products-editor [productId]="productId" [relatedProducts]="getRelatedProductsFormArray()">
                    </app-related-products-editor>
                </div>

                <div>
                    <label class="form-label" for="productImage" translate="Produktbillede"></label>
                    <div class="custom-file">
                        <input (change)="onFileChosen($event)" accept="image/*" class="form-control" id="productImage" type="file" value="Vælg fil"/>
                    </div>
                    <div class="col-sm-2">
                        <img *ngIf="imgUrl" [src]="imgUrl" alt="" class="NO-CACHE"/>
                    </div>
                </div>
                <div class="col-sm">
                    <hr>
                </div>
                <div class="col-sm text-end">
                    <button *ngIf="isNew() || isCopy()" class="btn btn-success" type="submit">{{ 'Opret produkt' | translate }}
                        <fa-icon [icon]="faCheck"></fa-icon>
                    </button>
                    <button *ngIf="isEdit()" class="btn btn-success" type="submit">{{ 'Opdater produkt' | translate }}
                        <fa-icon [icon]="faCheck"></fa-icon>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
