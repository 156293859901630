import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { LocaleService } from '../services';


@Injectable()
export class AddLocaleQuerystringParameterToHttpRequestsInterceptor implements HttpInterceptor {

  constructor(private _localeService: LocaleService) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.params.get('locale'))
      return next.handle(req);

    req = req.clone({
      setParams: {
        locale: this._localeService.getLanguage() || 'da'
      }
    });

    return next.handle(req);

  }

}
