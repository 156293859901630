import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, } from '@angular/router';

import { APIService, BreadcrumbsService, LocaleService } from '../../services';
import { Breadcrumbs, productPrefix, specialsPrefix } from '../../utils/breadcrumbs/breadcrumbs';
import { Constants } from '../../../../../wdcommon/Constants';
import { IDynamicProductWithRelated } from '../../../../../wdcommon/IProduct';


@Component({
  selector: 'app-general-product',
  templateUrl: './general-product.component.html',
  styleUrls: ['./general-product.component.css']
})
export class GeneralProductComponent implements OnInit {
  localeId: string;
  product: IDynamicProductWithRelated;
  brandId: string;
  breadcrumbs: Breadcrumbs = {};
  relatedUrls: {
    [productKey: string]: {
      productUrl: string,
      brandId: string,
    }
  } = {};

  protected readonly productPrefix = productPrefix;

  constructor(
      private api: APIService,
      private breadcrumbsService: BreadcrumbsService,
      private localeService: LocaleService,
      private route: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.localeId = this.localeService.getLanguage();
    this.route.paramMap.subscribe((paramMap) => {
      this.product = undefined;
      this.brandId = Constants.specialsCategoryBrandIdPrefix + paramMap.get('brandCategoryId');
      const productId = parseInt(paramMap.get('productId'), 10);
      this.api.getProduct(productId, this.localeId)
          .then((product) => this.createBreadcrumbsAndUrls(product));
    });
  }

  private async createBreadcrumbsAndUrls(product: IDynamicProductWithRelated) {
    product.relatedProducts.map((relatedProduct) => {
      this.breadcrumbsService.getProductUrlAndBrandId(relatedProduct.id, relatedProduct.categoryId)
          .then((productUrlAndBrandId) => {
            this.relatedUrls[productPrefix + relatedProduct.id] = productUrlAndBrandId;
          });
    });
    this.breadcrumbs = await this.breadcrumbsService.createSpecialBreadcrumbs(product.categoryId);
    const parentId = specialsPrefix + product.categoryId;
    this.breadcrumbs[productPrefix + product.id] = {
      url: this.breadcrumbs[parentId].url + '/' + productPrefix + product.id,
      name: product.name,
      parentId: parentId
    };
    this.product = product;
  }

}
