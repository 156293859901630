import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { CartItemType, CartSubType, IBoardOptions, ITypedAddCartItem } from '../../../../../../wdcommon/ICartItem';
import { ExternalShopName } from '../../../../../../wdcommon/IExternalShop';
import { IItemNumber, OptionProperty, Manufacturer, OtherProductShortName } from '../../../../../../wdcommon/IProduct';
import { VeneerProductBaseComponent } from '../../../base-component/veneer-product-base/veneer-product-base.component';
import { APIService, CartService, ModelLoaderService } from '../../../services';


@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.css']
})
export class BoardComponent extends VeneerProductBaseComponent {

  constructor(
      private api: APIService,
      private cartService: CartService,
      private modelLoader: ModelLoaderService,
      private toastrService: ToastrService,
      private router: Router,
      private translateService: TranslateService,
  ) {
    super(cartService, modelLoader, toastrService, api, router, translateService);
    this.otherProductShortName = OtherProductShortName.board;
  }

  protected async finalizeOrder() {
    const cartItemOptions: IBoardOptions = {
      [OptionProperty.width]: this.form.getControl(OptionProperty.width).value,
      [OptionProperty.height]: this.form.getControl(OptionProperty.height).value,
      [OptionProperty.veneer]: this.form.getControl(OptionProperty.veneer).value,
      [OptionProperty.surfaceTreatment]: this.isSurfaceTreatmentNeeded() ? this.form.getControl(OptionProperty.surfaceTreatment).value : null,
      [OptionProperty.comment]: this.form.getControl(OptionProperty.comment).value || null
    };

    const shortTypeEncoded = btoa(OtherProductShortName.board + cartItemOptions[OptionProperty.veneer].substring(0, 2)
        + (cartItemOptions[OptionProperty.surfaceTreatment] ? cartItemOptions[OptionProperty.surfaceTreatment].substring(0, 2) : '')
        + cartItemOptions[OptionProperty.height] + 'x' + cartItemOptions[OptionProperty.width]);
    const itemNo: IItemNumber = await this.api.getVarenr(shortTypeEncoded);

    const formattedName = this.translate('CART.WidthHeight_mm', { width: cartItemOptions[OptionProperty.width], height: cartItemOptions[OptionProperty.height] }) + ', '
        + this.translate('VENEER.' + cartItemOptions[OptionProperty.veneer])
        + (cartItemOptions[OptionProperty.surfaceTreatment] ? ' ' + this.translate('SURFACES.' + cartItemOptions[OptionProperty.surfaceTreatment]) : '');

    const orderObj: ITypedAddCartItem = {
      brandId: Manufacturer.nothegger,
      type: CartItemType.carcass,
      subType: CartSubType.board,
      name: this.translate('CABINET_CARCASS.BOARD'),
      itemno: itemNo.varenr,
      description: formattedName,
      amount: this.amount,
      options: cartItemOptions,
      externalShopName: ExternalShopName.nothegger
    };

    await this.addToCart(orderObj);
    await this.goto('products/nothegger');
  }

}
