import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { APIService, CartService, ModelLoaderService, UtilitiesService } from '../../services';
import { defaultBreadcrumbs } from '../../utils/breadcrumbs/breadcrumbs';
import { FormRow } from '../../dynamicForm/dynaform.interfaces';
import { DynamicFormComponent } from '../../dynamicForm/dynamic-form/dynamic-form.component';
import { TranslatingBase } from '../../base-component/ComponentBase';
import { CartItemType, CartSubType, IOtherItemOptions, ITypedAddCartItem } from '../../../../../wdcommon/ICartItem';
import { ExternalShopName } from '../../../../../wdcommon/IExternalShop';
import { IOption, OptionProperty, Manufacturer, OtherProductShortName } from '../../../../../wdcommon/IProduct';


const productTypes = {
  ['SF-FH3']: {
    width: 209,
    height: 65,
    depth: 576
  },
  ['SF-FH2']: {
    width: 143,
    height: 65,
    depth: 576
  },
  ['SF-MB']: {
    width: 120,
    height: 36,
    depth: 576
  },
  ['SF-GDH']: {
    width: 160,
    height: 40,
    depth: 576
  },
  ['SF-UK']: {
    width: 176,
    height: 65,
    depth: 576
  },
  ['SF-UL']: {
    width: 256,
    height: 65,
    depth: 576
  },
  ['SF-AMS']: {
    width: 256,
    height: 65,
    depth: 576
  },
  ['SF-AMB']: {
    width: 529,
    height: 65,
    depth: 576
  }
};

const productTypeVar = 'productType';
const woodTypeVar = OptionProperty.typeOfWood;
const treatmentVar = OptionProperty.surfaceTreatment;

@Component({
  selector: 'app-sortissimo-fastline',
  templateUrl: './sortissimo-fastline.component.html',
  styleUrls: ['./sortissimo-fastline.component.css']
})
export class SortissimoFastlineComponent extends TranslatingBase implements OnInit, AfterViewInit {
  fastlineBreadcrumb = defaultBreadcrumbs.notheggerSortissimoFastline;

  @ViewChild(DynamicFormComponent) form: DynamicFormComponent;

  sortissimoFastline = OtherProductShortName.sortissimoFastline;
  allProductTypes = Object.entries(productTypes);
  formIsInvalid = true;
  model: FormRow[] = [];
  amount = 1;
  productOptions: IOption[];

  constructor(private formBuilder: UntypedFormBuilder,
              private translateService: TranslateService,
              private router: Router,
              private utilities: UtilitiesService,
              private modelLoader: ModelLoaderService,
              private api: APIService,
              private cartService: CartService,
              private toastrService: ToastrService
  ) {
    super(translateService);
  }

  ngOnInit() {
    this.model = this.modelLoader.load(this.sortissimoFastline);
  }

  async ngAfterViewInit() {
    this.productOptions = await this.api.getProductOptions();
    this.loadOptions();
  }

  loadOptions() {
    for (const row of this.model) {
      for (const field of row.fields || []) {
        if (field && field.name === woodTypeVar) {
          field.options = this.productOptions
              .filter((o) => (o.property === OptionProperty.typeOfWood) && (o.types.indexOf(this.sortissimoFastline) > -1));
        } else if (field && field.name === treatmentVar) {
          field.options = this.productOptions
              .filter((o) => (o.property === OptionProperty.surfaceTreatment) && (o.types.indexOf(this.sortissimoFastline) > -1));
        } else if (field && field.name === productTypeVar) {
          field.options = [];
          this.allProductTypes.forEach((productTypeEntry) => {
            field.options.push({ label: this.translate('SortissimoFastLine.' + productTypeEntry[0]), value: productTypeEntry[0] });
          });
        }
      }
    }
  }

  isActive(type: string): boolean {
    return this.form !== undefined && this.form.getControl(productTypeVar).value === type;
  }

  setType(type: string) {
    const control = this.form.getControl(productTypeVar);
    if (control && control.value !== type) {
      control.setValue(type === undefined ? null : type);
    }
  }

  validateForm() {
    this.formIsInvalid = this.form.invalid;
  }

  async amountApproved(approved: boolean) {
    if (approved) {
      await this.finalizeOrder();
    }
  }

  async goto(url: string) {
    await this.router.navigateByUrl(url);
  }

  private async finalizeOrder() {
    const type: string = this.form.getControl(productTypeVar).value;
    const cartItemOptions: IOtherItemOptions = {
      type,
      [OptionProperty.width]: productTypes[type].width,
      [OptionProperty.depth]: productTypes[type].depth,
      [OptionProperty.height]: productTypes[type].height,
      [OptionProperty.typeOfWood]: this.form.getControl(woodTypeVar).value,
      [OptionProperty.surfaceTreatment]: this.form.getControl(treatmentVar).value
    };

    const shortTypeEncoded = btoa(
        cartItemOptions.type
        + cartItemOptions[OptionProperty.typeOfWood].substring(0, 2).toUpperCase()
        + cartItemOptions[OptionProperty.surfaceTreatment].substring(0, 2).toUpperCase()
    );
    const itemNo: any = await this.api.getVarenr(shortTypeEncoded);

    const formattedType = this.translate('SortissimoFastLine.' + cartItemOptions.type) + ' '
        + this.translate('TREESORTS.' + cartItemOptions[OptionProperty.typeOfWood]) + ' '
        + this.translate('SURFACES.' + cartItemOptions[OptionProperty.surfaceTreatment]);

    const orderObj: ITypedAddCartItem = {
      brandId: Manufacturer.nothegger,
      type: CartItemType.extra,
      subType: CartSubType.sortissimoFastline,
      name: this.translate('OTHER_NAMES.SORTISSIMO_FASTLINE'),
      itemno: itemNo.varenr,
      description: formattedType,
      amount: this.amount,
      options: cartItemOptions,
      externalShopName: ExternalShopName.nothegger
    };

    await this.cartService.addExtra(orderObj);
    this.toastrService.success(this.translate('Cart.AddedToCart'));
    await this.goto('/');
  }

}
