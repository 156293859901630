<div class="container">
    <div class="row mb-3">

        <div class="col-6">
            <app-breadcrumbs [currentBreadcrumb]="adminCompaniesBreadcrumb"></app-breadcrumbs>
        </div>

        <div class="col-6">
            <input (keyup)="searchFilter()" [(ngModel)]="searchNeedle" aria-label="Search" class="form-control" placeholder="Søg" type="search">
        </div>

    </div>
    <div class="row">

        <div class="col-sm">
            <table class="table table-striped table-hover">
                <thead>
                <tr>
                    <th (click)="sortBy('companyName')" class="sortable">
                        Firmanavn <i class="fa fa-fw fa-sort-{{ sortOrder }}{{ sortProperty == 'companyName' ? '' : '-' }}"></i>
                    </th>
                    <th (click)="sortBy('address')" class="sortable">
                        Adresse <i class="fa fa-fw fa-sort-{{ sortOrder }}{{ sortProperty == 'address' ? '' : '-' }}"></i>
                    </th>
                    <th (click)="sortBy('postcode')" class="sortable">
                        Postnr. <i class="fa fa-fw fa-sort-{{ sortOrder }}{{ sortProperty == 'postcode' ? '' : '-' }}"></i>
                    </th>
                    <th (click)="sortBy('city')" class="sortable">
                        By <i class="fa fa-fw fa-sort-{{ sortOrder }}{{ sortProperty == 'city' ? '' : '-' }}"></i>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let company of filteredCompanies" [routerLink]="['./', company.id]" class="clickable">
                    <td>{{ company.companyName }}</td>
                    <td>{{ company.address }}</td>
                    <td>{{ company.postcode }}</td>
                    <td>{{ company.city }}</td>
                </tr>
                </tbody>
            </table>
        </div>

    </div>
</div>
