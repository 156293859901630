<div class="d-flex justify-content-center overflow-hidden admin-navbar py-1 no-print">
    <div class="text-nowrap">
        <a [class.active]="isActiveRoute('/admin', true)" [routerLink]="['/admin']" class="up-menu-item">Dashboard</a>
    </div>
    <div class="text-nowrap">
        <a [class.active]="isActiveRoute('/admin/orders')" [routerLink]="['/admin/orders']" class="up-menu-item">Ordrer</a>
    </div>
    <div class="text-nowrap">
        <a [class.active]="isActiveRoute('/admin/users')" [routerLink]="['/admin/users']" class="up-menu-item">Brugere</a>
    </div>
    <div class="text-nowrap">
        <a [class.active]="isActiveRoute('/admin/companies')" [routerLink]="['/admin/companies']" class="up-menu-item">Firmaer</a>
    </div>
    <div class="text-nowrap">
        <a [class.active]="isActiveRoute('/admin/drawers')" [routerLink]="['/admin/drawers']" class="up-menu-item">Tilgængelighed</a>
    </div>
    <div class="text-nowrap">
        <a [class.active]="isActiveRoute('/admin/' + Manufacturer.ernstMair)" [routerLink]="'/admin/' + Manufacturer.ernstMair"
           class="up-menu-item">
            Ernst Mair
        </a>
    </div>
    <div class="text-nowrap">
        <a [class.active]="isActiveRoute('/admin/products')" [routerLink]="'/admin/' + productsUrl + '/' + specialsPrefix + '/0'"
           class="up-menu-item">
            Specialprodukter
        </a>
    </div>
    <div class="text-nowrap">
        <a [class.active]="isActiveRoute('/admin/freight')" class="up-menu-item" routerLink="/admin/freight">Fragt</a>
    </div>
    <div class="text-nowrap">
        <a [class.active]="isActiveRoute('/admin/global-content')" [routerLink]="'/admin/global-content'" class="up-menu-item">Indholdstekster</a>
    </div>
</div>
