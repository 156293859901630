import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons/faExternalLink';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faRedo } from '@fortawesome/free-solid-svg-icons/faRedo';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons/faShoppingCart';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faTruckMoving } from '@fortawesome/free-solid-svg-icons/faTruckMoving';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons/faUserSecret';

import { APIService, CartService, LocaleService, UserService } from '../../services';
import { productTypesUrl } from '../../utils/breadcrumbs/breadcrumbs';
import { TranslatingBase } from '../../base-component/ComponentBase';
import { RepeatDrawerComponent } from '../../repeat-drawer/repeat-drawer.component';
import { RepeatPurewoodFrontComponent } from '../../purewood/purewood-fronts/repeat-purewood-front/repeat-purewood-front.component';
import { RepeatCartItem } from '../../../../../wdcommon/ICartItem';
import { IDrawerHistory } from '../../../../../wdcommon/IDrawer';
import { GlobalContentType } from '../../../../../wdcommon/IGlobalContent';
import { IFrontHistory } from '../../../../../wdcommon/IProduct';
import { ISavedProject } from '../../../../../wdcommon/ISavedProject';
import { IUser } from '../../../../../wdcommon/IUser';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';


const toastErrorConfig: Partial<IndividualConfig> = {
  timeOut: 10000,
  progressBar: true,
  tapToDismiss: false,
};

interface ProductType {
  textKey: string;
  url: string;
}

interface DeliveryInfo {
  header: string;
  text: string;
}

interface IDrawerHistoryWithFormattedType extends IDrawerHistory {
  formattedType?: string;
}

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent extends TranslatingBase implements OnInit {
  @ViewChild('savedOrderList') savedProjectsModal: TemplateRef<any>;
  @ViewChild('deliveryInfoModal') deliveryInfoModal: TemplateRef<any>;
  @ViewChild(RepeatDrawerComponent) repeatDrawerModal: RepeatDrawerComponent;
  @ViewChild(RepeatPurewoodFrontComponent) repeatFrontModal: RepeatPurewoodFrontComponent;

  deliveryInfo: DeliveryInfo;
  deliveryInfoExists = true;

  loginForm: UntypedFormGroup;
  loginFormEmail = 'email';
  loginFormPassword = 'password';

  modalRef: BsModalRef;
  user: IUser;
  cart;
  savedProjects: ISavedProject[];
  selectedSavedProject;

  productTypes: ProductType[] = [];

  fiveDrawers: IDrawerHistoryWithFormattedType[] = [];
  fiveFronts: IFrontHistory[] = [];
  repeatDrawer: RepeatCartItem;
  repeatFront: RepeatCartItem;

  protected readonly faExternalLink = faExternalLink;
  protected readonly faInfoCircle = faInfoCircle;
  protected readonly faRedo = faRedo;
  protected readonly faShoppingCart = faShoppingCart;
  protected readonly faTrash = faTrash;
  protected readonly faTruckMoving = faTruckMoving;
  protected readonly faUser = faUser;
  protected readonly faUserPlus = faUserPlus;
  protected readonly faUserSecret = faUserSecret;

  constructor(
      private apiService: APIService,
      public cartService: CartService,
      private localeService: LocaleService,
      public modalService: BsModalService,
      private router: Router,
      private toastrService: ToastrService,
      private translateService: TranslateService,
      private userService: UserService,
  ) {
    super(translateService);
    const wd: ProductType = {
      textKey: 'ProductType.wooden-drawers',
      url: '/' + productTypesUrl + '/wooden-drawers',
    };
    const fittings: ProductType = {
      textKey: 'ProductType.fittings',
      url: '/' + productTypesUrl + '/fittings',
    };
    const fronts: ProductType = {
      textKey: 'ProductType.fronts',
      url: '/' + productTypesUrl + '/fronts',
    };
    const cabinet: ProductType = {
      textKey: 'ProductType.cabinet',
      url: '/' + productTypesUrl + '/cabinet',
    };
    const led: ProductType = {
      textKey: 'ProductType.led-lighting',
      url: '/' + productTypesUrl + '/led-lighting',
    };
    const surface: ProductType = {
      textKey: 'ProductType.surface-treatment',
      url: '/' + productTypesUrl + '/surface-treatment',
    };
    this.productTypes.push(wd);
    this.productTypes.push(fittings);
    this.productTypes.push(fronts);
    this.productTypes.push(cabinet);
    this.productTypes.push(led);
    this.productTypes.push(surface);
  }

  ngOnInit() {
    this.loginForm = new UntypedFormGroup({
      [this.loginFormEmail]: new UntypedFormControl(),
      [this.loginFormPassword]: new UntypedFormControl()
    });
    this.user = this.userService.getUser();
  }

  async showDeliveryInfo() {
    const infos = await this.apiService.getGlobalContentOfType(GlobalContentType.deliveryInfo);
    for (let i = 0; i < infos.length; i++) {
      if (infos[i].public && infos[i].lastPublished &&
          (!infos[i].availableFrom || new Date(infos[i].availableFrom).valueOf() < Date.now()) &&
          (!infos[i].availableTo || new Date(infos[i].availableTo).valueOf() > Date.now())) {
        const language = this.localeService.getLanguage();
        this.deliveryInfo = infos[i].public[language] as any;
        break;
      }
    }
    if (!this.deliveryInfo) {
      this.deliveryInfoExists = false;
    } else {
      this.modalRef = this.modalService.show(this.deliveryInfoModal);
    }
  }

  async loginUser() {
    const { success, error } = await this.userService.loginUser(this.loginForm.value.email, this.loginForm.value.password);

    if (success) {
      this.toastrService.clear();
      this.toastrService.success(this.translate('LOGIN.Success'));
      return this.router.navigateByUrl('/login');
    }

    const msg = this.translate('LOGIN.' + error);
    if (error === 'USER_NOT_ACTIVATED') {
      this.toastrService.info(msg, this.translate('LOGIN.Error.IkkeAktiveret'), toastErrorConfig);
    } else {
      this.toastrService.error(msg, this.translate('LOGIN.Error.Ukendt'), toastErrorConfig);
    }
  }

  async showSavedProjects() {
    this.savedProjects = await this.apiService.getSavedProjects();
    this.modalRef = this.modalService.show(this.savedProjectsModal);
  }

  async checkCartAndGoto() {
    if (this.cartService.getNumberOfItems() > 0) {
      await this.router.navigateByUrl('/cart');
    } else {
      this.toastrService.info(this.translateService.instant('NOTIFICATIONS.CART_EMPTY'));
    }
  }

  async deleteSavedProject() {
    this.apiService.deleteSavedOrder(this.selectedSavedProject).subscribe(() => {
      this.modalRef.hide();
      this.toastrService.success(this.translateService.instant('NOTIFICATION.SAVED_PROJECT_DELETED'));
      this.showSavedProjects();
    });
  }

  async restoreSavedProject() {
    const selectedProject = this.savedProjects.find(so => {
      return so.id === this.selectedSavedProject;
    });
    try {
      await this.cartService.import(selectedProject.content);
      this.toastrService.success(this.translateService.instant('NOTIFICATIONS.SAVED_PROJECT_LOADED'));
      this.modalRef.hide();
      if (this.router.url.indexOf('cart') > -1) {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.navigated = false;
        await this.router.navigate([this.router.url]);
      } else {
        await this.router.navigateByUrl('/cart');
      }
    } catch (ex) {
      this.modalRef.hide();
      this.toastrService.error(this.translateService.instant('NOTIFICATIONS.SAVED_PROJECT_IMPORT_ERROR'));
    }
  }

  isActiveRoute(route: string) {
    return this.router.url.indexOf(route) > -1;
  }

  setRepeatDrawer(drawer: IDrawerHistoryWithFormattedType) {
    document.getElementById('offcanvasRepeatClose').click();
    this.repeatDrawer = {
      contents: drawer.contents,
      description: drawer.description,
      formattedType: drawer.formattedType,
      itemno: drawer.itemno
    };
    this.repeatDrawerModal.show();
  }

  setRepeatFront(front: IFrontHistory) {
    document.getElementById('offcanvasRepeatClose').click();
    this.repeatFront = {
      contents: front.contents,
      description: front.description,
      formattedType: front.formattedType,
      itemno: front.itemno
    };
    this.repeatFrontModal.show();
  }

  protected loadHistory() {
    const drawersPromise = this.apiService.getDrawerHistory(5);
    const frontsPromise = this.apiService.getFrontHistory(5);
    Promise.all([drawersPromise, frontsPromise])
        .then(([drawers, fronts]) => {
          this.fiveDrawers = drawers.map((h) => {
            return {
              ...h,
              formattedType: this.translate('DRAWER_NAMES.' + h.contents.type),
            };
          });
          this.fiveFronts = fronts;
        });
  }

}
