import { Component, Input } from '@angular/core';
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome';

import { Breadcrumb, defaultBreadcrumbs } from './breadcrumbs';


@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.css']
})
export class BreadcrumbsComponent {
  @Input() breadcrumbs = defaultBreadcrumbs;
  faHome = faHome;
  breadcrumbTrail: Breadcrumb[];

  @Input() set currentBreadcrumb(crumb: Breadcrumb) {
    if (!crumb) {
      return;
    }
    const crumbs: Breadcrumb[] = [];
    crumbs.push(crumb);
    while (this.breadcrumbs[crumbs[crumbs.length - 1].parentId]) {
      crumbs.push(this.breadcrumbs[crumbs[crumbs.length - 1].parentId]);
    }
    this.breadcrumbTrail = crumbs.reverse();
  }

}
