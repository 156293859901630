
<div class="modal-header">
    <h4 class="modal-title">{{ 'Cart.ConfirmAddToCart' | translate }}</h4>
    <button (click)="hide()" aria-label="Close" class="btn-close" type="button"></button>
</div>

<div class="modal-body">
    <label class="form-label">{{ 'Cart.Amount' | translate }}</label>
    <input
        (change)="updateAmount()"
        [(ngModel)]="amount"
        class="form-control"
        type="number"
        [min]="minAmount"
        [max]="maxAmount"
        [step]="amountStep" />
</div>

<div class="modal-footer">
    <button (click)="approveOrCancel(true)" [disabled]="disable" class="btn btn-success">
        {{ 'Godkend' | translate }}
        <fa-icon [icon]="faCheck"></fa-icon>
    </button>
    <button (click)="approveOrCancel(false)" class="btn btn-secondary" data-dismiss="modal" type="button">
        {{ 'Annullér' | translate }}
    </button>
</div>