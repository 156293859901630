import { Component, OnInit } from '@angular/core';

import { APIService, SettingService } from '../../services';
import { defaultBreadcrumbs, productsUrl } from '../../utils/breadcrumbs/breadcrumbs';
import { IDynamicProduct } from '../../../../../wdcommon/IProduct';
import { IIFreightSetting } from '../../../../../wdcommon/ISetting';


@Component({
  selector: 'app-admin-products-all',
  templateUrl: './admin-products-all.component.html',
  styleUrls: ['./admin-products-all.component.css']
})
export class AdminProductsAllComponent implements OnInit {
  productsPerCategory: IDynamicProduct[][];
  freightSetting: IIFreightSetting;

  protected readonly adminAllProductsBreadcrumb = defaultBreadcrumbs.adminAllProducts;
  protected readonly productsUrl = productsUrl;

  constructor(
      private apiService: APIService,
      private settingService: SettingService,
  ) {
  }

  async ngOnInit() {
    const products = await this.apiService.getAllProducts('da');

    const productsPerCategory: {[categoryId: string]: IDynamicProduct[]} = {};
    products.forEach((product) => {
      const index = product.categoryId ? product.categoryId.toString() : 'null';
      if (!productsPerCategory[index]) {
        productsPerCategory[index] = [];
      }
      productsPerCategory[index].push(product);
    });

    this.productsPerCategory = Object.values(productsPerCategory);
    this.productsPerCategory.map((categoryProducts) => categoryProducts.sort((a, b) => a.itemNumber > b.itemNumber ? 1 : a.itemNumber < b.itemNumber ? -1 : 0));
    this.freightSetting = await this.settingService.getFreightSetting();
  }
}
