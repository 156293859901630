import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-root-modal',
  templateUrl: './root-modal.component.html',
  styleUrls: ['./root-modal.component.css']
})
export class RootModalComponent implements OnInit {

  @Input() modalClass: string; //  = 'modal-lg';

  @ViewChild('modal')
  modalTemplate: TemplateRef<any>;
  private reference: BsModalRef;

  constructor(private modalService: BsModalService) { }

  ngOnInit() {
  }

  show() {
    this.reference = this.modalService.show(this.modalTemplate, { class: this.modalClass });
  }

  hide() {
    if (this.reference) {
      this.reference.hide();
    }
  }

}
