import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { FrontCategory, IFront } from '../../../../../wdcommon/IProduct';
import { APIService } from '../../services';
import { Breadcrumb, defaultBreadcrumbs } from '../../utils/breadcrumbs/breadcrumbs';
import { TranslatingBase } from '../../base-component/ComponentBase';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-purewood-fronts',
  templateUrl: './purewood-fronts.component.html',
  styleUrls: ['./purewood-fronts.component.css']
})
export class PurewoodFrontsComponent extends TranslatingBase implements OnInit {
  @BlockUI('blockWhileLoading') blockWhileLoading: NgBlockUI;

  frontCategory: FrontCategory;
  linoleumFrontCategory = FrontCategory.linoleum;
  woodenFrontCategory = FrontCategory.wooden;

  breadcrumb: Breadcrumb;

  fronts: IFront[];

  constructor(
      private api: APIService,
      private route: ActivatedRoute,
      private router: Router,
      private translateService: TranslateService,
  ) {
    super(translateService);
  }

  async ngOnInit() {
    this.blockWhileLoading.start(this.translate('Loading.fronts'));

    this.route.params.subscribe(async (params) => {
      this.frontCategory = params.category;
      switch (this.frontCategory) {
        case FrontCategory.linoleum:
          this.breadcrumb = defaultBreadcrumbs['purewoodLinoleumFronts'];
          break;
        case FrontCategory.painted:
          this.breadcrumb = defaultBreadcrumbs['purewoodPaintedFronts'];
          break;
        case FrontCategory.veneer:
          this.breadcrumb = defaultBreadcrumbs['purewoodVeneerFronts'];
          break;
        case FrontCategory.wooden:
          this.breadcrumb = defaultBreadcrumbs['purewoodWoodenFronts'];
          break;

        default:
          console.error('Unknown Purewood front category', params);
          return this.router.navigateByUrl(defaultBreadcrumbs.purewood.url);
      }

      this.fronts = await this.api.getFronts(this.frontCategory);

      this.blockWhileLoading.stop();
    });
  }

}
