import { Injectable } from '@angular/core';

import { CartItemType } from '../../../../wdcommon/ICartItem';


@Injectable({
  providedIn: 'root'
})
export class CartItemDiscountService {
  calculateDiscount(type: CartItemType, amount: number, pricePer: number): { discountPercentage: number, totalPrice: number; } {
    if (type !== CartItemType.drawer)
      return { discountPercentage: 0, totalPrice: amount * pricePer };

    let discountPercentage = 0;

    if (amount >= 100)
      discountPercentage = 0.1;
    else if (amount >= 75)
      discountPercentage = 0.075;
    else if (amount >= 50)
      discountPercentage = 0.05;
    else if (amount >= 20)
      discountPercentage = 0.025;

    return { discountPercentage: discountPercentage, totalPrice: Math.round((1 - discountPercentage) * amount * pricePer * 100) / 100 };
  }
}
