<a (click)="openMeeting(meetingSchedulePopup)" aria-haspopup="true" [ngClass]="'clickable ' + addedClasses" id="meeting-schedule-btn" type="button">
  <fa-icon [icon]="faCalendar"></fa-icon> &nbsp;<ng-content></ng-content>
</a>

<ng-template #meetingSchedulePopup>
  <div class="modal-header border-bottom-0">
    <button (click)="modalRef.hide()" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
  </div>
  <div class="modal-body">
    <ul class="nav nav-tabs justify-content-center">
      <li class="nav-item">
        <a (click)="activeTab = ActiveTab.thomas; $event.preventDefault();" [attr.aria-controls]="ActiveTab.thomas" [attr.aria-selected]="activeTab === ActiveTab.thomas"
           [class.active]="activeTab === ActiveTab.thomas"
           [href]="'#' + ActiveTab.thomas" [id]="ActiveTab.thomas + '-tab'" class="nav-link" data-toggle="tab" role="tab"><h4>{{ 'SCHEDULE_MEETING.BookWith' | translate }} Thomas</h4></a>
      </li>
      <!-- <li class="nav-item">
        <a (click)="activeTab = ActiveTab.jacob; $event.preventDefault();" [attr.aria-controls]="ActiveTab.jacob" [attr.aria-selected]="activeTab === ActiveTab.jacob"
           [class.active]="activeTab === ActiveTab.jacob"
           [href]="'#' + ActiveTab.jacob" [id]="ActiveTab.jacob + '-tab'" class="nav-link" data-toggle="tab" role="tab"><h4>{{ 'SCHEDULE_MEETING.BookWith' | translate }} Jacob</h4></a>
      </li> -->
    </ul>
    <!-- Start of Meetings Embed Script -->
    <div [attr.aria-labelledby]="ActiveTab.thomas + '-tab'" [class.d-none]="activeTab !== ActiveTab.thomas" [id]="ActiveTab.thomas" class="tab-pane fade meetings-iframe-container show active"
         data-src="https://meetings-eu1.hubspot.com/thomas-thorhauge?embed=true" role="tabpanel">
    </div>
    <!-- <div [attr.aria-labelledby]="ActiveTab.jacob + '-tab'" [class.d-none]="activeTab !== ActiveTab.jacob" [id]="ActiveTab.jacob" class="tab-pane fade meetings-iframe-container show active"
         data-src="https://meetings-eu1.hubspot.com/jalbrechtsen?embed=true" role="tabpanel">
    </div> -->
    <!-- Notice: script is called from component on popup open -->
    <!-- End of Meetings Embed Script -->
  </div>
</ng-template>
