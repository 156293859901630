import { FormRow } from '../../dynamicForm/dynaform.interfaces';
import {
  createHeightBaseSelectionRow,
  createHrRow,
  createPremountedCouplingSelectionRow,
  createRunnerDeliverySelectionRow,
  createRunnerWidthSelectionRow,
  createTypeOfWoodSurfaceTreatmentSelectionRow
} from '../../dynamicForm/standard-rows';


export const MODEL: FormRow[] = [
  createTypeOfWoodSurfaceTreatmentSelectionRow(),
  createRunnerWidthSelectionRow(135, 1200, 250, 799),
  createRunnerDeliverySelectionRow(),
  createHeightBaseSelectionRow(),
  createPremountedCouplingSelectionRow(),
  createHrRow(),
  {
    id: 'config',
    type: 'configuration',
    config: ['udsparing', 'hulboring', 'boring', 'boringCutleryTray']
  }
];
