import { Validators } from '@angular/forms';

import { FieldConfig, FormRow } from '../../dynamicForm/dynaform.interfaces';
import { createHrRow } from '../../dynamicForm/standard-rows';
import { createDrawerHeightField, DrawerHeightFieldName, StandardSurfaceTreatmentField, StandardTypeOfWoodField } from '../../dynamicForm/standard-fields';

const surfaceField: FieldConfig = Object.assign({}, StandardSurfaceTreatmentField, {disabled: false});

export const MODEL: FormRow[] = [
  {
    id: 'type-height',
    show: true,
    fields: [
      {
        type: 'select',
        name: 'bstype',
        label: 'Type',
        options: [],
        translate: {
          itemLabel: false
        },
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message: 'Organiser.required.Type'
          }
        ]
      },
      createDrawerHeightField(DrawerHeightFieldName.height, false)
    ]
  },
  {
    id: 'dybde-bredde',
    show: true,
    fields: [
      {
        type: 'input',
        inputType: 'number',
        name: 'dybde',
        label: 'Dybde',
        min: 137,
        max: 1200,
        suffix: 'mm',
        placeholder: 'Organiser.placeholder.Depth',
        disabled: false,
        show: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message: 'Organiser.required.Depth'
          },
          {
            name: 'pattern',
            validator: Validators.pattern('\\d*'),
            message: 'Required.Depth.integer'
          }
        ],
        translate: { helptext: false }
      },
      {
        type: 'input',
        inputType: 'number',
        name: 'bredde',
        min: 137,
        max: 1200,
        suffix: 'mm',
        label: 'Bredde',
        placeholder: 'Organiser.placeholder.Width',
        disabled: false,
        show: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message: 'Organiser.required.Width'
          },
          {
            name: 'min',
            validator: Validators.min(137),
            message: 'Bredde minimum er 137 mm'
          },
          {
            name: 'max',
            validator: Validators.max(1200),
            message: 'Bredde maximum er 1200 mm'
          },
          {
            name: 'pattern',
            validator: Validators.pattern('\\d*'),
            message: 'Lysmål skal indtastes som et heltal'
          }
        ],
        translate: { helptext: false }
      },
    ]
  },
  {
    id: 'type-of-wood-surface-treatment-row',
    show: true,
    fields: [
      StandardTypeOfWoodField,
      surfaceField
    ]
  },
  {
    id: 'levbund',
    show: true,
    fields: [
      {
        type: 'checkbox',
        inputType: 'checkbox',
        name: 'levbund',
        containerClass: 'col-sm',
        label: 'Leveres med bund',
        options: [],
        validations: []
      }
    ]
  },
  createHrRow(),
];
