<div class="card mb-3">
    <div *ngIf="showStatus" class="card-header">
        <span class="float-end">Status: <b>{{ order.status.statusText }}</b></span>
    </div>

    <div class="card-body">
        <div class="row">
            <div class="col-sm-3">
                <div>
                    <b translate="UTILITY.DELIVERY_ADDRESS"></b>
                </div>
                <span [innerText]="order.deliveryAddress"></span><br>
                <span [innerHTML]="order.deliveryPostcode"></span>&nbsp;<span [innerHTML]="order.deliveryCity"></span><br>
                <span [innerHTML]="order.company.country"></span><br>
            </div>


            <div class="col-sm-3">
                <ng-container *ngIf="order.oneDelivery">
                    <div>
                        <b translate="Order.OneDeliveryAddress"></b>
                    </div>
                    <div [innerText]="order.oneDelivery" class="pb-2"></div>
                </ng-container>
                <ng-container *ngIf="order.deliveryDate">
                    <div>
                        <b>{{ 'UTILITY.DELIVERY_DATE' | translate }}</b><br>
                    </div>
                    <div [class.text-danger]="warning === 'severe'"
                         [class.text-warning]="warning === 'warning'">{{ order.deliveryDate | date }}</div>
                </ng-container>
                <b *ngIf="!order.deliveryDate">Leveringsdato er fri</b>
            </div>
            <div class="col-sm-6 text-end">
                <div>
                    <b translate="ORDERS.ORDERED_BY"></b>
                </div>
                <div>
                    {{ order.user.name }} - <a href="mailto:{{order.user.email}}">{{ order.user.email }}</a>
                </div>
            </div>
        </div>
    </div>
</div>
