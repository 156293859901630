import { Component, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { ICartItem } from '../../../../../wdcommon/ICartItem';
import { IOrder } from '../../../../../wdcommon/IOrder';
import { defaultBreadcrumbs } from '../../utils/breadcrumbs/breadcrumbs';
import { APIService, UserService } from '../../services';
import { TranslatingBase } from '../../base-component/ComponentBase';


@Component({
  selector: 'app-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.css']
})
export class MyOrdersComponent extends TranslatingBase implements OnInit {
  myOrdersBreadcrumb = defaultBreadcrumbs['myOrders'];

  displayOrders: IOrder[];
  orders: IOrder[];
  searchNeedle = '';
  currentOrderPage: any;

  constructor(
      private api: APIService,
      private translateService: TranslateService,
      private userService: UserService,
  ) {
    super(translateService);
  }

  ngOnInit() {
    firstValueFrom(this.api.getOrders(this.userService.getUserID))
        .then((orders) => {
          this.orders = orders;
          this.orders.reverse();
          this.displayOrders = this.orders;
        });
  }

  searchFilter() {
    if (this.searchNeedle.length > 2) {
      this.displayOrders = this.orders.filter((o) => JSON.stringify(o).replace(/"data:.*"/, '').toLowerCase().indexOf(this.searchNeedle.toLocaleLowerCase()) > -1);
    } else {
      this.displayOrders = this.orders;
    }
  }

  countItems(items: ICartItem[]) {
    return items.map((c) => c.amount).reduce((partialSum, a) => partialSum + a, 0);
  }

}
