<div class="container">

    <div *ngIf="globalContentBreadcrumb" class="row mb-3">
        <div class="col">
            <app-breadcrumbs [currentBreadcrumb]="globalContentBreadcrumb"></app-breadcrumbs>
        </div>
    </div>

    <ng-container *ngIf="!waiting">
        <div *ngIf="error" class="row mb-3">
            <div class="alert alert-warning" role="alert">{{error}}</div>
        </div>

        <div class="row mb-3">
            <div class="col-3">
                <label class="form-label" for="typeInput">Indholdstype</label>
                <input [value]="'GlobalContent.Type.' + content.type | translate" aria-describedby="typeHelp" class="form-control" id="typeInput"
                       readonly type="text">
                <div class="form-text" id="typeHelp">Indholdets type.</div>
            </div>
            <div class="col-3">
                <label class="form-label" for="keyInput">Unik ID-nøgle</label>
                <input [(ngModel)]="content.key" aria-describedby="keyHelp" class="form-control" id="keyInput" readonly type="text">
                <div class="form-text" id="keyHelp">Indholdets ID-nøgle - unik for indholdstypen.</div>
            </div>
            <div class="col-3">
                <label class="form-label" for="lastInput">Senest udgivet</label>
                <input [value]="content.lastPublished | date:'MMM d, y, H:mm:ss'" aria-describedby="lastHelp" class="form-control" id="lastInput"
                       readonly type="text">
                <div class="form-text" id="lastHelp">Hvornår er indholdet senest blevet udgivet.</div>
            </div>
            <div class="col-3">
                <label class="form-label" for="idInput">ID</label>
                <input [(ngModel)]="content.id" aria-describedby="idHelp" class="form-control" id="idInput" readonly type="text">
                <div class="form-text" id="idHelp">Database ID.</div>
            </div>
        </div>
    </ng-container>

</div>
<div *ngIf="typeHasText && content && languages" class="container-fluid">

    <div class="row">
        <ng-container *ngFor="let lang of languages" [ngSwitch]="content.type">
            <div *ngSwitchCase="typeEnum.brandBullets" class="col col-lg-6 col-xxl-3">
                <label [for]="'bulletInput' + lang.id" class="form-label">Bullet-points [{{lang.name}}]</label>
                <textarea [(ngModel)]="content.draft[lang.id]" [id]="'bulletInput' + lang.id" aria-describedby="bulletHelp"
                          class="form-control"></textarea>
            </div>

            <div *ngSwitchCase="typeEnum.deliveryInfo" class="col col-lg-6 col-xxl-3">
                <div>
                    <label [for]="'deliveryHeader' + lang.id" class="form-label">Overskrift [{{lang.name}}]</label>
                    <input [(ngModel)]="content.draft[lang.id]['header']" [id]="'deliveryHeader' + lang.id" aria-describedby="deliveryHelp"
                           class="form-control"/>
                </div>
                <div>
                    <label [for]="'deliveryText' + lang.id" class="form-label">Leveringsinfo-tekst [{{lang.name}}]</label>
                    <div>
                        <quill-editor [(ngModel)]="content.draft[lang.id]['text']" [id]="'deliveryText' + lang.id" aria-describedby="deliveryHelp"
                                      class="w-100 vh-25"></quill-editor>
                    </div>
                </div>
            </div>

            <!--            <div *ngSwitchCase="typeEnum.faq" class="col col-lg-6 col-xxl-3">-->
            <!--            </div>-->

            <div *ngSwitchCase="typeEnum.news" class="col col-lg-6">
                <div>
                    <label [for]="'newsHeader' + lang.id" class="form-label">Nyhedsoverskrift [{{lang.name}}]</label>
                    <input [(ngModel)]="content.draft[lang.id]['header']" [id]="'newsHeader' + lang.id" aria-describedby="newsHelp"
                           class="form-control"/>
                </div>
                <div>
                    <label [for]="'newsText' + lang.id" class="form-label">Nyhedstekst [{{lang.name}}]</label>
                    <textarea [(ngModel)]="content.draft[lang.id]['text']" [id]="'newsText' + lang.id" aria-describedby="newsHelp"
                              class="form-control"></textarea>
                </div>
            </div>

            <div *ngSwitchCase="typeEnum.productType" class="col col-lg-6 col-xxl-3">
                <div>
                    <label [for]="'productTypeHeader' + lang.id" class="form-label">Overskrift [{{lang.name}}]</label>
                    <input [(ngModel)]="content.draft[lang.id]['header']" [id]="'productTypeHeader' + lang.id" aria-describedby="productTypeHelp"
                           class="form-control"/>
                </div>
                <div>
                    <label [for]="'productTypeText' + lang.id" class="form-label">Brand tekst [{{lang.name}}]</label>
                    <textarea [(ngModel)]="content.draft[lang.id]['text']" [id]="'productTypeText' + lang.id" aria-describedby="productTypeHelp"
                              class="form-control"></textarea>
                </div>
            </div>

            <div *ngSwitchDefault class="col">
                <div class="form-text text-center">Denne indholdstype er muligvis ikke understøttet pt.</div>
            </div>
        </ng-container>
    </div>

    <div class="row mb-3">
        <ng-container [ngSwitch]="content.type">
            <div *ngSwitchCase="typeEnum.brandBullets" class="col">
                <div class="form-text text-center" id="bulletHelp">
                    Et bullet-point per linje. Forside bullet-points til visning for tilhørende brand.
                </div>
            </div>

            <div *ngSwitchCase="typeEnum.deliveryInfo" class="col" id="deliveryHelp">
                <div class="form-text text-center">Overskrift og tekst til leveringsinfo.</div>
            </div>

            <!--            <div *ngSwitchCase="typeEnum.faq" class="col">-->
            <!--            <div class="form-text text-center">FAQ tekst</div>-->
            <!--            </div>-->

            <div *ngSwitchCase="typeEnum.news" class="col">
                <div class="form-text text-center" id="newsHelp">Nyhedstekst til visning i karrusel på forside.</div>
            </div>

            <div *ngSwitchCase="typeEnum.productType" class="col">
                <div class="form-text text-center" id="productTypeHelp">Tekst der beskriver brandet i kontekst af den aktuelle produkttype.</div>
            </div>

            <div *ngSwitchDefault class="col">
                <div class="form-text text-center">Denne indholdstype er muligvis ikke understøttet pt.</div>
            </div>
        </ng-container>
    </div>

</div>
<div class="container">

    <ng-container *ngIf="!waiting">
        <div *ngIf="typeHasRouterLink" class="row">

            <div class="col mb-3">
                <label class="form-label" for="routerLinkInput">Tags</label>
                <div class="input-group">
                    <span class="input-group-text">{{baseUrl}}</span>
                    <input [(ngModel)]="content.routerLink" aria-describedby="routerLinkHelp" class="form-control" id="routerLinkInput" type="text">
                </div>
                <div class="form-text" id="routerLinkHelp">
                    Link til en intern URL, fx '/products/nothegger'.
                </div>
            </div>

        </div>
        <div class="row">

            <div [ngClass]="(typeHasImage ? 'col-8' : 'col') + ' mb-3'">
                <div class="row">
                    <div class="col-6 mb-3">
                        <label class="form-label" for="fromInput">Tilgængelig fra</label>
                        <input [(ngModel)]="content.availableFrom" aria-describedby="fromHelp" class="form-control" id="fromInput"
                               type="datetime-local">
                        <div class="form-text" id="fromHelp">Hvornår skal der åbnes for indholdet?</div>
                    </div>
                    <div class="col-6 mb-3">
                        <label class="form-label" for="toInput">Tilgængelig Til</label>
                        <input [(ngModel)]="content.availableTo" aria-describedby="toHelp" class="form-control" id="toInput" type="datetime-local">
                        <div class="form-text" id="toHelp">Hvornår skal der lukkes for indholdet?</div>
                    </div>
                    <div class="col-6 mb-3">
                        <label class="form-label" for="sortInput">Sortering</label>
                        <input [(ngModel)]="content.sortOrder" aria-describedby="sortHelp" class="form-control" id="sortInput" type="number">
                        <div class="form-text" id="sortHelp">Placering i sorteringsrækkefølgen for typen.</div>
                    </div>
                    <div class="col-6 mb-3">
                        <label class="form-label" for="designSelect">Vælg design</label>
                        <select (change)="alterDesign()" [(ngModel)]="content.design" aria-describedby="designHelp" aria-label="Vælg design"
                                class="form-select" id="designSelect" required>
                            <option *ngFor="let d of designs" [ngValue]="d" [translate]="'GlobalContent.Design.' + d"></option>
                        </select>
                        <div class="form-text" id="designHelp">Hvilket design skal indholdet oprettes efter?</div>
                    </div>
                    <div class="col">
                        <label class="form-label" for="tagsInput">Tags</label>
                        <input [(ngModel)]="content.tags" aria-describedby="tagsHelp" class="form-control" id="tagsInput" type="text">
                        <div class="form-text" id="tagsHelp">
                            Tags benyttes endnu ikke, men på sigt kan det bruges til fx at målrette indhold til specifikke brugere.
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="typeHasImage" class="col-4 mb-3">
                <label class="form-label" for="image">Billede</label>
                <div class="custom-file">
                    <input (change)="onFileChosen($event)" accept="image/*" aria-describedby="imageHelp" class="form-control" id="image" type="file"
                           value="Vælg fil"/>
                </div>
                <div [ngSwitch]="content.type" class="form-text" id="imageHelp">
                    <span *ngSwitchCase="typeEnum.news" class="col">Upload en billedfil (1620 x 438px).</span>
                    <span *ngSwitchCase="typeEnum.productType" class="col">Upload en billedfil (Anbefalet 1024 x 768px).</span>
                    <span *ngSwitchDefault class="col">Upload en billedfil.</span>
                </div>
                <div *ngIf="imgUrl">
                    <img [src]="imgUrl" alt="" class="img-fluid NO-CACHE"/>
                </div>
                <div *ngIf="newImgUrl && newImgUrl !== imgUrl">
                    <div><strong>Nyt billede:</strong></div>
                    <img [src]="newImgUrl" alt="" class="img-fluid NO-CACHE"/>
                </div>
            </div>

        </div>
        <div *ngIf="typeHasLogo" class="row">
            <div class="col col-md-6 mb-3">
                <label class="form-label" for="logo">Logo</label>
                <div class="custom-file">
                    <input (change)="onLogoChosen($event)" accept="image/*" aria-describedby="logoHelp" class="form-control" id="logo" type="file"
                           value="Vælg logo-fil"/>
                </div>
                <div class="form-text" id="logoHelp">
                    Upload en logofil (~430px i bredden).
                </div>
            </div>
            <div class="col col-md-6 mb-3">
                <div *ngIf="logoUrl">
                    <Label>Aktuelt logo:</Label>
                    <div>
                        <img [src]="logoUrl" alt="" class="img-fluid NO-CACHE logo-img"/>
                    </div>
                </div>
                <div *ngIf="newLogoUrl && newLogoUrl !== logoUrl">
                    <Label>Nyt logo:</Label>
                    <div>
                        <img [src]="newLogoUrl" alt="" class="img-fluid NO-CACHE logo-img"/>
                    </div>
                </div>
            </div>

        </div>
        <div class="row">

            <div class="col d-flex justify-content-end">
                <button (click)="updateContent()" [disabled]="updateDisabled()" class="btn btn-primary mx-3" type="submit">Opdatér skitse</button>
                <button (click)="updateContent(true)" [disabled]="publishDisabled()" class="btn btn-danger" type="submit">Udgiv indhold</button>
            </div>

        </div>
    </ng-container>
    <div *ngIf="waiting" class="row text-center my-5">

        <div class="spinner-border" role="status">
            <span class="visually-hidden">Arbejder...</span>
        </div>

    </div>

</div>
