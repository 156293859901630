import { ICartItem } from './ICartItem';
import { ICompany } from './ICompany';
import { IFreight } from './IPrices';

export enum FeeTypes {
  /**
   * hettichMinOrder is deprecated. Used on older orders.
   * @deprecated
   */
  hettichMinOrder = 'hettichMinOrder',
  oneDelivery = 'oneDelivery',
  purewoodColour = 'purewoodColour',
}

export interface IOrderStatus {
  id: number;
  statusText: string;
}

export type ICart = ICartItem[];

interface BaseOrder {
  deliveryDate: Date;
  externalShopOrders?: IExternalShopOrder[];
  exVat: number;
  fees?: OrderFee[];
  oneDelivery?: string;
  orderDate: Date;
  price: number;
}

export interface CreateOrder extends BaseOrder {
  alternateDeliveryAddress?: string;
  alternateDeliveryAddress2?: string;
  alternateDeliveryCity?: string;
  alternateDeliveryName?: string;
  alternateDeliveryPostcode?: number;
  comments?: string;
  company?: any;
  customs?: number;
  economicsNr?: string | number;
  order: ICart;
  rekvisition?: string;
  shipping: IFreight;
  status?: any;
  userID: number;
}

export interface ExistingOrder extends CreateOrder {
  id: number;
}

export interface OrderFee {
  [FeeTypes.hettichMinOrder]?: number;
  [FeeTypes.oneDelivery]?: number;
  [FeeTypes.purewoodColour]?: number;
  variant?: string;
}

export interface IOrderUser {
  id: number;
  email: string;
  name: string;
}

export interface IOrder extends BaseOrder {
  comments: string;
  company: ICompany;
  contents: ICartItem[];
  customs?: number;
  deliveryAddress: string;
  deliveryAddress2: string;
  deliveryCity: string;
  deliveryDate: Date;
  deliveryName: string;
  deliveryPostcode: number;
  ecoID: string;
  id: number;
  orderID: string;
  rekvisition: string;
  shipping: number;
  status: { id: number, statusText: string };
  user: IOrderUser;
}

export interface IExternalShopOrder {
  shopName: string;
  attempts: number;
  orderedAt?: Date;
  orderId?: string;
}

export interface IExternalShopOrderResponse {
  configErrors?: { [pos: number]: string };
  error?: string;
  errorDetails?: any;
  externalOrderId?: string;
}
