<div class="container">
    <div class="row mb-3">

        <app-breadcrumbs [currentBreadcrumb]="companyAdminBreadcrumb"></app-breadcrumbs>

    </div>
    <div class="row">
        <div class="col-sm">
            <button (click)="openUserModal(companyUsers)" class="btn btn-primary float-end"><span translate="CMPADM.Firma Brugere"></span>&nbsp;<fa-icon
                    [icon]="faUser">
            </fa-icon>
            </button>
        </div>
    </div>
    <hr>
    <!-- firma info form - kan rettes -->
    <form (ngSubmit)="updateCompany()" [formGroup]="companyInfoForm" [ngClass]="{submitted:formSubmitted}" novalidate>
        <div class="row mb-3">
            <div class="col-sm">
                <label class="form-label" translate="CMPADM.Firmanavn"></label>
                <input [placeholder]="translate('CMPADM.Firmanavn')" class="form-control" formControlName="companyName" type="text">
            </div>
            <div class="col-sm-3">
                <label class="form-label" for="CVRNumber" translate="CMPADM.CVR Nummer"></label>
                <input [placeholder]="translate('CMPADM.CVR Nummer')" class="form-control" formControlName="CVRNumber" id="CVRNumber" type="text">
            </div>
            <div class="col-sm-3">
                <label class="form-label" for="FakturaKonto" translate="CMPADM.Faktura Konto"></label>
                <input [placeholder]="translate('CMPADM.Faktura Konto')" class="form-control" formControlName="fakturaKonto" id="FakturaKonto"
                       type="text">
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-sm">
                <label class="form-label" translate="CMPADM.Email"></label>
                <input [placeholder]="translate('CMPADM.Email')"
                       class="form-control"
                       formControlName="email"
                       type="text">
            </div>
            <div class="col-sm">
                <label class="form-label" translate="CMPADM.Telefon"></label>
                <input [placeholder]="translate('CMPADM.Telefon')" class="form-control" formControlName="phone" type="text">
            </div>
        </div>

        <div class="row">
            <h5 translate="CMPADM.Faktureringsadresse"></h5>
        </div>

        <div class="row mb-3">
            <div class="col-sm">
                <label class="form-label" translate="CMPADM.Adresse"></label>
                <input [placeholder]="translate('CMPADM.Adresse')" class="form-control" formControlName="address" name="address" type="text">
            </div>
            <div class="col-sm">
                <label class="form-label">&nbsp;</label>
                <input [placeholder]="translate('CMPADM.Adresse (fortsat)')" class="form-control" formControlName="address2" name="address2"
                       type="text">
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-4">
                <label class="form-label" translate="CMPADM.Postnr"></label>
                <input [placeholder]="translate('CMPADM.Postnr')" class="form-control" formControlName="postcode" type="text">
            </div>
            <div class="col-8">
                <label class="form-label" translate="CMPADM.By"></label>
                <input [placeholder]="translate('CMPADM.By')" class="form-control" formControlName="city" type="text">
            </div>
        </div>

            <h5 translate="CMPADM.Leveringsadresse"></h5>

        <div class="mb-3">
            <div class="col-12 form-check">
                <input (change)="toggleDeliveryAddressSameAsBillingAddress()" [checked]="deliveryAddressSameAsBillingAddress" class="form-check-input"
                       id="deliveryAddressSameAsBillingAddress" type="checkbox">
                <label class="form-check-label" for="deliveryAddressSameAsBillingAddress" translate="CMPADM.Samme som faktureringsadresse"></label>
            </div>
        </div>

        <div *ngIf="!deliveryAddressSameAsBillingAddress" class="row mb-3">
            <div class="col-6">
                <label class="form-label" translate="CMPADM.Adresse"></label>
                <input [placeholder]="translate('CMPADM.Adresse')" class="form-control" formControlName="deliveryAddress" name="address" type="text">
            </div>
            <div class="col-6">
                <label class="form-label">&nbsp;</label>
                <input [placeholder]="translate('CMPADM.Adresse (fortsat)')" class="form-control" formControlName="deliveryAddress2" name="address2"
                       type="text">
            </div>
        </div>

        <div *ngIf="!deliveryAddressSameAsBillingAddress" class="row mb-3">
            <div class="col-sm">
                <label class="form-label" translate="CMPADM.Postnr"></label>
                <input [placeholder]="translate('CMPADM.Postnr')"
                       class="form-control"
                       formControlName="deliveryPostcode"
                       type="text">
            </div>
            <div class="col-sm">
                <label class="form-label" translate="CMPADM.By"></label>
                <input [placeholder]="translate('CMPADM.By')" class="form-control" formControlName="deliveryCity" type="text">
            </div>
        </div>

        <div>
            <button class="btn btn-primary float-end" type="submit">
                <span translate="UTILITY.SAVE"></span>&nbsp;<fa-icon [icon]="faSave"></fa-icon>
            </button>
        </div>
    </form>
</div>

<ng-template #addUserModal>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" translate="CMPADM.Tilføj bruger til firma"></h5>
            <button (click)="childModalRef.hide()" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">

            <label class="form-label" for="userName" translate="CMPADM.Indtast navn"></label>
            <div class="input-group mb-3">
                <input [(ngModel)]="newUserName" class="form-control" id="userName" name="userName" type="email">
            </div>
            <label class="form-label" for="userEmail" translate="CMPADM.Indtast email"></label>
            <div class="input-group">
                <input [(ngModel)]="newUserEmail" class="form-control" id="userEmail" name="userEmail" type="email">
            </div>

        </div>
        <div class="modal-footer">
            <button (click)="childModalRef.hide()" class="btn btn-secondary" data-dismiss="modal" translate="UTILITY.CANCEL" type="button"></button>
            <button (click)="addCompanyUser()" class="btn btn-success" translate="UTILITY.SAVE" type="button"></button>
        </div>
    </div>
</ng-template>

<ng-template #companyUsers>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" translate="CMPADM.Firma Brugere"></h5>
            <button (click)="modalRef.hide()" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-sm text-end">
                    <button (click)="childModalRef = modalService.show(addUserModal)" class="btn btn-success">
                        <fa-icon [icon]="faPlus"></fa-icon>&nbsp;
                        <span translate="CMPADM.Opret ny bruger"></span>
                    </button>
                    <br>
                </div>
            </div>
            <div class="row pt-2">
                <div class="col-sm">
                    <table class="table">
                        <thead>
                        <th translate="CMPADM.Email"></th>
                        <th translate="CMPADM.Navn"></th>
                        <th translate="CMPADM.Sidst logget på"></th>
                        <th></th>
                        </thead>
                        <tbody>
                        <tr *ngFor="let user of companyUserList">
                            <td class="align-middle">
                                <ng-container *ngIf="user && user.isCompanyAdmin === true">
                                    <fa-icon [icon]="faStar" title="Brugeren er administrator"></fa-icon>&nbsp;&nbsp;
                                </ng-container>
                                {{user.email}}
                            </td>
                            <td class="align-middle">{{user.name}}</td>
                            <td class="align-middle">{{user.lastLogin | date: 'HH:mm:ss - dd-MM-yyyy'}}</td>
                            <td class="align-middle text-end">
                                <button #pop="bs-popover"
                                        (click)="openPopover(pop)"
                                        (onHidden)="adminID = null;"
                                        (onShown)="adminID = user.id;"
                                        *ngIf="user && user.isCompanyAdmin === false"
                                        [outsideClick]="true"
                                        [popover]="confirmUserAdmin"
                                        class="btn btn-primary btn-sm"
                                        container="body">
                                    <span translate="CMPADM.Opgrader til admin"></span>&nbsp;
                                    <fa-icon [icon]="faArrowUp"></fa-icon>
                                </button>
                                &nbsp;&nbsp;
                                <button #pop="bs-popover" (click)="openPopover(pop)" (onHidden)="adminID = null" (onShown)="adminID = user.id"
                                        [outsideClick]="true" [popover]="confirmDeleteUser" class="btn btn-danger btn-sm" container="body"
                                        type="button">
                                    <span translate="CMPADM.Slet bruger"></span>
                                    &nbsp;<fa-icon [icon]="faTrash"></fa-icon>
                                </button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>

        </div>
        <div class="modal-footer">
        </div>
    </div>
</ng-template>

<ng-template #confirmUserAdmin>
    <div class="text-center">
        <div class="btn-group" role="group">
            <button (click)="setUserAsCompanyAdmin()" class="btn btn-sm btn-success" translate="CMPADM.Opgrader"></button>
        </div>
    </div>
</ng-template>
<ng-template #confirmDeleteUser>
    <div class="text-center">
        <div class="btn-group" role="group">
            {{ 'ConfirmDeleteUser' | translate }}
            &nbsp;&nbsp;
            <button (click)="deleteUser()" class="btn btn-sm btn-danger">{{ 'Slet' | translate }}</button>
            <button (click)="closePopover()" class="btn btn-sm btn-secondary">{{ 'Nej' | translate }}</button>
        </div>
    </div>
</ng-template>
