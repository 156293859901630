import { TranslateService } from '@ngx-translate/core';

import { PriceCalculatorService, DrawerOptionsService, HettichService } from '../';
import { CartItem } from './CartItem';
import { CartItemType, IAddCart, ISlacksDrawerOptions, ITypedAddCartItem } from '../../../../../wdcommon/ICartItem';
import { ICompanyAdjustments } from '../../../../../wdcommon/ICompany';


const runnerType = 'hettich_actro_5D';

export class SlacksDrawerCartItem extends CartItem {

  options: ISlacksDrawerOptions;

  constructor(
      private translateService: TranslateService,
      private priceService: PriceCalculatorService,
      private companyAdjustmentsProvider: () => Promise<ICompanyAdjustments>,
      private optionsService: DrawerOptionsService,
      private hettichService: HettichService,
      slacksDrawer: ITypedAddCartItem,
  ) {
    super(translateService, slacksDrawer);
    this.options = slacksDrawer.options as ISlacksDrawerOptions;
  }

  public async generateItems(): Promise<IAddCart> {
    const generatedItems: IAddCart = [];

    await this.generatePullOutItem(this.options, generatedItems);
    await this.generateCoupling(this.options, generatedItems);

    return generatedItems;
  }

  protected async calculatePrice(): Promise<number> {
    const price = await this.priceService.CalculateOthers(this as any, await this.companyAdjustmentsProvider());
    if (typeof price === 'number') {
      return price;
    }

    console.error(`Unable to calculate price for slacks drawer ${this.itemno} got: ${price}.`);
    return 0;
  }

  private async generatePullOutItem(options: ISlacksDrawerOptions, generatedItems: IAddCart) {
    if (options.coupling) {
      const runnerItemNo = await this.optionsService.getRunnerProductNumber(
          runnerType,
          550,
          40
      );
      if (!runnerItemNo) {
        console.error('Unable to generate runner', options);
        return;
      }

      const hettichBrandId = await this.hettichService.getHettichBrandId();

      const runnerItem: ITypedAddCartItem = {
        brandId: hettichBrandId,
        type: CartItemType.runner,
        name: this.translate('UDTRAEK.' + runnerType),
        itemno: runnerItemNo,
        amount: this.amount
      };

      generatedItems.push(runnerItem);
    }
  }

  private async generateCoupling(options: ISlacksDrawerOptions, generatedItems: IAddCart): Promise<void> {
    if (!options.coupling) {
      return;
    }

    const couplingItemNo = await this.optionsService.getRunnerProductNumber('koblinger_' + runnerType);

    const hettichBrandId = await this.hettichService.getHettichBrandId();

    const couplingItem: ITypedAddCartItem = {
      brandId: hettichBrandId,
      type: CartItemType.other,
      name: this.translate('CARTDETAILS.kobling'),
      description: this.translate('tilEgennavn', {egennavn: this.translate('UDTRAEK.' + runnerType)}),
      itemno: couplingItemNo,
      amount: this.amount
    };
    generatedItems.push(couplingItem);
  }
}
