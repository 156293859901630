import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { productsUrl, specialsPrefix } from '../../utils/breadcrumbs/breadcrumbs';
import { Manufacturer } from '../../../../../wdcommon/IProduct';


@Component({
  selector: 'app-admin-navbar',
  templateUrl: './admin-navbar.component.html',
  styleUrls: ['./admin-navbar.component.css'],
})
export class AdminNavbarComponent {
  productsUrl = productsUrl;
  specialsPrefix = specialsPrefix;
  Manufacturer = Manufacturer;

  constructor(private router: Router) { }

  public isActiveRoute(route: string, exactMatch?: boolean) {
    if (exactMatch)
      return route === this.router.url;

    return this.router.url.indexOf(route) > -1;
  }
}
