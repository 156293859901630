import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  public getSupportedLanguages(): Locale[] {
    return [
      { id: 'da', name: 'Dansk' },
      { id: 'en', name: 'English' },
      { id: 'sv', name: 'Svenska' },
      { id: 'no', name: 'Norsk' }
    ];
  }

  public getLanguage(): string {
    return localStorage.language || 'da';
  }

  public setLanguage(lang: string) {
    localStorage.setItem('language', lang);
  }

  public getLanguageById(id: string): Locale {
    return this.getSupportedLanguages().find((l) => l.id === id);
  }

}

export class Locale {
  id: string;
  name: string;
}
