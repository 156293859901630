import { Injectable } from '@angular/core';
import { FormRow } from '../dynamicForm/dynaform.interfaces';
import * as ernstIls from '../models/dynamic-form-models/ernst-ils';
import * as ernstInnenlade from '../models/dynamic-form-models/ernst-innenlade';
import * as ernstRearEzug from '../models/dynamic-form-models/ernst-rear-ezug';
import * as ernstRevEzug from '../models/dynamic-form-models/ernst-rev-ezug';
import * as ernstSchutte from '../models/dynamic-form-models/ernst-schutte';
import * as ernstTypeBModel from '../models/dynamic-form-models/ernst-type-b';
import * as purewoodIls from '../models/dynamic-form-models/purewood-ils';
import * as purewoodRevEzug from '../models/dynamic-form-models/purewood-rev-ezug';
import * as purewoodInnenlade from '../models/dynamic-form-models/purewood-innenlade';
import * as purewoodTypeBModel from '../models/dynamic-form-models/purewood-type-b';
import * as ezugModel from '../models/dynamic-form-models/ezug';
import * as innenladeFront from '../models/dynamic-form-models/ils';
import * as innenladeModel from '../models/dynamic-form-models/innenlade';
import * as innenladeDesignCurve from '../models/dynamic-form-models/innenlade_designCurve';
import * as innenladeGlasModel from '../models/dynamic-form-models/innenlade_glas';
import * as revEzugModel from '../models/dynamic-form-models/rev-ezug';
import * as schutteModel from '../models/dynamic-form-models/schutte';
import * as sortissimo from '../models/dynamic-form-models/sortissimo';
import * as solidOrganiser from '../models/dynamic-form-models/solidOrganiser';
import * as trashcanDrawer from '../models/dynamic-form-models/trashcanDrawer';
import * as sortissimoFastline from '../models/dynamic-form-models/sortissimoFastline';
import * as slacksDrawer from '../models/dynamic-form-models/slacksDrawer';
import * as carcassBoardModel from '../models/dynamic-form-models/cabinet-carcass-board';
import * as carcassCupboardModel from '../models/dynamic-form-models/cabinet-carcass-cupboard';
import * as carcassBaseUnitModel from '../models/dynamic-form-models/cabinet-carcass-base-unit';
import * as carcassWallUnitModel from '../models/dynamic-form-models/cabinet-carcass-wall-unit';
import * as careSetModel from '../models/dynamic-form-models/care-set';
import * as typeBModel from '../models/dynamic-form-models/type-b';
import { OtherProductShortName } from '../../../../wdcommon/IProduct';


const modelMapper = {
  'type-b': typeBModel.MODEL,
  'innenlade': innenladeModel.MODEL,
  'schutte': schutteModel.MODEL,
  'ezug': ezugModel.MODEL,
  'ezug_trend': ezugModel.MODEL,
  'rev_ezug': revEzugModel.MODEL,
  'innenlade_glas': innenladeGlasModel.MODEL,
  'innenlade_glas_not': innenladeGlasModel.MODEL,
  'innenlade_glas_20mm': innenladeGlasModel.MODEL,
  'innenlade_glas_20mm_not': innenladeGlasModel.MODEL,
  'innenlade_designfront': innenladeGlasModel.MODEL,
  'innenlade_designcurve': innenladeDesignCurve.MODEL,
  'design_curve': innenladeDesignCurve.MODEL,
  'ils': innenladeFront.MODEL,

  'ernst-mair-type-b': ernstTypeBModel.MODEL,
  'ernst-mair-innenlade': ernstInnenlade.MODEL,
  'ernst-mair-schutte': ernstSchutte.MODEL,
  'ernst-mair-rear-ezug': ernstRearEzug.MODEL,
  'ernst-mair-rev-ezug': ernstRevEzug.MODEL,

  'purewood-ils': purewoodIls.MODEL,
  'purewood-innenlade': purewoodInnenlade.MODEL,
  'purewood-type-b': purewoodTypeBModel.MODEL,
  'purewood-rev-ezug': purewoodRevEzug.MODEL,

  'sortissimo': sortissimo.MODEL,
  [OtherProductShortName.solidOrganiser]: solidOrganiser.MODEL,
  [OtherProductShortName.trashcanDrawer]: trashcanDrawer.MODEL,
  [OtherProductShortName.sortissimoFastline]: sortissimoFastline.MODEL,
  [OtherProductShortName.slacksDrawer]: slacksDrawer.MODEL,
  [OtherProductShortName.board]: carcassBoardModel.MODEL,
  [OtherProductShortName.cupboard]: carcassCupboardModel.MODEL,
  [OtherProductShortName.baseUnit]: carcassBaseUnitModel.MODEL,
  [OtherProductShortName.wallUnit]: carcassWallUnitModel.MODEL,
  [OtherProductShortName.careSet]: careSetModel.MODEL,
  'ernst-mair-ils': ernstIls.MODEL,
};

@Injectable({
  providedIn: 'root'
})
export class ModelLoaderService {

  constructor() {
  }

  load(modelName: string | OtherProductShortName): FormRow[] {
    return modelMapper[modelName];
  }
}
