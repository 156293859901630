import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faSave } from '@fortawesome/free-solid-svg-icons/faSave';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { TranslateService } from '@ngx-translate/core';
import { WizardComponent } from 'angular-archwizard';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, combineLatest, concat, firstValueFrom, isObservable } from 'rxjs';

import {
  ICartItem,
  ICutleryTrayOptions,
  IDrawerAddCartItem,
  ILogoOptions,
  IRearUdsparingOptions,
  ISiphonCuttingOptions
} from '../../../../wdcommon/ICartItem';
import { IDrawerHistory, IDrawerOptions, IDrawerType, SlideListOptionValue } from '../../../../wdcommon/IDrawer';
import { IOption, Manufacturer, OptionProperty, OptionThickness, OptionType } from '../../../../wdcommon/IProduct';
import { SiphonCuttingValues } from '../../../../wdcommon/SiphonCutting';
import { TranslatingBase } from '../base-component/ComponentBase';
import { FieldConfig, FormRow, SelectOption } from '../dynamicForm/dynaform.interfaces';
import { DynamicFormComponent } from '../dynamicForm/dynamic-form/dynamic-form.component';
import { OrderForm } from '../models/orderform.interface';
import { SortissimoComponent } from '../nothegger/sortissimo/sortissimo.component';
import {
  DisplayErnstMairRearEzugComponent
} from '../order/ernst-mair-display/display-ernst-mair-rear-ezug/display-ernst-mair-rear-ezug.component';
import {
  DisplayErnstMairRevEzugComponent
} from '../order/ernst-mair-display/display-ernst-mair-rev-ezug/display-ernst-mair-rev-ezug.component';
import { LogoAddComponent } from '../order/logo-add/logo-add.component';
import { PurewoodRevEzugDisplayComponent } from '../order/purewood-rev-ezug-display/purewood-rev-ezug-display.component';
import { RearUdsparingComponent } from '../order/rear-udsparing/rear-udsparing.component';
import { RevEzugDisplayComponent } from '../order/rev-ezug-display/rev-ezug-display.component';
import { SiphonCuttingComponent } from '../order/udsparing/siphon-cutting.component';
import { RootModalComponent } from '../root-modal/root-modal.component';
import {
  APIService,
  CartService,
  DrawerOptionsService,
  LocaleService,
  ModelLoaderService,
  NamingService,
  SessionService,
  UserService,
  UtilitiesService
} from '../services';
import { Breadcrumb, defaultBreadcrumbs, productsUrl } from '../utils/breadcrumbs/breadcrumbs';
import { coalesce, disableControl, enableControl, isTrue, setValue, toNumber } from './dynamic-order-tools';


const BestikindsatsDefaults: Readonly<ICutleryTrayOptions> = {
  type: null,
  [OptionProperty.width]: null,
  [OptionProperty.height]: null,
  [OptionProperty.depth]: null,
  [OptionProperty.typeOfWood]: null,
  [OptionProperty.surfaceTreatment]: null,
  special: false,
  bundLeveret: false
};

const DesignCurveSkuffeBreddeToFrontBreddeAdjustment = 18;
const RevEzugFrontHoejde = 17;

const Actro5Dkg40 = [250, 270, 300, 320, 350, 380, 400, 420, 450, 480, 500, 520, 550, 600];
const Actro5Dkg70 = [450, 500, 520, 550, 580, 600, 650, 700, 750];

const HeightsDefault = [55, 75, 95, 115, 135, 155, 175, 195, 215, 235, 255, 275, 295, 315, 335, 355, 375, 395];

// Ernst mair defaults:
const ErnstMairHeights = [52, 68, 84, 100, 116, 132, 148, 164, 180, 196, 212, 228, 244, 260, 276, 292, 308, 324, 340, 356, 372, 388, 404, 420, 436, 452, 468, 484];
const ErnstMairBottoms = ['6', '8', '12'];

const ExcludeNotheggerBottoms = ['6', '8'];


const HeightsSamlingSvaleOrFordaekt = [58, 84, 110, 136, 162, 188, 214, 240, 266, 292];
const MinimumRevEZugHeight = 115;
const MinimumErnstMairEzugHeights = 116;

const pushSystemUnavailableDepths = [280];
const PurewoodIlsDrawerDepth = 30;

@Component({
  selector: 'app-dynamic-order',
  templateUrl: './dynamic-order.component.html',
  styleUrls: ['./dynamic-order.component.css'],
})
export class DynamicOrderComponent extends TranslatingBase implements OnInit, AfterViewInit {
  @BlockUI('blockOrder') blockOrder: NgBlockUI;
  @BlockUI('blockSubmitButton') blockSubmit: NgBlockUI;

  @ViewChild(DynamicFormComponent) form: DynamicFormComponent;

  @ViewChild(SiphonCuttingComponent) udspComp: SiphonCuttingComponent;
  @ViewChild(RearUdsparingComponent) rearUdspComp: RearUdsparingComponent;
  @ViewChild('udspModal') udspModal: RootModalComponent;
  @ViewChild('wizard') wizard: WizardComponent;

  @ViewChild('bestikModal') bestikModal: RootModalComponent;
  @ViewChild(SortissimoComponent) bscomp: SortissimoComponent;

  @ViewChild(PurewoodRevEzugDisplayComponent) purewoodRevEzugMaal: PurewoodRevEzugDisplayComponent;
  @ViewChild(RevEzugDisplayComponent) revEzugMaal: RevEzugDisplayComponent;
  @ViewChild(RevEzugDisplayComponent) revEzugHoejder: RevEzugDisplayComponent;

  @ViewChild(DisplayErnstMairRearEzugComponent) ernstRearEzugMaal: DisplayErnstMairRearEzugComponent;
  @ViewChild(DisplayErnstMairRevEzugComponent) ernstRevEzugMaal: DisplayErnstMairRevEzugComponent;

  @ViewChild('pop') pop: PopoverDirective;

  modalRef: BsModalRef;
  dynamicBreadcrumb: Breadcrumb;
  notheggerBreadcrumb = defaultBreadcrumbs.nothegger;
  purewoodDrawersBreadcrumb = defaultBreadcrumbs.purewoodDrawers;
  ernstMairBreadcrumb = defaultBreadcrumbs.ernstMair;

  checkboxForm: UntypedFormGroup;
  ezugLocked: boolean;

  myValidator: Function;

  isNothegger: boolean;
  isErnstMair: boolean;
  isPurewood: boolean;
  manufacturer: Manufacturer;
  innenladeFrontHoejde: number;

  drawerType: string;
  overrideOptions: IDrawerOptions;
  drawer: IDrawerType
    & {
      tilknyttetUdtraek?: any,
      tilknyttetKobling?: any,
      tilknyttetJustering?: any,
      tilknyttetPTO?: any,
    } = {
      type: '',
      name: '',
      description: '',
      img: '',
      special: false,
      options: {},
      enabled: true,
      id: null
    };
  model: FormRow[] = [];
  udtrDybder;
  ilsDybder;
  orderOptionsToApprove: IDrawerOptions;

  drawerOptions: IOption[];
  treesortsFor20mm: IOption[] = [];
  cabinetTreesorts: IOption[] = [];
  superiorTreesorts: IOption[] = [];
  yachtTreesorts: IOption[] = [];
  runnerTypeOptions: IOption[] = [];

  allSurfaces: IOption[] = [];

  greb: string = null;
  udsparing: ISiphonCuttingOptions;
  rearUdsparing: IRearUdsparingOptions;
  bestikindsats: ICutleryTrayOptions = null;
  logo: ILogoOptions = null;

  bsTree: { label: string, value: string; }[];
  bsHeights: { label: string, value: number; }[];
  bsSurface: { label: string, value: string; }[];

  siphonCuttingDefaults: SiphonCuttingValues = {
    ab: 0,
    bc: 150,
    cd: 200,
    ef: 0,
    width: 0,
    depth: 0,
    existing: false,
    special: false,
  };

  rearUdsparingDefaults = {
    ab: 70,
    bc: 10,
    cd: 150,
    de: 200,
    fg: 10,
    gh: 70,
    width: 0,
    depth: 0,
    existing: false,
    special: false,
  };

  // checkbox vars
  boringShow = false;
  boringCutleryTrayShow = false;
  skuffe20mmFrontShow = false;
  ligeOverkantShow = false;
  fscShow = false;
  surfaceTreatmentUndersideShow = false;
  drawerBaseMountedShow = false;
  showBottomUnmounted = false;
  boerstetShow = false;
  grebShow = false;
  udsparingShow = false;
  bestikindsatsShow = false;
  logoShow = false;
  hulboringShow = false;

  runnerDepthToDrawerDepthAdjustment = new BehaviorSubject(0); // Drawer type specific setting?
  drawerOuterToInnerWidthAdjustment = new BehaviorSubject(0); // Drawer type specific setting
  afstandslistToSkuffeBreddeAdjustment = new BehaviorSubject(0); // Shims factor...
  slideListToDrawerWidthAdjustment = new BehaviorSubject(0); // Slide lists may withdraw from the actual drawer width (+6mm x1 or x2)

  skuffeBredde = new BehaviorSubject(-1);
  skuffeDybde = new BehaviorSubject(-1);
  frontHoejde = new BehaviorSubject(-1);
  frontBredde = new BehaviorSubject(-1);


  skuffeAntal = 1;
  cartItemComment: string;

  prettyOptions: { key: string, value: string; }[];

  isEdit = false;
  isRepeat = false;
  editItemno: string;

  editDrawer: ICartItem & { options: IDrawerOptions; };

  approveBtnText = 'Godkend';

  repeatDrawer: IDrawerHistory;

  activeCutleryTrayToast: ActiveToast<any>;

  protected readonly faArrowLeft = faArrowLeft;
  protected readonly faArrowRight = faArrowRight;
  protected readonly faCheck = faCheck;
  protected readonly faEdit = faEdit;
  protected readonly faPlus = faPlus;
  protected readonly faSave = faSave;
  protected readonly faTimes = faTimes;
  protected readonly faTrash = faTrash;
  protected readonly OptionProperty = OptionProperty;

  constructor(
    private apiService: APIService,
    private session: SessionService,
    private modelLoader: ModelLoaderService,
    private toastrService: ToastrService,
    private utilities: UtilitiesService,
    private modalService: BsModalService,
    private namingService: NamingService,
    private cartService: CartService,
    private router: Router,
    private route: ActivatedRoute,
    public optService: DrawerOptionsService,
    public userService: UserService,
    translateService: TranslateService,
    private localeService: LocaleService) {
    super(translateService);
  }

  async ngOnInit() {
    this.blockOrder.start(this.translate('Henter bestillings form'));
    if (this.route.snapshot.routeConfig.path.indexOf('repeat') > -1) {

      this.isRepeat = true;
      this.repeatDrawer = JSON.parse(this.session.getValue('repeatDrawer'));

      this.drawerType = this.route.snapshot.params.type;
      this.repeatDrawer.contents.type = this.drawerType;

      // Innenlade with a boring front is not possible
      if (this.drawerType === 'innenlade') {
        delete this.repeatDrawer.contents.boringFront;
      }

      this.overrideOptions = this.repeatDrawer.contents as IDrawerOptions;

    } else if (this.route.snapshot.routeConfig.path.indexOf('edit') > -1 && this.route.snapshot.params.itemno) {

      this.isEdit = true;
      this.approveBtnText = 'Gem rettelser';
      this.editItemno = this.route.snapshot.params.itemno;
      this.editDrawer = this.cartService.findByItemNo(this.editItemno) as ICartItem & { options: IDrawerOptions; };
      this.cartItemComment = this.editDrawer.comments;

      if (!this.editDrawer) {
        this.blockOrder.stop();
        await this.goto('/');
        return;
      }

      this.drawerType = this.editDrawer.options.type;
      this.overrideOptions = this.editDrawer.options;

      this.skuffeAntal = this.editDrawer.amount;

    } else {

      this.drawerType = this.route.snapshot.params.type;

    }

    if (!this.drawerType) {
      this.blockOrder.stop();
      await this.goto('/');
      return;
    }
    this.isErnstMair = this.drawerType.indexOf(Manufacturer.ernstMair) > -1;
    if (this.isErnstMair && !this.userService.isAllowedErnstMair()) {
      this.blockOrder.stop();
      await this.goto('/');
      return;
    }

    this.isPurewood = this.drawerType.indexOf(Manufacturer.purewood) > -1;
    if (this.isPurewood) {
      if (!this.userService.isAllowedPurewood()) {
        this.blockOrder.stop();
        await this.goto('/');
        return;
      }
      this.innenladeFrontHoejde = 15;
    } else {
      this.innenladeFrontHoejde = 17;
    }

    this.isNothegger = !this.isErnstMair && !this.isPurewood;
    this.manufacturer = this.isNothegger ? Manufacturer.nothegger : (this.isErnstMair ? Manufacturer.ernstMair : Manufacturer.purewood);

    // Fix checkboxes
    this.createCheckboxes();
    this.model = this.modelLoader.load(this.drawerType);

    // Validator for the combination of bestikindsats, greb, height and bottom.
    this.myValidator = (): { [s: string]: boolean; } => {
      return (this.bestikindsats && !this.checkIfCutleryTrayIsValid()) ? { invalid_indsats_combi: true } : null;
    };
  }

  async ngAfterViewInit() {
    if (this.isErnstMair) {
      this.drawer = await (await this.apiService.getErnstMairs(this.localeService.getLanguage())).find(d => d.type === this.drawerType) as any;
      this.dynamicBreadcrumb = {
        nameId: 'DRAWER_NAMES.' + this.drawerType,
        parentId: Manufacturer.ernstMair,
        url: this.ernstMairBreadcrumb + '/' + this.drawerType
      };
    } else if (this.isPurewood) {
      const purewoodDrawer = await this.apiService.getPurewoodDrawer(this.drawerType);
      this.drawer = {
        enabled: true,
        type: this.drawerType,
        options: {},
        id: purewoodDrawer.id,
        name: `DRAWER_NAMES.purewood-${purewoodDrawer.shortname}`,
        description: `DRAWER_DESC.purewood-${purewoodDrawer.shortname}`,
        img: `/assets/images/drawers/purewood/purewood-${purewoodDrawer.shortname}.png`,
        special: false
      };

      if (this.drawerType === 'purewood-ils') {
        this.drawer.options.skuffeDybde = PurewoodIlsDrawerDepth;
      }

      this.dynamicBreadcrumb = {
        nameId: 'DRAWER_NAMES.' + this.drawerType,
        parentId: 'purewoodDrawers',
        url: this.purewoodDrawersBreadcrumb + '/' + this.drawerType
      };
    } else {
      this.drawer = await this.optService.getDrawer(this.drawerType);
      this.dynamicBreadcrumb = {
        nameId: 'DRAWER_NAMES.' + this.drawerType,
        parentId: Manufacturer.nothegger,
        url: this.notheggerBreadcrumb + '/' + this.drawerType
      };
    }

    this.drawerOptions = await this.apiService.getProductOptions();
    this.loadDrawerOptions();
    this.udtrDybder = await firstValueFrom(this.optService.getUdtrDybder());
    this.ilsDybder = await firstValueFrom(this.optService.getIlsDybder());

    // load template content
    // this.form.loadTemplates();

    const configRow = this.model.find(r => r.id === 'config');
    this.setCheckboxesVisible(configRow.config);

    this.bindChangeHandlers();

    if (this.isEdit || this.isRepeat) {
      // Filling the form twice to ensure that the form is filled with the correct values after some of the fields dynamically change.
      // This is not a optimal solution, but the task requires a quick fix.
      // Task id: 2870 Fejl ved rettelse af ordre
      this.fillForm();
      this.fillForm();
      this.form.markAsTouched();
    } else {
      this.resetOrder(false);
    }

    // Add validation of the combination of bestikindsats, greb, height and bottom.
    if (this.bestikindsatsShow) {
      this.addBestikindsatsValidations();
    }

    this.blockOrder.stop();
  }

  addBestikindsatsValidations() {
    const validHoejdeList = [];
    const validBundList = [];
    const heightValidations = this.form.rows.find((r) => r.fields && r.fields.find((f) => f.name === OptionProperty.height))
      .fields.find((f) => f.name === OptionProperty.height)
      .validations || [];
    const drawerBaseValidations = this.form.rows.find((r) => r.fields && r.fields.find((f) => f.name === OptionProperty.drawerBase))
      .fields.find((f) => f.name === OptionProperty.drawerBase)
      .validations || [];
    if (!heightValidations.some(v => v.name === 'invalid_indsats_combi')) {
      heightValidations.push({
        name: 'invalid_indsats_combi',
        validator: this.myValidator,
        message: 'BestikIndsatsCombiInvalid'
      });
    }
    heightValidations.forEach(valid => {
      validHoejdeList.push(valid.validator);
    });
    validHoejdeList.push(this.myValidator);
    this.form.getControl(OptionProperty.height).setValidators(Validators.compose(validHoejdeList));

    if (!drawerBaseValidations.some(v => v.name === 'invalid_indsats_combi')) {
      drawerBaseValidations.push({
        name: 'invalid_indsats_combi',
        validator: this.myValidator,
        message: 'BestikIndsatsCombiInvalid'
      });
    }
    drawerBaseValidations.forEach(valid => {
      validBundList.push(valid.validator);
    });
    validBundList.push(this.myValidator);
    this.form.getControl(OptionProperty.drawerBase).setValidators(Validators.compose(validBundList));
  }

  setCheckboxValue(name: string, value: boolean) {
    const cb = this.checkboxForm.controls[name];
    if (cb) {
      cb.setValue(value === true ? value : undefined);
    }
  }

  async submit(formData: OrderForm) {
    this.blockSubmit.start();

    if (!this.form.valid || !this.form.touched)
      return;

    if (this.bestikindsatsShow && this.bestikindsats) {
      if (!this.bscomp.isSortissimoDepthValid()) {
        this.blockSubmit.stop();
        return this.toastrService.error(this.translate('Order.Bestikindsats.NotAvailable'));
      } else if (!this.bscomp.isSortissimoWidthValid()) {
        this.blockSubmit.stop();
        return this.toastrService.error(this.translate('Order.Bestikindsats.WidthUnavailable'));
      }
    }

    if (!this.userService.isLoggedIn && isTrue([formData[OptionProperty.runnerMark], 'contains', 'hettich'])) {
      this.blockSubmit.stop();
      return this.toastrService.error(this.translate('ORDERS.LoginForHettich'));
    }

    const getCheckboxValue = (name: string, defaultValue: boolean = undefined) => {
      const cb = this.checkboxForm.controls[name];
      if (cb && typeof (cb.value) === 'boolean') {
        if (cb.value === true)
          return cb.value;
      }

      return defaultValue;
    };


    const getValue = <TIn, TOut>(ctrlName: string, selector: (value: TIn) => TOut = undefined, defaultValue: TOut = undefined) => {
      const field = this.form.getField(ctrlName);
      if (!field || !field.show || !this.form.getRowForField(field).show)
        return defaultValue;

      const value = formData[ctrlName];
      if (value === undefined)
        return defaultValue;

      if (selector)
        return selector(value);

      return value;
    };

    const minus1ToUndefined = v => v === -1 ? undefined : v;

    console.info('Submitting dynamic order', {
      rawFormData: formData,
      stringifiedFormData: JSON.stringify(formData)
    });

    const getSkuffeBredde = () => {
      const value = minus1ToUndefined(this.skuffeBredde.value);

      if (value === undefined) {
        console.warn(`SkuffeBredde was undefined for ${this.drawerType}.`);

        if (this.isDrawerType('innenlade', 'ils'))
          throw new Error('SkuffeBredde is not set');
      }

      return value;
    }

    const orderOptions: IDrawerOptions = {
      [OptionProperty.type]: this.drawer.type,
      [OptionProperty.woodQuality]: formData[OptionProperty.woodQuality],
      [OptionProperty.typeOfWood]: formData[OptionProperty.typeOfWood],
      [OptionProperty.joint]: formData[OptionProperty.joint],
      [OptionProperty.surfaceTreatment]: formData[OptionProperty.surfaceTreatment],
      [OptionProperty.surfaceTreatmentUnderside]: getCheckboxValue('surfaceTreatmentUndersideBox'),

      [OptionProperty.runnerMark]: formData[OptionProperty.runnerMark],
      [OptionProperty.runnerType]: getValue(OptionProperty.runnerType),
      udtraekLeveret: getValue('udtrLeveret', v => v === 'ja', false),
      udtraekDybdeJustering: getValue('udtrDybdeJustering', v => v === 'ja'),
      [OptionProperty.runnerPushToOpenSilent]: getValue(OptionProperty.runnerPushToOpenSilent, v => v === 'ja'),
      [OptionProperty.runnerLoad]: getValue(OptionProperty.runnerLoad, v => toNumber(v)),
      [OptionProperty.synchronisationBar]: getValue(OptionProperty.synchronisationBar, v => v === 'ja'),

      [OptionProperty.runnerDepth]: minus1ToUndefined(toNumber(formData[OptionProperty.runnerDepth]) || toNumber(formData[OptionProperty.runnerDepthSelect])),
      [OptionProperty.drawerOuterWidth]: minus1ToUndefined(toNumber(formData[OptionProperty.drawerOuterWidth])),
      frontHoejde: minus1ToUndefined(this.frontHoejde.value),
      frontBredde: minus1ToUndefined(this.frontBredde.value),
      skuffeHoejde: minus1ToUndefined(toNumber(formData.height)),
      skuffeBredde: getSkuffeBredde(),
      skuffeDybde: minus1ToUndefined(this.skuffeDybde.value),

      [OptionProperty.premountedCoupling]: getValue(OptionProperty.premountedCoupling, (v) => v === 'ja', false),
      [OptionProperty.couplingAlongside]: getValue(OptionProperty.couplingAlongside, (v) => v === 'ja'),

      [OptionProperty.thickness]: getValue(OptionProperty.thickness),

      [OptionProperty.shimsSelection]: getValue(OptionProperty.shimsSelection),
      [OptionProperty.slideList]: getValue(OptionProperty.slideList),
      [OptionProperty.drawerBase]: getValue(OptionProperty.drawerBase),
      udsparing: coalesce(this.udsparing ? this.udsparing : this.rearUdsparing ? this.rearUdsparing : null),
      bestikindsats: this.bestikindsats ? Object.assign(BestikindsatsDefaults, this.bestikindsats) : undefined,
      logo: coalesce(this.logo),
      greb: coalesce(this.greb),
      reverseEnglisherZug: this.getReverseEnglisherZugOrderOption(),
      rearEnglisherZug: coalesce(this.ernstRearEzugMaal ? this.ernstRearEzugMaal.rearEzugMaal : null),
      boringFront: getCheckboxValue('boringFrontBox'),
      boringCutleryTray: getCheckboxValue('boringCutleryTrayBox'),
      skuffe20mmFront: getCheckboxValue('skuffe20mmFrontBox'),
      hulboring: getCheckboxValue('hulboringBox'),
      [OptionProperty.bottomUnmounted]: getCheckboxValue('bottomUnmountedCheckbox'),
      straightLine: getCheckboxValue('ligeOverkantBox'),
      // [FSC-disabled] FSC has been disabled for now (07/11/2023) it might be re-enabled in the future
      // previous value for [OptionProperty.fscCertified]: getCheckboxValue('fscBox'),
      [OptionProperty.fscCertified]: undefined,
      boerstet: getCheckboxValue('boerstetBox'),
      bundSkruet: getCheckboxValue('bundSkruetBox'),
      isUpPrice: getCheckboxValue('upPricesBox')
    };

    if (!orderOptions.udtraekLeveret) {
      delete orderOptions.udtraekDybdeJustering;
      delete orderOptions[OptionProperty.runnerPushToOpenSilent];
      delete orderOptions[OptionProperty.synchronisationBar];
    }

    Object.keys(orderOptions).forEach(key => {
      if (orderOptions[key] === undefined)
        delete orderOptions[key];
    });

    Object.entries(this.drawer.options || {})
      .forEach(([key, value]) => {
        if (!value) {
          return;
        }
        if (orderOptions[key]) {
          return;
        }

        orderOptions[key] = value;
      });

    if (this.isDrawerType('rev_ezug') || this.isDrawerType('purewood-rev-ezug')) {

      if (orderOptions.reverseEnglisherZug.ab < 50) {
        this.blockSubmit.stop();
        return this.toastrService.error(this.translate('Reverse Englisher Zug, A-B mål skal være minimum 50mm'));
      }

      if (orderOptions.reverseEnglisherZug.cd < 55 || orderOptions.reverseEnglisherZug.cd > 115) {
        this.blockSubmit.stop();
        return this.toastrService.error(this.translate('Reverse Englisher Zug, C-D mål er ugyldigt'));
      }

      const ezugHoejde = orderOptions.skuffeHoejde;

      // check om cd er < end højde
      if (orderOptions.reverseEnglisherZug.cd >= ezugHoejde) {
        this.blockSubmit.stop();
        return this.toastrService.error(this.translate('Reverse Englisher Zug, C-D skal være lavere end skuffens højde'), null, { timeOut: 8000 });
      }
    }

    // clear up alle ikke brugte controls
    const ctrls = this.form.getAllControls();

    Object.entries(ctrls).forEach(([key, valUnknown]) => {
      const valAny = valUnknown as any;
      if (valAny.disabled || valAny.value === null) {
        return delete formData[key];
      }
      if (key.indexOf(OptionProperty.runnerDepth) > -1) {
        const ctrl = this.form.getControl(key);
        if (this.drawer.type.indexOf('schutte') > -1) {
          formData['skuffedybde'] = valAny.value;
        } else if (this.drawer.type.indexOf('curve') > -1) {
          formData['skuffedybde'] = parseInt(valAny.value, 10) + this.runnerDepthToDrawerDepthAdjustment.value;
        } else {
          formData['skuffedybde'] = parseInt(valAny.value, 10) + this.runnerDepthToDrawerDepthAdjustment.value;
        }
        formData[key] = ctrl.value;
      } else if (key.indexOf('lysmaal') > -1) {
        if (this.drawer.type.indexOf('curve') > -1) {
          formData[OptionProperty.drawerOuterWidth] = parseInt(valAny.value, 10);
          formData['skuffebredde'] = parseInt(valAny.value, 10) + this.drawerOuterToInnerWidthAdjustment.value + DesignCurveSkuffeBreddeToFrontBreddeAdjustment;
          formData['frontBredde'] = parseInt(valAny.value, 10) + this.drawerOuterToInnerWidthAdjustment.value;
        } else {
          formData[key] = parseInt(valAny.value, 10) + this.drawerOuterToInnerWidthAdjustment.value;
        }
      }
    });

    this.orderOptionsToApprove = orderOptions;

    // fjern værdier vi ikke vil ha vist i oversigten
    const prettyOptions: Partial<IDrawerOptions> = {};
    Object.entries(orderOptions).forEach(([key, val]) => {
      if (!orderOptions.udtraekLeveret && isTrue([key, 'contains', OptionProperty.runnerMark])) {
        return;
      }

      if (this.isDrawerType('curve', 'schutte') && isTrue([key, 'contains', ['lysmaal', 'skinne']])) {
        return;
      }

      if (key === OptionProperty.typeOfWood && isTrue([val, 'contains', ['eg']]) && orderOptions[OptionProperty.fscCertified] === true) {
        prettyOptions[key] = [val, 'fsc'];
      } else {
        prettyOptions[key] = val;
      }
    });

    this.prettyOptions = await this.namingService.prettyNameList(prettyOptions);
    this.wizard.goToNextStep();
    this.blockSubmit.stop();
  }

  private getReverseEnglisherZugOrderOption() {
    const revEzugMaalOptions = [
      this.revEzugMaal,
      this.ernstRevEzugMaal,
      this.purewoodRevEzugMaal
    ];

    const foundOption = revEzugMaalOptions.find(option => option && option.revEzugMaal);

    return foundOption ? foundOption.revEzugMaal : undefined;
  }

  async approveOrder() {
    if (!this.cartService.checkCanAddToCartAndDisplayError()) {
      return;
    }

    this.blockOrder.start(this.translate('Godkender og indsender ordre'));
    const orderOpts = this.orderOptionsToApprove;

    const drawerDesc = this.getDrawerDescription(orderOpts);

    const hashObjects: IDrawerOptions & { antal?: any } = JSON.parse(JSON.stringify(orderOpts));
    delete hashObjects.antal;
    if (hashObjects.udsparing && hashObjects.udsparing.image) {
      delete hashObjects.udsparing.image;
    }

    const drawerHash = btoa(JSON.stringify(hashObjects));
    const vareNr: any = await this.apiService.getVarenr(drawerHash);

    const orderItem: IDrawerAddCartItem = {
      brandId: (this.isErnstMair ? Manufacturer.ernstMair : this.isPurewood ? Manufacturer.purewood : Manufacturer.nothegger),
      amount: this.skuffeAntal,
      comments: this.cartItemComment,
      description: drawerDesc,
      itemno: vareNr.varenr,
      name: this.drawer.name,
      options: orderOpts,
    };

    if (this.isEdit) {
      await this.cartService.removeItem(this.editItemno);
      await this.cartService.addDrawer(orderItem, this.editDrawer.index);

      this.toastrService.info(this.translate('Bestillingen er rettet!'));
      this.blockOrder.stop();
      await this.goto('cart');
      return;
    } else {
      await this.cartService.addDrawer(orderItem);

      const historyObj: IDrawerHistory = {
        description: orderItem.description,
        itemno: orderItem.itemno,
        contents: JSON.parse(JSON.stringify(orderItem.options))
      };
      if (historyObj.contents.udsparing) {
        delete historyObj.contents.udsparing.image;
      }

      if (this.userService.isLoggedIn) {
        await firstValueFrom(this.apiService.saveDrawerHistory(historyObj));
      }

      this.toastrService.info(this.translate('Bestilling tilføjet til kurv'));
      this.blockOrder.stop();
      await this.goto('/' + productsUrl + '/' + (this.isErnstMair ? Manufacturer.ernstMair : this.isPurewood ? Manufacturer.purewood + '/drawers' : Manufacturer.nothegger));
    }
  }

  bindChangeHandlers() {
    const whenNotNull = <T, R>(value: T | undefined | null, handler: (value: T) => R, defaultValue?: R | (() => R)) => {
      if (value)
        return handler(value);

      if (!defaultValue)
        return;

      if (defaultValue instanceof Function)
        return defaultValue();

      return defaultValue;
    };

    const toSelectOptions = this.utilities.arrayToObjectArray;

    const setOptions = (field: FieldConfig, options: IOption[]) => {
      const control = this.form.getControl(field.name);
      if (!control)
        throw new Error(`Got field named ${field.name} but can't find control.`);

      field.options = options;
    };

    const bindTargetFromSources = (
      targetFieldName: string,
      sourceIdentifiers: string[],
      handler: (
        properties: {
          fields: { [p: string]: FieldConfig | undefined; },
          controls: { [p: string]: AbstractControl | undefined; },
          values: { [p: string]: any; },
          enabled?: { [p: string]: boolean; },
          visible?: { [p: string]: boolean; },
          valid?: { [p: string]: boolean; };
        }) => void): void => {

      let targetField: FieldConfig;
      let targetControl: AbstractControl;
      if (targetFieldName) {
        targetField = this.form.getField(targetFieldName as string);
        targetControl = this.form.getControl(targetFieldName as string);
        if (!targetField && !targetControl)
          return;

        if (!targetField || !targetControl)
          throw new Error(`Unable to find target field or control names ${targetFieldName}. `);
      }

      const sources = sourceIdentifiers.map((sourceIdentifier) => {
        const source = (this as any)[sourceIdentifier];
        if (isObservable(source))
          return source;

        return this.form.getControl(sourceIdentifier as string) || this.checkboxForm.get(sourceIdentifier as string);
      });

      combineLatest(sources.map((source) => {
        if (source) {
          return concat(
            new Observable((o) => {
              o.next(undefined);
              o.complete();
            }),
            isObservable(source) ? source : (source as AbstractControl).valueChanges);
        }
        return new Observable((o) => o.next(undefined));
      }))
        .subscribe((values) => {
          if (!values)
            return;
          if (values.filter(v => v !== undefined).length === 0)
            return;

          const valuesObject = {} as { [p: string]: any; };
          const enabledObject = {} as { [p: string]: boolean; };
          const visibleObject = {} as { [p: string]: boolean; };
          const validObject = {} as { [p: string]: boolean; };
          const fieldsObject = {} as { [p: string]: FieldConfig | undefined; };
          const controlsObject = {} as { [p: string]: AbstractControl | undefined; };

          for (const { sourceFieldName, index } of sourceIdentifiers.map((k, i) => ({
            sourceFieldName: k as string,
            index: i
          }))) {
            const source = sources[index];

            valuesObject[sourceFieldName] = values[index];
            enabledObject[sourceFieldName] = whenNotNull(source, c => (c as AbstractControl).enabled || false, false);

            const field = this.form.getField(sourceFieldName);
            fieldsObject[sourceFieldName] = field;

            visibleObject[sourceFieldName] = whenNotNull(field, f => f.show || f.show === undefined, false);
            validObject[sourceFieldName] = whenNotNull(source, c => (c as AbstractControl).valid || false, false);
            controlsObject[sourceFieldName] = isObservable(source) ? undefined : source;
          }

          if (targetField) {
            valuesObject[targetFieldName] = targetControl.value;
            enabledObject[targetFieldName] = targetControl.enabled;
            visibleObject[targetFieldName] = targetField.show || targetField.show === undefined;
            validObject[targetFieldName] = targetControl.valid;
            fieldsObject[targetFieldName] = targetField;
            controlsObject[targetFieldName] = targetControl;
          }

          try {
            handler({
              fields: fieldsObject,
              controls: controlsObject,
              values: valuesObject,
              valid: validObject,
              enabled: enabledObject,
              visible: visibleObject
            });
          } catch (e) {
            console.error(`Error in handler: ${e}`, e);
          }
        });
    };


    // configure type of wood conditions
    bindTargetFromSources(
      OptionProperty.typeOfWood,
      [OptionProperty.woodQuality, 'skuffe20mmFrontBox'],
      ({
        fields: { [OptionProperty.typeOfWood]: typeOfWoodField },
        controls: { [OptionProperty.typeOfWood]: typeOfWoodControl },
        values: {
          [OptionProperty.typeOfWood]: typeOfWoodValue,
          [OptionProperty.woodQuality]: woodQualityValue,
          skuffe20mmFrontBox
        }
      }) => {
        if (skuffe20mmFrontBox) {
          if (typeOfWoodControl && !this.treesortsFor20mm.find((t) => t.value === typeOfWoodValue)) {
            this.toastrService.info(this.translate('choosen_wood_not_compatible_with_20mm'));
            typeOfWoodControl.reset({ value: null, disabled: typeOfWoodControl.disabled });
          }

          setOptions(typeOfWoodField, this.treesortsFor20mm);
        } else if (this.isDrawerType('glas_20mm', 'curve')) {
          setOptions(typeOfWoodField, this.yachtTreesorts);
        } else {
          if (woodQualityValue === OptionType.cabinet) {
            setOptions(typeOfWoodField, this.cabinetTreesorts);
          } else if (woodQualityValue === OptionType.yacht) {
            setOptions(typeOfWoodField, this.yachtTreesorts);
          } else {
            setOptions(typeOfWoodField, this.superiorTreesorts);
          }
        }

        if (woodQualityValue) {
          enableControl(typeOfWoodControl);
        } else if (!this.isErnstMair) {
          disableControl(typeOfWoodControl);
        }
      }
    );

    // configure joint conditions
    bindTargetFromSources(
      OptionProperty.joint,
      [OptionProperty.typeOfWood],
      ({
        controls: { [OptionProperty.joint]: jointControl },
        values: { [OptionProperty.typeOfWood]: typeOfWoodValue }
      }) => {
        if (!this.isNothegger) {
          return;
        }
        if (typeOfWoodValue) {
          enableControl(jointControl);
        } else {
          disableControl(jointControl);
        }
      }
    );

    // [FSC-disabled] FSC has been disabled for now (07/11/2023) as it is not needed, but might be needed again in the future
    // All code changes needed to re-enable FSC are marked with [FSC-disabled]

    // FSC 100% is available for Nothegger oak only!
    // bindTargetFromSources(
    //     undefined,
    //     [OptionProperty.typeOfWood],
    //     (
    //         { values: { [OptionProperty.typeOfWood]: typeOfWoodValue } }
    //     ) => {
    //       this.fscShow = this.isNothegger && isTrue([typeOfWoodValue, 'equals', 'eg']);
    //     }
    // );

    // configure height conditions
    bindTargetFromSources(
      OptionProperty.height,
      [OptionProperty.joint, OptionProperty.drawerOuterWidth],
      ({
        fields: { [OptionProperty.height]: heightField },
        controls: { [OptionProperty.height]: heightControl },
        values: { [OptionProperty.joint]: jointValue, [OptionProperty.height]: heightValue }
      }) => {
        if (jointValue || this.isPurewood) {
          let heights = HeightsDefault;

          // Set heights based on joints
          if (isTrue([jointValue, 'contains', ['svale', 'fordaekt']])) // also covers 'svale_fordaekt'
            heights = HeightsSamlingSvaleOrFordaekt;

          if (this.isDrawerType('rev_ezug') || this.isDrawerType('purewood-rev-ezug'))
            heights = heights.filter(h => h >= MinimumRevEZugHeight);

          setOptions(heightField, toSelectOptions(heights));

          if (heights.indexOf(heightValue) === -1) {
            setValue(heightControl, heights[0]);
          }
        }
      }
    );

    // configure surface conditions
    bindTargetFromSources(
      OptionProperty.surfaceTreatment,
      [OptionProperty.joint, OptionProperty.typeOfWood, OptionProperty.surfaceTreatment, OptionProperty.woodQuality],
      ({
        fields: { [OptionProperty.surfaceTreatment]: surfaceField },
        controls: { [OptionProperty.surfaceTreatment]: surfaceControl },
        values: {
          [OptionProperty.joint]: jointValue,
          [OptionProperty.typeOfWood]: typeOfWoodValue,
          [OptionProperty.surfaceTreatment]: surfaceValue,
          [OptionProperty.woodQuality]: woodQualityValue
        }
      }) => {
        if (((this.isErnstMair || this.isPurewood) && typeOfWoodValue) || jointValue) {
          enableControl(surfaceControl);
        } else {
          disableControl(surfaceControl);
        }

        // Rubio Monocoat:
        if (surfaceValue && surfaceValue.indexOf('rmc_') === 0) {
          surfaceField.helpHTML = this.translate('RMC_colour_link') + '<br />&#9888; ' + this.translate('RMC_colour_warning');
        } else {
          surfaceField.helpHTML = '';
        }

        if (this.manufacturer === Manufacturer.nothegger) {
          let options: IOption[];
          const filterValue = (woodQualityValue === OptionType.cabinet || woodQualityValue === OptionType.yacht) ? woodQualityValue : OptionType.superior;
          options = this.allSurfaces.filter((s) => s.types.indexOf(filterValue) > -1);

          if (isTrue([typeOfWoodValue, 'contains', 'ahorn'])) {
            options = options.filter((s) => !isTrue([s.value, 'startsWith', 'olie']));
            if (isTrue([surfaceValue, 'startsWith', 'olie'])) {
              setValue(surfaceControl, null);
            }
          }
          setOptions(surfaceField, options);

          if (isTrue([surfaceValue, 'startsWith', 'sortolie']) || isTrue([surfaceValue, 'startsWith', 'dd-lak'])) {
            surfaceField.helptext = this.translate('Min-pieces', { count: 20 });
          } else if (isTrue([surfaceValue, 'equals', 'naturlak'])) {
            surfaceField.helptext = this.translate('Min-pieces', { count: 20 });
          } else {
            surfaceField.helptext = '';
          }
        }
      }
    );

    // configure runner mark conditions
    bindTargetFromSources(
      OptionProperty.runnerMark,
      [OptionProperty.surfaceTreatment, OptionProperty.typeOfWood],
      ({
        controls: { [OptionProperty.runnerMark]: runnerMarkControl },
        values: { [OptionProperty.surfaceTreatment]: surfaceValue, [OptionProperty.typeOfWood]: typeOfWoodValue }
      }) => {
        if (surfaceValue && typeOfWoodValue) {
          enableControl(runnerMarkControl);
        } else {
          disableControl(runnerMarkControl);
        }
      }
    );

    // configure runner mark help text
    bindTargetFromSources(
      OptionProperty.runnerMark,
      [OptionProperty.runnerMark],
      ({
        fields: { [OptionProperty.runnerMark]: runnerMarkField },
        values: { [OptionProperty.runnerMark]: runnerMarkValue }
      }) => {
        runnerMarkField.helptext = '';
        if (runnerMarkValue && !isTrue([runnerMarkValue, 'contains', 'hettich'])) {
          runnerMarkField.helpHTML = `<b class="text-danger">${this.translate('Det er ikke muligt at levere udtrækket - bestil fra egen leverandør')}</b>`;
        } else if (runnerMarkValue && this.isErnstMair && isTrue([runnerMarkValue, 'contains', 'hettich']) && !isTrue([runnerMarkValue, 'contains', ['4D', '5D']])) {
          runnerMarkField.helpHTML = `<b class="text-danger">${this.translate('Kobling kan ikke formonteres')}</b>`;
        } else if (runnerMarkValue && isTrue([runnerMarkValue, 'contains', 'hettich']) && !this.userService.isLoggedIn) {
          runnerMarkField.helpHTML = `<b class="text-danger">${this.translate('ORDERS.LoginForHettich')}</b>`;
        } else if (runnerMarkValue && this.userService.isLoggedIn && this.userService.getUser().company.country === 'Norge') {
          runnerMarkField.helpHTML = `<b class="text-danger">${this.translate('Det er ikke muligt at levere udtrækket - bestil fra egen leverandør')}</b>`;
        } else {
          runnerMarkField.helpHTML = '';
        }
        // @todo Purewood? Actro 5D uden pris?
      }
    );

    // configure leverings row
    bindTargetFromSources(
      undefined,
      [OptionProperty.runnerMark],
      ({
        values: { [OptionProperty.runnerMark]: runnerMarkValue }
      }) => {
        const user = this.userService.getUser();
        this.form.getRow('udtr-leveret').show = isTrue([runnerMarkValue, 'contains', 'hettich']) && user && user.company.country !== 'Norge';
      }
    );

    // configure runner type dependencies
    bindTargetFromSources(
      OptionProperty.runnerType,
      [OptionProperty.runnerMark],
      ({
        fields: { [OptionProperty.runnerType]: runnerTypeField },
        controls: { [OptionProperty.runnerType]: runnerTypeControl },
        values: { [OptionProperty.runnerMark]: runnerMarkValue }
      }) => {

        if (isTrue([runnerMarkValue, 'contains', 'hettich'], [runnerMarkValue, '!contains', '5d'])) {
          runnerTypeField.show = true;
          runnerTypeControl.enable();
        } else {
          runnerTypeField.show = false;
          disableControl(runnerTypeControl);
        }
      }
    );

    // configure runner depth conditions
    bindTargetFromSources(
      OptionProperty.runnerDepth,
      [OptionProperty.surfaceTreatment, OptionProperty.runnerMark, OptionProperty.runnerType],
      ({
        fields: { [OptionProperty.runnerDepth]: runnerDepthField },
        controls: { [OptionProperty.runnerDepth]: runnerDepthControl },
        values: { [OptionProperty.surfaceTreatment]: surfaceValue, [OptionProperty.runnerMark]: runnerMarkValue },
        enabled: { [OptionProperty.runnerType]: runnerTypeEnabled }
      }) => {
        if (surfaceValue && this.isDrawerType('schutte')) {
          runnerDepthField.show = true;
          enableControl(runnerDepthControl);
        } else if (isTrue([runnerMarkValue, 'contains', 'hettich']))
          runnerDepthField.show = false;
        else if (runnerMarkValue && !runnerTypeEnabled) {
          runnerDepthField.show = true;
          enableControl(runnerDepthControl);
        } else {
          runnerDepthField.show = true;
          disableControl(runnerDepthControl);
        }
      }
    );

    bindTargetFromSources(
      OptionProperty.height,
      [OptionProperty.surfaceTreatment],
      ({
        fields: { [OptionProperty.height]: heightField },
        controls: { [OptionProperty.height]: heightCtrl },
        values: { [OptionProperty.surfaceTreatment]: surfaceValue },
      }) => {
        if (surfaceValue && this.isErnstMair) {
          let heights = ErnstMairHeights;
          if (this.isDrawerType('rev-ezug') || this.isDrawerType('rear-ezug')) {
            heights = heights.filter((h) => h >= MinimumErnstMairEzugHeights);
          }

          if (this.isDrawerType('ernst-mair-ils')) {
            enableControl(heightCtrl);
          }

          setOptions(heightField, toSelectOptions(heights));
        }
      }
    );

    bindTargetFromSources(
      OptionProperty.runnerDepth,
      [OptionProperty.runnerDepth, 'runnerDepthToDrawerDepthAdjustment'],
      ({
        fields: { [OptionProperty.runnerDepth]: runnerDepthField },
        values: {
          [OptionProperty.runnerDepth]: runnerDepthValue,
          ['runnerDepthToDrawerDepthAdjustment']: runnerDepthToDrawerDepthAdjustment
        },
        enabled: { [OptionProperty.runnerDepth]: runnerDepthEnabled },
        visible: { [OptionProperty.runnerDepth]: runnerDepthVisible },
        valid: { [OptionProperty.runnerDepth]: runnerDepthValid }
      }) => {

        runnerDepthValue = toNumber(runnerDepthValue);
        if (!runnerDepthValue || !runnerDepthVisible || !runnerDepthEnabled) {
          runnerDepthField.helptext = '';
          runnerDepthField.helpHTML = '';
          return;
        }

        if (runnerDepthValue && !runnerDepthValid) {
          runnerDepthField.helptext = '';
          runnerDepthField.helpHTML = `<b class="text-danger">${this.translate('Målet er udenfor gyldigt område')}</b>`;
          return;
        } else {
          const offsetDrawerDepth = runnerDepthValue + runnerDepthToDrawerDepthAdjustment;
          this.skuffeDybde.next(offsetDrawerDepth);

          runnerDepthField.helptext = offsetDrawerDepth !== runnerDepthValue
            ? this.translate('SkuffedybdeMm', { skuffeDybde: offsetDrawerDepth })
            : '';
          runnerDepthField.helpHTML = '';
        }

      });

    // configure runner depth select conditions
    bindTargetFromSources(
      OptionProperty.runnerDepthSelect,
      [OptionProperty.runnerMark, OptionProperty.runnerType],
      ({
        fields: { [OptionProperty.runnerDepthSelect]: runnerDepthSelectField },
        controls: { [OptionProperty.runnerDepthSelect]: runnerDepthSelectControl },
        values: {
          [OptionProperty.runnerMark]: runnerMarkValue,
          [OptionProperty.runnerType]: runnerTypeValue,
          [OptionProperty.runnerDepthSelect]: runnerDepthSelectValue
        }
      }) => {
        if (isTrue([runnerMarkValue, 'contains', 'hettich'])) {
          runnerDepthSelectField.show = true;

          if (isTrue([runnerMarkValue, 'contains', '5d'])) {
            enableControl(runnerDepthSelectControl);
            setOptions(runnerDepthSelectField, toSelectOptions(this.udtrDybder['actro5D']));
          } else if (isTrue([runnerTypeValue, 'contains', 'push'])) {
            if (isTrue([runnerMarkValue, 'contains', '4D'])) {
              enableControl(runnerDepthSelectControl);
              setOptions(runnerDepthSelectField, toSelectOptions(this.udtrDybder['standard']));
            }
          } else if (isTrue([runnerTypeValue, 'contains', 'silent'])) {
            if (isTrue([runnerMarkValue, 'contains', '4D'])) {
              enableControl(runnerDepthSelectControl);
              setOptions(runnerDepthSelectField, toSelectOptions(this.udtrDybder['standard']));
            }
          } else
            disableControl(runnerDepthSelectControl);

          if (runnerDepthSelectField.options.filter(v => v.value === runnerDepthSelectValue).length === 0)
            setValue(runnerDepthSelectControl, null);
          else
            setValue(runnerDepthSelectControl, runnerDepthSelectValue);

        } else {
          runnerDepthSelectField.show = false;

          if (!this.isEdit && !this.isRepeat) {
            disableControl(runnerDepthSelectControl);
          } else {
            setValue(runnerDepthSelectControl, runnerDepthSelectControl.value);
          }
        }
      });

    bindTargetFromSources(
      OptionProperty.runnerDepthSelect,
      [OptionProperty.runnerDepthSelect, 'runnerDepthToDrawerDepthAdjustment', OptionProperty.runnerMark, OptionProperty.runnerType],
      ({
        fields: {
          [OptionProperty.runnerDepthSelect]: runnerDepthSelectField,
          [OptionProperty.runnerType]: runnerTypeField
        },
        values: {
          [OptionProperty.runnerDepthSelect]: runnerDepthSelectValue,
          ['runnerDepthToDrawerDepthAdjustment']: runnerDepthToDrawerDepthAdjustment,
          [OptionProperty.runnerType]: runnerTypeValue
        },
        enabled: { [OptionProperty.runnerDepthSelect]: runnerDepthSelectEnabled },
        visible: { [OptionProperty.runnerDepthSelect]: runnerDepthSelectVisible },
        valid: { [OptionProperty.runnerDepthSelect]: runnerDepthSelectValid }
      }) => {

        runnerDepthSelectValue = toNumber(runnerDepthSelectValue);
        if (!runnerDepthSelectValue || !runnerDepthSelectVisible || !runnerDepthSelectEnabled) {
          runnerDepthSelectField.helptext = '';
          runnerDepthSelectField.helpHTML = '';
          return;
        }

        if (runnerDepthSelectValid) {
          const offsetDrawerDepth = runnerDepthSelectValue + runnerDepthToDrawerDepthAdjustment;
          this.skuffeDybde.next(offsetDrawerDepth);

          runnerDepthSelectField.helptext = this.translate('SkuffedybdeMm', { skuffeDybde: offsetDrawerDepth });
          runnerDepthSelectField.helpHTML = '';

          // Check if push system is selected and is available for the specific depth
          let runnerTypeOptions = JSON.parse(JSON.stringify(this.runnerTypeOptions));
          if (runnerDepthSelectValue && runnerTypeValue) {
            if (pushSystemUnavailableDepths.includes(runnerDepthSelectValue)) {
              runnerTypeOptions = runnerTypeOptions.filter((o) => o.value !== 'pushToOpen');
              runnerTypeField.helpHTML = `<b class="text-danger">${this.translate('UdtraekType.PushToOpen.Unavailable')}</b>`;
            } else {
              runnerTypeField.helpHTML = '';
            }
          }
          setOptions(runnerTypeField, runnerTypeOptions);
        }

      });

    // configure depth and height offset
    bindTargetFromSources(
      undefined,
      [OptionProperty.runnerMark, OptionProperty.runnerType, 'skuffe20mmFrontBox', OptionProperty.thickness],
      ({
        values: { [OptionProperty.runnerMark]: runnerMarkValue, ['skuffe20mmFrontBox']: skuffe20mmFrontBoxValue, [OptionProperty.thickness]: thicknessValue }
      }) => {
        const {
          runnerDepthToDrawerDepthAdjustment,
          drawerOuterToInnerWidthAdjustment
        } = this.getDepthAndWidthAdjustments({
          runnerMark: runnerMarkValue,
          twentyMmFront: skuffe20mmFrontBoxValue,
          thickness: thicknessValue
        });

        this.runnerDepthToDrawerDepthAdjustment.next(runnerDepthToDrawerDepthAdjustment);
        this.drawerOuterToInnerWidthAdjustment.next(drawerOuterToInnerWidthAdjustment);
      });

    // sync runnerDepth and runnerDepthSelect
    bindTargetFromSources(
      undefined,
      [OptionProperty.runnerDepth, OptionProperty.runnerDepthSelect],
      ({
        controls: {
          [OptionProperty.runnerDepth]: runnerDepthControl,
          [OptionProperty.runnerDepthSelect]: runnerDepthSelectControl
        },
        values: {
          [OptionProperty.runnerDepth]: runnerDepthValue,
          [OptionProperty.runnerDepthSelect]: runnerDepthSelectValue
        },
        visible: {
          [OptionProperty.runnerDepth]: runnerDepthVisible,
          [OptionProperty.runnerDepthSelect]: runnerDepthSelectVisible
        },
        valid: {
          [OptionProperty.runnerDepth]: runnerDepthValid,
          [OptionProperty.runnerDepthSelect]: runnerDepthSelectValid
        }
      }) => {
        if (runnerDepthVisible && runnerDepthValid) {
          setValue(runnerDepthSelectControl, runnerDepthValue);
        } else if (runnerDepthSelectVisible && runnerDepthSelectValid) {
          setValue(runnerDepthControl, runnerDepthSelectValue);
        }
      });

    // configure udtraek vaegt dependencies
    bindTargetFromSources(
      OptionProperty.runnerLoad,
      [OptionProperty.runnerMark, OptionProperty.runnerDepthSelect],
      ({
        fields: { [OptionProperty.runnerLoad]: runnerLoadField },
        controls: { [OptionProperty.runnerLoad]: runnerLoadControl },
        values: {
          [OptionProperty.runnerMark]: runnerMarkValue,
          [OptionProperty.runnerDepthSelect]: runnerDepthSelectValue
        }
      }) => {
        if (isTrue([runnerMarkValue, 'contains', ['hettich', '5d'], 'matchAll'])) {
          const options = [];

          if (Actro5Dkg40.indexOf(runnerDepthSelectValue) > -1) {
            options.push({ value: 40, label: '40', });
          }

          if (Actro5Dkg70.indexOf(runnerDepthSelectValue) > -1) {
            options.push({ value: 70, label: '70', });
          }

          setOptions(runnerLoadField, options);
          runnerLoadField.show = true;

          if (runnerDepthSelectValue) {
            if (!options.some((o) => o.value === runnerLoadControl.value)) {
              runnerLoadControl.setValue(options[0].value);
            }
            enableControl(runnerLoadControl);
          } else {
            disableControl(runnerLoadControl);
          }
        } else {
          runnerLoadField.show = false;
          disableControl(runnerLoadControl);
        }
      });

    // Configure push-to-open-silent dependencies
    bindTargetFromSources(
      OptionProperty.runnerPushToOpenSilent,
      [OptionProperty.runnerMark, OptionProperty.runnerType, 'udtrLeveret'],
      ({
        fields: { [OptionProperty.runnerPushToOpenSilent]: runnerPushToOpenSilentField },
        controls: { [OptionProperty.runnerPushToOpenSilent]: runnerPushToOpenSilentControl },
        values: {
          [OptionProperty.runnerMark]: runnerMarkValue,
          [OptionProperty.runnerType]: runnerTypeValue,
          udtrLeveret
        }
      }) => {
        if (isTrue([runnerMarkValue, 'contains', 'hettich'], [runnerMarkValue, 'contains', '4d'], [runnerTypeValue, 'contains', 'silent'], [udtrLeveret, 'equals', 'ja'])
          || isTrue([runnerMarkValue, 'contains', 'hettich'], [runnerMarkValue, 'contains', '5d'], [udtrLeveret, 'equals', 'ja'])) {
          runnerPushToOpenSilentField.show = true;
          enableControl(runnerPushToOpenSilentControl);
        } else {
          runnerPushToOpenSilentField.show = false;
          disableControl(runnerPushToOpenSilentControl);
        }
      });

    // configure clear udsparing due to changes
    bindTargetFromSources(
      undefined,
      ['skuffeBredde', 'skuffeDybde'],
      () => {
        if (this.udsparing || this.rearUdsparing) {
          this.udsparing = null;
          this.rearUdsparing = null;
          this.toastrService.warning(this.translate('SiphonCutting.removedDueToMeasureChanges'), null, { timeOut: 5000 });
        }
      });

    // configure drawer outer width dependencies
    bindTargetFromSources(
      OptionProperty.drawerOuterWidth,
      [OptionProperty.surfaceTreatment, OptionProperty.runnerDepth, OptionProperty.runnerDepthSelect],
      ({
        controls: { [OptionProperty.drawerOuterWidth]: drawerOuterWidthControl },
        values: {
          [OptionProperty.runnerDepth]: runnerDepthValue,
          [OptionProperty.runnerDepthSelect]: runnerDepthSelectValue
        },
        visible: {
          [OptionProperty.runnerDepth]: runnerDepthVisible,
          [OptionProperty.runnerDepthSelect]: runnerDepthSelectVisible
        }
      }) => {
        runnerDepthValue = toNumber(coalesce(runnerDepthValue, runnerDepthSelectValue));
        if (!this.isDrawerType('ernst-mair-ils')) {
          if ((runnerDepthValue || (!runnerDepthVisible && !runnerDepthSelectVisible)))
            enableControl(drawerOuterWidthControl);
          else
            disableControl(drawerOuterWidthControl);
        }
      });

    bindTargetFromSources(
      OptionProperty.drawerOuterWidth,
      [OptionProperty.drawerOuterWidth, 'drawerOuterToInnerWidthAdjustment', 'afstandslistToSkuffeBreddeAdjustment', 'slideListToDrawerWidthAdjustment'],
      ({
        values: {
          [OptionProperty.drawerOuterWidth]: drawerOuterWidthValue,
          ['drawerOuterToInnerWidthAdjustment']: drawerOuterToInnerWidthAdjustment,
          ['afstandslistToSkuffeBreddeAdjustment']: afstandslistToSkuffeBreddeAdjustment,
          ['slideListToDrawerWidthAdjustment']: slideListToDrawerWidthAdjustment
        },
        enabled: { [OptionProperty.drawerOuterWidth]: drawerOuterWidthEnabled },
        visible: { [OptionProperty.drawerOuterWidth]: drawerOuterWidthVisible },
        valid: { [OptionProperty.drawerOuterWidth]: drawerOuterWidthValid }
      }) => {
        drawerOuterWidthValue = toNumber(drawerOuterWidthValue);
        if (!drawerOuterWidthValue || !drawerOuterWidthVisible || !drawerOuterWidthEnabled || (drawerOuterWidthValue && !drawerOuterWidthValid)) {
          this.skuffeBredde.next(-1);
          this.frontBredde.next(-1);
          return;
        }

        const newDrawerWidth: number = drawerOuterWidthValue + drawerOuterToInnerWidthAdjustment +
          (this.isDrawerType('curve') ? 0 : (afstandslistToSkuffeBreddeAdjustment + slideListToDrawerWidthAdjustment));
        const newFrontWidth = (this.isDrawerType('curve')) ? newDrawerWidth + DesignCurveSkuffeBreddeToFrontBreddeAdjustment : -1;

        this.skuffeBredde.next(newDrawerWidth);
        this.frontBredde.next(newFrontWidth);
        if (this.bestikindsats) {
          this.bscomp.setWidth(this.calculateCutleryTrayWidth());
          this.checkIfCutleryTrayIsValid();
        }
      });

    bindTargetFromSources(
      OptionProperty.drawerOuterWidth,
      [OptionProperty.drawerOuterWidth, 'skuffeBredde', 'frontBredde'],
      ({
        fields: { [OptionProperty.drawerOuterWidth]: drawerOuterWidthField },
        values: {
          [OptionProperty.drawerOuterWidth]: drawerOuterWidthValue,
          ['skuffeBredde']: drawerWidthValue,
          ['frontBredde']: frontWidthValue
        },
        enabled: { [OptionProperty.drawerOuterWidth]: drawerOuterWidthEnabled },
        visible: { [OptionProperty.drawerOuterWidth]: drawerOuterWidthVisible },
        valid: { [OptionProperty.drawerOuterWidth]: drawerOuterWidthValid }
      }) => {

        drawerOuterWidthValue = toNumber(drawerOuterWidthValue);
        if (!drawerOuterWidthValue || !drawerOuterWidthVisible || !drawerOuterWidthEnabled) {
          drawerOuterWidthField.helptext = '';
          drawerOuterWidthField.helpHTML = '';
          return;
        }

        if (drawerOuterWidthValue && !drawerOuterWidthValid) {
          drawerOuterWidthField.helptext = '';
          drawerOuterWidthField.helpHTML = `<b class="text-danger">${this.translate('Målet er udenfor gyldigt område')}</b>`;
          return;
        } else {
          let drawerOuterWidthHelpText: string;

          if (this.isDrawerType('curve')) {
            drawerOuterWidthHelpText = this.translate('SkuffebreddeMmFrontbreddeMm', {
              skuffeBredde: drawerWidthValue,
              frontBredde: frontWidthValue
            });
          } else {
            drawerOuterWidthHelpText = drawerWidthValue !== drawerOuterWidthValue
              ? this.translate('SkuffebreddeMm', { skuffeBredde: drawerWidthValue })
              : '';
          }

          drawerOuterWidthField.helptext = drawerOuterWidthHelpText;
          drawerOuterWidthField.helpHTML = '';
        }
      });

    // configure height dependencies
    bindTargetFromSources(
      OptionProperty.height,
      [OptionProperty.drawerOuterWidth],
      ({
        controls: { [OptionProperty.height]: heightControl },
        values: { [OptionProperty.drawerOuterWidth]: drawerOuterWidthValue }
      }) => {
        if (!this.isDrawerType('ernst-mair-ils')) {
          if (toNumber(drawerOuterWidthValue))
            enableControl(heightControl);
          else
            disableControl(heightControl);
        }
      });

    bindTargetFromSources(
      OptionProperty.height,
      [OptionProperty.height, 'ligeOverkantBox'],
      ({
        values: { [OptionProperty.height]: heightValue, ligeOverkantBox },
        enabled: { [OptionProperty.height]: heightEnabled },
        visible: { [OptionProperty.height]: heightVisible },
        valid: { [OptionProperty.height]: heightValid }
      }) => {
        heightValue = toNumber(heightValue);
        if (!heightValue || !heightVisible || !heightEnabled) {
          this.frontHoejde.next(-1);
          return;
        }

        if (heightValue && !heightValid) {
          this.frontHoejde.next(-1);
        } else {
          if (this.isDrawerType('innenlade', 'ils')) {
            const offsetHoejde = heightValue + this.innenladeFrontHoejde - (ligeOverkantBox === true ? 2 : 0);
            this.frontHoejde.next(offsetHoejde);
          } else if (this.isDrawerType('type-b')) {
            this.frontHoejde.next(-1);
          } else if (this.isDrawerType('ezug') && (this.isDrawerType('rev') || this.isDrawerType('rear'))) {
            const offsetHoejde = heightValue + RevEzugFrontHoejde - (ligeOverkantBox === true ? 2 : 0);
            this.frontHoejde.next(offsetHoejde);
          } else if (this.isDrawerType('ezug')) {
            const frontHeight = this.optService.getEzugPieceHeight(heightValue);
            this.frontHoejde.next(frontHeight);
          }
          else {
            this.frontHoejde.next(heightValue);
          }
        }
      });

    bindTargetFromSources(
      undefined,
      [OptionProperty.drawerBase],
      () => {
        const heightControl = this.form.getControl(OptionProperty.height);
        if (this.bestikindsats && this.checkIfCutleryTrayIsValid() && !heightControl.valid) {
          heightControl.updateValueAndValidity();
        }
      }
    );

    bindTargetFromSources(
      undefined,
      [OptionProperty.height],
      () => {
        const drawerBaseControl = this.form.getControl(OptionProperty.drawerBase);
        if (this.bestikindsats && this.checkIfCutleryTrayIsValid() && !drawerBaseControl.valid) {
          drawerBaseControl.updateValueAndValidity();
        }
      }
    );

    bindTargetFromSources(
      OptionProperty.height,
      [OptionProperty.height, 'frontHoejde', 'ligeOverkantBox'],
      ({
        fields: { [OptionProperty.height]: heightField },
        values: { [OptionProperty.height]: heightValue, ['frontHoejde']: frontHoejdeValue },
        enabled: { [OptionProperty.height]: heightEnabled },
        visible: { [OptionProperty.height]: heightVisible },
        valid: { [OptionProperty.height]: heightValid }
      }) => {
        heightValue = toNumber(heightValue);
        if (!heightValue || !heightVisible || !heightEnabled) {
          heightField.helptext = '';
          heightField.helpHTML = '';
          return;
        }

        if (heightValue && !heightValid) {
          heightField.helptext = '';
          // heightField.helpHTML = `<b class="text-danger">${this.translate('Målet er udenfor gyldigt område')}</b>`;
          return;
        } else {
          let helptext = '';

          if (this.isDrawerType('innenlade', 'ils')) {
            helptext = this.translate('InnenladeTillaegTilFrontenMm', {
              tilpasning: this.innenladeFrontHoejde,
              frontHoejde: frontHoejdeValue
            });
          } else if (this.isDrawerType('ezug') && !this.isDrawerType('rev') && !this.isDrawerType('rear')) {
            helptext = this.translate('SænketStykkeHøjdeMm', { height: frontHoejdeValue });
          } else if (!this.isErnstMair && this.isDrawerType('ezug') && this.isDrawerType('rev')) {
            helptext = this.translate('FrontHøjdeMm', { height: frontHoejdeValue });
            const options = [];
            options.push({ value: 55, label: '55', });
            options.push({ value: 95, label: '95', });
            if (frontHoejdeValue > 115) {
              options.push({ value: 115, label: '115', });
            }
            if (this.revEzugHoejder)
              this.revEzugHoejder.revEzugHoejder = options;
          } else if (this.isErnstMair && this.isDrawerType('ezug')) {
            helptext = this.translate('FrontHøjdeMm', { height: frontHoejdeValue });
            const options = [];
            options.push({ value: 84, label: '84', });
            options.push({ value: 100, label: '100', });
            if (heightValue >= 132) {
              options.push({ value: 116, label: '116', });
            }
            if (this.ernstRevEzugMaal)
              this.ernstRevEzugMaal.revEzugHoejder = options;
            else if (this.ernstRearEzugMaal)
              this.ernstRearEzugMaal.rearEzugHoejder = options;
          }
          heightField.helptext = helptext;
          heightField.helpHTML = '';
        }
      }
    );

    // configure height and dybde dependencies related to bestikindsats.
    bindTargetFromSources(
      undefined,
      [OptionProperty.drawerOuterWidth, OptionProperty.runnerDepth, OptionProperty.runnerDepthSelect],
      ({
        valid: { [OptionProperty.drawerOuterWidth]: drawerOuterWidthValid },
      }) => {
        if (this.bestikindsats) {
          if (!drawerOuterWidthValid) {
            this.bscomp.setWidth(this.calculateCutleryTrayWidth());
          }

          const dybdeControl = this.getActiveRunnerDepthControl();
          if (dybdeControl.valid) {
            this.bscomp.setDepth(parseInt(dybdeControl.value, 10) + this.runnerDepthToDrawerDepthAdjustment.value - ((this.drawer.type.indexOf('curve') > -1) ? 36 : 29));
          }
          this.checkIfCutleryTrayIsValid();
        }
      }
    );

    // configure drawer base dependencies
    bindTargetFromSources(
      OptionProperty.drawerBase,
      [OptionProperty.height],
      ({
        controls: { [OptionProperty.drawerBase]: drawerBaseControl },
        values: { [OptionProperty.height]: heightValue },
      }) => {
        if (toNumber(heightValue))
          enableControl(drawerBaseControl);
        else
          disableControl(drawerBaseControl);
      }
    );

    // configure coupling dependencies
    bindTargetFromSources(
      OptionProperty.premountedCoupling,
      [OptionProperty.drawerBase, OptionProperty.runnerMark, 'skuffeBredde', 'bottomUnmountedCheckbox'],
      ({
        values: {
          [OptionProperty.drawerBase]: drawerBaseValue,
          [OptionProperty.runnerMark]: runnerMarkValue,
          ['skuffeBredde']: drawerWidthValue,
          ['bottomUnmountedCheckbox']: bottomUnmountedValue,
        },
        controls: { [OptionProperty.premountedCoupling]: premountedCouplingControl }
      }) => {

        if (drawerBaseValue || (this.isPurewood && runnerMarkValue)) {
          if (
            (this.isErnstMair && (runnerMarkValue && !isTrue([runnerMarkValue, 'contains', ['4D', '5D', 'blum']])))
            || (drawerWidthValue && drawerWidthValue > 0 && drawerWidthValue < 200 || bottomUnmountedValue)
          ) {
            disableControl(premountedCouplingControl);
            setValue(premountedCouplingControl, 'nej');
          } else {
            enableControl(premountedCouplingControl);
          }
        } else {
          disableControl(premountedCouplingControl);
        }
      }
    );

    // configure coupling alongside dependencies
    bindTargetFromSources(
      OptionProperty.couplingAlongside,
      [OptionProperty.premountedCoupling, OptionProperty.runnerMark],
      ({
        fields: { [OptionProperty.couplingAlongside]: couplingAlongsideField },
        controls: { [OptionProperty.couplingAlongside]: couplingAlongsideControl },
        values: {
          [OptionProperty.premountedCoupling]: couplingValue,
          [OptionProperty.runnerMark]: runnerMarkValue
        },
      }) => {
        if (couplingValue) {
          if (isTrue([couplingValue, 'equals', 'nej'], [runnerMarkValue, 'contains', 'hettich'])) {
            enableControl(couplingAlongsideControl);
            couplingAlongsideField.show = true;
          } else if (isTrue([couplingValue, 'equals', 'ja'])) {
            disableControl(couplingAlongsideControl);
            couplingAlongsideField.show = false;
          }
        } else {
          disableControl(couplingAlongsideControl);
          couplingAlongsideField.show = false;
        }
      });

    // configure shims dependencies
    bindTargetFromSources(
      OptionProperty.shimsSelection,
      [OptionProperty.premountedCoupling],
      ({
        controls: { [OptionProperty.shimsSelection]: shimsSelectionCtrl },
        values: { [OptionProperty.premountedCoupling]: couplingValue },
      }) => {
        if (couplingValue) {
          enableControl(shimsSelectionCtrl);
        } else {
          disableControl(shimsSelectionCtrl);
        }
      });

    // configure shims dependencies
    bindTargetFromSources(
      OptionProperty.slideList,
      [OptionProperty.shimsSelection, OptionProperty.slideList],
      ({
        fields: { [OptionProperty.slideList]: slideListField, [OptionProperty.shimsSelection]: shimsSelectionField },
        controls: { [OptionProperty.slideList]: slideListCtrl },
        values: { [OptionProperty.shimsSelection]: shimsSelectionValue },
      }) => {
        if (this.isNothegger) {
          slideListField.helpHTML = `<a href="/assets/Fuehrungsdopplungen_innen.pdf" target="_blank">${this.translate('FøringslisteHelpHTML')}</a>`;
        } else {
          slideListField.helpHTML = '';
        }

        if (shimsSelectionValue || shimsSelectionField == null) {
          enableControl(slideListCtrl);
        } else {
          disableControl(slideListCtrl);
        }
      });

    bindTargetFromSources(
      OptionProperty.shimsSelection,
      ['afstandslistToSkuffeBreddeAdjustment', 'skuffeBredde'],
      ({
        fields: { [OptionProperty.shimsSelection]: shimsSelectionField },
        values: { afstandslistToSkuffeBreddeAdjustment, skuffeBredde },
      }) => {
        if (afstandslistToSkuffeBreddeAdjustment < 0) {
          shimsSelectionField.helptext = this.translate(
            'DerFratrækkesMmSkuffebredden',
            { tilpasning: -afstandslistToSkuffeBreddeAdjustment, skuffeBredde });
        } else if (afstandslistToSkuffeBreddeAdjustment > 0) {
          shimsSelectionField.helptext = this.translate(
            'DerTillæggesMmSkuffebredden',
            { tilpasning: afstandslistToSkuffeBreddeAdjustment, skuffeBredde });
        } else {
          shimsSelectionField.helptext = '';
        }
      });

    // configure afstandslistToSkuffeBreddeAdjustment from shims
    bindTargetFromSources(
      undefined,
      [OptionProperty.shimsSelection],
      ({
        values: { [OptionProperty.shimsSelection]: shimsSelectionValue },
      }) => {
        if (isTrue([shimsSelectionValue, 'contains', 'x'])) {
          const fraTraek = -toNumber(shimsSelectionValue.split('x')[0]);
          if (fraTraek) {
            this.afstandslistToSkuffeBreddeAdjustment.next(
              (shimsSelectionValue as string).endsWith('x2') ? fraTraek * 2 : fraTraek
            );
          }
        } else {
          this.afstandslistToSkuffeBreddeAdjustment.next(0);
        }
      });

    // configure slideListToDrawerWidthAdjustment from slideList
    bindTargetFromSources(
      undefined,
      [OptionProperty.slideList],
      (
        { values: { [OptionProperty.slideList]: slideList } },
      ) => {
        if (slideList === SlideListOptionValue.slideYesPlusSix) {
          this.slideListToDrawerWidthAdjustment.next(6);
        } else {
          this.slideListToDrawerWidthAdjustment.next(0);
        }
      });

    // configure udtrDybdeJustering dependencies
    bindTargetFromSources(
      'udtrDybdeJustering',
      ['udtrLeveret', OptionProperty.runnerMark],
      ({
        fields,
        controls,
        values: { udtrLeveret, [OptionProperty.runnerMark]: runnerMarkValue },
        enabled: { udtrLeveret: udtrLeveretEnabled },
      }) => {
        if (udtrLeveretEnabled && isTrue([udtrLeveret, 'equals', 'ja'], [runnerMarkValue, 'contains', '5d'])) {
          enableControl(controls.udtrDybdeJustering);
          fields.udtrDybdeJustering.show = true;
        } else {
          disableControl(controls.udtrDybdeJustering);
          fields.udtrDybdeJustering.show = false;
        }
      });

    // Configure synchronisation bar dependencies
    bindTargetFromSources(
      OptionProperty.synchronisationBar,
      ['udtrLeveret', OptionProperty.runnerMark, OptionProperty.runnerPushToOpenSilent],
      ({
        fields: { [OptionProperty.synchronisationBar]: synchronisationBarField },
        controls: { [OptionProperty.synchronisationBar]: synchronisationBarCtrl },
        values: { udtrLeveret, [OptionProperty.runnerMark]: runnerMarkValue },
        enabled: { udtrLeveret: udtrLeveretEnabled }
      }) => {
        if (udtrLeveretEnabled && isTrue([udtrLeveret, 'equals', 'ja'], [runnerMarkValue, 'contains', ['4d', '5d']])) {
          enableControl(synchronisationBarCtrl);
          synchronisationBarField.show = true;
        } else {
          disableControl(synchronisationBarCtrl);
          synchronisationBarField.show = false;
        }
      }
    );

    bindTargetFromSources(
      'dybde_select',
      [OptionProperty.height],
      ({
        fields: { dybde_select: dybde_selectField },
        controls: { dybde_select: dybde_selectCtrl },
        values: { [OptionProperty.height]: heightValue },
      }) => {
        if (heightValue && this.isDrawerType('ernst-mair-ils')) {
          enableControl(dybde_selectCtrl);
          setOptions(dybde_selectField, toSelectOptions(this.ilsDybder['ernst-mair-ils']));
        } else {
          disableControl(dybde_selectCtrl);
        }
      }
    );

    bindTargetFromSources(
      'dybde_select',
      ['dybde_select', OptionProperty.drawerOuterWidth],
      ({
        values: { dybde_select },
        controls
      }) => {
        if (dybde_select && this.isDrawerType('ernst-mair-ils')) {
          this.skuffeDybde.next(dybde_select);
          enableControl(controls[OptionProperty.drawerOuterWidth]);
        }
      }
    );

    bindTargetFromSources(
      undefined,
      [OptionProperty.premountedCoupling],
      ({ values: { [OptionProperty.premountedCoupling]: couplingValue } }) => {
        const bottomUnmountedCtrl = this.checkboxForm.get('bottomUnmountedCheckbox');
        if (bottomUnmountedCtrl) {
          if (couplingValue && isTrue([couplingValue, 'equals', 'ja'])) {
            console.log('disableControl(bottomUnmountedCtrl) coupling', couplingValue);
            disableControl(bottomUnmountedCtrl);
          } else {
            enableControl(bottomUnmountedCtrl);
          }
        }
      }
    );
  }

  addSiphonCutting() {
    if (this.form.invalid || this.form.untouched || !this.skuffeBredde.value || this.skuffeBredde.value < 1 || !this.skuffeDybde.value || this.skuffeDybde.value < 1) {
      return this.toastrService.error(this.translate('Alle ovenstående mål og valg skal sættes før der kan tilføjes en udsparing'));
    }
    if (this.udsparing) {
      this.siphonCuttingDefaults = {
        ab: this.udsparing.ab,
        bc: this.udsparing.bc,
        cd: this.udsparing.cd,
        ef: this.udsparing.ef,
        width: this.skuffeBredde.value,
        depth: this.skuffeDybde.value,
        existing: true,
        special: this.udsparing.special
      };
    } else if (this.rearUdsparing) {
      this.rearUdsparingDefaults = {
        ab: this.rearUdsparing.ab,
        bc: this.rearUdsparing.bc,
        cd: this.rearUdsparing.cd,
        de: this.rearUdsparing.de,
        fg: this.rearUdsparing.fg,
        gh: this.rearUdsparing.gh,
        width: this.skuffeBredde.value,
        depth: this.skuffeDybde.value,
        existing: true,
        special: this.rearUdsparing.special
      };
    } else {
      // setup defaults
      this.siphonCuttingDefaults = {
        ab: 10,
        bc: 150,
        cd: 200,
        ef: 10,
        width: this.skuffeBredde.value,
        depth: this.skuffeDybde.value,
        existing: false,
        special: false,
      };

      this.rearUdsparingDefaults = {
        ab: 70,
        bc: 10,
        cd: 150,
        de: 200,
        fg: 10,
        gh: 70,
        width: this.skuffeBredde.value,
        depth: this.skuffeDybde.value,
        existing: false,
        special: false,
      };
    }
    if (this.isErnstMair && this.isDrawerType('rear-ezug')) {
      this.rearUdspComp.defaults = this.rearUdsparingDefaults;
      this.rearUdspComp.udsparingInit();
    } else {
      this.udspComp.defaults = this.siphonCuttingDefaults;
      this.udspComp.siphonCuttingInit(); // Param: reset => true
    }

    this.udspModal.modalClass = 'modal-lg';
    this.udspModal.show();
  }

  drawerBaseMutex(clicked: 'separate' | 'mounted') {
    const separateCtrl = this.checkboxForm.get('bottomUnmountedCheckbox');
    const mountedCtrl = this.checkboxForm.get('bundSkruetBox');
    if (separateCtrl.value && mountedCtrl.value) {
      if (clicked === 'separate') {
        setValue(mountedCtrl, false);
        return this.toastrService.error(this.translate('drawer_base_mounted.cleared'));
      } else {
        setValue(separateCtrl, false);
        return this.toastrService.error(this.translate('drawer_base_separate.cleared'));
      }
    }
  }

  confirmUdsparing() {
    this.bestikindsats = undefined;
    if (this.isErnstMair && this.isDrawerType('rear-ezug')) {
      this.rearUdsparing = this.rearUdspComp.getUdsparing();
    } else {
      this.udsparing = this.udspComp.getSiphonCutting();
    }

    this.toastrService.success(this.translate('Udsparing tilføjet'));
    this.udspModal.hide();
    if (this.hulboringShow) {
      this.setCheckboxValue('hulboringBox', false);
      disableControl(this.checkboxForm.controls['hulboringBox']);
    }
  }

  createCheckboxes() {
    // checkbox form
    this.checkboxForm = new UntypedFormGroup({
      boringFrontBox: new UntypedFormControl(),
      boringCutleryTrayBox: new UntypedFormControl(),
      bottomUnmountedCheckbox: new UntypedFormControl(),
      ligeOverkantBox: new UntypedFormControl(),
      fscBox: new UntypedFormControl(),
      surfaceTreatmentUndersideBox: new UntypedFormControl(),
      bundSkruetBox: new UntypedFormControl(),
      boerstetBox: new UntypedFormControl(),
      skuffe20mmFrontBox: new UntypedFormControl(),
      hulboringBox: new UntypedFormControl(),
      upPricesBox: new UntypedFormControl(),
    });

    this.surfaceTreatmentUndersideShow = this.isNothegger;
  }

  setCheckboxesVisible(boxes: string[]) {
    if (boxes.indexOf('boring') > -1) {
      this.boringShow = true;
    }

    if (boxes.indexOf('boringCutleryTray') > -1) {
      this.boringCutleryTrayShow = true;
    }

    // TESTING: træk lige overkant fra samlet fronthøjde ved innenlade skuffer
    if (boxes.indexOf('ligeOverkant') > -1) {
      this.ligeOverkantShow = true;
    }

    if (boxes.indexOf('bundSkruet') > -1) {
      this.drawerBaseMountedShow = true;
    }

    if (boxes.indexOf(OptionProperty.bottomUnmounted) > -1) {
      this.showBottomUnmounted = true;
    }

    if (boxes.indexOf('boerstet') > -1) {
      this.boerstetShow = true;
    }

    if (boxes.indexOf('skuffe20mmFront') > -1) {
      this.skuffe20mmFrontShow = true;
    }

    if (boxes.indexOf('hulboring') > -1) {
      this.hulboringShow = true;
    }

    if (boxes.indexOf('greb') > -1) {
      this.grebShow = true;
    }

    if (boxes.indexOf('udsparing') > -1) {
      this.udsparingShow = true;
    }

    if (boxes.indexOf('bestikindsats') > -1) {
      this.bestikindsatsShow = true;
    }

    if (boxes.indexOf('logo') > -1) {
      this.logoShow = true;
    }
  }

  resetOrder(showToast = true) {
    this.pop.hide();

    const resetControlByName = (ctrlName: string, value?: any, options?: Object) => {
      const ctrl = this.form.getControl(ctrlName);
      if (ctrl)
        ctrl.reset(value, options);
    };

    this.udsparing = null;
    this.rearUdsparing = null;
    this.bestikindsats = undefined;
    this.logo = null;

    Object.values(this.checkboxForm.controls)
      .reverse()
      .forEach((cUnknown) => {
        const cAny = cUnknown as any;
        cAny.reset(false, { disable: false });
      });

    this.form.getAllFields()
      .forEach((f) => resetControlByName(f.name, null, { disabled: true }));

    resetControlByName(OptionProperty.woodQuality, null, { disabled: false });

    if (showToast) {
      this.toastrService.info(this.translate('Ordren er nulstillet'));
    }
  }

  showAddLogo() {
    if (this.form.invalid || this.form.untouched) {
      return this.toastrService.error(this.translate('Alle ovenstående mål og valg skal sættes før der kan tilføjes til logo'));
    }
    this.modalRef = this.modalService.show(LogoAddComponent);
    this.modalRef.content.onSelect.subscribe((r) => {
      this.logo = r;
      this.toastrService.success(this.translate('Logo tilføjet til skuffen'));
    });
  }

  getActiveRunnerDepthControl() {
    let runnerDepthControl = this.form.getControl(OptionProperty.runnerDepth);
    if (runnerDepthControl.disabled) {
      runnerDepthControl = this.form.getControl(OptionProperty.runnerDepthSelect);
    }

    return runnerDepthControl;
  }

  deleteBestikindsats() {
    this.bestikindsats = undefined;
    this.form.getControl(OptionProperty.height).updateValueAndValidity();
    this.form.getControl(OptionProperty.drawerBase).updateValueAndValidity();
  }

  confirmBestikindsats() {
    this.bestikindsats = this.bscomp.getCutleryTrayOptions();
    if (this.bestikindsats) {
      this.udsparing = null;
      this.rearUdsparing = null;
      this.toastrService.success(this.translate('Bestikindsats tilføjet'));
      this.bestikModal.hide();
    } else {
      Object.keys(this.bscomp.form.getAllControls()).forEach(key => {
        this.bscomp.form.getControl(key).markAsTouched();
      });
      this.toastrService.error(this.translate('Alle felter skal udfyldes før bestikindsatsen kan tilføjes'));
    }
  }

  deleteUdsparing() {
    this.udsparing = null;
    if (this.hulboringShow)
      enableControl(this.checkboxForm.controls['hulboringBox']);
  }


  setGreb(grebType) {
    const prevGreb = this.greb;
    this.greb = grebType;
    if (prevGreb && grebType === null) {
      // Update height and drawer base validity
      this.form.getControl(OptionProperty.height).updateValueAndValidity();
      this.form.getControl(OptionProperty.drawerBase).updateValueAndValidity();
    }
    if (this.bestikindsats && !this.checkIfCutleryTrayIsValid()) {
      this.greb = null;
      if (this.activeCutleryTrayToast) {
        this.activeCutleryTrayToast.toastRef.close();
      }
      this.activeCutleryTrayToast = this.toastrService.error(this.translate('GrebNotPossible'), null, { timeOut: 10000 });
    }
  }

  addBestikindsats() {
    const innerDrawerWidthControl = this.form.getControl(OptionProperty.drawerOuterWidth);

    const depthControl = this.getActiveRunnerDepthControl();
    const heightControl = this.form.getControl(OptionProperty.height);
    const typeOfWoodControl = this.form.getControl(OptionProperty.typeOfWood);
    const surfaceControl = this.form.getControl(OptionProperty.surfaceTreatment);

    if (
      innerDrawerWidthControl.value === null ||
      depthControl.value === null ||
      typeOfWoodControl.value === null ||
      heightControl.value === null
    ) {
      return this.toastrService.error(this.translate('DybdeBreddeHøjdeTræsortMissing'));
    }

    const heightIncrement = 20;
    let min52 = 75;
    let min92 = 115;

    if (heightControl.value <= 52) {
      return this.toastrService.error(this.translate('MinHøjdeForAtTilføjeBestikindsats'));
    }

    // Drawer-base value
    const drawerBaseControl: any = this.form.getControl(OptionProperty.drawerBase);
    const drawerBaseValue = parseInt(drawerBaseControl.value.replace(/\D+/g, ''), 10);

    // vi skal checke om vi har greb i
    if (this.greb) {
      min52 = 115;
      min92 = 155;
    }

    if (drawerBaseValue > 9) {
      min52 += heightIncrement;
      min92 += heightIncrement;
    }

    if (heightControl.value < min52) {
      return this.toastrService.error(this.translate('BestikindsatsNotPossible'));
    }

    this.bsHeights = [];
    this.bsHeights.push({
      label: '52',
      value: 52,
    });

    if (heightControl.value >= min92) {
      this.bsHeights.push({
        label: '92',
        value: 92,
      });
    }

    this.bsTree = [];
    this.bsSurface = [];

    this.form.getField(OptionProperty.typeOfWood).options.forEach((o) => {
      this.bsTree.push({
        label: o.label,
        value: o.value,
      });
    });

    this.form.getField(OptionProperty.surfaceTreatment).options.forEach((o) => {
      this.bsSurface.push({
        label: o.label,
        value: o.value,
      });
    });

    const bsDefaults: ICutleryTrayOptions = this.bestikindsats || { ...BestikindsatsDefaults };

    if (bsDefaults[OptionProperty.typeOfWood] === null)
      bsDefaults[OptionProperty.typeOfWood] = typeOfWoodControl.value !== 'eg_rustik' ? typeOfWoodControl.value : null;

    if (bsDefaults[OptionProperty.surfaceTreatment] === null)
      bsDefaults[OptionProperty.surfaceTreatment] = surfaceControl.value;

    if (this.drawer.type.indexOf('curve') > -1) {
      bsDefaults[OptionProperty.depth] = parseInt(depthControl.value, 10) + this.runnerDepthToDrawerDepthAdjustment.value - 36;
    } else {
      bsDefaults[OptionProperty.depth] = parseInt(depthControl.value, 10) + this.runnerDepthToDrawerDepthAdjustment.value - 29;
    }
    bsDefaults[OptionProperty.width] = this.calculateCutleryTrayWidth();

    this.bscomp.setDefaultValues(bsDefaults);
    const heightOptions: SelectOption[] = this.bsHeights.map(h => ({ label: h.label, value: h.value.toString() }));
    this.bscomp.setOptions(this.bscomp.form.getField(OptionProperty.height), heightOptions);
    this.bestikModal.modalClass = 'modal-lg';
    this.bestikModal.show();
  }

  fillForm() {
    const options = this.overrideOptions;
    const getDrawerValue = (key: string) => {
      const value = options[key];

      if (value !== undefined)
        return value;

      switch (key) {
        case 'dybde_select':
          return options.skuffeDybde;
        case 'udtrLeveret':
          return options.udtraekLeveret;
        case 'udtrDybdeJustering':
          return options.udtraekDybdeJustering;
        case OptionProperty.height:
          return options.skuffeHoejde;
        case 'reverseEnglisherZug':
          return [options.reverseEnglisherZug.ab, options.reverseEnglisherZug.cd];
        case 'rearEnglisherZug':
          return [options.rearEnglisherZug.ab, options.rearEnglisherZug.cd];
        case OptionProperty.runnerDepth:
        case OptionProperty.runnerDepthSelect:
          return options[OptionProperty.runnerDepth];
        case OptionProperty.premountedCoupling:
        case OptionProperty.runnerLoad:
        case OptionProperty.runnerPushToOpenSilent:
        case OptionProperty.runnerType:
        case OptionProperty.synchronisationBar:
          return options[key];
      }

      console.debug(`Unable to find a value for control ${key}.`);
      return undefined;
    };

    for (const row of this.model || []) {
      for (const field of row.fields || []) {
        const key = field.name;
        const ctrl = this.form.getControl(key);
        let value = getDrawerValue(key);

        if (typeof (value) === 'boolean') {
          value = value ? 'ja' : 'nej';
        }

        if (field.type === 'select' && value) {
          if (!field.options) {
            field.options = [value];
          }
          if (field.options.some((f) => f.value === value)) {
            field.value = value;
            setValue(ctrl, value);
          }
        } else {
          field.value = value;
          setValue(ctrl, value);
        }
      }
    }

    setValue(this.checkboxForm.controls.boringFrontBox, options.boringFront);
    setValue(this.checkboxForm.controls.boringCutleryTrayBox, options.boringCutleryTray);
    setValue(this.checkboxForm.controls.bundSkruetBox, options.bundSkruet);
    setValue(this.checkboxForm.controls.bottomUnmountedCheckbox, options[OptionProperty.bottomUnmounted]);
    setValue(this.checkboxForm.controls.ligeOverkantBox, options.straightLine);
    setValue(this.checkboxForm.controls.fscBox, options[OptionProperty.fscCertified]);
    setValue(this.checkboxForm.controls.surfaceTreatmentUndersideBox, options[OptionProperty.surfaceTreatmentUnderside]);
    setValue(this.checkboxForm.controls.boerstetBox, options.boerstet);
    setValue(this.checkboxForm.controls.skuffe20mmFrontBox, options.skuffe20mmFront);
    setValue(this.checkboxForm.controls.hulboringBox, options.hulboring);
    if (this.userService.isLoggedIn && this.userService.isAdmin()) {
      setValue(this.checkboxForm.controls.upPricesBox, options.isUpPrice);
    }

    for (const row of this.model || []) {
      for (const field of row.fields || []) {
        const key = field.name;
        const ctrl = this.form.getControl(key);

        if (field.show && ctrl)
          ctrl.setValue(ctrl.value);
      }
    }

    if (this.udsparingShow) {
      if (options.udsparing) {
        if (options.udsparing.hasOwnProperty('ef')) {
          const siphonCuttingOptions = options.udsparing as ISiphonCuttingOptions;
          this.siphonCuttingDefaults = {
            ab: siphonCuttingOptions.ab,
            bc: siphonCuttingOptions.bc,
            cd: siphonCuttingOptions.cd,
            ef: siphonCuttingOptions.ef,
            width: this.skuffeBredde.value,
            depth: this.skuffeDybde.value,
            existing: true,
            special: options.udsparing.special
          };
          this.udspComp.defaults = this.siphonCuttingDefaults;
          this.udspComp.siphonCuttingInit();
          this.udsparing = this.udspComp.getSiphonCutting();
        } else {
          const udsparing = options.udsparing as IRearUdsparingOptions;
          this.rearUdsparingDefaults = {
            ab: udsparing.ab,
            bc: udsparing.bc,
            cd: udsparing.cd,
            de: udsparing.de,
            fg: udsparing.fg,
            gh: udsparing.gh,
            width: this.skuffeBredde.value,
            depth: this.skuffeDybde.value,
            existing: true,
            special: options.udsparing.special
          };
          this.rearUdspComp.defaults = this.rearUdsparingDefaults;
          this.rearUdspComp.udsparingInit();
          this.rearUdsparing = this.rearUdspComp.getUdsparing();
        }
        if (this.hulboringShow) {
          this.setCheckboxValue('hulboringBox', false);
          disableControl(this.checkboxForm.controls['hulboringBox']);
        }
      }
    }
    if (this.bestikindsatsShow) {
      if (options.bestikindsats) {
        this.bestikindsats = options.bestikindsats;
        this.bscomp.setDefaultValues(this.bestikindsats);
      }
    }

    if (this.grebShow) {
      this.greb = options.greb;
    }
    if (this.logoShow) {
      this.logo = options.logo;
    }

    if ((this.isEdit && this.isDrawerType('rev_ezug')) || (this.isRepeat && isTrue([this.drawerType, 'contains', 'rev_ezug']))) {
      this.revEzugMaal.revEzugMaal.ab = options.reverseEnglisherZug.ab;
      this.revEzugMaal.revEzugMaal.cd = options.reverseEnglisherZug.cd;
    }

    if ((this.isEdit && this.isDrawerType('purewood-rev-ezug')) || (this.isRepeat && isTrue([this.drawerType, 'contains', 'purewood-rev-ezug']))) {
      this.purewoodRevEzugMaal.revEzugMaal.ab = options.reverseEnglisherZug.ab;
      this.purewoodRevEzugMaal.revEzugMaal.cd = options.reverseEnglisherZug.cd;
    }

    if ((this.isEdit && this.isDrawerType('ernst-mair-rear-ezug')) || (this.isRepeat && isTrue([this.drawerType, 'contains', 'ernst-mair-rear-ezug']))) {
      this.ernstRearEzugMaal.rearEzugMaal.ab = options.rearEnglisherZug.ab;
      this.ernstRearEzugMaal.rearEzugMaal.cd = options.rearEnglisherZug.cd;
    }
    if ((this.isEdit && this.isDrawerType('ernst-mair-rev-ezug')) || (this.isRepeat && isTrue([this.drawerType, 'contains', 'ernst-mair-rev-ezug']))) {
      this.ernstRevEzugMaal.revEzugMaal.ab = options.reverseEnglisherZug.ab;
      this.ernstRevEzugMaal.revEzugMaal.cd = options.reverseEnglisherZug.cd;
    }
  }

  public isDrawerType(...types: string[]): boolean {
    return isTrue([this.drawer.type, 'contains', types]);
  }

  async goto(url: string) {
    await this.router.navigateByUrl(url);
  }

  private checkIfCutleryTrayIsValid() {
    if (this.activeCutleryTrayToast) {
      this.activeCutleryTrayToast.toastRef.close();
    }

    const innerDrawerWidthControl = this.form.getControl(OptionProperty.drawerOuterWidth);

    const activeRunnerDepthControl = this.getActiveRunnerDepthControl();
    const heightControl = this.form.getControl(OptionProperty.height);
    const typeOfWoodControl = this.form.getControl(OptionProperty.typeOfWood);

    if (
      innerDrawerWidthControl.value === null ||
      activeRunnerDepthControl.value === null ||
      typeOfWoodControl.value === null ||
      heightControl.value === null
    ) {
      this.activeCutleryTrayToast = this.toastrService.error(this.translate('DybdeBreddeHøjdeTræsortMissing'));
      return false;
    }

    const heightIncrement = 20;
    let min52 = 75;
    let min92 = 115;

    if (heightControl.value <= 55) {
      this.activeCutleryTrayToast = this.toastrService.error(this.translate('MinHøjdeForAtTilføjeBestikindsats'));
      return false;
    }

    // Drawer-base value:
    const drawerBaseCtrl: any = this.form.getControl(OptionProperty.drawerBase);
    const drawerBaseValue = parseInt(drawerBaseCtrl.value.replace(/\D+/g, ''), 10);

    // vi skal checke om vi har greb i
    if (this.greb) {
      min52 = 115;
      min92 = 155;
    }

    if (drawerBaseValue > 9) {
      min52 += heightIncrement;
      min92 += heightIncrement;
    }

    if (this.bestikindsats && this.bestikindsats[OptionProperty.height] === 52) {
      if (heightControl.value < min52) {
        this.activeCutleryTrayToast = this.toastrService.error(this.translate('BestikIndsatsCombiInvalid'), null, { timeOut: 10000 });
        return false;
      }
    } else if (this.bestikindsats && this.bestikindsats[OptionProperty.height] === 92) {
      if (heightControl.value < min92) {
        this.activeCutleryTrayToast = this.toastrService.error(this.translate('BestikIndsatsCombiInvalid'), null, { timeOut: 10000 });
        return false;
      }
    }

    if (this.bestikindsats) {
      const updatedCutleryTray = this.bscomp.getCutleryTrayOptions();
      if (!updatedCutleryTray) {
        this.activeCutleryTrayToast = this.toastrService.error(this.translate('CutleryTray.conditionsFail.title'), this.translate('CutleryTray.conditionsFail.message'));
        return false;
      }
      this.bestikindsats = updatedCutleryTray;
    }

    return true;
  }

  private calculateCutleryTrayWidth() {
    return this.skuffeBredde.value - 29;
  }

  private loadDrawerOptions() {
    for (const row of this.model) {
      for (const field of row.fields || []) {

        if (field.name === OptionProperty.woodQuality) {
          field.options = this.drawerOptions.filter((o) => o.property === field.name && o.types.includes(this.drawer.type));
          if (this.userService.isAdmin() && (this.drawerType === 'purewood-innenlade' || this.drawerType === 'purewood-ils')) {
            field.options.push({
              value: 'superior',
              index: 1000,
              label: 'KVALITET.admin.superior'
            });
          }

        } else if (field.name === OptionProperty.typeOfWood) {
          const relOpts = this.drawerOptions.filter((o) => {
            return o.property === field.name;
          });
          relOpts.forEach((o) => {
            if (this.isErnstMair || this.isPurewood) {
              if (o.types.indexOf(this.manufacturer) > -1) {
                this.superiorTreesorts.push(o);
                this.yachtTreesorts.push(o);
              }
            } else {
              if (o.types.indexOf(OptionType.superior) > -1) {
                this.superiorTreesorts.push(o);
              }

              if (o.types.indexOf(OptionType.cabinet) > -1) {
                this.cabinetTreesorts.push(o);
              }

              if (o.types.indexOf(OptionType.yacht) > -1) {
                this.yachtTreesorts.push(o);
              }

              if (o.types.indexOf('20mm') > -1) {
                this.treesortsFor20mm.push(o);
              }
            }
          });
          // There is default sorting in the database, but alphabetic sorting is preferred:
          this.superiorTreesorts.sort(this.utilities.abcSort);
          this.cabinetTreesorts.sort(this.utilities.abcSort);
          this.yachtTreesorts.sort(this.utilities.abcSort);
          this.treesortsFor20mm.sort(this.utilities.abcSort);
          field.options = relOpts;

        } else if (field.name === OptionProperty.joint) {
          field.options = this.drawerOptions.filter((o) => (o.property === field.name && o.types.includes(this.drawer.type)));

        } else if (field.name === OptionProperty.surfaceTreatment) {
          const relOpts = this.drawerOptions.filter((o) => {
            if (this.isErnstMair || this.isPurewood) {
              return o.property === field.name && (o.types.indexOf(this.manufacturer) > -1);
            } else {
              return o.property === field.name;
            }
          });
          relOpts.forEach((o) => {
            this.allSurfaces.push(o);
          });
          field.options = this.allSurfaces;

        } else if (field.name === OptionProperty.runnerMark) {
          field.options = this.drawerOptions.filter((o) => o.property === field.name && o.types.indexOf(this.manufacturer) > -1);

        } else if (field.name === OptionProperty.drawerBase) {
          if (this.isNothegger || this.isPurewood) {
            field.options = this.drawerOptions.filter((o) => (o.property === field.name && !ExcludeNotheggerBottoms.includes(o.value)));
          } else if (this.isErnstMair) {
            field.options = this.drawerOptions.filter((o) => (o.property === field.name && ErnstMairBottoms.includes(o.value)));
          }
        } else if (field.name === OptionProperty.thickness) {
          const fieldOptions = this.drawerOptions.filter((o) => o.property === field.name && o.types.indexOf(this.drawer.type) > -1);

          field.options = fieldOptions;
        } else if (field.options) {
          const fieldOptions = this.drawerOptions.filter((o) => o.property === field.name);
          if (fieldOptions.length) {
            if (field.options.length && fieldOptions.length !== field.options.length) {
              console.warn(field.name + ' double', field.options, fieldOptions);
            }
            field.options = fieldOptions;
          }

          // We need a list of  all runner types
          if (field.name === OptionProperty.runnerType) {
            this.runnerTypeOptions = fieldOptions;
          }
        }
      }
    }
  }

  /**
   * Gets depth and width adjustments for a drawer, based on the selected kind of runner mark and whether a 20 mmm front is selected.
   * @todo Contains a lot of ugly hardcoded values. Maybe some of these should be placed on the drawer type entities in the database.
   * @param runnerMark
   * @param twentyMmFront
   * @private
   */
  private getDepthAndWidthAdjustments({ runnerMark, twentyMmFront, thickness }: { runnerMark: string, twentyMmFront: boolean, thickness?: string | null; }): {
    runnerDepthToDrawerDepthAdjustment: number,
    drawerOuterToInnerWidthAdjustment: number
  } {
    const InnenladeLysmaalToSkuffeBreddeAdjustment = -15;
    const InnenladeRevEzugRunnerDepthToDrawerDepthAdjustment = 3;

    const DesignCurveRunnerDepthToDrawerDepthAdjustment = 50;

    const HInnenladeLysmaalToSkuffeBreddeAdjustment = -13;
    const HInnenladeRunnerDepthToDrawerDepthAdjustment = 13;

    const HettichLysmaalToSkuffeBreddeAdjustment = -13;
    const HettichRunnerDepthToDrawerDepthAdjustment = 0;
    const Hettich5dRunnerDepthToDrawerDepthAdjustment = -10;

    const OtherLysmaalToSkuffeBreddeAdjustment = -15;
    const OtherRunnerDepthToDrawerDepthAdjustment = -10;

    const SchutteRunnerDepthToDrawerDepthAdjustment = 0;
    const SchutteLysmaalToSkuffeBreddeAdjustment = 0;

    const IlsLysmaalToSkuffeBreddeAdjustment = 0;

    const Skuffe20mmFrontAdjustment = 7;

    let depthAdjustment: number;
    let widthAdjustment: number;

    const isHettichRunner = isTrue([runnerMark, 'contains', 'hettich']);
    const isBlumOrGrassRunner = isTrue([runnerMark, 'contains', 'blum']) || isTrue([runnerMark, 'contains', 'grass']);
    if (isHettichRunner) {
      const isHettich5dRunner = isTrue([runnerMark, 'contains', '5d']);
      const isHettich4dRunner = isTrue([runnerMark, 'contains', '4d']);

      if (isHettich5dRunner) {
        // Hettich Actro 5D
        if (this.isDrawerType('purewood-innenlade')) {
          depthAdjustment = switchThickness({
            default: 5,
            [OptionThickness.Purewood13_5MM]: 3,
            [OptionThickness.Purewood14_6MM]: 5
          });
          widthAdjustment = switchThickness({
            default: -12,
            [OptionThickness.Purewood13_5MM]: -15,
            [OptionThickness.Purewood14_6MM]: -13
          });
        } else if (this.isDrawerType('purewood-type-b')) {
          depthAdjustment = -10;
          widthAdjustment = switchThickness({
            default: -12,
            [OptionThickness.Purewood13_5MM]: -15,
            [OptionThickness.Purewood14_6MM]: -13
          })
        } else if (this.isDrawerType('purewood-rev-ezug')) {
          depthAdjustment = switchThickness({
            default: Hettich5dRunnerDepthToDrawerDepthAdjustment,
            [OptionThickness.Purewood13_5MM]: 3,
            [OptionThickness.Purewood14_6MM]: 5
          });
          widthAdjustment = switchThickness({
            default: OtherLysmaalToSkuffeBreddeAdjustment,
            [OptionThickness.Purewood13_5MM]: -15,
            [OptionThickness.Purewood14_6MM]: -13
          })
        } else if (this.isDrawerType('curve')) {
          depthAdjustment = Hettich5dRunnerDepthToDrawerDepthAdjustment + DesignCurveRunnerDepthToDrawerDepthAdjustment;
          widthAdjustment = OtherLysmaalToSkuffeBreddeAdjustment;
        } else if (this.isDrawerType('innenlade')) {
          depthAdjustment = InnenladeRevEzugRunnerDepthToDrawerDepthAdjustment;
          widthAdjustment = InnenladeLysmaalToSkuffeBreddeAdjustment;
        } else if (this.isDrawerType('ezug')) {
          depthAdjustment = InnenladeRevEzugRunnerDepthToDrawerDepthAdjustment;
          widthAdjustment = OtherLysmaalToSkuffeBreddeAdjustment;
        } else {
          depthAdjustment = Hettich5dRunnerDepthToDrawerDepthAdjustment;
          widthAdjustment = OtherLysmaalToSkuffeBreddeAdjustment;
        }
      } else if (isHettich4dRunner) {
        if (this.isDrawerType('purewood-innenlade')) {
          depthAdjustment = switchThickness({
            default: 15,
            [OptionThickness.Purewood13_5MM]: 13,
            [OptionThickness.Purewood14_6MM]: 11
          });
          widthAdjustment = switchThickness({
            default: -10,
            [OptionThickness.Purewood13_5MM]: -13,
            [OptionThickness.Purewood14_6MM]: -11
          });
        } else if (this.isDrawerType('purewood-type-b')) {
          depthAdjustment = 0;
          widthAdjustment = switchThickness({
            default: -10,
            [OptionThickness.Purewood13_5MM]: -13,
            [OptionThickness.Purewood14_6MM]: -11
          });
        } else if (this.isDrawerType('purewood-rev-ezug')) {
          depthAdjustment = HettichRunnerDepthToDrawerDepthAdjustment;
          widthAdjustment = switchThickness({
            default: HettichLysmaalToSkuffeBreddeAdjustment,
            [OptionThickness.Purewood13_5MM]: -13,
            [OptionThickness.Purewood14_6MM]: -11
          });
        } else if (this.isDrawerType('innenlade') && !this.isDrawerType('designcurve') || this.isDrawerType('ezug')) {
          depthAdjustment = HInnenladeRunnerDepthToDrawerDepthAdjustment;
          widthAdjustment = HInnenladeLysmaalToSkuffeBreddeAdjustment;
        } else if (this.isDrawerType('designcurve')) {
          depthAdjustment = DesignCurveRunnerDepthToDrawerDepthAdjustment + HettichRunnerDepthToDrawerDepthAdjustment;
          widthAdjustment = HettichLysmaalToSkuffeBreddeAdjustment;
        } else {
          depthAdjustment = HettichRunnerDepthToDrawerDepthAdjustment;
          widthAdjustment = HettichLysmaalToSkuffeBreddeAdjustment;
        }
      }
    } else {
      if (this.isDrawerType('purewood-innenlade')) {
        depthAdjustment = 5;
        widthAdjustment = -12;
      } else if (this.isDrawerType('purewood-type-b')) {
        depthAdjustment = -10;
        widthAdjustment = -12;
      } else if (this.isDrawerType('purewood-rev-ezug')) {
        depthAdjustment = OtherRunnerDepthToDrawerDepthAdjustment;
        widthAdjustment = OtherLysmaalToSkuffeBreddeAdjustment;
      } else if (this.isDrawerType('curve')) {
        depthAdjustment = OtherRunnerDepthToDrawerDepthAdjustment + DesignCurveRunnerDepthToDrawerDepthAdjustment;
        widthAdjustment = OtherLysmaalToSkuffeBreddeAdjustment;
      } else if (this.isDrawerType('innenlade')) {
        depthAdjustment = InnenladeRevEzugRunnerDepthToDrawerDepthAdjustment;
        widthAdjustment = InnenladeLysmaalToSkuffeBreddeAdjustment;
      } else if (this.isDrawerType('ezug')) {
        depthAdjustment = InnenladeRevEzugRunnerDepthToDrawerDepthAdjustment;
        widthAdjustment = OtherLysmaalToSkuffeBreddeAdjustment;
      } else if (this.isDrawerType('schutte')) {
        depthAdjustment = SchutteRunnerDepthToDrawerDepthAdjustment;
        widthAdjustment = SchutteLysmaalToSkuffeBreddeAdjustment;
      } else if (this.isDrawerType('ils')) {
        depthAdjustment = OtherRunnerDepthToDrawerDepthAdjustment;
        widthAdjustment = IlsLysmaalToSkuffeBreddeAdjustment;
      } else {
        depthAdjustment = OtherRunnerDepthToDrawerDepthAdjustment;
        widthAdjustment = OtherLysmaalToSkuffeBreddeAdjustment;
      }

      const isBlumMovento = isTrue([runnerMark, 'contains', 'movento']);
      if (isBlumMovento) {
        if (this.isDrawerType('purewood-innenlade')) {
          depthAdjustment = switchThickness({
            default: depthAdjustment,
            [OptionThickness.Purewood13_5MM]: 3,
            [OptionThickness.Purewood14_6MM]: 5
          });
          widthAdjustment = switchThickness({
            default: widthAdjustment,
            [OptionThickness.Purewood13_5MM]: -15,
            [OptionThickness.Purewood14_6MM]: -13
          });
        } else if (this.isDrawerType('purewood-type-b')) {
          depthAdjustment = switchThickness({
            default: depthAdjustment,
            [OptionThickness.Purewood13_5MM]: -10,
            [OptionThickness.Purewood14_6MM]: -10
          });
          widthAdjustment = switchThickness({
            default: widthAdjustment,
            [OptionThickness.Purewood13_5MM]: -15,
            [OptionThickness.Purewood14_6MM]: -13
          });
        } else if (this.isDrawerType('purewood-rev-ezug')) {
          depthAdjustment = switchThickness({
            default: depthAdjustment,
            [OptionThickness.Purewood13_5MM]: 3,
            [OptionThickness.Purewood14_6MM]: 5
          });
          widthAdjustment = switchThickness({
            default: widthAdjustment,
            [OptionThickness.Purewood13_5MM]: -15,
            [OptionThickness.Purewood14_6MM]: -13
          });
        }
      }
    }

    if (twentyMmFront) {
      depthAdjustment += Skuffe20mmFrontAdjustment;
    }

    function switchThickness(thicknessAdjustment: Record<string & {} | "default", number>) {
      if (thickness == null) {
        return thicknessAdjustment.default;
      }

      return thicknessAdjustment[thickness] || thicknessAdjustment.default;
    }

    return {
      runnerDepthToDrawerDepthAdjustment: depthAdjustment,
      drawerOuterToInnerWidthAdjustment: widthAdjustment
    };
  }

  private getDrawerDescription(orderOptions: IDrawerOptions): string {
    const drawerWidth = orderOptions.skuffeBredde;
    const drawerDepth = orderOptions.skuffeDybde;

    const isDrawerFront = !this.isErnstMair && this.isDrawerType('ils');
    if (isDrawerFront) {
      const frontHeight = orderOptions.frontHoejde;

      return this.translate(`CARTVALUE.drawerDescription.ils`, { bredde: drawerWidth, height: frontHeight })
    }

    return this.translate('CARTVALUE.drawerDescription.normal', {
      bredde: drawerWidth,
      dybde: drawerDepth,
      height: orderOptions.skuffeHoejde,
      [OptionProperty.typeOfWood]: this.translate('TREESORTS.' + orderOptions[OptionProperty.typeOfWood])
    });
  }

}
