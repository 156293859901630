<div class="container">
    <div class="row mb-3">
        <div class="col-sm">
            <div class="mb-2 text-muted">
                <span>
                    <a (click)=goto() style="cursor:pointer">Ernst Mair</a>
                    <span *ngIf="isDrawer">
                        / <a (click)="goto('drawers')" style="cursor:pointer">Drawers</a>
                        <span *ngIf="drawer">
                            / <a style="cursor:pointer">{{drawer.name | translate}}</a>
                        </span>
                    </span>
                </span>
            </div>
        </div>
        <div class="col-sm-2 text-end pb-3">
            <button appBackButton class="btn btn-primary">
                <fa-icon [icon]="faWindowClose"></fa-icon>
                {{ 'UTILITY.CANCEL' | translate }}
            </button>
        </div>
    </div>

    <div class="row pb-3">
        <div *ngIf="drawer" class="col-sm">
            <div class="row">
                <div *ngFor="let locale of drawer.locales" class="col-sm-3 mb-4">
                    <div class="card border-light shadow-sm rounded">
                        <div class="card-header">
                            {{ getLanguageName(locale.lang) }}
                        </div>
                        <div class="card-body">
                            <div>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <label class="form-label">{{drawer.name | translate}}</label>
                                    </div>
                                    <div class="ernst-image mb-3">
                                        <img [src]="drawer.img" alt=""/>
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="form-label">{{'UTILITY.DESC' | translate }}</label>
                                        <textarea Rows="10" [(ngModel)]="locale.description" class="form-control"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button (click)=updateDrawer() class="btn btn-success float-end">{{ 'Opdater' | translate }}
                <fa-icon [icon]="faCheck"></fa-icon>
            </button>
        </div>
        <div *ngIf="isDrawer === false" class="col-sm">
            <div class="row">
                <div *ngFor="let description of ernstMairDescriptions" class="col-sm-3 mb-4">
                    <div class="card border-light shadow-sm rounded">
                        <div class="card-header">
                            {{ getLanguageName(description.id) }}
                        </div>
                        <div class="card-body">
                            <div>
                                <div class="row">
                                    <h5>Ernst Mair</h5>
                                    <div class="ernst-image mb-3">
                                        <img alt="" src="/assets/images/ernst-mair/ernst-mair.png"/>
                                    </div>
                                    <div>
                                        <label class="form-label">{{'UTILITY.DESC' | translate }}</label>
                                        <textarea Rows="10" [(ngModel)]="description.value" class="form-control"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button (click)=updateErnstMairDescriptions() class="btn btn-success float-end">{{ 'Opdater' | translate }}
                <fa-icon [icon]="faCheck"></fa-icon>
            </button>
        </div>
    </div>
</div>
