import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { APIService, CartService, ModelLoaderService, UtilitiesService } from '../../services';
import { defaultBreadcrumbs } from '../../utils/breadcrumbs/breadcrumbs';
import { DynamicFormComponent } from '../../dynamicForm/dynamic-form/dynamic-form.component';
import { FormRow } from '../../dynamicForm/dynaform.interfaces';
import { TranslatingBase } from '../../base-component/ComponentBase';
import { CartItemType, CartSubType, IOtherItemOptions, ITypedAddCartItem } from '../../../../../wdcommon/ICartItem';
import { ExternalShopName } from '../../../../../wdcommon/IExternalShop';
import { OptionProperty, Manufacturer, OtherProductShortName } from '../../../../../wdcommon/IProduct';

const productTypes = {
  ['10.0088-34']: {
    volume: 34,
    internalWidth: 412,
    width: 397,
    depth: 440,
    height: 345,
    treatment: 'lakeret',
    cans: [{
      volume: 15,
      bio: true
    }, {
      volume: 19
    }]
  },
  ['10.0088-49']: {
    volume: 49,
    internalWidth: 562,
    width: 547,
    depth: 440,
    height: 345,
    treatment: 'lakeret',
    cans: [{
      volume: 19
    }, {
      volume: 15,
      bio: true
    }, {
      volume: 15
    }]
  },
  ['10.0088-77']: {
    volume: 77,
    internalWidth: 862,
    width: 847,
    depth: 440,
    height: 345,
    treatment: 'lakeret',
    cans: [{
      volume: 16
    }, {
      volume: 16
    }, {
      volume: 15,
      bio: true
    }, {
      volume: 30
    }]
  }
};

const productTypeVar = 'productType';
const woodTypeVar = OptionProperty.typeOfWood;

@Component({
  selector: 'app-trashcan-drawer',
  templateUrl: './trashcan-drawer.component.html',
  styleUrls: ['./trashcan-drawer.component.css']
})
export class TrashcanDrawerComponent extends TranslatingBase implements OnInit, AfterViewInit {
  trashcanBreadcrumb = defaultBreadcrumbs.notheggerTrashcanDrawer;

  @ViewChild(DynamicFormComponent) form: DynamicFormComponent;

  trashcanDrawer = OtherProductShortName.trashcanDrawer;
  allProductTypes = Object.entries(productTypes);
  formIsInvalid = true;
  model: FormRow[] = [];
  amount = 1;
  productOptions: any;

  constructor(
      private formBuilder: UntypedFormBuilder,
      private translateService: TranslateService,
      private router: Router,
      private utilities: UtilitiesService,
      private modelLoader: ModelLoaderService,
      private api: APIService,
      private cartService: CartService,
      private toastrService: ToastrService,
  ) {
    super(translateService);
  }

  ngOnInit() {
    this.model = this.modelLoader.load(this.trashcanDrawer);
  }

  async ngAfterViewInit() {
    this.productOptions = await this.api.getProductOptions();
    this.loadOptions();
  }

  loadOptions() {
    for (const row of this.model) {
      for (const field of row.fields || []) {
        if (field && field.name === woodTypeVar) {
          field.options = this.productOptions
              .filter((o) => (o.property === woodTypeVar && (o.types.indexOf(this.trashcanDrawer) > -1)));
        } else if (field && field.name === productTypeVar) {
          field.options = [];
          Object.entries(productTypes).forEach((productTypeEntry) => {
            field.options.push({ label: productTypeEntry[1].volume + ' ' + this.translate('liter'), value: productTypeEntry[0] });
          });
        }
      }
    }
  }

  isActive(type: string): boolean {
    return this.form !== undefined && this.form.getControl(productTypeVar).value === type;
  }

  setType(type: string) {
    const control = this.form.getControl(productTypeVar);
    if (control && control.value !== type) {
      control.setValue(type === undefined ? null : type);
    }
  }

  validateForm() {
    this.formIsInvalid = this.form.invalid;
  }

  async amountApproved(approved: boolean) {
    if (approved) {
      await this.finalizeOrder();
    }
  }

  async goto(url: string) {
    await this.router.navigateByUrl(url);
  }

  private async finalizeOrder() {
    const type: string = this.form.getControl(productTypeVar).value;
    const cartItemOptions: IOtherItemOptions = {
      type,
      [OptionProperty.width]: productTypes[type].width,
      [OptionProperty.depth]: productTypes[type].depth,
      [OptionProperty.height]: productTypes[type].height,
      [OptionProperty.typeOfWood]: this.form.getControl(woodTypeVar).value,
      [OptionProperty.surfaceTreatment]: productTypes[type].treatment
    };

    const shortTypeEncoded = btoa(cartItemOptions.type + cartItemOptions[OptionProperty.typeOfWood].substring(0, 2).toUpperCase());
    const itemNo: any = await this.api.getVarenr(shortTypeEncoded);

    const formattedType = cartItemOptions.type + ' '
        + this.translateService.instant('TREESORTS.' + cartItemOptions[OptionProperty.typeOfWood]);

    const orderObj: ITypedAddCartItem = {
      brandId: Manufacturer.nothegger,
      type: CartItemType.extra,
      subType: CartSubType.trashcanDrawer,
      name: this.translate('OTHER_NAMES.TRASHCAN_DRAWER'),
      itemno: itemNo.varenr,
      description: formattedType,
      amount: this.amount,
      options: cartItemOptions,
      externalShopName: ExternalShopName.nothegger
    };

    await this.cartService.addExtra(orderObj);
    this.toastrService.success(this.translate('Cart.AddedToCart'));
    await this.router.navigateByUrl('/');
  }

}
