<div class="container">
    <div class="row mb-3">

        <div class="col-12">
            <app-breadcrumbs [currentBreadcrumb]="carcassBreadcrumb"></app-breadcrumbs>
        </div>

    </div>
    <div class="row">

        <tabset class="w-100 mt-3">
            <tab [heading]="'CABINET_CARCASS.EXPLAIN_HEADER' | translate" class="pt-4">
                <h3 [innerHTML]="'CABINET_CARCASS.EXPLAIN_HEADER' | translate"></h3>
                <img src="/assets/images/cabinet-carcass.png" [alt]="'CABINET_CARCASS.EXPLAIN_HEADER' | translate" />
                <div [innerHTML]="'CABINET_CARCASS.EXPLAIN' | translate | newLineToBR"></div>
            </tab>

            <tab heading="{{ 'CABINET_CARCASS.CUPBOARD' | translate }}" class="pt-4">
                <app-cupboard></app-cupboard>
            </tab>

            <tab heading="{{ 'CABINET_CARCASS.WALL_UNIT' | translate }}" class="pt-4">
                <app-wall-unit></app-wall-unit>
            </tab>

            <tab heading="{{ 'CABINET_CARCASS.BASE_UNIT' | translate }}" class="pt-4">
                <app-base-unit></app-base-unit>
            </tab>

            <tab heading="{{ 'CABINET_CARCASS.BOARD' | translate }}" class="pt-4">
                <app-board></app-board>
            </tab>
        </tabset>

    </div>
</div>
