<div class="container">
  <div class="row">

    <div class="col-12">
      <app-breadcrumbs [currentBreadcrumb]="sortissimoBreadcrumb"></app-breadcrumbs>
    </div>

  </div>
  <div class="row">

    <div class="col-sm-8">
      <app-dynamic-form (submit)="submit()" [autoFocusFirst]="true" [rows]="model"></app-dynamic-form>
      <div *ngIf="isSortissimoWidthValid() && isSortissimoDepthValid() && special" class="col-sm alert alert-info">
        <h5 class="alert-heading">Special størrelse på mål</h5>
        <p>Bemærk at dette vil medføre et tillæg.
          <br>Vælg evt. en anden bestikindsats type.
        </p>
      </div>
      <div *ngIf="!isSortissimoDepthValid()" class="col-sm alert alert-danger">
        <h5 class="alert-heading">Denne bestikindsats type kan ikke vælges med den valgte dybde</h5>
        <p>Vælg evt. en anden bestikindsats type eller en anden skuffedybde.</p>
      </div>
      <div *ngIf="!isSortissimoWidthValid()" class="col-sm alert alert-danger">
        <h5 class="alert-heading">{{'Order.Bestikindsats.BreddeErrorHeader' | translate }}</h5>
        <p>{{'Order.Bestikindsats.BreddeErrorBody' | translate }}</p>
      </div>
    </div>

    <div class="col-sm-4">
      <div class="card">
        <div class="card-body">
          <p class="card-text">{{ 'WoodenDrawersTilbyderSortissimoBestikindsats' | translate }}</p>
          <div class="row">
            <div class="col-sm-6">U1 <img class="bs-img" alt=""
                   (click)="setType('U1')"
                   [ngClass]="{ 'active': isActive('U1') }"
                   src="/assets/images/bestikindsats/u1.png" /></div>
            <div class="col-sm-6">U2 <img class="bs-img" alt=""
                   (click)="setType('U2')"
                   [ngClass]="{ 'active': isActive('U2') }"
                   src="/assets/images/bestikindsats/u2.png" /></div>
            <div class="col-sm-6">U3 <img class="bs-img" alt=""
                   (click)="setType('U3')"
                   [ngClass]="{ 'active': isActive('U3') }"
                   src="/assets/images/bestikindsats/u3.png" /></div>
            <div class="col-sm-6">U4 <img class="bs-img" alt=""
                   (click)="setType('U4')"
                   [ngClass]="{ 'active': isActive('U4') }"
                   src="/assets/images/bestikindsats/u4.png" /></div>
            <div class="col-sm-6">U5 <img class="bs-img" alt=""
                   (click)="setType('U5')"
                   [ngClass]="{ 'active': isActive('U5') }"
                   src="/assets/images/bestikindsats/u5.png" /></div>
            <div class="col-sm-6">U6 <img class="bs-img" alt=""
                   (click)="setType('U6')"
                   [ngClass]="{ 'active': isActive('U6') }"
                   src="/assets/images/bestikindsats/u6.png" /></div>
            <div class="col-sm-6">U7 <img class="bs-img" alt=""
                   (click)="setType('U7')"
                   [ngClass]="{ 'active': isActive('U7') }"
                   src="/assets/images/bestikindsats/u7.png" /></div>
            <div class="col-sm-6">U8 <img class="bs-img" alt=""
                   (click)="setType('U8')"
                   [ngClass]="{ 'active': isActive('U8') }"
                   src="/assets/images/bestikindsats/u8.png" /></div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
