import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { APIService, CartItemDiscountService, CartItemFactory, CartItemFactoryFactory, HettichService, UserService, UtilitiesService } from './';
import { TranslatingBase } from '../base-component/ComponentBase';
import { AdditionalCartItem, CartItem, UserError } from './CartItems';
import { CartItemType, IAddCart, IAddCartItem, ICartItem, IDrawerAddCartItem, ITypedAddCartItem, ITypedDrawerAddCartItem } from '../../../../wdcommon/ICartItem';
import { ExternalShopName } from '../../../../wdcommon/IExternalShop';
import { ICart } from '../../../../wdcommon/IOrder';
import { Manufacturer } from '../../../../wdcommon/IProduct';
import { ToastrService } from 'ngx-toastr';

type Cart = CartItem[];

@Injectable({
  providedIn: 'root'
})
export class CartService extends TranslatingBase {

  private readonly _cartItemFactory: CartItemFactory;
  private readonly _userService: UserService;
  private readonly _utilities: UtilitiesService;
  private readonly _cartItemDiscountService: CartItemDiscountService;

  private _existingCart: { orderId: string, id: number; } | undefined = undefined;
  private _saveCartOnMutate = true;
  private _publishOnMutate = true;
  private _cartSubject: BehaviorSubject<Cart>;

  constructor(
      private hettichService: HettichService,
      protected toastrService: ToastrService,
      apiService: APIService,
      userService: UserService,
      cartItemFactoryFactory: CartItemFactoryFactory,
      utilities: UtilitiesService,
      cartItemDiscountService: CartItemDiscountService,
      translateServer: TranslateService,
  ) {
    super(translateServer);
    if (!userService)
      throw new Error('Parameter \'userService\' cannot be null.');
    if (!cartItemFactoryFactory)
      throw new Error('Parameter \'cartItemFactoryFactory\' cannot be null.');
    if (!utilities)
      throw new Error('Parameter \'utilities\' cannot be null.');

    this._userService = userService;
    this._cartItemFactory = cartItemFactoryFactory.create(() => this._userService.getCompanyAdjustments(), () => this.getCart());
    this._utilities = utilities;
    this._cartItemDiscountService = cartItemDiscountService;

  }

  public get isEditingExistingCart() {
    return this._existingCart !== undefined;
  }

  public get translatedEditTitle(): string {
    return this.translate('CART.IsEditing', { orderId: this._existingCart.orderId });
  }

  private checkUserStatus(displayError: boolean): boolean {
    if (!this._userService.isLoggedIn) {
        if (displayError) {
            this.toastrService.error(this.translate('CART.LoginRequired'));
        }
        return false;
    }

    if (!this._userService.isUserActive()) {
        if (displayError) {
            this.toastrService.error(this.translate('CART.ActivationRequired'));
        }
        return false;
    }

    return true;
  }

  public checkCanAddToCart(): boolean {
      return this.checkUserStatus(false);
  }

  public checkCanAddToCartAndDisplayError(): boolean {
      return this.checkUserStatus(true);
  }

  public async initialize(): Promise<void> {

    console.debug('CartService.initialize...');
    try {
      await this.loadCart();
    } catch (ex) {
      console.warn('Unable to deserialize cart. Discarding.', ex);

    }
  }

  public addDrawer(item: IDrawerAddCartItem, index?: number): Promise<void> {
    const typedItem = item as ITypedDrawerAddCartItem;
    typedItem.type = CartItemType.drawer;
    if (item.options.type.indexOf(Manufacturer.ernstMair) === -1 && item.options.type.indexOf(Manufacturer.purewood) === -1) {
      typedItem.externalShopName = ExternalShopName.nothegger;
    }
    return this.addItem(typedItem, index);
  }

  public addExtra(item: IAddCartItem, index?: number): Promise<void> {
    const typedItem = item as ITypedAddCartItem;
    typedItem.type = CartItemType.extra;
    return this.addItem(typedItem, index);
  }

  public addAdditional(item: IAddCartItem, index?: number): Promise<void> {
    const typedItem = item as ITypedAddCartItem;
    typedItem.type = CartItemType.additional;
    return this.addItem(typedItem, index);
  }

  public async setAmount(item: ICartItem | string, newAmount: number): Promise<undefined | string | any> {
    if (!item)
      throw new Error('Parameter \'item\' cannot be null.');
    if (!isFinite(newAmount) || newAmount < 0)
      throw new Error('Parameter \'newAmount\' must be a finite, non-negative integer.');

    const { generated, nonGenerated } = this.getItemByItemNo(item);

    if (generated && nonGenerated) {

      if (newAmount < generated.amount) {
        await this.publishNewCart();
        return;
      }

      newAmount = newAmount - generated.amount;
      if (newAmount <= 0)
        await this.removeItem(item);
      else
        await this.mutateItem(() => nonGenerated.amount = newAmount);

    } else if (nonGenerated) {

      if (newAmount <= 0)
        return 'use-remove-item';

      try {
        await this.mutateItem(() => nonGenerated.amount = newAmount);
      } catch (ex) {
        if ((ex as UserError) !== null) {
          return ex.params;
        }
      }


    } else if (generated) {

      if (newAmount < generated.amount) {
        await this.publishNewCart();
        return;
      }

      newAmount -= generated.amount;
      const addItem = generated.toAddCartItem();
      addItem.amount = newAmount;

      await this.addItem(addItem);

    } else
      throw new Error('Item does not exists.');
  }

  async addItem(originalNewItem: ITypedAddCartItem, index?: number): Promise<void> {
    const newItem = JSON.parse(JSON.stringify(originalNewItem)) as ITypedAddCartItem;
    const { nonGenerated, itemno, amount } = this.getItemByItemNo(newItem);

    if (nonGenerated) {
      await this.setAmount(itemno, amount + newItem.amount);
    } else {
      const newCartItem = await this._cartItemFactory.create(newItem);

      await this.mutate((items) =>
          this._utilities.addAt(
              items,
              newCartItem,
              typeof (index) === 'number' && index >= 0 ? index : items.length)
      );
    }
  }

  public async removeItem(item: ICartItem | string, amountToRemove?: number) {

    if (!item)
      throw new Error('Parameter \'item\' cannot be null.');

    const { nonGenerated, amount } = this.getItemByItemNo(item);

    amountToRemove = amountToRemove || amount;

    if (amountToRemove < amount)
      await this.setAmount(item, amount - amountToRemove);
    else if (nonGenerated)
      await this.mutate(items => items.filter(i => i !== nonGenerated));

  }

  public export(): string {
    const serializedCart = this.getCart()
        .map(i => i.serialize())
        .filter(i => i);
    return JSON.stringify(serializedCart);
  }

  public getExistingCart(): { orderId: string, id: number; } {
    return this._existingCart;
  }

  public async import(serializedCart: string, existingCart?: { orderId: string, id: number; }): Promise<void> {
    await this.clearCart();
    await this.loadCart(serializedCart, existingCart);
  }

  public async clearCart(): Promise<void> {
    localStorage.removeItem('existingCart');
    localStorage.removeItem('cart');
    await this.initialize();
  }

  public getNumberOfItems() {
    return this.toPublicCart(this.getCart()).map((c) => c.amount).reduce((partialSum, a) => partialSum + a, 0);
  }

  public connectItems(): Observable<ICart> {
    return this._cartSubject.pipe(
        map((cart) => this.toPublicCart(cart)));
  }

  public findByItemNo(itemNo: string): ICartItem {
    const { generated, nonGenerated, index } = this.getItemByItemNo(itemNo);
    return this.toICartItem([{ item: generated, index }, { item: nonGenerated, index }]);
  }

  async publishNewCart(newCart?: Cart): Promise<void> {
    if (!newCart)
      newCart = this.getCart();
    if (!Array.isArray(newCart))
      throw new Error('preCondition: newCart must be an array.');

    const forEachItem = async (action: (item: CartItem) => Promise<'keep' | 'remove'>): Promise<Cart> => {
      const newItems: CartItem[] = [];
      for (const item of newCart as CartItem[]) {
        const result = await action(item);
        if (result !== 'remove')
          newItems.push(item);
      }

      return newItems;
    };

    const generatedItems: IAddCart = [];

    newCart = await forEachItem(i => i.preCompute());

    newCart = await forEachItem(async i => {
      const result = await i.compute(newCart);

      if (result === 'remove' || result === 'keep')
        return result;

      generatedItems.push(...(result || []));

      return 'keep';
    });

    this._publishOnMutate = this._saveCartOnMutate = false;
    try {

      const groupedItems = this._utilities.groupBy(generatedItems, i => i.itemno);
      const aggregatedItems = Object.values(groupedItems)
          .map((items) => {
            const item = items[0];
            item.amount = items.reduce((sum, i) => sum + i.amount, 0);
            return item;
          });

      for (const generatedItem of aggregatedItems) {
        generatedItem.asGenerated = true;
        const newCartItem = await this._cartItemFactory.create(generatedItem);
        newCart.push(newCartItem);
      }

    } finally {
      this._publishOnMutate = this._saveCartOnMutate = true;
    }

    newCart = await forEachItem(async i => {
      await i.postCompute();
      return 'keep';
    });

    if (!Array.isArray(newCart))
      throw new Error('postCondition: newCart must be an array.');
    this.verifyCart(newCart);
    this._cartSubject.next(newCart);
  }

  private async mutate(mutator: (cart: Cart) => Cart): Promise<void> {
    if (!mutator)
      throw new Error('Parameter \'mutator\' cannot be null.');

    const cart = this.getCart();
    const newCart = mutator(cart);

    if (this._publishOnMutate)
      await this.publishNewCart(newCart);

    if (this._saveCartOnMutate)
      this.saveCart();
  }

  private async mutateItem(mutator: () => void): Promise<void> {
    if (!mutator)
      throw new Error('Parameter \'mutator\' cannot be null.');

    mutator();

    if (this._saveCartOnMutate)
      this.saveCart();

    await this.publishNewCart();
  }

  private saveCart() {
    const serializedCartAsString = this.export();
    localStorage.setItem('cart', serializedCartAsString);

    if (this._existingCart !== undefined)
      localStorage.setItem('existingCart', JSON.stringify(this._existingCart));
    else
      localStorage.removeItem('existingCart');
  }

  private async loadCart(serializedCart?: string, existingCart?: { orderId: string, id: number; }): Promise<void> {

    this._saveCartOnMutate = false;
    try {
      let deserializedExistingCart: { orderId: string, id: number; };
      deserializedExistingCart = existingCart || JSON.parse(localStorage.getItem('existingCart') || '{}');
      this._existingCart = deserializedExistingCart.id === undefined ? undefined : deserializedExistingCart;
      this._cartSubject = new BehaviorSubject([]);
      let deserializedCart: ITypedAddCartItem[];
      deserializedCart = JSON.parse(serializedCart || localStorage.getItem('cart') || '[]');
      if (deserializedCart.length > 0) {
        const drawers = deserializedCart.filter(item => item.type === CartItemType.drawer);
        const additional = deserializedCart.filter(item => item.type === CartItemType.additional);
        const nonDrawers = deserializedCart.filter((i) => [CartItemType.runner, CartItemType.extra, CartItemType.hettichRunnerAddOn].includes(i.type));
        const fronts = deserializedCart.filter((i) => i.type === CartItemType.fronts);
        const carcass = deserializedCart.filter((i) => i.type === CartItemType.carcass);

        for (const cartItem of fronts) {
          await this.addItem(cartItem);
        }

        for (const cartItem of carcass) {
          await this.addItem(cartItem);
        }

        for (const cartItem of drawers)
          await this.addItem(cartItem);

        for (const cartItem of additional) {
          cartItem.price = cartItem.price || (cartItem as AdditionalCartItem).pricePer || undefined;
          await this.addAdditional(cartItem);
        }


        const cart = this.getCart();
        for (const cartItem of nonDrawers) {
          const existingItem = cart.find(u => u.itemno === cartItem.itemno);
          if (existingItem) {
            const amount = cartItem.amount - existingItem.amount;
            if (amount > 0) {
              cartItem.amount = amount;
              await this.addItem(cartItem);
            }
            continue;
          }
          await this.addItem(cartItem);
        }
      }
    } finally {
      this._saveCartOnMutate = true;

      const hettichBrandId = await this.hettichService.getHettichBrandId();

      // These products will magically appear in the cart, if they are required by other products (drawers with synchronising bars)
      await this.addItem(
          {
            brandId: hettichBrandId,
            type: CartItemType.hettichRunnerAddOn,
            itemno: 'synchronisationBar',
            name: 'synkronstang',
            amount: 1
          });
      await this.addItem(
          {
            brandId: hettichBrandId,
            type: CartItemType.hettichRunnerAddOn,
            itemno: 'synchronisationBarAdapter',
            name: 'synkronstang',
            amount: 1
          });
    }
  }

  private getItemByItemNo(item: ICartItem | IAddCartItem | string): { generated: CartItem, nonGenerated: CartItem, itemno: string, amount: number, index: number, amountStep: number; } {
    const itemNo = typeof (item) === 'string' ? item : item.itemno;

    const cartItems = this.getCart().map((i, index) => ({ item: i, index })).filter((i) => i.item.itemno === itemNo);
    if (cartItems.length > 2)
      throw new Error(`More than 2 cart items with itemno ${itemNo}. There should only ever be at most 1 generated and 1 not generated.`);

    const generateds = cartItems.filter((i) => i.item.isGenerated);
    const nonGenerateds = cartItems.filter((i) => !i.item.isGenerated);

    if (generateds.length > 1 || nonGenerateds.length > 1) {
      throw new Error(`Issue with cart items with itemno ${itemNo}. There are either 2 generated or non-generated.`);
    }

    const generated = generateds[0];
    const nonGenerated = nonGenerateds[0];

    let amountStep = 1;
    if ((item as ICartItem) !== null) {
      amountStep = (item as ICartItem).amountStep;
    } else if ((item as IAddCartItem) !== null) {
      amountStep = (item as IAddCartItem).amountStep;
    } else if (typeof (item) === 'string') {
      if ((item as string).match(/^WD2PR-/)) {
        amountStep = 10;
      }
    }

    return {
      generated: generated ? generated.item : undefined,
      nonGenerated: nonGenerated ? nonGenerated.item : undefined,
      itemno: itemNo,
      amount: (generated ? generated.item.amount : 0) + (nonGenerated ? nonGenerated.item.amount : 0),
      index: Math.min(generated ? generated.index : Number.MAX_SAFE_INTEGER, nonGenerated ? nonGenerated.index : Number.MAX_SAFE_INTEGER),
      amountStep: amountStep,
    };
  }

  private getCart(): Cart {
    if (!this._cartSubject || !this._cartSubject.value)
      throw new Error('Cart has not been initialized.');

    return this._cartSubject.value;
  }

  private toPublicCart(cart: Cart): ICart {
    const visibleItems = cart.filter((i) => i.isVisible);

    const groups = this._utilities.groupBy(visibleItems.map((i, index) => ({ item: i, index })), (i) => i.item.itemno);

    const publicCart = [] as ICartItem[];

    for (const key in groups) {
      if (groups.hasOwnProperty(key)) {
        const group = groups[key];

        const iCartItem = this.toICartItem(group);
        if (iCartItem)
          publicCart.push(iCartItem);
      }
    }

    publicCart.sort((i1, i2) =>
        i1.index === i2.index
            ? i1.itemno.localeCompare(i2.itemno)
            : i1.index - i2.index);

    return publicCart;
  }

  private toICartItem(items: { item: CartItem; index: number; }[]): ICartItem {
    if (!Array.isArray(items))
      return undefined;

    items = items.filter((i) => i && i.item);
    if (items.length === 0)
      return undefined;

    const item = items[0].item;
    const amount = items.reduce((previousValue, currentItem) => previousValue + currentItem.item.amount, 0);
    if (amount < 0)
      return undefined;

    const minAmount = items.reduce((previousValue, currentItem) => previousValue + (currentItem.item.isGenerated ? currentItem.item.amount : 0), 0);

    const minIndex = items.reduce((previousValue, currentItem) => Math.min(previousValue, currentItem.index), Number.MAX_SAFE_INTEGER);

    let breakageFeeIncluded: boolean;
    let oneDeliverySize: number;
    let oneDeliveryDoublePallet: boolean;
    let discountPercentage: number;
    let totalPrice: number;
    if (item.type === CartItemType.additional) {
      discountPercentage = 0;
      totalPrice = item.priceTotal;
      breakageFeeIncluded = (<AdditionalCartItem>item).breakageFeeIncluded;
      oneDeliverySize = (<AdditionalCartItem>item).oneDeliverySize;
      oneDeliveryDoublePallet = (<AdditionalCartItem>item).oneDeliveryDoublePallet;
    } else if (item.options.type && item.options.type.toString().indexOf(Manufacturer.ernstMair) > -1) {
      discountPercentage = 0;
      totalPrice = item.priceTotal;
    } else {
      const { discountPercentage: tmpDiscountPercentage, totalPrice: tmpTotalPrice } = this._cartItemDiscountService.calculateDiscount(item.type, amount, item.pricePer);
      discountPercentage = tmpDiscountPercentage;
      totalPrice = tmpTotalPrice;
    }

    return {
      brandId: item.brandId,
      comments: item.comments,
      type: item.type,
      subType: item.subType,
      itemno: item.itemno,
      index: minIndex,
      name: item.name,
      amount: amount,
      breakageFeeIncluded: breakageFeeIncluded,
      fragt: item.fragt,
      minAmount: minAmount,
      amountStep: item.amountStep,
      pricePer: item.pricePer,
      priceDetails: { ...item.priceDetails },
      orderDetails: [...item.orderDetails],
      priceTotal: totalPrice,
      discountPercentage: discountPercentage,
      description: item.description,
      options: { ...item.options },
      externalShopName: item.externalShopName,
      oneDeliverySize: oneDeliverySize,
      oneDeliveryDoublePallet: oneDeliveryDoublePallet,
    };
  }

  private verifyCart(cart: Cart) {
    for (const item of cart) {
      if (!(item instanceof CartItem))
        throw new Error(`Non CartItem item found in cart: ${item}.`);

      this.getItemByItemNo(item.itemno);
    }
  }
}
