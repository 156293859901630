import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { APIService, LocaleService, UserService } from '../services';
import { defaultBreadcrumbs, productsUrl, specialsPrefix } from '../utils/breadcrumbs/breadcrumbs';
import { Manufacturer } from '../../../../wdcommon/IProduct';
import { GlobalContentType } from '../../../../wdcommon/IGlobalContent';
import { TranslatingBase } from '../base-component/ComponentBase';


interface HomeCategory {
  id: string;
  bulletPoints?: string[];
  imageUrl: string;
  name: string;
  url: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent extends TranslatingBase implements OnInit {
  productsUrl = productsUrl;
  categories: HomeCategory[] = [];

  constructor(
      private api: APIService,
      private localeService: LocaleService,
      private router: Router,
      private translateService: TranslateService,
      private userService: UserService,
  ) {
    super(translateService);
  }

  async ngOnInit() {
    this.categories.push({
      id: 'nothegger',
      imageUrl: '/assets/images/nothegger_icon_grau.png',
      name: this.translate(defaultBreadcrumbs[Manufacturer.nothegger].nameId),
      url: defaultBreadcrumbs[Manufacturer.nothegger].url,
    });

    this.categories.push({
      id: 'purewood',
      imageUrl: '/assets/images/purewood_logo.png',
      name: this.translate(defaultBreadcrumbs[Manufacturer.purewood].nameId),
      url: defaultBreadcrumbs[Manufacturer.purewood].url,
    });

    if (this.userService.isAllowedErnstMair()) {
      this.categories.push({
        id: 'ernst-mair',
        imageUrl: '/assets/images/ernst-mair/ernst-mair.png',
        name: this.translate(defaultBreadcrumbs[Manufacturer.ernstMair].nameId),
        url: defaultBreadcrumbs[Manufacturer.ernstMair].url,
      });
    }

    this.api.getCategories(0, this.localeService.getLanguage())
        .then((categories) => {
          categories.forEach((c) => {
            this.categories.push({
              id: c.name.toLocaleLowerCase().replace(/\s+/g, '-'),
              imageUrl: c.imageUrl,
              name: c.name,
              url: '/' + productsUrl + '/' + specialsPrefix + c.id,
            });
          });
          this.loadBulletPoints();
        });
  }

  loadBulletPoints() {
    this.api.getGlobalContentOfType(GlobalContentType.brandBullets)
        .then((brandBulletsArray) => {
          brandBulletsArray.forEach((brandBullets) => {
            if (brandBullets.public && brandBullets.public[this.localeService.getLanguage()] &&
                (!brandBullets.availableFrom || new Date(brandBullets.availableFrom).valueOf() < Date.now()) &&
                (!brandBullets.availableTo || new Date(brandBullets.availableTo).valueOf() > Date.now())) {
              this.categories.forEach((c) => {
                if (c.id !== brandBullets.key) {
                  if (c.id.startsWith('buster') && brandBullets.key.startsWith('buster')) {
                    console.log(c.id, brandBullets.key);
                  }
                  return;
                }
                c.bulletPoints = (brandBullets.public[this.localeService.getLanguage()] as string).split(/\n/);
              });
            }
          });
        });
  }

}
