import { TranslateService } from '@ngx-translate/core';

import { PriceCalculatorService } from '../';
import { CartItem } from './CartItem';
import { IAddCartItem, ITypedAddCartItem } from '../../../../../wdcommon/ICartItem';
import { ICompanyAdjustments } from '../../../../../wdcommon/ICompany';


export class FrontCartItem extends CartItem {

  constructor(
    translateService: TranslateService,
    private priceService: PriceCalculatorService,
    extra: ITypedAddCartItem,
    private companyAdjustmentsProvider: () => Promise<ICompanyAdjustments>,
  ) {
    super(translateService, extra);
  }

  protected async calculatePrice(): Promise<number> {
    // If fileId is provided, this means that the front has a special option and price cannot be calculated.
    if (this.options.fileId) {
      return 0;
    }

    const companyAdjustments = await this.companyAdjustmentsProvider();
    const price = await this.priceService.calculateFront(this as IAddCartItem, companyAdjustments);

    if (typeof (price) === 'number') {
      return price;
    }

    console.error(`Unable to calculate price for front ${this.itemno} got: ${price}.`);
    return 0;
  }
}
