<div class="container">
  <div class="row">
    <div class="col-sm">
      <h3>{{headline}}</h3>
    </div>
  </div>
  <div class="row">
    <div class="col-sm">
      <a *ngFor="let language of languages"
         (click)="changeLanguage(language.id)">
        <img src="/assets/images/flags/{{ language.id }}.png"
             alt="{{ language.name }}" />
      </a>
    </div>
  </div>
  <div class="row"
       style="padding-bottom: 10px;">
    <div class="col-sm">

      <form [formGroup]="generalForm"
            (ngSubmit)="save()"
            novalidate>

        <div class="row"
             formArrayName="locale">
          <div class="col-sm-12"
               *ngFor="let locale of generalForm.get('locale')['controls']; let i = index">
            <app-info-editor-locale [hidden]="generalForm.get('locale')['controls'][i].value.lang !== selectedLang.id"
                                    [group]="generalForm.get('locale')['controls'][i]">
            </app-info-editor-locale>
          </div>
        </div>

        <div class="col-sm">
          <hr>
        </div>

        <div class="col-sm text-end">
          <button class="btn btn-success" type="submit">
            {{ 'UTILITY.SAVE' | translate }}
            <fa-icon [icon]="faCheck"></fa-icon>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
