<h3>{{ 'CABINET_CARCASS.WALL_UNIT' | translate }}</h3>
<div class="d-flex">
  <img [alt]="'CABINET_CARCASS.WALL_UNIT' | translate" class="me-auto" src="/assets/images/cabinet-carcass/OS.png"/>
  <figure class="figure text-center mx-4 align-self-end">
    <img [alt]="'SuspensionFittings' | translate" src="/assets/images/cabinet-carcass/Schrankaufhaenger.jpg"/>
    <figcaption [innerHTML]="'SuspensionFittings' | translate" class="figure-caption"></figcaption>
  </figure>
  <figure class="figure text-center align-self-end">
    <img [alt]="'CABINET_CARCASS.NotchedBack' | translate" src="/assets/images/cabinet-carcass/genutet.png"/>
    <figcaption [innerHTML]="'CABINET_CARCASS.NotchedBack' | translate" class="figure-caption"></figcaption>
  </figure>
</div>
<app-dynamic-form (change)="handleFormChanged()" [autoFocusFirst]="true" [rows]="model"></app-dynamic-form>
<hr/>
<div class="text-end">
  <app-amount-step (approved)="amountApproved($event)" [(amount)]="amount" [disable]="formIsInvalid"></app-amount-step>
</div>
