import { Component, Input, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { TranslatingBase } from '../../base-component/ComponentBase';
import { IOrderLayoutViewMode } from '../iorder-layout';
import { CartItemType, ICartItem, RepeatCartItem } from '../../../../../wdcommon/ICartItem';
import { Manufacturer } from '../../../../../wdcommon/IProduct';
import { RepeatDrawerComponent } from '../../repeat-drawer/repeat-drawer.component';
import { RepeatPurewoodFrontComponent } from '../../purewood/purewood-fronts/repeat-purewood-front/repeat-purewood-front.component';


interface OrderLayoutType {
  items: ICartItem[];
  translateItemName: boolean;
  typeName: string;
  sortOrder: number;
}

interface OrderLayoutTypeObject {
  [key: string]: OrderLayoutType;
}


@Component({
  selector: 'app-order-layout-body',
  templateUrl: './order-layout-body.component.html',
  styleUrls: ['./order-layout-body.component.css']
})
export class OrderLayoutBodyComponent extends TranslatingBase {
  @ViewChild(RepeatDrawerComponent) repeatDrawerModal: RepeatDrawerComponent;
  @ViewChild(RepeatPurewoodFrontComponent) repeatFrontModal: RepeatPurewoodFrontComponent;

  @Input() viewMode: IOrderLayoutViewMode;

  bodyTypesObject: OrderLayoutTypeObject = {};
  bodyTypes: OrderLayoutType[];

  repeatDrawer: RepeatCartItem;
  repeatFront: RepeatCartItem;

  constructor(
      public translateService: TranslateService,
  ) {
    super(translateService);
  }

  @Input() set items(items: ICartItem[]) {
    this.bodyTypesObject = {};
    items.forEach((item) => {
      let index: string;
      let name: string;
      let translateName = false;
      let sortOrder: number;
      switch (item.type) {
        case CartItemType.drawer:
          name = this.translate('UTILITY.DRAWERS');
          translateName = true;
          if (item.options.type.indexOf(Manufacturer.ernstMair) > -1) {
            index = CartItemType.drawer + '_' + Manufacturer.ernstMair;
            name += ', Ernst Mair';
            sortOrder = -1;
          } else if (item.options.type.indexOf(Manufacturer.purewood) > -1) {
            index = CartItemType.drawer + '_' + Manufacturer.purewood;
            name += ', Purewood';
            sortOrder = -2;
          } else {
            index = CartItemType.drawer + '_' + Manufacturer.nothegger;
            name += ', Nothegger';
            sortOrder = -3;
          }
          break;

        case CartItemType.runner:
        case CartItemType.hettichRunnerAddOn:
        case CartItemType.other:
          index = CartItemType.runner;
          name = this.translate('OrderType.hettichRunner');
          sortOrder = this.getSortOrder(item.type);
          break;

        default:
          index = item.type;
          name = this.translate('OrderType.' + item.type);
          sortOrder = this.getSortOrder(item.type);
      }
      if (!this.bodyTypesObject[index]) {
        this.bodyTypesObject[index] = {
          items: [],
          sortOrder: sortOrder,
          translateItemName: translateName,
          typeName: name
        };
      }
      this.bodyTypesObject[index].items.push(item);
    });
    this.bodyTypes = Object.values(this.bodyTypesObject).sort((a, b) => a.sortOrder - b.sortOrder);
  }

  setRepeatDrawer(drawer: ICartItem) {
    this.repeatDrawer = {
      contents: JSON.parse(JSON.stringify(drawer.options)),
      description: drawer.description,
      formattedType: this.translate(drawer.name),
      itemno: drawer.itemno
    };
    this.repeatDrawerModal.show();
  }

  setRepeatFront(item) {
    this.repeatFront = {
      contents: JSON.parse(JSON.stringify(item.options)),
      description: item.description,
      formattedType: item.name,
      itemno: item.itemno
    };
    this.repeatFrontModal.show();
  }

  private getSortOrder(itemType: CartItemType) {
    switch (itemType) {
      case CartItemType.extra:
        return 1;
      case CartItemType.carcass:
        return 2;
      case CartItemType.runner:
        return 3;
      case CartItemType.hettichRunnerAddOn:
        return 4;
      case CartItemType.other:
        return 5;
      case CartItemType.fronts:
        return 6;
      case CartItemType.additional:
        return 7;
    }
    console.warn('Unknown cart item type', itemType);
    return 1000;
  }
}
