<div class="container">

  <div class="row">
    <div class="col-sm">
      <div class="card">
        <div class="card-header">
          Ofte stillede spørgsmål
        </div>
        <div class="card-body">
          <h3 class="card-title">Kvalitetsbeskrivelser</h3>
          <p class="card-text">
            Her kan du læse om de forskellige skuffe kvaliteter vi udbyder. De tilhørende træsorter kan ses under
            menupunktet <a href="/faq/treesorts">
              <fa-icon [icon]="faLink"></fa-icon> træsorter
            </a>
          </p>
          <h5 class="card-title">
            Superior kvalitet (Standard)
          </h5>
          <p class="card-text">
            Uden knaster, mulige små afvigelser > under 1 cm., små trædefekter er tilladt. <br>
            Flere stykker træ er sammensat i skuffen. Træet er sorteret. <br>
            Beregnet til under-monteret udtræk, på bagside, boret til udtræk.<br>
            Skuffetykkelse 13,5 mm., klasse A/B.
          </p>

          <h5 class="card-title">
            Cabinet kvalitet (natur) -20% - i bøg dog kun 10% rabat
          </h5>
          <p class="card-text">
            Synlige knaster, variation i farve og træfejl er tilladt. <br>
            Klasse B/C. Ikke sorteret træ. <br>
            Beregnet til undermonteret udtræk, på bagside, boret til udtræk. <br>
            Skuffetykkelse 13,5 mm. Skuffen kan være repareret med reparationssæt. (flickstellen)
          </p>

          <h5 class="card-title">
            Yacht kvalitet +50%
          </h5>
          <p class="card-text">
            Uden knaster, høj kvalitet, træbilledet er ens for hele skuffen op til 20 cm højde. <br>
            Dvs. ikke sammensat træ i skuffen. <br>
            Træet er soteret og er i samme kvalitet som Superior. <br>
            Beregnet til under-monteret udtræk, på bagside, boret til udtræk. <br>
            Skuffetykkelse 13,5 mm.
          </p>

        </div>
      </div>
    </div>
  </div>

</div>
