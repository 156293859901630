import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { environment } from '../../environments/environment';

import { KlaviyoRouteEndpoints, SubscribedStateResponse } from '../../../../wdcommon/KlaviyoNewsletter';

const KLAVIYO_ROOT_URL = environment.apiUrl + '/klaviyo/';


@Injectable({
  providedIn: 'root'
})
export class KlaviyoService {

  constructor(
      private http: HttpClient,
  ) {
  }

  public async getSubscribedState(): Promise<SubscribedStateResponse> {
    const subscribedResult = await firstValueFrom(this.http.post<SubscribedStateResponse>(KLAVIYO_ROOT_URL + KlaviyoRouteEndpoints.subscribedStatus, {}));
    if (!subscribedResult.success) {
      console.error('Error getting Klaviyo subscribed state', subscribedResult.error);
    }
    return subscribedResult;
  }

  public subscribe(): Promise<any> {
    return firstValueFrom(this.http.post(KLAVIYO_ROOT_URL + KlaviyoRouteEndpoints.subscribe, {}));
  }

  public unsubscribe(): Promise<any> {
    return firstValueFrom(this.http.post(KLAVIYO_ROOT_URL + KlaviyoRouteEndpoints.unsubscribe, {}));
  }
}
