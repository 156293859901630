import { FormRow } from '../../dynamicForm/dynaform.interfaces';
import { createDrawerHeightField, createDrawerWidthField, createRunnerDepthField, DrawerWidthFieldName, RunnerDepthFieldName, StandardDrawerBaseField } from '../../dynamicForm/standard-fields';
import { createHrRow, createJointSurfaceTreatmentSelectionRow, createQualityTypeOfWoodSelectionRow } from '../../dynamicForm/standard-rows';


export const MODEL: FormRow[] = [
  createQualityTypeOfWoodSelectionRow(),
  createJointSurfaceTreatmentSelectionRow(),
  {
    id: 'dybde-bredde',
    show: true,
    fields: [
      createRunnerDepthField(150, 1200, RunnerDepthFieldName.drawerDepth),
      createDrawerWidthField(150, 1200, DrawerWidthFieldName.width),
      createDrawerHeightField(),
    ]
  },
  {
    id: 'drawer-base-row',
    show: true,
    fields: [StandardDrawerBaseField]
  },
  createHrRow(),
  {
    id: 'config',
    type: 'configuration',
    config: ['greb', 'boerstet']
  }
];
