import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons/faCheckSquare';
import { faSquare } from '@fortawesome/free-solid-svg-icons/faSquare';

import { IErnstMairDrawerResponse } from '../../../../wdcommon/IDrawer';
import { APIService, LocaleService, UserService } from '../services';
import { defaultBreadcrumbs } from '../utils/breadcrumbs/breadcrumbs';
import { Manufacturer } from '../../../../wdcommon/IProduct';


@Component({
  selector: 'app-ernst-mair',
  templateUrl: './ernst-mair.component.html',
  styleUrls: ['./ernst-mair.component.css']
})
export class ErnstMairComponent implements OnInit {
  faCheckSquare = faCheckSquare;
  faSquare = faSquare;
  ernstMairBreadcrumb = defaultBreadcrumbs[Manufacturer.ernstMair];

  ernstMairDrawerRows: IErnstMairDrawerResponse[][];
  asAdmin: boolean;

  constructor(
      private api: APIService,
      private currentLocale: LocaleService,
      private toastrService: ToastrService,
      private router: Router,
      private userService: UserService
  ) {
  }

  async ngOnInit() {
    this.asAdmin = window.location.pathname.indexOf('/admin/') === 0;
    if (this.asAdmin) {
      this.ernstMairBreadcrumb = defaultBreadcrumbs['adminDrawers' + Manufacturer.ernstMair];
    }

    if (this.userService.isAllowedErnstMair()) {
      // Get Ernst Mair drawers
      const ernstMairDrawers = await this.api.getErnstMairs(this.currentLocale.getLanguage());

      // Line drawers up in rows of four
      const rowSize = 4;
      this.ernstMairDrawerRows = [];
      const rows = Math.ceil(ernstMairDrawers.length / rowSize);
      for (let row = 0; row < rows; row++) {
        this.ernstMairDrawerRows.push([]);
      }

      // Drawers
      ernstMairDrawers.forEach((drawer, i) => {
        const index = Math.floor(i / rowSize);
        this.ernstMairDrawerRows[index].push(drawer);
      });

      // Top up with empty drawer-objects
      const remainder = rowSize * rows - ernstMairDrawers.length;
      for (let extra = 0; extra < remainder; extra++) {
        this.ernstMairDrawerRows[rows - 1].push({
          id: -1,
          enabled: false,
          img: '',
          name: '',
          description: '',
          type: ''
        });
      }
    }
  }

  async orderErnstMair(drawer: IErnstMairDrawerResponse) {
    if (!drawer.enabled || this.asAdmin) {
      return;
    }
    await this.goto('products/ernst-mair/' + drawer.type);
  }

  async goto(url: string) {
    await this.router.navigateByUrl(url);
  }

  async alterAvailability(ernstMairDrawer: IErnstMairDrawerResponse) {
    try {
      ernstMairDrawer.enabled = !ernstMairDrawer.enabled;
      await this.api.updateErnstMair(ernstMairDrawer);
    } catch (err) {
      console.error(err);
      this.toastrService.error('An error occurred when trying to update the drawer');
    }
  }

}
