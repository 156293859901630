import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from "@ngx-translate/core";

import { UserService } from '../../services';
import { TranslatingBase } from "../../base-component/ComponentBase";
import { IUser } from "../../../../../wdcommon/IUser";


@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent extends TranslatingBase implements OnInit {

    @BlockUI() blockLoginBtn: NgBlockUI;

    resetPasswordForm: UntypedFormGroup;
    resetHash: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private toastrService: ToastrService,
        private userService: UserService,
        private translateService: TranslateService,
    ) {
        super(translateService);
    }

    ngOnInit() {
        this.resetPasswordForm = new UntypedFormGroup({
            password: new UntypedFormControl(),
            password2: new UntypedFormControl()
        });

        this.route.params.subscribe((params) => {
            this.resetHash = params['hash'];
        });
    }

    resetPassword() {
        this.blockLoginBtn.start();
        if (this.resetPasswordForm.controls.password.value === this.resetPasswordForm.controls.password2.value) {
            this.userService.resetPassword(this.resetPasswordForm.controls.password.value, this.resetHash)
                .subscribe(async (r: IUser & { err: string, message: string }) => {
                    if (r.err) {
                        this.toastrService.error(r.message, r.err);
                    } else {
                        this.toastrService.success(this.translate('resetPassword.passwordChanged.success'), r.name);
                        await this.router.navigateByUrl('/login');
                        this.blockLoginBtn.stop();
                    }
                });
        } else {
            this.resetPasswordForm.reset();
            this.toastrService.error(this.translate('resetPassword.passwordNotAlike.error'));
            this.blockLoginBtn.stop();
        }
    }

}
