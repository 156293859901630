<div class="container">
  <div class="row">
    <div class="col-sm">

      <h3>Rediger leveringsinfo</h3>

    </div>
  </div>

  <div class="row pb-2">
    <div class="col-sm">

      <form (ngSubmit)="save()" [formGroup]="infoForm" novalidate>
        <div class="row" formArrayName="locale">
          <div *ngFor="let locale of infoForm.get('locale')['controls']; let i = index" class="col-sm-6 mb-4">
            <app-info-editor-locale [group]="infoForm.get('locale')['controls'][i]"></app-info-editor-locale>
          </div>
        </div>

        <div class="row">
          <div class="col-sm">
            <hr>
          </div>
        </div>

        <div class="row">
          <div class="col-sm text-end">
            <button class="btn btn-success" type="submit">
              {{ 'UTILITY.SAVE' | translate }}
              <fa-icon [icon]="faCheck"></fa-icon>
            </button>
          </div>
        </div>
      </form>

    </div>
  </div>
</div>
