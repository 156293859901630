<h3 *ngIf="breadcrumbTrail" class="up-title mt-0">
    <img src="assets/images/dot.jpg" alt="DOT" />
    <ng-container *ngIf="breadcrumbTrail[breadcrumbTrail.length - 1].nameId">
        {{ breadcrumbTrail[breadcrumbTrail.length - 1].nameId | translate }}
    </ng-container>
    <ng-container *ngIf="breadcrumbTrail[breadcrumbTrail.length - 1].name">
        {{ breadcrumbTrail[breadcrumbTrail.length - 1].name }}
    </ng-container>
</h3>
<div *ngIf="breadcrumbTrail" class="mb-2 text-muted">
    <a routerLink="/">
        <fa-icon [icon]="faHome"></fa-icon>&nbsp;
        {{ 'breadcrumbs.home' | translate }}
    </a>&nbsp;/&nbsp;
    <ng-container *ngFor="let crumb of breadcrumbTrail; let i = index">
        <a [routerLink]="crumb.url" *ngIf="crumb.url && (i < breadcrumbTrail.length - 1)">
            <ng-container *ngIf="crumb.nameId">
                {{ crumb.nameId | translate }}
            </ng-container>
            <ng-container *ngIf="crumb.name">
                {{ crumb.name }}
            </ng-container>
        </a>
        <span *ngIf="!crumb.url || (i === breadcrumbTrail.length - 1)">
            <ng-container *ngIf="crumb.nameId">
                {{ crumb.nameId | translate }}
            </ng-container>
            <ng-container *ngIf="crumb.name">
                {{ crumb.name }}
            </ng-container>
        </span>
        <ng-container *ngIf="i < breadcrumbTrail.length - 1">&nbsp;/&nbsp;</ng-container>
    </ng-container>
</div>
