import { Component, OnInit } from '@angular/core';

import { defaultBreadcrumbs } from '../utils/breadcrumbs/breadcrumbs';
import { Manufacturer } from '../../../../wdcommon/IProduct';
import { UserService } from '../services';


@Component({
  selector: 'app-purewood',
  templateUrl: './purewood.component.html',
  styleUrls: ['./purewood.component.css']
})
export class PurewoodComponent implements OnInit {
  purewoodBreadcrumb = defaultBreadcrumbs[Manufacturer.purewood];
  drawersBreadcrumb = defaultBreadcrumbs['purewoodDrawers'];
  linoleumFrontsBreadcrumb = defaultBreadcrumbs['purewoodLinoleumFronts'];
  paintedFrontsBreadcrumb = defaultBreadcrumbs['purewoodPaintedFronts'];
  veneerFrontsBreadcrumb = defaultBreadcrumbs['purewoodVeneerFronts'];
  woodenFrontsBreadcrumb = defaultBreadcrumbs['purewoodWoodenFronts'];
  canAccessDrawers: boolean;

  constructor(
      private userService: UserService,
  ) {
  }

  async ngOnInit() {
    this.canAccessDrawers = (this.userService.isAllowedPurewood() === true);
  }

}
