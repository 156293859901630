import { Component, OnInit } from '@angular/core';

import { ICompany } from '../../../../../wdcommon/ICompany';
import { APIService, UtilitiesService } from '../../services';
import { defaultBreadcrumbs } from '../../utils/breadcrumbs/breadcrumbs';


@Component({
  selector: 'app-admin-companies',
  templateUrl: './admin-companies.component.html',
  styleUrls: ['./admin-companies.component.css']
})
export class AdminCompaniesComponent implements OnInit {
  adminCompaniesBreadcrumb = defaultBreadcrumbs.adminCompanies;

  companies: ICompany[];
  filteredCompanies: ICompany[];

  searchNeedle = '';
  sortOrder: 'asc' | 'desc' = 'asc';
  sortProperty: keyof ICompany = 'companyName';

  constructor(
      private api: APIService
  ) {
  }

  setFilteredCompanies(companies: ICompany[]) {
    if (this.searchNeedle.length > 2) {
      companies = this.companies.filter(o => {
        const values = Object.values(o);
        let contains = false;
        values.forEach(v => {
          if ((v + '').toLowerCase().indexOf(this.searchNeedle.toLowerCase()) > -1) {
            contains = true;
          }
        });
        return contains;
      });
    }

    this.filteredCompanies = UtilitiesService.sortByPropertyName(companies, this.sortProperty, this.sortOrder);
  }

  sortBy(property: keyof ICompany) {
    if (property === this.sortProperty)
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    else {
      this.sortProperty = property;
      this.sortOrder = 'asc';
    }

    this.setFilteredCompanies(this.companies);
  }

  ngOnInit() {
    this.api.getCompanies().subscribe((c) => {
      this.companies = c;
      this.setFilteredCompanies(c);
    });
  }

  searchFilter() {
    this.setFilteredCompanies(this.companies);
  }

}
