import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';


export enum ButtonSize {
  sm = 'sm',
  normal = 'md',
  lg = 'lg',
}

@Component({
  selector: 'app-remove-button',
  templateUrl: './remove-button.component.html',
  styleUrls: ['./remove-button.component.css']
})
export class RemoveButtonComponent {
  @Input() disabled: boolean;
  @Input() buttonSize = ButtonSize.sm;
  @Input() outline = false;

  @Output() delete = new EventEmitter();

  protected readonly faTrash = faTrash;
  protected readonly ButtonSize = ButtonSize;

  deleteConfirmed() {
    this.delete.emit(null);
    this.hideDelPop();
  }

  hideDelPop() {
    document.body.click();
  }
}
