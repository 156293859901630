import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }

  public getToken(): string {
    return localStorage.getItem('token');
  }

  public logout() {
    localStorage.removeItem('token');
  }

  public isAuthenticated() {
    const token = this.getToken();
    if (!token) {
      return false;
    }

    const date = this.getTokenExpirationDate(token);
    if (date === undefined) {
      return false;
    }
    return (date.valueOf() > new Date().valueOf());
  }

  private getTokenExpirationDate(token: string) {
    const decoded: any = jwt_decode(token);

    if (typeof decoded.exp !== 'number') {
      return undefined;
    }

    return new Date(decoded.exp * 1000);
  }
}
