import { Injectable } from '@angular/core';

interface ChosenImageFile {
  imgFile: File;
  imgUrl: string;
}

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  async onFileChosen(event: Event): Promise<ChosenImageFile> {
    const chosenImgFile: ChosenImageFile = {
      imgFile: null,
      imgUrl: null,
    };
    if ((event.target as HTMLInputElement).files && (event.target as HTMLInputElement).files.length) {
      chosenImgFile.imgFile = (event.target as HTMLInputElement).files[0];
      return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onload = () => {
          chosenImgFile.imgUrl = reader.result as string;
          resolve(chosenImgFile);
        };
        reader.readAsDataURL(chosenImgFile.imgFile);
      });
    }
    return chosenImgFile;
  }

  urlToFile(url: string, filename: string): Promise<File> {
    return fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          return new File([blob], filename, { type: 'image/png' });
        });
  }

}
