import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription, combineLatest } from 'rxjs';
import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';

import { IProductCategoryResponse, IDynamicProduct } from '../../../../../wdcommon/IProduct';
import { APIService } from '../../services';
import { AdminProductsLanguageService } from './admin-products-language.service';
import { CategoryLocale, ProductCategory } from './product-category';
import { defaultBreadcrumbs, productsUrl, specialsPrefix } from '../../utils/breadcrumbs/breadcrumbs';


@Component({
  selector: 'app-admin-products',
  templateUrl: './admin-products.component.html',
  styleUrls: ['./admin-products.component.css']
})
export class AdminProductsComponent implements OnInit, OnDestroy {
  modalRef: BsModalRef;
  categories: IProductCategoryResponse[];
  routePrefix: string;
  products: IDynamicProduct[];
  catAndLangSubscription: Subscription;

  categoryName: string;
  categoryDescription: string;
  categoryId?: number;
  isRoot: Boolean;
  newCategoryName: string;
  hettichDisabled = false;

  localeId: string;

  protected readonly faCopy = faCopy;
  protected readonly faEdit = faEdit;
  protected readonly faPlus = faPlus;

  protected readonly adminSpecialProductsBreadcrumb = defaultBreadcrumbs.adminSpecialProducts;

  constructor(
      public modalService: BsModalService,
      private adminProductsLanguageService: AdminProductsLanguageService,
      private api: APIService,
      private route: ActivatedRoute,
      private router: Router,
  ) {
  }

  ngOnInit() {
    this.catAndLangSubscription = combineLatest([this.route.paramMap, this.adminProductsLanguageService.connectCurrentLocale()])
        .subscribe(async ([paramMap, locale]) => {
          this.localeId = locale.id;
          this.products = undefined;
          this.categoryName = undefined;
          this.categoryDescription = undefined;
          this.categories = undefined;
          this.setCategoryId(parseInt(paramMap.get('categoryId'), 10) || 0);
        });
  }

  ngOnDestroy(): void {
    this.catAndLangSubscription.unsubscribe();
  }

  async deleteCategory() {
    await this.api.deleteCategory(this.categoryId);
    await this.router.navigateByUrl('/admin/' + productsUrl + '/' + specialsPrefix + '0');
  }

  async createProduct() {
    await this.router.navigateByUrl('/admin/products/new/' + (this.categoryId ? this.categoryId : ''));
  }

  async editProduct(product: IDynamicProduct) {
    await this.router.navigateByUrl('/admin/products/edit/' + product.id);
  }

  async copyProduct(product: IDynamicProduct) {
    await this.router.navigateByUrl('/admin/products/copy/' + product.id);
  }

  async deleteProduct(product: IDynamicProduct) {
    this.products = undefined;
//    await this.api.deleteProductImage(product.id);
    await this.api.deleteProduct(product.id);
    this.loadProducts();
  }

  async openEditCategory(categoryId: number) {
    await this.router.navigateByUrl('/admin/products/categories/' + categoryId);
  }

  openModal(modal) {
    this.modalRef = this.modalService.show(modal);
  }

  async addNewCategory() {
    const defaultLocale: CategoryLocale = {
      name: this.newCategoryName,
      lang: this.adminProductsLanguageService.currentLocale.id
    };
    const newCategory: ProductCategory = {
      id: null,
      locale: [defaultLocale],
      parentId: (this.categoryId > 0) ? this.categoryId : null,
    };

    await this.api.saveCategory(newCategory);
    this.categories = undefined;
    this.loadCategories();

    this.modalRef.hide();
    this.newCategoryName = '';
  }

  async updateHettich() {
    this.hettichDisabled = true;
    await this.api.updateHettich();
    this.modalRef.hide();
  }

  private setCategoryId(categoryId: number) {
    this.categoryId = categoryId;
    this.isRoot = !this.categoryId;

    this.api.getCategory(this.categoryId, this.localeId)
        .then((category) => {
          this.categoryName = category.name ?? 'Specialprodukter';
          this.categoryDescription = category.description;
        });
    this.api.getCategoryTree(categoryId, this.localeId)
        .then((tree) => {
          let index: number = tree.findIndex((t) => t.id === categoryId);
          let categoryPart = '';
          while (index > 0) {
            index--;
            categoryPart = tree[index].id + '/' + categoryPart + '/';
          }
          this.routePrefix = '/admin/' + productsUrl + '/' + specialsPrefix + '0/' + categoryPart + (categoryId ? categoryId + '/' : '');
        });
    this.loadCategories();
    this.loadProducts();
  }

  private loadCategories() {
    this.api.getCategories(this.categoryId, this.localeId)
        .then((productCategories) => this.categories = productCategories);
  }

  private loadProducts() {
    this.api.getProducts(this.categoryId, this.localeId)
        .then((products) => this.products = products);
  }

}
