import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';

import { CartItemType, CartSubType, IProfileOptions, ITypedAddCartItem } from '../../../../../wdcommon/ICartItem';
import { ExternalShopName } from '../../../../../wdcommon/IExternalShop';
import { IOption, OptionProperty, Manufacturer, OtherProductShortName } from '../../../../../wdcommon/IProduct';
import { APIService, CartService, UserService } from '../../services';
import { TranslatingBase } from '../../base-component/ComponentBase';
import { defaultBreadcrumbs } from '../../utils/breadcrumbs/breadcrumbs';
import { AddToCartWithAmountModalComponent } from '../../modals/add-to-cart-with-amount-modal/add-to-cart-with-amount-modal.component';


@Component({
  selector: 'app-profiles',
  templateUrl: './profiles.component.html',
  styleUrls: ['./profiles.component.css']
})
export class ProfilesComponent extends TranslatingBase implements OnInit {
  profilesForm: UntypedFormGroup;
  typesOfWood: IOption[];
  treatments: IOption[];
  modalRef: BsModalRef<AddToCartWithAmountModalComponent>;
  antal: number;
  oldAntal: number;

  protected readonly faArrowRight = faArrowRight;
  protected readonly faCheck = faCheck;
  protected readonly profilesBreadcrumb = defaultBreadcrumbs.notheggerProfiles;
  protected readonly OptionProperty = OptionProperty;
  protected readonly profiles = OtherProductShortName.profiles;

  protected readonly typeOfWoodVar = OptionProperty.typeOfWood;
  protected readonly surfaceTreatmentVar = OptionProperty.surfaceTreatment;
  protected readonly heightVar = OptionProperty.height;

  constructor(
      private fb: UntypedFormBuilder,
      private api: APIService,
      public modalService: BsModalService,
      private cartService: CartService,
      private toastrService: ToastrService,
      private translateService: TranslateService,
      protected userService: UserService,
      private router: Router
  ) {
    super(translateService);
  }

  async ngOnInit() {
    this.profilesForm = this.fb.group({
      [this.heightVar]: [null, [<any>Validators.required]],
      length: [null, [<any>Validators.required]],
      [OptionProperty.thickness]: [[14, 40], [<any>Validators.required]],
      [this.typeOfWoodVar]: [null, [<any>Validators.required]],
      [this.surfaceTreatmentVar]: [null, [<any>Validators.required]],
    });
    this.antal = 10;
    this.oldAntal = this.antal;
    const options = await this.api.getProductOptions();
    this.typesOfWood = options.filter((o) => (o.property === this.typeOfWoodVar && o.types.indexOf(this.profiles) > -1));
    this.treatments = options.filter((o) => (o.property === this.surfaceTreatmentVar && o.types.indexOf(this.profiles) > -1));
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control.markAsTouched({ onlySelf: true });
    });
    return this.profilesForm.valid;
  }

  showAddToCartModal() {
    this.modalRef = this.modalService.show(AddToCartWithAmountModalComponent, {
      initialState: {
        amount: this.antal,
        minAmount: 10,
        amountStep: 10
      }
    });

    this.modalRef.content.amountChange.subscribe((amount: number) => {
      this.antal = amount;
    });

    this.modalRef.content.approved.subscribe((wasApproved) => {
      if (wasApproved)
        this.finalizeOrder();
    });
  }

  async finalizeOrder() {
    if (!this.profilesForm.valid)
      return;

    const shortType = 'WD2PR-'
        + this.profilesForm.controls[this.heightVar].value
        + this.profilesForm.controls[this.typeOfWoodVar].value.substring(0, 2).toUpperCase()
        + this.profilesForm.controls[this.surfaceTreatmentVar].value.substring(0, 2).toUpperCase()
        + this.profilesForm.controls.length.value + 'x'
        + this.profilesForm.controls[this.heightVar].value + 'x'
        + this.profilesForm.controls[OptionProperty.thickness].value;

    const shortTypeEncoded = btoa(shortType);
    const vareNr: any = await this.api.getVarenr(shortTypeEncoded);

    const formattedType = this.profilesForm.controls.length.value + 'x'
        + this.profilesForm.controls[OptionProperty.thickness].value + 'x'
        + this.profilesForm.controls[this.heightVar].value + 'mm '
        + this.translateService.instant('TREESORTS.' + this.profilesForm.controls[this.typeOfWoodVar].value) + ' '
        + this.translateService.instant('SURFACES.' + this.profilesForm.controls[this.surfaceTreatmentVar].value);

    const profileOptions: IProfileOptions = {
      length: this.profilesForm.controls.length.value,
      [OptionProperty.thickness]: this.profilesForm.controls[OptionProperty.thickness].value,
      [OptionProperty.height]: this.profilesForm.controls[this.heightVar].value,
      [OptionProperty.typeOfWood]: this.profilesForm.controls[this.typeOfWoodVar].value,
      [OptionProperty.surfaceTreatment]: this.profilesForm.controls[this.surfaceTreatmentVar].value,
    };

    const orderObj: ITypedAddCartItem = {
      brandId: Manufacturer.nothegger,
      type: CartItemType.extra,
      subType: CartSubType.profil,
      name: this.translate('OTHER_NAMES.PROFILES'),
      itemno: vareNr.varenr,
      description: formattedType,
      amount: this.antal,
      minAmount: 10,
      amountStep: 10,
      options: profileOptions,
      externalShopName: ExternalShopName.nothegger
    };

    await this.cartService.addExtra(orderObj);
    this.modalRef.hide();
    this.toastrService.success('Varen er tilføjet til kurven');
    await this.goto('/');
  }

  validateInput() {
    if ((this.antal % 10) !== 0 && this.antal !== 0) {
      this.antal = this.oldAntal;
      this.toastrService.error(this.translate('Delbart', { delenhed: '10' }));
    } else {
      this.oldAntal = this.antal;
    }
  }

  async goto(url: string) {
    await this.router.navigateByUrl(url);
  }

}
