<label class="form-label" translate="FRONTS.COLOR"></label>
<div class="d-flex flex-row">
    <div class="pe-3 mb-2 btn-group">
        <input (click)="paletteChanged(ncs)" checked class="btn-check" id="paletteNcs" name="palette" type="radio">
        <label [class.active]="showPalette === ncs" [class.btn-outline-primary]="showPalette !== ncs" [class.btn-primary]="showPalette === ncs"
               class="btn" for="paletteNcs">NCS</label>
        <input (click)="paletteChanged(ral)" class="btn-check" id="paletteRal" name="palette" type="radio">
        <label [class.active]="showPalette === ral" [class.btn-outline-primary]="showPalette !== ral" [class.btn-primary]="showPalette === ral"
               class="btn" for="paletteRal">RAL</label>
    </div>
    <app-ncs-color-picker (colourChange)="emitNcsColour($event)" *ngIf="showPalette === ncs" [colour]="ncsColour"></app-ncs-color-picker>
    <app-ral-color-picker (colourChange)="emitRalColour($event)" *ngIf="showPalette === ral" [colour]="ralColour"></app-ral-color-picker>
</div>
