import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { APIService, CartService, ModelLoaderService } from '../../../services';
import { VeneerProductBaseComponent } from '../../../base-component/veneer-product-base/veneer-product-base.component';
import { CartItemType, CartSubType, ITypedAddCartItem, IWallUnitOptions } from '../../../../../../wdcommon/ICartItem';
import { ExternalShopName } from '../../../../../../wdcommon/IExternalShop';
import { IItemNumber, OptionProperty, Manufacturer, OtherProductShortName } from '../../../../../../wdcommon/IProduct';


@Component({
  selector: 'app-wall-unit',
  templateUrl: './wall-unit.component.html',
  styleUrls: ['./wall-unit.component.css']
})
export class WallUnitComponent extends VeneerProductBaseComponent {

  constructor(
      private api: APIService,
      private cartService: CartService,
      private modelLoader: ModelLoaderService,
      private toastrService: ToastrService,
      private router: Router,
      private translateService: TranslateService,
  ) {
    super(cartService, modelLoader, toastrService, api, router, translateService);
    this.otherProductShortName = OtherProductShortName.wallUnit;
  }

  protected async finalizeOrder() {
    const shelves = parseInt(this.form.getControl(OptionProperty.shelves).value, 10);

    const cartItemOptions: IWallUnitOptions = {
      [OptionProperty.comment]: this.form.getControl(OptionProperty.comment).value || null,
      [OptionProperty.height]: this.form.getControl(OptionProperty.height).value,
      [OptionProperty.surfaceTreatment]: this.isSurfaceTreatmentNeeded() ? this.form.getControl(OptionProperty.surfaceTreatment).value : null,
      [OptionProperty.veneer]: this.form.getControl(OptionProperty.veneer).value,
      [OptionProperty.width]: this.form.getControl(OptionProperty.width).value,
      [OptionProperty.depth]: this.form.getControl(OptionProperty.depth).value,
      [OptionProperty.shelves]: shelves,
      [OptionProperty.shelfHoles]: this.form.getControl(OptionProperty.shelfHoles).value,
      [OptionProperty.cornerJoin]: this.form.getControl(OptionProperty.cornerJoin).value,
      [OptionProperty.suspensionFittings]: this.form.getControl(OptionProperty.suspensionFittings).value === true
    };

    const shortTypeEncoded = btoa(
        OtherProductShortName.wallUnit + cartItemOptions[OptionProperty.veneer].substring(0, 2)
        + (cartItemOptions[OptionProperty.surfaceTreatment] ? cartItemOptions[OptionProperty.surfaceTreatment].substring(0, 2) : '')
        + cartItemOptions[OptionProperty.height] + 'x' + cartItemOptions[OptionProperty.width] + 'x' + cartItemOptions[OptionProperty.depth]
        + (shelves ? shelves + cartItemOptions[OptionProperty.shelfHoles] : '')
        + cartItemOptions[OptionProperty.cornerJoin].substring(0, 2)
        + (cartItemOptions[OptionProperty.suspensionFittings] ? 'Y' : 'N')
    );
    const itemNo: IItemNumber = await this.api.getVarenr(shortTypeEncoded);

    const formattedName = this.translate('CART.WidthDepthHeight_mm', {
          width: cartItemOptions[OptionProperty.width],
          depth: cartItemOptions[OptionProperty.depth],
          height: cartItemOptions[OptionProperty.height]
        }) + ', '
        + this.translate('VENEER.' + cartItemOptions[OptionProperty.veneer])
        + (cartItemOptions[OptionProperty.surfaceTreatment] ? ' ' + this.translate('SURFACES.' + cartItemOptions[OptionProperty.surfaceTreatment]) : '') + ', '
        + this.translate('CORNERJOIN.' + cartItemOptions[OptionProperty.cornerJoin]) + ', '
        + (cartItemOptions[OptionProperty.shelves] > 0 ? this.translate('CART.SHELVES', { shelves: cartItemOptions[OptionProperty.shelves] }) + ' ' : '')
        + (cartItemOptions[OptionProperty.shelfHoles] ? this.translate('CART.SHELF_HOLES.' + cartItemOptions[OptionProperty.shelfHoles]) + ', ' : '')
        + this.translate('CART.SUSPENSION_FITTINGS_WITH' + (cartItemOptions[OptionProperty.suspensionFittings] ? '' : 'OUT'));

    const orderObj: ITypedAddCartItem = {
      brandId: Manufacturer.nothegger,
      type: CartItemType.carcass,
      subType: CartSubType.wallUnit,
      name: this.translate('CABINET_CARCASS.WALL_UNIT'),
      itemno: itemNo.varenr,
      description: formattedName,
      amount: this.amount,
      options: cartItemOptions,
      externalShopName: ExternalShopName.nothegger
    };

    await this.addToCart(orderObj);
    await this.goto('products/nothegger');
  }

}
