import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { APIService, CartService, ModelLoaderService, UtilitiesService } from '../../services';
import { defaultBreadcrumbs } from '../../utils/breadcrumbs/breadcrumbs';
import { FormRow } from '../../dynamicForm/dynaform.interfaces';
import { DynamicFormComponent } from '../../dynamicForm/dynamic-form/dynamic-form.component';
import { TranslatingBase } from '../../base-component/ComponentBase';
import { CartItemType, CartSubType, IOtherItemOptions, ITypedAddCartItem } from '../../../../../wdcommon/ICartItem';
import { ExternalShopName } from '../../../../../wdcommon/IExternalShop';
import { OptionProperty, Manufacturer, OtherProductShortName } from '../../../../../wdcommon/IProduct';


const productTypes: {
  [type: string]: {
    varWidth: {
      min: number,
      max: number
    },
    varDepth: {
      min: number,
      max: number
    }
  }
} = {
  BE1: {
    varWidth: {
      min: 250,
      max: 350
    },
    varDepth: {
      min: 260,
      max: 340
    }
  },
  BE2: {
    varWidth: {
      min: 350,
      max: 500
    },
    varDepth: {
      min: 260,
      max: 340
    }
  },
  BE3: {
    varWidth: {
      min: 500,
      max: 650
    },
    varDepth: {
      min: 260,
      max: 340
    }
  },
  BE4: {
    varWidth: {
      min: 220,
      max: 350
    },
    varDepth: {
      min: 340,
      max: 400
    }
  },
  BE5: {
    varWidth: {
      min: 350,
      max: 500
    },
    varDepth: {
      min: 340,
      max: 400
    }
  },
  BE6: {
    varWidth: {
      min: 500,
      max: 650
    },
    varDepth: {
      min: 340,
      max: 400
    }
  },
  BE7: {
    varWidth: {
      min: 220,
      max: 350
    },
    varDepth: {
      min: 400,
      max: 550
    }
  },
  BE8: {
    varWidth: {
      min: 350,
      max: 500
    },
    varDepth: {
      min: 400,
      max: 550
    }
  },
  BE9: {
    varWidth: {
      min: 500,
      max: 650
    },
    varDepth: {
      min: 400,
      max: 550
    }
  },
  BEM: {
    varWidth: {
      min: 220,
      max: 650
    },
    varDepth: {
      min: 400,
      max: 550
    }
  }
};
const thicknessValues = {
  default: 52,
  BEM: 55
};

const productTypeVar = 'productType';
const depthVar = OptionProperty.depth;
const widthVar = OptionProperty.width;
const woodTypeVar = OptionProperty.typeOfWood;
const treatmentVar = OptionProperty.surfaceTreatment;

const depthNumberRange = 'depthNumberRange';
const widthNumberRange = 'widthNumberRange';

@Component({
  selector: 'app-solid-organiser',
  templateUrl: './solid-organiser.component.html',
  styleUrls: ['./solid-organiser.component.css']
})
export class SolidOrganiserComponent extends TranslatingBase implements OnInit, AfterViewInit {
  solidBreadcrumb = defaultBreadcrumbs.notheggerSolidOrganiser;

  @ViewChild(DynamicFormComponent) form: DynamicFormComponent;

  solidOrganiser = OtherProductShortName.solidOrganiser;
  productTypesObject = productTypes;
  productTypesArray = Object.entries(productTypes);
  formIsInvalid = true;
  model: FormRow[] = [];
  amount = 1;
  productOptions: any;
  depthValidator: Function;
  widthValidator: Function;

  constructor(
      private formBuilder: UntypedFormBuilder,
      private translateService: TranslateService,
      private router: Router,
      private utilities: UtilitiesService,
      private modelLoader: ModelLoaderService,
      private api: APIService,
      private cartService: CartService,
      private toastrService: ToastrService,
  ) {
    super(translateService);
  }

  ngOnInit() {
    this.model = this.modelLoader.load(this.solidOrganiser);
    this.depthValidator = (): { [s: string]: boolean } => {
      return !this.isDepthValid() ? { [depthNumberRange]: true } : null;
    };
    this.widthValidator = (): { [s: string]: boolean; } => {
      return !this.checkWidthTypeValid() ? { [widthNumberRange]: true } : null;
    };
  }

  async ngAfterViewInit() {
    this.productOptions = await this.api.getProductOptions();
    this.loadOptions();
    this.addModelValidations();
  }

  addModelValidations() {
    // Get existing depth validators
    const validDepthList: any[] = [];
    const depthValidations = this.form
        .rows.find((r) => r.fields && r.fields.find((f) => f.name === depthVar))
        .fields.find((f) => f.name === depthVar)
        .validations || [];
    depthValidations.forEach((valid) => {
      validDepthList.push(valid.validator);
    });

    // Add depth number range validation, if missing
    if (!depthValidations.some((v) => v.name === depthNumberRange)) {
      depthValidations.push({
        name: depthNumberRange,
        validator: this.depthValidator,
        message: 'SolidOrganiser.DepthInvalid'
      });
      validDepthList.push(this.depthValidator);
    }

    // Get existing width validators
    const validWidthList = [];
    const widthValidations = this.form.rows
        .find(r => r.fields && r.fields.find((f) => f.name === widthVar))
        .fields.find((f) => f.name === widthVar).validations || [];
    widthValidations.forEach(valid => {
      validWidthList.push(valid.validator);
    });

    // Add width number range validation, if missing
    if (!widthValidations.some(v => v.name === widthNumberRange)) {
      widthValidations.push({
        name: widthNumberRange,
        validator: this.widthValidator,
        message: 'SolidOrganiser.WidthInvalid'
      });
      validWidthList.push(this.widthValidator);
    }

    // Assign depth and width validators
    this.form.getControl(depthVar).setValidators(Validators.compose(validDepthList));
    this.form.getControl(widthVar).setValidators(Validators.compose(validWidthList));
  }

  public isDepthValid() {
    if (this.form) {
      const productTypeInput = this.form.getControl(productTypeVar).value;
      const depthInput = parseInt(this.form.getControl(depthVar).value, 10);

      if (productTypeInput && depthInput) {
        return (depthInput >= productTypes[productTypeInput].varDepth.min && depthInput <= productTypes[productTypeInput].varDepth.max);
      }
    }
    return true;
  }

  public checkWidthTypeValid() {
    if (this.form) {
      const productTypeInput = this.form.getControl(productTypeVar).value;
      const widthInput = parseInt(this.form.getControl(widthVar).value, 10);

      if (productTypeInput && widthInput) {
        return (widthInput >= productTypes[productTypeInput].varWidth.min && widthInput <= productTypes[productTypeInput].varWidth.max);
      }
    }
    return true;
  }

  loadOptions() {
    for (const row of this.model) {
      for (const field of row.fields || []) {
        if (field && field.name === OptionProperty.thickness) {
          field.options = [];
          Object.entries(thicknessValues).forEach((thicknessEntry) => {
            field.options.push({ label: thicknessEntry[1] + '', value: thicknessEntry[0] });
          });
        } else if (field && field.name === woodTypeVar) {
          field.options = this.productOptions
              .filter((o) => (o.property === woodTypeVar) && (o.types.indexOf(this.solidOrganiser) > -1))
              .sort(this.utilities.abcSort);
        } else if (field && field.name === treatmentVar) {
          field.options = this.productOptions
              .filter((o) => (o.property === treatmentVar) && (o.types.indexOf(this.solidOrganiser) > -1))
              .sort(this.utilities.abcSort);
        } else if (field && field.name === productTypeVar) {
          field.options = [];
          Object.entries(productTypes).forEach((productTypeEntry) => {
            field.options.push({ label: this.translate('SolidOrganiser.' + productTypeEntry[0]), value: productTypeEntry[0] });
          });
        }
      }
    }
  }

  isActive(type: string): boolean {
    return this.form !== undefined && this.form.getControl(productTypeVar).value === type;
  }

  getType(): string {
    return this.form.getControl(productTypeVar).value;
  }

  setType(type: string) {
    const control = this.form.getControl(productTypeVar);
    if (control && control.value !== type) {
      control.setValue(type === undefined ? null : type);
      this.validateFormAndUpdateThickness();
    }
  }

  validateFormAndUpdateThickness() {
    const typeControl = this.form.getControl(productTypeVar);
    const thicknessControl = this.form.getControl(OptionProperty.thickness);
    thicknessControl.setValue(Object.keys(thicknessValues).includes(typeControl.value) ? thicknessValues[typeControl.value] : thicknessValues.default);
    this.formIsInvalid = this.form.invalid;
  }

  async amountApproved(approved: boolean) {
    if (approved) {
      await this.finalizeOrder();
    }
  }

  async goto(url: string) {
    await this.router.navigateByUrl(url);
  }

  private async finalizeOrder() {
    const type = this.form.getControl(productTypeVar).value;
    const cartItemCandidate: IOtherItemOptions = {
      [OptionProperty.depth]: parseInt(this.form.getControl(depthVar).value, 10),
      [OptionProperty.height]: Object.keys(thicknessValues).includes(type) ? thicknessValues[type] : thicknessValues.default,
      [OptionProperty.surfaceTreatment]: this.form.getControl(treatmentVar).value,
      type,
      [OptionProperty.typeOfWood]: this.form.getControl(woodTypeVar).value,
      width: parseInt(this.form.getControl(widthVar).value, 10)
    };

    const shortType = cartItemCandidate.type
        + cartItemCandidate[OptionProperty.typeOfWood].substring(0, 2).toUpperCase()
        + cartItemCandidate[OptionProperty.surfaceTreatment].substring(0, 2).toUpperCase()
        + cartItemCandidate[OptionProperty.width] + 'x'
        + cartItemCandidate[OptionProperty.depth] + 'x'
        + cartItemCandidate[OptionProperty.height];

    const shortTypeEncoded = btoa(shortType);
    const itemNo: any = await this.api.getVarenr(shortTypeEncoded);

    const formattedType = this.translate('CART.BestikindsatsDescription',
        {
          type: cartItemCandidate.type,
          width: cartItemCandidate[OptionProperty.width],
          depth: cartItemCandidate[OptionProperty.depth],
          height: cartItemCandidate[OptionProperty.height],
          treesort: this.translate('TREESORTS.' + cartItemCandidate[OptionProperty.typeOfWood]),
          surface: this.translate('SURFACES.' + cartItemCandidate[OptionProperty.surfaceTreatment]),
          bottom: ''
        });

    const orderObj: ITypedAddCartItem = {
      brandId: Manufacturer.nothegger,
      type: CartItemType.extra,
      subType: CartSubType.solidOrganiser,
      name: this.translate('OTHER_NAMES.SOLID_ORGANISER'),
      itemno: itemNo.varenr,
      description: formattedType,
      amount: this.amount,
      options: cartItemCandidate,
      externalShopName: ExternalShopName.nothegger
    };

    await this.cartService.addExtra(orderObj);
    this.toastrService.success(this.translate('Cart.AddedToCart'));
    await this.router.navigateByUrl('/');
  }

}
