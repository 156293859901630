import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';

import { IPrices } from '../../../../wdcommon/IPrices';
import { APIService, LocaleService, SettingService, UserService } from '../services';
import { TranslatingBase } from '../base-component/ComponentBase';


@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent extends TranslatingBase implements OnInit {
  faEdit = faEdit;

  freightPrices: { antalSkuffer: string; pris: number; }[] = [];
  freightArea: string;
  minFreightText: string;
  freightDesc: string;
  faqGeneral: string;
  showFreightTable = false;

  constructor(private api: APIService,
              public userService: UserService,
              private locale: LocaleService,
              translate: TranslateService,
              private router: Router,
              private settingService: SettingService) {
    super(translate);
  }

  async ngOnInit() {
    this.faqGeneral = (await this.settingService.getTextSetting('faq-general')).text;

    // Handle freight section
    let tier;
    let postalCode: number;
    if (this.userService.isLoggedIn) {
      this.showFreightTable = true;

      const user = this.userService.getUser();
      tier = user.company.freightTier;

      switch (tier) {
        case 'se':
          this.freightArea = this.translate('Sverige');
          break;
        case 'no':
          this.freightArea = this.translate('Norge');
          break;
        default:
          this.freightArea = this.translate('Danmark');
      }

      if (user.company.deliveryPostcode) {
        postalCode = user.company.deliveryPostcode;
      } else if (user.company.postcode) {
        postalCode = user.company.postcode;
      } else {
        this.freightDesc = this.translate('FAQ_GENERAL_TEXTS.SHIPPING_COSTS_PRICES');
        this.showFreightTable = false;
      }

      const prices: IPrices = await this.api.getPrices();
      const freights = prices.tillaeg.fragt[tier].postnr.find((p) => p.minPostalCode <= postalCode && postalCode <= p.maxPostalCode);
      if (!freights) {
        this.freightDesc = this.translate('FAQ_GENERAL_TEXTS.SHIPPING_COSTS_PRICES');
        this.showFreightTable = false;
      }

      this.minFreightText = this.translate('FAQ_GENERAL_TEXTS.MINIMUM_SHIPPING_PRICE', { price: freights['min.fragt'] });
      const drawerPostalIntervalPattern = '[0-9\-]';
      Object.keys(freights).forEach((freightPropertyName) => {
        if (freightPropertyName.match(drawerPostalIntervalPattern)) {
          this.freightPrices.push({ antalSkuffer: freightPropertyName, pris: freights[freightPropertyName] });
        }
      });
      this.freightDesc = this.translate('FAQ_GENERAL_TEXTS.SHIPPING_COSTS_PRICES_POST', { postalcode: postalCode });
    } else {
      this.freightDesc = this.translate('FAQ_GENERAL_TEXTS.SHIPPING_COSTS_PRICES');
    }
  }

  async edit() {
    await this.router.navigateByUrl('/admin/faq/general');
  }
}
