import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { FieldConfig, FormRow } from '../dynaform.interfaces';


@Component({
  selector: 'app-dynamic-form',
  template: `
      <form class="dynamic-form" [id]="id" #formElement [formGroup]="form" (submit)="onSubmit($event)">
          <div *ngFor="let row of renderRows;" [id]="row.id" class="row">
              <ng-container *ngIf="(row.show || row.show === undefined) && row.type !== 'configuration'">
                  <ng-container *ngFor="let field of row.fields;">
                      <ng-container *ngIf="field.show || field.show === undefined" dynamicField [field]="field" [group]="form"></ng-container>
                  </ng-container>
              </ng-container>
          </div>
          <ng-content></ng-content>
      </form>
  `,
  styles: []
})
export class DynamicFormComponent implements OnChanges, AfterViewInit {
  @Input() autoFocusFirst: boolean;
  @Input() id: string;
  @Input() rows: FormRow[] = [];

  @Output() submit: EventEmitter<any> = new EventEmitter<any>();
  @Output() formCreated: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('formElement') formElement: ElementRef;

  form: UntypedFormGroup;
  renderRows: FormRow[] = [];

  constructor(
      private fb: UntypedFormBuilder
  ) {
  }

  get valid() {
    return this.form.valid;
  }

  get invalid() {
    return this.form.invalid;
  }

  get touched() {
    return this.form.touched;
  }

  get untouched() {
    return this.form.untouched;
  }

  async ngAfterViewInit() {
    await this.autoFocus();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['rows']) {

      for (const row of this.rows || []) {
        row.show = row.show || row.show === undefined;

        for (const field of row.fields || []) {
          field.show = field.show || field.show === undefined;

          if (!field.helptext)
            field.helptext = '';
          if (!field.helpHTML)
            field.helpHTML = '';
        }
      }

      this.form = this.createControl();

      this.renderRows = this.rows;
      await this.autoFocus();
      this.formCreated.emit(true);

    }
  }

  markAsTouched() {
    this.form.markAsTouched();
  }

  getField(name: string) {
    for (const row of this.rows || []) {
      for (const field of row.fields || []) {
        if (field.name === name)
          return field;
      }
    }

    return null;
  }

  getAllFields() {
    let searchFields: FieldConfig[] = [];
    this.rows.forEach((row: FormRow) => {
      if (row.type !== 'configuration') {
        searchFields = searchFields.concat(row.fields);
      }
    });
    return searchFields;
  }

  getRow(name: string) {
    return this.rows.find((r: FormRow) => {
      return r.id === name;
    });
  }

  getRowForField(field: FieldConfig): FormRow {
    return this.rows.find((r: FormRow) => {
      return r.fields.find((f: FieldConfig) => f === field) !== undefined;
    });
  }

  getControl(name: string) {
    return this.form.get(name);
  }

  getAllControls() {
    return this.form.controls;
  }

  onSubmit(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.form.valid) {
      console.log('The form is valid!');
      this.submit.emit(this.form.value);
    } else {
      console.log('We validate all fields!!');
      this.validateAllFormFields(this.form);
    }
  }

  private createControl() {
    const group = this.fb.group({});

    this.rows.forEach(r => {

      if (r.type === 'configuration') {
        return;
      }

      r.fields.forEach(field => {
        if (field.type === 'button') {
          return;
        } else if (field.type === 'html') {
          return;
        }
        const controlDisabled = field.disabled || false;
        const initialValue = field.value || null;
        const control = this.fb.control(
            { value: initialValue, disabled: controlDisabled },
            this.bindValidations(field.validations || [])
        );

        group.addControl(field.name, control);
      });
    });

    return group;
  }

  private bindValidations(validations: any) {
    if (validations.length > 0) {
      const validList = [];
      validations.forEach(valid => {
        validList.push(valid.validator);
      });
      return Validators.compose(validList);
    }
    return null;
  }

  private async autoFocus() {
    if (!this.autoFocusFirst) {
      return;
    }

    const startTime = new Date().getTime();
    const lessThanFiveSecondsHasElapsed = () => (new Date().getTime() - startTime) < 5000;

    while (lessThanFiveSecondsHasElapsed()) {
      const nativeElement = (this.formElement || { nativeElement: undefined }).nativeElement;

      if (nativeElement && typeof (nativeElement.querySelectorAll) === 'function') {
        const ctrls = this.formElement.nativeElement.querySelectorAll('select,input,textarea') || [];
        if (ctrls.length > 0 && typeof (ctrls[0].focus) === 'function') {
          ctrls[0].focus();
          return;
        }
      }

      await new Promise((resolve) => setTimeout(() => resolve(null), 100));
    }
  }

  private validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

}
