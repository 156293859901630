import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { APIService, DrawerOptionsService, HettichService, NamingService, PriceCalculatorService } from './';
import {
  AdditionalCartItem,
  CabinetCarcassCartItem,
  Cart,
  CartItem,
  DrawerCartItem,
  ExtraCartItem,
  FrontCartItem,
  OtherCartItem,
  RunnerCartItem,
  SlacksDrawerCartItem,
  SynchronisationBarAdapterCartItem,
  SynchronisationBarCartItem
} from './CartItems';
import { CartItemType, CartSubType, ITypedAddCartItem, ITypedDrawerAddCartItem } from '../../../../wdcommon/ICartItem';
import { ICompanyAdjustments } from '../../../../wdcommon/ICompany';


export class CartItemFactory {
  constructor(
      private _apisService: APIService,
      private _cartProvider: () => Cart,
      private _companyAdjustmentsProvider: () => Promise<ICompanyAdjustments>,
      private _hettichService: HettichService,
      private _namingService: NamingService,
      private _optsService: DrawerOptionsService,
      private _priceService: PriceCalculatorService,
      private _translateService: TranslateService,
  ) {
  }

  public async create(item: ITypedAddCartItem): Promise<CartItem> {
    if (!item) {
      throw new Error('Parameter \'item\' cannot be null.');
    }

    let cartItem: CartItem;
    switch (item.type) {
      case CartItemType.drawer:
        cartItem = new DrawerCartItem(
            this._apisService,
            this._companyAdjustmentsProvider,
            item as ITypedDrawerAddCartItem,
            this._namingService,
            this._optsService,
            this._priceService,
            this._translateService,
            this._hettichService,
        );
        break;

      case CartItemType.other:
        cartItem = new OtherCartItem(this._translateService, this._priceService, this._companyAdjustmentsProvider, item, this._hettichService);
        break;

      case CartItemType.extra:
        switch (item.subType) {
          case CartSubType.slacksDrawer:
            cartItem = new SlacksDrawerCartItem(this._translateService, this._priceService, this._companyAdjustmentsProvider, this._optsService, this._hettichService, item);
            break;

          default:
            cartItem = new ExtraCartItem(this._translateService, this._priceService, this._companyAdjustmentsProvider, item);
        }
        break;

      case CartItemType.runner:
        cartItem = new RunnerCartItem(this._translateService, this._priceService, item, this._companyAdjustmentsProvider);
        break;

      case CartItemType.hettichRunnerAddOn:
        switch (item.itemno) { // Added to cart automagically and defined by other products in the cart
          case 'synchronisationBar':
            cartItem = new SynchronisationBarCartItem(this._translateService, this._priceService, this._companyAdjustmentsProvider, this._hettichService);
            break;

          case 'synchronisationBarAdapter':
            cartItem = new SynchronisationBarAdapterCartItem(this._translateService, this._priceService, this._companyAdjustmentsProvider, this._hettichService);
            break;

          default:
            cartItem = new OtherCartItem(this._translateService, this._priceService, this._companyAdjustmentsProvider, item, this._hettichService);
        }
        break;

      case CartItemType.additional:
        cartItem = new AdditionalCartItem(this._translateService, this._priceService, item, this._companyAdjustmentsProvider);
        break;

      case CartItemType.fronts:
        cartItem = new FrontCartItem(this._translateService, this._priceService, item, this._companyAdjustmentsProvider);
        break;

      case CartItemType.carcass:
        cartItem = new CabinetCarcassCartItem(this._translateService, this._priceService, item);
        break;

      default:
        throw new Error('Unsupported drawer type.');
    }

    await cartItem.initialize(this._cartProvider());

    return cartItem;

  }

}

@Injectable({
  providedIn: 'root'
})
export class CartItemFactoryFactory {
  private readonly _priceService: PriceCalculatorService;
  private readonly _namingService: NamingService;
  private readonly _translateService: TranslateService;
  private readonly _optsService: DrawerOptionsService;
  private readonly _apiService: APIService;
  private readonly _hettichService: HettichService;

  constructor(
      private priceService: PriceCalculatorService,
      private namingService: NamingService,
      private translateService: TranslateService,
      private optsService: DrawerOptionsService,
      private apiService: APIService,
      private hettichService: HettichService,
  ) {
    this._priceService = priceService;
    this._namingService = namingService;
    this._translateService = translateService;
    this._optsService = optsService;
    this._apiService = apiService;
    this._hettichService = hettichService;
  }

  public create(companyAdjustments: () => Promise<ICompanyAdjustments>, cartProvider: () => Cart): CartItemFactory {
    if (!companyAdjustments)
      throw new Error('Parameter \'companyAdjustments\' cannot be null.');
    if (!cartProvider)
      throw new Error('Parameter \'cartProvider\' cannot be null.');

    return new CartItemFactory(this._apiService, cartProvider, companyAdjustments, this._hettichService, this._namingService, this._optsService, this._priceService, this._translateService);
  }
}
