import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { UserService } from '../services';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private userService: UserService) { }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (!this.userService.getUser()) {
      await this.router.navigateByUrl('/login');
      return false;
    }

    const res = await this.userService.checkUserIsValid();
    if (!res) {
      await this.router.navigateByUrl('/logout');
    }
    return res;
  }
}
