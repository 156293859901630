import { Component, Input } from '@angular/core';
import { IOrder } from '../../../../../wdcommon/IOrder';

@Component({
  selector: 'app-order-layout-header',
  templateUrl: './order-layout-header.component.html',
  styleUrls: ['./order-layout-header.component.css']
})
export class OrderLayoutHeaderComponent {
  @Input() order: IOrder;
  @Input() showStatus = false;
  @Input() warning = '';
}
