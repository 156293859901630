import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { utils, WorkBook, WorkSheet, writeFile } from 'xlsx';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { faStoreAlt } from '@fortawesome/free-solid-svg-icons/faStoreAlt';
import { faStoreAltSlash } from '@fortawesome/free-solid-svg-icons/faStoreAltSlash';
import { faStore } from '@fortawesome/free-solid-svg-icons/faStore';
import { faStoreSlash } from '@fortawesome/free-solid-svg-icons/faStoreSlash';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';

import { IProducer } from '../../../../../wdcommon/IProducer';
import { IUser } from '../../../../../wdcommon/IUser';
import { APIService, UtilitiesService } from '../../services';


@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.css']
})
export class AdminUsersComponent implements OnInit {
  @ViewChild('pop') pop: PopoverDirective;
  @BlockUI('delUserBlock') delUserBlock: NgBlockUI;

  faCheck = faCheck;
  faDownload = faDownload;
  faExclamationTriangle = faExclamationTriangle;
  faStoreAlt = faStoreAlt;
  faStoreAltSlash = faStoreAltSlash;
  faStore = faStore;
  faStoreSlash = faStoreSlash;
  faTrash = faTrash;

  producers = {
    ernstMair: IProducer.ernstMair,
    purewood: IProducer.purewood
  };

  users: IUser[];
  filteredUsers: IUser[];
  searchNeedle = '';
  modalRef: BsModalRef;
  sortOrder: 'asc' | 'desc' = 'asc';
  sortProperty: keyof IUser = 'name';

  deleteID: number;

  constructor(
      public modalService: BsModalService,
      private api: APIService,
      private changeDetector: ChangeDetectorRef,
      private toastrService: ToastrService,
  ) {
  }

  setFilteredUsers(users: IUser[]) {
    if (this.searchNeedle.length > 2) {
      users = this.users.filter((u) => {
        return (u.name.toLowerCase().indexOf(this.searchNeedle.toLowerCase()) > -1
            || u.company.companyName.toLowerCase().indexOf(this.searchNeedle.toLowerCase()) > -1
            || u.email.toLowerCase().indexOf(this.searchNeedle.toLowerCase()) > -1);
      });
    }

    this.filteredUsers = UtilitiesService.sortByPropertyName(users, this.sortProperty, this.sortOrder);
  }

  sortBy(property: keyof IUser) {
    if (property === this.sortProperty)
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    else {
      this.sortProperty = property;
      this.sortOrder = 'asc';
    }

    this.setFilteredUsers(this.users);
  }

  async ngOnInit() {
    await this.api.getAllUsers().toPromise()
        .then((r) => {
          r.map((u) => u.companyName = u.company.companyName);
          this.users = r;
          this.setFilteredUsers(r);
        });
  }

  searchFilter() {
    this.setFilteredUsers(this.users);
  }

  filter(type: string) {
    if (type === 'none' || type === null) {
      this.filteredUsers = this.users;
    } else if (type === 'notactive') {
      this.filteredUsers = this.users.filter(u => {
        return u.activated === false;
      });
    }
  }

  userActivated(activatedUser: IUser) {
    this.users.find((user) => user.id === activatedUser.id).activated = true;
  }

  hidePop() {
    this.pop.hide();
  }

  async deleteUser() {
    if (this.deleteID) {
      this.delUserBlock.start();
      console.log('deleting user', this.deleteID);

      await this.api.deleteUser(this.deleteID).toPromise();
      console.log('user was deleted!');
      this.api.getAllUsers().subscribe(r => {
        // filtrer bruger id'et vi lige har sletet ud
        // i visse tilfælde vil angular ikke fange en frisk bruger række uden et refresh
        this.users = (r as any[]).filter(u => {
          return u.id !== this.deleteID;
        });
        this.filteredUsers = this.users;
        this.pop.hide();
        this.delUserBlock.stop();
        this.toastrService.success('Brugeren er slettet');
        this.changeDetector.detectChanges();
        this.deleteID = null;
      });

    }
  }

  async setAccessToProducer(producer: IProducer, allowAccess: boolean, userId: number) {
    switch (producer) {
      case IProducer.ernstMair:
        this.users.find((user) => user.id === userId).allowErnstMair = allowAccess;
        break;

      case IProducer.purewood:
        this.users.find((user) => user.id === userId).allowPurewood = allowAccess;
        break;

      default:
        this.toastrService.error('Ukendt producent angivet: ' + producer + '.');
    }
    await this.api.allowProducerAccess(producer, allowAccess, userId);
    this.toastrService.success('Brugeren er har nu ' + (allowAccess ? '' : 'ikke længere ') + 'adgang til ' + producer + ' produkter.');
  }

  exportToExcel() {
    const userData = [];
    for (const user of this.users) {
      userData.push({
        'Navn': user.name,
        'Brugernavn': user.username,
        'Email': user.email,
        'Firma': user.companyName,
        'Firma tlf': user.company.phone,
        'Seneste Login': user.lastLogin
      });
    }

    const ws: WorkSheet = utils.json_to_sheet(userData);
    ws['!cols'] = [
      { wch: 20 },
      { wch: 20 },
      { wch: 25 },
      { wch: 30 },
      { wch: 15 },
      { wch: 22 }
    ];
    const wb: WorkBook = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Sheet1');
    writeFile(wb, 'UserData.xlsx');
  }

  // DONE: rediger og slet bruger sider
}
