<button (click)="modalRef = modalService.show(activateUserModal)" *ngIf="user.activated === false" class="btn btn-info btn-sm"
        tooltip="Aktivér bruger">
    <fa-icon [icon]="faUserCheck"></fa-icon>
</button>&nbsp;&nbsp;

<ng-template #activateUserModal>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">Aktivér bruger</h5>
            <button (click)="modalRef.hide()" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            Vil du aktivere brugeren <b>{{ activatingEmail }}</b>?
        </div>
        <div class="modal-footer">
            <button (click)="activateUser()" class="btn btn-primary" type="button" [disabled]="activating">Aktivér bruger</button>
            <button (click)="modalRef.hide()" class="btn btn-secondary" data-dismiss="modal" type="button">Annullér</button>
        </div>
    </div>
</ng-template>
