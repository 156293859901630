<div class="container">
    <div>
        <app-admin-products-language *appAdminViewOnly class="float-end"></app-admin-products-language>
        <h3>Ernst Mair</h3>
    </div>
    <div class="mb-2 text-muted">
        <span>
            <a (click)=goto() style="cursor:pointer">Ernst Mair</a>
            <span *ngIf="showDrawers"> / <a style="cursor:pointer">Drawers</a></span>
        </span>
    </div>
    <div *ngIf="showDrawers === false" class="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-5 g-4">
        <div class="col">
            <div class="card wd-card h-100">
                <div class="card-header">Ernst Mair
                    <button (click)="editErnstMair()" class="btn btn-sm ms-3 float-end" type="button">
                        <fa-icon [icon]="faEdit"></fa-icon>
                    </button>
                </div>
                <div class="card-body">
                    <div (click)="goto('drawers')" class="d-flex align-items-center justify-content-center">
                        <div>
                            <img alt="" class="img-fluid" src="/assets/images/ernst-mair/ernst-mair.png"/>
                        </div>
                    </div>
                    <p class="card-text">{{ernstMairDescription}}</p>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="showDrawers" class="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-5 g-4">
        <div *ngFor="let d of drawers" class="col">
            <div class="card wd-card h-100">
                <div class="card-header">{{ d.name | translate }}
                    <button (click)="editErnstMair(d.type)" class="btn btn-sm ms-3 float-end" type="button">
                        <fa-icon [icon]="faEdit"></fa-icon>
                    </button>
                </div>
                <div class="card-body">
                    <div class="d-flex align-items-center justify-content-center">
                        <div>
                            <img [src]="d.img" alt="" class="img-fluid"/>
                        </div>
                    </div>
                    <p class="card-text">{{ d.description }}</p>
                </div>
            </div>
        </div>
    </div>
</div>
