import { Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { firstValueFrom } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faComment } from '@fortawesome/free-regular-svg-icons/faComment';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons/faEnvelopeOpen';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons/faFilePdf';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons/faShoppingCart';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faTree } from '@fortawesome/free-solid-svg-icons/faTree';
import { faWrench } from '@fortawesome/free-solid-svg-icons/faWrench';
import * as moment from 'moment';

import { Constants } from '../../../../../wdcommon/Constants';
import { CartItemType, ICartItem, OrderManuallyAtNothegger } from '../../../../../wdcommon/ICartItem';
import { ICompany } from '../../../../../wdcommon/ICompany';
import { FeeTypes, IExternalShopOrder, IOrder, IOrderStatus } from '../../../../../wdcommon/IOrder';
import { IPrices } from '../../../../../wdcommon/IPrices';
import { OptionProperty, Manufacturer } from '../../../../../wdcommon/IProduct';
import { TranslatingBase } from '../../base-component/ComponentBase';
import { APIService, CartService, UserService } from '../../services';
import { Breadcrumb } from '../../utils/breadcrumbs/breadcrumbs';


@Component({
  selector: 'app-admin-order',
  templateUrl: './admin-order.component.html',
  styleUrls: ['./admin-order.component.css']
})
export class AdminOrderComponent extends TranslatingBase implements OnInit {
  adminOrderBreadcrumb: Breadcrumb;

  order: IOrder;
  drawers: (ICartItem & { udsparing?: { drawerID: string, imageFile: { id: number; }; }; })[] = [];
  notheggerDrawers: (ICartItem & { udsparing?: { drawerID: string, imageFile: { id: number; }; }; })[] = [];
  ernstMairDrawers: (ICartItem & { udsparing?: { drawerID: string, imageFile: { id: number; }; }; })[] = [];
  purewoodDrawers: (ICartItem & { udsparing?: { drawerID: string, imageFile: { id: number; }; }; })[] = [];
  others: ICartItem[] = [];
  runner: ICartItem[] = [];
  additionals: ICartItem[] = [];
  fronts: ICartItem[] = [];
  carcass: ICartItem[] = [];

  warning = '';

  orderEmails = [];

  customerMail = {
    subject: '',
    email: '',
    message: ''
  };

  notifyCustomer = false;
  changeDeliveryDate = false;

  levDate = new Date();
  bsDeliveryDate: any;

  modalRef: BsModalRef;

  newStatusID: number;

  orderStatuses: IOrderStatus[];
  company: ICompany;

  udsparingImagePlaceholder = null;
  tmpUdsparingDrawerID = '';

  itemsCollapse = [];

  additionalsBreakageFees: { count: number; price: any; };

  orderAt: IExternalShopOrder;
  orderingExternally = false;
  externalOrderError?: string;
  orderedExternally: boolean;
  orderManually: number[] = OrderManuallyAtNothegger;

  protected readonly faChevronDown = faChevronDown;
  protected readonly faChevronUp = faChevronUp;
  protected readonly faComment = faComment;
  protected readonly faEdit = faEdit;
  protected readonly faEnvelopeOpen = faEnvelopeOpen;
  protected readonly faExclamationTriangle = faExclamationTriangle;
  protected readonly faFilePdf = faFilePdf;
  protected readonly faShoppingCart = faShoppingCart;
  protected readonly faTrash = faTrash;
  protected readonly faTree = faTree;
  protected readonly faWrench = faWrench;
  protected readonly feeTypes = FeeTypes;
  protected readonly OptionProperty = OptionProperty;

  constructor(private api: APIService,
              private cart: CartService,
              private router: Router,
              private toastrService: ToastrService,
              private route: ActivatedRoute,
              public modalService: BsModalService,
              translateService: TranslateService,
              private userService: UserService) {
    super(translateService);
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
  }

  async ngOnInit() {
    this.orderStatuses = await firstValueFrom(this.api.getOrderStatuses());

    this.route.params.subscribe((params) => this.getOrderDetails(params.id));
  }

  sendCustomerMail() {
    this.api.sendMail(this.customerMail.email, this.customerMail.subject, this.customerMail.message)
        .subscribe((r: any) => {
          if (r.err) {
            return this.toastrService.error(r.message);
          }
          this.toastrService.success('Beskeden er sendt med email til ' + this.customerMail.email);
          this.modalRef.hide();
        });
  }

  deleteOrder() {
    this.api.deleteOrder(this.order.id).subscribe(() => {
      this.toastrService.success('Ordre ' + this.order.orderID + ' er slettet');
      this.modalRef.hide();
      return this.router.navigateByUrl('/admin/orders');
    });
  }

  async showUdsparing(id, dId) {
    this.tmpUdsparingDrawerID = dId;
    const image: any = await firstValueFrom(this.api.getProductImage(id));
    this.udsparingImagePlaceholder = image.encImg;
  }

  updateOrderStatus() {
    const newStatus = this.orderStatuses.find((s) => {
      return s.id === parseInt(this.newStatusID + '', 10);
    });
    this.api.updateOrderStatus(this.order.id, newStatus).subscribe(async (orderStatus: any) => {
      this.order.status = orderStatus;
      this.modalRef.hide();

      if (this.changeDeliveryDate) {
        await firstValueFrom(this.api.updateOrderDeliveryDate(this.order.orderID, this.bsDeliveryDate));
        this.order.deliveryDate = this.bsDeliveryDate;
      }

      if (this.notifyCustomer) {
        await firstValueFrom(this.api.sendMail(this.order.user.email, 'Ordre opdatering vedr.' + this.order.orderID,
            `Status på din ordre ${this.order.orderID} er opdateret.<br>` +
            `Status er nu: <b>${orderStatus.statusText}</b><br>` +
            `<br>` +
            `Leveringsdato er: <b>${formatDate(this.order.deliveryDate, 'dd-MM-yyyy', 'da')}</b><br><br>` +
            `Med venlig hilsen<br>` +
            `Unit-Partner ApS<br><br>` +
            `<i>NB. Denne email kan ikke besvares</i>`
        ));
      }

      this.toastrService.success('Ordre status er opdateret til ' + orderStatus.statusText);

      this.changeDeliveryDate = false;
      this.notifyCustomer = false;
    });
  }

  getPDF() {
    this.api.getOrderPDF(this.order.orderID)
        .subscribe({
          next: (r: any) => {
            const file = new Blob([r], {
              type: 'application/pdf'
            });

            const fileURL = URL.createObjectURL(file);
            const a = document.createElement('a');
            a.setAttribute('style', 'display: none');
            a.href = fileURL;
            a.download = this.order.orderID + '.pdf';
            a.click();
            URL.revokeObjectURL(fileURL);
            a.remove();
          },
          error: () => {
            this.toastrService.error(this.translate('ORDER.PDFNOTFOUND'), this.translate('ORDER.PDFNOTFOUNDTitle'));
          }
        });
  }

  async editOrder() {
    const orderContents = await firstValueFrom(this.api.getOrderContents(this.order.orderID));
    await this.cart.import(JSON.stringify(orderContents), { orderId: this.order.orderID, id: this.order.id });
    const didNavigate = await this.router.navigateByUrl('/cart');
    if (!didNavigate) {
      this.toastrService.error(this.translate('CART.Navigation.Fail'));
    }
    this.modalRef.hide();
  }

  async recallOrder() {
    const orderContents = await firstValueFrom(this.api.getOrderContents(this.order.orderID));
    await this.cart.import(JSON.stringify(orderContents));
    const didNavigate = await this.router.navigateByUrl('/cart');
    if (!didNavigate) {
      this.toastrService.error(this.translate('CART.Navigation.Fail'));
    }
    this.modalRef.hide();
  }

  async restoreOrder(projectName: string) {
    if (!projectName || projectName.trim() === '') {
      return this.toastrService.error(this.translate('Order.Restore.Projectname_Missing'));
    }

    await this.api.postRestoredOrder({
      cart: JSON.stringify(this.order.contents),
      name: projectName,
      user: this.order.user,
    });
    this.toastrService.success(this.translate('Order.Restore.Saved', { name: projectName, user: this.order.user.name }));
    this.modalRef.hide();
  }

  shouldBeCollapsed(index: string) {
    const collapseItem = this.itemsCollapse[index];
    return collapseItem === true || collapseItem === undefined;
  }

  toggleCollapse(index: string) {
    this.itemsCollapse[index] = !this.shouldBeCollapsed(index);
  }

  async transferOrder() {
    const orders = [this.order];
    this.api.transferOrders(orders)
        .subscribe(() => {
          this.order.status = this.orderStatuses.find((s) => {
            return s.id === 9;
          });
          this.modalRef.hide();
        });
  }

  async createExternalOrder() {
    this.orderingExternally = true;
    this.externalOrderError = undefined;
    const user = this.userService.getUser();
    const orderResponse = await this.api.createExternalShopOrder(this.orderAt, this.order, user);
    if (orderResponse.error) {
      console.warn('We have an error', orderResponse);
      this.externalOrderError = orderResponse.error;
    } else if (orderResponse.configErrors) {
      console.warn('Invalid config', orderResponse, this.router.url);
      this.externalOrderError = Object.values(orderResponse.configErrors).join(', ');
    } else {
      await this.router.navigateByUrl(this.router.url);
    }
    this.modalRef.hide();
    this.orderingExternally = false;
  }

  async getFrontDesign(fileId: string, itemNo: string) {
    try {
      const pdf = await this.api.getFrontDesignPdf(fileId);
      const url = window.URL.createObjectURL(new Blob([pdf], { type: 'application/pdf' }));
      const downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.setAttribute('download', this.order.orderID + '_' + itemNo + '_design.pdf');
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (e) {
      console.error('PDF error', e);
    }
  }

  private async getOrderDetails(orderId: any) {
    this.adminOrderBreadcrumb = {
      name: 'Ordre #' + orderId,
      parentId: 'adminOrders',
      url: '/admin/orders/' + orderId,
    };

    this.orderEmails = [];
    this.drawers = [];
    this.notheggerDrawers = [];
    this.ernstMairDrawers = [];
    this.purewoodDrawers = [];
    this.runner = [];
    this.fronts = [];
    this.carcass = [];
    this.others = [];
    this.additionals = [];

    const prices: IPrices = await this.api.getPrices();
    this.order = await firstValueFrom(this.api.getOrder(orderId));
    this.company = await this.api.getCompany(this.order.company.id);
    this.newStatusID = this.order.status.id;

    const contents = this.order.contents || [];
    const today = moment();

    contents.forEach((item) => {
      switch (item.type) {
        case CartItemType.drawer:
          this.drawers.push(item);
          if (item.options.type.indexOf(Manufacturer.ernstMair) > -1) {
            this.ernstMairDrawers.push(item);
          } else if (item.options.type.indexOf(Manufacturer.purewood) > -1) {
            this.purewoodDrawers.push(item);
          } else {
            this.notheggerDrawers.push(item);
          }
          break;

        case CartItemType.runner:
          this.runner.push(item);
          break;

        case CartItemType.additional:
          this.additionals.push(item);
          break;

        case CartItemType.fronts:
          this.fronts.push(item);
          break;

        case CartItemType.carcass:
          this.carcass.push(item);
          break;

        default:
          this.others.push(item);
      }
    });

    this.additionalsBreakageFees = {
      count: this.additionals
          .filter(additional => additional.breakageFeeIncluded)
          .length,
      price: prices.tillaeg.breakageFee[Constants.breakageFeeItemNumber]
    };

    this.orderEmails.push(this.order.user.email);
    this.orderEmails.push(this.order.company.email);

    const deliveryDate = moment(this.order.deliveryDate);
    this.levDate = deliveryDate.toDate();
    this.bsDeliveryDate = this.levDate;
    if (deliveryDate.subtract(3, 'd').isAfter(today)) {
      this.warning = 'warning';
    } else if (deliveryDate.isBefore(today)) {
      this.warning = 'severe';
    }

    if (this.drawers.length > 0) {
      this.api.getOrderUdsparinger(this.order.id).subscribe((udsps) => {
        if (udsps.length > 0) {
          udsps.forEach(u => {
            // find skuffen der tilhører udsparingen
            const udspDrawer = this.drawers.find((d) => {
              return d.itemno === u.drawerID;
            });
            if (udspDrawer) {
              udspDrawer.udsparing = u;
            }
          });
        }
      });
    }

    this.orderedExternally = false;
    if (Array.isArray(this.order.externalShopOrders)) {
      for (const ext of this.order.externalShopOrders) {
        if (ext.orderId) {
          this.orderedExternally = true;
          break;
        }
      }
    }
  }
}
