<div *ngIf="order" class="container">
    <div class="row mb-3">

        <div class="col">
            <app-breadcrumbs [currentBreadcrumb]="adminOrderBreadcrumb"></app-breadcrumbs>
        </div>

    </div>

    <!-- Error row -->
    <div *ngIf="externalOrderError" class="row mb-3">

        <div class="col-sm">
            <div class="alert alert-danger" role="alert">
                <h4 class="alert-heading">Fejl ved ekstern bestilling</h4>
                {{externalOrderError}}
            </div>
        </div>

    </div>
    <!-- Ordered externally row -->
    <ng-container *ngFor="let ext of order.externalShopOrders">
        <div *ngIf="ext.orderId" class="row mb-3">

            <div class="col-sm">
                <div class="alert alert-info" role="alert">
                    <h4 class="alert-heading">Ordren eller dele heraf er bestilt hos {{ext.shopName}}</h4>
                    <div>Ordre ID: {{ext.orderId}}</div>
                    <div>Bestillingstidspunkt: {{ext.orderedAt | date}}</div>
                </div>
            </div>

        </div>
    </ng-container>

    <!-- Order header row -->
    <app-order-layout-header [order]="order" [showStatus]="true" [warning]="warning"></app-order-layout-header>


    <!-- Button row -->
    <div class="row mb-3">

        <div class="col-sm text-end col-with-top-margin">
            <button (click)="modalRef = modalService.show(contactCustomer, { class: 'modal-md'})" class="btn btn-sm btn-primary">
                {{ 'Order.Contact.Customer' | translate }} &nbsp;&nbsp;
                <fa-icon [icon]="faEnvelopeOpen"></fa-icon>
            </button>&nbsp;
            <button (click)="modalRef = modalService.show(confirmUpdateStatus, { class: 'modal-md'})" class="btn btn-sm btn-primary">
                {{ 'Order.Update.Status' | translate }} &nbsp;<fa-icon [icon]="faEdit"></fa-icon>
            </button>&nbsp;
            <button (click)="modalRef = modalService.show(confirmTransfer, { class: 'modal-sm'})"
                    *ngIf="order.status.id === 8 || order.status.id === 0" class="btn  btn-sm btn-primary">
                {{ 'Order.Transfer' | translate}}
            </button>&nbsp;
            <ng-container *ngFor="let externalOrder of order.externalShopOrders">
                <button (click)="orderAt = externalOrder; modalRef = modalService.show(confirmExternalOrder)" *ngIf="!externalOrder.orderId"
                        class="btn btn-sm btn-primary">
                    {{ 'Order.externalOrder.OrderAt' + externalOrder.shopName | translate }}
                    <fa-icon [icon]="faShoppingCart"></fa-icon>
                </button>&nbsp;
            </ng-container>
            <button (click)="getPDF()" class="btn btn-sm btn-primary">
                Download PDF &nbsp;&nbsp;<fa-icon [icon]="faFilePdf"></fa-icon>
            </button>&nbsp;
            <!-- <button class="btn btn-primary" *ngIf="order.ecoID !== ''" (click)="getEcoPDF()">Download ECO PDF &nbsp;&nbsp;<fa-icon [icon]="faFilePdf"></fa-icon></button>&nbsp; -->
            <button (click)="modalRef = modalService.show(confirmRecall, { class: 'modal-sm'})" class="btn btn-sm btn-primary">
                {{ 'Order.Recall_Order' | translate }}
            </button>&nbsp;
            <button (click)="modalRef = modalService.show(confirmEdit, { class: 'modal-sm'})" *ngIf="!orderedExternally"
                    class="btn btn-sm btn-primary">
                {{ 'Order.Edit_Order' | translate }}
            </button>&nbsp;
            <button (click)="modalRef = modalService.show(confirmRestore, { class: 'modal-sm'})" class="btn btn-sm btn-primary">
                {{ 'Order.Restore_Order' | translate}}
            </button>&nbsp;
            <button (click)="modalRef = modalService.show(confirmDelete, { class: 'modal-sm'})" class="btn btn-sm btn-danger">
                {{ 'Order.Delete'| translate }} &nbsp;&nbsp;<fa-icon [icon]="faTrash"></fa-icon>
            </button>
        </div>

    </div>

    <!-- Nothegger drawers row -->
    <div *ngIf="notheggerDrawers.length > 0" class="row">

        <div class="col-sm">
            <h5>{{ 'UTILITY.DRAWERS' | translate }}</h5>
            <table class="table">
                <thead>
                <tr class="d-flex">
                    <th class="col-sm-2">
                        {{ 'UTILITY.ITEM_NO' | translate }}
                        <div [title]="'Order.externalOrder.OrderExternally' | translate" class="float-end">
                            <fa-icon [icon]="faShoppingCart"></fa-icon>
                        </div>
                    </th>
                    <th class="col-sm-4">{{ 'UTILITY.DESC' | translate }}</th>
                    <th class="col-sm-1 text-center">{{ 'UTILITY.AMOUNT' | translate }}</th>
                    <th class="col-sm-2 text-center">
                        {{ 'PRICING.UNIT_PRICE' | translate }} ({{ 'PRICING.EX_VAT' | translate}})
                    </th>
                    <th class="col-sm-2 text-end">
                        {{ 'PRICING.PRICE_TOTAL' | translate }} ({{ 'PRICING.EX_VAT' | translate}})
                    </th>
                    <th class="col-sm-1 text-end"></th>

                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let d of notheggerDrawers;">
                    <tr class="d-flex">
                        <td class="col-sm-2">
                            {{ d.itemno }}
                            <!-- Order externally, begin -->
                            <div *ngIf="d.externalShopName && !d.externalShopSuccess && !d.externalShopError"
                                 [title]="'Kan bestilles hos ' + d.externalShopName"
                                 class="float-end">
                                <fa-icon [icon]="faShoppingCart"></fa-icon>
                            </div>
                            <div *ngIf="d.externalShopError" [title]="'Problem ved bestilling hos ' + d.externalShopName + ': ' + d.externalShopError"
                                 class="float-end">
                                <fa-icon [icon]="faShoppingCart" class="text-warning"></fa-icon>
                            </div>
                            <div *ngIf="d.externalShopSuccess" [title]="'Bestilt hos ' + d.externalShopName" class="float-end">
                                <fa-icon [icon]="faShoppingCart" class="text-success"></fa-icon>
                            </div>
                            <!-- Order externally, end -->
                        </td>
                        <td class="col-sm-4">
                            {{ d.name | translate }} - {{ d.description }}
                            <div *ngIf="d.comments">
                                <small>
                                    <fa-icon [icon]="faComment"></fa-icon>
                                    {{ d.comments }}
                                </small>
                            </div>
                            <!-- Ordered externally message, begin -->
                            <small *ngIf="d.externalShopError">
                                <fa-icon [icon]="faExclamationTriangle" class="text-warning"></fa-icon>
                                <span class="text-warning">{{d.externalShopError}}</span>&nbsp;
                            </small>
                            <ng-container *ngIf="d.externalShopSuccess">
                                <small class="text-success">{{d.externalShopName}} ordre:
                                    <ng-container *ngFor="let ext of order.externalShopOrders">
                                        <ng-container *ngIf="ext.shopName === d.externalShopName">
                                            {{ext.orderId}}
                                        </ng-container>
                                    </ng-container>
                                </small>&nbsp;
                                <small *ngIf="d.options[OptionProperty.fscCertified]" class="text-warning" title="FSC 100% skal bestilles manuelt">
                                    <fa-icon [icon]="faTree" class="text-warning"></fa-icon>
                                    FSC 100%&nbsp;
                                </small>
                                <small *ngIf="d.options.type === 'rev_ezug'" class="text-warning"
                                       title="Bestilt som Innenlade - skal manuelt bestilles som Reverse Englisher Zug">
                                    <fa-icon [icon]="faWrench" class="text-warning"></fa-icon>
                                    IL->REZ
                                </small>
                            </ng-container>
                            <!-- Ordered externally message, end -->
                            <span *ngIf="d.udsparing">
                  &nbsp;&nbsp;<img
                                    (click)="showUdsparing(d.udsparing.imageFile.id, d.udsparing.drawerID); modalRef = modalService.show(udsparingModal)"
                                    alt="udspicon" class="imagelink" src="/assets/images/udsp-icon-filled.png"
                                    style="height: 1.2em; width: auto; vertical-align: middle;" tooltip="Åben udsparing tegning">
                </span>
                        </td>
                        <td class="col-sm-1 text-center">
                            {{ d.amount }}
                        </td>
                        <td class="col-sm-2 text-center">
                            {{ d.pricePer | number: '1.2-2'}} {{ 'USED_CURRENCY' | translate }}
                        </td>
                        <td class="col-sm-2 text-end">
                            {{ d.priceTotal | number: '1.2-2'}} {{ 'USED_CURRENCY' | translate }}
                        </td>
                        <td class="col-sm-1 text-end">
                            <button (click)="toggleCollapse(d.itemno)" class="btn btn-sm btn-primary" type="button">
                                <ng-container *ngIf="shouldBeCollapsed(d.itemno)">
                                    <fa-icon [icon]="faChevronDown"></fa-icon>&nbsp;
                                    <span translate="CART.Vis"></span>
                                </ng-container>
                                <ng-container *ngIf="!shouldBeCollapsed(d.itemno)">
                                    <fa-icon [icon]="faChevronUp"></fa-icon>&nbsp;
                                    <span translate="CART.Skjul"></span>
                                </ng-container>
                            </button>
                        </td>
                    </tr>
                    <div [collapse]="shouldBeCollapsed(d.itemno)" class="card-body p-0">
                        <table class="table table-sm table-hover my-3">
                            <thead class="thead-light">
                            <tr>
                                <th translate="Order.Option"></th>
                                <th translate="UTILITY.CHOICE"></th>
                                <th class="text-end" translate="UTILITY.PRICE"></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let od of d.orderDetails">
                                <td>{{ od.name }}</td>
                                <td>{{ od.detail }}</td>
                                <td *ngIf="od.price !== 0" class="text-end">
                                    <span>{{ od.price | number: '1.2-2' }}{{ 'USED_CURRENCY' | translate }}</span>
                                </td>
                                <td *ngIf="od.price === 0" class="text-end"><span>{{ '' }}</span></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </ng-container>
                </tbody>
            </table>
        </div>

    </div>
    <!-- Ernst Mair drawers row -->
    <div *ngIf="ernstMairDrawers.length > 0" class="row">

        <div class="col-sm">
            <h5>{{ 'UTILITY.DRAWERS' | translate }}, Ernst Mair</h5>
            <table class="table">
                <thead>
                <tr class="d-flex">
                    <th class="col-sm-1">{{ 'UTILITY.ITEM_NO' | translate }}</th>
                    <th class="col-sm-4">{{ 'UTILITY.DESC' | translate }}</th>
                    <th class="col-sm-1 text-center">{{ 'UTILITY.AMOUNT' | translate }}</th>
                    <th class="col-sm-2 text-center">{{ 'PRICING.UNIT_PRICE' | translate }} ({{ 'PRICING.EX_VAT' |                  translate}})
                    </th>
                    <th class="col-sm-2 text-end">{{ 'PRICING.PRICE_TOTAL' | translate }} ({{ 'PRICING.EX_VAT' |                  translate}})
                    </th>
                    <th class="col-sm-2 text-end"></th>

                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let d of ernstMairDrawers;">
                    <tr class="d-flex">
                        <td class="col-sm-1">
                            {{ d.itemno }}
                        </td>
                        <td class="col-sm-4">
                            {{ d.name | translate }} - {{ d.description }}
                            <span *ngIf="d.udsparing">
                  &nbsp;&nbsp;<img
                                    (click)="showUdsparing(d.udsparing.imageFile.id, d.udsparing.drawerID); modalRef = modalService.show(udsparingModal)"
                                    alt="udspicon" class="imagelink" src="/assets/images/udsp-icon-filled.png"
                                    style="height: 1.2em; width: auto; vertical-align: middle;" tooltip="Åben udsparing tegning">
                </span>
                        </td>
                        <td class="col-sm-1 text-center">
                            {{ d.amount }}
                        </td>
                        <td class="col-sm-2 text-center">
                            {{ d.pricePer | number: '1.2-2'}} {{ 'USED_CURRENCY' | translate }}
                        </td>
                        <td class="col-sm-2 text-end">
                            {{ d.priceTotal | number: '1.2-2'}} {{ 'USED_CURRENCY' | translate }}
                        </td>
                        <td class="col-sm-2 text-end">
                            <button (click)="toggleCollapse(d.itemno)" class="btn btn-sm btn-primary" type="button">
                  <span *ngIf="shouldBeCollapsed(d.itemno)">
                    <fa-icon [icon]="faChevronDown"></fa-icon>&nbsp;
                    <span translate="CART.Vis"></span>
                  </span>
                                <span *ngIf="!shouldBeCollapsed(d.itemno)">
                    <fa-icon [icon]="faChevronUp"></fa-icon>&nbsp;
                    <span translate="CART.Skjul"></span>
                  </span>
                            </button>
                        </td>
                    </tr>
                    <div [collapse]="shouldBeCollapsed(d.itemno)" class="card-body" style="padding:0">
                        <table class="table table-sm table-hover"
                               style="margin-top: 20px; margin-bottom:20px">
                            <thead class="thead-light">
                            <tr>
                                <th translate="Order.Option"></th>
                                <th translate="UTILITY.CHOICE"></th>
                                <th class="text-end"><span translate="UTILITY.PRICE"></span></th>
                            </tr>
                            </thead>
                            <!--<tbody [innerHtml]="getOrderDetails(index)"> -->
                            <tbody>
                            <tr *ngFor="let od of d.orderDetails">
                                <td>{{ od.name }}</td>
                                <td>{{ od.detail }}</td>
                                <td *ngIf="od.price !== 0"
                                    class="text-end"><span>{{ od.price | number: '1.2-2' }}{{ 'USED_CURRENCY' | translate
                                    }}</span></td>
                                <td *ngIf="od.price === 0" class="text-end"><span>{{ '' }}</span></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </ng-container>
                </tbody>
            </table>
        </div>

    </div>
    <!-- Purewood drawers row -->
    <div *ngIf="purewoodDrawers.length > 0" class="row">

        <div class="col-sm">
            <h5>{{ 'UTILITY.DRAWERS' | translate }}, Purewood</h5>
            <table class="table">
                <thead>
                <tr class="d-flex">
                    <th class="col-sm-1">{{ 'UTILITY.ITEM_NO' | translate }}</th>
                    <th class="col-sm-4">{{ 'UTILITY.DESC' | translate }}</th>
                    <th class="col-sm-1 text-center">{{ 'UTILITY.AMOUNT' | translate }}</th>
                    <th class="col-sm-2 text-center">{{ 'PRICING.UNIT_PRICE' | translate }} ({{ 'PRICING.EX_VAT' | translate }})</th>
                    <th class="col-sm-2 text-end">{{ 'PRICING.PRICE_TOTAL' | translate }} ({{ 'PRICING.EX_VAT' | translate }})</th>
                    <th class="col-sm-2 text-end"></th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let d of purewoodDrawers">
                    <tr class="d-flex">
                        <td class="col-sm-1">
                            {{ d.itemno }}
                        </td>
                        <td class="col-sm-4">
                            {{ d.name | translate }} - {{ d.description }}
                            <span *ngIf="d.udsparing">
                  &nbsp;&nbsp;
                  <img (click)="showUdsparing(d.udsparing.imageFile.id, d.udsparing.drawerID); modalRef = modalService.show(udsparingModal)"
                       alt="udspicon" class="imagelink" src="/assets/images/udsp-icon-filled.png"
                       style="height: 1.2em; width: auto; vertical-align: middle;" tooltip="Åben udsparing tegning">
                </span>
                        </td>
                        <td class="col-sm-1 text-center">
                            {{ d.amount }}
                        </td>
                        <td class="col-sm-2 text-center">
                            {{ d.pricePer | number: '1.2-2'}} {{ 'USED_CURRENCY' | translate }}
                        </td>
                        <td class="col-sm-2 text-end">
                            {{ d.priceTotal | number: '1.2-2'}} {{ 'USED_CURRENCY' | translate }}
                        </td>
                        <td class="col-sm-2 text-end">
                            <button (click)="toggleCollapse(d.itemno)" class="btn btn-sm btn-primary" type="button">
                                <ng-container *ngIf="shouldBeCollapsed(d.itemno)">
                                    <fa-icon [icon]="faChevronDown"></fa-icon>&nbsp;
                                    <span translate="CART.Vis"></span>
                                </ng-container>
                                <ng-container *ngIf="!shouldBeCollapsed(d.itemno)">
                                    <fa-icon [icon]="faChevronUp"></fa-icon>&nbsp;
                                    <span translate="CART.Skjul"></span>
                                </ng-container>
                            </button>
                        </td>
                    </tr>
                    <div [collapse]="shouldBeCollapsed(d.itemno)" class="card-body p-0">
                        <table class="table table-sm table-hover my-3">
                            <thead class="thead-light">
                            <tr>
                                <th translate="Order.Option"></th>
                                <th translate="UTILITY.CHOICE"></th>
                                <th class="text-end"><span translate="UTILITY.PRICE"></span></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let od of d.orderDetails">
                                <td>{{ od.name }}</td>
                                <td>{{ od.detail }}</td>
                                <td *ngIf="od.price !== 0" class="text-end">
                                    {{ od.price | number: '1.2-2' }}{{ 'USED_CURRENCY' | translate }}
                                </td>
                                <td *ngIf="od.price === 0" class="text-end">{{ '' }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </ng-container>
                </tbody>
            </table>
        </div>

    </div>
    <!-- Drawer runners row -->
    <div *ngIf="runner.length > 0" class="row">

        <div class="col-sm">
            <h5>{{ 'DRAWER_OPTIONS.DRAWER_RUNNERS' | translate }}</h5>
            <table class="table">
                <thead>
                <tr class="d-flex">
                    <th class="col-sm-2">{{ 'UTILITY.ITEM_NO' | translate }}</th>
                    <th class="col-sm-4">{{ 'UTILITY.DESC' | translate }}</th>
                    <th class="col-sm-2 text-center">{{ 'UTILITY.AMOUNT' | translate }}</th>
                    <th class="col-sm-2 text-center">{{ 'PRICING.UNIT_PRICE' | translate }}
                        ({{ 'PRICING.EX_VAT' | translate}})
                    </th>
                    <th class="col-sm-2 text-end">{{ 'PRICING.PRICE_TOTAL' | translate }}
                        ({{ 'PRICING.EX_VAT' | translate}})
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let u of runner"
                    class="d-flex">
                    <td class="col-sm-2">
                        {{ u.itemno }}
                    </td>
                    <td class="col-sm-4">
                        {{ u.name }}
                    </td>
                    <td class="col-sm-2 text-center">
                        {{ u.amount }}
                    </td>
                    <td class="col-sm-2 text-center">
                        {{ u.pricePer | number: '1.2-2'}} {{ 'USED_CURRENCY' | translate }}
                    </td>
                    <td class="col-sm-2 text-end">
                        {{ u.priceTotal | number: '1.2-2'}} {{ 'USED_CURRENCY' | translate }}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

    </div>
    <!-- Others row -->
    <div *ngIf="others.length > 0" class="row">

        <div class="col-sm">
            <h5>{{ 'CART.OTHER_PRODUCTS' | translate }}</h5>
            <table class="table">
                <thead>
                <tr class="d-flex">
                    <th class="col-sm-2">
                        {{ 'UTILITY.ITEM_NO' | translate }}
                        <div [title]="'Order.externalOrder.OrderExternally' | translate" class="float-end">
                            <fa-icon [icon]="faShoppingCart"></fa-icon>
                        </div>
                    </th>
                    <th class="col-sm-4">{{ 'UTILITY.DESC' | translate }}</th>
                    <th class="col-sm-2 text-center">{{ 'UTILITY.AMOUNT' | translate }}</th>
                    <th class="col-sm-2 text-center">{{ 'PRICING.UNIT_PRICE' | translate }}
                        ({{ 'PRICING.EX_VAT' | translate}})
                    </th>
                    <th class="col-sm-2 text-end">{{ 'PRICING.PRICE_TOTAL' | translate }}
                        ({{ 'PRICING.EX_VAT' | translate}})
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let o of others" class="d-flex">
                    <td class="col-sm-2">
                        {{ o.itemno }}
                        <!-- Order externally, begin -->
                        <div *ngIf="o.externalShopName && !o.externalShopSuccess && !o.externalShopError && !orderManually.includes(o.subType)"
                             [title]="'Kan bestilles hos ' + o.externalShopName" class="float-end">
                            <fa-icon [icon]="faShoppingCart"></fa-icon>
                        </div>
                        <div *ngIf="o.externalShopError && !orderManually.includes(o.subType)"
                             [title]="'Problem ved bestilling hos ' + o.externalShopName + ': ' + o.externalShopError" class="float-end">
                            <fa-icon [icon]="faShoppingCart" class="text-warning"></fa-icon>
                        </div>
                        <div *ngIf="o.externalShopSuccess && !orderManually.includes(o.subType)" [title]="'Bestilt hos ' + o.externalShopName"
                             class="float-end">
                            <fa-icon [icon]="faShoppingCart" class="text-success"></fa-icon>
                        </div>
                        <div *ngIf="o.externalShopName && orderManually.includes(o.subType)"
                             [title]="'Skal bestilles manuelt hos ' + o.externalShopName"
                             class="float-end">
                            <fa-icon [icon]="faShoppingCart" class="text-warning"></fa-icon>
                        </div>
                        <!-- Order externally, end -->
                    </td>
                    <td class="col-sm-4">
                        {{ o.name }}<br/>
                        <div *ngIf="o.comments">
                            <small>
                                <fa-icon [icon]="faComment"></fa-icon>
                                {{ o.comments }}
                            </small>
                        </div>
                        <!-- Ordered externally message, begin -->
                        <small *ngIf="o.externalShopError && !orderManually.includes(o.subType)">
                            <fa-icon [icon]="faExclamationTriangle" class="text-warning"></fa-icon>
                            <span class="text-warning">{{o.externalShopError}}</span>&nbsp;
                        </small>
                        <small *ngIf="o.externalShopSuccess && !orderManually.includes(o.subType)">
                <span class="text-success">{{o.externalShopName}} ordre:
                  <ng-container *ngFor="let ext of order.externalShopOrders">
                    <span *ngIf="ext.shopName === o.externalShopName">
                      {{ext.orderId}}
                    </span>
                  </ng-container>
                </span>&nbsp;
                        </small>
                        <small *ngIf="o.externalShopName && orderManually.includes(o.subType)">
                            <span class="text-warning">{{ 'order_externally_manually' | translate }}</span>&nbsp;
                        </small>
                        <!-- Ordered externally message, end -->
                        <small>{{ o.description }}</small>
                    </td>
                    <td class="col-sm-2 text-center">
                        {{ o.amount }}
                    </td>
                    <td class="col-sm-2 text-center">
                        {{ o.pricePer | number: '1.2-2'}} {{ 'USED_CURRENCY' | translate }}
                    </td>
                    <td class="col-sm-2 text-end">
                        {{ o.priceTotal | number: '1.2-2'}} {{ 'USED_CURRENCY' | translate }}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

    </div>
    <!-- Purewood Fronts row -->
    <div *ngIf="fronts.length > 0" class="row">

        <div class="col-sm">
            <h5>{{ 'Purewood.Fronts.Title' | translate }}</h5>
            <table class="table">
                <thead>
                <tr class="d-flex">
                    <th class="col-sm-2">{{ 'UTILITY.ITEM_NO' | translate }}</th>
                    <th class="col-sm-4">{{ 'UTILITY.DESC' | translate }}</th>
                    <th class="col-sm-2 text-center">{{ 'UTILITY.AMOUNT' | translate }}</th>
                    <th class="col-sm-2 text-center">{{ 'PRICING.UNIT_PRICE' | translate }}
                        ({{ 'PRICING.EX_VAT' | translate}})
                    </th>
                    <th class="col-sm-2 text-end">{{ 'PRICING.PRICE_TOTAL' | translate }}
                        ({{ 'PRICING.EX_VAT' | translate}})
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let o of fronts"
                    class="d-flex">
                    <td class="col-sm-2">
                        {{ o.itemno }}
                    </td>
                    <td class="col-sm-4">
                        {{ o.name }}
                        <div [innerText]="o.description"></div>
                        <div *ngIf="o.options.fileId">
                            <a (click)="getFrontDesign(o.options.fileId, o.itemno); $event.preventDefault();" href="#">
                                <fa-icon [icon]="faFilePdf"></fa-icon>
                                Design fil
                            </a>
                        </div>
                    </td>
                    <td class="col-sm-2 text-center">
                        {{ o.amount }}
                    </td>
                    <ng-container *ngIf="o.priceTotal">
                        <td class="col-sm-2 text-center">
                            {{ o.pricePer | number: '1.2-2'}} {{ 'USED_CURRENCY' | translate }}
                        </td>
                        <td class="col-sm-2 text-end">
                            {{ o.priceTotal | number: '1.2-2'}} {{ 'USED_CURRENCY' | translate }}
                        </td>
                    </ng-container>
                    <td *ngIf="!o.priceTotal" class="col-sm-4 text-center">
                        * <i>{{ 'PRICE_WILL_FOLLOW' | translate }}</i> *
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

    </div>
    <!-- CabinetCarcass row -->
    <div *ngIf="carcass.length > 0" class="row">

        <div class="col-sm">
            <h5>{{ 'CABINET_CARCASS.BOARD' | translate }}</h5>
            <table class="table">
                <thead>
                <tr class="d-flex">
                    <th class="col-sm-2">
                        {{ 'UTILITY.ITEM_NO' | translate }}
                        <div [title]="'Order.externalOrder.OrderExternally' | translate" class="float-end">
                            <fa-icon [icon]="faShoppingCart"></fa-icon>
                        </div>
                    </th>
                    <th class="col-sm-4">{{ 'UTILITY.DESC' | translate }}</th>
                    <th class="col-sm-2 text-center">{{ 'UTILITY.AMOUNT' | translate }}</th>
                    <th class="col-sm-4 text-center">{{ 'UTILITY.PRICE' | translate }}</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let o of carcass" class="d-flex">
                    <td class="col-sm-2">
                        {{ o.itemno }}
                        <!-- Order externally, begin -->
                        <div *ngIf="o.externalShopName && !o.externalShopSuccess && !o.externalShopError && !orderManually.includes(o.subType)"
                             [title]="'Kan bestilles hos ' + o.externalShopName" class="float-end">
                            <fa-icon [icon]="faShoppingCart"></fa-icon>
                        </div>
                        <div *ngIf="o.externalShopError && !orderManually.includes(o.subType)"
                             [title]="'Problem ved bestilling hos ' + o.externalShopName + ': ' + o.externalShopError" class="float-end">
                            <fa-icon [icon]="faShoppingCart" class="text-warning"></fa-icon>
                        </div>
                        <div *ngIf="o.externalShopSuccess && !orderManually.includes(o.subType)" [title]="'Bestilt hos ' + o.externalShopName"
                             class="float-end">
                            <fa-icon [icon]="faShoppingCart" class="text-success"></fa-icon>
                        </div>
                        <div *ngIf="o.externalShopName && orderManually.includes(o.subType)"
                             [title]="'Skal bestilles manuelt hos ' + o.externalShopName"
                             class="float-end">
                            <fa-icon [icon]="faShoppingCart" class="text-warning"></fa-icon>
                        </div>
                        <!-- Order externally, end -->
                    </td>
                    <td class="col-sm-4">
                        <div>{{ o.name }} {{ o.description }}</div>
                        <div *ngIf="o.options.comment">
                            <fa-icon [icon]="faComment"></fa-icon>&nbsp;<i>"{{ o.options.comment }}"</i>
                        </div>
                        <!-- Ordered externally message, begin -->
                        <small *ngIf="o.externalShopError && !orderManually.includes(o.subType)">
                            <fa-icon [icon]="faExclamationTriangle" class="text-warning"></fa-icon>
                            <span class="text-warning">{{o.externalShopError}}</span>&nbsp;
                        </small>
                        <small *ngIf="o.externalShopSuccess && !orderManually.includes(o.subType)">
                <span class="text-success">{{o.externalShopName}} ordre:
                  <ng-container *ngFor="let ext of order.externalShopOrders">
                    <span *ngIf="ext.shopName === o.externalShopName">
                      {{ext.orderId}}
                    </span>
                  </ng-container>
                </span>&nbsp;
                        </small>
                        <small *ngIf="o.externalShopName && orderManually.includes(o.subType)">
                            <span class="text-warning">{{ 'order_externally_manually' | translate }}</span>&nbsp;
                        </small>
                        <!-- Ordered externally message, end -->
                    </td>
                    <td class="col-sm-2 text-center">
                        {{ o.amount }}
                    </td>
                    <td class="col-sm-4 text-center">
                        * <i>{{ 'PRICE_WILL_FOLLOW' | translate }}</i> *
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

    </div>
    <div *ngIf="additionals.length > 0" class="row">

        <div class="col-sm">
            <h5>{{ 'CART.ADDITONAL_PRODUCTS' | translate }}</h5>
            <table class="table">
                <thead>
                <tr class="d-flex">
                    <th class="col-sm-2">{{ 'UTILITY.ITEM_NO' | translate }}</th>
                    <th class="col-sm-4">{{ 'UTILITY.DESC' | translate }}</th>
                    <th class="col-sm-2 text-center">{{ 'UTILITY.AMOUNT' | translate }}</th>
                    <th class="col-sm-2 text-center">{{ 'PRICING.UNIT_PRICE' | translate }}
                        ({{ 'PRICING.EX_VAT' | translate}})
                    </th>
                    <th class="col-sm-2 text-end">{{ 'PRICING.PRICE_TOTAL' | translate }}
                        ({{ 'PRICING.EX_VAT' | translate}})
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let a of additionals" class="d-flex">
                    <td class="col-sm-2">
                        {{ a.itemno }}
                    </td>
                    <td class="col-sm-4">
                        {{ a.name }}
                        <div *ngIf="a.breakageFeeIncluded" class="small fst-italic">
                            Der tillægges anbrudsgebyr brud på en pakke ({{ a.options.breakageFeeCount }} stk/pakke).
                        </div>
                    </td>
                    <td class="col-sm-2 text-center">
                        {{ a.amount }}
                    </td>
                    <td class="col-sm-2 text-center">
                        {{ a.pricePer | number: '1.2-2'}} {{ 'USED_CURRENCY' | translate }}
                    </td>
                    <td class="col-sm-2 text-end">
                        {{ a.priceTotal | number: '1.2-2'}} {{ 'USED_CURRENCY' | translate }}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

    </div>
    <div class="row">

        <div class="col-sm">
            <h5>Ordretotaler</h5>
            <table class="table table-sm">
                <tbody>
                <tr>
                    <td class="text-end">Fragt</td>
                    <td class="text-end">{{ order.shipping | number: '1.2-2'}} {{ 'USED_CURRENCY' | translate }}</td>
                </tr>
                <ng-container *ngIf="order.fees">
                    <ng-container *ngFor="let fee of order.fees">
                        <tr *ngIf="fee[feeTypes.oneDelivery]">
                            <td class="text-end">{{ 'CART.OneDelivery' | translate }}</td>
                            <td class="text-end">{{ fee[feeTypes.oneDelivery] | number: '1.2-2'}} {{ 'USED_CURRENCY' | translate }}</td>
                        </tr>
                        <tr *ngIf="fee[feeTypes.hettichMinOrder]">
                            <td class="text-end">{{ 'CART.Min_Hettich_addon' | translate }}</td>
                            <td class="text-end">{{ fee[feeTypes.hettichMinOrder] | number: '1.2-2'}} {{ 'USED_CURRENCY' | translate }}</td>
                        </tr>
                        <tr *ngIf="fee[feeTypes.purewoodColour]">
                            <td class="text-end">{{ 'Purewood.ColourStartup.Fee' | translate }} {{ fee.variant }}</td>
                            <td class="text-end">{{ fee[feeTypes.purewoodColour] | number: '1.2-2'}} {{ 'USED_CURRENCY' | translate }}</td>
                        </tr>
                    </ng-container>
                </ng-container>
                <tr *ngIf="order.customs">
                    <td class="text-end" translate="CART.Told"></td>
                    <td class="text-end">{{ order.customs | number: '1.2-2' }} {{ 'USED_CURRENCY' | translate }}</td>
                </tr>
                <tr *ngIf="additionalsBreakageFees && additionalsBreakageFees.count">
                    <td class="text-end" style="border: none;" translate="CART.breakageFee"></td>
                    <td class="text-end" style="border: none;">
                        {{ additionalsBreakageFees.count * additionalsBreakageFees.price | number: '1.2-2' }}
                        {{ 'USED_CURRENCY' | translate }}</td>
                </tr>
                <tr *ngIf="company && company.vat">
                    <td class="text-end">{{ 'ORDERS.TOTAL_EX_VAT' | translate }}</td>
                    <td class="text-end" style="width: 10%;">{{ order.exVat | number: '1.2-2'}} {{ 'USED_CURRENCY' | translate }}</td>
                </tr>
                <tr *ngIf="company && !company.vat">
                    <td class="text-end"><b>{{ 'ORDERS.TOTAL_EX_VAT' | translate }}</b></td>
                    <td class="text-end" style="width: 10%;"><b>{{ order.exVat | number: '1.2-2'}}</b> {{ 'USED_CURRENCY' | translate }}</td>
                </tr>
                <tr *ngIf="company && company.vat">
                    <td class="text-end"><b>{{ 'ORDERS.TOTAL_INCL_VAT' | translate }}</b></td>
                    <td class="text-end"><b>{{ order.exVat * 1.25 | number: '1.2-2'}} {{ 'USED_CURRENCY' | translate }}</b>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

    </div>
</div>

<ng-template #contactCustomer>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">Kontakt Kunde</h5>
            <button (click)="modalRef.hide()" aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
        </div>
        <div class="modal-body">
            <div class="row mb-3">
                <div class="col-sm">
                    <label class="form-label">Email</label>
                    <select [(ngModel)]="customerMail.email" class="form-control">
                        <option *ngFor="let email of orderEmails" value="{{ email }}">{{ email }}</option>
                    </select>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm">
                    <label class="form-label">Emne</label>
                    <input [(ngModel)]="customerMail.subject" class="form-control" type="text">
                </div>
            </div>
            <div class="row">
                <div class="col-sm">
                    <label class="form-label">Besked</label>
                    <textarea [(ngModel)]="customerMail.message" class="form-control"></textarea>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button (click)="sendCustomerMail()" class="btn btn-primary" type="button">Send</button>
            <button (click)="modalRef.hide()" class="btn btn-secondary" data-dismiss="modal" type="button">Annullér</button>
        </div>
    </div>
</ng-template>

<ng-template #confirmUpdateStatus>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">Opdater Status</h5>
            <button (click)="modalRef.hide()" aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
        </div>
        <div class="modal-body">
            <div class="mb-3">
                <label class="form-label">Ny status:</label>
                <select [(ngModel)]="newStatusID" class="form-control">
                    <option *ngFor="let status of orderStatuses" value="{{ status.id }}">{{ status.statusText }}</option>
                </select>
            </div>
            <div class="form-check">
                <input [(ngModel)]="notifyCustomer" class="form-check-input" id="notifyCheck" type="checkbox">
                <label class="form-check-label" for="notifyCheck">Send email til kunden med opdatering</label>
            </div>
            <div class="form-check">
                <input [(ngModel)]="changeDeliveryDate" class="form-check-input" id="deliveryCheck" type="checkbox">
                <label class="form-check-label" for="deliveryCheck">Ret leveringsdato</label>
            </div>
            <br>
            <div *ngIf="changeDeliveryDate">
                <label class="form-label" for="lev_dato">Ny leveringsdato</label>
                <input [(ngModel)]="bsDeliveryDate" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" [bsValue]="levDate" bsDatepicker
                       class="form-control" id="lev_dato" name="lev_dato" placeholder="Vælg ønsket leveringsdato" type="text">
            </div>
        </div>
        <div class="modal-footer">
            <button (click)="updateOrderStatus()" class="btn btn-primary" type="button">Gem</button>
            <button (click)="modalRef.hide()" class="btn btn-secondary" data-dismiss="modal" type="button">Annullér</button>
        </div>
    </div>
</ng-template>

<ng-template #confirmDelete>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">Slet ordre</h5>
            <button (click)="modalRef.hide()" aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
        </div>
        <div class="modal-body">
            <p>Er du sikker på at du vil slette ordren?</p>
        </div>
        <div class="modal-footer">
            <button (click)="deleteOrder()" class="btn btn-danger" type="button">Slet</button>
            <button (click)="modalRef.hide()" class="btn btn-secondary" data-dismiss="modal" type="button">Annullér</button>
        </div>
    </div>
</ng-template>

<ng-template #confirmRecall>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">{{ 'Order.Recall_Order' | translate }}</h5>
            <button (click)="modalRef.hide()" aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
        </div>
        <div class="modal-body">
            <p>{{ 'Order.Confirm.Recall' | translate }}</p>
            <p>{{ 'Order.Overwrite.Warning' | translate }}</p>
        </div>
        <div class="modal-footer">
            <button (click)="recallOrder()" class="btn btn-warning" type="button">{{'Order.Recall_Btn' | translate}}</button>
            <button (click)="modalRef.hide()" class="btn btn-secondary" data-dismiss="modal" type="button">{{ 'UTILITY.CANCEL' | translate }}</button>
        </div>
    </div>
</ng-template>

<ng-template #confirmEdit>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">{{ 'Order.Edit_Order' | translate }}</h5>
            <button (click)="modalRef.hide()" aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
        </div>
        <div class="modal-body">
            <p>{{ 'Order.Confirm.Edit' | translate }}</p>
            <p>{{ 'Order.Overwrite.Warning' | translate }}</p>
        </div>
        <div class="modal-footer">
            <button (click)="editOrder()" class="btn btn-warning" type="button">{{'Order.Edit_Btn' | translate}}</button>
            <button (click)="modalRef.hide()" class="btn btn-secondary" data-dismiss="modal" type="button">{{ 'UTILITY.CANCEL' | translate }}</button>
        </div>
    </div>
</ng-template>

<ng-template #confirmRestore>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">{{'Order.Restore_Order' | translate}}</h5>
            <button (click)="modalRef.hide()" aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
        </div>
        <div class="modal-body">
            <p>{{'Order.Restore.Confirm' | translate }} {{ order.user.name }}.</p>
            <div>
                <label class="form-label">{{'Order.Restore.Projectname' | translate }}:</label>
                <input #projectName class="form-control" required type="text">
            </div>
        </div>
        <div class="modal-footer">
            <button (click)="restoreOrder(projectName.value)" class="btn btn-warning" type="button">{{'Order.Restore_Btn' | translate}}</button>
            <button (click)="modalRef.hide()" class="btn btn-secondary" data-dismiss="modal" type="button">{{ 'UTILITY.CANCEL' | translate }}</button>
        </div>
    </div>
</ng-template>

<ng-template #udsparingModal>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">Udsparing til skuffe {{ tmpUdsparingDrawerID }}</h5>
            <button (click)="modalRef.hide()" aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
        </div>
        <div class="modal-body">
            <img [src]="udsparingImagePlaceholder" alt="udsparing" style="width: 100%;">
        </div>
        <div class="modal-footer">
            <!-- <button type="button" class="btn btn-success" (click)="downloadUdspImage()">Gem</button> -->
            <button (click)="modalRef.hide()" class="btn btn-secondary" data-dismiss="modal" type="button">Luk</button>
        </div>
    </div>
</ng-template>

<ng-template #confirmTransfer>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">Overfør ordre til e-conomic</h5>
            <button (click)="modalRef.hide()" aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
        </div>
        <div class="modal-body">
            <p>Er du sikker på, at du vil overføre ordren til e-conomic?</p>
        </div>
        <div class="modal-footer">
            <button (click)="transferOrder()" class="btn btn-primary" type="button">Overfør ordre</button>
            <button (click)="modalRef.hide()" class="btn btn-secondary" data-dismiss="modal" type="button">Annullér</button>
        </div>
    </div>
</ng-template>

<ng-template #confirmExternalOrder>
    <div *ngIf="orderAt" class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">{{ 'Order.externalOrder.OrderAt' + orderAt.shopName | translate }}</h5>
            <button (click)="modalRef.hide()" aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
        </div>
        <div class="modal-body">
            <p *ngIf="orderAt.attempts > 0">{{ 'Order.externalOrder.Attempts' | translate }}: {{ orderAt.attempts }}</p>
            <p *ngIf="orderAt.orderId">{{ 'Order.externalOrder.AlreadyOrdered' | translate }}: {{ orderAt.orderedAt | date }}</p>
            <div *ngIf="!orderAt.orderId">
                <div>{{ 'Order.externalOrder.ConfirmOrdering' | translate }}</div>
                <div>
                    <fa-icon [icon]="faExclamationTriangle" class="text-warning"></fa-icon>
                    Bemærk at ordren efterfølgende ikke kan redigeres.
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button (click)="createExternalOrder()" [disabled]="orderAt.orderId || orderingExternally" class="btn btn-primary" type="button">
                {{ 'Order.externalOrder.OrderAt' + orderAt.shopName | translate }}
            </button>
            <button (click)="modalRef.hide()" class="btn btn-secondary" data-dismiss="modal" type="button">{{ 'Annullér' | translate }}</button>
        </div>
    </div>
</ng-template>
