/**
 * Inspired by https://www.w3schools.com/lib/w3color.js
 */
import { ParsedPaletteColour } from '../../../../wdcommon/Colours';


export function parseNcs(ncsString: string): ParsedPaletteColour {
  const ncsFixed = ncsString
      .toUpperCase()
      .replace('NCS', '') // remove NCS text
      .replace('S', '') // remove S text
      .replace(/[()-]/g, '')  // remove chars
      .replace(/^\s+|\s+$/g, '') // trim
      .replace(/\s{2,}/g, ' '); // no double spaces
  const matches = ncsFixed.match(/^(\d{2})(\d{2})[-\s]?([BGNRY])(\d{2})?([BGRY])?$/);
  if (matches === null) {
    // console.log('Not parsed', ncsString, ncsFixed, matches);
    return {
      NCS: ncsString,
      HEX: 'transparent',
      Name: null,
      success: false
    };
  }
  const [_, s, c, hueColour, hp, withColour] = matches;
  if (s === null || c === null || hueColour === null) {
    // console.log('Not valid', ncsString, ncsFixed, s, c, hueColour);
    return {
      NCS: ncsString,
      HEX: 'transparent',
      Name: null,
      success: false
    };
  }

  // Sanity check
  if (
      (hueColour === 'Y' && withColour !== undefined && withColour !== 'R') ||
      (hueColour === 'R' && withColour !== undefined && withColour !== 'B') ||
      (hueColour === 'B' && withColour !== undefined && withColour !== 'G') ||
      (hueColour === 'G' && withColour !== undefined && withColour !== 'Y')
  ) {
    // console.log('Not sane', ncsString, ncsFixed, hueColour, withColour);
    return {
      NCS: ncsString,
      HEX: 'transparent',
      Name: '',
      success: false
    };
  }

  const blackness = parseInt(s, 10);
  const chromaticness = parseInt(c, 10);
  const withPercentage = parseInt(hp, 10) || 0;

  if (blackness + chromaticness > 100) {
    // console.log('Invalid blackness and chromaticness', ncsString, blackness, chromaticness);
    return {
      NCS: ncsString,
      HEX: 'transparent',
      Name: '',
      success: false
    };
  }

  if (hueColour === 'N') {
    const gray = (1 - blackness / 100) * 255;
    const ncsNoHueName = 'NCS S ' + s + c + '-N';
    // console.log('No hue', ncsNoHueName);
    return {
      NCS: ncsString,
      HEX: toHexString(gray, gray, gray),
      Name: ncsNoHueName,
      success: true
    };
  }

  if (withPercentage > 0 && withColour === undefined) {
    // console.log('No with-colour', withPercentage);
    return {
      NCS: ncsString,
      HEX: 'transparent',
      Name: '',
      success: false
    };
  }

  let red1: number;
  let blue1: number;
  let green1: number;

  if (hueColour === 'Y' && withPercentage <= 60) {
    red1 = 1;
  } else if ((hueColour === 'Y' && withPercentage > 60) || (hueColour === 'R' && withPercentage <= 80)) {
    const factor1 = withPercentage + (hueColour === 'Y' ? -60 : 40);
    red1 = ((Math.sqrt(14884 - Math.pow(factor1, 2))) - 22) / 100;
  } else if ((hueColour === 'R' && withPercentage > 80) || (hueColour === 'B')) {
    red1 = 0;
  } else if (hueColour === 'G') {
    red1 = ((Math.sqrt(33800 - Math.pow((withPercentage - 170), 2))) - 70) / 100;
  }

  if (hueColour === 'Y') {
    green1 = (85 - 17 / 20 * withPercentage) / 100;
  } else if (hueColour === 'R' && withPercentage <= 60) {
    green1 = 0;
  } else if (hueColour === 'R' && withPercentage > 60) {
    green1 = (67.5 - (Math.sqrt(5776 - Math.pow(withPercentage - 25, 2)))) / 100;
  } else if (hueColour === 'B' && withPercentage <= 60) {
    green1 = (6.5 + (Math.sqrt(7044.5 - Math.pow(withPercentage - 68.5, 2)))) / 100;
  } else if ((hueColour === 'B' && withPercentage > 60) || (hueColour === 'G' && withPercentage <= 60)) {
    green1 = 0.9;
  } else if (hueColour === 'G' && withPercentage > 60) {
    green1 = (90 - (1 / 8 * (withPercentage - 60))) / 100;
  }

  if (hueColour === 'Y' && withPercentage <= 80) {
    blue1 = 0;
  } else if ((hueColour === 'Y' && withPercentage > 80) || (hueColour === 'R' && withPercentage <= 60)) {
    const factor1 = withPercentage + (hueColour === 'Y' ? -80 : 20) + 20.5;
    blue1 = (104 - (Math.sqrt(11236 - Math.pow(factor1, 2)))) / 100;
  } else if ((hueColour === 'R' && withPercentage > 60) || (hueColour === 'B' && withPercentage <= 80)) {
    const factor1 = withPercentage - (hueColour === 'R' ? 120 : 20);
    blue1 = ((Math.sqrt(10000 - Math.pow(factor1, 2))) - 10) / 100;
  } else if ((hueColour === 'B' && withPercentage > 80) || (hueColour === 'G' && withPercentage <= 40)) {
    const factor1 = withPercentage + (hueColour === 'B' ? -80 : 20) - 131;
    blue1 = (122 - (Math.sqrt(19881 - Math.pow(factor1, 2)))) / 100;
  } else if (hueColour === 'G' && withPercentage > 40) {
    blue1 = 0;
  }

  const avg = (red1 + green1 + blue1) / 3;
  const red2 = ((avg - red1) * (100 - chromaticness) / 100) + red1;
  const green2 = ((avg - green1) * (100 - chromaticness) / 100) + green1;
  const blue2 = ((avg - blue1) * (100 - chromaticness) / 100) + blue1;
  const maxFactor = 255 / (Math.max(red2, green2, blue2)) * (100 - (1.05 * blackness - 5.25)) / 100;

  const ncsName = 'NCS S ' + s + c + '-' + hueColour + (withColour ? hp + withColour : '');
  // console.log('With hue', ncsName);
  return {
    NCS: ncsString,
    HEX: toHexString(
        red2 * maxFactor,
        green2 * maxFactor,
        blue2 * maxFactor
    ),
    Name: ncsName,
    success: true
  };
}

function toHexString(red: number, green: number, blue: number) {
  return '#' + toHex(red) + toHex(green) + toHex(blue);
}

function toHex(n: number) {
  return Math.round(Math.max(0, Math.min(255, n))).toString(16).padStart(2, '0');
}

export function getContrastYIQ(hexColour: string) {
  // https://stackoverflow.com/a/11868398
  if (hexColour.length !== 7) {
    return 'black';
  }
  hexColour = hexColour.replace('#', '');
  const r = parseInt(hexColour.substring(0, 2), 16);
  const g = parseInt(hexColour.substring(2, 4), 16);
  const b = parseInt(hexColour.substring(4, 6), 16);
  const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  return (yiq >= 128) ? 'black' : 'white';
}
