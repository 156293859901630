import { TranslateService } from '@ngx-translate/core';

import { APIService, DrawerOptionsService, HettichService, NamingService, PriceCalculatorService } from '../';
import { CartItem } from './CartItem';
import { CartItemType, CartSubType, IAddCart, IShimsOptions, ITypedAddCartItem, ITypedDrawerAddCartItem } from '../../../../../wdcommon/ICartItem';
import { ICompanyAdjustments } from '../../../../../wdcommon/ICompany';
import { IDrawerOptions, ShimsOptions, ShimsOptionValue } from '../../../../../wdcommon/IDrawer';
import { ExternalShopName } from '../../../../../wdcommon/IExternalShop';
import { PriceType } from '../../../../../wdcommon/IPrices';
import { OptionProperty, Manufacturer } from '../../../../../wdcommon/IProduct';


export class DrawerCartItem extends CartItem {
  public readonly options: IDrawerOptions = {} as IDrawerOptions;

  private _priceDetails: { [options: string]: number; total: number; };
  private _orderDetails: { name: string, detail: string, price: number; }[];

  constructor(
      private api: APIService,
      private companyAdjustmentsProvider: () => Promise<ICompanyAdjustments>,
      private drawer: ITypedDrawerAddCartItem,
      private namingService: NamingService,
      private optionsService: DrawerOptionsService,
      private priceService: PriceCalculatorService,
      private translateService: TranslateService,
      private hettichService: HettichService,
  ) {
    super(translateService, drawer);

    this.options = drawer.options;
  }

  public get priceDetails() {
    return this._priceDetails || { total: 0 };
  }

  public get orderDetails() {
    return this._orderDetails || [];
  }

  public getBrandId() {
    if (this.options.type.indexOf(Manufacturer.ernstMair) > -1) {
      return Manufacturer.ernstMair;
    } else if (this.options.type.indexOf(Manufacturer.purewood) > -1) {
      return Manufacturer.purewood;
    }
    return Manufacturer.nothegger;
  }

  public getSynchronisationBarWidth() {
    if (this.options[OptionProperty.synchronisationBar]) {
      return Number(this.options.skuffeBredde) * this.amount;
    }
    return 0;
  }

  public needsSynchronisationBarAdapters() {
    return (
        typeof this.options[OptionProperty.runnerMark] === 'string' &&
        this.options[OptionProperty.runnerMark].indexOf('4D') > -1 &&
        this.options[OptionProperty.synchronisationBar] === true &&
        this.options[OptionProperty.runnerPushToOpenSilent] === false
    );
  }

  public async generateItems(): Promise<IAddCart> {
    const generatedItems: IAddCart = [];

    const options = this.options;

    await this.generateShimsItem(options, generatedItems);
    await this.generateRunnerItem(options, generatedItems);
    await this.generateCoupling(options, generatedItems);
    await this.generateRunnerDepthAdjustmentItem(options, generatedItems);
    await this.generatePushToOpenSilentItem(options, generatedItems);

    return generatedItems;
  }

  protected async calculatePrice(): Promise<number> {
    const price: PriceType = await this.priceService.Calculate(this.options, await this.companyAdjustmentsProvider());

    if (price !== null && typeof price === 'object' && typeof price.total === 'number') {
      this._priceDetails = price;
      return price.total;
    }

    console.error(`Unable to calculate price for drawer ${this.options} got: ${price}.`);
    return 0;
  }

  protected async updateDetails(): Promise<void> {
    this._orderDetails = this.getOrderDetails();
  }

  private getOrderDetails() {
    const drawer = this;
    const priceDetails = drawer.priceDetails;

    if (!priceDetails) {
      console.log('No price details for', this);
      return;
    }
    const details = [] as { name: string, detail: string, price: number; }[];

    Object.keys(priceDetails).forEach((key) => {
      if (key === OptionProperty.shimsSelection || key === 'frontBredde' || key === OptionProperty.runnerDepth) {
        return;
      }

      const value = drawer.options[key];

      const pretty = this.namingService.getPrettyNameSync(key, value);

      if (pretty) {
        details.push({
          name: pretty.key,
          detail: pretty.value,
          price: priceDetails[key]
        });
      }
    });

    return details;
  }

  private async generateShimsItem(options: IDrawerOptions, generatedItems: IAddCart) {
    if (!options[OptionProperty.shimsSelection] || options[OptionProperty.shimsSelection] === ShimsOptionValue.nej) {
      return;
    }

    const shortType = 'WD2AL-' + options[OptionProperty.shimsSelection]
        + (options[OptionProperty.typeOfWood] as string).toUpperCase()
        + options[OptionProperty.surfaceTreatment].toUpperCase();

    const shortTypeEncoded = btoa(shortType);
    const itemNO: any = await this.api.getVarenr(shortTypeEncoded);
    const shimsNameFormatted = options[OptionProperty.shimsSelection].replace('x2', '').trim() + ' mm';

    const shimsOptions: IShimsOptions = {
      [OptionProperty.typeOfWood]: options[OptionProperty.typeOfWood] as string,
      [OptionProperty.surfaceTreatment]: options[OptionProperty.surfaceTreatment],
      [OptionProperty.shimsSelection]: options[OptionProperty.shimsSelection]
    };

    const amount = ShimsOptions.find((shimsOption) => shimsOption.value === options[OptionProperty.shimsSelection]).amount;

    const shimsObj: ITypedAddCartItem = {
      brandId: this.getBrandId(),
      type: CartItemType.extra,
      subType: CartSubType.afstandsliste,
      name: this.translate('CARTDETAILS.afstandsListe') + ' ' + shimsNameFormatted,
      description: this.translate('TREESORTS.' + options[OptionProperty.typeOfWood]) + ', ' + this.translate('SURFACES.' + options[OptionProperty.surfaceTreatment]) + ', ' + shimsNameFormatted,
      itemno: itemNO.varenr,
      amount: amount * this.amount,
      amountStep: amount,
      options: shimsOptions,
      externalShopName: ExternalShopName.nothegger
    };

    generatedItems.push(shimsObj);
  }

  private async generateRunnerItem(options: IDrawerOptions, generatedItems: IAddCart) {
    if (options.udtraekLeveret) {
      const runnerProductNumber = await this.optionsService.getRunnerProductNumber(
          options[OptionProperty.runnerMark],
          options[OptionProperty.runnerDepth],
          options[OptionProperty.runnerLoad],
          options[OptionProperty.runnerType]
      );
      if (!runnerProductNumber) {
        console.error('Unable to generate runner', options);
        return;
      }

      let runnerName = this.translate(`UDTRAEK.${options[OptionProperty.runnerMark]}`);
      const runnerDescription: string[] = [];

      if (options[OptionProperty.runnerType]) {
        runnerDescription.push(this.translate(options[OptionProperty.runnerType]));
      }
      if (options[OptionProperty.runnerDepth]) {
        runnerDescription.push(options[OptionProperty.runnerDepth] + ' mm');
      }
      if (options[OptionProperty.runnerLoad]) {
        runnerDescription.push(options[OptionProperty.runnerLoad] + ' kg');
      }
      if (runnerDescription) {
        runnerName += ' - ' + runnerDescription.join(', ');
      }

      const hettichBrandId = await this.hettichService.getHettichBrandId();

      const runnerItem: ITypedAddCartItem = {
        brandId: hettichBrandId,
        description: runnerDescription.join(', '),
        type: CartItemType.runner,
        name: runnerName,
        itemno: runnerProductNumber,
        amount: this.amount,
      };

      generatedItems.push(runnerItem);
    }
  }

  private async generateRunnerDepthAdjustmentItem(options: IDrawerOptions, generatedItems: IAddCart) {
    if (options.udtraekDybdeJustering) {
      const hettichBrandId = await this.hettichService.getHettichBrandId();
      const runnerDepthAdjustmentItem: ITypedAddCartItem = {
        brandId: hettichBrandId,
        type: CartItemType.other,
        itemno: '9257706',
        name: this.translate('CARTDETAILS.udtraekDybdeJustering'),
        description: this.translate('tilEgennavn', { egennavn: 'Actro 5D' }),
        amount: this.amount
      };
      generatedItems.push(runnerDepthAdjustmentItem);
    }
  }

  private async generatePushToOpenSilentItem(options: IDrawerOptions, generatedItems: IAddCart) {
    if (options[OptionProperty.runnerPushToOpenSilent]) {
      const pushToOpenSilent4DSku = '9246315';
      const pushToOpenSilent5DSku_40 = '9257892';
      const pushToOpenSilent5DSku_70 = '9257893';
      let posSku: string;
      let posDesc = '';

      if (options[OptionProperty.runnerMark].indexOf('4D') > -1) {
        posSku = pushToOpenSilent4DSku;
        posDesc = this.translate('tilEgennavn', { egennavn: 'Hettich Quadro 4D' });
      } else if (options[OptionProperty.runnerMark].indexOf('5D') > -1) {
        if (options[OptionProperty.runnerLoad] <= 40) {
          posSku = pushToOpenSilent5DSku_40;
        } else {
          posSku = pushToOpenSilent5DSku_70;
        }
        posDesc = this.translate('tilEgennavn', { egennavn: 'Hettich Actro 5D' });
      }
      if (posSku) {
        const hettichBrandId = await this.hettichService.getHettichBrandId();

        const pushToOpenSilentItem: ITypedAddCartItem = {
          brandId: hettichBrandId,
          type: CartItemType.other,
          itemno: posSku,
          name: 'Push to Open Silent System',
          description: posDesc,
          amount: this.amount
        };
        generatedItems.push(pushToOpenSilentItem);
      }
    }
  }

  private async generateCoupling(options: IDrawerOptions, generatedItems: IAddCart): Promise<void> {
    if (!options[OptionProperty.couplingAlongside])
      return;

    const CouplingSku = await this.optionsService.getRunnerProductNumber('koblinger_' + options[OptionProperty.runnerMark]);

    let desc;
    if (options[OptionProperty.runnerMark].indexOf('4D') > -1) {
      desc = this.translate('tilEgennavn', { egennavn: 'Hettich Quadro 4D' });
    } else if (options[OptionProperty.runnerMark].indexOf('5D') > -1) {
      desc = this.translate('tilEgennavn', { egennavn: 'Hettich Actro 5D' });
    }

    const hettichBrandId = await this.hettichService.getHettichBrandId();

    const couplingItem: ITypedAddCartItem = {
      brandId: hettichBrandId,
      type: CartItemType.other,
      name: this.translate('CARTDETAILS.kobling'),
      description: desc,
      itemno: CouplingSku,
      amount: this.amount
    };
    generatedItems.push(couplingItem);
  }

}
