<div class="container">
    <div class="row mb-3">

        <div class="col">
            <app-breadcrumbs [currentBreadcrumb]="adminFreightBreadcrumb"></app-breadcrumbs>
        </div>

    </div>
    <div class="row">

        <div class="col-12 mb-3 bg-primary text-white p-3 ps-4 body-type-header">
            {{ 'Træskuffer og bestikindsatser' | uppercase }}
        </div>

    </div>
    <div *ngIf="prices" class="row">

        <div class="col-12 mb-3">
            <div>Fragt for træskuffer og bestikindsater beregnes ud fra antallet af skuffer samt bestikindsatser (Sortissimo og massive).</div>
            <div>En skuffe med en bestikindsats tæller således for to enheder.</div>
            <div>
                Fragten er opsat per land og postnummer, samt en defaultværdi per land, som benyttes, hvis ikke der kan findes et gyldigt postnummer
                på kunden.
            </div>
            <div>
                <strong>OBS:</strong> For bukseudtræk, affaldssystemer, træprofiler samt plejesæt gælder det, at minimumsfragten træder i kraft, hvis
                de er i kurven, men disse tæller ikke med i antal, når fragten beregnes.
            </div>
            <div>
                <small>Disse fragtpriser kan pt. ikke redigeres fra direkte fra administration.</small>
            </div>
        </div>
        <div *ngFor="let country of countries" class="col-4 mb-3 px-4">
            <div class="row bg-primary">
                <div [innerText]="country.name" class="col-12 text-white"></div>
            </div>
            <div class="row bg-secondary">
                <div class="col-12">Standard (overstyres af nedenstående postnumre)</div>
            </div>
            <div class="row">
                <div class="col-12">
                    Minimumsfragt: {{ prices.tillaeg.fragt[country.tier].default['min.fragt'] | number: '1.2-2' }} DKK
                </div>
                <div *ngIf="prices.tillaeg.fragt[country.tier].default.fragtPrice" class="col-12">
                    Pris pr. enhed: {{ prices.tillaeg.fragt[country.tier].default.fragtPrice | number: '1.2-2' }} DKK
                </div>
            </div>
            <div *ngIf="!prices.tillaeg.fragt[country.tier].default.fragtPrice" class="row fw-bold">
                <div class="col-6">Antal enheder</div>
                <div class="col-6 text-end">Pris pr. enhed</div>
            </div>
            <div *ngIf="prices.tillaeg.fragt[country.tier].default[IDrawerPostalInterval.i01to20]" class="row">
                <div class="col-6">1-20</div>
                <div class="col-6 text-end">{{ prices.tillaeg.fragt[country.tier].default[IDrawerPostalInterval.i01to20] | number: '1.2-2' }}DKK
                </div>
            </div>
            <div *ngIf="prices.tillaeg.fragt[country.tier].default[IDrawerPostalInterval.i21to40]" class="row">
                <div class="col-6">21-40</div>
                <div class="col-6 text-end">{{ prices.tillaeg.fragt[country.tier].default[IDrawerPostalInterval.i21to40] | number: '1.2-2' }}DKK
                </div>
            </div>
            <div *ngIf="prices.tillaeg.fragt[country.tier].default[IDrawerPostalInterval.i41to60]" class="row">
                <div class="col-6">41-60</div>
                <div class="col-6 text-end">{{ prices.tillaeg.fragt[country.tier].default[IDrawerPostalInterval.i41to60] | number: '1.2-2' }}DKK
                </div>
            </div>
            <div *ngIf="prices.tillaeg.fragt[country.tier].default[IDrawerPostalInterval.i61to80]" class="row">
                <div class="col-6">61-80</div>
                <div class="col-6 text-end">{{ prices.tillaeg.fragt[country.tier].default[IDrawerPostalInterval.i61to80] | number: '1.2-2' }}DKK
                </div>
            </div>
            <div *ngIf="prices.tillaeg.fragt[country.tier].default[IDrawerPostalInterval.i81to100]" class="row">
                <div class="col-6">81-100</div>
                <div class="col-6 text-end">{{ prices.tillaeg.fragt[country.tier].default[IDrawerPostalInterval.i81to100] | number: '1.2-2' }}DKK
                </div>
            </div>
            <div *ngIf="prices.tillaeg.fragt[country.tier].default[IDrawerPostalInterval.i101to]" class="row">
                <div class="col-6">101-</div>
                <div class="col-6 text-end">{{ prices.tillaeg.fragt[country.tier].default[IDrawerPostalInterval.i101to] | number: '1.2-2' }} DKK</div>
            </div>
            <ng-container *ngFor="let postalFreight of prices.tillaeg.fragt[country.tier].postnr">
                <div class="row bg-secondary mt-3">
                    <div [innerText]="'Postnumre: ' + postalFreight.minPostalCode + ' - ' + postalFreight.maxPostalCode" class="col-12"></div>
                </div>
                <div class="row">
                    <div class="col-12">
                        Minimumsfragt: {{ postalFreight['min.fragt'] | number: '1.2-2' }} DKK
                    </div>
                    <div *ngIf="postalFreight.fragtPrice" class="col-12">
                        Pris pr. enhed: {{ postalFreight.fragtPrice | number: '1.2-2' }} DKK
                    </div>
                </div>
                <div *ngIf="!postalFreight.fragtPrice" class="row fw-bold">
                    <div class="col-6">Antal enheder</div>
                    <div class="col-6 text-end">Pris pr. enhed</div>
                </div>
                <div *ngIf="postalFreight[IDrawerPostalInterval.i01to20]" class="row">
                    <div class="col-6">1-20</div>
                    <div class="col-6 text-end">{{ postalFreight[IDrawerPostalInterval.i01to20] | number: '1.2-2' }} DKK</div>
                </div>
                <div *ngIf="postalFreight[IDrawerPostalInterval.i21to40]" class="row">
                    <div class="col-6">21-40</div>
                    <div class="col-6 text-end">{{ postalFreight[IDrawerPostalInterval.i21to40] | number: '1.2-2' }} DKK</div>
                </div>
                <div *ngIf="postalFreight[IDrawerPostalInterval.i41to60]" class="row">
                    <div class="col-6">41-60</div>
                    <div class="col-6 text-end">{{ postalFreight[IDrawerPostalInterval.i41to60] | number: '1.2-2' }} DKK</div>
                </div>
                <div *ngIf="postalFreight[IDrawerPostalInterval.i61to80]" class="row">
                    <div class="col-6">61-80</div>
                    <div class="col-6 text-end">{{ postalFreight[IDrawerPostalInterval.i61to80] | number: '1.2-2' }} DKK</div>
                </div>
                <div *ngIf="postalFreight[IDrawerPostalInterval.i81to100]" class="row">
                    <div class="col-6">81-100</div>
                    <div class="col-6 text-end">{{ postalFreight[IDrawerPostalInterval.i81to100] | number: '1.2-2' }} DKK</div>
                </div>
                <div *ngIf="postalFreight[IDrawerPostalInterval.i101to]" class="row">
                    <div class="col-6">101-</div>
                    <div class="col-6 text-end">{{ postalFreight[IDrawerPostalInterval.i101to] | number: '1.2-2' }} DKK</div>
                </div>
            </ng-container>
        </div>

    </div>
    <div class="row">

        <div class="col-12 my-3 bg-primary text-white p-3 ps-4 body-type-header">
            {{ 'Purewood fronter' | uppercase }}
        </div>

        <div *ngIf="freightSetting" class="col-12 mb-3">
            <div>Fragt på Purewood fronter falder i en af to udfaldsrum.</div>
            <ol>
                <li><strong>Almindelig fragt:</strong> &lt; {{ freightSetting.purewood.front.palletWeightThreshold }} kg er basis-prisen plus
                    kvadratmeterprisen.
                </li>
                <li><strong>Pallefragt:</strong> &ge; {{ freightSetting.purewood.front.palletWeightThreshold }} kg er prisen per palle. En palle kan
                    ca. bære {{ freightSetting.purewood.front.palletMaxWeight }} kg og det antages, at en Purewood front
                    vejer {{ freightSetting.purewood.front.m2ToWeight }} kg/m<sup>2</sup>, hvilket svarer til at der kan
                    være {{ (freightSetting.purewood.front.palletMaxWeight / freightSetting.purewood.front.m2ToWeight) | number: '1.0-1' }}
                    m<sup>2</sup> på en palle.
                </li>
            </ol>
            <div>
                Særregel for <strong>store fronter</strong>: Hvis en front er større end {{ freightSetting.purewood.front.doublePalletLimit }} mm, så
                beregnes der altid pallefragt og som minimum en dobbeltpalle.
            </div>
            <h5 class="form-label">Opsætning af variabler</h5>
            <div class="row">
                <div class="col-3 mb-3 mt-auto">
                    <label class="form-label">Minimumvægt for pallefragt-beregning</label>
                    <div class="input-group">
                        <input [(ngModel)]="freightSetting.purewood.front.palletWeightThreshold" class="form-control text-end" step="5" type="number">
                        <span class="input-group-text">kg</span>
                    </div>
                </div>
                <div class="col-3 mb-3 mt-auto">
                    <label class="form-label">Kapacitet på en palle</label>
                    <div class="input-group">
                        <input [(ngModel)]="freightSetting.purewood.front.palletMaxWeight" class="form-control text-end" step="10" type="number">
                        <span class="input-group-text">kg</span>
                    </div>
                </div>
                <div class="col-3 mb-3 mt-auto">
                    <label class="form-label">Vægt per kvadratmeter for en Purewood front</label>
                    <div class="input-group">
                        <input [(ngModel)]="freightSetting.purewood.front.m2ToWeight" class="form-control text-end" step="0.2" type="number">
                        <span class="input-group-text">kg/m<sup>2</sup></span>
                    </div>
                </div>
                <div class="col-3 mb-3 mt-auto">
                    <label class="form-label">Mindste dimension som udløser en dobbeltpalle</label>
                    <div class="input-group">
                        <input [(ngModel)]="freightSetting.purewood.front.doublePalletLimit" class="form-control text-end" step="100" type="number">
                        <span class="input-group-text">mm</span>
                    </div>
                </div>
                <div class="col-4 mb-3">
                    <label class="form-label">Grundpris ved 1) almindelig fragt</label>
                    <div class="input-group mb-1">
                        <img [ngSrc]="'/assets/images/flags/dk.png'" alt="Danmark" class="input-group-text" height="40" width="60">
                        <input [(ngModel)]="freightSetting.purewood.front.baseCost.dk" class="form-control text-end" step="50" type="number">
                        <span class="input-group-text">DKK</span>
                    </div>
                    <div class="input-group mb-1">
                        <img [ngSrc]="'/assets/images/flags/no.png'" alt="Norge" class="input-group-text" height="40" width="60">
                        <input [(ngModel)]="freightSetting.purewood.front.baseCost.no" class="form-control text-end" step="50" type="number">
                        <span class="input-group-text">DKK</span>
                    </div>
                    <div class="input-group">
                        <img [ngSrc]="'/assets/images/flags/se.png'" alt="Sverige" class="input-group-text" height="40" width="60">
                        <input [(ngModel)]="freightSetting.purewood.front.baseCost.se" class="form-control text-end" step="50" type="number">
                        <span class="input-group-text">DKK</span>
                    </div>
                </div>
                <div class="col-4 mb-3">
                    <label class="form-label">Kvadratmeterpris ved 1) almindelig fragt</label>
                    <div class="input-group mb-1">
                        <img [ngSrc]="'/assets/images/flags/dk.png'" alt="Danmark" class="input-group-text" height="40" width="60">
                        <input [(ngModel)]="freightSetting.purewood.front.costPerM2.dk" class="form-control text-end" type="number">
                        <span class="input-group-text">DKK/m<sup>2</sup></span>
                    </div>
                    <div class="input-group mb-1">
                        <img [ngSrc]="'/assets/images/flags/no.png'" alt="Norge" class="input-group-text" height="40" width="60">
                        <input [(ngModel)]="freightSetting.purewood.front.costPerM2.no" class="form-control text-end" type="number">
                        <span class="input-group-text">DKK/m<sup>2</sup></span>
                    </div>
                    <div class="input-group">
                        <img [ngSrc]="'/assets/images/flags/se.png'" alt="Sverige" class="input-group-text" height="40" width="60">
                        <input [(ngModel)]="freightSetting.purewood.front.costPerM2.se" class="form-control text-end" type="number">
                        <span class="input-group-text">DKK/m<sup>2</sup></span>
                    </div>
                </div>
                <div class="col-4 mb-3">
                    <label class="form-label">Fragtpris per palle ved 2) pallefragt</label>
                    <div class="input-group mb-1">
                        <img [ngSrc]="'/assets/images/flags/dk.png'" alt="Danmark" class="input-group-text" height="40" width="60">
                        <input [(ngModel)]="freightSetting.purewood.front.palletCost.dk" class="form-control text-end" step="50" type="number">
                        <span class="input-group-text">DKK/palle</span>
                    </div>
                    <div class="input-group mb-1">
                        <img [ngSrc]="'assets/images/flags/no.png'" alt="Norge" class="input-group-text" height="40" width="60">
                        <input [(ngModel)]="freightSetting.purewood.front.palletCost.no" class="form-control text-end" step="50" type="number">
                        <span class="input-group-text">DKK/palle</span>
                    </div>
                    <div class="input-group">
                        <img [ngSrc]="'/assets/images/flags/se.png'" alt="Sverige" class="input-group-text" height="40" width="60">
                        <input [(ngModel)]="freightSetting.purewood.front.palletCost.se" class="form-control text-end" step="50" type="number">
                        <span class="input-group-text">DKK/palle</span>
                    </div>
                </div>
                <div class="col-12 text-end">
                    <button (click)="saveFreightSettings()" class="btn btn-success">Gem fragt-opsætning</button>
                </div>
            </div>
            <h5>Beregningseksempel</h5>
            <div class="row">
                <div class="col-3">
                    Køb af Purewood fronter, fordelt på almindelige og store.
                </div>
                <div class="col-3">
                    Fronter under {{ freightSetting.purewood.front.doublePalletLimit }} mm i alt:
                    <div class="input-group">
                        <input [(ngModel)]="purewoodFrontExampleSmall" class="form-control text-end" step="0.2" type="number">
                        <span class="input-group-text">m<sup>2</sup></span>
                    </div>
                </div>
                <div class="col-3">
                    Fronter over {{ freightSetting.purewood.front.doublePalletLimit }} mm i alt:
                    <div class="input-group">
                        <input [(ngModel)]="purewoodFrontExampleLarge" class="form-control text-end" step="0.2" type="number">
                        <span class="input-group-text">m<sup>2</sup></span>
                    </div>
                </div>
                <div class="col-12">
                    <ng-container
                            *ngIf="purewoodFrontExampleSmall * freightSetting.purewood.front.m2ToWeight < freightSetting.purewood.front.palletWeightThreshold && purewoodFrontExampleLarge === 0">
                        <div class="fw-bold">Almindelig fragt:</div>
                        <div>Til Danmark:
                            <u>{{ (freightSetting.purewood.front.baseCost.dk + freightSetting.purewood.front.costPerM2.dk * Math.ceil(purewoodFrontExampleSmall)) | number: '1.2-2' }}
                                DKK</u></div>
                        <div>Til Norge:
                            <u>{{ (freightSetting.purewood.front.baseCost.no + freightSetting.purewood.front.costPerM2.no * Math.ceil(purewoodFrontExampleSmall)) | number: '1.2-2' }}
                                DKK</u></div>
                        <div>Til Sverige:
                            <u>{{ (freightSetting.purewood.front.baseCost.se + freightSetting.purewood.front.costPerM2.se * Math.ceil(purewoodFrontExampleSmall)) | number: '1.2-2' }}
                                DKK</u></div>
                    </ng-container>
                    <ng-container
                            *ngIf="purewoodFrontExampleSmall * freightSetting.purewood.front.m2ToWeight >= freightSetting.purewood.front.palletWeightThreshold || purewoodFrontExampleLarge > 0">
                        <div class="fw-bold">Pallefragt:</div>
                        <div>Til Danmark:
                            <u>{{ (examplePallets * freightSetting.purewood.front.palletCost.dk) | number: '1.2-2' }}
                                DKK</u></div>
                        <div>Til Norge:
                            <u>{{ (examplePallets * freightSetting.purewood.front.palletCost.no) | number: '1.2-2' }}
                                DKK</u></div>
                        <div>Til Sverige:
                            <u>{{ (examplePallets * freightSetting.purewood.front.palletCost.se) | number: '1.2-2' }}
                                DKK</u></div>
                    </ng-container>
                </div>
            </div>

        </div>

    </div>
    <div class="row">

        <div class="col-12 my-3 bg-primary text-white p-3 ps-4 body-type-header">
            {{ 'Specialprodukter' | uppercase }}
        </div>

    </div>
    <div class="row">

        <div class="col-12 mb-3">
            <div class="fw-bold">OBS: Fragt på specialvarer er baseret på kundens sprog og ikke kundens land.</div>
            <div class="fw-bold">Kunden kan altså potentielt ændre sin fragtpris ved at skifte sprog.</div>
            <div>
                Når der lægges varer fra en af specialprodukt-kategorierne i kurven, så tillægges der fragt baseret på kundens sprog som vist i
                nedenstående tabel.
            </div>
            <div>Hvis fragten nedenfor er angivet som "ikke defineret", så kan det være at fragten er sat på underkategorier.</div>
            <div>Dette anbefales kun, hvis underkategorierne har forskellige fragtsatser.</div>
            <div>Hvis fragten er angivet på både hovedkategi og underkategori, så er det hovedkategoriens fragtindstilling der har forrang.</div>
        </div>

    </div>
    <div class="row">

        <div *ngFor="let locale of locales" class="col-3 mb-3 px-4">
            <ng-container *ngIf="specialCategories[locale.id]">
                <div class="row bg-secondary">
                    <div [innerText]="locale.name" class="col-12"></div>
                </div>
                <div *ngFor="let specialCategory of specialCategories[locale.id]" class="border-bottom d-flex justify-content-between">
                    <div>
                        {{ specialCategory.name}}
                        <a [routerLink]="'/admin/products/categories/' + specialCategory.id">
                            <fa-icon [icon]="faEdit"></fa-icon>
                        </a>
                    </div>
                    <div class="flex-shrink-1">
                        <ng-container *ngIf="specialCategory.freightRate">
                            {{ specialCategory.freightRate | number: '1.2-2' }} DKK
                        </ng-container>
                        <ng-container *ngIf="!specialCategory.freightRate">
                            Ikke defineret <abbr title="Fragten kan være defineret af underkategorier.">*</abbr>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </div>

    </div>
    <div class="row">

        <div class="col-12 mb-3"><small>*Fragten kan være defineret af underkategorier.</small></div>

    </div>
    <div class="row">

        <div class="col-12 my-3 bg-primary text-white p-3 ps-4 body-type-header">
            {{ 'Samlet fragt' | uppercase }}
        </div>
        <div *ngIf="freightSetting" class="col-12 mb-3">
            <div>Kunden har mulighed for at vælge samlet fragt fra kurven.</div>
            <div>
                Produkter fra Nothegger (undtaget afstandslister) og Ernst Mair, samt skuffer fra Purewood fylder hver især
                1/{{ freightSetting.oneDelivery.palletCapacity }} af en palle.
            </div>
            <div>
                Det gør Purewood fronter også, men hvis de er større end {{ freightSetting.oneDelivery.doublePalletLimit }} mm, så skal de pakkes på
                en dobbeltpalle.
            </div>
            <div>
                Specialprodukter opsættes inde på hvert enkelt produkt. Det kan angives hvor meget de fylder af en palle, hvorvidt et enkelt produkt
                fylder en hel palle og hvorvidt produkter skal pakkes på en dobbeltpalle.
            </div>
            <div>Prisen per palle er {{ freightSetting.oneDelivery.palletFreight.dk | number: '1.2-2' }} DKK</div>
            <div><a [routerLink]="'/admin/' + productsUrl + '/' + specialsPrefix + 'all'">Se oversigt over alle produkter her.</a></div>
            <div>Produkter der ikke har en "samlet fragt"-indstilling antages at være en smådel, som ikke fylder nævneværdigt på en palle.</div>
        </div>

    </div>
</div>
