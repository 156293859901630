import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faComment } from '@fortawesome/free-solid-svg-icons/faComment';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faRedo } from '@fortawesome/free-solid-svg-icons/faRedo';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';

import { CartService } from '../../services';
import { productsUrl } from '../../utils/breadcrumbs/breadcrumbs';
import { TranslatingBase } from '../../base-component/ComponentBase';
import { IOrderLayoutViewMode } from '../iorder-layout';
import { CartItemType, ICartItem } from '../../../../../wdcommon/ICartItem';
import { UserService } from '../../services/user.service';


@Component({
  selector: 'app-order-layout-body-item',
  templateUrl: './order-layout-body-item.component.html',
  styleUrls: ['./order-layout-body-item.component.css']
})
export class OrderLayoutBodyItemComponent extends TranslatingBase {
  @Input() discountRow: boolean;
  @Input() item: ICartItem;
  @Input() itemName: string;
  @Input() viewMode: IOrderLayoutViewMode;
  @Output() repeatDrawer = new EventEmitter<ICartItem>();
  @Output() repeatFront = new EventEmitter<ICartItem>();

  itemsCollapse = [];

  protected readonly faChevronDown = faChevronDown;
  protected readonly faChevronUp = faChevronUp;
  protected readonly faComment = faComment;
  protected readonly faRedo = faRedo;
  protected readonly CartItemType = CartItemType;
  protected readonly IOrderLayoutViewMode = IOrderLayoutViewMode;
  protected readonly faEdit = faEdit;
  protected readonly productsUrl = productsUrl;
  protected readonly faTrash = faTrash;

  constructor(
      private cartService: CartService,
      private translateService: TranslateService,
      private userService: UserService,
  ) {
    super(translateService);
  }

  get isInCart() {
    return this.viewMode === IOrderLayoutViewMode.cart || this.viewMode === IOrderLayoutViewMode.cart_offline;
  }

  get isUserLoggedIn() {
    return this.userService.isLoggedIn; //this.viewMode !== IOrderLayoutViewMode.cart_offline;
  }

  shouldBeCollapsed(index: string) {
    const collapseItem = this.itemsCollapse[index];
    return collapseItem === true || collapseItem === undefined;
  }

  toggleCollapse(index: string) {
    this.itemsCollapse[index] = !this.shouldBeCollapsed(index);
  }

  setRepeatDrawer() {
    this.repeatDrawer.emit(this.item);
  }

  setRepeatFront() {
    this.repeatFront.emit(this.item);
  }

  async updateCount({ target }: Event) {
    const newAmount = (target as HTMLInputElement).valueAsNumber;

    if (newAmount === undefined || !isFinite(newAmount) || isNaN(newAmount)) {
      return;
    }

    await this.cartService.setAmount(this.item, newAmount);
  }

  async deleteCartItem() {
    await this.cartService.removeItem(this.item.itemno);
  }
}
