import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({ selector: '[appCustomerViewOnly]' })
export class CustomerViewOnlyDirective {

  constructor(private _viewContainer: ViewContainerRef, private _templateRef: TemplateRef<any>) {
    this._updateView();
  }

  private _updateView() {
    if (window.location.pathname.indexOf('/admin/') !== 0) {
      this._viewContainer.clear();
      this._viewContainer.createEmbeddedView(this._templateRef);
    }
  }

}
