<div class="container">
    <div class="row mb-3">
        <div class="col">
            <app-breadcrumbs [currentBreadcrumb]="adminBreadcrumb"></app-breadcrumbs>
        </div>
    </div>

    <div class="row">

        <div class="col-sm-6">
            <h4>5 Seneste ordrer</h4>
            <div class="list-group">
                <a *ngFor="let order of newOrders" [routerLink]="['/admin/orders/'+order.id]"
                   class="list-group-item list-group-item-wd">{{ order.company.companyName }}: {{ order.orderID }}</a>
            </div>
        </div>
        <div class="col-sm-6">
            <h4>Brugere der afventer godkendelse</h4>
            <div *ngIf="waitingUsers.length === 0" class="alert alert-light" role="alert">
                Ingen brugere venter godkendelse
            </div>
            <div class="list-group">
                <div *ngFor="let user of waitingUsers" class="list-group-item list-group-item-wd">
                    <app-admin-approve-user [user]="user" (activated)="loadWaitingUsers()"></app-admin-approve-user>
                    <button #pop="bs-popover" (onHidden)="deleteID = null;" (onShown)="deleteID = user.id;" [outsideClick]="true" [popoverTitle]=""
                            [popover]="delPopUser" class="btn btn-sm btn-danger" container="body" onclick="$event.stopPropagation()" type="button">
                        <fa-icon [icon]="faTrash"></fa-icon>
                    </button>
                    {{ user.company.companyName }}: {{ user.email }}
                </div>
            </div>
        </div>

    </div>
    <div class="row">

        <div class="col-sm">
            <h4 class="pt-3">Ordre overblik (seneste 250)</h4>
            <ngx-charts-advanced-pie-chart *ngIf="orderData.length > 0" [results]="orderData" [scheme]="colorScheme"
                                           [view]="view"></ngx-charts-advanced-pie-chart>
        </div>

    </div>
    <div class="row">

        <div class="col-sm">
            <h4 class="pt-3">Indtjening (seneste 250) <small class="text-muted">(DKK)</small></h4>
            <ngx-charts-advanced-pie-chart *ngIf="earningsData.length > 0" [results]="earningsData" [scheme]="colorScheme"
                                           [view]="view"></ngx-charts-advanced-pie-chart>
        </div>
    </div>
</div>

<ng-template #delPopUser>
    <div *blockUI="delUserBlock" class="text-center">
        <div class="btn-group" role="group">
            <button (click)="deleteUser()" class="btn btn-sm btn-danger">Slet</button>
            <button (click)="hidePop()" class="btn btn-sm btn-secondary">Nej</button>
        </div>
    </div>
</ng-template>
