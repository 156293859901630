import { Component, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { UserService } from './services';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
    title = 'Unit-Partner Shop';

    constructor(
        private userService: UserService,
    ) {
    }

    async ngOnInit() {
        if (!this.userService.getUser()) {
            const token = localStorage.getItem('token') ?? '';
            if (token.length > 0) {
                // If no user exist in session, then this will set the user from token. A page reload is then needed.
                await this.userService.checkUserIsValid();
                window.location.reload();
            }
        }
    }
}
