import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { CartService } from '../../services';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { TranslatingBase } from '../../base-component/ComponentBase';
import { AddToCartWithAmountModalComponent } from '../../modals/add-to-cart-with-amount-modal/add-to-cart-with-amount-modal.component';


@Component({
  selector: 'app-amount-step',
  templateUrl: './amount-step.component.html',
  styleUrls: ['./amount-step.component.css']
})
export class AmountStepComponent extends TranslatingBase {
  @Input()  disable: boolean;
  @Input()  amount: number;
  @Output() amountChange = new EventEmitter<number>();
  @Output() approved = new EventEmitter<boolean>();

  faArrowRight = faArrowRight;

  modalRef: BsModalRef<AddToCartWithAmountModalComponent>;

  constructor(
      public modalService: BsModalService,
      protected cartService: CartService,
      protected translateService: TranslateService,
      protected toastrService: ToastrService
  ) {
    super(translateService);
  }

  displayConfirmDialog() {
    this.modalRef = this.modalService.show(
        AddToCartWithAmountModalComponent,
        {
          initialState: {
            amount: this.amount,
            disable: this.disable
          }
        }
    );

    this.modalRef.content.amountChange.subscribe((amount: number) => {
      this.amount = amount;
      this.amountChange.emit(amount);
    });

    this.modalRef.content.approved.subscribe((approved: boolean) => {
      this.approved.emit(approved);
    });
  }
}
