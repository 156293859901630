<div *ngIf="!compact">
  <a (click)="setLanguage(language.id)" *ngFor="let language of languages">
    <img [alt]="language.name" [src]="'/assets/images/flags/' + language.id + '.png'"/>
  </a>
</div>

<ng-container *ngIf="compact">
  <span class="btn-group" dropdown>
    <button aria-controls="language_select" class="btn btn-sm btn-outline-primary dropdown-toggle dropdown-toggle-split" dropdownToggle
            id="language_button" type="button">
      <fa-icon [icon]="faGlobe"></fa-icon>&nbsp; {{ localeService.getLanguage() }}&nbsp;<span class="caret"></span>
    </button>
    <ul *dropdownMenu aria-labelledby="language_button" class="dropdown-menu dropdown-menu-end dropdown-split" id="language_select" role="menu">
      <li *ngFor="let language of languages" role="menuitem">
        <div (click)="setLanguage(language.id)" class="dropdown-item">{{ language.name }}</div>
      </li>
    </ul>
  </span>
</ng-container>
