<div class="card border-light shadow-sm rounded">
    <div class="card-header">
        <app-remove-button (delete)="deleteLocale()" [disabled]="!canBeDeleted" class="float-end"></app-remove-button>
        {{displayLanguage}}
    </div>

    <div class="card-body">
        <div [formGroup]="group">
            <div class="row mb-3">
                <div class="col-sm-6">
                    <label class="form-label">{{'Kategorinavn' | translate}}</label>
                    <input class="form-control" formControlName="name" required type="text">
                </div>

                <div class="col-sm-6">
                    <label class="form-label">{{'Fragtpris' | translate}}</label>
                    <input class="form-control" formControlName="freightRate" id="freightRate" step="0.01" type="number">
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12">
                    <label class="form-label">{{'Beskrivelse' | translate}}</label>
                    <div>
                        <quill-editor formControlName="description"></quill-editor>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
