import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { APIService, LocaleService, UserService } from '../services';
import { Breadcrumb } from '../utils/breadcrumbs/breadcrumbs';
import { GlobalContentType, IGlobalContent } from '../../../../wdcommon/IGlobalContent';


interface ProductTypeBrand {
  header: string;
  imageUrl: string;
  logoUrl: string;
  routerLink?: string;
  sortOrder: number;
  text: string;
}


@Component({
  selector: 'app-product-type',
  templateUrl: './product-type.component.html',
  styleUrls: ['./product-type.component.css']
})
export class ProductTypeComponent implements OnInit {
  language: string;
  productTypePrefix: string;
  brands: ProductTypeBrand[] = [];
  productTypeBreadcrumb: Breadcrumb;

  constructor(
      private apiService: APIService,
      private localeService: LocaleService,
      private route: ActivatedRoute,
      private router: Router,
      private userService: UserService,
  ) {
  }

  ngOnInit() {
    this.language = this.localeService.getLanguage();
    this.route.params.subscribe((params) => {
      this.productTypePrefix = params.productType + '-';
      this.apiService.getGlobalContentOfType(GlobalContentType.productType)
          .then((contents) => {
            const brandContents = contents.filter((c) => (
                c.key.indexOf(this.productTypePrefix) === 0 &&
                c.public && c.public[this.language] &&
                (!c.availableFrom || new Date(c.availableFrom).valueOf() < Date.now()) &&
                (!c.availableTo || new Date(c.availableTo).valueOf() > Date.now()) &&
                (c.key.indexOf('ernst-mair') === -1 || this.userService.isAllowedErnstMair()) &&
                (c.key !== 'wooden-drawers-purewood' || this.userService.isAllowedPurewood())
            ));

            if (brandContents.length === 0) {
              this.router.navigateByUrl('/').then();
            } else {
              this.productTypeBreadcrumb = {
                nameId: 'ProductType.' + params.productType,
                url: '/' + this.productTypeBreadcrumb + '/' + params.productType,
              };

              const promises: Promise<ProductTypeBrand>[] = [];
              brandContents.forEach((b) => promises.push(this.createBrandBlock(b)));
              return Promise.all(promises);
            }
          })
          .then((brands) => {
            brands.sort((a, b) => a.sortOrder - b.sortOrder);
            this.brands = brands;
          });
    });
  }

  async createBrandBlock(b: IGlobalContent): Promise<ProductTypeBrand> {
    const image = await this.apiService.getGlobalContentImage(b.type, b.key);
    const logo = await this.apiService.getGlobalContentImage(b.type, b.key + '-logo');

    return {
      header: b.public[this.language]['header'],
      imageUrl: (image === null ? null : image.encImg),
      logoUrl: (logo === null ? null : logo.encImg),
      routerLink: b.routerLink,
      sortOrder: b.sortOrder,
      text: b.public[this.language]['text'],
    };
  }

}
