import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { Locale, LocaleService } from '../../../services';
import { AdminProductsLanguageService } from '../admin-products-language.service';


@Component({
  selector: 'app-admin-products-language',
  templateUrl: './admin-products-language.component.html',
  styleUrls: ['./admin-products-language.component.css']
})
export class AdminProductsLanguageComponent implements OnInit, OnDestroy {

  _locales: Locale[];
  private _currentLocale: Locale;
  private _currentLocaleSubscription: Subscription;

  constructor(private localeService: LocaleService, private adminProductsLanguageService: AdminProductsLanguageService) {
  }

  get currentLocale() {
    return this._currentLocale;
  }

  set currentLocale(locale: Locale) {
    this.adminProductsLanguageService.currentLocale = locale;
  }

  ngOnInit() {
    this._locales = this.localeService.getSupportedLanguages();
    this._currentLocaleSubscription = this.adminProductsLanguageService
        .connectCurrentLocale()
        .subscribe(locale => this._currentLocale = locale);
  }

  ngOnDestroy() {
    this._currentLocaleSubscription.unsubscribe();
  }

  compareLocales(first: Locale, second: Locale): boolean {
    if (!first || !second)
      return false;

    return first.id === second.id;
  }

}
