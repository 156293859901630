<div class="container">
    <div class="row mb-3">

        <div class="col-12">
            <app-breadcrumbs [currentBreadcrumb]="purewoodBreadcrumb"></app-breadcrumbs>
        </div>

    </div>
    <div class="row">
        <div *ngIf="canAccessDrawers" class="col-12 col-sm-6 col-lg-4 col-xl-3 mt-3">

            <div [routerLink]="drawersBreadcrumb.url" class="card up-card h-100 clickable">
                <img [alt]="drawersBreadcrumb.nameId | translate" class="card-img-top py-4 img-scale-down" src="/assets/images/drawers/innenlade.png">
                <div class="card-body">
                    <div [translate]="drawersBreadcrumb.nameId" class="card-title"></div>
                </div>
            </div>

        </div>
        <div class="col-12 col-sm-6 col-lg-4 col-xl-3 mt-3">

            <div [routerLink]="paintedFrontsBreadcrumb.url" class="card up-card h-100 clickable">
                <img [alt]="paintedFrontsBreadcrumb.nameId | translate" class="card-img-top img-scale-down" src="/assets/images/fronts/English%20Edge%20Painted.png">
                <div class="card-body">
                    <div [translate]="paintedFrontsBreadcrumb.nameId" class="card-title"></div>
                </div>
            </div>

        </div>
        <div class="col-12 col-sm-6 col-lg-4 col-xl-3 mt-3">

            <div [routerLink]="woodenFrontsBreadcrumb.url" class="card up-card h-100 clickable">
                <img [alt]="woodenFrontsBreadcrumb.nameId | translate" class="card-img-top img-scale-down" src="/assets/images/fronts/M1%20Oak.png">
                <div class="card-body">
                    <div [translate]="woodenFrontsBreadcrumb.nameId" class="card-title"></div>
                </div>
            </div>

        </div>
        <div class="col-12 col-sm-6 col-lg-4 col-xl-3 mt-3">

            <div [routerLink]="linoleumFrontsBreadcrumb.url" class="card up-card h-100 clickable">
                <img [alt]="linoleumFrontsBreadcrumb.nameId | translate" class="card-img-top img-scale-down" src="/assets/images/fronts/Linoleum.png">
                <div class="card-body">
                    <div [translate]="linoleumFrontsBreadcrumb.nameId" class="card-title d-flex flex-nowrap justify-content-center"></div>
                </div>
            </div>

        </div>
        <div class="col-12 col-sm-6 col-lg-4 col-xl-3 mt-3">

            <div [routerLink]="veneerFrontsBreadcrumb.url" class="card up-card h-100 clickable">
                <img [alt]="veneerFrontsBreadcrumb.nameId | translate" class="card-img-top img-scale-down pt-3" src="/assets/images/fronts/plain.png">
                <div class="card-body">
                    <div [translate]="veneerFrontsBreadcrumb.nameId" class="card-title"></div>
                </div>
            </div>

        </div>
    </div>

</div>
