import { Validators } from '@angular/forms';

import { FormRow } from './dynaform.interfaces';
import {
  createDrawerHeightField,
  createDrawerWidthField,
  createRunnerDepthField,
  DrawerHeightFieldName,
  StandardCouplingField,
  StandardDrawerBaseField,
  StandardIncludeCouplingField,
  StandardJointField,
  StandardRunnerDepthSelectField,
  StandardRunnerLoadField,
  StandardRunnerMarkField,
  StandardRunnerTypeField,
  StandardSurfaceTreatmentField,
  StandardThicknessField,
  StandardTypeOfWoodField,
  StandardWoodQualityField
} from './standard-fields';
import { OptionProperty } from '../../../../wdcommon/IProduct';
import { ShimsOptions, SlideListOptions } from '../../../../wdcommon/IDrawer';


export enum FormRowIds {
  shimsRow = 'shims-row',
  slideListRow = 'slide-list-row',
  veneerTreatmentRow = 'veneer-treatment-row',
}

export function createHeightBaseSelectionRow(heightName: DrawerHeightFieldName = DrawerHeightFieldName.height): FormRow {
  return {
    id: 'height-base-row',
    show: true,
    fields: [
      createDrawerHeightField(heightName),
      StandardDrawerBaseField
    ]
  };
}

export function createHrRow(): FormRow {
  return {
    id: 'spacer-row-1',
    show: true,
    fields: [
      {
        name: 'spacerRowHR',
        type: 'html',
        html: '<hr/>',
        containerClass: 'col-sm'
      }
    ]
  };
}

export function createPurewoodThicknessRow(): FormRow {
  return {
    id: 'thickness-row',
    show: true,
    fields: [
      StandardThicknessField
    ]
  }
}

export function createJointSurfaceTreatmentSelectionRow(): FormRow {
  return {
    id: 'joint-surface-treatment-row',
    show: true,
    fields: [
      StandardJointField,
      StandardSurfaceTreatmentField
    ]
  };
}

export function createSurfaceTreamentSelectionRow(): FormRow {
  return {
    id: 'surface-treatment-row',
    show: true,
    fields: [
      StandardSurfaceTreatmentField
    ]
  };
}


export function createPremountedCouplingSelectionRow(): FormRow {
  return {
    id: 'premounted-coupling-row',
    show: true,
    fields: [
      StandardCouplingField,
      StandardIncludeCouplingField,
    ]
  };
}

export function createRunnerDeliverySelectionRow(): FormRow {
  return {
    id: 'udtr-leveret',
    fields: [
      {
        type: 'select',
        name: 'udtrLeveret',
        label: 'Skal Unit-Partner levere udtræk?',
        classes: 'col-sm',
        value: 'nej',
        options: [
          {
            value: 'nej',
            label: 'Nej'
          },
          {
            value: 'ja',
            label: 'Ja'
          }
        ]
      },
      {
        type: 'select',
        name: 'udtrDybdeJustering',
        label: 'Dybdejustering',
        classes: 'col-sm',
        disabled: true,
        value: 'nej',
        show: false,
        options: [
          {
            value: 'nej',
            label: 'Nej'
          },
          {
            value: 'ja',
            label: 'Ja'
          }
        ]
      },
      {
        type: 'select',
        name: OptionProperty.runnerPushToOpenSilent,
        label: 'Push to Open Silent',
        show: false,
        disabled: true,
        classes: 'col-sm',
        value: 'nej',
        options: [
          {
            value: 'nej',
            label: 'Nej'
          },
          {
            value: 'ja',
            label: 'Ja'
          }
        ]
      },
      {
        type: 'select',
        name: OptionProperty.synchronisationBar,
        label: 'Synkronstang',
        classes: 'col-sm',
        show: false,
        disabled: true,
        value: 'nej',
        options: [
          {
            value: 'nej',
            label: 'Nej'
          },
          {
            value: 'ja',
            label: 'Ja'
          }
        ]
      }
    ],
    show: false
  };
}

export function createRunnerWidthSelectionRow(drawerWidthMin: number = 150, drawerWidthMax: number = 1200, runnerDepthMin: number = 150, runnerDepthMax: number = 1200): FormRow {
  return {
    id: 'runner-width-row',
    show: true,
    fields: [
      StandardRunnerMarkField,
      StandardRunnerTypeField,
      createRunnerDepthField(runnerDepthMin, runnerDepthMax),
      StandardRunnerDepthSelectField,
      StandardRunnerLoadField,
      createDrawerWidthField(drawerWidthMin, drawerWidthMax),
    ]
  };
}

export function createSlideListRow(): FormRow {
  return {
    id: FormRowIds.slideListRow,
    show: true,
    fields: [
      {
        type: 'select',
        classes: 'col-sm-6',
        name: OptionProperty.slideList,
        label: 'Føringsliste',
        helpHTML: 'FøringslisteHelpHTML',
        show: true,
        disabled: false,
        options: SlideListOptions,
        validations: [{
          name: 'required',
          validator: Validators.required,
          message: 'Required.SlideList.Selected'
        }],
      }
    ]
  };
}

export function createShimsRow(): FormRow {
  return {
    id: FormRowIds.shimsRow,
    show: true,
    fields: [
      {
        type: 'select',
        classes: 'col-sm-6',
        name: OptionProperty.shimsSelection,
        label: 'Afstandsliste',
        helptext: 'Afstandslisten fratrækkes i bredden',
        show: true,
        disabled: true,
        suffix: 'mm',
        options: ShimsOptions,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message: 'Der skal vælges afstandsliste'
          }
        ],
        translate: { helptext: false }
      }
    ]
  };
}

export function createTypeOfWoodSurfaceTreatmentSelectionRow(): FormRow {
  return {
    id: 'type-of-wood-surface-treatment-row',
    show: true,
    fields: [
      StandardTypeOfWoodField,
      StandardSurfaceTreatmentField
    ]
  };
}

export function createQualityTypeOfWoodSelectionRow(): FormRow {
  return {
    id: 'quality-type-of-wood-row',
    show: true,
    fields: [
      StandardWoodQualityField,
      StandardTypeOfWoodField
    ]
  };
}
