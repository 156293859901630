import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserService } from '../services';

@Directive({ selector: '[appOnlyVisibleForUsers]' })
export class OnlyVisibleForUsersDirective {

  constructor(private _viewContainer: ViewContainerRef, private _templateRef: TemplateRef<any>, private userService: UserService) {
    this._updateView();
  }

  private _updateView() {
    if (this.userService.isLoggedIn) {
      this._viewContainer.clear();
      this._viewContainer.createEmbeddedView(this._templateRef);
    }
  }

}
