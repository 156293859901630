<div *appOnlyVisibleForUsers>
  <p class="price">
    {{ price | number: '1.2-2' }} {{ currencyShort }}
  </p>

  <p class="pieces-or-set" *ngIf="_product?.isSet !== null">
    <span *ngIf="_product?.isSet === true">{{ 'Prisen er pr sæt' | translate }}</span>
    <span *ngIf="_product?.isSet === false">{{ 'Prisen er pr stk' | translate }}</span>
  </p>
</div>

<div *appOnlyVisibleBeforeLogin>
  <small class="text-muted">{{ 'PriceVisibleAfterLogin' | translate }}</small>
</div>
