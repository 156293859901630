<button [class.btn-danger]="!outline" [class.btn-lg]="buttonSize === ButtonSize.lg" [class.btn-outline-danger]="outline"
        [class.btn-sm]="buttonSize === ButtonSize.sm" [disabled]="disabled" [outsideClick]="true" [popoverTitle]="'Er du sikker' | translate"
        [popover]="delPop" class="btn" container="body" type="button" tabindex="-1">
    <fa-icon [icon]="faTrash"></fa-icon>
    <ng-content></ng-content>
</button>

<ng-template #delPop>
    <div class="text-center">
        <div class="btn-group" role="group">
            <button (click)="deleteConfirmed()" class="btn btn-sm btn-danger" translate="UTILITY.DELETE"></button>
            <button (click)="hideDelPop()" class="btn btn-sm btn-secondary" translate="UTILITY.NO"></button>
        </div>
    </div>
</ng-template>
