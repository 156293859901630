<div class="container">
    <div class="row">
        <app-sortissimo></app-sortissimo>
    </div>
    <div class="row">
        <hr/>
    </div>
    <div class="float-end">
        <button (click)="approveBestikindsats() && displayAddToCartModal()" class="btn btn-primary" type="submit">
            Videre
            <fa-icon [icon]="faArrowRight"></fa-icon>
        </button>
    </div>
</div>>