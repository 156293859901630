import { TranslateService } from '@ngx-translate/core';

import { CartItemType, CartSubType, IAddCart, ITypedAddCartItem } from '../../../../../wdcommon/ICartItem';
import { ExternalShopName } from '../../../../../wdcommon/IExternalShop';
import { TranslatingBase } from '../../base-component/ComponentBase';
import { UserError } from './UserError';

export type Cart = CartItem[];

export abstract class CartItem extends TranslatingBase {
  public readonly comments: string;
  public readonly type: CartItemType;
  public readonly subType: CartSubType;
  public readonly name: string;
  public readonly description: string;
  public readonly itemno: string;
  public readonly isComputed: boolean = false;
  public readonly isGenerated: boolean = false;
  public readonly externalShopName?: ExternalShopName;
  public readonly options: { readonly [options: string]: any; };
  public readonly amountStep: number;
  public readonly price: number;
  public readonly fragt: number;
  public readonly oneDeliverySize: number;
  public readonly oneDeliveryDoublePallet: boolean;

  private _brandId: string;
  private _amount = 0;
  private _pricePer = 0;
  private _hasCustomAmount: boolean;

  protected constructor(
      private __translateService: TranslateService,
      private itemBase: ITypedAddCartItem,
      private readonly amountComputer?: (cart: Cart) => number | Promise<number>
  ) {
    super(__translateService);

    if (!itemBase)
      throw new Error('Parameter \'itemBase\' cannot be null.');

    this.comments = itemBase.comments;
    this.type = itemBase.type;
    this.subType = itemBase.subType;
    this.itemno = itemBase.itemno;
    this.name = itemBase.name;
    this.description = itemBase.description;
    this.options = itemBase.options || {};
    this.amountStep = itemBase.amountStep;
    this._brandId = itemBase.brandId;
    this._amount = itemBase.amount;
    this._pricePer = 0;
    this.price = itemBase.price ? itemBase.price : 0;
    this.fragt = itemBase.fragt ? itemBase.fragt : 0;
    this.oneDeliverySize = (itemBase.type === CartItemType.additional ? (itemBase.oneDeliverySize ?? 0) : undefined);
    this.oneDeliveryDoublePallet = (itemBase.type === CartItemType.additional ? (itemBase.oneDeliveryDoublePallet === true) : undefined);

    this.isGenerated = itemBase.asGenerated;
    this.externalShopName = itemBase.externalShopName;
    if (amountComputer) {
      this.isComputed = this.isGenerated = true;
    }

    this.validateAmount(itemBase.amount);
  }

  public get priceDetails() {
    return { total: 0 };
  }

  public get orderDetails() {
    return [];
  }

  public get isVisible() {
    return !this.isComputed || this.amount > 0;
  }

  public get pricePer(): number {
    return this._pricePer;
  }

  public get priceTotal(): number {
    return this._pricePer * this.amount;
  }

  public set brandId(brandId: string) {
    this._brandId = brandId;
  }

  public get brandId() {
    return this._brandId;
  }

  public get amount() {
    return this._amount;
  }

  public set amount(value) {

    if (this.isGenerated)
      throw new Error('Cannot update amount for generated items.');
    this.validateAmount(value);

    this._amount = value;
    this._hasCustomAmount = true;

  }

  public async initialize(items: Cart) {

    await this.preCompute();
    await this.compute(items);
    await this.postCompute();

  }

  public async preCompute(): Promise<'keep' | 'remove'> {

    if (this.isGenerated && !this.isComputed)
      return 'remove';

    return 'keep';
  }

  public async compute(cart: Cart): Promise<'keep' | 'remove' | IAddCart> {

    if (!cart)
      throw new Error('Parameter \'cartItems\' cannot be null.');

    if (this.isComputed) {
      if (this._hasCustomAmount)
        return;

      this._amount = await this.amountComputer(cart);
      return 'keep';
    } else {
      const generatedItems = this.generateItems();

      return generatedItems || 'keep';
    }
  }

  public async postCompute(): Promise<void> {

    await this.amountUpdated();
    await this.updateDetails();

  }

  public serialize(): ITypedAddCartItem {
    if (this.isComputed || this.isGenerated) {
      return null;
    }
    return this.toAddCartItem() as ITypedAddCartItem;
  }

  public toAddCartItem(): ITypedAddCartItem {

    return {
      brandId: this._brandId,
      comments: this.comments,
      type: this.type,
      subType: this.subType,
      itemno: this.itemno,
      name: this.name,
      description: this.description,
      amount: this.amount,
      options: this.options,
      amountStep: this.amountStep,
      price: this.price,
      fragt: this.fragt,
      externalShopName: this.externalShopName,
      oneDeliveryDoublePallet: this.oneDeliveryDoublePallet,
      oneDeliverySize: this.oneDeliverySize,
    };
  }

  protected abstract calculatePrice(): Promise<number>;

  protected async updateDetails(): Promise<void> {
  }

  protected async generateItems(): Promise<IAddCart | undefined> {
    return undefined;
  }

  private async amountUpdated(): Promise<void> {
    const price = await this.calculatePrice();
    this._pricePer = Math.round(price * 100) / 100;
  }

  private validateAmount(value: number) {
    if (typeof (value) !== 'number' || !isFinite(value))
      throw new Error('Amount must be a finite number.');
    if (value < 0 || value === 0 && !this.isComputed)
      throw new Error('Cannot set amount to <= 0.');
    if (this.amountStep && (value % this.amountStep !== 0)) {
      throw new UserError('Amount must be divisible by ' + this.amountStep, { translationKey: 'Delbart', param: { delenhed: this.amountStep } });
    }
  }
}
