<div class="container">
    <div *ngIf="product" class="row mb-3">

        <div class="col-12">
            <app-breadcrumbs [breadcrumbs]="breadcrumbs" [currentBreadcrumb]="breadcrumbs[productPrefix + product.id]"></app-breadcrumbs>
        </div>

    </div>

    <div *ngIf="product" class="card">
        <div class="d-flex">
            <div>
                <img [src]="product.imageUrl" alt=""/>
            </div>

            <div class="flex-grow-1">
                <div class="card-body">
                    <h5 class="card-title">{{ product.name }}</h5>
                    <app-item-number [product]="product"></app-item-number>

                    <div *ngIf="product.description" [innerText]="product.description" class="alert alert-primary" role="alert"></div>

                    <p *ngIf="product.link">
                        <a [href]="product.link" target="_blank">Yderligere info</a>
                    </p>
                    
                    <app-can-purchase-view>
                        <p class="authorized">
                            <app-product-price [product]="product" [brandId]="brandId"></app-product-price>
                        </p>

                        <app-add-to-basket class="authorized" [product]="product"></app-add-to-basket>
                    </app-can-purchase-view>


                    <div *ngIf="product.relatedProducts && product.relatedProducts.length > 0" class="related-products mt-5">
                        <h5>Relaterede produkter</h5>
                        <a *ngFor="let relatedProduct of product.relatedProducts" class="list-group-item list-group-item-action">
                            <div class="d-flex">
                                <div *ngIf="relatedUrls[productPrefix + relatedProduct.id]"
                                     [routerLink]="relatedUrls[productPrefix + relatedProduct.id].productUrl" class="clickable flex-grow-1 d-flex py-auto">
                                    <span *ngIf="relatedProduct.imageUrl" class="product-image">
                                        <img [src]="relatedProduct.imageUrl" alt=""/>
                                    </span>

                                    <div class="my-auto flex-grow-1">
                                        {{ relatedProduct.name }}
                                        <app-item-number [product]="relatedProduct"></app-item-number>
                                    </div>

                                    <span class="my-auto">
                                        <app-product-price [product]="relatedProduct" [brandId]="relatedUrls[productPrefix + relatedProduct.id].brandId"></app-product-price>
                                    </span>
                                </div>

                                <app-add-to-basket [product]="relatedProduct" class="ms-3 my-auto"></app-add-to-basket>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
