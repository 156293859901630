import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder } from '@angular/forms';

import { IRelatedProductSearchResult } from '../../../../../../../wdcommon/IRelatedProduct';
import { APIService } from '../../../../services';
import { AdminProductsLanguageService } from '../../admin-products-language.service';


@Component({
  selector: 'app-related-products-editor',
  templateUrl: './related-products-editor.component.html',
  styleUrls: ['./related-products-editor.component.css']
})
export class RelatedProductsEditorComponent implements OnInit {

  @Input() productId: number;
  @Input() relatedProducts: UntypedFormArray;

  keyword = 'name';
  data: IRelatedProductSearchResult[] = [];

  constructor(
      private _fb: UntypedFormBuilder,
      private api: APIService,
      private adminProductsLanguageService: AdminProductsLanguageService
  ) {
  }

  ngOnInit() {
  }

  async onChangeSearch(text: string) {
    this.data = await this.api.searchForRelatedProducts(text, this.adminProductsLanguageService.currentLocale.id, this.productId);
  }

  async selectEvent(relatedProduct: IRelatedProductSearchResult) {
    if (this.relatedProducts.value.filter(p => p.id === relatedProduct.id).length === 0)
      this.relatedProducts.push(this._fb.group(relatedProduct));
  }

  removeProduct(relatedProduct: IRelatedProductSearchResult) {
    this.relatedProducts.controls.splice(this.relatedProducts.controls.findIndex(l => l.value.id === relatedProduct.id), 1);
    this.relatedProducts.value.splice(this.relatedProducts.value.indexOf(l => l.id === relatedProduct.id), 1);
  }

}
