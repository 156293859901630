<footer class="up-footer d-flex flex-column mt-5 no-print">
    <div class="container-fluid">

        <div class="up-footer-top d-flex row align-items-center">
            <div class="up-footer-menu col-12 col-sm-6 d-flex flex-wrap">
                <div>
                    <a [innerText]="'NAVBAR.GENERALLY' | translate" [routerLink]="['/faq/general']"></a>
                </div>
                <div>
                    <a [innerText]="'NAVBAR.QUALITY_DESC' | translate" [routerLink]="['/faq/quality']"></a>
                </div>
                <div>
                    <a [innerText]="'NAVBAR.NOTHEGGER_CATALOG' | translate" href="/assets/rz_Katalog_Nothegger_2018_web.pdf" target="nothegger"></a>
                </div>
                <div>
                    <app-schedule-meeting>{{ 'SCHEDULE_MEETING.Book' | translate }}</app-schedule-meeting>
                </div>
            </div>
            <div class="up-footer-contact col-12 col-sm-6 d-flex flex-wrap justify-content-sm-end">
                <div class="d-flex flex-nowrap align-items-center">
                    <fa-icon [icon]="faEnvelope" class="up-footer-icon"></fa-icon> &nbsp;kundeservice@unitpartner.dk
                </div>
                <div class="d-flex flex-nowrap align-items-center">
                    <fa-icon [icon]="faPhoneAlt" class="up-footer-icon"></fa-icon> &nbsp;+45 89 37 77 77
                </div>
                <div class="d-flex flex-nowrap align-items-center">
                    <fa-icon [icon]="faBuilding" class="up-footer-icon"></fa-icon> &nbsp;CVR: 37494054
                </div>
            </div>
        </div>

        <div class="up-footer-bottom">
            <img (click)="gotoUnitPartnerDk()" alt="Unit-Partner" class="clickable"
                 src="https://unitpartner.dk/wp-content/uploads/2021/07/UniPartner_Logo_2021_Negativ-1-e1639995700909-768x232.png"
                 width="200"/>
        </div>
    </div>
</footer>
