<ng-template #selectRepeatType>
    <div class="modal-header">
        <h4 class="modal-title">{{ 'FRONTS.REPEAT' | translate }}</h4>
        <button (click)="modalRef.hide()" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <div class="modal-body">
        <h5>{{ selectedFront.formattedType }}</h5>
        <div><strong translate="Gentag som"></strong>&nbsp;<strong [translate]="'Purewood.Front.' + paintedCategory"></strong></div>
        <div class="d-flex flex-wrap">

            <div *ngFor="let front of paintedFronts" class="mb-3 me-3">
                <div (click)="repeatPurewoodFront(paintedCategory, front.id)" class="card wd-card">
                    <div class="card-body text-center">
                        <img [src]="'/assets/images/fronts/' + front.image" alt="" class="repeat-img">
                        <div [translate]="front.name"></div>
                    </div>
                </div>
            </div>

        </div>

        <div><strong translate="Gentag som"></strong>&nbsp;<strong [translate]="'Purewood.Front.' + woodenCategory"></strong></div>
        <div class="d-flex flex-wrap">

            <div *ngFor="let front of woodenFronts" class="mb-3 me-3">
                <div (click)="repeatPurewoodFront(woodenCategory, front.id)" class="card wd-card">
                    <div class="card-body text-center">
                        <img [src]="'/assets/images/fronts/' + front.image" alt="" class="repeat-img">
                        <div [translate]="front.name"></div>
                    </div>
                </div>
            </div>

        </div>

        <div><strong translate="Gentag som"></strong>&nbsp;<strong [translate]="'Purewood.Front.' + linoleumCategory"></strong></div>
        <div class="d-flex flex-wrap">

            <div *ngFor="let front of linoleumFronts" class="mb-3 me-3">
                <div (click)="repeatPurewoodFront(linoleumCategory, front.id)" class="card wd-card">
                    <div class="card-body text-center">
                        <img [src]="'/assets/images/fronts/' + front.image" alt="" class="repeat-img">
                        <div [translate]="front.name"></div>
                    </div>
                </div>
            </div>

        </div>

        <div><strong translate="Gentag som"></strong>&nbsp;<strong [translate]="'Purewood.Front.' + veneerCategory"></strong></div>
        <div class="d-flex flex-wrap">

            <div *ngFor="let front of veneerFronts" class="mb-3 me-3">
                <div (click)="repeatPurewoodFront(veneerCategory, front.id)" class="card wd-card">
                    <div class="card-body text-center">
                        <img [src]="'/assets/images/fronts/' + front.image" alt="" class="repeat-img">
                        <div [translate]="front.name"></div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="modal-footer">
        <button (click)="modalRef.hide()" class="btn btn-secondary" data-dismiss="modal" translate="UTILITY.CANCEL" type="button"></button>
    </div>
</ng-template>
