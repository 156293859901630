<div *ngIf="order" class="container">
    <div *ngIf="orderDetailsBreadcrumb" class="row mb-3">

        <app-breadcrumbs [currentBreadcrumb]="orderDetailsBreadcrumb"></app-breadcrumbs>

    </div>

    <app-order-layout-header [order]="order"></app-order-layout-header>

    <div class="row">

        <div class="col-12 text-end">
            <button (click)="getPDF()" class="btn btn-sm btn-primary">
                Download PDF &nbsp;&nbsp;<fa-icon [icon]="faFilePdf"></fa-icon>
            </button>
        </div>

    </div>

    <app-order-layout-body [items]="order.contents" [viewMode]="IOrderLayoutViewMode.order"></app-order-layout-body>

    <div class="mx-3">

        <div class="row py-2 border-top border-primary">
            <div class="col-9 text-end " translate="ORDERS.SHIPPING"></div>
            <div class="col-3 text-end fw-bold text-nowrap">
                {{ order.shipping | number: '1.2-2'}} {{ 'USED_CURRENCY' | translate }}
            </div>
        </div>

        <ng-container *ngIf="order.fees">
            <ng-container *ngFor="let fee of order.fees">
                <div *ngIf="fee[feeTypes.oneDelivery]" class="row py-2">
                    <div class="col-9 text-end" translate="CART.OneDelivery"></div>
                    <div class="col-3 text-end fw-bold text-nowrap">
                        {{ fee[feeTypes.oneDelivery] | number: '1.2-2'}} {{ 'USED_CURRENCY' | translate }}
                    </div>
                </div>
                <div *ngIf="fee[feeTypes.hettichMinOrder]" class="row py-2">
                    <div class="col-9 text-end" translate="CART.Min_Hettich_addon"></div>
                    <div class="col-3 text-end fw-bold text-nowrap">
                        {{ fee[feeTypes.hettichMinOrder] | number: '1.2-2'}} {{ 'USED_CURRENCY' | translate }}
                    </div>
                </div>
                <div *ngIf="fee[feeTypes.purewoodColour]" class="row py-2">
                    <div class="col-9 text-end">{{ 'Purewood.ColourStartup.Fee' | translate }} {{ fee.variant }}</div>
                    <div class="col-3 text-end fw-bold text-nowrap">
                        {{ fee[feeTypes.purewoodColour] | number: '1.2-2'}} {{ 'USED_CURRENCY' | translate }}
                    </div>
                </div>
            </ng-container>
        </ng-container>

        <div *ngIf="additionalItemsBreakageFees && additionalItemsBreakageFees.count" class="row py-2">
            <div class="col-9 text-end " translate="CART.breakageFee"></div>
            <div class="col-3 text-end fw-bold text-nowrap">
                {{ additionalItemsBreakageFees.count * additionalItemsBreakageFees.price | number: '1.2-2' }} {{ 'USED_CURRENCY' | translate }}
            </div>
        </div>

        <ng-container *ngIf="!order.company.vat">
            <div class="row py-2">
                <h4 class="col-9 text-end fw-bold " translate="ORDERS.TOTAL_EX_VAT"></h4>
                <h4 class="col-3 text-end fw-bold text-nowrap">
                    {{ order.exVat | number: '1.2-2'}} {{ 'USED_CURRENCY' | translate }}
                </h4>
            </div>
        </ng-container>
        <ng-container *ngIf="order.company.vat">
            <div class="row py-2">
                <div class="col-9 text-end " translate="ORDERS.TOTAL_EX_VAT"></div>
                <div class="col-3 text-end fw-bold text-nowrap">
                    {{ order.exVat | number: '1.2-2'}} {{ 'USED_CURRENCY' | translate }}
                </div>
            </div>
            <div class="row py-2">
                <h4 class="col-9 text-end fw-bold">{{ 'ORDERS.TOTAL_INCL_VAT' | translate | uppercase }}</h4>
                <h4 class="col-3 text-end fw-bold text-nowrap">
                    {{ order.exVat * 1.25 | number: '1.2-2'}} {{ 'USED_CURRENCY' | translate }}
                </h4>
            </div>
        </ng-container>

    </div>

</div>
