import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appPostalCode]'
})
export class PostalCodeDirective {
  // Only want positive integers
  private regex: RegExp = new RegExp(/^\d+$/g);
  private maxLength = 6;
  // Allow key codes for special events Backspace, tab, end, home
  private specialKeys = ['Backspace', 'Tab', 'End', 'Home', 'Enter', 'ArrowLeft', 'ArrowRight', 'Clear', 'Copy', 'Paste', 'Delete'];

  constructor(private el: ElementRef) {
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) > -1) {
      return;
    }
    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);
    if (next && !String(next).match(this.regex) || next.length >= this.maxLength) {
      event.preventDefault();
    }
  }
}
