import { Component, HostBinding, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FieldComponentBase } from '../FieldComponentBase';

@Component({
  selector: 'app-input',
  template: `
      <div class="mb-3">
          <label *ngIf="field.label" [for]="field.name" class="form-label">{{ translate('fieldLabel', field.label) }}</label>
          <div [formGroup]="group" class="input-group">
              <span *ngIf="field.prefix" class="input-group-text">{{ translate('prefix', field.prefix) }}</span>
              <input [id]="field.name" [formControlName]="field.name" [max]="field.max" [min]="field.min" [placeholder]="translate('placeholder', field.placeholder)" [type]="field.inputType" class="form-control">
              <span *ngIf="field.suffix" class="input-group-text">{{ translate('suffix', field.suffix) }}</span>
              <ng-container *ngFor="let validation of field.validations;">
                  <div [class.d-block]="group.get(field.name).touched && group.get(field.name).hasError(validation.name)" class="invalid-feedback w-100">
                      {{ translate('validations', validation.message) }}
                  </div>
              </ng-container>
          </div>
          <small *ngIf="field.helptext" [ngClass]="field.helptextClass" class="form-text text-muted">{{ translate('helptext', field.helptext) }}</small>
          <small *ngIf="field.helpHTML" [innerHTML]="translate('helptext', field.helpHTML)" [ngClass]="field.helptextClass" class="form-text text-muted"></small>
      </div>`
})
export class InputComponent extends FieldComponentBase implements OnInit {

  @HostBinding('class') class: string;

  constructor(translateService: TranslateService) {
    super(translateService);
  }

  ngOnInit() {
    this.class = this.field.classes || 'col-sm';
  }

}
