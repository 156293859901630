<h3>{{ 'CABINET_CARCASS.BASE_UNIT' | translate }}</h3>
<figure class="figure float-end text-center">
  <img [alt]="'CABINET_CARCASS.NotchedBack' | translate" src="/assets/images/cabinet-carcass/genutet.png"/>
  <figcaption [innerHTML]="'CABINET_CARCASS.NotchedBack' | translate" class="figure-caption"></figcaption>
</figure>
<img [alt]="'CABINET_CARCASS.BASE_UNIT' | translate" src="/assets/images/cabinet-carcass/US.png"/>
<app-dynamic-form (change)="handleFormChanged()" [autoFocusFirst]="true" [rows]="model"></app-dynamic-form>
<hr/>
<div class="text-end">
  <app-amount-step (approved)="amountApproved($event)" [(amount)]="amount" [disable]="formIsInvalid"></app-amount-step>
</div>
