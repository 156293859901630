import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { getDatetimeString, GlobalContentDesign, GlobalContentType, IGlobalContent } from '../../../../../../wdcommon/IGlobalContent';
import { APIService, ImageService, Locale, LocaleService } from '../../../services';
import { Breadcrumb, defaultBreadcrumbs } from '../../../utils/breadcrumbs/breadcrumbs';
import { TranslatingBase } from '../../../base-component/ComponentBase';


@Component({
  selector: 'app-global-content-editor',
  templateUrl: './global-content-editor.component.html',
  styleUrls: ['./global-content-editor.component.css']
})
export class GlobalContentEditorComponent extends TranslatingBase implements OnInit {
  typeEnum = GlobalContentType;

  globalContentBreadcrumb: Breadcrumb;

  content: IGlobalContent;
  designs = [
    GlobalContentDesign.default,
  ];
  storedContent: string;

  error?: string;
  waiting = true;

  languages: Locale[];

  typeHasText = true;
  typeHasRouterLink = false;
  baseUrl: string;

  typeHasImage = false;
  imgUrl: string;
  newImgUrl: string;
  imgFile: File;
  newImgFile: File;

  typeHasLogo = false;
  logoUrl: string;
  newLogoUrl: string;
  logoFile: File;
  newLogoFile: File;

  constructor(
      private apiService: APIService,
      private imageService: ImageService,
      private localeService: LocaleService,
      private location: Location,
      private route: ActivatedRoute,
      private translateService: TranslateService,
  ) {
    super(translateService);
  }

  ngOnInit() {
    this.baseUrl = window.location.href.replace(this.location.path(), '');
    this.languages = this.localeService.getSupportedLanguages();
    this.route.params.subscribe((params) => {
      this.apiService.getGlobalContent(params.type, params.key)
          .then((globalContent) => {
            this.globalContentBreadcrumb = {
              name: this.translate('GlobalContent.editor') + ': ' + params.key + ' [' + params.type + ']',
              url: defaultBreadcrumbs.adminGlobalContent.url + '/' + params.type + '/' + params.key,
              parentId: 'adminGlobalContent',
            };

            if (!globalContent.draft) {
              globalContent.draft = {};
            }
            this.languages.forEach((l) => {
              if (!globalContent.draft[l.id]) {
                switch (globalContent.type) {
                  case GlobalContentType.deliveryInfo:
                  case GlobalContentType.news:
                  case GlobalContentType.productType:
                    globalContent.draft[l.id] = {};
                    break;
                  default:
                    globalContent.draft[l.id] = '';
                }
              }
            });

            if (globalContent.type === GlobalContentType.news) {
              this.designs.push(GlobalContentDesign.fullImage);
            }
            this.content = globalContent;
            this.typeHasImage = [GlobalContentType.productType].includes(globalContent.type);
            this.typeHasLogo = [GlobalContentType.productType].includes(globalContent.type);
            this.typeHasRouterLink = [GlobalContentType.productType, GlobalContentType.news].includes(globalContent.type);
            this.storedContent = JSON.stringify(this.content);

            if (this.typeHasImage || this.typeHasLogo) {
              const promises: Promise<any>[] = [];
              promises.push(this.typeHasImage ? this.loadImage(globalContent) : Promise.resolve());
              promises.push(this.typeHasLogo ? this.loadLogo(globalContent) : Promise.resolve());
              Promise.all(promises)
                  .then(() => this.alterDesign());
            } else {
              this.alterDesign();
            }
          });
    });
  }

  async updateContent(publish = false) {
    this.waiting = true;
    if (publish) {
      this.content.public = this.content.draft;
      this.content.lastPublished = getDatetimeString(new Date());
    }
    await this.apiService.setGlobalContent(this.content);
    if (this.typeHasImage) {
      if (this.newImgFile && this.newImgUrl !== this.imgUrl) {
        await this.apiService.saveGlobalContentImage(this.newImgFile, this.content.type, this.content.key);
        this.imgFile = this.newImgFile;
        this.imgUrl = this.newImgUrl;
      } else if (!this.newImgUrl && this.imgFile) {
        await this.apiService.deleteGlobalContentImage(this.content.type, this.content.key);
      }
    }
    if (this.typeHasLogo) {
      if (this.newLogoFile && this.newLogoUrl !== this.logoUrl) {
        await this.apiService.saveGlobalContentImage(this.newLogoFile, this.content.type, this.content.key + '-logo');
        this.logoFile = this.newLogoFile;
        this.logoUrl = this.newLogoUrl;
      } else if (!this.newLogoUrl && this.logoFile) {
        await this.apiService.deleteGlobalContentImage(this.content.type, this.content.key + '-logo');
      }
    }
    this.storedContent = JSON.stringify(this.content);
    this.waiting = false;
  }

  async onFileChosen(event: Event): Promise<void> {
    const { imgFile, imgUrl } = await this.imageService.onFileChosen(event);
    if (imgFile !== null && imgUrl !== null) {
      this.newImgFile = imgFile;
      this.newImgUrl = imgUrl;
    }
  }

  async onLogoChosen(event: Event): Promise<void> {
    const { imgFile, imgUrl } = await this.imageService.onFileChosen(event);
    if (imgFile !== null && imgUrl !== null) {
      this.newLogoFile = imgFile;
      this.newLogoUrl = imgUrl;
    }
  }

  updateDisabled() {
    return JSON.stringify(this.content) === this.storedContent;
  }

  publishDisabled() {
    return this.updateDisabled() &&
        JSON.stringify(this.content.draft) === JSON.stringify(this.content.public) &&
        (!this.newImgUrl || this.imgUrl === this.newImgUrl) &&
        (!this.newLogoUrl || this.logoUrl === this.newLogoUrl);
  }

  alterDesign() {
    this.waiting = true;
    console.log(this.content);
    if (this.content.type === GlobalContentType.news) {
      if (this.content.design === GlobalContentDesign.default) {
        this.typeHasImage = false;
        this.typeHasText = true;
        this.waiting = false;
      } else {
        this.typeHasText = false;
        this.typeHasImage = true;
        this.loadImage(this.content)
            .then(() => this.waiting = false);
      }
    } else {
      this.waiting = false;
    }
  }

  private async loadImage(globalContent: IGlobalContent) {
    return this.apiService.getGlobalContentImage(globalContent.type, globalContent.key)
        .then((image) => {
          if (image === null) {
            return;
          }
          this.imgUrl = this.newImgUrl = image.encImg;
          this.imageService.urlToFile(this.imgUrl, 'globalContentImage')
              .then((imgFile) => {
                this.imgFile = imgFile;
              });
        });
  }

  private async loadLogo(globalContent: IGlobalContent) {
    return this.apiService.getGlobalContentImage(globalContent.type, globalContent.key + '-logo')
        .then((image) => {
          if (image === null) {
            return;
          }
          this.logoUrl = this.newLogoUrl = image.encImg;
          this.imageService.urlToFile(this.logoUrl, 'globalContentLogo')
              .then((imgFile) => {
                this.logoFile = imgFile;
              });
        });

  }
}
