enum ImageMimeType {
  gif = 'image/gif',
  jpeg = 'image/jpeg',
  png = 'image/png',
  webp = 'image/webp',
  error = 'text/plain',
}

export function getImageMimeFromDataString(dataString: string): ImageMimeType {
  const mimeIdentifier = dataString.charAt(0);
  if (dataString && mimeIdentifier) {
    if (mimeIdentifier === '/') {
      return ImageMimeType.jpeg;
    } else if (mimeIdentifier === 'R') {
      return ImageMimeType.gif;
    } else if (mimeIdentifier === 'i') {
      return ImageMimeType.png;
    } else if (mimeIdentifier === 'U') {
      return ImageMimeType.webp;
    }
  }

  console.warn('Unknown image mime type',  {
    mimeIdentifier,
    dataString,
  });
  
  return ImageMimeType.error;
}
