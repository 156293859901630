import { TranslateService } from '@ngx-translate/core';


export class TranslatingBase {

  constructor(
      private _translateService: TranslateService,
  ) {
  }

  public translate(key: string | Array<string>, interpolateParams?: Object) {
    if (typeof key !== 'string') {
      console.warn(key, typeof key, 'Key is not a string');
      return '';
    }

    const translated = this._translateService.instant(key, interpolateParams);
    if (typeof translated !== 'string') {
      console.warn(key, translated, typeof translated, 'Translated is not a string');
      return '';
    }

    return translated;
  }

}
