import { Component, HostBinding, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FieldComponentBase } from '../FieldComponentBase';

@Component({
  selector: 'app-checkbox',
  template: `
  <div class="form-check" [formGroup]="group" [class]="field.containerClass">
    <input class="form-check-input" [formControlName]="field.name" type="checkbox" value="" [id]="field.name">
    <label class="form-check-label" [for]="field.name">
      {{ translate('fieldLabel', field.label) }}
    </label>
  </div>
  `,
  styles: []
})
export class CheckboxComponent extends FieldComponentBase implements OnInit {

  @HostBinding('class') class;

  constructor(translateService: TranslateService) {
    super(translateService);
  }

  ngOnInit() {
    this.class = this.field.classes || 'col-sm';
  }

}
