import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { APIService, CartService, ModelLoaderService } from '../../services';
import { defaultBreadcrumbs, productsUrl } from '../../utils/breadcrumbs/breadcrumbs';
import { OtherProductBaseComponent } from '../../base-component/other-product-base/other-product-base.component';
import { CartItemType, CartSubType, ICareSetOptions, ITypedAddCartItem } from '../../../../../wdcommon/ICartItem';
import { ExternalShopName } from '../../../../../wdcommon/IExternalShop';
import { IItemNumber, OptionProperty, Manufacturer, OtherProductShortName } from '../../../../../wdcommon/IProduct';
import { UserService } from '../../services/user.service';


const productTypes: ICareSetOptions[] = [
  {
    [OptionProperty.articleNumber]: '10.9998',
  },
  {
    [OptionProperty.articleNumber]: '10.9999',
  },
];

@Component({
  selector: 'app-care-set',
  templateUrl: './care-set.component.html',
  styleUrls: ['./care-set.component.css']
})
export class CareSetComponent extends OtherProductBaseComponent implements OnInit {
  protected readonly careSetBreadcrumb = defaultBreadcrumbs.notheggerCareSet;
  protected readonly OtherProductShortName = OtherProductShortName;
  protected readonly productTypes = productTypes;
  protected readonly OptionProperty = OptionProperty;

  constructor(
      private api: APIService,
      private cartService: CartService,
      private modelLoader: ModelLoaderService,
      protected userService: UserService,
      private toastrService: ToastrService,
      private router: Router,
      private translateService: TranslateService,
  ) {
    super(cartService, modelLoader, toastrService, api, router, translateService);
    this.otherProductShortName = OtherProductShortName.careSet;
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadOptions();
  }

  isActive(type: string): boolean {
    return this.form !== undefined && this.form.getControl(OptionProperty.articleNumber).value === type;
  }

  setType(type: string) {
    const control = this.form.getControl(OptionProperty.articleNumber);
    if (control && control.value !== type) {
      control.setValue(type === undefined ? null : type);
      this.handleFormChanged();
    }
  }
  protected async finalizeOrder() {
    if (!this.cartService.checkCanAddToCartAndDisplayError()) {
      return;
    }

    const cartItemOptions: ICareSetOptions = {
      [OptionProperty.articleNumber]: this.form.getControl(OptionProperty.articleNumber).value,
    };

    const shortType = OtherProductShortName.careSet + '.' + cartItemOptions[OptionProperty.articleNumber];
    const itemNo: IItemNumber = await this.api.getVarenr(btoa(shortType));
    const formattedName = this.translate(shortType);

    const orderObj: ITypedAddCartItem = {
      brandId: Manufacturer.nothegger,
      type: CartItemType.extra,
      subType: CartSubType.careSet,
      name: formattedName,
      itemno: itemNo.varenr,
      amount: this.amount,
      options: cartItemOptions,
      externalShopName: ExternalShopName.nothegger
    };

    await this.addToCart(orderObj);
    await this.goto(productsUrl + '/' + Manufacturer.nothegger);
  }

  private loadOptions() {
    for (const row of this.model) {
      for (const field of row.fields || []) {
        if (field && field.name === OptionProperty.articleNumber) {
          field.options = [];
          productTypes.forEach((productTypeEntry) => {
            field.options.push({ label: this.translate(OtherProductShortName.careSet + '.' + productTypeEntry.articleNumber), value: productTypeEntry.articleNumber });
          });
        }
      }
    }
  }

}
