<div class="row">
    <div class="col-12 mt-3 bg-primary text-white p-2 ps-3 body-type-header">
        {{ typeName | uppercase }}
    </div>
</div>
<div class="row bg-primary-light d-none d-md-flex">
    <div [ngClass]="hasDiscount ? 'col-4 col-lg-5 col-xl-7' : 'col-5 col-lg-6 col-xl-8'" translate="OrderLayout.ProductDescription"></div>
    <div *ngIf="hasDiscount" class="col-1 text-end" translate="Rabat"></div>
    <div class="col-7 col-lg-6 col-xl-4">
        <div class="row">
            <div class="col-6 text-end" translate="OrderLayout.AmountAndPrice" *ngIf="isUserLoggedIn"></div>
            <div class="col-6 text-end" translate="OrderLayout.Amount" *ngIf="!isUserLoggedIn"></div>
            <div class="col-6 text-end">{{ 'PRICING.PRICE_TOTAL' | translate }} ({{ 'PRICING.EX_VAT' | translate}})</div>
        </div>
    </div>
</div>
<app-order-layout-body-item (repeatDrawer)="setRepeatDrawer($event)" (repeatFront)="setRepeatFront($event)" *ngFor="let item of items"
                            [discountRow]="hasDiscount" [itemName]="getItemName(item)" [item]="item"
                            [viewMode]="viewMode"></app-order-layout-body-item>
