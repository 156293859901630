<div class="container">
    <div class="row mb-3">
        <div class="col-sm-12 col-lg-4">
            <app-breadcrumbs [currentBreadcrumb]="adminOrdersBreadcrumb"></app-breadcrumbs>
        </div>
        <div class="col-sm-6 col-lg-4 text-end">
            <select #f
                    (change)="filter(f.value)"
                    class="form-control">
                <option value="{{ 'none' }}">{{ 'Alle' }}</option>
                <option *ngFor="let status of orderStatuses"
                        value="{{ status.id }}">{{ status.statusText }}</option>
            </select>
        </div>
        <div class="col-sm-6 col-lg-4">
            <input (keyup)="searchFilter()"
                   [(ngModel)]="searchNeedle"
                   aria-label="Search"
                   class="form-control"
                   placeholder="Søg"
                   type="search">
        </div>
    </div>

    <div class="row">
        <div class="col-sm">
            <table class="table table-striped table-hover orderTable">
                <thead>
                <tr>
                    <th>Ordre ID</th>
                    <th>Ordre Dato</th>
                    <th>Leveringsdato</th>
                    <th>Status</th>
                    <th>Kunde</th>
                </tr>
                </thead>
                <tbody *ngIf="displayOrders.length">
                <tr *ngFor="let order of displayOrders | paginate: { itemsPerPage: 10, currentPage: currentOrderPage }"
                    [ngClass]="{ 'table-warning':order.warning === 'warning', 'table-danger':order.warning === 'severe' }"
                    [routerLink]="['./', order.id]"
                    style="cursor: pointer;">
                    <td>{{ order.orderID }}</td>
                    <td>{{ order.orderDate | date }}</td>
                    <td>{{ order.deliveryDate | date }}</td>
                    <td>{{ order.status.statusText }} &nbsp;&nbsp;
                        <fa-icon *ngIf='order.status.id === 8' [icon]="faStar"></fa-icon>
                        <fa-icon *ngIf='order.status.id === 2' [icon]="faClipboard"></fa-icon>
                        <fa-icon *ngIf='order.status.id === 3' [icon]="faTruck"></fa-icon>
                        <fa-icon *ngIf='order.status.id === 4' [icon]="faCheckCircle"></fa-icon>
                        <ng-container *ngIf="order.externalShopOrders">
                <span *ngFor="let ext of order.externalShopOrders" [title]="ext.shopName + (ext.orderId ? ' ordre ID ' + ext.orderId : '')"
                      class="ms-2">
                  <fa-icon [icon]="faShoppingCart"
                           [ngClass]="ext.orderId ? 'text-success' : (hasExternalError(ext.shopName, order.contents) ? 'text-warning' : '')"></fa-icon>
                </span>
                        </ng-container>
                    </td>
                    <td>{{ order.company.companyName }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div *ngIf="orderStatuses" class="row">
        <div class="col-sm text-end">
            <button (click)="modalRef = modalService.show(confirmTransfer, { class: 'modal-md'})"
                    *ngIf="displayOrders.length > 0 && (f.value === '8' || f.value ==='0')"
                    class="btn btn-primary">Overfør ordrer
            </button>
        </div>
        <div class="col-sm text-end">
            <pagination-controls (pageChange)="currentOrderPage = $event"
                                 class="order-pagination"
                                 nextLabel="Næste"
                                 previousLabel="Forrige">
            </pagination-controls>
        </div>
    </div>

</div>

<ng-template #confirmTransfer>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">Overfør ordrer til e-conomic</h5>
            <button (click)="modalRef.hide()" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <p>Er du sikker på, at du vil overføre {{ this.displayOrders.length }} ordrer til e-conomic?</p>
        </div>
        <div class="modal-footer">
            <button (click)="transferOrders()"
                    class="btn btn-primary"
                    type="button">Overfør ordrer
            </button>
            <button (click)="modalRef.hide()"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                    type="button">Annullér
            </button>
        </div>
    </div>
</ng-template>
