import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';

import { Locale, LocaleService, SettingService } from '../../services';
import { TranslatingBase } from '../../base-component/ComponentBase';
import { ITextSetting } from '../../../../../wdcommon/ISetting';


@Component({
  selector: 'app-general-editor',
  templateUrl: './general-editor.component.html',
  styleUrls: ['./general-editor.component.css']
})
export class GeneralEditorComponent extends TranslatingBase implements OnInit {
  faCheck = faCheck;

  languages: Locale[];
  generalForm: UntypedFormGroup;
  selectedLang: Locale;
  headline: string;
  keyId: string;

  constructor(
      private languageService: LocaleService,
      private _fb: UntypedFormBuilder,
      private settingService: SettingService,
      private route: ActivatedRoute,
      private toastrService: ToastrService,
      private router: Router,
      private translateService: TranslateService
  ) {
    super(translateService);
  }

  ngOnInit() {
    this.languages = this.languageService.getSupportedLanguages();
    this.generalForm = this._fb.group({
      locale: this._fb.array([])
    });
    this.selectedLang = this.languages.find(l => l.id === 'da');

    if (this.route.snapshot.routeConfig.path.indexOf('faq/general') > -1) {
      this.keyId = 'faq-general';
      this.headline = 'Rediger FAQ - Generelt';
    } else if (this.route.snapshot.routeConfig.path.indexOf('faq/quality') > -1) {
      this.keyId = 'faq-quality';
      this.headline = 'Rediger FAQ - Kvalitetsbeskrivelser';
    } else {
      throw new Error('Unsupported path for FAQ editing');
    }

    this.loadInfo();
  }

  changeLanguage(localeId: string) {
    this.selectedLang = this.languageService.getLanguageById(localeId);
  }

  async save() {
    if (!this.generalForm.valid) {
      return;
    }

    const promises: Promise<any>[] = [];
    this.generalForm.value.locale.forEach((locale) => {
      const setting: ITextSetting = {
        text: locale.text,
      };
      promises.push(this.settingService.setTextSettingByLocale(locale.lang, this.keyId, setting));
    });
    await Promise.all(promises);

    await this.router.navigateByUrl('/');

    return this.toastrService.success(this.translate(this.keyId + '_edited'), null, { timeOut: 10000 });
  }

  loadInfo() {
    this.languages.forEach((lang) => {
      this.settingService.getTextSettingByLocale(lang.id, this.keyId)
          .then((text) => this.addLocale(lang.id, text));
    });
  }

  initFormLocale(locale: string, text: string) {
    return this._fb.group({
      lang: [locale],
      text: [text]
    });
  }

  addLocale(locale: string, info) {
    const localesControl = <UntypedFormArray>this.generalForm.controls['locale'];
    localesControl.push(this.initFormLocale(locale, info.text));
  }
}
