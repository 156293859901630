<div class="row">
    <div class="col-sm-3">
        <canvas #revEzugCanvas height="150" width="275"></canvas>
    </div>
    <div class="col-sm-3">
        <div class="mb-3">
            <label class="form-label" for="ab">A-B</label>
            <div class="input-group">
                <input [(ngModel)]="revEzugMaal.ab" [disabled]="isLocked" class="form-control" id="ab" min="50" placeholder="A-B" type="number">
                <span class="input-group-text">mm</span>
            </div>
        </div>
        <div>
            <label class="form-label" for="cd">C-D</label>
            <div class="input-group">
                <select [(ngModel)]="revEzugMaal.cd" [disabled]="isLocked" class="form-control" id="cd">
                    <option value="null">Vælg CD</option>
                    <option *ngFor="let o of revEzugHoejder" value="{{o.value}}">{{o.label}}</option>
                </select>
                <span class="input-group-text">mm</span>
            </div>
        </div>
        <br>
    </div>
</div>
