<a *ngIf="_colourSet.length" [outsideClick]="true" [popover]="pickColor" placement="right" [style.background]="colour.success ? colour.HEX : 'transparent'"
   [style.border-style]="colour.success ? 'solid' : 'dashed'" class="btn border-dark" containerClass="widePopover">
    <span [style.color]="getContrast(colour.success ? colour.HEX : fallbackHex)">{{ colour.success ? colour.Name : 'CHOOSE_NCS_COLOR' | translate }}</span>
</a>

<div *ngIf="_colourSet.length === 0" class="input-group">
    <div [style.background]="colour.success ? colour.HEX : fallbackHex" class="input-group-text">
        {{ colour.success ? '&nbsp;' : '?'}}
    </div>
    <input (focusout)="updateValue()" (keyup)="parseAsYouType($event)" [placeholder]="'COLOR_PICKER.NCS.EnterNcsCode' | translate"
           [style.border-style]="colour.success ? 'solid' : 'dashed'" [value]="colour.NCS ? colour.NCS : ''" aria-label="NCS" class="form-control"
           type="text">
</div>

<ng-template #pickColor>
    <div class="colours-container">
        <div (click)="selectColourFromSet(colour)" *ngFor="let colour of _colourSet" [style.background]="colour.HEX" class="colour-item p-2 rounded">
            <span [style.color]="getContrast(colour.HEX)">
                {{ colour.Name }}<br>
                <small>
                    {{ colour.NCS }}
                    <ng-container *ngIf="colour.LRV"><br>{{ colour.LRV }}</ng-container>
                </small>
            </span>
        </div>
    </div>
</ng-template>
