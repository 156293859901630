<div class="bgimage"></div>

<div class="d-flex align-items-center justify-content-end min-h-100vh">
    <div class="card shadow-sm p-3 bg-white rounded mx-auto">
        <div class="card-body">
            <div class="text-center">
                <img alt="Unit-Partner Shop" class="up-logo-img" src="assets/images/UniPartner_Shop-Logo.png">
            </div>

            <div *ngIf="actionText" class="border-success action-text mx-auto">
                {{ actionText }}
                <div *ngIf="newUserCreated" class="pt-3">
                    <app-schedule-meeting>{{ 'SCHEDULE_MEETING.DoBookMeeting' | translate }}</app-schedule-meeting>
                </div>
            </div>

            <form (ngSubmit)="loginUser()" [formGroup]="loginForm" class="form-signin">

                <label class="visually-hidden form-label" for="email">{{ 'CMPADM.Email' | translate }}</label>
                <input [placeholder]="'CMPADM.Indtast email' | translate" autofocus class="form-control" formControlName="email" id="email" required
                       type="email">
                <label class="visually-hidden form-label" for="password">{{ 'SIGNUP.Password' | translate }}</label>
                <input [placeholder]="'SIGNUP.Password' | translate" class="form-control" formControlName="password" id="password" required
                       type="password">
                <div class="d-grid">
                    <button *blockUI='blockLoginBtn' class="btn btn-lg btn-success" type="submit">{{ 'LOGIN.LOGIN' | translate }}</button>
                </div>
                <div class="d-flex justify-content-between flex-nowrap mt-3">
                    <div class="d-grid">
                        <a (click)="modalRef = modalService.show(lostPassword)"
                           class="btn btn-primary btn-sm text-white">{{ 'LOGIN.FORGOT_PASSWORD' | translate }}</a>
                    </div>
                    <div class="mx-3 d-grid">
                        <a [routerLink]="['/signup']" class="btn btn-primary btn-sm">{{ 'LOGIN.REGISTER_NEW' | translate }}</a>
                    </div>
                    <div>
                        <a [routerLink]="['/']" class="btn btn-primary btn-sm">{{ 'LOGIN.VisitAsGuest' | translate }}</a>
                    </div>
                </div>
                <div class="mt-5 d-flex flex-nowrap justify-content-between">
                    <p class="text-muted">
                        <a [outsideClick]="true" [popover]="selectLanguage" href="javascript:void(0)" translate="PROFILE.LANGUAGE"></a>
                    </p>
                    <p class="text-muted text-end">&copy; 2023 - <a href="https://unitpartner.dk" target="_blank">Unit-Partner ApS</a></p>
                </div>
            </form>
        </div>
    </div>

</div>

<ng-template #selectLanguage>
    <select (change)="setLanguage()" [(ngModel)]="preferredLanguage">
        <option value="da">Dansk</option>
        <option value="en">English</option>
        <option value="sv">Svenska</option>
        <option value="no">Norsk</option>
    </select>
</ng-template>

<ng-template #lostPassword>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" translate="LOGIN.GlemtPasswordModal.title"></h5>
            <button (click)="modalRef.hide()" aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
        </div>
        <div class="modal-body">
            <span translate="LOGIN.GlemtPasswordModal.text"></span><br><br>
            <input (keydown.enter)="sendLostPasswordEmail()" [(ngModel)]="restoreEmail" [placeholder]="'LOGIN.GlemtPasswordModal.Email' | translate"
                   class="form-control" type="email">
        </div>
        <div class="modal-footer">
            <button (click)="sendLostPasswordEmail()" class="btn btn-primary" translate="LOGIN.GlemtPasswordModal.Send" type="button"></button>
            <button (click)="modalRef.hide()" class="btn btn-secondary" data-dismiss="modal" translate="UTILITY.CANCEL" type="button"></button>
        </div>
    </div>
</ng-template>
