import { Validators } from '@angular/forms';

import { FormRow } from '../../dynamicForm/dynaform.interfaces';
import { StandardTypeOfWoodField } from '../../dynamicForm/standard-fields';

export const MODEL: FormRow[] = [{
  id: 'type-wood-row',
  show: true,
  fields: [{
    type: 'select',
    name: 'productType',
    label: 'Type',
    classes: 'col-sm-6',
    options: [],
    translate: {
      itemLabel: false
    },
    validations: [{
      name: 'required',
      validator: Validators.required,
      message: 'Organiser.required.Type'
    }]
  }, StandardTypeOfWoodField]
}];
