import { Component, HostBinding, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { FieldConfig } from '../../dynaform.interfaces';

@Component({
  selector: 'app-htmlfield',
  template: ``,
  styles: []
})
export class HTMLFieldComponent implements OnInit {

  @HostBinding('class') class;
  @HostBinding('id') name;
  @HostBinding('innerHtml') innerHtml;

  field: FieldConfig;
  group: UntypedFormGroup;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.class = this.field.containerClass;
    this.name = this.field.name;
    this.innerHtml = this.sanitizer.bypassSecurityTrustHtml(this.field.html);
  }

}
