import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { TranslateService } from '@ngx-translate/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { UploadFile, UploadInput, UploadOutput, UploaderOptions } from 'ngx-uploader';
import { Subject } from 'rxjs';

import { PaletteColour, ParsedPaletteColour } from '../../../../../../wdcommon/Colours';
import { Constants } from '../../../../../../wdcommon/Constants';
import {
  CartItemType,
  CartSubType,
  ICartItem,
  IPurewoodFrontOptions,
  IPurewoodLinoleumFrontOptions,
  IPurewoodPaintedFrontOptions,
  IPurewoodWoodenFrontOptions,
  ITypedAddCartItem,
  RepeatCartItem
} from '../../../../../../wdcommon/ICartItem';
import { FrontTypeOptions } from "../../../../../../wdcommon/IFront";
import { IFrontDrillingSetup, IFrontHingeSetup } from "../../../../../../wdcommon/IPrices";
import { FrontCategory, FrontDesign, IFront, IFrontHistory, IOption, Manufacturer, OptionProperty, OtherProductShortName } from '../../../../../../wdcommon/IProduct';
import { IIFreightSetting } from '../../../../../../wdcommon/ISetting';
import { environment } from '../../../../environments/environment';
import { TranslatingBase } from '../../../base-component/ComponentBase';
import { APIService, CartService, SessionService, SettingService, UserService } from '../../../services';
import { Breadcrumb, defaultBreadcrumbs } from '../../../utils/breadcrumbs/breadcrumbs';
import { parseNcs } from '../../../utils/colorConverter';

import { FieldConfig, FormRow } from '../../../dynamicForm/dynaform.interfaces';
import { DynamicFormComponent } from '../../../dynamicForm/dynamic-form/dynamic-form.component';
import { StandardTypeOfWoodField } from '../../../dynamicForm/standard-fields';
import { createTypeOfWoodSurfaceTreatmentSelectionRow } from '../../../dynamicForm/standard-rows';

interface LinoleumColour {
  id: number;
  ncs: string;
  name: string;
  lrv: string;
}

const RalColors: PaletteColour[] = require('../../../utils/ral-color-picker/RalColors.json');
const PurewoodLinoleumColors: LinoleumColour[] = require('./LinoleumColours.json');

const maxDimensions = {
  max_dimension_one: Constants.purewoodFrontMaxSize,
  max_dimension_two: 1250
};

interface FrontOrderTextsAndOptions {
  options: IPurewoodPaintedFrontOptions | IPurewoodWoodenFrontOptions | IPurewoodLinoleumFrontOptions;
  name: string;
  description: string;
}

@Component({
  selector: 'app-purewood-front',
  templateUrl: './purewood-front.component.html',
  styleUrls: ['./purewood-front.component.css']
})
export class PurewoodFrontComponent extends TranslatingBase implements OnInit {
  @BlockUI() uploadBlock: NgBlockUI;
  @ViewChild(DynamicFormComponent) dynamicForm: DynamicFormComponent;

  frontsShortName: string;

  parentBreadcrumb: Breadcrumb;
  breadcrumb: Breadcrumb;
  frontCategory: FrontCategory;

  addingToCart = false;
  productOptions: IOption[];
  front: IFront;
  frontForm: UntypedFormGroup;
  dimensionRows: Array<FrontTypeOptions> = [];
  typeOfWood: string;
  freightSetting: IIFreightSetting;
  drillingSetup: IFrontDrillingSetup;
  countryCode: string;

  isLoggedIn: boolean;
  onlyBasicInput = true;
  initialColour: PaletteColour;
  linoleumColours: PaletteColour[] = [];

  uploadOptions: UploaderOptions;
  file: UploadFile;
  uploadInput: EventEmitter<UploadInput>;
  frontDesignFileId: string;

  thicknesses: IOption[];
  gripThicknesses: string[] = [];

  model: FormRow[];
  woodOrTreatmentIsValid: boolean;

  frontType: string

  formWasCreated$: Subject<boolean> = new Subject<boolean>();
  dynamicFormExists = false;
  approveButtonTextId = 'UTILITY.ADD_TO_CART';
  dimensionsAreValid = false;

  editItemNo: string;
  editFront: ICartItem;
  editFrontOptions: IPurewoodPaintedFrontOptions | IPurewoodWoodenFrontOptions | IPurewoodLinoleumFrontOptions;

  protected readonly faPlus = faPlus;
  protected readonly purewoodFrontDefaultMinSize = Constants.purewoodFrontDefaultMinSize;
  protected readonly FrontCategoryEnum = FrontCategory;
  protected readonly FrontDesign = FrontDesign;
  protected readonly OptionProperty = OptionProperty;

  constructor(
    private apiService: APIService,
    private cartService: CartService,
    private formBuilder: UntypedFormBuilder,
    private toastrService: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private session: SessionService,
    private settingService: SettingService,
    private translateService: TranslateService,
    private userService: UserService,
  ) {
    super(translateService);
    this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
  }

  get drilledGripsAvailable() {
    if (this.thicknesses.length === 1) {
      return this.gripThicknesses.includes(this.thicknesses[0].value);
    }
    return this.gripThicknesses.length && this.frontForm.controls[OptionProperty.thickness] && this.gripThicknesses.includes(this.frontForm.controls[OptionProperty.thickness].value);
  }

  async ngOnInit() {
    this.model = undefined;
    this.uploadOptions = { concurrency: 1, allowedContentTypes: ['application/pdf'] };
    this.isLoggedIn = this.userService.isLoggedIn;
    this.countryCode = (this.isLoggedIn) ? this.userService.getUser().company.freightTier : 'dk';

    const [freightSetting, drillingSetup] = await Promise.all([this.settingService.getFreightSetting(), this.apiService.getFrontDrillingSetup()])
    this.freightSetting = freightSetting;
    this.drillingSetup = drillingSetup;

    this.route.params.subscribe(async (params) => {
      if (params.editItemNo) {
        this.approveButtonTextId = this.translate('Gem rettelser');
        this.editItemNo = params.editItemNo;
        this.editFront = this.cartService.findByItemNo(this.editItemNo);
        this.editFrontOptions = this.editFront.options as IPurewoodPaintedFrontOptions | IPurewoodWoodenFrontOptions | IPurewoodLinoleumFrontOptions;
        this.frontType = this.editFrontOptions.frontType;
        this.frontCategory = this.editFrontOptions.category;
        this.typeOfWood = this.editFrontOptions[OptionProperty.typeOfWood];
      } else {
        this.typeOfWood = undefined;
        this.editItemNo = undefined;
        this.frontCategory = params.category;
        this.frontType = params.type;
      }
      await this.loadAndSetupFront();
    });
  }

  getInitialColour(): ParsedPaletteColour {
    return (this.initialColour ? Object.assign(this.initialColour, { success: true }) : {
      HEX: 'transparent',
      Name: '',
      success: false
    });
  }

  async addToCart() {
    if (!this.cartService.checkCanAddToCartAndDisplayError()) {
      return;
    }

    let frontValid = false;
    let thickness: string = null;
    this.addingToCart = true;

    const dimensionsValid = this.validateDimensions();

    switch (this.frontCategory) {
      case FrontCategory.linoleum:
        thickness = this.frontForm.controls[OptionProperty.thickness].value;
        frontValid = this.validateLinoleum(thickness);
        break;

      case FrontCategory.painted:
        thickness = this.front.design === FrontDesign.NO_DESIGN ?
          (this.frontForm.controls[OptionProperty.thickness].value === 'null' ? null : this.frontForm.controls[OptionProperty.thickness].value ?? null) :
          null;
        frontValid = this.validatePainted(thickness);
        break;

      case FrontCategory.veneer:
      case FrontCategory.wooden:
        if (this.thicknesses.length === 1) {
          thickness = this.thicknesses[0].value;
        } else if (this.thicknesses.length > 1) {
          console.warn('More than one wooden or veneer thickness', this.front, this.thicknesses);
        }
        frontValid = this.validateWooden();
        break;
    }

    if (!frontValid || !dimensionsValid) {
      return;
    }

    for await (const dimensionRow of this.dimensionRows) {
      const baseOptions: IPurewoodFrontOptions = {
        category: this.frontCategory,
        design: this.front.design,
        frontType: this.front.id,
        [OptionProperty.frontDrillType]: dimensionRow[OptionProperty.frontDrillType] ?? 'none',
        [OptionProperty.frontGrip]: dimensionRow[OptionProperty.frontGrip] ?? 'none',
        [OptionProperty.frontXGrip]: dimensionRow[OptionProperty.frontXGrip] ?? null,
        [OptionProperty.height]: dimensionRow[OptionProperty.height],
        [OptionProperty.width]: dimensionRow[OptionProperty.width],
      };
      let orderTextsAndOptions: FrontOrderTextsAndOptions;

      switch (this.frontCategory) {
        case FrontCategory.linoleum:
          orderTextsAndOptions = this.getLinoleumOrderTextsAndOptions(dimensionRow, baseOptions, thickness);
          break;

        case FrontCategory.painted:
          orderTextsAndOptions = this.getPaintedOrderTextsAndOptions(dimensionRow, baseOptions, thickness);
          break;

        case FrontCategory.veneer:
        case FrontCategory.wooden:
          orderTextsAndOptions = this.getWoodenOrderTextsAndOptions(dimensionRow, baseOptions);
      }

      const itemHash = btoa(JSON.stringify(orderTextsAndOptions.options));
      const itemNumber = await this.apiService.getVarenr(itemHash);

      const item: ITypedAddCartItem = ({
        brandId: Manufacturer.purewood,
        name: orderTextsAndOptions.name,
        description: orderTextsAndOptions.description,
        itemno: itemNumber.varenr,
        amount: dimensionRow.amount,
        options: orderTextsAndOptions.options,
        type: CartItemType.fronts,
        subType: CartSubType.fronts
      });

      if (this.editItemNo) {
        await this.cartService.removeItem(this.editItemNo);
        await this.cartService.addItem(item, this.editFront.index);
        this.editItemNo = undefined;
      } else {
        await this.cartService.addItem(item);
      }

      // Store to history
      if (this.isLoggedIn) {
        const historyObj: IFrontHistory = {
          contents: JSON.parse(JSON.stringify(orderTextsAndOptions.options)),
          description: item.description,
          formattedType: item.name,
          itemno: itemNumber.varenr,
          manufacturer: Manufacturer.purewood
        };
        await this.apiService.saveFrontHistory(historyObj);
      }
    }

    if (this.editItemNo) {
      this.toastrService.info(this.translate('Bestillingen er rettet!'));
      await this.goto('/cart');
    } else {
      this.toastrService.success(`Varen${this.dimensionRows.length > 1 ? 'e' : ''} er tilføjet til kurven`);
      await this.goto(this.parentBreadcrumb.url);
    }
  }

  addOption(index?: number) {
    if (index === undefined || this.dimensionRows.length === (index + 1)) {
      this.dimensionRows.push({
        [OptionProperty.frontDrillType]: 'none',
        [OptionProperty.frontGrip]: 'none',
        [OptionProperty.frontXGrip]: null,
        [OptionProperty.height]: null,
        [OptionProperty.width]: null,
        amount: null,
        category: this.frontCategory,
        design: this.front.design,
        frontType: this.frontType,
        valid: false,
      });
    }
  }

  deleteOption(index: number) {
    if (this.dimensionRows.length === 1) {
      return this.toastrService.error(this.translate('FRONTS.AT_LEAST_ONE_DIMENSIONS'));
    }
    this.dimensionRows.splice(index, 1);
    this.validateDimensions();
  }

  colourChanged(colour: PaletteColour) {
    this.initialColour = colour;
    this.frontForm.controls.colour.setValue((colour && colour.Name) ? colour.Name : null);
  }

  validateForm() {
    this.woodOrTreatmentIsValid = this.dynamicForm.valid;
  }

  async goto(url: string) {
    await this.router.navigateByUrl(url);
  }

  onUploadOutput(output: UploadOutput): void {
    switch (output.type) {
      case 'addedToQueue':
        this.frontDesignFileId = null;
        if (typeof output.file !== 'undefined') {
          this.file = output.file;
          const reader = new FileReader();
          reader.readAsDataURL(output.file.nativeFile);
        }
        break;

      case 'done':
        this.frontDesignFileId = output.file.response.fileId;
        this.uploadBlock.stop();
        break;

      case 'allAddedToQueue':
        if (this.frontDesignFileId === null) {
          this.startPdfUpload();
        }
        break;

      default:
        break;
    }
  }

  formWasCreated() {
    this.formWasCreated$.next(typeof this.dynamicForm !== 'undefined');
    this.dynamicFormExists = true;
  }

  validateDimensions() {
    return this.dimensionRows.every((x) => {
      if (!x.valid) {
        this.dimensionsAreValid = false;
        return false;
      }

      // Validate option-values set
      if (!x[OptionProperty.width] || !x[OptionProperty.height] || !x.amount) {
        this.toastrService.error(this.translate('FILL_IN_ALL_OPTIONS'), this.translate('FILL_IN_ALL_OPTIONS_TITLE'), { timeOut: 5000 });
        this.addingToCart = false;
        this.dimensionsAreValid = false;
        return false;
      }

      // Validate option-dimensions
      if (x[OptionProperty.width] > maxDimensions.max_dimension_one || x[OptionProperty.height] > maxDimensions.max_dimension_one ||
        (x[OptionProperty.width] > maxDimensions.max_dimension_two && x[OptionProperty.height] > maxDimensions.max_dimension_two)) {
        this.toastrService.error(this.translate('Fronts.dimensionsMax.message', {
          one: maxDimensions.max_dimension_one,
          two: maxDimensions.max_dimension_two
        }), this.translate('Fronts.dimensionsMax.title'), { timeOut: 5000 });
        this.addingToCart = false;
        this.dimensionsAreValid = false;
        return false;
      }

      // Validate height with drilling
      if (x[OptionProperty.frontDrillType] && x[OptionProperty.frontDrillType] !== 'none') {
        let drillingValid = false;
        let drillingSetups: IFrontHingeSetup[] = [{
          hinges: 0,
          max: Constants.purewoodFrontMaxSize,
          min: this.front.minimumSize ?? Constants.purewoodFrontDefaultMinSize,
        }];

        if (x[OptionProperty.frontDrillType].indexOf('hingesIkea') > -1) {
          drillingSetups = this.drillingSetup.hingesIkea;
        } else if (x[OptionProperty.frontDrillType].indexOf('hingesUP') > -1) {
          drillingSetups = this.drillingSetup.hingesUP;
        }

        for (const hingeSetup of drillingSetups) {
          if (hingeSetup.min <= x[OptionProperty.height] && hingeSetup.max >= x[OptionProperty.height]) {
            drillingValid = true;
            break;
          }
        }
        if (!drillingValid) {
          const gripOption = this.productOptions.filter((o) => (o.property === OptionProperty.frontDrillType && o.value === x[OptionProperty.frontDrillType]));
          const drillingText = this.translate(gripOption.length === 1 ? gripOption.pop().label + '.long' : 'LOGIN.Error.Ukendt');
          this.toastrService.error(this.translate('fronts.drilling.invalidDimension.message', {
            drillingText: drillingText,
            height: x[OptionProperty.height],
          }), this.translate('fronts.drilling.invalidDimension.title'), { timeOut: 5000 });
          this.addingToCart = false;
          this.dimensionsAreValid = false;
          return false;
        }
      }

      this.dimensionsAreValid = true;
      return true;
    });
  }

  private async loadAndSetupFront() {
    const frontPromise = this.apiService.getFront(this.frontCategory, this.frontType);
    const productOptionsPromise = this.apiService.getProductOptions();
    const [front, productOptions] = await Promise.all([frontPromise, productOptionsPromise]);
    this.productOptions = productOptions;

    if (!front) {
      console.error('Unknown front id', this.frontCategory, this.frontType, front);
      this.toastrService.error('Unknown front id');
      return this.goto(this.parentBreadcrumb.url);
    }

    // Category specific breadcrumbs
    const capitalisedFrontCategory = this.frontCategory[0].toUpperCase() + this.frontCategory.slice(1);
    const parentBreadcrumbId = Manufacturer.purewood + capitalisedFrontCategory + 'Fronts';
    this.parentBreadcrumb = defaultBreadcrumbs[parentBreadcrumbId];
    this.breadcrumb = {
      nameId: front.name,
      url: this.parentBreadcrumb.url + '/' + front.id,
      parentId: parentBreadcrumbId
    };

    this.frontsShortName = OtherProductShortName.fronts + capitalisedFrontCategory;
    this.front = front;

    switch (this.frontCategory) {
      case FrontCategory.linoleum:
        this.setupLinoleumFront();
        break;

      case FrontCategory.painted:
        this.setupPaintedFront();
        break;

      case FrontCategory.veneer:
      case FrontCategory.wooden:
        this.setupWoodenFront();
        break;

      default:
        console.error('Unknown Purewood front category', this.frontType);
        return this.goto(defaultBreadcrumbs.purewood.url);
    }

    // Are we repeating a front?
    const storedFront = this.session.getValue('repeatPurewoodFront');
    if (this.route.snapshot.routeConfig.path.indexOf('front-repeat') > -1 && storedFront) {
      this.repeatFront(JSON.parse(storedFront) as RepeatCartItem);
    } else if (this.route.snapshot.routeConfig.path.indexOf('front-edit') > -1) {
      // Use the repeat functionality to fill in the edit-details:
      const repeatObject: RepeatCartItem = {
        contents: this.editFrontOptions,
        description: this.editFront.description,
        itemno: this.editItemNo,
        formattedType: this.editFront.name,
      };
      this.repeatFront(repeatObject, this.editFront.amount);
    } else {
      this.addOption();
    }
  }

  private repeatFront(repeatFront: RepeatCartItem, amount = 1) {
    let repeatFrontOptions: IPurewoodFrontOptions;

    switch (this.frontCategory) {
      case FrontCategory.linoleum:
        repeatFrontOptions = this.getLinoleumRepeatFrontOptions(repeatFront.contents as IPurewoodLinoleumFrontOptions);
        break;

      case FrontCategory.painted:
        repeatFrontOptions = this.getPaintedRepeatFrontOptions(repeatFront.contents as IPurewoodPaintedFrontOptions);
        break;

      case FrontCategory.veneer:
      case FrontCategory.wooden:
        repeatFrontOptions = this.getWoodenRepeatFrontOptions(repeatFront.contents as IPurewoodWoodenFrontOptions);
    }

    // Set height, width and amount
    this.dimensionRows.push({
      ...repeatFrontOptions,
      amount: amount,
      valid: true,
    });
  }

  private startPdfUpload() {
    if (!this.file) {
      return this.toastrService.error(this.translate('Fronts.selectPdfFileError.message'), this.translate('Fronts.selectPdfFileError.title'));
    }

    const event: UploadInput = {
      type: 'uploadAll',
      url: environment.apiUrl + '/fronts/own-design',
      method: 'POST',
      headers: {
        Authorization: this.userService.getUserToken()
      }
    };
    this.uploadBlock.start(this.translate('FRONTS.UPLOADING_OWN_DESIGN'));
    this.uploadInput.emit(event);
  }

  // Linoleum
  private setupLinoleumFront() {
    this.generateLinoleumColours();

    this.onlyBasicInput = ![FrontDesign.EDGE_2MM_DESIGN, FrontDesign.EDGE_5MM_DESIGN].includes(this.front.design);

    this.frontForm = this.formBuilder.group({
      colour: [null, [<any>Validators.required]],
      [OptionProperty.thickness]: [null, [<any>Validators.required]],
    });
    this.thicknesses = this.productOptions.filter((o) => (o.property === OptionProperty.thickness && o.types.indexOf(this.frontsShortName) > -1));
    this.gripThicknesses = [this.thicknesses[this.thicknesses.length - 1].value];

    // Linoleum with wooden edges
    if ([FrontDesign.EDGE_2MM_DESIGN, FrontDesign.EDGE_5MM_DESIGN].includes(this.front.design)) {
      const standardTypeOfWoodField: FieldConfig = Object.assign({}, StandardTypeOfWoodField, { classes: 'col-12', label: 'FRONTS.EDGE' });
      this.model = [{
        id: 'wood-row',
        fields: [standardTypeOfWoodField]
      }];
      this.model[0].fields.find((field) => field.name === OptionProperty.typeOfWood)
        .options = this.productOptions.filter((o) => (o.property === OptionProperty.typeOfWood && o.types.indexOf(this.frontsShortName) > -1));
      this.reactOnWoodChanges();
    } else {
      // If it is not a wooden edge, then there is only one thickness; 22mm
      this.thicknesses = [this.thicknesses.pop()];
    }
  }

  private generateLinoleumColours() {
    const colours: PaletteColour[] = [];
    PurewoodLinoleumColors.forEach((colour) => {
      const parsedColour = parseNcs(colour.ncs);
      if (parsedColour.success) {
        colours.push({
          NCS: colour.ncs,
          Name: `${colour.id} ${colour.name}`,
          HEX: parsedColour.HEX,
          LRV: colour.lrv
        });
      }
    });
    this.linoleumColours = colours;
  }

  private getLinoleumRepeatFrontOptions(repeatLinoleumFrontOptions: IPurewoodLinoleumFrontOptions) {
    if (repeatLinoleumFrontOptions.colour) {
      this.colourChanged(this.linoleumColours.find((colour) => colour.Name === repeatLinoleumFrontOptions.colour));
    }

    const lSubscription = this.formWasCreated$.subscribe((formInitialised) => {
      if (formInitialised) {
        this.dynamicForm.getControl(OptionProperty.typeOfWood).setValue(repeatLinoleumFrontOptions[OptionProperty.typeOfWood]);
        this.validateForm();
        lSubscription.unsubscribe();
      }
    });

    this.frontForm.controls[OptionProperty.thickness].setValue(repeatLinoleumFrontOptions[OptionProperty.thickness]);

    return repeatLinoleumFrontOptions;
  }

  private validateLinoleum(thickness: string) {
    if (!this.frontForm.controls.colour.valid) {
      this.toastrService.error(this.translate('COLOUR_MISSING'), this.translate('COLOUR_MISSING_TITLE'), { timeOut: 5250 });
      this.addingToCart = false;
      return false;
    }

    // const gripPdfIsValid = this.type.design !== FrontDesign.OWN_DESIGN || (typeof this.frontDesignFileId === 'string' && this.frontDesignFileId.length > 0);
    // if (!gripPdfIsValid) {
    //   this.toastrService.error(this.translate('FRONTS.GRIP_DESIGN_MISSING'), { timeOut: 5500 });
    //   this.addingToCart = false;
    //   return false;
    // }

    if ([FrontDesign.EDGE_2MM_DESIGN, FrontDesign.EDGE_5MM_DESIGN].includes(this.front.design) && !this.woodOrTreatmentIsValid) {
      this.toastrService.error(this.translate('FRONTS.EDGE_MISSING'), this.translate('FRONTS.EDGE_MISSING_TITLE'), { timeOut: 5750 });
      this.addingToCart = false;
      return false;
    }

    const thicknessIsValid = thickness && (this.thicknesses.map((t) => t.value)).includes(thickness);
    if (!thicknessIsValid) {
      this.toastrService.error(this.translate('FRONTS.PLEASE_SELECT_THICKNESS'), this.translate('FRONTS.PLEASE_SELECT_THICKNESS_TITLE'), { timeOut: 6000 });
      this.addingToCart = false;
      return false;
    }

    return true;
  }

  private getLinoleumOrderTextsAndOptions(dimensionRow: FrontTypeOptions, baseOptions: IPurewoodFrontOptions, thickness: string): FrontOrderTextsAndOptions {
    let edgeValue: string = null;
    if ([FrontDesign.EDGE_2MM_DESIGN, FrontDesign.EDGE_5MM_DESIGN].includes(this.front.design)) {
      edgeValue = this.dynamicForm.getControl(OptionProperty.typeOfWood).value;
    }
    const linoleumOptions: IPurewoodLinoleumFrontOptions = {
      ...baseOptions,
      colour: this.frontForm.controls.colour.value,
      fileId: (this.gripThicknesses.includes(thickness) && baseOptions[OptionProperty.frontGrip] === 'custom' && this.frontDesignFileId) ? this.frontDesignFileId || null : null,
      [OptionProperty.frontGrip]: this.gripThicknesses.includes(thickness) ? baseOptions[OptionProperty.frontGrip] : 'none',
      [OptionProperty.frontXGrip]: (this.gripThicknesses.includes(thickness) && baseOptions[OptionProperty.frontGrip].indexOf('X') > -1) ? baseOptions[OptionProperty.frontXGrip] : null,
      [OptionProperty.thickness]: thickness,
      [OptionProperty.typeOfWood]: edgeValue,
    };

    const widthAndHeightFormatted = dimensionRow[OptionProperty.width] + 'x' + dimensionRow[OptionProperty.height] + 'x' + parseInt(thickness, 10) + 'mm';
    const name = this.translate(this.front.name) + ', ' +
      this.translate(edgeValue ? 'TREESORTS.' + edgeValue : 'fronts.edge.blackMdf') + ', ' +
      linoleumOptions.colour + ', ' +
      widthAndHeightFormatted;

    const description = this.getOrderLineDescription({
      model: this.translate(this.front.name),
      type: this.translate(edgeValue ? 'TREESORTS.' + edgeValue : 'fronts.edge.blackMdf'),
      color: linoleumOptions.colour,
      width: dimensionRow[OptionProperty.width],
      height: dimensionRow[OptionProperty.height],
      thickness,
      additional: [
        this.getDrillDescription(dimensionRow[OptionProperty.frontDrillType]),
        this.getGripDescription(linoleumOptions[OptionProperty.frontGrip], linoleumOptions[OptionProperty.frontXGrip])
      ]
    })

    return {
      name,
      description: description,
      options: linoleumOptions
    };
  }

  private getOrderLineDescription({
    type,
    model,
    color,
    thickness,
    width,
    height,
    additional,
    surfaceTreatment,
    typeOfWood
  }: {
    type: string;
    model: string;
    color?: string;
    thickness?: string;
    surfaceTreatment?: string;
    typeOfWood?: string;
    width: string | number;
    height: string | number;
    additional?: (string | null)[]
  }): string {
    const description: string[] = [];

    description.push(`${this.translate("fronts.description.type.label")}: ` + type);
    description.push(`${this.translate("fronts.description.model.label")}: ` + model);
    if (surfaceTreatment != null)
      description.push(`${this.translate("fronts.description.surfaceTreatment.label")}: ` + surfaceTreatment);

    if (typeOfWood != null)
      description.push(`${this.translate("fronts.description.typeOfWood.label")}: ` + typeOfWood)

    description.push(`${this.translate("fronts.description.widthAndHeight.label")}: ` + `${width}x${height}mm`);
    if (thickness != null)
      description.push(`${this.translate("fronts.description.thickness.label")}: ` + thickness);

    if (color != null)
      description.push(`${this.translate("fronts.description.color.label")}: ` + color);

    if (additional?.length >= 0)
      description.push(...additional.filter(a => a?.length > 0));

    return description.join("\n");
  }

  // Painted
  private setupPaintedFront() {
    this.onlyBasicInput = ![FrontDesign.OWN_DESIGN, FrontDesign.NO_DESIGN].includes(this.front.design);

    const controlsConfig: { [p: string]: any } = {
      colour: [null, [<any>Validators.required]],
    };
    if (this.front.design === FrontDesign.NO_DESIGN) {
      controlsConfig[OptionProperty.thickness] = [null, [<any>Validators.required]];
    }
    this.thicknesses = this.productOptions.filter((o) => (o.property === OptionProperty.thickness && o.types.indexOf(this.frontsShortName) > -1));
    this.gripThicknesses = [this.thicknesses[this.thicknesses.length - 1].value];
    this.frontForm = this.formBuilder.group(controlsConfig);
  }

  private getPaintedRepeatFrontOptions(repeatPaintedFrontOptions: IPurewoodPaintedFrontOptions) {
    if (this.front.design === FrontDesign.NO_DESIGN && repeatPaintedFrontOptions.design === FrontDesign.NO_DESIGN) {
      // Set thickness
      this.frontForm.controls[OptionProperty.thickness].setValue(repeatPaintedFrontOptions[OptionProperty.thickness]);
    }

    // Set colour
    if (repeatPaintedFrontOptions.colour) {
      const ralColourMatch = repeatPaintedFrontOptions.colour.indexOf('RAL') > -1 ? repeatPaintedFrontOptions.colour.match(/\d+/) : null;
      if (ralColourMatch !== null) {
        const ralColour = parseInt(ralColourMatch[0], 10);
        const ralEntry = RalColors.find((ral) => ral.RAL === ralColour);
        if (ralEntry) {
          this.initialColour = {
            HEX: ralEntry.HEX,
            Name: repeatPaintedFrontOptions.colour,
            RAL: ralEntry.RAL
          };
        }
      } else if (repeatPaintedFrontOptions.colour.indexOf('NCS') > -1) {
        this.initialColour = parseNcs(repeatPaintedFrontOptions.colour);
      } else {
        const fromLinoleumColour = PurewoodLinoleumColors.find((colour) => repeatPaintedFrontOptions.colour.indexOf(colour.id.toString()) > -1);
        if (fromLinoleumColour) {
          const parsedNcs: PaletteColour = parseNcs(fromLinoleumColour.ncs);
          parsedNcs.NCS = parsedNcs.Name;
          this.colourChanged(parsedNcs);
        }
      }
    }

    return repeatPaintedFrontOptions;
  }

  private validatePainted(thickness: string) {
    if (!this.frontForm.controls.colour.valid) {
      this.toastrService.error(this.translate('COLOUR_MISSING'), this.translate('COLOUR_MISSING_TITLE'), { timeOut: 5250 });
      this.addingToCart = false;
      return false;
    }

    const pdfIsValid = this.front.design !== FrontDesign.OWN_DESIGN || (typeof this.frontDesignFileId === 'string' && this.frontDesignFileId.length > 0);
    if (!pdfIsValid) {
      this.toastrService.error(this.translate('FRONTS.OWN_DESIGN_MISSING'), this.translate('FRONTS.OWN_DESIGN_MISSING_TITLE'), { timeOut: 5500 });
      this.addingToCart = false;
      return false;
    }

    const thicknessIsValid = ![FrontDesign.NO_DESIGN].includes(this.front.design) || (thickness !== null);
    if (!thicknessIsValid) {
      this.toastrService.error(this.translate('FRONTS.PLEASE_SELECT_THICKNESS'), this.translate('FRONTS.PLEASE_SELECT_THICKNESS_TITLE'), { timeOut: 5750 });
      this.addingToCart = false;
      return false;
    }

    return true;
  }

  private getPaintedOrderTextsAndOptions(dimensionRow: FrontTypeOptions, baseOptions: IPurewoodFrontOptions, thickness: string): FrontOrderTextsAndOptions {
    const widthAndHeightFormatted = dimensionRow[OptionProperty.width] + 'x' + dimensionRow[OptionProperty.height] + 'mm';
    const name = this.translate('Purewood.Front.painted') + ', ' + this.translate(this.front.name) + ' ' + widthAndHeightFormatted;
    const thicknessFormatted = this.translate('THICKNESS.' + (typeof thickness === 'string' ? thickness : this.thicknesses[this.thicknesses.length - 1].value));
    const paintedOptions: IPurewoodPaintedFrontOptions = {
      ...baseOptions,
      colour: this.frontForm.controls.colour.value,
      fileId: (((this.gripThicknesses.includes(thickness) && baseOptions[OptionProperty.frontGrip] === 'custom') || (this.front.design === FrontDesign.OWN_DESIGN)) && this.frontDesignFileId) ? this.frontDesignFileId || null : null,
      [OptionProperty.frontGrip]: this.gripThicknesses.includes(thickness) ? baseOptions[OptionProperty.frontGrip] : 'none',
      [OptionProperty.frontXGrip]: (this.gripThicknesses.includes(thickness) && baseOptions[OptionProperty.frontGrip].indexOf('X') > -1) ? baseOptions[OptionProperty.frontXGrip] : null,
      [OptionProperty.thickness]: thickness,
    };

    let description: string = this.getOrderLineDescription({
      type: this.translate('Purewood.Front.painted'),
      model: this.translate(this.front.name),
      color: paintedOptions.colour,
      thickness: thicknessFormatted,
      width: dimensionRow[OptionProperty.width],
      height: dimensionRow[OptionProperty.height],
      additional: [
        this.getDrillDescription(dimensionRow[OptionProperty.frontDrillType]),
        this.getGripDescription(paintedOptions[OptionProperty.frontGrip], paintedOptions[OptionProperty.frontXGrip])
      ]
    })

    return {
      name,
      description,
      options: paintedOptions
    };
  }


  // Wooden
  private setupWoodenFront() {
    this.frontForm = this.formBuilder.group({});
    this.thicknesses = this.productOptions.filter((o) => (o.property === OptionProperty.thickness && o.types.indexOf(this.frontsShortName) > -1));
    if (this.thicknesses.length) {
      this.gripThicknesses = [this.thicknesses[this.thicknesses.length - 1].value];
    }

    this.model = [createTypeOfWoodSurfaceTreatmentSelectionRow()];
    this.model[0].fields.find((field) => field.name === OptionProperty.typeOfWood)
      .options = this.productOptions.filter((o) => (o.property === OptionProperty.typeOfWood && o.types.indexOf(this.frontsShortName) > -1));
    this.model[0].fields.find((field) => field.name === OptionProperty.surfaceTreatment)
      .options = this.productOptions.filter((o) => (o.property === OptionProperty.surfaceTreatment && o.types.indexOf(this.frontsShortName) > -1));

    this.reactOnWoodChanges();
  }

  private getWoodenRepeatFrontOptions(repeatWoodenFrontOptions: IPurewoodWoodenFrontOptions) {
    const wSubscription = this.formWasCreated$.subscribe((formInitialised) => {
      if (formInitialised) {
        this.dynamicForm.getControl(OptionProperty.typeOfWood).setValue(repeatWoodenFrontOptions[OptionProperty.typeOfWood]);
        this.dynamicForm.getControl(OptionProperty.surfaceTreatment).setValue(repeatWoodenFrontOptions[OptionProperty.surfaceTreatment]);
        this.validateForm();
        wSubscription.unsubscribe();
      }
    });

    return repeatWoodenFrontOptions;
  }

  private validateWooden() {
    const woodenValid = this.woodOrTreatmentIsValid;
    if (!woodenValid) {
      this.toastrService.error(this.translate('FRONTS.PLEASE_SELECT_WOOD_AND_SURFACE'), this.translate('FRONTS.PLEASE_SELECT_WOOD_AND_SURFACE_TITLE'), { timeOut: 5750 });
      this.addingToCart = false;
    }
    return woodenValid;
  }

  private getWoodenOrderTextsAndOptions(dimensionRow: FrontTypeOptions, baseOptions: IPurewoodFrontOptions): FrontOrderTextsAndOptions {
    const name = this.translate('Purewood.Front.' + this.frontCategory) + ', ' + this.translate(this.front.name) + ' ' + dimensionRow[OptionProperty.width] + 'x' + dimensionRow[OptionProperty.height] + 'mm';
    const typeOfWoodValue = this.dynamicForm.getControl(OptionProperty.typeOfWood).value;
    const surfaceTreatmentValue = this.dynamicForm.getControl(OptionProperty.surfaceTreatment).value;
    const woodenOptions: IPurewoodWoodenFrontOptions = {
      ...baseOptions,
      fileId: (this.thicknesses.length && baseOptions[OptionProperty.frontGrip] === 'custom' && this.frontDesignFileId) ? this.frontDesignFileId || null : null,
      [OptionProperty.frontGrip]: this.thicknesses ? baseOptions[OptionProperty.frontGrip] : 'none',
      [OptionProperty.frontXGrip]: (this.thicknesses && baseOptions[OptionProperty.frontGrip].indexOf('X') > -1) ? baseOptions[OptionProperty.frontXGrip] : null,
      [OptionProperty.surfaceTreatment]: surfaceTreatmentValue,
      [OptionProperty.typeOfWood]: typeOfWoodValue,
    };
    const woodName = this.translate('TREESORTS.' + typeOfWoodValue);
    const surfaceName = this.translate('SURFACES.' + surfaceTreatmentValue);

    const description = this.getOrderLineDescription({
      height: dimensionRow[OptionProperty.height],
      width: dimensionRow[OptionProperty.width],
      model: this.translate('Purewood.Front.' + this.frontCategory),
      type: this.translate(this.front.name),
      surfaceTreatment: surfaceName,
      typeOfWood: woodName,
      additional: [
        this.getDrillDescription(dimensionRow[OptionProperty.frontDrillType]),
        this.getGripDescription(woodenOptions[OptionProperty.frontGrip], woodenOptions[OptionProperty.frontXGrip])
      ]
    });

    return {
      name,
      description,
      options: woodenOptions
    };
  }

  private reactOnWoodChanges() {
    this.formWasCreated$
      .subscribe((formInitialised) => {
        if (formInitialised) {
          this.dynamicForm.getControl(OptionProperty.typeOfWood).valueChanges
            .subscribe((newWood) => {
              this.typeOfWood = newWood;
              this.dimensionRows.forEach((row) => {
                row[OptionProperty.typeOfWood] = newWood;
              });
            });
        }
      });
  }

  private getDrillDescription(frontDrillType: string) {
    if (!frontDrillType || frontDrillType === 'none') {
      return null;
    }

    let formatting = `${this.translate("fronts.description.drilling.label")}: `

    const drillOptions = this.productOptions.filter((o) => (o.property === OptionProperty.frontDrillType && o.value === frontDrillType));
    if (drillOptions.length !== 1) {
      formatting += 'Fejl ' + drillOptions.length;
    } else {
      formatting += this.translate(drillOptions[0].label + '.short');
    }

    return formatting;
  }

  private getGripDescription(frontGrip: string, x?: number) {
    if (!frontGrip || frontGrip === 'none') {
      return null;
    }

    let prefix = `${this.translate('fronts.grip.label')}: `;

    if (frontGrip === 'custom') {
      return prefix + this.translate('Own Design');
    }

    const gripOption = this.productOptions.filter((o) => (o.property === OptionProperty.frontGrip && o.value === frontGrip));
    if (gripOption.length !== 1) {
      console.warn(gripOption);
      return prefix + 'Fejl ' + gripOption.length;
    }


    let description = this.translate(gripOption[0].label + '.long');
    if (frontGrip.indexOf('X') > -1) {
      description = description.replace('X*', 'X = ' + x);
    }

    return prefix + description;
  }

}
