<div class="container">
    <div class="row mb-3">

        <div class="col-12">
            <app-breadcrumbs [currentBreadcrumb]="adminSpecialProductsBreadcrumb"></app-breadcrumbs>
            <!-- WIP, unified breadcrumbs -->
        </div>

        <div class="col-8">
            <div class="d-flex justify-content-start">
                <h3>{{ categoryName }}</h3>
                <div class="mx-2">
                    <button (click)="openEditCategory(categoryId)" *ngIf="!isRoot" [title]="'Redigér ' + categoryName + '-kategori'"
                            class="btn btn-sm btn-secondary" type="button">
                        <fa-icon [icon]="faEdit"></fa-icon>
                    </button>
                </div>
                <app-remove-button (delete)="deleteCategory()" *ngIf="!isRoot" [title]="'Slet ' + categoryName + '-kategori'"></app-remove-button>
            </div>
            <app-product-path [categoryId]="categoryId"></app-product-path>
            <div [innerHtml]="categoryDescription"></div>
        </div>
        <div class="col-4 text-end">
            <button class="btn btn-sm btn-primary" routerLink="/admin/products/specials/all">Se alle produkter</button>
            <button (click)="openModal(updateHettichModal)" [disabled]="hettichDisabled" class="btn btn-sm btn-secondary mx-2">
                Opdatér Hettich priser
            </button>
            <app-admin-products-language></app-admin-products-language>
        </div>
    </div>

    <div *ngIf="categories && categories.length === 0 && products && products.length === 0">
        Denne kategori har endnu ingen produkter eller underkategorier.
    </div>

    <div *ngIf="categories && routePrefix" class="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-5 g-4 mb-3">
        <div *ngFor="let category of categories" class="col">
            <a [routerLink]="routePrefix + category.id" class="card wd-card h-100 clickable">
                <div class="card-header">{{ category.name }}</div>
                <div class="card-body d-flex align-items-center justify-content-center">
                    <img *ngIf="category.imageUrl" [src]="category.imageUrl" alt="" class="img-fluid"/>
                </div>
            </a>
        </div>
    </div>

    <div *ngIf="products" class="list-group mb-3">
        <a *ngFor="let product of products" class="list-group-item">
            <div class="d-flex">
                <div class="flex-grow-1 d-flex py-auto">
                    <span class="product-image">
                        <img [src]="product.imageUrl" alt=""/>
                    </span>

                    <div class="my-auto flex-grow-1">
                        {{ product.name }}
                        <app-item-number [product]="product"></app-item-number>
                    </div>

                    <span class="my-auto">
                        <app-product-price [product]="product" brandId="rawPrice"></app-product-price>
                    </span>
                </div>

                <div class="my-auto">
                    <button (click)="editProduct(product)" class="btn btn-sm ms-3">
                        <fa-icon [icon]="faEdit"></fa-icon>
                    </button>
                    <button (click)="copyProduct(product)" class="btn btn-sm ms-3">
                        <fa-icon [icon]="faCopy"></fa-icon>
                    </button>
                    <app-remove-button (delete)="deleteProduct(product)" class="ms-3"></app-remove-button>
                </div>
            </div>
        </a>
    </div>

    <div class="d-flex justify-content-between mb-3">
        <button (click)="openModal(addCategoryModal)" class="btn btn-primary ms-auto me-2">
            {{ 'Tilføj kategori' | translate }}&nbsp;
            <fa-icon [icon]="faPlus"></fa-icon>
        </button>

        <button (click)="createProduct()" [hidden]="isRoot" class="btn btn-primary">
            {{ 'Tilføj produkt' | translate }}&nbsp;
            <fa-icon [icon]="faPlus"></fa-icon>
        </button>
    </div>
</div>

<ng-template #addCategoryModal>
    <div class="modal-header">
        <h4 class="modal-title">{{ 'Tilføj kategori' | translate }}</h4>
        <button (click)="modalRef.hide()" aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
    </div>
    <div class="modal-body">

        <div class="container-fluid">
            <div class="row">
                <div class="col-sm">
                    <h5>{{'Navn' | translate }}</h5>
                </div>
            </div>
            <div class="row">
                <div class="col-sm">
                    <input [(ngModel)]="newCategoryName" appFocus class="form-control" type="text">
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button (click)="addNewCategory()" class="btn btn-success form-control">{{ 'Tilføj kategori' | translate }}
            <fa-icon [icon]="faPlus"></fa-icon>
        </button>
        <button (click)="modalRef.hide()" class="btn btn-secondary" data-dismiss="modal">Annullér</button>
    </div>
</ng-template>

<ng-template #updateHettichModal>
    <div class="modal-header">
        <h4 class="modal-title">Opdatér Hettich priser</h4>
        <button (click)="modalRef.hide()" aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
    </div>
    <div class="modal-body">
        <p>
            <strong>OBS: Hettich priser skal først opdateres af Holion.</strong>
        </p>
        <p>
            Herefter vil priser på Hettich-produkter opretter her blive opdateret.<br/>
            Det er et krav at produktnummer består af et "H" efterfulgt af syv cifre. Fx <code>H1234567</code>.
        </p>
    </div>
    <div class="modal-footer">
        <button (click)="updateHettich()" [disabled]="hettichDisabled" class="btn btn-success">Opdatér priser</button>
        <button (click)="modalRef.hide()" class="btn btn-secondary" data-dismiss="modal">Annullér</button>
    </div>
</ng-template>
