<div class="container">
    <div class="row mb-3">
        <div class="col-sm">
            <h3>{{ 'Rediger kategori' | translate }}</h3>
            <app-product-path [categoryId]="categoryId"></app-product-path>
        </div>
        <div class="col-sm-2 text-end">
            <button appBackButton class="btn btn-primary">
                <fa-icon [icon]="faWindowClose"></fa-icon>
                {{ 'UTILITY.CANCEL' | translate }}
            </button>
        </div>
    </div>

    <div class="row pb-3">
        <div class="col-sm">

            <form (ngSubmit)="updateCategory()" [formGroup]="categoryForm" novalidate>

                <div class="row" formArrayName="locale">
                    <div *ngFor="let locale of categoryForm.get('locale')['controls']; let i = index" class="col-sm-6 mb-4">
                        <app-admin-category-locale (delete)="deleteLocale(locale)" [canBeDeleted]="categoryForm.get('locale')['controls'].length > 1"
                                                   [group]="categoryForm.get('locale')['controls'][i]"></app-admin-category-locale>
                    </div>
                </div>

                <div *ngIf="unsupportedLocales && unsupportedLocales.length > 0" class="mb-4">
                    Tilføj:
                    <button (click)="addLocale(locale)" *ngFor="let locale of unsupportedLocales" class="btn ms-2">
                        {{locale.name}}
                    </button>
                </div>

                <div class="mb-3">
                    <label class="form-label">{{'Sortering (indtast et positivt tal)' | translate}}</label>
                    <input class="form-control" formControlName="order" type="text">
                </div>

                <div>
                    <label class="form-label">{{'Kategoribillede' | translate}}</label>
                    <input (change)="onFileChosen($event)" accept="image/*" class="form-control" id="categoryImage" type="file" value="Vælg fil"/>
                    <div *ngIf="imgUrl" class="col-sm-2 mx-2">
                        <img [src]="imgUrl" alt="" class="NO-CACHE"/>
                    </div>
                </div>
                <div class="col-sm">
                    <hr>
                </div>
                <div class="col-sm text-end">
                    <button class="btn btn-success" type="submit">
                        {{ 'Opdater kategori' | translate }}
                        <fa-icon [icon]="faCheck"></fa-icon>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
