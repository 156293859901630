<div class="row">
    <div class="col-sm-8">
        <canvas #udspCanvas
                width="800"
                height="700"></canvas>
    </div>
    <div class="col-sm-4">
        <dynamic-basic-form [group]="formGroup"
                                (change)="changeHandler($event)"
                                [model]="measuresForm">
        </dynamic-basic-form>
        <hr>

        <div class="alert alert-info"
             role="alert">
            <h5 class="alert-heading">{{ 'Skuffe dimensioner' | translate }}</h5>
            <hr>
            <p>
                {{ translate('SkuffebreddeMm', { skuffeBredde: defaults['width'] }) }}<br> {{ translate('SkuffedybdeMm',
                { skuffeDybde: defaults['depth'] }) }}<br></p>
        </div>

        <div class="alert alert-warning"
             role="alert">
            <h5 class="alert-heading">{{ 'RearUdsparing.PointExpHeader' | translate }}</h5>
            <hr>
            <p>{{ 'RearUdsparing.PointExplanation' | translate }}</p>
        </div>
    </div>

    <!--<div class="alert alert-warning" *ngIf="outOfBounds" role="alert">
      <h5 class="alert-heading">{{ 'Special udsparing' | translate }}</h5>
          <hr>
          <p>{{ 'Udsparing uden for standardmål' | translate }}</p>
    </div>
  </div>-->
</div>
