import { OptionProperty } from "../../../../../wdcommon/IProduct";
import { FormRow } from '../../dynamicForm/dynaform.interfaces';
import {
  createHeightBaseSelectionRow,
  createHrRow,
  createJointSurfaceTreatmentSelectionRow,
  createPremountedCouplingSelectionRow,
  createQualityTypeOfWoodSelectionRow,
  createRunnerDeliverySelectionRow,
  createRunnerWidthSelectionRow,
  createShimsRow,
  createSlideListRow,
} from '../../dynamicForm/standard-rows';
import { DrawerHeightFieldName } from '../../dynamicForm/standard-fields';


export const MODEL: FormRow[] = [
  createQualityTypeOfWoodSelectionRow(),
  createJointSurfaceTreatmentSelectionRow(),
  createRunnerWidthSelectionRow(),
  createRunnerDeliverySelectionRow(),
  createHeightBaseSelectionRow(DrawerHeightFieldName.sideHeight),
  createPremountedCouplingSelectionRow(),
  createShimsRow(),
  createSlideListRow(),
  createHrRow(),
  {
    id: 'config',
    type: 'configuration',
    config: ['udsparing', 'bestikindsats', 'logo', 'greb', OptionProperty.bottomUnmounted, 'skuffe20mmFront', 'ligeOverkant', 'bundSkruet', 'boerstet']
  }
];
