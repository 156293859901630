import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { RepeatCartItem } from '../../../../../../wdcommon/ICartItem';
import { FrontCategory, IFront } from '../../../../../../wdcommon/IProduct';
import { APIService, SessionService } from '../../../services';


@Component({
  selector: 'app-repeat-purewood-front',
  templateUrl: './repeat-purewood-front.component.html',
  styleUrls: ['./repeat-purewood-front.component.css']
})
export class RepeatPurewoodFrontComponent {
  @Input() selectedFront: RepeatCartItem;
  @ViewChild('selectRepeatType') repeatModal: TemplateRef<any>;

  modalRef: BsModalRef;
  linoleumCategory = FrontCategory.linoleum;
  paintedCategory = FrontCategory.painted;
  veneerCategory = FrontCategory.veneer;
  woodenCategory = FrontCategory.wooden;
  linoleumFronts: IFront[];
  paintedFronts: IFront[];
  veneerFronts: IFront[];
  woodenFronts: IFront[];

  constructor(
      private apiService: APIService,
      private modalService: BsModalService,
      private router: Router,
      private session: SessionService,
  ) {
  }

  async repeatPurewoodFront(category: string, type: string) {
    this.session.setValue('repeatPurewoodFront', JSON.stringify(this.selectedFront));
    this.modalRef.hide();
    await this.router.navigateByUrl('products/purewood/front-repeat/' + category + '/' + type);
  }

  show() {
    const paintPromise = this.apiService.getFronts(FrontCategory.painted);
    const woodenPromise = this.apiService.getFronts(FrontCategory.wooden);
    const linoleumPromise = this.apiService.getFronts(FrontCategory.linoleum);
    const veneerPromise = this.apiService.getFronts(FrontCategory.veneer);
    Promise.all([paintPromise, woodenPromise, linoleumPromise, veneerPromise])
        .then(([painted, wooden, linoleum, veneer]) => {
          this.paintedFronts = painted;
          this.woodenFronts = wooden;
          this.linoleumFronts = linoleum;
          this.veneerFronts = veneer;
        })
        .then(() => this.modalRef = this.modalService.show(this.repeatModal, { class: 'modal-lg', }));
  }

}
