<div class="row">
  <div #siphonCuttingCanvasParent class="col-sm-8 pe-4">
    <canvas #siphonCuttingCanvas [height]="canvasHeight" [width]="canvasWidth"></canvas>
  </div>
  <div class="col-sm-4">
    <dynamic-basic-form (change)="changeHandler($event)" [group]="formGroup" [model]="measuresForm"></dynamic-basic-form>
    <hr>

    <div class="alert alert-info" role="alert">
      <h5 class="alert-heading">{{ 'Skuffe dimensioner' | translate }}</h5>
      <hr>
      <p>
        {{ translate('SkuffebreddeMm', {skuffeBredde: defaults['width']}) }}<br>
        {{ translate('SkuffedybdeMm', {skuffeDybde: defaults['depth']}) }}
      </p>
    </div>

    <div *ngIf="outOfBounds" class="alert alert-warning" role="alert">
      <h5 class="alert-heading">{{ 'Special udsparing' | translate }}</h5>
      <hr>
      <p>{{ 'Udsparing uden for standardmål' | translate }}</p>
    </div>
  </div>
</div>
