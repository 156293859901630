<div class="container">
    <div class="row mb-3">

        <div class="col">
            <app-breadcrumbs [currentBreadcrumb]="productTypeBreadcrumb"></app-breadcrumbs>
        </div>

    </div>
    <div class="row">

        <div *ngFor="let brand of brands" [class.clickable]="brand.routerLink" [routerLink]="brand.routerLink" class="col col-lg-6 mb-3">
            <div class="card up-brand-card rounded-0 h-100 pb-0">
                <img *ngIf="brand.imageUrl" [alt]="brand.header" [src]="brand.imageUrl" class="img-fluid">
                <div class="card-body up-brand-card-text w-100 h-100 pt-3 pl-3">
                    <img *ngIf="brand.logoUrl" [alt]="brand.header" [src]="brand.logoUrl" class="img-fluid logo-img mb-3">
                    <h5 *ngIf="!brand.logoUrl && brand.header" [innerHTML]="brand.header" class="card-title"></h5>
                    <p [innerText]="brand.text" class="card-text"></p>
                </div>
            </div>
        </div>

    </div>
</div>
