import { Component, EventEmitter, Input, Output } from '@angular/core';

import { PaletteColour, ParsedPaletteColour } from '../../../../../wdcommon/Colours';
import { getContrastYIQ, parseNcs } from '../colorConverter';


@Component({
  selector: 'app-ncs-color-picker',
  templateUrl: './ncs-color-picker.component.html',
  styleUrls: ['./ncs-color-picker.component.css']
})
export class NcsColorPickerComponent {
  fallbackHex = 'transparent';
  @Input() colour: ParsedPaletteColour = {
    success: false,
    Name: '',
    HEX: this.fallbackHex
  };
  @Output() colourChange = new EventEmitter<ParsedPaletteColour>();

  _colourSet: PaletteColour[] = [];

  constructor() {
  }

  @Input() set colourSet(colours: PaletteColour[]) {
    this._colourSet = colours;
  }

  getContrast(hexColour: string) {
    return getContrastYIQ(hexColour);
  }

  selectColourFromSet(colour: PaletteColour) {
    if (this.colour.Name !== colour.Name) {
      this.colour = Object.assign({success: true}, colour);
      this.colourChange.emit(this.colour);
    }
    document.body.click();
  }

  parseAsYouType(event: KeyboardEvent) {
    this.parseNcsString((event.target as HTMLInputElement).value);
  }

  updateValue() {
    if (this.colour.success) {
      this.colour.NCS = this.colour.Name;
    }
    this.colourChange.emit(this.colour);
  }

  private parseNcsString(ncsString: string) {
    const parsedColour = parseNcs(ncsString);
    if (parsedColour.success) {
      this.colour.HEX = parsedColour.HEX;
      this.colour.success = true;
      if (this.colour.Name !== parsedColour.Name) {
        this.colour.Name = parsedColour.Name;
        this.colourChange.emit(this.colour);
      }
    } else {
      this.colour.HEX = this.fallbackHex;
      this.colour.success = false;
      if (this.colour.Name !== '') {
        this.colour.Name = '';
        this.colourChange.emit(this.colour);
      }
    }
  }
}
