import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { LocaleService } from '../../../services';


@Component({
  selector: 'app-admin-category-locale',
  templateUrl: './admin-category-locale.component.html',
  styleUrls: ['./admin-category-locale.component.css']
})
export class AdminCategoryLocaleComponent implements OnInit {

  @Input('group') group: UntypedFormGroup;
  @Input() canBeDeleted: boolean;

  @Output() delete = new EventEmitter();

  displayLanguage: string;

  constructor(private localeService: LocaleService) {
  }

  ngOnInit() {
    this.displayLanguage = this.localeService.getLanguageById(this.group.value.lang).name;
  }

  deleteLocale() {
    this.delete.emit(null);
  }

}
