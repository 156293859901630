import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';

import { APIService, LocaleService, SettingService } from '../../services';
import { TranslatingBase } from '../../base-component/ComponentBase';
import { IInfoSetting } from '../../../../../wdcommon/ISetting';


@Component({
  selector: 'app-info-editor',
  templateUrl: './info-editor.component.html',
  styleUrls: ['./info-editor.component.css']
})
export class InfoEditorComponent extends TranslatingBase implements OnInit {
  infoForm: UntypedFormGroup;

  protected readonly faCheck = faCheck;

  constructor(
      private _fb: UntypedFormBuilder,
      private toastrService: ToastrService,
      private apiService: APIService,
      private translateService: TranslateService,
      private localeService: LocaleService,
      private settingService: SettingService,
      private router: Router,
  ) {
    super(translateService);
  }

  async ngOnInit() {
    this.infoForm = this._fb.group({
      locale: this._fb.array([])
    });

    await this.loadInfo();
  }

  loadInfo() {
    const locales = this.localeService.getSupportedLanguages();

    const promises = [];
    locales.forEach((locale) => {
      const promise = this.settingService.getInfoSettingByLocale(locale.id)
          .then((info) => this.addLocale(locale.id, info));
      promises.push(promise);
    });
    return Promise.all(promises);
  }

  initFormLocale(locale: string, headline: string, text: string) {
    return this._fb.group({
      lang: [locale],
      headline: [headline],
      text: [text]
    });
  }

  addLocale(locale: string, info: IInfoSetting) {
    const localesControl = <UntypedFormArray>this.infoForm.controls['locale'];
    localesControl.push(this.initFormLocale(locale, info.headline, info.text));
  }

  async showValidationMsg(formGroup: UntypedFormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: UntypedFormControl = <UntypedFormControl>formGroup.controls[key];

        if (Object.keys(control).includes('controls')) {
          const formGroupChild: UntypedFormGroup = <UntypedFormGroup>formGroup.controls[key];
          await this.showValidationMsg(formGroupChild);
        }

        control.markAsTouched();
      }
    }
  }

  async save() {
    if (!this.infoForm.valid) {
      await this.showValidationMsg(this.infoForm);
      return;
    }

    const promises = [];
    this.infoForm.value.locale.forEach((locale) => {
      const info: IInfoSetting = {
        headline: locale.headline,
        text: locale.text,
      };
      promises.push(this.settingService.setInfoSettingByLocale(locale.lang, info));
    });
    await Promise.all(promises);

    await this.router.navigateByUrl('/');

    return this.toastrService.success(this.translate('Info_edited'), null, { timeOut: 10000 });
  }
}
