<div class="container">
  <div class="row">

    <div class="col-12">
      <app-breadcrumbs [currentBreadcrumb]="solidBreadcrumb"></app-breadcrumbs>
    </div>

  </div>
  <div class="row">

    <div class="col-sm-6">
      <app-dynamic-form (change)="validateFormAndUpdateThickness()" [rows]="model"
                        [autoFocusFirst]="true"></app-dynamic-form>
      <div class="col-sm alert alert-danger" *ngIf="checkWidthTypeValid() === false">
        <h5 class="alert-heading">{{'SolidOrganiser.WidthErrorHeader' | translate }}</h5>
        <p>
          {{'SolidOrganiser.WidthErrorBody' | translate }}<br/>
          Min: {{productTypesObject[getType()].varWidth.min}}<br/>
          Max: {{productTypesObject[getType()].varWidth.max}}
        </p>
      </div>
      <div class="col-sm alert alert-danger" *ngIf="isDepthValid() === false">
        <h5 class="alert-heading">{{'SolidOrganiser.DepthErrorHeader' | translate }}</h5>
        <p>
          {{'SolidOrganiser.DepthErrorBody' | translate }}<br/>
          Min: {{productTypesObject[getType()].varDepth.min}}<br/>
          Max: {{productTypesObject[getType()].varDepth.max}}
        </p>
      </div>
      <div class="text-end">
        <app-amount-step [(amount)]="amount" [disable]="formIsInvalid" (approved)="amountApproved($event)"></app-amount-step>
      </div>
    </div>

    <div class="col-sm-6">
      <div class="card">
        <div class="card-body">
          <p class="card-text">{{ 'SolidOrganiser.ProductTypesHeader' | translate }}</p>
          <div class="row">
            <div *ngFor="let productType of productTypesArray" class="col-sm-4 text-center pb-3">
              <div><strong>{{ 'SolidOrganiser.' + productType[0] | translate }}</strong></div>
              <img class="product-type-img"
                   (click)="setType(productType[0])"
                   [ngClass]="{ 'active': isActive(productType[0]) }"
                   [src]="'/assets/images/' + solidOrganiser + '/' + productType[0] + '.png'"
                   [alt]="'SolidOrganiser.' + productType[0] | translate"
              />
              <div>
                <small>
                  {{ 'Bredde' | translate }}: {{ productType[1].varWidth.min }}-{{ productType[1].varWidth.max }} mm
                  <br/>
                  {{ 'Dybde' | translate }}: {{ productType[1].varDepth.min }}-{{ productType[1].varDepth.max }} mm
                </small>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div [innerText]="translate('SolidOrganiser.OptionDescription')"></div>
        </div>
      </div>
    </div>

  </div>
</div>
