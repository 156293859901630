import { Validators } from '@angular/forms';

import { OptionProperty } from '../../../../../wdcommon/IProduct';
import { FormRow } from '../../dynamicForm/dynaform.interfaces';
import { FormRowIds } from '../../dynamicForm/standard-rows';
import { StandardSurfaceTreatmentField } from '../../dynamicForm/standard-fields';

export const MODEL: FormRow[] = [{
  id: 'dimensions-row',
  show: true,
  fields: [{
    type: 'input',
    classes: 'col-sm-6',
    inputType: 'number',
    name: OptionProperty.width,
    min: 120,
    max: 2750,
    suffix: 'mm',
    label: 'Bredde',
    placeholder: 'Indtast bredde',
    validations: [{
      name: 'required',
      validator: Validators.required,
      message: 'Organiser.required.Width'
    }, {
      name: 'min',
      validator: Validators.min(120),
      message: 'Required.Width.Min.120'
    }, {
      name: 'max',
      validator: Validators.max(2750),
      message: 'Required.Width.Max.2750'
    }, {
      name: 'pattern',
      validator: Validators.pattern(/\d+/),
      message: 'Bredde skal indtastes som et heltal'
    }]
  }, {
    type: 'input',
    classes: 'col-sm-6',
    inputType: 'number',
    name: OptionProperty.height,
    label: 'Højde',
    min: 120,
    max: 2750,
    suffix: 'mm',
    placeholder: 'Placeholder.Height',
    validations: [{
      name: 'required',
      validator: Validators.required,
      message: 'Required.Height.entered'
    }, {
      name: 'min',
      validator: Validators.min(120),
      message: 'Required.Height.Min.120'
    }, {
      name: 'max',
      validator: Validators.max(2750),
      message: 'Required.Height.Max.2750'
    }, {
      name: 'pattern',
      validator: Validators.pattern(/\d+/),
      message: 'Required.Height.integer'
    }],
    helptext: 'woodGrainDirectionFollowsHeight',
    translate: { helptext: true }
  }]
}, {
  id: FormRowIds.veneerTreatmentRow,
  show: true,
  fields: [
    {
      type: 'select',
      name: OptionProperty.veneer,
      label: 'VENEER.NAME',
      disabled: false,
      classes: 'col-sm-6',
      options: [],
      validations: [
        {
          name: 'required',
          validator: Validators.required,
          message: 'Required.veneer.entered'
        }
      ]
    },
    StandardSurfaceTreatmentField
  ]
}, {
  id: 'comment-row',
  show: true,
  fields: [{
    type: 'input',
    // rows: 5,
    name: OptionProperty.comment,
    label: 'Comment',
    placeholder: 'Placeholder.Comment',
    classes: 'col-12'
  }]
}];
