export enum KlaviyoRouteEndpoints {
  subscribe = 'subscribe',
  subscribedStatus = 'subscribedStatus',
  unsubscribe = 'unsubscribe',
}

export enum SubscribedState {
  new,
  subscribed,
  unsubscribed,
}

export interface SubscribedStateResponse {
  subscribed: SubscribedState;
  error?: string;
  success: boolean;
}
