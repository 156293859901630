import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';

import { APIService, SettingService } from '../../services';
import { AdminProductsLanguageService } from '../admin-products/admin-products-language.service';
import { Manufacturer } from '../../../../../wdcommon/IProduct';


@Component({
  selector: 'app-admin-ernst-mair',
  templateUrl: './admin-ernst-mair.component.html',
  styleUrls: ['./admin-ernst-mair.component.css']
})
export class AdminErnstMairComponent implements OnInit {
  showDrawers;
  drawers;
  ernstMairDescription: string;

  protected readonly faEdit = faEdit;

  constructor(
      private router: Router,
      private route: ActivatedRoute,
      private adminProductsLanguageService: AdminProductsLanguageService,
      private settingService: SettingService,
      private apiService: APIService) {
  }

  async ngOnInit() {
    this.showDrawers = this.route.snapshot.routeConfig.path.indexOf('drawers') > -1;
    this.adminProductsLanguageService.connectCurrentLocale()
        .subscribe(async (locale) => {
          if (!this.showDrawers) {
            this.ernstMairDescription = (await this.settingService.getTextSettingByLocale(`${locale.id}`, Manufacturer.ernstMair)).text;
          } else {
            this.drawers = await this.apiService.getErnstMairs(`${locale.id}`);
          }
        });
  }

  async goto(drawers?: string) {
    await this.router.navigateByUrl(drawers ? `/admin/${Manufacturer.ernstMair}/${drawers}` : `/admin/${Manufacturer.ernstMair}`);
  }

  async editErnstMair(drawerType?: string) {
    await this.router.navigateByUrl(drawerType ? `/admin/${Manufacturer.ernstMair}/edit/${drawerType}` : `/admin/${Manufacturer.ernstMair}/edit`);
  }
}
