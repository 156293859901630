export enum ExternalShopName {
  nothegger = 'Nothegger'
}

export interface INotheggerShimsConfiguration {
  'HOLZART': string;
  'OBERFLÄCHE': string;
}

export interface INotheggerProfileConfiguration {
  'HOLZART': string;
  'STÄRKE': number;
  'OBERFLÄCHE': string;
  'WASSERFRESTVERLEIMT': boolean;
  'LÄNGE': number;
  'BREITE': number;
}

export interface INotheggerDrawerConfiguration {
  'BREITE': number;
  'HÖHE': number;
  'LADEHOLZART': string;
  'LADEKUPPLUNG': number;
  'LADEOBERFLÄCHE': string;
  'LADETYP': number;
  'TIEFE': number;

  'LADE20MMFRONT'?: boolean;
  'LADEANTIRUTSCHMATTEN'?: number;
  'LADEART'?: number;
  'LADEBESTECKEINSATZ'?: any;
  'LADEBODENGESCHRAUBT'?: boolean;
  'LADEBODENLOSE'?: boolean;
  'LADEBODENSTÄRKE'?: number;
  'LADEBOHRUNG'?: boolean;
  'LADEBRANDING'?: number;
  'LADEFÜHRUNG'?: any;
  'LADEFÜHRUNGSDOPPLUNG'?: boolean;
  'LADEFÜHRUNGINNENLICHTE'?: number;
  'LADEFÜHRUNGNENNLÄNGE'?: number;
  'LADEFÜHRUNGPREIS'?: number;
  'LADEFÜHRUNGZUBEHÖR'?: any;
  'LADEGEBÜRSTET'?: boolean;
  'LADEGRIFFFRÄSUNG'?: number;
  'LADEGRIFFFRÄSUNGVARIANTE'?: number;
  'LADEHANSTHOLMLOCHREIHE'?: boolean;
  'LADEKUPPLUNGSBOHRUNG'?: number;
  'LADEOHNEBOHRUNGFÜRFÜHRUNG'?: boolean;
  'LADEQUALITÄT'?: number;
  'LADERELING'?: number;
  'LADEREVERSETYP'?: number;
  'LADESORTISSIMO'?: any;
  'LADESORTISSIMOINDIVIDUELL'?: any;
  'LADESORTISSIMOINDIVIDUELLPREIS'?: number;
  'LADESTRAIGHTLINE'?: boolean;
  'LADESYNCHRONISATION'?: number;
  'LADESYPHON'?: number;
  'LADESYPHON_X'?: number;
  'LADESYPHON_Y'?: number;
  'LADESYPHON_Z'?: number;
  'LADEVARIANTE'?: number;
  'LADEVERBINDUNG'?: number;

  'LADEREVERSE_HR'?: number;
  'LADEREVERSE_VT'?: number;
}

export interface INotheggerOtherConfiguration {
  'TYP': number;
  'HOLZART': string;
  'OBERFLÄCHE': string;
  'BREITE': number;
  'TIEFE': number;
  'HÖHE': number;
  'BODEN'?: boolean;
  'RAHMENLOS'?: boolean;
  'SONDERFORM'?: boolean;
  'SORTISSIMOGEWÜRZDOSENANZAHL'?: number;
  'VOLLAUSZUG'?: number;
  'ZARGEN'?: boolean;
}

export interface INotheggerOrderItem {
  /** @ignore */
  pos?: number;
  quantity: number;
  productNumber: string;
  comments?: string;
  configurationData: INotheggerDrawerConfiguration | INotheggerOtherConfiguration | INotheggerShimsConfiguration | INotheggerProfileConfiguration | {};
}

export interface INotheggerDeliveryAddress {
  companyName: string;
  firstName: string;
  lastName: string;
  address: string;
  postalCode: string;
  city: string;
  country: string;
  phone: string;
  email: string;
}

export interface INotheggerOrder {
  customerOrderNumber: string;
  /** @format uuid */
  customerId: string;
  commission?: string;
  comments?: string;
  deliveryAddress: INotheggerDeliveryAddress;
  orderItems: INotheggerOrderItem[];
}
