import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { TranslateService } from '@ngx-translate/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons/faArrowUp';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faSave } from '@fortawesome/free-solid-svg-icons/faSave';
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';

import { defaultBreadcrumbs } from '../../utils/breadcrumbs/breadcrumbs';
import { IUser } from '../../../../../wdcommon/IUser';
import { APIService, UserService } from '../../services';
import { TranslatingBase } from '../../base-component/ComponentBase';
import { ICompany } from '../../../../../wdcommon/ICompany';


const toastConfig: Partial<IndividualConfig> = { timeOut: 8000 };

@Component({
  selector: 'app-company-admin',
  templateUrl: './company-admin.component.html',
  styleUrls: ['./company-admin.component.css']
})
export class CompanyAdminComponent extends TranslatingBase implements OnInit {
  @ViewChild('pop') pop: PopoverDirective;

  protected readonly faArrowUp = faArrowUp;
  protected readonly faPlus = faPlus;
  protected readonly faSave = faSave;
  protected readonly faStar = faStar;
  protected readonly faTrash = faTrash;
  protected readonly faUser = faUser;

  protected readonly companyAdminBreadcrumb = defaultBreadcrumbs['companyAdmin'];

  companyInfoForm: UntypedFormGroup;
  company: ICompany;
  companyUserList: IUser[];
  adminID;
  deliveryAddressSameAsBillingAddress: boolean;

  newUserEmail: string;
  newUserName: string;
  popover: any;

  modalRef: BsModalRef;
  childModalRef: BsModalRef;
  formSubmitted: boolean;

  constructor(private userService: UserService,
              private toastrService: ToastrService,
              private _fb: UntypedFormBuilder,
              public modalService: BsModalService,
              private api: APIService,
              translateService: TranslateService) {
    super(translateService);
  }

  ngOnInit() {
    this.company = this.userService.getUser().company;


    this.deliveryAddressSameAsBillingAddress = !this.company.deliveryAddress;
    this.companyInfoForm = this._fb.group({
      companyName: [this.company.companyName, [<any>Validators.required]],
      CVRNumber: [{ value: this.company.CVRNumber, disabled: true }],
      address: [this.company.address, [<any>Validators.required]],
      address2: [this.company.address2],
      city: [this.company.city, [<any>Validators.required]],
      postcode: [this.company.postcode, [<any>Validators.required]],
      email: [this.company.email, [<any>Validators.required, <any>Validators.email]],
      fakturaKonto: [{ value: this.company.fakturaKonto, disabled: true }],
      phone: [this.company.phone, [<any>Validators.required]],
      deliveryAddress: [{ value: this.company.deliveryAddress, disabled: this.deliveryAddressSameAsBillingAddress }, [Validators.required]],
      deliveryAddress2: [{ value: this.company.deliveryAddress2, disabled: this.deliveryAddressSameAsBillingAddress }],
      deliveryCity: [{ value: this.company.deliveryCity, disabled: this.deliveryAddressSameAsBillingAddress }, [Validators.required]],
      deliveryPostcode: [
        {
          value: !this.company.deliveryPostcode ? '' : this.company.deliveryPostcode,
          disabled: this.deliveryAddressSameAsBillingAddress
        },
        [Validators.required]
      ],
    });

  }

  openUserModal(modal) {
    this.api.getCompanyUsers().subscribe((r) => {
      this.companyUserList = r;
      this.modalRef = this.modalService.show(modal, { class: 'modal-lg' });
    });
  }

  toggleDeliveryAddressSameAsBillingAddress() {
    this.deliveryAddressSameAsBillingAddress = !this.deliveryAddressSameAsBillingAddress;
    if (!this.deliveryAddressSameAsBillingAddress) {
      this.companyInfoForm.controls.deliveryAddress.enable();
      this.companyInfoForm.controls.deliveryAddress2.enable();
      this.companyInfoForm.controls.deliveryCity.enable();
      this.companyInfoForm.controls.deliveryPostcode.enable();
    } else {
      this.companyInfoForm.controls.deliveryAddress.disable();
      this.companyInfoForm.controls.deliveryAddress2.disable();
      this.companyInfoForm.controls.deliveryCity.disable();
      this.companyInfoForm.controls.deliveryPostcode.disable();
    }
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control.markAsTouched({ onlySelf: true });
    });

    return formGroup.valid;
  }

  updateCompany() {
    this.formSubmitted = true;

    const valid = this.validateAllFormFields(this.companyInfoForm);

    if (!valid) {
      return;
    }
    const company: ICompany = this.companyInfoForm.value;
    if (!company.deliveryAddress || this.deliveryAddressSameAsBillingAddress) {
      this.deliveryAddressSameAsBillingAddress = true;
      company.deliveryAddress = undefined;
      company.deliveryAddress2 = undefined;
      company.deliveryCity = undefined;
      company.deliveryPostcode = undefined;
    }

    this.api.updateCompany(company).subscribe(async (r: any) => {
      if (r.err) {
        return this.toastrService.error(r.message, null, toastConfig);
      } else {
        // Updates user in SESSION
        await this.userService.checkUserIsValid();

        return this.toastrService.success('Firma informationerne er opdateret', null, toastConfig);
      }
    });
  }

  async addCompanyUser() {
    const existingUser: any = await firstValueFrom(this.api.getCompanyUserByEmail(this.newUserEmail));
    if (existingUser && existingUser.deleted) {
      this.api.restoreCompanyUser(existingUser).subscribe((_) => {
        this.childModalRef.hide();
        return this.toastrService.success(this.translate('COMPANY_USER_REQUESTED') + this.newUserEmail, null, toastConfig);
      });
    } else {
      this.api.createNewCompanyUser(this.newUserEmail, this.newUserName).subscribe((r: any) => {
        if (r.err) {
          return this.toastrService.error(this.translate(r.err.message), null, toastConfig);
        }
        this.childModalRef.hide();
        this.toastrService.success(this.translate('COMPANY_USER_REQUESTED') + this.newUserEmail, null, toastConfig);
      });
    }
  }


  openPopover(pop) {
    this.popover = pop;
  }

  closePopover() {
    this.popover.hide();
  }

  deleteUser() {
    if (this.adminID) {
      this.api.deleteUser(this.adminID).subscribe((r: any) => {
        if (r.err) {
          this.toastrService.error(r.message, null, toastConfig);
        } else {

          this.api.getCompanyUsers().subscribe(res => {
            this.companyUserList = res;
            this.toastrService.success('Brugeren er slettet', null, toastConfig);
            this.modalRef.hide();
          });
        }
      });
      this.closePopover();
    }
  }

  async setUserAsCompanyAdmin() {
    if (this.adminID) {
      await this.api.setUserAsCompanyAdmin(this.adminID).subscribe((r: any) => {
        if (r.err) {
          this.toastrService.error(r.message, null, toastConfig);
        } else {

          this.api.getCompanyUsers().subscribe(res => {
            this.companyUserList = res;
            this.toastrService.success('Brugeren er opgraderet til firma administrator', null, toastConfig);
            this.modalRef.hide();
          });
        }
      });
      this.closePopover();
    } else {
      this.toastrService.error('Der er ikke valgt en bruger der kan forfremmes til firma administrator', null, toastConfig);
    }
  }

}
