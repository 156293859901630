import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appBackButton]'
})
export class BackButtonDirective {
  constructor() { }

  @HostListener('click')
  onClick() {
    window.history.back();
  }
}
