export enum Palette {
  ncs = 'ncs',
  ral = 'ral'
}

export interface PaletteColour {
  HEX: string;
  Name: string;
  RAL?: number;
  NCS?: string;
  LRV?: string;
}

export interface ParsedPaletteColour extends PaletteColour {
  success: boolean;
}
