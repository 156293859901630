import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons/faWindowClose';

import { APIService, Locale, LocaleService, SettingService } from '../../services';
import { TranslatingBase } from '../../base-component/ComponentBase';
import { IFullErnstMairDrawerResponse } from '../../../../../wdcommon/IDrawer';
import { Manufacturer } from '../../../../../wdcommon/IProduct';
import { ITextSetting } from '../../../../../wdcommon/ISetting';


@Component({
  selector: 'app-edit-ernst-mair',
  templateUrl: './edit-ernst-mair.component.html',
  styleUrls: ['./edit-ernst-mair.component.css']
})
export class EditErnstMairComponent extends TranslatingBase implements OnInit {
  isDrawer = false;
  drawer: IFullErnstMairDrawerResponse;
  ernstMairDescriptions = [];
  displayLanguages: Locale[];

  protected readonly faCheck = faCheck;
  protected readonly faWindowClose = faWindowClose;

  constructor(
      private activeRoute: ActivatedRoute,
      private router: Router,
      private api: APIService,
      private localeService: LocaleService,
      private settingService: SettingService,
      private toastrService: ToastrService,
      translate: TranslateService
  ) {
    super(translate);
  }

  async ngOnInit() {
    this.displayLanguages = this.localeService.getSupportedLanguages();
    if (this.activeRoute.snapshot.params.type) {
      this.isDrawer = true;
      this.drawer = await firstValueFrom(this.api.getErnstMair(this.activeRoute.snapshot.params.type));
    } else {
      const promises = this.displayLanguages.map(async (element) => {
        const ernstMair = await this.settingService.getTextSettingByLocale(element.id, Manufacturer.ernstMair);
        this.ernstMairDescriptions.push({
          id: element.id,
          value: ernstMair.text
        });
        return;
      });
      await Promise.all(promises);
    }
  }

  goto(drawers?: string) {
    return this.router.navigateByUrl('/admin/' + Manufacturer.ernstMair + (drawers ? '/drawers' : ''));
  }

  async updateDrawer() {
    try {
      await this.api.updateErnstMair(this.drawer);
      this.toastrService.success(`Updated Ernst Mair ${this.translate(this.drawer.name)} description`);
    } catch (Err) {
      console.error(Err);
      this.toastrService.error('An error occurred when trying to save the drawer description');
    }
  }

  getLanguageName(langId: string) {
    return this.displayLanguages.find((l) => l.id === langId).name;
  }

  updateErnstMairDescriptions() {
    const promises = this.ernstMairDescriptions.map((d) => {
      const setting: ITextSetting = { text: d.value, };
      return this.settingService.setTextSettingByLocale(d.id, Manufacturer.ernstMair, setting);
    });
    Promise.all(promises)
        .then(() => this.toastrService.success('Updated Ernst Mair description'));
  }
}
