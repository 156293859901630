import { Component, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faCartArrowDown } from '@fortawesome/free-solid-svg-icons/faCartArrowDown';

import { APIService, CartService, LocaleService, UserService } from '../../services';
import { Constants } from '../../../../../wdcommon/Constants';
import { CartItemType, ITypedAddCartItem } from '../../../../../wdcommon/ICartItem';
import { IDynamicProduct } from '../../../../../wdcommon/IProduct';
import { IRelatedProductSearchResult } from '../../../../../wdcommon/IRelatedProduct';
import { TranslatingBase } from '../../base-component/ComponentBase';
import { TranslateService } from '@ngx-translate/core';
import { AddToCartWithAmountModalComponent } from '../../modals/add-to-cart-with-amount-modal/add-to-cart-with-amount-modal.component';


@Component({
  selector: 'app-add-to-basket',
  templateUrl: './add-to-basket.component.html',
  styleUrls: ['./add-to-basket.component.css']
})
export class AddToBasketComponent extends TranslatingBase {
  @Input() product: IDynamicProduct | IRelatedProductSearchResult;

  faCartArrowDown = faCartArrowDown;
  faCheck = faCheck;

  productAmountToAddToBasket: number = 1;

  modalRef: BsModalRef<AddToCartWithAmountModalComponent>;

  constructor(
      public modalService: BsModalService,
      private api: APIService,
      private cartService: CartService,
      private localeService: LocaleService,
      private toastrService: ToastrService,
      public userService: UserService,
      protected translateService: TranslateService
  ) {
    super(translateService)
  }

  chooseProduct(event: Event) {
    event.stopPropagation();
    this.productAmountToAddToBasket = 1;

    this.modalRef = this.modalService.show(AddToCartWithAmountModalComponent, {
      initialState: {
        amount: this.productAmountToAddToBasket
      }
    });

    this.modalRef.content.amountChange.subscribe((amount: number) => {
      this.productAmountToAddToBasket = amount;
    });

    this.modalRef.content.approved.subscribe((wasApproved) => {
      if (wasApproved)
        this.addToBasket();
    });
  }

  async addToBasket() {
    const localeId = this.localeService.getLanguage();
    const tree = await this.api.getCategoryTree(this.product.categoryId, localeId);

    const orderObj: ITypedAddCartItem = {
      brandId: Constants.specialsCategoryBrandIdPrefix + tree[0].id,
      type: CartItemType.additional,
      name: this.product.name,
      itemno: this.product.itemNumber,
      description: '',
      amount: this.productAmountToAddToBasket,
      price: this.product.price,
      options: {
        categoryFreightRate: tree,
        breakageFeeCount: this.product.breakageFeeCount || null,
      },
      oneDeliverySize: this.product.oneDeliverySize,
      oneDeliveryDoublePallet: this.product.oneDeliveryDoublePallet,
    };

    await this.cartService.addAdditional(orderObj);
    this.toastrService.success(this.translate('Cart.AddedToCart'));
  }

}
