<div class="container">
    <div class="row mb-3">

        <app-breadcrumbs [currentBreadcrumb]="myProfileBreadcrumb"></app-breadcrumbs>

    </div>
    <div class="row">
        <ul class="nav nav-tabs">
            <li class="nav-item">
                <a (click)="currentPage = 'contactInfo';"
                   [ngClass]="{'active': currentPage === 'contactInfo'}"
                   class="nav-link"
                   style="cursor: pointer;">{{ 'PROFILE.CONTACT_INFO' | translate }}</a>
            </li>
            <li class="nav-item">
                <a (click)="currentPage = 'logoer';"
                   [ngClass]="{'active': currentPage === 'logoer'}"
                   class="nav-link"
                   style="cursor: pointer;">{{ 'PROFILE.LOGOS' | translate }}</a>
            </li>
        </ul>
    </div>


    <div *ngIf="currentPage === 'contactInfo'" class="row">

        <div class="col-sm-6">
            <h5>{{ 'PROFILE.CONTACT_INFO' | translate }}</h5>

            <div class="mb-3">
                <label class="form-label">Navn:</label>
                <input [value]="user.name" class="form-control" disabled readonly type="text"/>
            </div>

            <div class="mb-3">
                <label class="form-label">Email:</label>
                <input [value]="user.email" class="form-control" disabled readonly tooltip="{{ 'PROFILE.CANT_CHANGE_EMAIL' | translate }}"
                       type="text"/>
            </div>

            <div class="mb-3">
                <label class="form-label">Adgangskode:</label>
                <div>
                    <button (click)="modalRef = modalService.show(switchPW)" class="btn btn-primary" translate="PROFILE.CHANGE_PASSWORD"></button>
                </div>
            </div>

            <div class="mb-3">
                <label class="form-label">{{ 'PROFILE.LANGUAGE' | translate }}:</label>
                <select (change)="setLanguage()" [(ngModel)]="preferredLanguage" class="form-control">
                    <option value="da">Dansk</option>
                    <option value="en">English</option>
                    <option value="sv">Svenska</option>
                    <option value="no">Norsk</option>
                </select>
            </div>

            <div *ngIf="newsletterStatus">
                <label class="form-label">{{ 'PROFILE.NEWSLETTER' | translate }}:</label>
                <div *ngIf="!newsletterSubscribing && (newsletterStatus.subscribed === SubscribedState.unsubscribed || newsletterStatus.subscribed === SubscribedState.new)">
                    {{ 'PROFILE.NEWSLETTER_NOT_SIGNED_UP' | translate }}<br>
                    <button (click)="subscribeToNewsletter()" class="btn btn-success">{{ 'PROFILE.NEWSLETTER_SUBSCRIBE' | translate }}<br>
                    </button>
                </div>
                <div *ngIf="newsletterStatus.subscribed === SubscribedState.subscribed">
                    {{ 'PROFILE.NEWSLETTER_SIGNED_UP' | translate }}<br>
                    <button (click)="unsubscribeNewsletter()" class="btn btn-secondary">{{ 'PROFILE.NEWSLETTER_UNSUBSCRIBE' | translate }}</button>
                </div>
                <div *ngIf="newsletterSubscribing" class="card bg-info text-white p-2">
                    {{ 'PROFILE.NEWSLETTER_SUBSCRIBED_CHECK_MAIL' | translate }}
                </div>
            </div>

        </div>
        <div class="col-sm-6">

            <h5>{{ 'PROFILE.COMPANY_INFO' | translate }}</h5>
            {{ user.company.companyName }}<br>
            {{ 'PROFILE.CVR_VAT' | translate }}: {{ user.company.CVRNumber }}<br>
            {{ user.company.address }}<br>
            <span *ngIf="user.company.address2 !== ''">
        {{ user.company.address2 }} <br>
      </span>
            {{ user.company.postcode }} {{ user.company.city }}<br>
            <br>
            {{ 'PROFILE.PHONE' | translate }}: {{ user.company.phone }}<br>
            Email: {{ user.company.email }}

        </div>

    </div>
    <div *ngIf="currentPage === 'logoer'" class="row">

        <div class="col-sm-12">
            <ul class="list-group">
                <li class="list-group-item">
                    <button (click)="modalRef = modalService.show(logoModal);" class="btn btn-primary float-end">
                        {{ 'PROFILE.UPLOAD_NEW_LOGO' | translate }}
                    </button>
                    <br>
                </li>
                <li *ngFor="let logo of logos" class="list-group-item">
          <span>
            <img alt="" class="logo-list-image-height" src="{{ logo.imgBase64 }}"> {{ logo.origFilename }}
          </span>
                    <span class="float-end">
            <button #pop="bs-popover" (onHidden)="delLogoId = null;" (onShown)="delLogoId = logo.id;"
                    [outsideClick]="true" [popoverTitle]="translate('Er du sikker')" [popover]="delLogoPop"
                    class="btn btn-danger" container="body" type="button">
              <fa-icon [icon]="faTrash"></fa-icon>
                {{ 'UTILITY.DELETE' | translate }}
            </button>
          </span>
                </li>
            </ul>
        </div>

    </div>
</div>

<ng-template #switchPW>
    <div *blockUI="'pwChangeBlock'">
        <div class="modal-header">
            <h4 class="modal-title">{{ 'PROFILE.CHANGE_PASSWORD' | translate }}</h4>
            <button (click)="modalRef.hide()" aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
        </div>
        <div class="modal-body">
            <p>{{ 'PROFILE.AFTER_PASSWORD_CHANGE' | translate }}</p>
            <form (ngSubmit)="changePW()" [formGroup]="changePWForm">
                <label class="form-label">{{ 'UTILITY.CURRENT' | translate }} Password</label>
                <input class="form-control mb-3" formControlName="oldPW" id="oldPW" name="oldPW" type="password">
                <label class="form-label">{{ 'UTILITY.NEW' | translate }} Password</label>
                <input class="form-control mb-3" formControlName="newPW" type="password">
                <label class="form-label">{{ 'UTILITY.REPEAT' | translate }} {{ 'UTILITY.NEW' | translate }} Password</label>
                <input class="form-control" formControlName="newPWRepeat" type="password">
            </form>

        </div>
        <div class="modal-footer">
            <button (click)="changePW()" class="btn btn-primary" type="button">{{ 'PROFILE.CHANGE_PASSWORD' | translate }}</button>
            <button (click)="modalRef.hide()" class="btn btn-secondary" data-dismiss="modal" type="button">{{ 'UTILITY.CANCEL' | translate }}</button>
        </div>
    </div>
</ng-template>

<ng-template #logoModal>
    <div class="modal-header">
        <h4 class="modal-title">{{ 'UTILITY.ADD' | translate }} Logo</h4>
        <button (click)="modalRef.hide()" aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
    </div>
    <div class="modal-body">
        <app-logo-uploader (uploaded)="onUploaded()" [modalRef]="modalRef"></app-logo-uploader>
    </div>
    <div class="modal-footer">
        <button (click)="modalRef.hide()" class="btn btn-secondary" data-dismiss="modal" type="button">
            {{ 'UTILITY.CLOSE_WINDOW' | translate }}
        </button>
    </div>
</ng-template>

<ng-template #delLogoPop>
    <div class="text-center">
        <div class="btn-group"
             role="group">
            <button (click)="deleteLogo()" class="btn btn-sm btn-danger">{{ 'UTILITY.YES' | translate }}</button>
            <button (click)="pop.hide()" class="btn btn-sm btn-secondary">{{ 'UTILITY.NO' | translate }}</button>
        </div>
    </div>
</ng-template>
