import { Component, Input, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { faCalendar } from '@fortawesome/free-solid-svg-icons/faCalendar';

enum ActiveTab {
  thomas = 'thomas',
  jacob = 'jacob'
}

@Component({
  selector: 'app-schedule-meeting',
  templateUrl: './schedule-meeting.component.html'
})
export class ScheduleMeetingComponent {
  @Input() addedClasses = '';

  faCalendar = faCalendar;
  modalRef: BsModalRef;

  ActiveTab = ActiveTab;
  activeTab: ActiveTab = ActiveTab.thomas;

  constructor(public modalService: BsModalService) {
  }

  openMeeting(popUp: TemplateRef<any>) {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
    document.body.appendChild(s);
    this.modalRef = this.modalService.show(popUp, { class: 'modal-lg' });
  }

}
