import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { IndividualConfig, ToastrService } from 'ngx-toastr';

import { LocaleService, UserService } from '../services';
import { TranslatingBase } from '../base-component/ComponentBase';


const toastErrorConfig: Partial<IndividualConfig> = {
  timeOut: 10000,
  progressBar: true,
  tapToDismiss: false,
};


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent extends TranslatingBase implements OnInit {

  @BlockUI('blockLoginBtn') blockLoginBtn: NgBlockUI;

  loginForm: UntypedFormGroup;
  modalRef: BsModalRef;
  restoreEmail: string;
  preferredLanguage = 'da';
  actionText: string | null = null;
  newUserCreated = false;

  constructor(
      private localeService: LocaleService,
      private userService: UserService,
      public modalService: BsModalService,
      private toastrService: ToastrService,
      private router: Router,
      private route: ActivatedRoute,
      private translateService: TranslateService) {
    super(translateService);
  }

  async ngOnInit() {
    this.loginForm = new UntypedFormGroup({
      email: new UntypedFormControl(),
      password: new UntypedFormControl()
    });

    const action = this.route.snapshot.queryParams.action;
    if (action) {
      if (action === 'user-created') {
        this.newUserCreated = true;
      }
      this.actionText = this.translate(action);
    }

    if (this.userService.getUser()) {
      if (await this.userService.checkUserIsValid()) {
        await this.router.navigateByUrl('/');
        if (this.actionText) {
          this.toastrService.info(this.actionText)
        }
      }
    }

    this.preferredLanguage = this.localeService.getLanguage();
  }

  async loginUser() {
    this.blockLoginBtn.start();

    const { success, error } = await this.userService.loginUser(this.loginForm.value.email, this.loginForm.value.password);

    if (success) {
      this.toastrService.clear();
      this.toastrService.success(this.translate('LOGIN.Success'));
      return this.router.navigateByUrl('/');
    }

    const msg = this.translate('LOGIN.' + error);
    if (error === 'USER_NOT_ACTIVATED') {
      this.toastrService.info(msg, this.translate('LOGIN.Error.IkkeAktiveret'), toastErrorConfig);
    } else {
      this.toastrService.error(msg, this.translate('LOGIN.Error.Ukendt'), toastErrorConfig);
    }
  }

  sendLostPasswordEmail() {
    console.debug('Nulstiller password for', this.restoreEmail);
    this.userService.requestPasswordReset(this.restoreEmail).subscribe((r: any) => {
      if (r.err) {
        return this.toastrService.error(r.message);
      } else {
        this.modalRef.hide();
        return this.toastrService.info(this.translate('LOGIN.GlemtPasswordModal.NulstillingAnmodet'));
      }
    });
  }

  setLanguage() {
    this.localeService.setLanguage(this.preferredLanguage);
    this.translateService.use(this.preferredLanguage);
  }
}
