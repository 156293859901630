import { Component } from '@angular/core';

import { defaultBreadcrumbs } from '../../utils/breadcrumbs/breadcrumbs';
import { Manufacturer } from '../../../../../wdcommon/IProduct';


@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css'],
})
export class ProductsComponent {
  adminDrawersBreadcrumb = defaultBreadcrumbs.adminDrawers;
  notheggerBreadcrumb = defaultBreadcrumbs['adminDrawers' + Manufacturer.nothegger];
  ernstMairBreadcrumb = defaultBreadcrumbs['adminDrawers' + Manufacturer.ernstMair];
}
