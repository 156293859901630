import { FormRow } from '../../dynamicForm/dynaform.interfaces';
import { createDrawerHeightField, StandardSurfaceTreatmentField, StandardThicknessField } from '../../dynamicForm/standard-fields';
import {
  createHrRow,
  createPremountedCouplingSelectionRow,
  createPurewoodThicknessRow,
  createQualityTypeOfWoodSelectionRow,
  createRunnerDeliverySelectionRow,
  createRunnerWidthSelectionRow,
  createSlideListRow
} from '../../dynamicForm/standard-rows';

export const MODEL: FormRow[] = [
  createQualityTypeOfWoodSelectionRow(),
  {
    id: 'surface-treatment-row',
    show: true,
    fields: [
      StandardSurfaceTreatmentField,
      StandardThicknessField
    ]
  },
  createRunnerWidthSelectionRow(150, 1200, 150, 1205),
  createRunnerDeliverySelectionRow(),
  {
    id: 'height-row',
    show: true,
    fields: [
      createDrawerHeightField(),
    ]
  },
  createPremountedCouplingSelectionRow(),
  createSlideListRow(),
  createHrRow(),
  {
    id: 'config',
    type: 'configuration',
    config: ['udsparing', 'logo']
  }
];
