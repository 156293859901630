<carousel [interval]="7500" [noPause]="false" [showIndicators]="true">

    <slide *ngIf="userService.isLoggedIn && !userService.isUserActive()">
        <div class="up-box up-box-primarily up-slide d-flex flex-column justify-content-around">
            <div class="up-box-header" translate="SIDEBAR.BrugerIkkeAktiveret.title"></div>
            <div [innerHTML]="'SIDEBAR.BrugerIkkeAktiveret.text' | translate" class="up-box-body"></div>
        </div>
    </slide>

    <slide *ngIf="userService.isLoggedIn && unknownAtKlaviyo">
        <div class="up-box up-box-action up-slide d-flex flex-column justify-content-around">
            <div class="up-box-header">{{ 'PROFILE.NEWSLETTER' | translate }}</div>
            <div [innerText]="'SIDEBAR.NEWSLETTER_TEXT' | translate" class="up-box-body"></div>
            <div class="up-box-footer d-flex justify-content-around">
                <strong *ngIf="newsletterSubscribing" class="text-center">
                    <fa-icon [icon]="faEnvelopeOpenText"></fa-icon>
                    <div [innerHTML]="'PROFILE.NEWSLETTER_SUBSCRIBED_CHECK_MAIL' | translate"></div>
                </strong>
                <ng-container *ngIf="!newsletterSubscribing">
                    <button (click)="subscribeToNewsletter()"
                            class="up-btn">{{ 'SIDEBAR.NEWSLETTER_SUBSCRIBE_YES' | translate }}</button>
                    <button (click)="sayNoToNewsletter()"
                            class="up-btn up-btn-secondary">{{ 'SIDEBAR.NEWSLETTER_SUBSCRIBE_NO' | translate }}</button>
                </ng-container>
            </div>
        </div>
    </slide>

    <ng-container *ngIf="newsArray.length">
        <slide *ngFor="let news of newsArray">
            <ng-container [ngSwitch]="news.design">
                <div *ngSwitchCase="globalContentDesign.default" [class.clickable]="news.routerLink" [routerLink]="news.routerLink"
                     class="up-box up-box-primarily up-slide d-flex flex-column">
                    <div class="up-box-header strong" [innerText]="news.header"></div>
                    <div [innerHTML]="news.text" class="up-box-body flex-grow-1 d-flex flex-column justify-content-between h-100"></div>
                </div>
                <div *ngSwitchCase="globalContentDesign.fullImage">
                    <img [class.clickable]="news.routerLink" [routerLink]="news.routerLink" [src]="news.imgUrl" alt="" class="up-slide-full-image">
                </div>
                <div *ngSwitchDefault>
                    <div [innerHtml]="'Unknown design: ' + news.text + ' ' + news.imgUrl"></div>
                </div>
            </ng-container>
        </slide>
    </ng-container>

</carousel>
