import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as i18IsoCountries from 'i18n-iso-countries';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';

import { APIService } from '../services';
import { TranslatingBase } from '../base-component/ComponentBase';


interface SignUp { // See also wd_server route company => POST /
  address2: string;
  companyName: string;
  country: string;
  cvr: string;
  email: string;
  freightTier: string;
  name: string;
  password: string;
  phone: string;
  address: string;
  postcode: string;
  city: string;
  samples: boolean;
}


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent extends TranslatingBase implements OnInit {
  @BlockUI('blockCVRBtn') blockCVRBtn: NgBlockUI;
  modalRef: BsModalRef;
  @ViewChild('termsModal') termsModal: TemplateRef<any>;
  @ViewChild('confirmModal') confirmModal: TemplateRef<any>;

  faCheck = faCheck;
  faSearch = faSearch;

  companyInfo: any;
  cvrNumber: string;
  companyExists = false;
  userExists = false;
  unknownError = false;
  infoCorrect;
  termsAccepted;
  formData: Partial<SignUp> = {};
  countries = [];
  language;

  constructor(
      private api: APIService,
      private modalService: BsModalService,
      private router: Router,
      private toastrService: ToastrService,
      private translateService: TranslateService,
  ) {
    super(translateService);
  }

  ngOnInit() {
    // Register languages for countries:
    this.setupLanguageSelection();
  }

  showTerms() {
    this.modalRef = this.modalService.show(this.termsModal, { class: 'modal-lg' });
  }

  showConfirmUser(newUserForm: NgForm) {

    this.companyExists = false;
    this.userExists = false;
    this.unknownError = false;
    if (newUserForm.invalid) {
      alert(this.translate('SIGNUP.udfyldAlle'));
      return;
    }

    if (!this.termsAccepted) {
      alert(this.translate('SIGNUP.accepterBetingelserne'));
      return;
    } else if (!this.infoCorrect) {
      alert(this.translate('SIGNUP.bekræftInformationerne'));
      return;
    } else {
      console.log(this.termsAccepted, this.infoCorrect);
    }

    const countryCode = i18IsoCountries.getAlpha2Code(this.formData.country, this.language);
    if (countryCode === 'DK') {
      this.blockCVRBtn.start('Validerer cvr ...');
      this.api.validateCVR(this.formData.cvr)
          .subscribe({
            next: (cvrInfo: { name: string, phone: string, address: string, zipcode: string, city: string, vat: string; }) => {
              this.blockCVRBtn.stop();
              if (!cvrInfo.vat || cvrInfo.vat.toString() !== this.formData.cvr.toString()) {
                this.toastrService.error(this.translate('SIGNUP.CvrIkkeFundet'), null, { timeOut: 10000 });
                return;
              }
              this.formData.freightTier = countryCode === null ? null : countryCode.toLowerCase();
              this.modalRef = this.modalService.show(this.confirmModal);
            },
            error: () => {
              this.blockCVRBtn.stop();
              this.toastrService.error(this.translate('SIGNUP.CvrIkkeFundet'), null, { timeOut: 10000 });
              return;
            }
          });
    } else {
      this.formData.freightTier = countryCode === null ? null : countryCode.toLowerCase();
      this.modalRef = this.modalService.show(this.confirmModal);
    }
  }

  createUser() {
    this.modalRef.hide();
    this.blockCVRBtn.start('Opretter bruger ...');
    this.api.createCompany(this.formData)
        .subscribe({
          next: async () => {
            this.blockCVRBtn.stop();
            await this.router.navigateByUrl('/login?action=user-created');
          },
          error: (ex) => {
            console.error(ex);

            if (ex.error && ex.error.errorId === 'CompanyExists')
              this.companyExists = true;
            else if (ex.error && ex.error.errorId === 'EmailExists')
              this.userExists = true;
            else
              this.unknownError = true;

            this.blockCVRBtn.stop();
          }
        });
  }

  searchCVR() {
    this.companyExists = false;
    this.companyInfo = null;
    this.blockCVRBtn.start('Henter info...');

    this.api.getCVRInfo(this.cvrNumber)
        .subscribe({
          next: (cvrInfo: { name: string, phone: string, address: string, zipcode: string, city: string, vat: string; }) => {
            this.api.checkCVR(cvrInfo.vat)
                .subscribe({
                  next: ({ alreadyExists }) => {

                    if (alreadyExists) {
                      this.blockCVRBtn.stop();
                      this.companyExists = true;
                    } else {
                      const cres = (cvrInfo as any);
                      this.companyInfo = cvrInfo;
                      this.formData.companyName = cres.name;
                      this.formData.phone = cres.phone;
                      this.formData.address = cres.address;
                      this.formData.postcode = cres.zipcode;
                      this.formData.city = cres.city;
                      this.formData.cvr = cres.vat;

                      this.blockCVRBtn.stop();
                    }
                  },
                  error: (err) => {
                    console.log('obs err', err);
                    this.unknownError = true;
                  }
                });

          },
          error: () => {
            this.blockCVRBtn.stop();
            this.toastrService.error('CVR nummeret kunne ikke findes. Check venligst at nummeret er korrekt og prøv igen.', null, { timeOut: 10000 });
          }
        });
  }

  fillformManually() {
    if (!this.companyInfo) {
      this.companyInfo = {
        name: '',
        phone: '',
        address: '',
        zipcode: '',
        city: '',
        vat: ''
      };
    }
  }

  setupLanguageSelection() {

    this.language = this.translateService.currentLang;
    if (!this.language)
      this.language = 'da';
    else if (this.language === 'no')
      this.language = 'nb';

    this.countries = [];
    try {
      i18IsoCountries.registerLocale(require(`i18n-iso-countries/langs/${this.language}.json`));
    } catch (ex) {
      console.log('Error when setting countries list to the current language. Setting to danish as default', ex);
      this.language = 'da';
      i18IsoCountries.registerLocale(require(`i18n-iso-countries/langs/${this.language}.json`));

    }
    this.countries.push(i18IsoCountries.getName('DK', this.language));
    this.countries.push(i18IsoCountries.getName('SE', this.language));
    this.countries.push(i18IsoCountries.getName('NO', this.language));
  }
}
