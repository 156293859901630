import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { faArrowRight, faCheck } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CartService } from '../../services';
import { TranslatingBase } from '../../base-component/ComponentBase';

@Component({
  selector: 'app-add-to-cart-with-amount-modal',
  templateUrl: './add-to-cart-with-amount-modal.component.html'
})
export class AddToCartWithAmountModalComponent extends TranslatingBase {
  @Input()  disable: boolean;
  @Input()  amount: number;

  @Input()  minAmount: number = 1;
  @Input()  maxAmount: number = Infinity;
  @Input()  amountStep: number = 1;

  @Output() amountChange = new EventEmitter<number>();
  @Output() approved = new EventEmitter<boolean>();

  faArrowRight = faArrowRight;
  faCheck = faCheck;

  constructor(
      protected cartService: CartService,
      protected translateService: TranslateService,
      protected modalService: BsModalService
  ) {
    super(translateService);
  }

  updateAmount() {
    if (this.amount < this.minAmount) {
      this.amount = this.minAmount;
    } else if (this.amount > this.maxAmount) {
      this.amount = this.maxAmount;
    } else if (this.amount % this.amountStep !== 0) {
      this.amount = Math.round(this.amount / this.amountStep) * this.amountStep;
    }
    this.amountChange.emit(this.amount);
  }

  approveOrCancel(approved: boolean) {
    if (approved && !this.cartService.checkCanAddToCartAndDisplayError()) {
      return;
    }

    this.hide();
    this.approved.emit(approved);
  }

  hide() {
    this.modalService.hide();
  }
}
