<div class="container">
    <div class="row mb-3">

        <div class="col">
            <app-breadcrumbs [currentBreadcrumb]="adminGlobalContentBreadcrumb"></app-breadcrumbs>
        </div>

    </div>

    <div class="row mb-3 bg-primary text-white py-3 body-type-header">
        <div class="col-1 ps-4">
            <img alt="DOT" class="me-1" src="assets/images/dot_hvid.jpg"/>
        </div>
        <div class="col-3">
            Indholdstype
        </div>
        <div class="col-2">
            ID-nøgle
        </div>
        <div class="col-2">
            Udgivet
        </div>
        <div class="col-2">
            Sprog
        </div>
        <div class="col-2">
            Database ID
        </div>
    </div>

    <ng-container *ngIf="existingPages">
        <div *ngFor="let page of existingPages" class="row">
            <div class="col-1">
                <button [routerLink]="'/admin/global-content/' + page.type + '/' + page.key" class="btn btn-sm btn-primary" type="button">
                    <fa-icon [icon]="faEdit"></fa-icon> &nbsp;Redigér
                </button>&nbsp;
            </div>
            <div class="col-3">
                {{ page.sortOrder }}.
                {{ 'GlobalContent.Type.' + page.type | translate }}
            </div>
            <div class="col-2">
                {{page.key}}
            </div>
            <div class="col-2">
                <span *ngIf="isActive(page)" [innerHTML]="page.lastPublished | date:'MMM d y H:mm'"></span>
            </div>
            <div class="col-2">
                <ng-container *ngIf="isActive(page)">{{dumpObjectKeys(page.public)}}</ng-container>
            </div>
            <div class="col-2">
                {{page.id}}
            </div>
        </div>
    </ng-container>

    <div class="row mt-3">
        <div class="col text-end">
            <button (click)="openModal(createGlobalContent)" class="btn btn-primary">Opret nyt indhold</button>
        </div>
    </div>

</div>

<ng-template #createGlobalContent>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Opret nyt indhold</h4>
        <button (click)="modalRef.hide()" aria-label="Close" class="btn-close close pull-right" type="button">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div *ngIf="!waiting" class="modal-body">
        <div *ngIf="error" class="mb-3 alert alert-warning" role="alert">{{error}}</div>
        <div class="mb-3">
            <label class="form-label" for="typeSelect">Vælg indholdstype</label>
            <select [(ngModel)]="newType" aria-describedby="typeHelp" aria-label="Vælg type" class="form-select" id="typeSelect" required>
                <option *ngFor="let t of typeEnum | keyvalue" [ngValue]="t.key" [translate]="'GlobalContent.Type.' + t.key"></option>
            </select>
            <div class="form-text" id="typeHelp">Hvilken type indhold skal oprettes?</div>
        </div>
        <div class="mb-3">
            <label class="form-label" for="keyInput">Unikt ID for indholdet</label>
            <input [(ngModel)]="newKey" aria-describedby="keyHelp" class="form-control" id="keyInput" required type="text">
            <div class="form-text" id="keyHelp">Skal være unikt indenfor typen. Benyt sammenhængende tekst, evt. med bindestreger.</div>
        </div>
        <button (click)="createContent()" class="btn btn-primary" type="submit">Opret</button>
    </div>
    <div *ngIf="waiting" class="modal-body text-center my-5">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</ng-template>
