import { Component, Input } from '@angular/core';

import { APIService, DrawerOptionsService, HettichService } from '../../services';
import { Constants } from '../../../../../wdcommon/Constants';
import { CartItemType, CartSubType, ICartItem, IPurewoodFrontOptions } from '../../../../../wdcommon/ICartItem';
import { ImageMessageTypes, Manufacturer } from '../../../../../wdcommon/IProduct';

@Component({
  selector: 'app-order-layout-image',
  templateUrl: './order-layout-image.component.html',
  styleUrls: ['./order-layout-image.component.css']
})
export class OrderLayoutImageComponent {
  _item: ICartItem;
  imageUrl: string;
  imageUrlBase64: string;

  constructor(
      private apiService: APIService,
      private drawerOptionsService: DrawerOptionsService,
      private hettichService: HettichService,
  ) {
  }

  @Input() set item(i: ICartItem) {
    this._item = i;
    (async () => await this.setImageUrlFromItem())();
  }

  private async setImageUrlFromItem() {
    this.imageUrl = undefined;

    switch (this._item.type) {

      case CartItemType.drawer:
        switch (this._item.brandId) {

          case Manufacturer.nothegger:
            const notheggerTypes = await this.drawerOptionsService.getDrawers();
            const notheggerType = notheggerTypes.find((d) => d.type === this._item.options.type);
            if (notheggerType && notheggerType.img) {
              this.imageUrl = notheggerType.img;
            }
            break;

          case Manufacturer.purewood:
            const purewoodTypes = await this.apiService.getPurewoodDrawers();
            const purewoodType = purewoodTypes.find((d) => Manufacturer.purewood + '-' + d.shortname === this._item.options.type);
            if (purewoodType && purewoodType.shortname) {
              this.imageUrl = '/assets/images/drawers/' + purewoodType.shortname.replace('-', '_') + '.png';
            }
            break;

          case Manufacturer.ernstMair:
            const ernstTypes = await this.apiService.getErnstMairs('da');
            const ernstType = ernstTypes.find((d) => d.type === this._item.options.type);
            if (ernstType && ernstType.img) {
              this.imageUrl = ernstType.img;
            }
            break;

        }

        if (!this.imageUrl) {
          this.imageUrl = '/assets/images/drawers/innenlade.png';
        }
        break;

      case CartItemType.extra:
        switch (this._item.brandId) {

          case Manufacturer.purewood:
            this.imageUrl = '/assets/images/purewood_logo.png';
            break;

          case Manufacturer.ernstMair:
            this.imageUrl = '/assets/images/ernst-mair/ernst-mair.png';
            break;

          case Manufacturer.nothegger:
          default:
            this.imageUrl = '/assets/images/nothegger_icon_grau.png';
            break;

        }
        break;

      case CartItemType.fronts:
        const options = this._item.options as IPurewoodFrontOptions;
        const fronts = await this.apiService.getFronts(options.category);
        const front = fronts.find((f) => f.id === options.frontType);
        if (front && front.image) {
          this.imageUrl = '/assets/images/fronts/' + front.image;
        }
        break;

      case CartItemType.carcass:
        let carcassImage = 'Panel';
        switch (this._item.subType) {
          case CartSubType.cupboard:
            carcassImage = 'HS';
            break;
          case CartSubType.baseUnit:
            carcassImage = 'US';
            break;
          case CartSubType.wallUnit:
            carcassImage = 'OS';
            break;
        }
        this.imageUrl = `/assets/images/cabinet-carcass/${carcassImage}.png`;
        break;

      case CartItemType.additional:
        if (this._item.brandId && this._item.brandId.indexOf(Constants.specialsCategoryBrandIdPrefix) === 0) {
          const base64ImageAdditional = await this.apiService.getCategoryImage(parseInt(this._item.brandId.replace(Constants.specialsCategoryBrandIdPrefix, ''), 10));
          if (base64ImageAdditional.message === ImageMessageTypes.base64) {
            this.imageUrlBase64 = base64ImageAdditional.encImg;
          }
        }
        break;

      case CartItemType.hettichRunnerAddOn:
      case CartItemType.other:
      case CartItemType.runner:
        let categoryBrand: string;
        if (this._item.brandId && this._item.brandId.indexOf(Constants.specialsCategoryBrandIdPrefix) === 0) {
          categoryBrand = this._item.brandId;
        } else {
          categoryBrand = await this.hettichService.getHettichBrandId();
        }

        const base64Image = await this.apiService.getCategoryImage(parseInt(categoryBrand.replace(Constants.specialsCategoryBrandIdPrefix, ''), 10));
        if (base64Image.message === ImageMessageTypes.base64) {
          this.imageUrlBase64 = base64Image.encImg;
        }
        break;

      default:
        console.warn('NO IMAGE for ' + this._item.type, this._item.brandId, this._item);
    }
  }

}
