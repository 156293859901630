<div *blockUI="'blockOrder'" class="container">
    <img alt="" class="order-image-header d-none d-md-block float-end" src="{{ drawer.img }}">
    <div class="row">
        <div class="col">
            <app-breadcrumbs [currentBreadcrumb]="dynamicBreadcrumb"></app-breadcrumbs>
        </div>
    </div>
    <hr/>
    <aw-wizard #wizard [navBarLayout]="'small'">
        <aw-wizard-step [navigationSymbol]="{ symbol: '1' }" [stepTitle]="'Udfyld bestilling' | translate">

            <div *ngIf="drawer.type.indexOf('curve') > -1" class="row">
                <div class="col-sm text-center">
                    <img alt="" src="/assets/images/drawers/designcurve_description.png">
                </div>
            </div>

            <!-- form order -->
            <app-dynamic-form (submit)="submit($event)" [autoFocusFirst]="true" [rows]="model">

                <div *ngIf="this.drawer.type === 'rev_ezug'" class="row">
                    <div class="col-sm">
                        <app-rev-ezug-display [isLocked]="ezugLocked"></app-rev-ezug-display>
                    </div>
                </div>
                <div *ngIf="this.drawer.type === 'purewood-rev-ezug'" class="row">
                    <div class="col-sm">
                        <app-purewood-rev-ezug-display [isLocked]="ezugLocked"></app-purewood-rev-ezug-display>
                    </div>
                </div>
                <div *ngIf="this.drawer.type === 'ernst-mair-rear-ezug'" class="row">
                    <div class="col-sm">
                        <app-display-ernst-mair-rear-ezug [isLocked]="ezugLocked">
                        </app-display-ernst-mair-rear-ezug>
                    </div>
                </div>
                <div *ngIf="this.drawer.type === 'ernst-mair-rev-ezug'" class="row">
                    <div class="col-sm">
                        <app-display-ernst-mair-rev-ezug [isLocked]="ezugLocked">
                        </app-display-ernst-mair-rev-ezug>
                    </div>
                </div>

                <!-- greb,  udsparing og whatnot knapper -->
                <div class="row">

                    <div class="col-sm">
                        <div *ngIf="grebShow" class="row mb-3">
                            <div class="col-sm">
                                <div><label class="form-label">{{ 'Greb' | translate }}</label></div>
                                <div class="row">
                                    <div class="col-sm">
                                        <button (click)="setGreb(null)" [ngClass]='{active: greb === null}' class="btn btn-greb"
                                                type="button">
                                            <img alt="" src="/assets/images/greb/ingen_small.png">
                                        </button>&nbsp;
                                        <button (click)="setGreb('v')" [ngClass]='{active: greb === "v"}' class="btn btn-greb" type="button">
                                            <img alt="" src="/assets/images/greb/v_small.png">
                                        </button>&nbsp;
                                        <button (click)="setGreb('c')" [ngClass]='{active: greb === "c"}' class="btn btn-greb" type="button">
                                            <img alt="" src="/assets/images/greb/c_small.png">
                                        </button>&nbsp;
                                        <button (click)="setGreb('o')" [ngClass]='{active: greb === "o"}' class="btn btn-greb" type="button">
                                            <img alt="" src="/assets/images/greb/o_small.png">
                                        </button>&nbsp;
                                        <button (click)="setGreb('k')" [ngClass]='{active: greb === "k"}' class="btn btn-greb" type="button">
                                            <img alt="" src="/assets/images/greb/k_small.png">
                                        </button>&nbsp;
                                        <button (click)="setGreb('l')" [ngClass]='{active: greb === "l"}' class="btn btn-greb" type="button">
                                            <img alt="" src="/assets/images/greb/l_small.png">
                                        </button>
                                        <br><br>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div *ngIf="udsparingShow" class="col-12 col-xl-6 pb-2">
                                <div *ngIf="udsparing || rearUdsparing" class="btn-group" role="group">
                                    <button (click)="addSiphonCutting()" class="btn btn-primary form-control" type="button">
                                        <fa-icon [icon]="faEdit"></fa-icon>
                                        {{ translate('Ret Udsparing') }}
                                    </button>
                                    <button #pop="bs-popover" [outsideClick]="true" [popover]="popDelUdsparing"
                                            class="btn btn-sm btn-danger form-control" container="body" type="button">
                                        <fa-icon [icon]="faTrash"></fa-icon>
                                        {{ translate('Fjern Udsparing') }}
                                    </button>
                                </div>
                                <button (click)="addSiphonCutting()" *ngIf="!udsparing && !rearUdsparing" [disabled]="bestikindsats"
                                        class="btn btn-primary form-control" type="button">
                                    <fa-icon [icon]="faPlus"></fa-icon>
                                    {{ 'Tilføj Udsparing' | translate }}
                                </button>
                            </div>
                            <div *ngIf="bestikindsatsShow" class="col-12 col-xl-6 pb-2">
                                <div *ngIf="bestikindsats" class="text-center">
                                    <small [translateParams]="{type: bestikindsats.type, height: bestikindsats[OptionProperty.height], depth: bestikindsats[OptionProperty.depth], typeOfWood: 'TREESORTS.' + bestikindsats[OptionProperty.typeOfWood] | translate, width: bestikindsats[OptionProperty.width]}"
                                           [translate]="'CARTVALUE.bestikindsats.' + (bestikindsats.special ? 'special' : 'normal')"
                                           class="text-primary"></small>
                                </div>
                                <div *ngIf="bestikindsats" class="btn-group p-0" role="group">
                                    <button (click)="addBestikindsats()" class="btn btn-primary form-control" type="button">
                                        <fa-icon [icon]="faEdit"></fa-icon>
                                        {{ 'Ret Bestikindsats' | translate }}
                                    </button>
                                    <button #pop="bs-popover" [outsideClick]="true" [popover]="popDelBestikindsats"
                                            class="btn btn-sm btn-danger form-control" container="body" type="button">
                                        <fa-icon [icon]="faTrash"></fa-icon>
                                        {{ 'Fjern Bestikindsats' | translate }}
                                    </button>

                                </div>
                                <small *ngIf="bestikindsats && bscomp && bscomp.isSortissimoDepthValid() === false" class="text-danger">
                                    <b>{{ 'Order.Bestikindsats.NotAvailable' | translate }}</b>
                                </small>
                                <small *ngIf="bestikindsats && bscomp && bscomp.isSortissimoWidthValid() === false" class="text-danger">
                                    <b>{{ 'Order.Bestikindsats.WidthUnavailable' | translate }}</b>
                                </small>
                                <button (click)="addBestikindsats()" *ngIf="!bestikindsats" [disabled]="udsparing"
                                        class="btn btn-primary form-control" type="button">
                                    <fa-icon [icon]="faPlus"></fa-icon>
                                    {{ 'Tilføj Bestikindsats' | translate }}
                                </button>
                            </div>
                            <div *ngIf="logoShow" class="col-12 col-xl-6 pb-2">
                                <div *ngIf="logo" class="btn-group" role="group">
                                    <button (click)="showAddLogo()" class="btn btn-primary form-control" type="button">
                                        <fa-icon [icon]="faEdit"></fa-icon>
                                        {{ 'Ret Logo' | translate }}
                                    </button>
                                    <button #pop="bs-popover" [outsideClick]="true" [popover]="popDelLogo"
                                            class="btn btn-sm btn-danger form-control" container="body" type="button">
                                        <fa-icon [icon]="faTrash"></fa-icon>
                                        {{ 'Fjern Logo' | translate }}
                                    </button>
                                </div>
                                <button (click)="showAddLogo()" *ngIf="!logo" class="btn btn-primary form-control" type="button">
                                    <fa-icon [icon]="faPlus"></fa-icon>
                                    {{ 'Tilføj Logo' | translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm text-end">
                        <div *ngIf="boringShow" [formGroup]="checkboxForm" class="form-check form-check-reverse">

                            <label class="form-check-label" for="boringBox">
                                {{ 'Boring til front' | translate }}
                            </label>
                            <input class="form-check-input" formControlName="boringFrontBox" id="boringBox" type="checkbox" value="">
                        </div>

                        <div *ngIf="boringCutleryTrayShow" [formGroup]="checkboxForm" class="form-check form-check-reverse">

                            <label class="form-check-label" for="boringCutleryTrayBox">
                                {{ 'Boring til bestikindsats' | translate }}
                            </label>
                            <input class="form-check-input" formControlName="boringCutleryTrayBox" id="boringCutleryTrayBox" type="checkbox" value="">
                        </div>

                        <div *ngIf="showBottomUnmounted" [formGroup]="checkboxForm" class="form-check form-check-reverse">
                            <label class="form-check-label" for="bottomUnmountedCheckbox">
                                {{ 'Løs bund' | translate }}
                            </label>
                            <input (change)="drawerBaseMutex('separate')" class="form-check-input" formControlName="bottomUnmountedCheckbox"
                                   id="bottomUnmountedCheckbox" type="checkbox" value="">
                        </div>

                        <div *ngIf="drawerBaseMountedShow" [formGroup]="checkboxForm" class="form-check form-check-reverse">
                            <label class="form-check-label" for="bundSkruetBox">
                                {{ 'Bund skruet i' | translate }}
                            </label>
                            <input (change)="drawerBaseMutex('mounted')" class="form-check-input" formControlName="bundSkruetBox"
                                   id="bundSkruetBox" type="checkbox" value="">
                        </div>

                        <div *ngIf="skuffe20mmFrontShow" [formGroup]="checkboxForm" class="form-check form-check-reverse">
                            <label class="form-check-label" for="skuffe20mmBox">
                                {{ '20mm front (Tillæg 60%)' | translate }}
                            </label>
                            <input class="form-check-input" formControlName="skuffe20mmFrontBox" id="skuffe20mmBox" type="checkbox" value="">
                        </div>

                        <div *ngIf="ligeOverkantShow" [formGroup]="checkboxForm" class="form-check form-check-reverse">
                            <label class="form-check-label" for="ligeOverkantBox">
                                {{ 'Lige Overkant (Straight Line)' | translate }}
                            </label>
                            <input class="form-check-input" formControlName="ligeOverkantBox" id="ligeOverkantBox" type="checkbox" value="">
                        </div>

                        <div *ngIf="fscShow" [formGroup]="checkboxForm" class="form-check form-check-reverse">
                            <label class="form-check-label" for="fscBox">
                                {{ 'CARTDETAILS.fsc' | translate }}
                            </label>
                            <input class="form-check-input" formControlName="fscBox" id="fscBox" type="checkbox" value="">
                        </div>

                        <div *ngIf="surfaceTreatmentUndersideShow" [formGroup]="checkboxForm" class="form-check form-check-reverse">
                            <label class="form-check-label" for="surfaceTreatmentUndersideBox">
                                {{ 'CARTDETAILS.surfaceTreatmentUnderside' | translate }}
                            </label>
                            <input class="form-check-input" formControlName="surfaceTreatmentUndersideBox" id="surfaceTreatmentUndersideBox" type="checkbox" value="">
                        </div>

                        <div *ngIf="boerstetShow" [formGroup]="checkboxForm" class="form-check form-check-reverse">
                            <label class="form-check-label" for="boerstetBox">
                                {{ 'Børstet indvendigt/udvendigt (Tillæg 35%)' | translate }}
                            </label>
                            <input class="form-check-input" formControlName="boerstetBox" id="boerstetBox" type="checkbox" value="">
                        </div>
                        <div *ngIf="hulboringShow" [formGroup]="checkboxForm" class="form-check form-check-reverse">
                            <label class="form-check-label" for="hulboringBox">
                                {{ 'hulboring' | translate }}
                            </label>
                            <input class="form-check-input" formControlName="hulboringBox" id="hulboringBox" type="checkbox" value="">
                        </div>
                        <div *ngIf="isErnstMair && this.userService.isLoggedIn && this.userService.isAdmin()" [formGroup]="checkboxForm"
                             class="form-check form-check form-check-reverse">
                            <label class="form-check-label" for="upPricesBox" translate="CART.UP_Prices"></label>
                            <input class="form-check-input" formControlName="upPricesBox" id="upPricesBox" type="checkbox">
                        </div>

                    </div>
                </div>
                <div class="row">
                    <div class="col-sm">
                        <hr/>
                    </div>
                </div>
                <div class="row mb-5">
                    <div class="col-sm-3">
                        <button #pop="bs-popover" [outsideClick]="true" [popover]="popNulstil" class="btn btn-danger" container="body"
                                type="button">
                            {{ 'Nulstil bestilling' | translate }}&nbsp;<fa-icon [icon]="faTimes"></fa-icon>
                        </button>
                    </div>
                    <div class="col-sm-3 offset-sm-6">
                        <button *blockUI="'blockSubmitButton'" class="btn btn-primary form-control" type="submit">
                            {{ 'Videre' | translate }}&nbsp;<fa-icon [icon]="faArrowRight"></fa-icon>
                        </button>
                    </div>
                </div>
            </app-dynamic-form>

        </aw-wizard-step>
        <aw-wizard-step [navigationSymbol]="{ symbol: '2' }" [stepTitle]="'Antal & Godkend' | translate">
            <div class="row">
                <div class="col-sm">
                    <hr/>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-2">
                    <button awPreviousStep class="btn btn-primary btn-lg w-100">
                        <fa-icon [icon]="faArrowLeft"></fa-icon>
                        {{ 'UTILITY.BACK' | translate }}
                    </button>
                </div>
                <div class="col-sm">
                    <div [innerHTML]="'GennemseOgGodkend' | translate" class="alert alert-info"></div>
                </div>
            </div>
            <div class="row">

                <div class="col-sm-8">
                    <table class="table table-sm table-hover">
                        <tbody>
                        <tr *ngFor="let opt of prettyOptions || []">
                            <td> {{ opt.key }} </td>
                            <td> {{ opt.value }} </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-sm">

                    <div class="row mb-3">
                        <div class="col-sm">
                            <div *ngIf="!isEdit" class="mb-3">
                                <h5 class="mb-0">
                                    <label for="skuffeAntal" class="form-label" translate="Antal skuffer"></label>
                                </h5>
                                <div class="input-group">
                                    <input id="skuffeAntal" [(ngModel)]="skuffeAntal" class="form-control" min="1" placeholder="Antal" type="number">
                                    <span class="input-group-text" translate="stk"></span>
                                </div>
                            </div>
                            <div *ngIf="isNothegger">
                                <h5 class="mb-0">
                                    <label for="cartItemComment" class="form-label" translate="DRAWER_OPTIONS.LabelText"></label>
                                </h5>
                                <input id="cartItemComment" [(ngModel)]="cartItemComment" [placeholder]="'Placeholder.LabelText' | translate" class="form-control"
                                       type="text">
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm">
                            <button (click)="approveOrder()" class="btn btn-lg btn-success">
                                {{ approveBtnText | translate }}
                                <fa-icon *ngIf="approveBtnText === 'Godkend'" [icon]="faCheck"></fa-icon>
                                <fa-icon *ngIf="approveBtnText === 'Gem rettelser'" [icon]="faSave"></fa-icon>
                            </button>
                        </div>
                    </div>

                </div>
            </div>
            <div class="row">
                <div class="col-sm mb-5">
                    <hr/>
                </div>
            </div>
        </aw-wizard-step>
    </aw-wizard>
</div>


<!-- modals -->
<app-root-modal #udspModal>
    <div class="modal-header">
        <h4 class="modal-title">{{ 'Tilføj Udsparing' | translate }}</h4>
        <button (click)="udspModal.hide()" aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
    </div>
    <div class="modal-body">
        <app-siphon-cutting *ngIf="isDrawerType('rear-ezug') === false" [defaults]="siphonCuttingDefaults"></app-siphon-cutting>
        <app-rear-udsparing *ngIf="isDrawerType('rear-ezug')" [defaults]="rearUdsparingDefaults"></app-rear-udsparing>
    </div>
    <div class="modal-footer">
        <button (click)="confirmUdsparing();" class="btn btn-primary" translate="UTILITY.SAVE" type="button"></button>
        <button (click)="udspModal.hide()" class="btn btn-secondary" data-dismiss="modal" translate="UTILITY.CANCEL" type="button"></button>
    </div>
</app-root-modal>


<!-- pop up templates -->
<ng-template #popDelBestikindsats>
    <div class="text-center">
        <div class="btn-group" role="group">
            {{ 'SikkerPåDuVilFjerneBestikindsatsen' | translate }} &nbsp;&nbsp;
            <button (click)="deleteBestikindsats();" class="btn btn-sm btn-danger">{{ 'Fjern' | translate }}</button>
            <button (click)="pop.hide()" class="btn btn-sm btn-secondary">{{ 'Nej' | translate }}</button>
        </div>
    </div>
</ng-template>

<ng-template #popDelUdsparing>
    <div class="text-center">
        <div class="btn-group" role="group">
            {{ 'Er du sikker på at du vil fjerne udsparingen' | translate }} &nbsp;&nbsp;
            <button (click)="deleteUdsparing();" class="btn btn-sm btn-danger">{{ 'Fjern' | translate }}</button>
            <button (click)="pop.hide()" class="btn btn-sm btn-secondary">{{ 'Nej' | translate }}</button>
        </div>
    </div>
</ng-template>

<ng-template #popDelLogo>
    <div class="text-center">
        <div class="btn-group" role="group">
            {{ 'Er du sikker på at du vil fjerne logoet' | translate }} &nbsp;&nbsp;
            <button (click)="logo = null;" class="btn btn-sm btn-danger">{{ 'Fjern' | translate }}</button>
            <button (click)="pop.hide()" class="btn btn-sm btn-secondary">{{ 'Nej' | translate }}</button>
        </div>
    </div>
</ng-template>

<ng-template #popNulstil>
    <div class="text-center">
        <div class="btn-group" role="group">
            <button (click)="resetOrder()" class="btn btn-sm btn-danger">{{ 'Nulstil' | translate }}</button>
            <button (click)="pop.hide()" class="btn btn-sm btn-secondary">{{ 'Nej' | translate }}</button>
        </div>
    </div>
</ng-template>

<app-root-modal #bestikModal>
    <div class="modal-header">
        <h4 class="modal-title">{{ 'Tilføj Bestikindsats' | translate }}</h4>
        <button (click)="bestikModal.hide()" aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
    </div>
    <div class="modal-body">
        <app-sortissimo #bscomp [widthDepthHidden]="true"></app-sortissimo>
    </div>
    <div class="modal-footer">
        <button (click)="confirmBestikindsats();" class="btn btn-primary" translate="UTILITY.SAVE" type="button"></button>
        <button (click)="this.bestikModal.hide();" class="btn btn-secondary" data-dismiss="modal" translate="UTILITY.CANCEL" type="button"></button>
    </div>
</app-root-modal>
