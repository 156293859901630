import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { faUserCheck } from '@fortawesome/free-solid-svg-icons/faUserCheck';

import { IUser } from '../../../../../wdcommon/IUser';
import { APIService } from '../../services';


@Component({
  selector: 'app-admin-approve-user',
  templateUrl: './admin-approve-user.component.html',
  styleUrls: ['./admin-approve-user.component.css']
})
export class AdminApproveUserComponent {
  @Input() user: IUser;
  @Input() activatingEmail: string;
  @Output() activated: EventEmitter<boolean> = new EventEmitter();
  @Output() failed: EventEmitter<boolean> = new EventEmitter();

  faUserCheck = faUserCheck;

  activating = false;
  modalRef: BsModalRef;

  constructor(
      public modalService: BsModalService,
      private apiService: APIService,
      private toastrService: ToastrService,
  ) { }

  async activateUser() {
    this.activating = true;
    const user = await this.apiService.activateUser(this.user.id);
    if (!user.activated) {
      this.toastrService.error('FEJL. Brugeren blev ikke aktiveret. ' + user['message']);
      this.failed.emit(true);
    } else {
      this.toastrService.success('Brugeren er aktiveret');
      this.activated.emit(true);
    }
    this.modalRef.hide();
    this.activating = false;
  }

}
