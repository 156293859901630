import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  constructor() { }

  public abcSort(a, b) {
    const aName = a.value.toUpperCase();
    const bName = b.value.toUpperCase();
    return (aName < bName) ? -1 : (aName > bName) ? 1 : 0;
  }

  public arrayToObjectArray(arr) {
    const temp = [];
    arr.forEach(v => {
      temp.push({
        'label': v, 'value': v
      });
    });
    return temp;
  }

  // public async asyncForEach<T, R>(array: T[], callback: (value: T, index?: number, array?: T[]) => Promise<R>) {
  //   for (let index = 0; index < array.length; index++) {
  //     await callback(array[index], index, array);
  //   }
  // }

  public isInternetExplorer({ userAgent, appVersion }: { userAgent: string, appVersion: string; }) {
    return (!userAgent || userAgent.indexOf('MSIE') !== -1)
      || (!appVersion || appVersion.indexOf('Trident/') > 0);
  }

  public groupBy<T extends {}>(items: T[], keySelector: (i: T) => string): { [key: string]: T[]; } {
    const groups: { [key: string]: T[]; } = {};

    for (const item of items || []) {

      const key = keySelector(item);

      if (groups[key])
        groups[key].push(item);
      else
        groups[key] = [item];
    }

    return groups;
  }

  public addAt<T>(array: T[], item: T, index: number): T[] {

    return array.slice(0, index).concat([item]).concat(array.slice(index, array.length));

  }

  static sortByPropertyName<T>(elements: T[], propertyName: keyof T, order: 'asc' | 'desc'): T[] {
    elements.sort((a, b) => {
      if (a[propertyName] < b[propertyName])
        return -1;

      if (a[propertyName] > b[propertyName])
        return 1;

      return 0;
    });

    if (order === 'desc')
      return elements.reverse();

    return elements;
  }
}
