import { Component, EventEmitter, Input, Output } from '@angular/core';

import { PaletteColour, ParsedPaletteColour } from '../../../../../wdcommon/Colours';
import { getContrastYIQ } from '../colorConverter';

const RalColors: PaletteColour[] = require('./RalColors.json');


@Component({
  selector: 'app-ral-color-picker',
  templateUrl: './ral-color-picker.component.html',
  styleUrls: ['./ral-color-picker.component.css']
})
export class RalColorPickerComponent {
  fallbackHex = '#FFFFFF';

  @Input() colour: ParsedPaletteColour = {
    HEX: this.fallbackHex,
    success: false,
    Name: ''
  };
  @Output() colourChange = new EventEmitter<ParsedPaletteColour>();
  search = '';

  constructor() {
  }

  getRalColors() {
    return RalColors.filter((color) => {
      if (this.search && this.search.length > 0)
        return Object.values(color)
            .some((ralValue) =>
                String(ralValue)
                    .toLowerCase()
                    .includes((this.search.replace(/RAL/gi, '').trim()).toLowerCase())
            );

      return true;
    });
  }

  getContrast(hexColour: string) {
    return getContrastYIQ(hexColour);
  }

  searchColors(event: KeyboardEvent) {
    this.search = (event.target as HTMLInputElement).value;
  }

  selectColor(colour: PaletteColour) {
    this.colour.success = true;
    this.colour.RAL = colour.RAL;
    this.colour.HEX = colour.HEX;
    if (this.colour.Name !== 'RAL ' + colour.RAL) {
      this.colour.Name = 'RAL ' + colour.RAL;
      this.colourChange.emit(this.colour);
    }
    document.body.click();
  }

}
