import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';

import { SettingService, UserService } from '../../services';


@Component({
  selector: 'app-quality',
  templateUrl: './quality.component.html',
  styleUrls: ['./quality.component.css']
})
export class FaqQualityComponent implements OnInit {
  faEdit = faEdit;

  faqQuality: string;

  constructor(private router: Router, private settingService: SettingService, public userService: UserService) {
  }

  async ngOnInit() {
    this.faqQuality = (await this.settingService.getTextSetting('faq-quality')).text;
  }

  async edit() {
    await this.router.navigateByUrl('/admin/faq/quality');
  }

}
