import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-display-ernst-mair-rear-ezug',
  templateUrl: './display-ernst-mair-rear-ezug.component.html',
  styleUrls: ['./display-ernst-mair-rear-ezug.component.css']
})
export class DisplayErnstMairRearEzugComponent implements OnInit, AfterViewInit {

  @Input() isLocked: boolean;

  @ViewChild('rearEzugCanvas') rearEzugCanvas: ElementRef;

  rearEzugMaal = {
    ab: 70,
    cd: 84,
    special: false
  };

  rearEzugHoejder = [
    {
      label: '84',
      value: 84
    },
    {
      label: '100',
      value: 100
    },
    {
      label: '116',
      value: 116
    },
  ];

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.drawRearEzug();
  }

  drawRearEzug() {
    console.debug('Drawing rearEzugCanvas');
    const ctx: CanvasRenderingContext2D =
      this.rearEzugCanvas.nativeElement.getContext('2d');

    // ezug path
    ctx.fillStyle = '#FFF';
    ctx.fillRect(0, 0, 500, 500);
    ctx.fillStyle = '#000';
    const outerPath = new Path2D('M 30 40 L 65 40 L 65 60 C 65 70 80 70 80 70 L 205 70 C 200 70 215 70 215 60 L 215 40 L 250 40 L 250 120 L 30 120 L 30 40 Z');
    ctx.font = '14px Arial';
    ctx.stroke(outerPath);

    // hjælpelinjer
    ctx.moveTo(35, 30);
    ctx.lineTo(65, 30);
    ctx.fillText('A         B', 24, 35);

    ctx.fillText('C', 138, 65);
    ctx.moveTo(142.5, 75);
    ctx.lineTo(142.5, 115);
    ctx.fillText('D', 138, 135);

    ctx.textAlign = 'center';
    ctx.fillText('min. 50 mm.', 64, 15);
    ctx.textAlign = 'left';
    // ctx.fillText(cd + '', 225, 95);

    ctx.stroke();
    //  markeringer (a-b, c-e, d-f)
  }

}
