<div class="container">
    <div class="row mb-3">

        <div class="col-12">
            <app-breadcrumbs [currentBreadcrumb]="profilesBreadcrumb"></app-breadcrumbs>
        </div>

    </div>
    <div class="row">

        <div class="col-sm-7">
            <form [formGroup]="profilesForm" novalidate>
                <div class="mb-3">
                    <div class="col-md-6">
                        <label class="form-label" for="typeOfWood">{{ 'Træsort' | translate }}</label>
                        <select [formControlName]="typeOfWoodVar" class="form-control" id="typeOfWood">
                            <option value="null">{{ 'Vælg træsort' | translate }}</option>
                            <option *ngFor="let t of typesOfWood"
                                    value="{{ t.value }}">{{ t.label | translate }}</option>
                        </select>
                    </div>
                    <div class="col-md-6">
                        <label class="form-label" for="surfaceTreatment">{{ 'Overflade' | translate}}</label>
                        <select [formControlName]="surfaceTreatmentVar" class="form-control" id="surfaceTreatment">
                            <option value="null">{{'Vælg overflade' | translate}}</option>
                            <option *ngFor="let t of treatments"
                                    value="{{ t.value }}">{{ t.label | translate }}</option>
                        </select>
                    </div>
                </div>
                <div class="mb-3">
                    <div class="col-md-6">
                        <label class="form-label">{{ 'Højde' | translate }}</label>
                        <div class="input-group">
                            <select [formControlName]="heightVar" class="form-control">
                                <option value="null">{{'Vælg højde' | translate }}</option>
                                <option value="75">75</option>
                                <option value="95">95</option>
                                <option value="115">115</option>
                                <option value="135">135</option>
                            </select>
                            <div class="input-group-text">mm</div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label class="form-label">{{'Længde' | translate}}</label>
                        <div class="input-group">
                            <select class="form-control" formControlName='length'>
                                <option value="null">{{'Vælg længde' | translate}}</option>
                                <!--                                <option value="800">800</option>-->
                                <option value="1000">1000</option>
                                <option value="1200">1200</option>
                                <option value="2000">2000</option>
                            </select>
                            <div class="input-group-text">mm</div>
                        </div>
                    </div>
                </div>
                <div class="mb-3">
                    <div class="col-md-6">
                        <label class="form-label">{{'Tykkelse' | translate}}</label>
                        <div class="input-group">
                            <select class="form-control" [formControlName]="OptionProperty.thickness">
                                <option value="14">14</option>
                                <option value="40">40</option>
                            </select>
                            <div class="input-group-text">mm</div>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="float-end">
                    <button (click)="validateAllFormFields(this.profilesForm) && showAddToCartModal()" class="btn btn-primary"
                            type="submit">
                        {{ 'Videre' | translate }}
                        <fa-icon [icon]="faArrowRight"></fa-icon>
                    </button>
                </div>
            </form>
        </div>

    </div>
</div>