import { TranslateService } from '@ngx-translate/core';

import { HettichService, PriceCalculatorService } from '../';
import { Cart, CartItem } from './CartItem';
import { ITypedAddCartItem } from '../../../../../wdcommon/ICartItem';
import { ICompanyAdjustments } from '../../../../../wdcommon/ICompany';


export class OtherCartItem extends CartItem {

  constructor(
      private ___translateService: TranslateService,
      private priceService: PriceCalculatorService,
      private companyAdjustmentsProvider: () => Promise<ICompanyAdjustments>,
      private _extra: ITypedAddCartItem,
      private hettichService: HettichService,
      private _amountComputer?: (cart: Cart) => number | Promise<number>,
  ) {
    super(___translateService, _extra, _amountComputer);
  }

  protected async calculatePrice(): Promise<number> {
    const price = await this.priceService.CalculateOthers(this as ITypedAddCartItem, await this.companyAdjustmentsProvider());

    if (typeof (price) === 'number')
      return price;

    console.error(`Unable to calculate price for other ${this.itemno} got: ${price}.`);
    return 0;
  }

  protected setHettichBrandId() {
    this.hettichService.getHettichBrandId()
        .then((hettichBrandId) => {
          this.brandId = hettichBrandId;
        });
  }

}
