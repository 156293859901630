<div class="card border-light shadow-sm rounded">
    <div class="card-header">{{displayLanguage}}</div>

    <div class="card-body">
        <div [formGroup]="group">
            <div class="row mb-3">
                <div *ngIf="group.contains('headline')" class="col-sm-12">
                    <label class="form-label">Overskrift</label>
                    <input class="form-control" formControlName="headline" type="text">
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12">
                    <label class="form-label">Tekst</label>
                    <div>
                        <quill-editor formControlName="text"></quill-editor>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
