import { Injectable } from '@angular/core';

import { APIService , LocaleService } from './';
import { Breadcrumbs, specialsPrefix, productPrefix, productsUrl } from '../utils/breadcrumbs/breadcrumbs';
import { Constants } from '../../../../wdcommon/Constants';


const baseUrl = '/' + productsUrl + '/' + specialsPrefix;

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService {

  constructor(
      private api: APIService,
      private localeService: LocaleService,
  ) {
  }

  public async createSpecialBreadcrumbs(categoryId: number): Promise<Breadcrumbs> {
    const localeId = this.localeService.getLanguage();
    const tree = await this.api.getCategoryTree(categoryId, localeId);
    if (!tree || !tree.length) {
      return null;
    }

    const breadcrumbs: Breadcrumbs = {};
    for (let i = 0; i < tree.length; i++) {
      const parentId = (tree[i - 1] ? specialsPrefix + tree[i - 1].id : null);

      breadcrumbs[specialsPrefix + tree[i].id] = {
        url: (parentId ? breadcrumbs[parentId].url + '/' : baseUrl) + tree[i].id,
        name: tree[i].name,
        parentId: parentId
      };
    }

    return breadcrumbs;
  }

  public async getProductUrlAndBrandId(productId: number, categoryId: number): Promise<{brandId: string, productUrl: string}> {
    const tree = await this.api.getCategoryTree(categoryId, 'da');
    if (!tree || !tree.length) {
      return null;
    }

    let url = '/' + productsUrl + '/' + specialsPrefix;
    tree.forEach((category) => {
      url += category.id + '/';
    });

    return {
      productUrl: url + '/' + productPrefix + productId,
      brandId: Constants.specialsCategoryBrandIdPrefix + tree[0].id,
    };
  }

}
