import { Injectable } from '@angular/core';

import { APIService, LocaleService } from '.';
import { IIFreightSetting, IInfoSetting, ITextSetting } from '../../../../wdcommon/ISetting';


@Injectable({
  providedIn: 'root'
})
export class SettingService {
  private readonly freightKey = 'freight';
  private readonly infoKeyPrefix = 'info-';

  constructor(
      private apiService: APIService,
      private localeService: LocaleService
  ) {
  }

  async getTextSettingByLocale(locale: string, settingId: string) {
    const key = settingId + '-' + locale;
    const setting = await this.apiService.getSetting(key);
    try {
      return <ITextSetting>JSON.parse(setting.value);
    } catch {
      console.warn('Could not find text setting, ' + settingId + ', from lang: ' + locale);
      this.apiService.clearSettingFromCache(key);
    }
    return <ITextSetting>{ text: '' };
  }

  getTextSetting(keyId: string) {
    return this.getTextSettingByLocale(this.localeService.getLanguage(), keyId);
  }

  async getInfoSettingByLocale(locale: string) {
    const key = this.infoKeyPrefix + locale;
    const setting = await this.apiService.getSetting(key);
    try {
      return <IInfoSetting>JSON.parse(setting.value);
    } catch {
      console.warn('Could not find info setting from lang: ' + locale);
      this.apiService.clearSettingFromCache(key);
    }
    return <IInfoSetting>{ headline: '', text: '' };
  }

  async getFreightSetting() {
    try {
      const setting = await this.apiService.getSetting(this.freightKey);
      return <IIFreightSetting>JSON.parse(setting.value);
    } catch {
      console.warn('Could not find freight setting: ', this.freightKey);
      this.apiService.clearSettingFromCache(this.freightKey);
    }

    // Fallback to these default values (also used for tests)
    return <IIFreightSetting>{
      oneDelivery: {
        doublePalletLimit: 1200,
        palletCapacity: 20,
        palletFreight: {
          dk: 800,
          no: 800,
          se: 800,
        },
      },
      purewood: {
        colourStartup: {
          fee: {
            dk: 250,
            no: 250,
            se: 250,
          },
          threshold: 5000,
        },
        front: {
          baseCost: {
            dk: 300,
            no: 300,
            se: 300,
          },
          costPerM2: {
            dk: 50,
            no: 50,
            se: 50,
          },
          doublePalletLimit: 1200,
          m2ToWeight: 19,
          palletCost: {
            dk: 800,
            no: 800,
            se: 800,
          },
          palletMaxWeight: 400,
          palletWeightThreshold: 100,
        }
      }
    };
  }

  setTextSettingByLocale(locale: string, settingKey: string, setting: ITextSetting) {
    return this.apiService.postSetting(settingKey + '-' + locale, JSON.stringify(setting));
  }

  setInfoSettingByLocale(locale: string, info: IInfoSetting) {
    return this.apiService.postSetting(this.infoKeyPrefix + locale, JSON.stringify(info));
  }

  setFreightSetting(freightSetting: IIFreightSetting) {
    return this.apiService.postSetting(this.freightKey, JSON.stringify(freightSetting));
  }

}
