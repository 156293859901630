import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { TranslatingBase } from '../../base-component/ComponentBase';
import { IOrderLayoutViewMode } from '../iorder-layout';
import { ICartItem } from '../../../../../wdcommon/ICartItem';
import { UserService } from '../../services/user.service';


@Component({
  selector: 'app-order-layout-body-type',
  templateUrl: './order-layout-body-type.component.html',
  styleUrls: ['./order-layout-body-type.component.css']
})
export class OrderLayoutBodyTypeComponent extends TranslatingBase {
  @Input() translateItemName = false;
  @Input() typeName: string;
  @Input() items: ICartItem[];
  @Input() viewMode: IOrderLayoutViewMode;
  @Output() repeatDrawer = new EventEmitter<ICartItem>();
  @Output() repeatFront = new EventEmitter<ICartItem>();

  constructor(
      public translateService: TranslateService,
      private userService: UserService,
  ) {
    super(translateService);
  }

  get isUserLoggedIn() {
    return this.userService.isLoggedIn;  //this.viewMode !== IOrderLayoutViewMode.cart_offline;
  }
  get hasDiscount() {
    return this.items.some((i) => i.discountPercentage);
  }

  getItemName(item: ICartItem) {
    return (this.translateItemName ? this.translate(item.name) : item.name);
  }

  setRepeatDrawer(item: ICartItem) {
    this.repeatDrawer.emit(item);
  }

  setRepeatFront(item: ICartItem) {
    this.repeatFront.emit(item);
  }
}
