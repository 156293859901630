<div *ngIf="company" class="container">
    <div class="row mb-3">

        <div class="col-6">
            <app-breadcrumbs [currentBreadcrumb]="adminCompanyBreadcrumb"></app-breadcrumbs>
        </div>

        <div class="col-6 text-end">
            <button (click)="modalRef = modalService.show(contactCustomer, { class: 'modal-md'})" class="btn btn-primary">
                Kontakt Firma &nbsp;&nbsp;
                <fa-icon [icon]="faEnvelopeOpen"></fa-icon>
            </button>&nbsp;
            <button (click)="modalRef = modalService.show(sureYouWantToDelete, { class: 'modal-md'})" class="btn btn-danger">
                Slet firma &nbsp;&nbsp;<fa-icon [icon]="faTrash"></fa-icon>
            </button>&nbsp;
        </div>

    </div>
    <div class="row mb-5">
        <div class="col-sm">
            <form (ngSubmit)="adminUpdateCompany()" [formGroup]="companyInfoForm" novalidate>
                <div class="row mb-3">
                    <div class="col-sm">
                        <label class="form-label">Firmanavn</label>
                        <input class="form-control" formControlName="companyName" placeholder="Firmanavn" type="text">
                    </div>
                    <div class="col-sm-2">
                        <label class="form-label" for="CVRNumber">CVR Nummer</label>
                        <input class="form-control" formControlName="CVRNumber" id="CVRNumber" placeholder="cvr" type="text">
                    </div>
                    <div class="col-sm-2">
                        <label class="form-label" for="fakturaKonto">Faktura Konto</label>
                        <input class="form-control" formControlName="fakturaKonto" id="fakturaKonto" placeholder="Faktura Konto" type="text">
                    </div>
                    <div class="col-sm-2">
                        <label class="form-label" for="economicsNummer">e-conomics Nummer</label>
                        <input class="form-control" formControlName="economicsNumber" id="economicsNummer" placeholder="e-conomics Nummer"
                               type="text">
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-sm">
                        <label class="form-label">Adresse</label>
                        <input class="form-control" formControlName="address" name="address" placeholder="Adresse" type="text">
                    </div>
                    <div class="col-sm">
                        <label class="form-label">&nbsp;</label>
                        <input class="form-control" formControlName="address2" name="address2" placeholder="Adresse (fortsat)" type="text">
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-sm">
                        <label class="form-label">Postnr.</label>
                        <input class="form-control" formControlName="postcode" placeholder="Postnr." type="text">
                    </div>
                    <div class="col-sm">
                        <label class="form-label">By</label>
                        <input class="form-control" formControlName="city" placeholder="By" type="text">
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-sm">
                        <label class="form-label">Email</label>
                        <input class="form-control" formControlName="email" placeholder="Email" type="text">
                    </div>
                    <div class="col-sm">
                        <label class="form-label">Telefon</label>
                        <input class="form-control" formControlName="phone" placeholder="Telefon" type="text">
                    </div>
                    <div class="col-sm">
                        <label class="form-label" for="vat">Moms</label>
                        <select class="form-control" formControlName="vat" id="vat">
                            <option [ngValue]="true">Ja</option>
                            <option [ngValue]="false">Nej</option>
                        </select>
                    </div>
                    <div class="col-sm">
                        <label class="form-label" for="freightTier">Fragt klasse</label>
                        <select class="form-control" formControlName="freightTier" id="freightTier">
                            <option value="dk">Danmark</option>
                            <option value="no">Norge</option>
                            <option value="se">Sverige</option>
                        </select>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm">
                        <button class="btn btn-primary float-end" type="submit">
                            Opdatér Firma &nbsp;<fa-icon [icon]="faSync"></fa-icon>
                        </button>
                    </div>
                </div>
            </form>
        </div>

    </div>
    <div class="row bg-primary text-white py-3 body-type-header">

        <div class="col">
            Prisjustering per leverandør for {{company.companyName}}
        </div>

    </div>
    <div class="row">

        <div class="col-2 mt-3">
            <label [for]="manufacturer.nothegger" class="form-label">Nothegger</label>
            <div class="input-group">
                <input [id]="manufacturer.nothegger" [(ngModel)]="adjusts[manufacturer.nothegger]" class="form-control" type="number"/>
                <span class="input-group-text">%</span>
            </div>
        </div>

        <div class="col-2 mt-3">
            <label [for]="manufacturer.purewood" class="form-label">Purewood</label>
            <div class="input-group">
                <input [id]="manufacturer.purewood" [(ngModel)]="adjusts[manufacturer.purewood]" class="form-control" type="number"/>
                <span class="input-group-text">%</span>
            </div>
        </div>
        <div class="col-2 mt-3">
            <label [for]="manufacturerWithSuffix.PurewoodDrawer" class="form-label">Purewood Drawer</label>
            <div class="input-group">
                <input [id]="manufacturerWithSuffix.PurewoodDrawer" [(ngModel)]="adjusts[manufacturerWithSuffix.PurewoodDrawer]" class="form-control" type="number"/>
                <span class="input-group-text">%</span>
            </div>
        </div>
        <div class="col-2 mt-3">
            <label [for]="manufacturer.ernstMair" class="form-label">Ernst Mair</label>
            <div class="input-group">
                <input [id]="manufacturer.ernstMair" [(ngModel)]="adjusts[manufacturer.ernstMair]" class="form-control" type="number"/>
                <span class="input-group-text">%</span>
            </div>
        </div>
        <div *ngFor="let category of categories" class="col-2 mt-3">
            <label [for]="'cat-' + category.id" class="form-label">{{category.name}}</label>
            <div class="input-group">
                <input [id]="'cat-' + category.id" [(ngModel)]="adjusts[Constants.specialsCategoryBrandIdPrefix + category.id]" class="form-control" type="number"/>
                <span class="input-group-text">%</span>
            </div>
        </div>

    </div>
    <div class="row mt-3">

        <div class="col-12 text-end">
            <button [disabled]="saving || disableSaveAdjusts()" (click)="saveAdjusts()" class="btn btn-primary">
                <span [class.d-none]="!saving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Gem prisjusteringer
            </button>
        </div>

    </div>
</div>


<ng-template #sureYouWantToDelete>
    <div class="modal-header">
        <h4 class="modal-title">Slet firma</h4>
        <button (click)="modalRef.hide()" aria-label="Close" class="btn-close" type="button"></button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-sm">
                <p>Er du sikker på at du vil slette firmaet {{ company.companyName }}?</p>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button (click)="deleteCompany()"
                class="btn btn-danger"
                data-dismiss="modal"
                type="button">Slet
        </button>
        <button (click)="modalRef.hide()"
                class="btn btn-secondary"
                data-dismiss="modal"
                type="button">Annullér
        </button>
    </div>
</ng-template>

<ng-template #contactCustomer>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">Kontakt Firma</h5>
            <button (click)="modalRef.hide()" aria-label="Close" class="btn-close" type="button"></button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-sm">
                    <label class="form-label">Email</label>
                    <input [(ngModel)]="customerMail.email" class="form-control" placeholder="Firma email">
                </div>
            </div>
            <div class="row">
                <div class="col-sm">
                    <label class="form-label">Emne</label>
                    <input [(ngModel)]="customerMail.subject" class="form-control" type="text">
                </div>
            </div>
            <div class="row">
                <div class="col-sm">
                    <label class="form-label">Besked</label>
                    <textarea [(ngModel)]="customerMail.message" class="form-control"></textarea>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button (click)="sendCompanyEmail()"
                    class="btn btn-primary"
                    type="button">Send
            </button>
            <button (click)="modalRef.hide()"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                    type="button">Annullér
            </button>
        </div>
    </div>
</ng-template>
