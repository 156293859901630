<a [outsideClick]="true" [popover]="pickColor" placement="right" [style.background]="colour.success ? colour.HEX : 'transparent'"
   [style.border-style]="colour.success ? 'solid' : 'dashed'" class="btn border-dark">
    <span [style.color]="getContrast(colour.success ? colour.HEX : fallbackHex)">{{ colour.success ? 'RAL ' + colour.RAL : 'CHOOSE_COLOR' | translate }}</span>
</a>

<ng-template #pickColor>
    <div class="ral-colour-group">
        <label class="form-label" for="search">{{'UTILITY.SEARCH' | translate}}</label>
        <input (keyup)="searchColors($event)" [value]="search" class="form-control" id="search" placeholder="" type="text">
        <div class="colors-container">
            <div (click)="selectColor(colour)" *ngFor="let colour of getRalColors()" [style.background]="colour.HEX" class="color-item">
                <span [style.color]="getContrast(colour.HEX)">{{ colour.RAL }}</span>
            </div>
        </div>
    </div>
</ng-template>
