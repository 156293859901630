<div class="container">
    <div class="row mb-3">

        <div class="col-12">
            <app-breadcrumbs [currentBreadcrumb]="ernstMairBreadcrumb"></app-breadcrumbs>
        </div>

    </div>
    <div class="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-5 g-4">
        <ng-container *ngFor="let drawerRow of ernstMairDrawerRows">

            <div *ngFor="let drawer of drawerRow" class="col">

                <div (click)="orderErnstMair(drawer)" *ngIf="drawer.id > 0" class="card wd-card h-100">
                    <div *ngIf="drawer.enabled === false"
                         class="watermark-text">{{'Products.TemporaryUnavailable' | translate }}</div>
                    <div class="card-header">{{ drawer.name | translate }}</div>
                    <div class="card-body d-flex align-items-center justify-content-center flex-column">
                        <img [src]="drawer.img" alt="" class="img-fluid">
                        <p class="card-text">{{ drawer.description }}</p>
                    </div>
                    <div *appAdminViewOnly class="card-footer">
                        Tilgængelighed
                        <button [tooltip]="'Gør denne vare ' + drawer.enabled ? 'utilgængelig' : 'tilgængelig'"
                                class="btn btn-success btn-sm float-end">
                            <fa-icon (click)="alterAvailability(drawer)" [icon]="drawer.enabled ? faCheckSquare : faSquare"></fa-icon>
                        </button>
                    </div>
                </div>

                <div *ngIf="drawer.id === -1" class="card border-0"></div>

            </div>

        </ng-container>
    </div>
</div>
