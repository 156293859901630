<div class="container">

    <div class="row">
        <div class="col-sm">
            <div class="card">
                <div class="card-header">
                    {{ 'NAVBAR.FAQ' | translate }}
                    <div *ngIf="this.userService.isAdmin()" class="float-end edit-icon">
                        <button (click)="edit()" class="btn btn-sm" type="button">
                            <fa-icon [icon]="faEdit"></fa-icon>
                        </button>
                    </div>
                </div>
                <div class="card-body">
                    <div [innerHTML]="faqGeneral" class="ql-editor p-0"></div>
                    <br>
                    <h5 *ngIf="userService.isLoggedIn"
                        class="card-title">
                        {{ 'FAQ_GENERAL.SHIPPING_COSTS' | translate }} - {{ freightArea }}
                    </h5>
                    <h5 *ngIf="!userService.isLoggedIn"
                        class="card-title">
                        {{ 'FAQ_GENERAL.SHIPPING_COSTS' | translate }}
                    </h5>
                    <p [innerHTML]="freightDesc">
                    </p>
                    <div *ngIf="showFreightTable">
                        <table class="table table-sm table-striped">
                            <thead>
                            <tr>
                                <th>{{ 'UTILITY.AMOUNT_OF' | translate }} {{ 'UTILITY.DRAWERS' | translate | lowercase }}</th>
                                <th>{{ 'FAQ_GENERAL.SHIPPING_COSTS' | translate }} pr. {{ 'UTILITY.DRAWER' | translate | lowercase }}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let p of freightPrices">
                                <td>
                                    {{ p.antalSkuffer }}
                                </td>
                                <td>
                                    {{ p.pris }} {{ 'USED_CURRENCY' | translate }}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <p>
                            {{minFreightText}}
                        </p>
                    </div>

                </div>
            </div>
        </div>
    </div>

</div>
