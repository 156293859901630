<div class="container">

    <div class="row">
        <div class="col-sm">
            <div class="card">
                <div class="card-header">
                    {{ 'NAVBAR.FAQ' | translate }}
                    <div *ngIf="this.userService.isAdmin()" class="float-end edit-icon">
                        <button (click)="edit()" class="btn btn-sm" type="button">
                            <fa-icon [icon]="faEdit"></fa-icon>
                        </button>
                    </div>
                </div>
                <div class="card-body">
                    <div [innerHTML]="faqQuality" class="ql-editor p-0"></div>
                </div>
            </div>
        </div>
    </div>

</div>
