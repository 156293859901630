<div class="card border-light shadow-sm rounded">
    <div class="card-header">
        <app-remove-button (delete)="deleteLocale()" [disabled]="!canBeDeleted" class="float-end"></app-remove-button>
        {{displayLanguage}}
    </div>

    <div class="card-body">
        <div [formGroup]="group">
            <div class="row">
                <div class="col-sm-12 mb-3">
                    <label class="form-label">{{'Produktnavn' | translate}}</label>
                    <input class="form-control" formControlName="name" required type="text">
                </div>

                <div class="col-sm-12 mb-3">
                    <label class="form-label">{{'UTILITY.DESC' | translate }}</label>
                    <textarea Rows="10" class="form-control" formControlName="description"></textarea>
                </div>

                <div class="col-sm-3">
                    <label class="form-label">{{'Produktpris' | translate}}</label>
                    <input class="form-control" formControlName="price" id="price" required step="0.01" type="number">
                </div>

                <div class="col-sm-9">
                    <div class="single-line">
                        <label class="form-label">{{'Anbrudsgebyr' | translate}}</label><br/>
                        {{ 'Tillægges ved køb af færre end' | translate }}&nbsp;
                        <input class="form-control" formControlName="breakageFeeCount" step="1" style="width: 60px" type="number">&nbsp; stk.
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
