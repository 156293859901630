import { Component } from '@angular/core';
import { faBuilding } from '@fortawesome/free-regular-svg-icons/faBuilding';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons/faEnvelope';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons/faPhoneAlt';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
  faBuilding = faBuilding;
  faEnvelope = faEnvelope;
  faPhoneAlt = faPhoneAlt;

  gotoUnitPartnerDk() {
    console.log('l');
    window.location.href = 'https://unitpartner.dk/';
  }
}
