import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons/faEnvelopeOpen';
import { faSync } from '@fortawesome/free-solid-svg-icons/faSync';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';

import { APIService } from '../../services';
import { Breadcrumb } from '../../utils/breadcrumbs/breadcrumbs';
import { ICompany, ICompanyAdjustments } from '../../../../../wdcommon/ICompany';
import { Constants } from '../../../../../wdcommon/Constants';
import { IProductCategoryResponse, Manufacturer, ManufacturerWithSuffix } from '../../../../../wdcommon/IProduct';


@Component({
  selector: 'app-admin-company',
  templateUrl: './admin-company.component.html',
  styleUrls: ['./admin-company.component.css']
})
export class AdminCompanyComponent implements OnInit {
  adminCompanyBreadcrumb: Breadcrumb;

  company: ICompany;
  companyID;
  companyInfoForm: UntypedFormGroup;

  modalRef: BsModalRef;

  customerMail = {
    subject: '',
    message: '',
    email: ''
  };

  categories: IProductCategoryResponse[];

  adjusts: ICompanyAdjustments;
  adjustsInDb: string;
  saving = false;

  protected readonly faEnvelopeOpen = faEnvelopeOpen;
  protected readonly faTrash = faTrash;
  protected readonly faSync = faSync;

  protected readonly manufacturer = Manufacturer;
  protected readonly manufacturerWithSuffix = ManufacturerWithSuffix;
  protected readonly Constants = Constants;

  constructor(
      private api: APIService,
      private route: ActivatedRoute,
      private _fb: UntypedFormBuilder,
      private router: Router,
      private toastrService: ToastrService,
      public modalService: BsModalService
  ) {
  }

  ngOnInit() {
    this.route.params
        .subscribe((params) => {
          this.companyID = params['id'];
          this.loadData();
        });
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control.markAsTouched({ onlySelf: true });
    });

    return formGroup.valid;
  }

  adminUpdateCompany() {
    const valid = this.validateAllFormFields(this.companyInfoForm);

    if (valid) {
      const company: ICompany = this.companyInfoForm.value;
      this.api.updateCompanyFromAdmin(company, this.companyID).subscribe((r: any) => {
        if (r.err) {
          return this.toastrService.error(r.message);
        } else {
          this.company.companyName = r.companyName;
          return this.toastrService.success('Firma informationerne er opdateret');
        }
      });
    }
  }

  async deleteCompany() {
    console.log('Deleting delID', this.companyID);

    const companyDeletion = await this.api.deleteCompany(this.companyID);
    console.log('deleted', companyDeletion);
    this.modalRef.hide();
    await this.router.navigateByUrl('/admin');

  }

  sendCompanyEmail() {
    console.log('Sending customer mail', this.customerMail);
    this.api.sendMail(this.customerMail.email, this.customerMail.subject, this.customerMail.message).subscribe((r: any) => {
      if (r.err) {
        return this.toastrService.error(r.message);
      }
      this.toastrService.success('Beskeden er sendt med email til ' + this.customerMail.email);
      this.modalRef.hide();
    });
  }

  saveAdjusts() {
    this.saving = true;
    return this.api.setCompanyAdjusts(this.companyID, this.adjusts)
        .then(() => {
          this.adjustsInDb = JSON.stringify(this.adjusts);
          this.saving = false;
        });
  }

  disableSaveAdjusts() {
    return (this.adjustsInDb === JSON.stringify(this.adjusts));
  }

  private loadData() {
    const companyPromise = this.api.getCompany(this.companyID);
    const adjustPromise = this.api.getCompanyAdjusts(this.companyID);
    const categoriesPromise = this.api.getCategories(0, 'da');

    Promise.all([companyPromise, adjustPromise, categoriesPromise])
        .then(([company, adjusts, categories]) => {
          this.adminCompanyBreadcrumb = {
            name: company.companyName,
            parentId: 'adminCompanies',
            url: '/admin/companies/' + company.id,
          };

          this.companyInfoForm = this._fb.group({
            companyName: [company.companyName, [<any>Validators.required]],
            CVRNumber: [company.CVRNumber, [<any>Validators.required]],
            economicsNumber: [company.economicsNumber],
            fakturaKonto: [company.fakturaKonto],
            address: [company.address, [<any>Validators.required]],
            address2: [company.address2],
            city: [company.city, [<any>Validators.required]],
            postcode: [company.postcode, [<any>Validators.required]],
            email: [company.email, [<any>Validators.required, <any>Validators.email]],
            phone: [company.phone, [<any>Validators.required]],
            vat: [company.vat, [<any>Validators.required]],
            freightTier: [company.freightTier, [<any>Validators.required]]
          });

          this.customerMail.email = company.email;

          this.adjusts = {
            [Manufacturer.nothegger]: adjusts[Manufacturer.nothegger] ?? Constants.notheggerDefaultAdjustment,
            [Manufacturer.purewood]: adjusts[Manufacturer.purewood] ?? 0,
            [ManufacturerWithSuffix.PurewoodDrawer]: adjusts[ManufacturerWithSuffix.PurewoodDrawer] ?? 0,
            [Manufacturer.ernstMair]: adjusts[Manufacturer.ernstMair] ?? 0,
          };
          categories.forEach((category) => {
            this.adjusts[Constants.specialsCategoryBrandIdPrefix + category.id] = adjusts[Constants.specialsCategoryBrandIdPrefix + category.id] ?? 0;
          });
          this.adjustsInDb = JSON.stringify(this.adjusts);

          this.categories = categories;
          this.company = company;
        });
  }

}
