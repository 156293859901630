import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { UserService } from '../services';


@Injectable({
  providedIn: 'root'
})
export class CompanyAuthGuardGuard implements CanActivate {

  constructor(
      private userService: UserService,
      private router: Router,
      private toastrService: ToastrService,
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    const user = this.userService.getUser();
    if (user.isCompanyAdmin === true) {
      return true;
    } else {
      this.router.navigateByUrl('/');
      this.toastrService.error('Du har ikke rettigheder til firma administrationen');
      return false;
    }
  }
}
