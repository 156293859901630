import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import 'moment/locale/da';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faClipboard } from '@fortawesome/free-solid-svg-icons/faClipboard';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons/faShoppingCart';
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { faTruck } from '@fortawesome/free-solid-svg-icons/faTruck';

import { defaultBreadcrumbs } from '../../utils/breadcrumbs/breadcrumbs';
import { ICartItem } from '../../../../../wdcommon/ICartItem';
import { IOrder, IOrderStatus } from '../../../../../wdcommon/IOrder';
import { APIService } from '../../services';


interface AdminOrder extends IOrder {
  warning?: string;
}


@Component({
  selector: 'app-admin-orders',
  templateUrl: './admin-orders.component.html',
  styleUrls: ['./admin-orders.component.css']
})
export class AdminOrdersComponent implements OnInit {
  faCheckCircle = faCheckCircle;
  faClipboard = faClipboard;
  faShoppingCart = faShoppingCart;
  faStar = faStar;
  faTruck = faTruck;

  adminOrdersBreadcrumb = defaultBreadcrumbs.adminOrders;

  displayOrders: AdminOrder[] = [];
  orders: AdminOrder[] = [];
  orderStatuses: IOrderStatus[] = [];

  currentOrderPage = 1;
  modalRef: BsModalRef;

  searchNeedle = '';

  constructor(
      private api: APIService,
      public modalService: BsModalService
  ) {
  }

  async ngOnInit() {
    this.orders = await this.api.getOrders().toPromise();
    this.orders.forEach((order) => {
      const today = moment();
      const warningDeadline = moment(order.deliveryDate).subtract(7, 'd');
      const deliveryDate = moment(order.deliveryDate);
      if (order.status.id !== 4) {
        if (today.isAfter(warningDeadline) && today.isBefore(deliveryDate)) {
          order.warning = 'warning';
        } else if (today.isAfter(deliveryDate)) {
          order.warning = 'severe';
        } else {
          order.warning = 'none';
        }
      }
    });
    this.displayOrders = this.orders;

    this.orderStatuses = await this.api.getOrderStatuses().toPromise();
  }

  // orderPageChanged(event) {
  //   // console.log('change event', event);
  // }

  filter(status) {
    if (!status || status === 'none') {
      this.displayOrders = this.orders;
    } else {
      this.displayOrders = this.orders.filter(o => {
        return o.status.id.toString() === status;
      });
    }
  }

  searchFilter() {
    if (this.searchNeedle.length > 2) {
      this.displayOrders = this.orders.filter(o => {
        const values = Object.values(o);
        let contains = false;
        values.forEach(v => {
          if ((v + '').toLowerCase().indexOf(this.searchNeedle.toLowerCase()) > -1) {
            contains = true;
          }
        });
        return contains;
      });
    } else {
      this.displayOrders = this.orders;
    }
  }

  hasExternalError(externalShopName: string, orderContents: ICartItem[]): boolean {
    let hasError = false;
    orderContents.forEach((cartItem) => {
      if (cartItem.externalShopName === externalShopName && cartItem.externalShopError) {
        hasError = true;
      }
    });
    return hasError;
  }

  async transferOrders() {
    this.api.transferOrders(this.displayOrders).subscribe(res => {
      console.log('Post Order Res', res);
    });
    this.modalRef.hide();
    this.displayOrders.forEach(o => o.status = this.orderStatuses.find(s => s.id === 9));
    this.filter('none');
  }
}
