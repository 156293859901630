import { Validators } from '@angular/forms';

import { OptionProperty } from '../../../../../wdcommon/IProduct';
import { FormRow } from '../../dynamicForm/dynaform.interfaces';
import { FormRowIds } from '../../dynamicForm/standard-rows';
import { StandardSurfaceTreatmentField } from '../../dynamicForm/standard-fields';

export const MODEL: FormRow[] = [{
  id: 'dimensions-row',
  show: true,
  fields: [{
    type: 'input',
    classes: 'col-sm-4',
    inputType: 'number',
    name: OptionProperty.width,
    min: 150,
    max: 1500,
    suffix: 'mm',
    label: 'Bredde',
    placeholder: 'Indtast bredde',
    validations: [{
      name: 'required',
      validator: Validators.required,
      message: 'Organiser.required.Width'
    }, {
      name: 'pattern',
      validator: Validators.pattern(/\d+/),
      message: 'Bredde skal indtastes som et heltal'
    }, {
      name: 'min',
      validator: Validators.min(150),
      message: 'Required.Width.Min.150'
    }, {
      name: 'max',
      validator: Validators.max(1500),
      message: 'Required.Width.Max.1500'
    }]
  }, {
    type: 'input',
    classes: 'col-sm-4',
    inputType: 'number',
    name: OptionProperty.depth,
    label: 'Dybde',
    min: 190,
    max: 1200,
    suffix: 'mm',
    placeholder: 'Organiser.placeholder.Depth',
    validations: [{
      name: 'required',
      validator: Validators.required,
      message: 'Organiser.required.Depth'
    }, {
      name: 'pattern',
      validator: Validators.pattern(/\d+/),
      message: 'Required.Depth.integer'
    }, {
      name: 'min',
      validator: Validators.min(190),
      message: 'Required.Depth.Min.190'
    }, {
      name: 'max',
      validator: Validators.max(1200),
      message: 'Required.Depth.Max.1200'
    }]
  }, {
    type: 'input',
    classes: 'col-sm-4',
    inputType: 'number',
    name: OptionProperty.height,
    label: 'Højde',
    min: 100,
    max: 2300,
    suffix: 'mm',
    placeholder: 'Placeholder.Height',
    validations: [{
      name: 'required',
      validator: Validators.required,
      message: 'Required.Height.entered'
    }, {
      name: 'pattern',
      validator: Validators.pattern(/\d+/),
      message: 'Required.Height.integer'
    }, {
      name: 'min',
      validator: Validators.min(100),
      message: 'Required.Height.Min.100'
    }, {
      name: 'max',
      validator: Validators.max(2300),
      message: 'Required.Height.Max.2300'
    }]
  }]
}, {
  id: 'shelf-row',
  show: true,
  fields: [{
    type: 'input',
    classes: 'col-sm-6',
    inputType: 'number',
    name: OptionProperty.shelves,
    label: 'Shelves',
    min: 0,
    max: 5,
    suffix: 'pcs',
    placeholder: 'Placeholder.Shelves',
    validations: [{
      name: 'required',
      validator: Validators.required,
      message: 'Required.Shelves.entered'
    }, {
      name: 'pattern',
      validator: Validators.pattern(/\d+/),
      message: 'Required.Shelves.integer'
    }, {
      name: 'min',
      validator: Validators.min(0),
      message: 'Required.Shelves.Min.0'
    }, {
      name: 'max',
      validator: Validators.max(5),
      message: 'Required.Shelves.Max.5'
    }]
  }, {
    type: 'select',
    name: OptionProperty.shelfHoles,
    label: 'ShelfHoles',
    disabled: true,
    classes: 'col-sm-6',
    options: [],
    validations: [
      {
        name: 'required',
        validator: Validators.required,
        message: 'Required.ShelfHoles.entered'
      }
    ]
  }]
}, {
  id: 'additional-row',
  show: true,
  fields: [{
    type: 'select',
    name: OptionProperty.cornerJoin,
    label: 'CornerJoin',
    disabled: false,
    classes: 'col-sm-6',
    options: [],
    validations: [
      {
        name: 'required',
        validator: Validators.required,
        message: 'Required.CornerJoin.entered'
      }
    ]
  }, {
    type: 'checkbox',
    inputType: 'checkbox',
    name: OptionProperty.suspensionFittings,
    label: 'SuspensionFittings',
    classes: 'col-6 align-self-end ps-4 pb-4'
  }]
}, {
  id: FormRowIds.veneerTreatmentRow,
  show: true,
  fields: [{
    type: 'select',
    name: OptionProperty.veneer,
    label: 'VENEER.NAME',
    disabled: false,
    classes: 'col-sm-6',
    options: [],
    validations: [
      {
        name: 'required',
        validator: Validators.required,
        message: 'Required.veneer.entered'
      }
    ]
  },
    StandardSurfaceTreatmentField
  ]
}, {
  id: 'comments-row',
  fields: [{
    type: 'input',
    name: OptionProperty.comment,
    label: 'Comment',
    placeholder: 'Placeholder.Comment',
    classes: 'col-12'
  }]
}];
