<div class="container">
  <div class="row">

    <div class="col-12">
      <app-breadcrumbs [currentBreadcrumb]="slackBreadcrumb"></app-breadcrumbs>
    </div>

  </div>
  <div class="row">

    <div class="col-sm-4">
      <app-dynamic-form (change)="validateFormAndUpdateWidth()" [autoFocusFirst]="true"
                        [rows]="model"></app-dynamic-form>
      <div class="col-sm alert alert-danger" *ngIf="isWidthValid() === false">
        <h5 class="alert-heading">{{'SlacksDrawer.WidthErrorHeader' | translate }}</h5>
        <p>{{'SlacksDrawer.WidthErrorBody' | translate }}</p>
      </div>
      <div class="text-end">
        <app-amount-step [(amount)]="amount" [disable]="formIsInvalid" (approved)="amountApproved($event)"></app-amount-step>
      </div>
    </div>

    <div class="col-sm-8">
      <div class="card">
        <div class="card-body">
          <p class="card-text">{{ 'SlacksDrawer.ProductTypesHeader' | translate }}</p>
          <div class="row g-0">
            <div *ngFor="let productType of allProductTypes" class="col-sm-6 text-center">
              <div><strong>{{ 'SlacksDrawer.' + productType[0] | translate }}</strong></div>
              <img class="product-type-img"
                   (click)="setType(productType[0])"
                   [ngClass]="{ 'active': isActive(productType[0]) }"
                   [src]="'/assets/images/' + slacksDrawer + '/' + productType[0] + '.png'"
                   [alt]="'SlacksDrawer.' + productType[0] | translate"
              />
              <div class="row pt-2 pb-3 g-0">
                <div class="col-6 text-end">{{ 'SlacksDrawer.fixedWidth' | translate }}:</div>
                <div class="col-6 text-end ps-2">{{ productType[1].width }} mm</div>
                <div class="col-6 text-end">{{ 'SlacksDrawer.customWidth' | translate }}:</div>
                <div class="col-6 text-end ps-2">{{ productType[1].varWidth.min }}-{{ productType[1].varWidth.max }} mm</div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div [innerText]="translate('SlacksDrawer.OptionDescription')"></div>
        </div>
        <div class="card-footer">
          <div><strong [innerText]="translate('SlacksDrawer.coupling.header')"></strong></div>
          <div [innerText]="translate('SlacksDrawer.coupling.description')"></div>
        </div>
      </div>
    </div>

  </div>
</div>
