import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-display-ernst-mair-rev-ezug',
  templateUrl: './display-ernst-mair-rev-ezug.component.html',
  styleUrls: ['./display-ernst-mair-rev-ezug.component.css']
})
export class DisplayErnstMairRevEzugComponent implements OnInit, AfterViewInit {

  @Input() isLocked: boolean;

  @ViewChild('revEzugCanvas') revEzugCanvas: ElementRef;

  revEzugMaal = {
    ab: 55,
    cd: 84,
    special: false
  };

  revEzugHoejder = [
    {
      label: '84',
      value: 84
    },
    {
      label: '100',
      value: 100
    }
  ];

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.drawRearEzug();
  }

  drawRearEzug() {
    console.debug('Drawing rearEzugCanvas');
    const ctx: CanvasRenderingContext2D =
      this.revEzugCanvas.nativeElement.getContext('2d');

    // ezug path
    ctx.fillStyle = '#FFF';
    ctx.fillRect(0, 0, 500, 500);
    ctx.fillStyle = '#000';
    const outerPath = new Path2D('M 30 40 L 80 40 C 80 70 115 70 135 70 L 185 70 L 185 120 L 30 120 L 30 40 Z');
    ctx.font = '14px Arial';
    ctx.stroke(outerPath);

    // hjælpelinjer
    ctx.moveTo(35, 30);
    ctx.lineTo(65, 30);
    ctx.fillText('A         B', 24, 35);

    ctx.fillText('C', 193, 65);
    ctx.moveTo(195, 75);
    ctx.lineTo(195, 115);
    ctx.fillText('D', 193, 135);

    ctx.textAlign = 'center';
    ctx.fillText('min. 50 mm.', 64, 15);
    ctx.textAlign = 'left';
    // ctx.fillText(cd + '', 225, 95);

    ctx.stroke();
    //  markeringer (a-b, c-e, d-f)
  }


}
