import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { APIService, BreadcrumbsService, LocaleService } from '../services';
import { Breadcrumbs, productPrefix, specialsPrefix } from '../utils/breadcrumbs/breadcrumbs';
import { Constants } from '../../../../wdcommon/Constants';
import { IDynamicProduct, IProductCategoryResponse } from '../../../../wdcommon/IProduct';


@Component({
  selector: 'app-general-product-category',
  templateUrl: './general-product-category.component.html',
  styleUrls: ['./general-product-category.component.css']
})
export class GeneralProductCategoryComponent implements OnInit {
  localeId: string;

  subcategories: IProductCategoryResponse[];
  products: IDynamicProduct[];
  brandId: string;
  category: IProductCategoryResponse;
  breadcrumbs: Breadcrumbs = {};

  protected readonly categoryPrefix = specialsPrefix;
  protected readonly productPrefix = productPrefix;

  constructor(
      private api: APIService,
      private breadcrumbsService: BreadcrumbsService,
      private localeService: LocaleService,
      private route: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.localeId = this.localeService.getLanguage();

    this.route.paramMap.subscribe((paramMap) => {
      this.category = undefined;
      this.subcategories = undefined;
      this.products = undefined;
      let categoryId = parseInt(paramMap.get('categoryId'), 10);
      if (isNaN(categoryId)) {
        categoryId = parseInt(paramMap.get('brandCategoryId'), 10);
      }
      this.brandId = Constants.specialsCategoryBrandIdPrefix + paramMap.get('brandCategoryId');
      if (categoryId && !isNaN(categoryId)) {
        return this.loadCategories(categoryId);
      }
    });
  }

  private async loadCategories(categoryId: number) {
    this.api.getCategories(categoryId, this.localeId)
        .then((subcategories) => this.subcategories = subcategories);
    this.api.getProducts(categoryId, this.localeId)
        .then((products) => this.products = products);
    const category = await this.api.getCategory(categoryId, this.localeId);

    this.breadcrumbs = await this.breadcrumbsService.createSpecialBreadcrumbs(categoryId);
    this.category = category;
  }

}
