import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { UserService } from '../services';


@Injectable({
  providedIn: 'root'
})
export class UserActivatedGuard implements CanActivate {

  constructor(private router: Router, private userService: UserService) { }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.userService.isUserActive()){
      await this.router.navigateByUrl('/');
      return false;
    }

    return true;
  }
}
