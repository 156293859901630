<div class="container">
    <div class="row mb-3">

        <div class="col-12">
            <app-breadcrumbs *ngIf="breadcrumb" [currentBreadcrumb]="breadcrumb"></app-breadcrumbs>
        </div>

        <div *ngIf="frontCategory === linoleumFrontCategory" [innerText]="'Purewood.LinoleumFronts.Description' | translate"
             class="col-12 col-md-9 col-lg-7 col-xl-6 mt-3 alert alert-primary" role="alert"></div>

        <div *ngIf="frontCategory === woodenFrontCategory" [innerText]="'Purewood.WoodenFronts.Description' | translate"
            class="col-12 col-md-9 col-lg-7 col-xl-6 mt-3 alert alert-primary" role="alert"></div>

    </div>
    <div *ngIf="fronts" class="row">

        <div *ngFor="let front of fronts" class="col-12 col-lg-6 col-xl-4 mt-3">

            <div [routerLink]="breadcrumb.url + '/' + front.id" class="card up-card up-card-h h-100 clickable">
                <div class="row g-0">
                    <div class="col-md-8">
                        <div class="card-body">
                            <div [innerText]="front.name | translate" class="card-title"></div>
                            <div class="card-text">
                                <div class="card-text" [innerText]="front.description | translate"></div>
<!--                                <button class="btn btn-sm btn-success my-2" [innerText]="'Product_Order' | translate"></button>-->
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 d-flex align-items-center">
                        <img [alt]="front.name" [src]="'/assets/images/fronts/' + front.image" class="card-img">
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
