import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';

import { GlobalContentDesign, GlobalContentType, IGlobalContent } from '../../../../../wdcommon/IGlobalContent';
import { APIService } from '../../services';
import { defaultBreadcrumbs } from '../../utils/breadcrumbs/breadcrumbs';


@Component({
  selector: 'app-admin-global-content',
  templateUrl: './admin-global-content.component.html',
  styleUrls: ['./admin-global-content.component.css']
})
export class AdminGlobalContentComponent implements OnInit {
  faEdit = faEdit;

  adminGlobalContentBreadcrumb = defaultBreadcrumbs.adminGlobalContent;

  modalRef?: BsModalRef;
  error?: string;
  waiting = false;
  newType: GlobalContentType;
  newKey: string;

  typeEnum = GlobalContentType;

  existingPages: IGlobalContent[];

  constructor(
      private apiService: APIService,
      private modalService: BsModalService,
  ) {
  }

  ngOnInit() {
    this.apiService.getAllGlobalContent()
        .then((allPages) => {
          this.existingPages = allPages
              .sort((a, b) => {
                return a.type > b.type ? 1 :
                    a.type < b.type ? -1 :
                        (a.sortOrder - b.sortOrder !== 0) ? a.sortOrder - b.sortOrder :
                            a.key > b.key ? 1 :
                                a.key < b.key ? -1 : 0;
              });
        });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  async createContent() {
    this.waiting = true;
    this.error = undefined;
    if (!this.newKey || !this.newType) {
      this.waiting = false;
      this.error = 'Alle værdier er ikke sat';
      return;
    }

    await this.apiService.setGlobalContent({
      key: this.newKey,
      type: this.newType,
      public: {},
      design: GlobalContentDesign.default,
      draft: {},
      tags: '',
      sortOrder: 1,
    });
    this.ngOnInit();
    this.modalRef.hide();
    this.waiting = false;
  }

  dumpObjectKeys(obj: object) {
    console.log(obj, Object.keys(obj));
    return '[' + Object.keys(obj).filter((k) => (obj[k] && obj[k] !== '' && Object.keys(obj[k]).length)) + ']';
  }

  isActive(content: IGlobalContent) {
    return (content.public && content.lastPublished &&
        (!content.availableFrom || new Date(content.availableFrom).valueOf() < Date.now()) &&
        (!content.availableTo || new Date(content.availableTo).valueOf() > Date.now()));
  }
}
