import { Component, Input } from '@angular/core';

import { UserService } from '../../../services';
import { IDynamicProduct } from '../../../../../../wdcommon/IProduct';
import { IRelatedProductSearchResult } from '../../../../../../wdcommon/IRelatedProduct';


@Component({
  selector: 'app-product-price',
  templateUrl: './product-price.component.html',
  styleUrls: ['./product-price.component.css']
})
export class ProductPriceComponent {
  @Input() brandId: string;

  price = 0;
  currencyShort = 'DKK';
  _product: IDynamicProduct | IRelatedProductSearchResult;

  constructor(
      private userService: UserService,
  ) {
  }

  @Input() set product(inProduct: IDynamicProduct | IRelatedProductSearchResult) {
    this._product = inProduct;

    this.userService.getCompanyAdjustments()
        .then((adjustments) => {
          const priceAdjustment = (100 + (adjustments[this.brandId] ?? 0)) / 100;
          this.price = this._product.price * priceAdjustment;
        });
  }

}
