<div class="container">
  <div class="row">

    <div class="col-12">
      <app-breadcrumbs [currentBreadcrumb]="drawersBreadcrumb"></app-breadcrumbs>
    </div>

  </div>
  <div class="row">
    <div class="col-12">
      <div class="alert alert-secondary" role="alert" [innerHTML]="'Purewood.Drawers.Description' | translate"></div>
    </div>
  </div>
    <div class="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-5 g-4">
  <ng-container *ngFor="let subcategoryRow of subcategoryRows">

      <div *ngFor="let subcategory of subcategoryRow" class="col">

        <div (click)="gotoSubcategory(subcategory)" *ngIf="subcategory.type !== 'placeholder'" class="card wd-card h-100">
          <div *ngIf="!subcategory.enabled" class="watermark-text">
            {{ 'Products.TemporaryUnavailable' | translate }}
          </div>
          <div class="card-header">{{ subcategory.name | translate }}</div>
          <div class="card-body d-flex align-items-center justify-content-center flex-column">
            <img [src]="subcategory.img" alt="" class="img-fluid">
            <p *ngIf="subcategory.description" class="card-text">{{ subcategory.description | translate }}</p>
          </div>
        </div>

        <div *ngIf="subcategory.type === 'placeholder'" class="card border-0"></div>

      </div>

  </ng-container>
    </div>
</div>
