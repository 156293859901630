<header class="bg-white up-nav d-md-flex sticky-top no-print">
    <div class="flex-grow-1 pt-2 title-line ms-5">
        <img alt="Unit-Partner Shop" class="up-logo-img" routerLink="/" src="assets/images/UniPartner_Shop-Logo.png">
    </div>

    <div class="me-3 nav-settings d-flex flex-wrap-reverse align-items-center justify-content-end">

        <a class="btn btn-sm btn-outline-primary" id="button-create-user" type="button" *ngIf="!user" routerLink="/signup">
            <fa-icon [icon]="faUserPlus"></fa-icon>&nbsp;
            {{ 'SIGNUP.Opret Bruger' | translate }}&nbsp;<span class="caret"></span>
        </a>

        <app-schedule-meeting [addedClasses]="'me-2 btn btn-sm btn-outline-primary'">{{ 'SCHEDULE_MEETING.Book' | translate }}</app-schedule-meeting>

        <button (click)="showDeliveryInfo()" *ngIf="user && deliveryInfoExists" class="btn btn-sm btn-outline-primary">
            <fa-icon [icon]="faTruckMoving"></fa-icon>&nbsp;
            {{ 'NAVBAR.DELIVERY' | translate }}
        </button>

        <div class="btn-group" dropdown>
            <button class="btn btn-sm btn-outline-primary dropdown-toggle dropdown-toggle-split" dropdownToggle id="button-faq" type="button">
                <fa-icon [icon]="faInfoCircle"></fa-icon>&nbsp;
                {{ 'NAVBAR.FAQ' | translate }}&nbsp;<span class="caret"></span>
            </button>
            <ul *dropdownMenu aria-labelledby="button-faq" class="dropdown-menu dropdown-split" role="menu">
                <li role="menuitem"><a [routerLink]="['/faq/general']" class="dropdown-item">{{ 'NAVBAR.GENERALLY' | translate }}</a></li>
                <li role="menuitem"><a [routerLink]="['/faq/quality']" class="dropdown-item">{{ 'NAVBAR.QUALITY_DESC' | translate }}</a></li>
                <!-- <li role="menuitem"><a class="dropdown-item" routerLink="/faq/treesorts">Træsorter</a></li> -->
                <li role="menuitem">
                    <a class="dropdown-item" href="/assets/rz_Katalog_Nothegger_2018_web.pdf" target="nothegger">
                        {{ 'NAVBAR.NOTHEGGER_CATALOG' | translate }}&nbsp;
                        <fa-icon [icon]="faExternalLink"></fa-icon>
                    </a>
                </li>
                <li *ngIf="user && user.isCompanyAdmin" role="menuitem">
                    <a class="dropdown-item" href="/assets/Vejledning%20oprettelse%20af%20ekstra%20bruger%20.pdf" target="upHelp">
                        {{ 'NAVBAR.CREATE_EXTRA_USER' | translate }}&nbsp;
                        <fa-icon [icon]="faExternalLink"></fa-icon>
                    </a>
                </li>
            </ul>
        </div>

        <ng-container *ngIf="!user">
            <div [insideClick]="true" class="btn-group" dropdown>
                <button aria-expanded="false" aria-haspopup="true" class="btn btn-sm btn-outline-primary dropdown-toggle me-2" data-toggle="dropdown"
                        dropdownToggle id="button-login" type="button">
                    <fa-icon [icon]="faUser"></fa-icon>&nbsp;
                    {{ 'LOGIN.LOGIN' | translate }}
                </button>
                <div *dropdownMenu aria-labelledby="button-login" class="dropdown-menu dropdown-menu-end up-login-box">
                    <form (ngSubmit)="loginUser()" [formGroup]="loginForm" class="px-4 py-2">
                        <div class="mb-3">
                            <label class="mb-0 col-form-label-sm" for="inputLoginEmail">{{ 'CMPADM.Email' | translate }}</label>
                            <input [formControlName]="loginFormEmail" [placeholder]="'CMPADM.Indtast email' | translate"
                                   class="form-control form-control-sm" id="inputLoginEmail" type="email">
                        </div>
                        <div class="mb-3">
                            <label class="mb-0 col-form-label-sm" for="inputLoginPassword">{{ 'SIGNUP.Password' | translate }}</label>
                            <input [formControlName]="loginFormPassword" [placeholder]="'SIGNUP.Password' | translate"
                                   class="form-control form-control-sm" id="inputLoginPassword" type="password">
                        </div>
                        <div class="d-grid">
                            <button class="btn btn-sm btn-primary" type="submit">{{ 'LOGIN.LOGIN' | translate }}</button>
                        </div>
                    </form>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" routerLink="/login">{{ 'LOGIN.MoreOptions' | translate }}</a>
                </div>
            </div>
        </ng-container>

        <div *ngIf="user" class="btn-group me-2" dropdown>
            <button *ngIf="user.isAdmin" [title]="'NAVBAR.ADMIN' | translate" class="btn btn-sm btn-outline-primary" routerLink="/admin">
                <fa-icon [icon]="faUserSecret"></fa-icon>
            </button>
            <button aria-controls="dropdown-split" class="btn btn-sm btn-outline-primary dropdown-toggle dropdown-toggle-split" dropdownToggle
                    id="button-split" type="button">
                <ng-container *ngIf="!user.isAdmin">
                    <fa-icon [icon]="faUser"></fa-icon>&nbsp;
                </ng-container>
                {{ user.name }}&nbsp;<span class="caret"></span>
            </button>
            <ul *dropdownMenu aria-labelledby="button-split" class="dropdown-menu dropdown-split" id="dropdown-split" role="menu">
                <li role="menuitem">
                    <a class="dropdown-item" routerLink="/profile/my-profile">{{ 'NAVBAR.MY_PROFILE' | translate }}</a>
                </li>
                <li role="menuitem">
                    <a class="dropdown-item" routerLink="/profile/my-orders">{{ 'NAVBAR.ORDERS' | translate }}</a>
                </li>
                <li *ngIf="user && user.isCompanyAdmin === true" role="menuitem">
                    <a class="dropdown-item" routerLink="/profile/company-admin">{{ 'NAVBAR.COMPANY_ADMIN' | translate }}</a>
                </li>
                <li role="menuitem">
                    <a (click)="showSavedProjects()" class="dropdown-item">{{ 'NAVBAR.SAVED_PROJECTS' | translate }}</a>
                </li>
                <li class="divider dropdown-divider"></li>
                <li role="menuitem">
                    <a class="dropdown-item" routerLink="/logout">{{ 'NAVBAR.LOGOUT' | translate }}</a>
                </li>
            </ul>
        </div>

        <app-language-selector *ngIf="!user" [compact]="true" class="me-2"></app-language-selector>

        <app-can-purchase-view>
            <button
                (click)="checkCartAndGoto()"
                [class.btn-outline-secondary]="cartService.getNumberOfItems() === 0"
                [class.btn-success]="cartService.getNumberOfItems() > 0"
                class="btn btn-sm authorized"
            >
                <fa-icon [icon]="faShoppingCart"></fa-icon>
                <div *ngIf="cartService.getNumberOfItems() > 0" class="badge badge-white ms-2">{{ cartService.getNumberOfItems() }}</div>
            </button>
        </app-can-purchase-view>

    </div>
</header>


<ng-container *ngIf="user">
    <button (click)="loadHistory()" aria-controls="offcanvasRepeat" class="btn btn-lg btn-primary position-fixed end-0 btn-float-end no-print"
            data-bs-target="#offcanvasRepeat" data-bs-toggle="offcanvas" type="button">
        <fa-icon [icon]="faRedo" size="lg"></fa-icon>
    </button>
    <div aria-labelledby="offcanvasRepeatLabel" class="offcanvas offcanvas-end" data-bs-scroll="true" id="offcanvasRepeat" tabindex="-1">
        <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasRepeatLabel">{{ 'SIDEBAR.HISTORY' | translate }}</h5>
            <button aria-label="Close" class="btn-close" data-bs-dismiss="offcanvas" id="offcanvasRepeatClose" type="button"></button>
        </div>
        <div class="offcanvas-body">

            <ng-container *ngIf="fiveDrawers.length > 0">
                <h5 class="mb-2">
                    {{ 'SIDEBAR.LATEST_5_DRAWERS' | translate }}
                </h5>
                <div class="list-group mb-3">
                    <div (click)="setRepeatDrawer(drawer)" *ngFor="let drawer of fiveDrawers"
                         class="list-group-item list-group-item-action flex-column align-items-start clickable">
                        <div class="d-flex w-100 justify-content-between">
                            <h6 class="mb-1">{{ drawer.itemno }}</h6>
                            <small>{{ drawer.formattedType }}</small>
                        </div>
                        <small>{{drawer.description}}</small>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="fiveFronts.length > 0">
                <h5 class="mb-2">
                    {{ 'SIDEBAR.LATEST_5_FRONTS' | translate }}
                </h5>
                <div class="list-group mb-5">
                    <div (click)="setRepeatFront(front)" *ngFor="let front of fiveFronts"
                         class="list-group-item list-group-item-action flex-column align-items-start clickable">
                        <div class="d-flex justify-content-between w-100">
                            <h6 class="mb-1">{{ front.itemno }}</h6>
                            <small>{{ front.formattedType }}</small>
                        </div>
                        <small>{{front.description}}</small>
                    </div>
                </div>
            </ng-container>

        </div>
    </div>
    <app-repeat-drawer [selectedDrawer]="repeatDrawer"></app-repeat-drawer>
    <app-repeat-purewood-front [selectedFront]="repeatFront"></app-repeat-purewood-front>
</ng-container>

<div class="d-none d-md-flex justify-content-center mb-4 overflow-hidden no-print">
    <div *ngFor="let productType of productTypes" class="text-nowrap">
        <a [class.active]="isActiveRoute(productType.url)" [routerLink]="productType.url" class="up-menu-item">
            {{ productType.textKey | translate }}
        </a>
    </div>
</div>


<ng-template #deliveryInfoModal>
    <div class="modal-header"><h5>{{deliveryInfo.header}}</h5></div>
    <div [innerHTML]="deliveryInfo.text" class="modal-body"></div>
    <div class="modal-footer">
        <button (click)="modalRef.hide()" class="btn btn-primary" data-dismiss="modal" type="button">
            {{ 'UTILITY.CLOSE' | translate}}
        </button>
    </div>
</ng-template>


<ng-template #confirmRestoreProject>
    <div class="modal-header">
        Er du sikker på at du hente det gemte projekt?
    </div>
    <div class="modal-body">
        <div class="alert alert-warning">
            Når et gemt projekt hentes <b>overskrives den nuværende kurv</b> og data kan derfor gå tabt.
        </div>
    </div>
    <div class="modal-footer">
        <button (click)="restoreSavedProject()" class="btn btn-primary" type="button">
            Hent
            <fa-icon [icon]="faRedo"></fa-icon>
        </button>
        <button (click)="modalRef.hide(); modalRef = modalService.show(savedOrderList)" class="btn btn-secondary" data-dismiss="modal" type="button">
            {{ 'UTILITY.CANCEL' | translate }}
        </button>
    </div>
</ng-template>

<ng-template #confirmDeleteProject>
    <div class="modal-header">Er du sikker på at du vil slette det gemte projekt?</div>
    <div class="modal-body">
        <div class="alert alert-danger">
            Når et gemt projekt slettes kan det ikke gendannes igen og data vil være tabt.
        </div>
    </div>
    <div class="modal-footer">
        <button (click)="deleteSavedProject()" class="btn btn-danger" type="button">
            Slet
            <fa-icon [icon]="faTrash"></fa-icon>
        </button>
        <button (click)="modalRef.hide(); modalRef = modalService.show(savedOrderList)" class="btn btn-secondary" data-dismiss="modal" type="button">
            {{ 'UTILITY.CANCEL' | translate }}
        </button>
    </div>
</ng-template>

<ng-template #savedOrderList>
    <div class="modal-header">
        <h4 class="modal-title">Gemte Projekter</h4>
        <button (click)="modalRef.hide()" aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-sm">
                <table class="table table-sm table-striped table-hover">
                    <thead>
                    <tr>
                        <th>Projekt navn</th>
                        <th>&nbsp;</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let s of savedProjects">
                        <td>{{ s.name }}</td>
                        <td class="text-end">
                            <button (click)="selectedSavedProject = s.id; modalRef.hide(); modalRef = modalService.show(confirmRestoreProject)"
                                    class="btn btn-sm btn-info me-2">
                                Åbn
                            </button>
                            <button (click)="selectedSavedProject = s.id; modalRef.hide(); modalRef = modalService.show(confirmDeleteProject)"
                                    class="btn btn-sm btn-danger">
                                Slet
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal-footer"></div>
</ng-template>
