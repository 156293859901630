<div class="ng-autocomplete">
  <div *ngFor="let relatedProduct of relatedProducts.value"
       (click)="removeProduct(relatedProduct)"
       class="btn btn-danger btn-sm ms-2 mb-2">{{relatedProduct.name}} ({{relatedProduct.itemNumber}})</div>

  <ng-autocomplete [data]="data"
                   [searchKeyword]="keyword"
                   (selected)="selectEvent($event)"
                   (inputChanged)="onChangeSearch($event)"
                   [itemTemplate]="itemTemplate"
                   [notFoundTemplate]="notFoundTemplate">
  </ng-autocomplete>

  <ng-template #itemTemplate
               let-item>
    <a>
      <span [innerHTML]="item.name"></span> (<span [innerHTML]="item.itemNumber"></span>)
    </a>
  </ng-template>

  <ng-template #notFoundTemplate
               let-notFound>
    <div>Ingen matchende produkter fundet</div>
  </ng-template>
</div>
