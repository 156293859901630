import { Injectable } from '@angular/core';

import { APIService } from './api.service';
import { Constants } from '../../../../wdcommon/Constants';


@Injectable({
  providedIn: 'root'
})
export class HettichService {
  private hettichId: Promise<number>;

  constructor(
      private apiService: APIService,
  ) {
  }

  getHettichCategoryId() {
    if (!this.hettichId) {
      this.hettichId = this.apiService.getCategories(null, 'da')
          .then((topCategories) => {
            const hettichCategory = topCategories.find((c) => c.name.toLowerCase().indexOf('hettich') > -1);
            return (hettichCategory ? hettichCategory.id : undefined);
          });
    }
    return this.hettichId;
  }

  getHettichBrandId() {
    if (!this.hettichId) {
      return this.getHettichCategoryId()
          .then((id) => Constants.specialsCategoryBrandIdPrefix + id);
    }
    return this.hettichId
        .then((id) => Constants.specialsCategoryBrandIdPrefix + id);
  }

}
