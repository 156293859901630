import { FormRow } from '../../dynamicForm/dynaform.interfaces';
import { createDrawerHeightField, createDrawerWidthField, DrawerHeightFieldName, DrawerWidthFieldName } from '../../dynamicForm/standard-fields';
import { createHrRow, createJointSurfaceTreatmentSelectionRow, createQualityTypeOfWoodSelectionRow } from '../../dynamicForm/standard-rows';


export const MODEL: FormRow[] = [
  createQualityTypeOfWoodSelectionRow(),
  createJointSurfaceTreatmentSelectionRow(),
  {
    id: 'lysmaal-height',
    show: true,
    fields: [
      createDrawerWidthField(150, 1200, DrawerWidthFieldName.width),
      createDrawerHeightField(DrawerHeightFieldName.sideHeight)
    ]
  },
  createHrRow(),
  {
    id: 'config',
    type: 'configuration',
    config: ['greb', 'skuffe20mmFront', 'ligeOverkant', 'boerstet']
  }
];
