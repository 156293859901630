import { Directive, Input, OnInit, ViewContainerRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FieldConfig } from './dynaform.interfaces';
import { ButtonComponent } from './fields/button/button.component';
import { CheckboxComponent } from './fields/checkbox/checkbox.component';
import { HTMLFieldComponent } from './fields/htmlfield/htmlfield.component';
import { InputComponent } from './fields/input/input.component';
import { SelectComponent } from './fields/select/select.component';

const componentMapper = {
  input: InputComponent,
  button: ButtonComponent,
  select: SelectComponent,
  checkbox: CheckboxComponent,
  html: HTMLFieldComponent
};

@Directive({
  selector: '[dynamicField]',
})
export class DynamicFieldDirective implements OnInit {

  @Input() field: FieldConfig;
  @Input() group: UntypedFormGroup;
  componentRef: any;

  constructor(
      private container: ViewContainerRef,
  ) {
  }

  ngOnInit() {
    this.componentRef = this.container.createComponent(componentMapper[this.field.type]);
    this.componentRef.instance.field = this.field;
    this.componentRef.instance.group = this.group;
  }

}
