import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { firstValueFrom } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons/faCheckSquare';
import { faSquare } from '@fortawesome/free-solid-svg-icons/faSquare';

import { IDrawerType } from '../../../../wdcommon/IDrawer';
import { IOtherProduct, Manufacturer } from '../../../../wdcommon/IProduct';
import { APIService, DrawerOptionsService, SessionService } from '../services';
import { TranslatingBase } from '../base-component/ComponentBase';
import { defaultBreadcrumbs } from '../utils/breadcrumbs/breadcrumbs';


interface NotheggerProduct {
  enabled: boolean;
  name?: string;
  description?: string;
  img?: string;
  type: 'drawer' | 'special' | 'other' | 'placeholder' | 'carcass';
  drawer?: IDrawerType;
  other?: IOtherProduct;
}

@Component({
  selector: 'app-nothegger',
  templateUrl: './nothegger.component.html',
  styleUrls: ['./nothegger.component.css']
})
export class NotheggerComponent extends TranslatingBase implements OnInit {
  faCheckSquare = faCheckSquare;
  faSquare = faSquare;
  notheggerBreadcrumb = defaultBreadcrumbs[Manufacturer.nothegger];

  notheggerRowSize = 4;
  specialsRowSize = 3;
  notheggerProductRows: NotheggerProduct[][];
  specialsProductRows: NotheggerProduct[][];

  specialModalRef: BsModalRef;
  @ViewChild('specialsModal') specialsModal: TemplateRef<any>;
  asAdmin: boolean;

  constructor(
      private api: APIService,
      private modalService: BsModalService,
      private toastrService: ToastrService,
      private optsService: DrawerOptionsService,
      private router: Router,
      private session: SessionService,
      private translateService: TranslateService
  ) {
    super(translateService);
  }

  async ngOnInit() {
    this.session.deleteValue('OrderDrawer');
    this.asAdmin = window.location.pathname.indexOf('/admin/') === 0;
    if (this.asAdmin) {
      this.notheggerBreadcrumb = defaultBreadcrumbs['adminDrawers' + Manufacturer.nothegger];
    }

    const notheggerDrawers: IDrawerType[] = await this.optsService.getDrawers(Manufacturer.nothegger);
    const specialDrawers = await this.optsService.getDrawers('special');
    const otherProducts: IOtherProduct[] = await firstValueFrom(this.api.getOtherProducts());

    // Line up Nothegger products in rows (not specials yet)
    this.notheggerProductRows = [];
    const totalCells = notheggerDrawers.length + otherProducts.length + 2;
    const rows = Math.ceil(totalCells / this.notheggerRowSize);
    for (let row = 0; row < rows; row++) {
      this.notheggerProductRows.push([]);
    }

    // Drawers
    notheggerDrawers.forEach((drawer, i) => {
      const index = Math.floor(i / this.notheggerRowSize);
      this.notheggerProductRows[index].push({
        type: 'drawer',
        enabled: drawer.enabled,
        name: drawer.name,
        description: drawer.description,
        img: drawer.img,
        drawer: drawer
      });
    });

    // Special drawers
    const specialIndex = Math.floor(notheggerDrawers.length / this.notheggerRowSize);
    this.notheggerProductRows[specialIndex].push({
      type: 'special',
      enabled: (specialDrawers.length > 0),
      name: 'HOME.SPECIALS_TITLE',
      description: 'HOME.SPECIALS_DESC',
      img: '/assets/images/drawers/special_skuffer.png'
    });

    // Other products
    otherProducts.forEach((other, i) => {
      const index = Math.floor((notheggerDrawers.length + 1 + i) / this.notheggerRowSize);
      this.notheggerProductRows[index].push({
        type: 'other',
        enabled: other.enabled,
        name: other.name,
        description: other.description,
        img: other.img,
        other: other
      });
    });

    // Carcass
    this.notheggerProductRows[rows - 1].push({
      type: 'carcass',
      enabled: true,
      name: 'CABINET_CARCASS.NAME',
      description: '',
      img: 'assets/images/cabinet-carcass.png'
    });

    // Top up with empty drawer-objects
    const remainder = this.notheggerRowSize * rows - totalCells;
    for (let extra = 0; extra < remainder; extra++) {
      this.notheggerProductRows[rows - 1].push({
        type: 'placeholder',
        enabled: false
      });
    }

    // Now, line up Nothegger special products in rows
    this.specialsProductRows = [];
    const specialRows = Math.ceil(specialDrawers.length / this.specialsRowSize);
    for (let row = 0; row < specialRows; row++) {
      this.specialsProductRows.push([]);
    }

    // Nothegger special drawers
    specialDrawers.forEach((drawer, i) => {
      const index = Math.floor(i / this.specialsRowSize);
      this.specialsProductRows[index].push({
        type: 'drawer',
        enabled: drawer.enabled,
        name: drawer.name,
        description: drawer.description,
        img: drawer.img,
        drawer: drawer
      });
    });

    // Top specials up with empty drawer-objects
    const specialsRemainder = this.specialsRowSize * specialRows - specialDrawers.length;
    for (let extra = 0; extra < specialsRemainder; extra++) {
      this.specialsProductRows[specialRows - 1].push({
        type: 'placeholder',
        enabled: false
      });
    }
  }

  async gotoProduct(product: NotheggerProduct) {
    if (this.asAdmin && product.type !== 'special') {
      return;
    }
    this.closeSpecialModal();
    if (!product.enabled) {
      return;
    }
    switch (product.type) {
      case 'special':
        this.popSpecialModal();
        break;

      case 'carcass':
        await this.goto('/products/nothegger/cabinet-carcass');
        break;

      case 'other':
        this.session.setValue('OrderOther', JSON.stringify(product));
        await this.goto('/products/nothegger/' + product.other.shortname);
        break;

      case 'drawer':
        await this.goto('products/nothegger/' + product.drawer.type);
        break;

      default:
        console.warn('Unknown Nothegger product selected', product);
    }
  }

  async goto(url: string) {
    await this.router.navigateByUrl(url);
  }

  closeSpecialModal() {
    if (this.specialModalRef) {
      this.specialModalRef.hide();
      this.specialModalRef = null;
    }
  }

  popSpecialModal() {
    this.closeSpecialModal();
    this.specialModalRef = this.modalService.show(this.specialsModal, { class: 'modal-lg' });
  }

  async alterAvailability(product: NotheggerProduct) {
    try {
      product.enabled = !product.enabled;
      switch (product.type) {
        case 'drawer':
          product.drawer.enabled = product.enabled;
          await firstValueFrom(this.api.updateDrawer(product.drawer));
          break;

        case 'other':
          product.other.enabled = product.enabled;
          await firstValueFrom(this.api.updateOtherProduct(product.other));
          break;

        default:
          console.error('Unexpected Nothegger product type', product);
      }
      this.toastrService.info(this.translate('Product_' + (product.enabled ? 'activated' : 'deactivated'), { productName: this.translate(product.name) }));
    } catch (err) {
      console.error(err);
      this.toastrService.error('An error occurred when trying to update the product.');
    }
  }

}
