<div class="container">
    <div class="row mb-3">

        <div class="col">
            <app-breadcrumbs [currentBreadcrumb]="adminDrawersBreadcrumb"></app-breadcrumbs>
        </div>

    </div>
    <div class="row">

        <!-- Nothegger-->
        <div class="col-sm-3 px-3 pb-4">
            <div [routerLink]="notheggerBreadcrumb.url" class="card wd-card pb-2 h-100 w-100">
                <div class="card-header" [innerText]="notheggerBreadcrumb.name"></div>
                <div class="card-body d-flex align-items-center justify-content-center">
                    <img alt="" class="img-fluid" src="/assets/images/nothegger_icon_grau.png">
                </div>
            </div>
        </div>

        <!-- Ernst Mair -->
        <div class="col-sm-3 px-3 pb-4">
            <div [routerLink]="ernstMairBreadcrumb.url"  class="card wd-card pb-2 h-100 w-100">
                <div class="card-header" [innerText]="ernstMairBreadcrumb.name"></div>
                <div class="card-body d-flex align-items-center justify-content-center">
                    <img alt="" class="img-fluid" src="/assets/images/ernst-mair/ernst-mair.png">
                </div>
            </div>
        </div>

    </div>
</div>
