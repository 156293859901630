import { TranslateService } from '@ngx-translate/core';

import { PriceCalculatorService } from '../price.calculator.service';
import { CartItem } from './CartItem';
import { ITypedAddCartItem } from '../../../../../wdcommon/ICartItem';
import { ICompanyAdjustments } from '../../../../../wdcommon/ICompany';


export class ExtraCartItem extends CartItem {

  constructor(
      translateService: TranslateService,
      private priceService: PriceCalculatorService,
      private companyAdjustmentsProvider: () => Promise<ICompanyAdjustments>,
      extra: ITypedAddCartItem
  ) {
    super(translateService, extra);
  }

  protected async calculatePrice(): Promise<number> {
    const price = await this.priceService.CalculateOthers(this as ITypedAddCartItem, await this.companyAdjustmentsProvider());

    if (typeof (price) === 'number')
      return price;

    console.error(`Unable to calculate price for extra ${this.itemno} got: ${price}.`);
    return 0;
  }
}
