import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import jwt_decode from 'jwt-decode';

import { APIService, LocaleService } from './';
import { AdminProductsLanguageService } from '../admin/admin-products/admin-products-language.service';
import { Constants } from '../../../../wdcommon/Constants';
import { ICompanyAdjustments } from '../../../../wdcommon/ICompany';
import { Manufacturer } from '../../../../wdcommon/IProduct';
import { IUser } from '../../../../wdcommon/IUser';

const jwtHelper = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private user: IUser;
  private isValid = false;

  private companyAdjustments: ICompanyAdjustments;

  constructor(
      private apiService: APIService,
      private adminProductsLanguageService: AdminProductsLanguageService,
      private localeService: LocaleService
  ) {
  }

  get isLoggedIn(): boolean {
    return !!this.user;
  }

  public getUser() {
    if (!this.user) {
      this.loadUser();
    }
    return this.user;
  }

  public getUserID() {
    return this.user.id;
  }

  public getUserToken() {
    return localStorage.token;
  }

  public async loginUser(email: string, password: string): Promise<{ success: boolean, error?: string; }> {
    localStorage.removeItem('token');
    try {
      const response = await this.apiService.loginUser(email, password);

      if (response.err)
        return { success: false, error: response.err };

      if (!response.token)
        return { success: false, error: 'UNKNOWN' };

      localStorage.setItem('token', response.token);

      const decodedUser = jwt_decode(response.token);
      this.setUser(decodedUser as IUser);

      return { success: true };
    } catch (err) {
      console.error('error! from login!', err);
      return { success: false, error: 'error! from login!' };
    }
  }

  public isUserValid() {
    return this.isValid;
  }

  public isUserActive() {
    return this.user && this.user.activated;
  }

  public isAdmin() {
    return this.user && this.user.isAdmin;
  }

  public isAllowedErnstMair() {
    return this.user && this.user.allowErnstMair;
  }

  public isAllowedPurewood() {
    return this.user && this.user.allowPurewood;
  }

  public resetPassword(password: string, hash: string) {
    return this.apiService.resetPassword(password, hash);
  }

  public requestPasswordReset(email: string) {
    return this.apiService.requestPasswordReset(email);
  }

  public async checkUserIsValid() {
    const user = await this.apiService.getUserValid();
    if (user && user.id) {
      // Check if token has expired
      const token = localStorage.getItem('token');
      if (jwtHelper.isTokenExpired(token)) {
        this.logoutUser();
        return false;
      } else {
        this.isValid = true;
        this.setUser(user);
        this.user = user;
        return true;
      }
    } else {
      this.logoutUser();
      return false;
    }
  }

  public logoutUser() {
    this.isValid = false;
    this.user = undefined;
    this.companyAdjustments = undefined;
    sessionStorage.removeItem('user');
    localStorage.removeItem('token');
  }

  public async getCompanyAdjustments() {
    if (!this.user) {
      this.loadUser();
    }

    if (this.user && this.user.company && this.user.company.id > 0) {
      if (!this.companyAdjustments) {
        this.companyAdjustments = await this.apiService.getCompanyAdjusts(this.user.company.id);
      }
      const adjustments: ICompanyAdjustments = JSON.parse(JSON.stringify(this.companyAdjustments));
      this.applyAdminNotheggerAdjust(adjustments);
      return adjustments;
    }

    return {};
  }

  public setAdminNotheggerAdjust(notheggerAdjust?: number) {
    if (typeof notheggerAdjust === 'undefined') {
      sessionStorage.removeItem('notheggerAdjust');
    } else {
      sessionStorage.setItem('notheggerAdjust', notheggerAdjust.toString(10));
    }
  }

  public getAdminNotheggerAdjust() {
    const adjust = parseInt(JSON.parse(sessionStorage.getItem('notheggerAdjust')), 10);
    if (!isNaN(adjust)) {
      return adjust;
    }
    if (this.companyAdjustments && this.companyAdjustments[Manufacturer.nothegger]) {
      return this.companyAdjustments[Manufacturer.nothegger];
    }
    return Constants.notheggerDefaultAdjustment;
  }

  private applyAdminNotheggerAdjust(adjusts: ICompanyAdjustments) {
    if (this.isAdmin()) {
      adjusts[Manufacturer.nothegger] = this.getAdminNotheggerAdjust();
    }
    return adjusts;
  }

  private setUser(user: IUser) {
    sessionStorage.setItem('user', JSON.stringify(user));

    let localeId = 'en';
    switch (user.company.country) {
      case 'Danmark':
        localeId = 'da';
        break;

      case 'Norge':
        localeId = 'no';
        break;

      case 'Sverige':
        localeId = 'sv';
        break;
    }

    this.adminProductsLanguageService.currentLocale = this.localeService.getLanguageById(localeId);
  }

  private loadUser() {
    this.user = JSON.parse(sessionStorage.getItem('user'));
  }

}
