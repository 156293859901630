import { Component, OnInit, ViewChild } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { Color, ScaleType } from '@swimlane/ngx-charts';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';

import { IOrder, IOrderStatus } from '../../../../../wdcommon/IOrder';
import { IUser } from '../../../../../wdcommon/IUser';
import { APIService } from '../../services';
import { defaultBreadcrumbs } from '../../utils/breadcrumbs/breadcrumbs';


@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css'],
})
export class AdminDashboardComponent implements OnInit {
  @ViewChild('pop') pop: PopoverDirective;
  @BlockUI('deletingUserBlock') delUserBlock: NgBlockUI;

  faTrash = faTrash;

  adminBreadcrumb = defaultBreadcrumbs.admin;

  orders: IOrder[];

  view: number[] = [1110, 200];
  orderData = [];

  earningsData = [];

  orderStatuses: IOrderStatus[];
  waitingUsers: IUser[] = [];
  newOrders: IOrder[];

  colorScheme: Color = {
    name: 'adminCharts',
    selectable: false,
    group: ScaleType.Linear,
    domain: ['#5AA454', '#C7B42C', '#AAAAAA', '#A10A28']
  };

  deleteID: number;

  constructor(
      private apiService: APIService,
      private toastrService: ToastrService,
  ) {
  }

  async ngOnInit() {
    this.orderStatuses = await this.apiService.getOrderStatuses().toPromise();
    await this.loadWaitingUsers();
    this.orders = await this.apiService.getOrders().toPromise();

    this.newOrders = this.orders.sort((a: IOrder, b: IOrder) => {
      return b.id - a.id;
    }).slice(0, 5);

    const statusCounter: { [key: number]: number } = {};

    let economicEarnings = 0;
    let waitingEarnings = 0;

    // Generate order overview
    this.orders.forEach(order => {
      if (!statusCounter[order.status.id]) {
        statusCounter[order.status.id] = 0;
      }
      statusCounter[order.status.id]++;
      if (order.status.id === 11) {
        economicEarnings += order.price;
      } else {
        waitingEarnings += order.price;
      }
    });

    const orderData = [];
    this.orderStatuses.forEach((orderStatus) => {
      if (typeof statusCounter[orderStatus.id] === 'number') {
        orderData.push({
          name: orderStatus.statusText,
          value: statusCounter[orderStatus.id]
        });
      }
    });
    this.orderData = orderData;
    this.earningsData = [
      {
        name: 'Afsendt til e-conomic',
        value: economicEarnings,
        extra: { format: 'currency' }
      },
      {
        name: 'Afventende ordrer',
        value: waitingEarnings,
        extra: { format: 'currency' }
      }
    ];
  }

  async loadWaitingUsers() {
    this.waitingUsers = await this.apiService.getInactiveUsers();
  }

  hidePop() {
    this.pop.hide();
  }

  async deleteUser() {
    if (this.deleteID) {
      this.delUserBlock.start();

      await this.apiService.deleteUser(this.deleteID).toPromise();
      await this.loadWaitingUsers();
      this.hidePop();
      this.delUserBlock.stop();
      this.toastrService.success('Brugeren er slettet');
      this.deleteID = null;
    }
  }

}
