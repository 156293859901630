import { FormRow } from '../../dynamicForm/dynaform.interfaces';
import { createDrawerHeightField, createDrawerWidthField, DrawerHeightFieldName, DrawerWidthFieldName, StandardSurfaceTreatmentField, StandardThicknessField } from '../../dynamicForm/standard-fields';
import { createHrRow, createQualityTypeOfWoodSelectionRow } from '../../dynamicForm/standard-rows';


export const MODEL: FormRow[] = [
  createQualityTypeOfWoodSelectionRow(),
  {
    id: 'surface-treatment-row',
    show: true,
    fields: [
      StandardSurfaceTreatmentField,
      StandardThicknessField
    ]
  },
  {
    id: 'lysmaal-height',
    show: true,
    fields: [
      createDrawerWidthField(150, 1200, DrawerWidthFieldName.width),
      createDrawerHeightField(DrawerHeightFieldName.sideHeight)
    ]
  },
  createHrRow(),
  {
    id: 'config',
    type: 'configuration',
    config: ['greb', 'ligeOverkant', 'boerstet']
  }
];
