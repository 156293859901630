import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Palette, PaletteColour, ParsedPaletteColour } from '../../../../../wdcommon/Colours';


@Component({
  selector: 'app-ncs-or-ral-color-picker',
  templateUrl: './ncs-or-ral-color-picker.component.html',
  styleUrls: ['./ncs-or-ral-color-picker.component.css']
})
export class NcsOrRalColorPickerComponent {
  @Input() set paletteColour(inColour: PaletteColour) {
    if (inColour && inColour.NCS) {
      this.emitNcsColour(Object.assign(inColour, {success: true}));
      this.showPalette = Palette.ncs;
    }
    if (inColour && inColour.RAL) {
      this.emitRalColour(Object.assign(inColour, {success: true}));
      this.showPalette = Palette.ral;
    }
  }

  @Output() paletteColourChange = new EventEmitter<PaletteColour>();

  ncsColour: ParsedPaletteColour = {
    HEX: 'transparent',
    Name: '',
    success: false
  };
  ralColour: ParsedPaletteColour = {
    HEX: 'transparent',
    Name: '',
    success: false
  };
  ncs = Palette.ncs;
  ral = Palette.ral;
  showPalette: Palette = Palette.ncs;

  constructor() {
  }

  paletteChanged(palette: Palette) {
    this.showPalette = palette;
    switch (palette) {
      case Palette.ncs:
        this.paletteColourChange.emit(this.ncsColour);
        break;
      case Palette.ral:
        this.paletteColourChange.emit(this.ralColour);
        break;
    }
  }

  emitNcsColour(colour: ParsedPaletteColour) {
    this.ncsColour = colour;
    this.paletteColourChange.emit(colour);
  }

  emitRalColour(colour: ParsedPaletteColour) {
    this.ralColour = colour;
    this.paletteColourChange.emit(colour);
  }

}
