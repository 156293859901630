import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons/faWindowClose';

import { APIService, ImageService, Locale, LocaleService, SettingService } from '../../../services';
import { AdminProductsLanguageService } from '../admin-products-language.service';
import { TranslatingBase } from '../../../base-component/ComponentBase';
import { Constants } from '../../../../../../wdcommon/Constants';
import { IDynamicProductWithLocales } from '../../../../../../wdcommon/IProduct';
import { IIFreightSetting } from '../../../../../../wdcommon/ISetting';


enum ViewType { new, edit, copy }

@Component({
  selector: 'app-admin-products-new',
  templateUrl: './admin-products-new.component.html',
  styleUrls: ['./admin-products-new.component.css']
})
export class AdminProductsNewComponent extends TranslatingBase implements OnInit, OnDestroy {
  categoryId?: number;
  productId?: number;
  product: IDynamicProductWithLocales;

  unsupportedLocales: Locale[] = [];

  productForm: UntypedFormGroup;

  modalRef: BsModalRef;
  imgUrl: string;
  imgFile: File;

  viewType = ViewType.new;
  categorySubscription: Subscription;
  sizeMax: number;
  freightSetting: IIFreightSetting;

  protected readonly faCheck = faCheck;
  protected readonly faWindowClose = faWindowClose;
  protected readonly Constants = Constants;

  constructor(
      private _fb: UntypedFormBuilder,
      private adminProductsLanguageService: AdminProductsLanguageService,
      private api: APIService,
      private imageService: ImageService,
      private localeService: LocaleService,
      private route: ActivatedRoute,
      private settingService: SettingService,
      private toastrService: ToastrService,
      private translateService: TranslateService,
  ) {
    super(translateService);
  }

  async ngOnInit() {
    this.freightSetting = await this.settingService.getFreightSetting();
    this.sizeMax = this.freightSetting.oneDelivery.palletCapacity;
    this.productForm = this._fb.group({
      id: [null],
      categoryId: [null],
      itemNumber: [null, this.cannotContainSpace],
      link: ['', Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6}).*')],
      isSet: [false, Validators.required],
      locale: this._fb.array([]),
      relatedProducts: this._fb.array([]),
      oneDeliverySize: [0],
      oneDeliveryDoublePallet: [false],
      fullPallet: [false],
    });

    this.unsupportedLocales = this.localeService.getSupportedLanguages();

    if (this.route.snapshot.routeConfig.path.indexOf('edit') > -1 && this.route.snapshot.params.productId) {
      this.viewType = ViewType.edit;

      this.productId = +this.route.snapshot.paramMap.get('productId');
      await this.loadProduct(this.productId);
    } else if (this.route.snapshot.routeConfig.path.indexOf('copy') > -1 && this.route.snapshot.params.productId) {
      this.viewType = ViewType.copy;

      this.productId = +this.route.snapshot.paramMap.get('productId');
      await this.loadProduct(this.productId);
    } else {
      this.viewType = ViewType.new;
      this.categoryId = this.route.snapshot.paramMap.get('categoryId') ? +this.route.snapshot.paramMap.get('categoryId') : null;

      this.productForm.controls.categoryId.setValue(this.categoryId);

      this.addLocale(this.adminProductsLanguageService.currentLocale);
    }
  }

  initProductFormLocales(localeId: string, name = '', description = '', price: number = null, breakageFeeCount: number = null, id?: number) {
    return this._fb.group({
      id: [id],
      lang: [localeId],
      name: [name, [Validators.required]],
      description: [description],
      price: [price, [Validators.required]],
      breakageFeeCount: [breakageFeeCount]
    });
  }

  public cannotContainSpace(control: AbstractControl): ValidationErrors | null {
    if (control.value && (control.value as string).indexOf(' ') >= 0) {
      return { cannotContainSpace: true };
    }

    return null;
  }

  addLocale(locale: Locale | string, name?: string, description?: string, price?: number, breakageFeeCount?: number, id?: number) {
    const localeId = typeof locale === 'string' ? locale : locale.id;

    const localesControl = <UntypedFormArray>this.productForm.controls['locale'];
    localesControl.push(this.initProductFormLocales(localeId, name, description, price, breakageFeeCount, id));

    this.unsupportedLocales.splice(this.unsupportedLocales.findIndex(l => l.id === localeId), 1);
  }

  deleteLocale(locale: UntypedFormGroup) {
    const localesControl = <UntypedFormArray>this.productForm.controls['locale'];
    localesControl.controls.splice(localesControl.controls.findIndex(l => l.value === locale.value), 1);
    this.productForm.value.locale.splice(this.productForm.value.locale.findIndex(l => l.lang === locale.value.lang), 1);

    const removedLocale = this.localeService.getLanguageById(locale.value.lang);
    this.unsupportedLocales.push(removedLocale);
  }

  showValidationMsg(formGroup: UntypedFormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: UntypedFormControl = <UntypedFormControl>formGroup.controls[key];

        if (Object.keys(control).includes('controls')) {
          const formGroupChild: UntypedFormGroup = <UntypedFormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }

        control.markAsTouched();
      }
    }
  }

  async createProduct() {
    if (!this.productForm.valid) {
      this.showValidationMsg(this.productForm);

      return this.toastrService.error(this.translate('EnterProductDetails'), this.translate('EnterProductDetailsTitle'), { timeOut: 10000 });
    }

    // Save product and clear product form
    const wasDisabled = this.productForm.controls.oneDeliverySize.disabled;
    if (wasDisabled) {
      this.productForm.controls.oneDeliverySize.enable();
    }
    const savedProduct = await this.api.saveProduct(this.productForm.value);
    if (wasDisabled) {
      this.productForm.controls.oneDeliverySize.disable();
    }
    await this.api.saveProductImage(this.imgFile, savedProduct.id);

    if (this.viewType === ViewType.edit)
      return this.toastrService.success(this.translate('Product_Edited'), this.translate('Product_Edited_Title'), { timeOut: 10000 });

    return this.toastrService.success(this.translate('Product_Added'), this.translate('Product_Added_Title'), { timeOut: 10000 });
  }

  async onFileChosen(event: Event): Promise<void> {
    const { imgFile, imgUrl } = await this.imageService.onFileChosen(event);
    if (imgFile !== null && imgUrl !== null) {
      this.imgFile = imgFile;
      this.imgUrl = imgUrl;
    }
  }

  getRelatedProductsFormArray(): UntypedFormArray {
    return <UntypedFormArray>this.productForm.get('relatedProducts');
  }

  isNew() {
    return this.viewType === ViewType.new;
  }

  isEdit() {
    return this.viewType === ViewType.edit;
  }

  isCopy() {
    return this.viewType === ViewType.copy;
  }

  ngOnDestroy() {
    if (this.categorySubscription)
      this.categorySubscription.unsubscribe();
  }

  private async loadProduct(productId: number) {
    this.product = await this.api.getProductForEditing(productId, this.adminProductsLanguageService.currentLocale.id);
    this.sizeMax = (this.product.oneDeliveryDoublePallet ? 2 : 1) * this.freightSetting.oneDelivery.palletCapacity;

    if (this.viewType === ViewType.copy) {
      delete this.product.id;
      delete this.product.itemNumber;
      this.product.locale.map(l => delete l.id);
    }

    this.productForm.controls.id.setValue(this.product.id);
    this.productForm.controls.categoryId.setValue(this.product.categoryId);
    this.productForm.controls.itemNumber.setValue(this.product.itemNumber);
    this.productForm.controls.link.setValue(this.product.link);
    this.productForm.controls.isSet.setValue(this.product.isSet);
    this.productForm.controls.oneDeliverySize.setValue(this.product.oneDeliverySize ?? 0);
    if (this.product.oneDeliverySize === 255) {
      this.productForm.controls.oneDeliverySize.disable();
    }
    this.productForm.controls.fullPallet.setValue(this.product.oneDeliverySize === 255);
    this.productForm.controls.fullPallet.valueChanges
        .subscribe((value) => {
          if (value === true) {
            this.productForm.controls.oneDeliverySize.setValue(255);
            this.productForm.controls.oneDeliverySize.disable();
          } else {
            this.productForm.controls.oneDeliverySize.enable();
            this.productForm.controls.oneDeliverySize.setValue((this.product.oneDeliverySize === 255) ? 1 : (this.product.oneDeliverySize ?? 0));
          }
        });
    this.productForm.controls.oneDeliveryDoublePallet.setValue(this.product.oneDeliveryDoublePallet === true);
    this.productForm.controls.oneDeliveryDoublePallet.valueChanges
        .subscribe((value) => {
          this.sizeMax = (value ? 2 : 1) * this.freightSetting.oneDelivery.palletCapacity;
          this.product.oneDeliveryDoublePallet = value;
        });
    this.product.locale.forEach(locale => {
      this.addLocale(locale.lang, locale.name, locale.description, locale.price, locale.breakageFeeCount, locale.id);
    });

    const relatedProductsControl = <UntypedFormArray>this.productForm.controls['relatedProducts'];
    this.product.relatedProducts.forEach(product => {
      relatedProductsControl.push(this._fb.group({ id: product.id, name: product.name, itemNumber: product.itemNumber }));
    });

    // Set image
    const image = await this.api.getDynamicProductImage(productId);
    this.imgUrl = image.encImg;
    this.imgFile = await this.imageService.urlToFile(this.imgUrl, 'productimage');
  }

}
