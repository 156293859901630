<div class="container">
  <div class="row">

    <div class="col-12">
      <app-breadcrumbs [currentBreadcrumb]="trashcanBreadcrumb"></app-breadcrumbs>
    </div>

  </div>
  <div class="row">

    <div class="col-sm">

      <app-dynamic-form (change)="validateForm()" [autoFocusFirst]="true" [rows]="model"></app-dynamic-form>

      <div class="d-flex flex-row-reverse pb-3">
        <app-amount-step [(amount)]="amount" [disable]="formIsInvalid" (approved)="amountApproved($event)"></app-amount-step>
      </div>

      <div class="card">
        <div class="card-body">
          <p class="card-text">{{ 'TrashcanDrawer.ProductTypesHeader' | translate }}</p>
          <div class="row">
            <div *ngFor="let productType of allProductTypes" class="col-sm-4 text-center">
              <div><strong>{{ productType[1].volume }} {{ 'liter' | translate }}</strong></div>
              <img class="product-type-img"
                   (click)="setType(productType[0])"
                   [ngClass]="{ 'active': isActive(productType[0]) }"
                   [src]="'/assets/images/' + trashcanDrawer + '/' + productType[0] + '.png'"
                   [alt]="productType[1]"
              />
              <div class="row pt-2  ">
                <div class="col-6 text-end">{{ 'InternalWidth' | translate }}:</div>
                <div class="col-6 text-end">{{ productType[1].internalWidth }} mm</div>
                <div class="col-6 text-end">{{ 'Bredde' | translate }}:</div>
                <div class="col-6 text-end">{{ productType[1].width }} mm</div>
              </div>
              <div class="pt-2"><strong>{{ 'TrashcanDrawer.Trashcans' | translate }}</strong></div>
              <div class="d-inline-block text-end">
                <div *ngFor="let can of productType[1].cans">
                  {{can.volume}} {{ 'liter' | translate }}<span *ngIf="can.bio"> {{ 'bio' | translate }}</span>
                </div>
              </div>
              <div class="pt-2"><a [href]="'/assets/images/' + trashcanDrawer + '/' + productType[0] + '.pdf'" target="_blank">{{ 'productDrawingLink' | translate }}</a></div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div [innerText]="translate('TrashcanDrawer.OptionDescription')"></div>
        </div>
      </div>

    </div>

  </div>
</div>
