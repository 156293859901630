import { Validators } from '@angular/forms';

import { FieldConfig, FormRow } from '../../dynamicForm/dynaform.interfaces';
import { StandardSurfaceTreatmentField, StandardTypeOfWoodField } from '../../dynamicForm/standard-fields';


const standardTypeOfWoodField: FieldConfig = Object.assign({}, StandardTypeOfWoodField, {classes: 'col-12'});

const standardSurfaceTreatmentField: FieldConfig = Object.assign({}, StandardSurfaceTreatmentField, {classes: 'col-12', disabled: false});

export const MODEL: FormRow[] = [{
  id: 'type-row',
  show: true,
  fields: [{
    type: 'select',
    name: 'productType',
    label: 'Type',
    classes: 'col-12',
    options: [],
    translate: {
      itemLabel: false
    },
    validations: [{
      name: 'required',
      validator: Validators.required,
      message: 'Organiser.required.Type'
    }]
  }]
}, {
  id: 'wood-row',
  show: true,
  fields: [standardTypeOfWoodField]
}, {
  id: 'treatment-row',
  show: true,
  fields: [standardSurfaceTreatmentField]
}];
