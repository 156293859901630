import { Injectable } from '@angular/core';

import { APIService } from './api.service';


const originalErrorLog = console.error;
const originalWarningLog = console.warn;
const originalWarningInfo = console.info;

@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  constructor() { }

  private static getLoggingContext() {
    const url = window.location.href;

    return {
      url
    }
  }

  public async setLogging(api: APIService) {
    console.error = async function (message?: any, ...optionalParams: any[]) {
      originalErrorLog(message, ...optionalParams);
      await LoggingService.sendLog(api, { platform: 'frontend', level: 'Error' }, message, ...optionalParams);
    };

    console.warn = async function (message?: any, ...optionalParams: any[]) {
      originalWarningLog(message, ...optionalParams);
      await LoggingService.sendLog(api, { platform: 'frontend', level: 'Warning' }, message, ...optionalParams);
    };

    console.info = async function (message?: any, ...optionalParams: any[]) {
      originalWarningInfo(message, ...optionalParams);
      await LoggingService.sendLog(api, { platform: 'frontend', level: 'Information' }, message, ...optionalParams);
    };
  }

  private static async sendLog(api: APIService, tags: any, message?: any, ...optionalParameters: any[]) {
    const strippedTags = this.stripCircularReferences(tags);
    const strippedMessage = this.stripCircularReferences(message);
    const optionalParams: any[] = optionalParameters.map(optionalParameter => {
      if (typeof optionalParameter !== 'object' || optionalParameter === null) return optionalParameter;

      const clonedObject = Object.assign({}, optionalParameter);

      return this.stripCircularReferences(clonedObject);
    })

    const loggingContext = this.getLoggingContext();

    await api.log(strippedTags, strippedMessage, ...optionalParams, loggingContext)
      .then((value) => {
        if (value === 'OK') return;
        if (typeof value === 'object' && 'err' in value)
          originalErrorLog('An error occured while saving log: ', value.err);
      })
      .catch(e => {
        originalErrorLog(e);
      });
  }

  private static stripCircularReferences(object: Record<any, any>) {
    if (typeof object !== 'object' || object === null) return object;

    const cache = new WeakSet();
    return JSON.parse(JSON.stringify(object, function (key, value) {
      if (typeof value === 'object' && value !== null) {
        if (cache.has(value)) {
          return "[Circular]"
        }
        cache.add(value);
      }
      return value;
    }));
  }
}
