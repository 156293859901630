import { Component, Input, OnInit } from '@angular/core';

import { IProductCategoryTreeResponse } from '../../../../../wdcommon/IProduct';
import { productsUrl, specialsPrefix } from '../../utils/breadcrumbs/breadcrumbs';
import { APIService, LocaleService } from '../../services';


@Component({
  selector: 'app-product-path',
  templateUrl: './product-path.component.html',
  styleUrls: ['./product-path.component.css']
})
export class ProductPathComponent implements OnInit {
  @Input() categoryId: number;
  tree: IProductCategoryTreeResponse[];

  constructor(
      private api: APIService,
      private localeService: LocaleService,
  ) {
  }

  ngOnInit(): void {
    const localeId = this.localeService.getLanguage();

    this.api.getCategoryTree(this.categoryId, localeId)
        .then((tree) => this.tree = [{ id: 0, name: null, description: null }, ...tree]);
  }

  getCategoryPath(category: IProductCategoryTreeResponse) {
    let index: number = this.tree.findIndex((cp) => cp.id === category.id);
    let categoryPart = '';
    while (index > 0) {
      index--;
      categoryPart = this.tree[index].id + '/' + categoryPart;
    }

    return '/admin/' + productsUrl + '/' + specialsPrefix + categoryPart + '/' + (category.id ?? 0);
  }

}
