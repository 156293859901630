import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons/faFilePdf';

import { APIService } from '../../services';
import { Breadcrumb, defaultBreadcrumbs } from '../../utils/breadcrumbs/breadcrumbs';
import { TranslatingBase } from '../../base-component/ComponentBase';
import { IOrderLayoutViewMode } from '../../order-layout/iorder-layout';
import { Constants } from '../../../../../wdcommon/Constants';
import { CartItemType } from '../../../../../wdcommon/ICartItem';
import { FeeTypes, IOrder } from '../../../../../wdcommon/IOrder';
import { IPrices } from '../../../../../wdcommon/IPrices';


@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.css']
})
export class OrderDetailsComponent extends TranslatingBase implements OnInit {
  orderDetailsBreadcrumb: Breadcrumb;

  prices: IPrices;
  order: IOrder;

  toastConfig: Partial<IndividualConfig> = { timeOut: 1000 };
  additionalItemsBreakageFees: { count: number; price: any; };

  protected readonly faFilePdf = faFilePdf;
  protected readonly feeTypes = FeeTypes;
  protected readonly IOrderLayoutViewMode = IOrderLayoutViewMode;

  constructor(
      private apiService: APIService,
      private route: ActivatedRoute,
      private router: Router,
      private location: Location,
      private translateService: TranslateService,
      private toastrService: ToastrService,
  ) {
    super(translateService);
  }

  async ngOnInit() {
    this.prices = await this.apiService.getPrices();
    this.route.params
        .subscribe(async (params) => {
          const order = await this.apiService.getOrderByID(params.id);
          if ((order as any).error) {
            this.toastrService.error((order as any).error);
            return this.router.navigateByUrl('/');
          }
          const parentBreadcrumb = defaultBreadcrumbs['myOrders'];
          this.orderDetailsBreadcrumb = {
            parentId: 'myOrders',
            url: parentBreadcrumb.url + '/order-details/' + order.orderID,
            name: this.translate('ORDERLIST.Ordre ID') + ': ' + order.orderID
          };
          this.order = order;
          this.setupOrder();
        });
  }

  getPDF() {
    this.apiService.getOrderPDF(this.order.orderID)
        .subscribe({
          next: (r: any) => {
            const file = new Blob([r], {
              type: 'application/pdf'
            });

            const fileURL = URL.createObjectURL(file);
            const a = document.createElement('a');
            a.setAttribute('style', 'display: none');
            a.href = fileURL;
            a.download = this.order.orderID + '.pdf';
            a.click();
            URL.revokeObjectURL(fileURL);
            a.remove();
          },
          error: () => {
            this.toastrService.error(this.translate('ORDER.PDFNOTFOUND'), this.translate('ORDER.PDFNOTFOUNDTitle'), this.toastConfig);
          }
        });
  }

  private setupOrder() {
    this.additionalItemsBreakageFees = {
      count: this.order.contents.filter((i) => i.type === CartItemType.additional && i.breakageFeeIncluded).length,
      price: this.prices.tillaeg.breakageFee[Constants.breakageFeeItemNumber]
    };
  }
}
