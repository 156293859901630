import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { TranslatingBase } from '../../base-component/ComponentBase';
import { defaultBreadcrumbs } from '../../utils/breadcrumbs/breadcrumbs';

@Component({
  selector: 'app-cabinet-carcass',
  templateUrl: './cabinet-carcass.component.html',
  styleUrls: ['./cabinet-carcass.component.css']
})
export class CabinetCarcassComponent extends TranslatingBase implements OnInit {
  carcassBreadcrumb = defaultBreadcrumbs.notheggerCarcass;

  constructor(
      private translateService: TranslateService,
      private router: Router
  ) {
    super(translateService);
  }

  ngOnInit() {
  }

  async goto(url: string) {
    await this.router.navigateByUrl(url);
  }

}
