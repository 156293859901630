import { Component } from '@angular/core';
import { faLink } from '@fortawesome/free-solid-svg-icons/faLink';

@Component({
  selector: 'app-descriptions',
  templateUrl: './descriptions.component.html',
  styleUrls: ['./descriptions.component.css']
})
export class FaqDescriptionsComponent {
  faLink = faLink;

  constructor() {
  }
}
