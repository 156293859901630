import { Validators } from '@angular/forms';

import { FormRow } from '../../dynamicForm/dynaform.interfaces';
import { createDrawerHeightField, createDrawerWidthField } from '../../dynamicForm/standard-fields';
import { createHrRow, createTypeOfWoodSurfaceTreatmentSelectionRow } from '../../dynamicForm/standard-rows';


const drawerWidthField = createDrawerWidthField();
drawerWidthField.classes = 'col-sm-4';

export const MODEL: FormRow[] = [
  createTypeOfWoodSurfaceTreatmentSelectionRow(),
  {
    id: 'height-depth-width',
    show: true,
    fields: [
      createDrawerHeightField(),
      {
        type: 'select',
        name: 'dybde_select',
        label: 'Dybde',
        suffix: 'mm',
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message: 'Dybde skal udfyldes'
          }
        ],
        disabled: true,
        show: true,
        options: [],
        classes: 'col-sm-4',
        translate: { itemLabel: false, helptext: false }
      },
      drawerWidthField,
    ]
  },
  createHrRow(),
  {
    id: 'config',
    type: 'configuration',
    config: ['greb']
  }
];
