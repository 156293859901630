import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { humanizeBytes, UploaderOptions, UploadFile, UploadInput, UploadOutput } from 'ngx-uploader';

import { environment } from '../../environments/environment';
import { UserService } from '../services';
import { TranslatingBase } from '../base-component/ComponentBase';

const API_URL = environment.apiUrl;


@Component({
  selector: 'app-logo-uploader',
  templateUrl: './logo-uploader.component.html',
  styleUrls: ['./logo-uploader.component.css']
})
export class LogoUploaderComponent extends TranslatingBase {

  @BlockUI() uploadBlock: NgBlockUI;
  @Input() modalRef: BsModalRef;

  @Output() uploaded = new EventEmitter<boolean>();

  options: UploaderOptions;
  files: UploadFile[];
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;
  validFileExtensions = ['jpg', 'jpeg', 'png'];
  imgUrls: Array<any>;

  constructor(
      private userService: UserService,
      private toastrService: ToastrService,
      translateService: TranslateService,
  ) {
    super(translateService);
    this.options = { concurrency: 1 };
    this.files = []; // local uploading files array
    this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
    this.humanizeBytes = humanizeBytes;
  }

  onUploadOutput(output: UploadOutput): void {
    switch (output.type) {
      case 'allAddedToQueue':

        // uncomment this if you want to auto upload files when added
        // const event: UploadInput = {
        //   type: 'uploadAll',
        //   url: '/upload',
        //   method: 'POST',
        //   data: { foo: 'bar' }
        // };
        // this.uploadInput.emit(event);
        break;
      case 'addedToQueue':
        this.imgUrls = [];
        if (typeof output.file !== 'undefined') {
          const fileSplit = output.file.name.split('.');
          const fileExt = fileSplit[fileSplit.length - 1];
          if (this.validFileExtensions.includes(fileExt)) {
            this.files.push(output.file);
            const reader = new FileReader();
            reader.readAsDataURL(output.file.nativeFile);
            reader.onload = () => {
              this.imgUrls.push(reader.result);
            };
          } else {
            this.uploadBlock.stop();
            this.toastrService.error(this.translate('LOGO.Profile.FileTypes'), null, { timeOut: 10000 });
            this.uploaded.emit(false);
          }
        }
        break;
      case 'uploading':
        if (typeof output.file !== 'undefined') {
          // update current data in files array for uploading file
          const index = this.files.findIndex((file) => typeof output.file !== 'undefined' && file.id === output.file.id);
          this.files[index] = output.file;
        }
        break;
      case 'removed':
        // remove file from array when removed
        this.files = this.files.filter((file: UploadFile) => file !== output.file);
        break;
      case 'dragOver':
        this.dragOver = true;
        break;
      case 'dragOut':
      case 'drop':
        this.dragOver = false;
        break;
      case 'done':
        // The file is downloaded
        console.log('Upload er slut .... ');
        this.uploadBlock.stop();
        this.modalRef.hide();
        this.uploaded.emit(true);
        break;
    }
  }

  startUpload() {
    if (this.files.length === 0) {
      return this.toastrService.error(this.translate('LOGO.Profile.Nofiles'), null, { timeOut: 10000 });
    }

    const event: UploadInput = {
      type: 'uploadAll',
      url: API_URL + '/users/logo',
      method: 'POST',
      headers: {
        Authorization: this.userService.getUserToken()
      }
    };
    this.uploadBlock.start('Uploader logo ...');
    this.uploadInput.emit(event);
  }

}



