import { CartItemType, ICartItem } from './ICartItem';
import { OptionProperty, IProductCategoryTreeResponse, OtherProductShortName } from './IProduct';
import { Constants } from './Constants';

export interface PriceType {
  total: number;
  base: number;
  [options: string]: number;
}

export interface IBasePrices {
  [depthClass: string]: {
    [heightClass: string]: number[];
  };
}

export enum IDrawerPostalInterval {
  i01to20 = '1-20',
  i21to40 = '21-40',
  i41to60 = '41-60',
  i61to80 = '61-80',
  i81to100 = '81-100',
  i101to = '101-'
}

export interface IPostalFreight {
  'min.fragt': number;
  [OtherProductShortName.profiles]: number;
  fragtPrice?: number;
  minPostalCode?: number;
  maxPostalCode?: number;
  [IDrawerPostalInterval.i01to20]?: number;
  [IDrawerPostalInterval.i21to40]?: number;
  [IDrawerPostalInterval.i41to60]?: number;
  [IDrawerPostalInterval.i61to80]?: number;
  [IDrawerPostalInterval.i81to100]?: number;
  [IDrawerPostalInterval.i101to]?: number;
  told?: number;
}

export interface IAddOnPrices {
  [OptionProperty.shimsSelection]?: {
    [typeName: string]: number;
  };
  bestikindsats?: {
    [typeName: string]: number;
  };
  breakageFee?: {
    [Constants.breakageFeeItemNumber]: number;
  };
  bs_addon?: {
    [name: string]: number | string;
  };
  [OptionProperty.drawerBase]: {
    [bottom: string]: number | string;
  };
  extras: {
    [extra: string]: number | string;
  };
  fastLine?: {
    [typeName: string]: number;
  };
  [OptionProperty.premountedCoupling]: {
    [couplingType: string]: number;
  };
  fragt?: {
    [country: string]: {
      postnr: IPostalFreight[];
      default: IPostalFreight;
    };
  };
  koblinger?: {
    [typeName: string]: number;
  };
  [OptionProperty.woodQuality]?: {
    [qualityClass: string]: string;
  };
  logo?: {
    [location: string]: number;
  };
  [OptionProperty.surfaceTreatment]: {
    [surfaceTreatment: string]: string | { [surfaceTreatmentSub: string]: number };
  };
  [OptionProperty.joint]?: {
    [joint: string]: string;
  };
  slacksDrawer?: {
    [typeName: string]: {
      [widthType: string]: {
        [typeOfWood: string]: number;
      };
    };
  };
  solidOrganiser?: {
    prices: {
      [typeName: string]: number;
    };
    woodAdjust: {
      [woodType: string]: string;
    };
    treatmentAdjust: {
      [surfaceTreatment: string]: string;
    };
  };
  sortissimoFastline?: {
    [typeName: string]: {
      [sub: string]: number | string;
    };
  };
  [OptionProperty.typeOfWood]: {
    [typeOfWood: string]: string;
  };
  trashcanDrawer?: {
    [typeName: string]: {
      [typeOfWood: string]: number;
    };
  };
  type: {
    [typeName: string]: number | string;
  };
}

export interface ITypePrices {
  [typeName: string]: number;
}

export interface IOtherPrices {
  fastLine: ITypePrices;
  afstandsListe: ITypePrices;
  profiles: ITypePrices;
}

export interface IPrices {
  base: IBasePrices;
  tillaeg: IAddOnPrices;
  udtraek: ITypePrices;
}

export interface IFrontHingeSetup {
  hinges: number;
  max: number;
  min: number;
}

export interface IFrontDrillingSetup {
  drawerPrice: number;
  gripPrices: {
    R: number;
    S: number;
    J: number;
  }
  hingePrice: number;
  hingesIkea: IFrontHingeSetup[];
  hingesUP: IFrontHingeSetup[];
}

export interface IFrontPrices {
  [priceCategory: string]: {
    [design: string]: number;
  };
}

export interface IFreightCalculationCartItems {
  drawers: ICartItem[];
  [CartItemType.runner]: ICartItem[];
  extras: ICartItem[];
  additionals: ICartItem[];
  [CartItemType.fronts]: ICartItem[];
  [CartItemType.carcass]: ICartItem[];
}

export interface IBreakageFee {
  count: number;
  price: number;
}

export interface IFreight {
  told?: number;
  basePrice: number;
  hasFronts: boolean;
  frontsAddition: number;
  additionalsFreight: IProductCategoryTreeResponse[];
  additionalsBreakageFees: IBreakageFee;
}
