import { TranslateService } from '@ngx-translate/core';

import { HettichService, PriceCalculatorService } from '../';
import { Cart } from './CartItem';
import { DrawerCartItem } from './DrawerCartItem';
import { OtherCartItem } from './OtherCartItem';
import { CartItemType } from '../../../../../wdcommon/ICartItem';
import { ICompanyAdjustments } from '../../../../../wdcommon/ICompany';


export class SynchronisationBarCartItem extends OtherCartItem {
  private SynchronisationBarWidthInMm = 1450;

  constructor(
    private ____translateService: TranslateService,
    private _priceService: PriceCalculatorService,
    private _companyAdjustmentsProvider: () => Promise<ICompanyAdjustments>,
    private _hettichService: HettichService,
    ) {
    super(
      ____translateService,
      _priceService,
      _companyAdjustmentsProvider,
      {
        brandId: 'hettich',
        type: CartItemType.hettichRunnerAddOn,
        itemno: '9348744',
        name: ____translateService.instant('CARTDETAILS.udtraekSynkronstang'),
        description: ____translateService.instant('tilEgennavn', { egennavn: 'push to open systemer' }),
        amount: 0,
      },
        _hettichService,
      items => this.calcAmountOfSynchronisationBar(items)
    );
    this.setHettichBrandId();
  }

  private calcAmountOfSynchronisationBar(items: Cart) {

    let totalSynchronisationBarWidth = 0;
    for (const drawer of items.filter(i => i.type === CartItemType.drawer)) {
      if (drawer instanceof DrawerCartItem) {
        totalSynchronisationBarWidth += drawer.getSynchronisationBarWidth();
      }
    }

    return Math.ceil(totalSynchronisationBarWidth / this.SynchronisationBarWidthInMm);
  }
}
