import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminCategoryComponent } from './admin/admin-category/admin-category.component';
import { AdminCompaniesComponent } from './admin/admin-companies/admin-companies.component';
import { AdminCompanyComponent } from './admin/admin-company/admin-company.component';
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import { AdminErnstMairComponent } from './admin/admin-ernst-mair/admin-ernst-mair.component';
import { AdminOrderComponent } from './admin/admin-order/admin-order.component';
import { AdminOrdersComponent } from './admin/admin-orders/admin-orders.component';
import { AdminProductsNewComponent } from './admin/admin-products/admin-products-new/admin-products-new.component';
import { AdminProductsComponent } from './admin/admin-products/admin-products.component';
import { AdminUsersComponent } from './admin/admin-users/admin-users.component';
import { AdminComponent } from './admin/admin.component';
import { EditErnstMairComponent } from './admin/edit-ernst-mair/edit-ernst-mair.component';
import { GeneralEditorComponent } from './admin/general-editor/general-editor.component';
import { InfoEditorComponent } from './admin/info-editor/info-editor.component';
import { CartComponent } from './cart/cart.component';
import { MyProfileComponent } from './profile/my-profile/my-profile.component';
import { MyOrdersComponent } from './profile/my-orders/my-orders.component';
import { OrderDetailsComponent } from './profile/order-details/order-details.component';
import { CompanyAdminComponent } from './profile/company-admin/company-admin.component';
import { DynamicOrderComponent } from './dynamic-order/dynamic-order.component';
import { FaqDescriptionsComponent } from './faq/descriptions/descriptions.component';
import { FaqComponent } from './faq/faq.component';
import { FaqQualityComponent } from './faq/quality/quality.component';
import { AdminGuard } from './guards/admin.guard';
import { AuthGuard } from './guards/auth.guard';
import { CompanyAuthGuardGuard } from './guards/company-auth-guard.guard';
import { InternetExplorerGuard } from './guards/internet-explorer.guard';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './login/reset-password/reset-password.component';
import { LogoutComponent } from './logout/logout.component';
import { MasterLayoutComponent } from './master-layout/master-layout.component';
import { ErnstMairComponent } from './ernst-mair/ernst-mair.component';
import { NotheggerComponent } from './nothegger/nothegger.component';
import { BestikindsatsComponent } from './nothegger/bestikindsats/bestikindsats.component';
import { ProfilesComponent } from './nothegger/profiles/profiles.component';
import { SolidOrganiserComponent } from './nothegger/solid-organiser/solid-organiser.component';
import { TrashcanDrawerComponent } from './nothegger/trashcan-drawer/trashcan-drawer.component';
import { SortissimoFastlineComponent } from './nothegger/sortissimo-fastline/sortissimo-fastline.component';
import { SlacksDrawerComponent } from './nothegger/slacks-drawer/slacks-drawer.component';
import { CabinetCarcassComponent } from './nothegger/cabinet-carcass/cabinet-carcass.component';
import { PurewoodFrontComponent } from './purewood/purewood-fronts/purewood-front/purewood-front.component';
import { PurewoodFrontsComponent } from './purewood/purewood-fronts/purewood-fronts.component';
import { PurewoodDrawersComponent } from './purewood/purewood-drawers/purewood-drawers.component';
import { PurewoodComponent } from './purewood/purewood.component';
import { ProductsComponent } from './admin/products/products.component';
import { CompanyUserComponent } from './signup/company-user/company-user.component';
import { SignupComponent } from './signup/signup.component';
import { UnsupportedBrowserComponent } from './unsupported-browser/unsupported-browser.component';
import { Manufacturer, OtherProductShortName } from '../../../wdcommon/IProduct';
import { productPrefix, productsUrl, productTypesUrl, specialsPrefix } from './utils/breadcrumbs/breadcrumbs';
import { GeneralProductCategoryComponent } from './general-product-category/general-product-category.component';
import { GeneralProductComponent } from './general-product-category/general-product/general-product.component';
import { AdminGlobalContentComponent } from './admin/admin-global-content/admin-global-content.component';
import { GlobalContentEditorComponent } from './admin/admin-global-content/global-content-editor/global-content-editor.component';
import { ProductTypeComponent } from './product-type/product-type.component';
import { AdminProductsAllComponent } from './admin/admin-products-all/admin-products-all.component';
import { CareSetComponent } from './nothegger/care-set/care-set.component';
import { AdminFreightComponent } from './admin/admin-freight/admin-freight.component';
import { UserActivatedGuard } from './guards/user-activated.guard';


const routes: Routes = [
  { path: 'unsupported-browser', component: UnsupportedBrowserComponent },
  { path: 'login', component: LoginComponent, canActivate: [InternetExplorerGuard] },
  { path: 'resetpassword/:hash', component: ResetPasswordComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'company-signup/:hash', component: CompanyUserComponent },
  { path: 'logout', component: LogoutComponent, canActivate: [AuthGuard] },
  {
    path: '', component: MasterLayoutComponent, canActivate: [InternetExplorerGuard], children: [
      { path: '', component: HomeComponent },
      {
        path: 'profile', canActivate: [AuthGuard], children: [
          { path: 'my-profile', component: MyProfileComponent },
          { path: 'my-orders', component: MyOrdersComponent },
          { path: 'my-orders/order-details/:id', component: OrderDetailsComponent },
          { path: 'company-admin', component: CompanyAdminComponent, canActivate: [CompanyAuthGuardGuard] },
        ]
      },
      { path: 'order/:type', component: DynamicOrderComponent },
      { path: 'order/edit/:itemno', component: DynamicOrderComponent },
      { path: 'order/repeat/:type', component: DynamicOrderComponent },

      { path: productTypesUrl + '/:productType', component: ProductTypeComponent },

      { path: productsUrl + '/' + Manufacturer.nothegger, component: NotheggerComponent },
      { path: productsUrl + '/' + Manufacturer.nothegger + '/cabinet-carcass', component: CabinetCarcassComponent },
      { path: productsUrl + '/' + Manufacturer.nothegger + '/sortissimo', component: BestikindsatsComponent },
      { path: productsUrl + '/' + Manufacturer.nothegger + '/' + OtherProductShortName.careSet, component: CareSetComponent },
      { path: productsUrl + '/' + Manufacturer.nothegger + '/' + OtherProductShortName.sortissimoFastline, component: SortissimoFastlineComponent },
      { path: productsUrl + '/' + Manufacturer.nothegger + '/' + OtherProductShortName.solidOrganiser, component: SolidOrganiserComponent },
      { path: productsUrl + '/' + Manufacturer.nothegger + '/' + OtherProductShortName.slacksDrawer, component: SlacksDrawerComponent },
      { path: productsUrl + '/' + Manufacturer.nothegger + '/' + OtherProductShortName.trashcanDrawer, component: TrashcanDrawerComponent },
      { path: productsUrl + '/' + Manufacturer.nothegger + '/profiles', component: ProfilesComponent },
      { path: productsUrl + '/' + Manufacturer.nothegger + '/:type', component: DynamicOrderComponent },

      { path: productsUrl + '/' + Manufacturer.purewood, component: PurewoodComponent },
      { path: productsUrl + '/' + Manufacturer.purewood + '/fronts/:category', component: PurewoodFrontsComponent },
      { path: productsUrl + '/' + Manufacturer.purewood + '/fronts/:category/:type', component: PurewoodFrontComponent },
      { path: productsUrl + '/' + Manufacturer.purewood + '/front-edit/:editItemNo', component: PurewoodFrontComponent },
      { path: productsUrl + '/' + Manufacturer.purewood + '/front-repeat/:category/:type', component: PurewoodFrontComponent },
      { path: productsUrl + '/' + Manufacturer.purewood + '/drawers', component: PurewoodDrawersComponent },
      { path: productsUrl + '/' + Manufacturer.purewood + '/drawers/:type', component: DynamicOrderComponent },

      { path: productsUrl + '/' + Manufacturer.ernstMair, component: ErnstMairComponent },
      { path: productsUrl + '/' + Manufacturer.ernstMair + '/:type', component: DynamicOrderComponent },

      { path: productsUrl + '/' + specialsPrefix + ':brandCategoryId/' + productPrefix + ':productId', component: GeneralProductComponent },
      { path: productsUrl + '/' + specialsPrefix + ':brandCategoryId/:categoryId/' + productPrefix + ':productId', component: GeneralProductComponent },
      { path: productsUrl + '/' + specialsPrefix + ':brandCategoryId/:parent/:categoryId/' + productPrefix + ':productId', component: GeneralProductComponent },
      { path: productsUrl + '/' + specialsPrefix + ':brandCategoryId/:grand/:parent/:categoryId/' + productPrefix + ':productId', component: GeneralProductComponent },
      { path: productsUrl + '/' + specialsPrefix + ':brandCategoryId/:gr8aGrand/:grand/:parent/:categoryId/' + productPrefix + ':productId', component: GeneralProductComponent },
      { path: productsUrl + '/' + specialsPrefix + ':brandCategoryId/:gr8bGrand/:gr8aGrand/:grand/:parent/:categoryId/' + productPrefix + ':productId', component: GeneralProductComponent },
      { path: productsUrl + '/' + specialsPrefix + ':brandCategoryId/:gr8cGrand/:gr8bGrand/:gr8aGrand/:grand/:parent/:categoryId/' + productPrefix + ':productId', component: GeneralProductComponent },
      { path: productsUrl + '/' + specialsPrefix + ':brandCategoryId', component: GeneralProductCategoryComponent },
      { path: productsUrl + '/' + specialsPrefix + ':brandCategoryId/:categoryId', component: GeneralProductCategoryComponent },
      { path: productsUrl + '/' + specialsPrefix + ':brandCategoryId/:parent/:categoryId', component: GeneralProductCategoryComponent },
      { path: productsUrl + '/' + specialsPrefix + ':brandCategoryId/:grand/:parent/:categoryId', component: GeneralProductCategoryComponent },
      { path: productsUrl + '/' + specialsPrefix + ':brandCategoryId/:gr8aGrand/:grand/:parent/:categoryId', component: GeneralProductCategoryComponent },
      { path: productsUrl + '/' + specialsPrefix + ':brandCategoryId/:gr8bGrand/:gr8aGrand/:grand/:parent/:categoryId', component: GeneralProductCategoryComponent },
      { path: productsUrl + '/' + specialsPrefix + ':brandCategoryId/:gr8cGrand/:gr8bGrand/:gr8aGrand/:grand/:parent/:categoryId', component: GeneralProductCategoryComponent },

      { path: 'cart', component: CartComponent, canActivate: [AuthGuard, UserActivatedGuard] },
      { path: 'faq/general', component: FaqComponent },
      { path: 'faq/quality', component: FaqQualityComponent },
      { path: 'faq/descriptions', component: FaqDescriptionsComponent },
      {
        path: 'admin', component: AdminComponent, canActivate: [AdminGuard], children: [
          { path: '', component: AdminDashboardComponent },
          { path: 'freight', component: AdminFreightComponent },
          { path: 'info', component: InfoEditorComponent },
          { path: 'faq/general', component: GeneralEditorComponent },
          { path: 'faq/quality', component: GeneralEditorComponent },
          { path: 'orders', component: AdminOrdersComponent },
          { path: 'orders/:id', component: AdminOrderComponent },
          { path: 'users', component: AdminUsersComponent },
          { path: 'companies', component: AdminCompaniesComponent },
          { path: 'drawers', component: ProductsComponent },
          { path: 'drawers/' + Manufacturer.nothegger, component: NotheggerComponent },
          { path: 'drawers/' + Manufacturer.ernstMair, component: ErnstMairComponent },
          { path: 'companies/:id', component: AdminCompanyComponent },
          { path: 'products/new/:categoryId', component: AdminProductsNewComponent },
          { path: 'products/edit/:productId', component: AdminProductsNewComponent },
          { path: 'products/copy/:productId', component: AdminProductsNewComponent },
          { path: productsUrl + '/' + specialsPrefix + 'all', component: AdminProductsAllComponent },
          { path: productsUrl + '/' + specialsPrefix + ':categoryId', component: AdminProductsComponent },
          { path: productsUrl + '/' + specialsPrefix + '0/:categoryId', component: AdminProductsComponent },
          { path: productsUrl + '/' + specialsPrefix + '0/:parent/:categoryId', component: AdminProductsComponent },
          { path: productsUrl + '/' + specialsPrefix + '0/:grand/:parent/:categoryId', component: AdminProductsComponent },
          { path: productsUrl + '/' + specialsPrefix + '0/:gr8aGrand/:grand/:parent/:categoryId', component: AdminProductsComponent },
          { path: productsUrl + '/' + specialsPrefix + '0/:gr8bGrand/:gr8aGrand/:grand/:parent/:categoryId', component: AdminProductsComponent },
          { path: productsUrl + '/' + specialsPrefix + '0/:gr8cGrand/:gr8bGrand/:gr8aGrand/:grand/:parent/:categoryId', component: AdminProductsComponent },
          { path: productsUrl + '/' + specialsPrefix + '0/:gr8dGrand/:gr8cGrand/:gr8bGrand/:gr8aGrand/:grand/:parent/:categoryId', component: AdminProductsComponent },
          { path: 'products/categories/:categoryId', component: AdminCategoryComponent },
          { path: Manufacturer.ernstMair, component: AdminErnstMairComponent },
          { path: Manufacturer.ernstMair + '/drawers', component: AdminErnstMairComponent },
          { path: Manufacturer.ernstMair + '/edit', component: EditErnstMairComponent },
          { path: Manufacturer.ernstMair + '/edit/:type', component: EditErnstMairComponent },
          { path: 'global-content', component: AdminGlobalContentComponent },
          { path: 'global-content/:type/:key', component: GlobalContentEditorComponent }
        ]
      }
    ]
  },
  { path: '**', redirectTo: '/' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
