import { Component, Input } from '@angular/core';
import { IDynamicProduct } from '../../../../../../wdcommon/IProduct';
import { IRelatedProductSearchResult } from '../../../../../../wdcommon/IRelatedProduct';

@Component({
  selector: 'app-item-number',
  templateUrl: './item-number.component.html',
  styleUrls: ['./item-number.component.css']
})
export class ItemNumberComponent {

  @Input() product: IDynamicProduct | IRelatedProductSearchResult;

}
