import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { ICutleryTrayOptions } from '../../../../../wdcommon/ICartItem';
import { IOption, OptionProperty, OtherProductShortName } from '../../../../../wdcommon/IProduct';
import { FieldConfig, FormRow, SelectOption } from '../../dynamicForm/dynaform.interfaces';
import { DynamicFormComponent } from '../../dynamicForm/dynamic-form/dynamic-form.component';
import { TranslatingBase } from '../../base-component/ComponentBase';
import { APIService, ModelLoaderService, UtilitiesService } from '../../services';
import { defaultBreadcrumbs } from '../../utils/breadcrumbs/breadcrumbs';


interface ISortissimoProduct {
  [type: string]: {
    minDepth: number;
    maxRegularDepth: number;
    minWidth: number;
    maxRegularWidth: number;
  };
}

const sortissimoProducts: ISortissimoProduct = {
  U1: {
    minDepth: 220,
    maxRegularDepth: 340,
    minWidth: 220,
    maxRegularWidth: 650
  },
  U2: {
    minDepth: 260,
    maxRegularDepth: 550,
    minWidth: 350,
    maxRegularWidth: 650
  },
  U3: {
    minDepth: 340,
    maxRegularDepth: 550,
    minWidth: 350,
    maxRegularWidth: 650
  },
  U4: {
    minDepth: 340,
    maxRegularDepth: 550,
    minWidth: 350,
    maxRegularWidth: 650
  },
  U5: {
    minDepth: 340,
    maxRegularDepth: 550,
    minWidth: 350,
    maxRegularWidth: 650
  },
  U6: {
    minDepth: 340,
    maxRegularDepth: 550,
    minWidth: 500,
    maxRegularWidth: 650
  },
  U7: {
    minDepth: 500,
    maxRegularDepth: 550,
    minWidth: 500,
    maxRegularWidth: 650
  },
  U8: {
    minDepth: 400,
    maxRegularDepth: 550,
    minWidth: 500,
    maxRegularWidth: 650
  },
};

const heights: SelectOption[] = [
  { label: '52', value: '52' },
  { label: '92', value: '92' }
];

const setValue = (control: AbstractControl, value: any) => {
  if (control && control.value !== value)
    control.setValue(value === undefined ? null : value);
};


@Component({
  selector: 'app-sortissimo',
  templateUrl: './sortissimo.component.html',
  styleUrls: ['./sortissimo.component.css']
})
export class SortissimoComponent extends TranslatingBase implements OnInit, AfterViewInit {
  sortissimoBreadcrumb = defaultBreadcrumbs.notheggerSortissimoOrganiser;
  @Input() widthDepthHidden: boolean;
  @ViewChild(DynamicFormComponent) form: DynamicFormComponent;

  sortissimoOrganiser = OtherProductShortName.sortissimoOrganiser;
  bsTypes: SelectOption[] = [];
  special = false;
  model: FormRow[] = [];
  antal = 1;
  bsOptions: IOption[];
  mySortissimoDepthValidator: Function;
  mySortissimoWidthValidator: Function;

  constructor(private fb: UntypedFormBuilder,
              private translateService: TranslateService,
              public api: APIService,
              private router: Router,
              private utilities: UtilitiesService,
              private modelLoader: ModelLoaderService) {
    super(translateService);
  }

  ngOnInit() {
    this.model = this.modelLoader.load('sortissimo');
    this.mySortissimoDepthValidator = (): { [s: string]: boolean; } => {
      return !this.isSortissimoDepthValid() ? { invalid_indsats_combi: true } : null;
    };
    this.mySortissimoWidthValidator = (): { [s: string]: boolean; } => {
      return !this.isSortissimoWidthValid() ? { invalid_indsats_bredde: true } : null;
    };
  }

  async ngAfterViewInit() {
    this.bsOptions = await this.api.getProductOptions();
    this.loadOptions();
    if (this.widthDepthHidden) {
      this.form.getField('bredde').show = false;
      this.form.getField('dybde').show = false;
    } else {
      this.form.getField('bredde').show = true;
      this.form.getField('dybde').show = true;
    }

    this.addWidthAndDepthValidations();
  }

  addWidthAndDepthValidations() {
    const validDepthList = [];
    const depthValidations = this.form.rows.find(r => r.fields && r.fields.find(f => f.name === 'dybde')).fields.find(f => f.name === 'dybde').validations || [];

    if (!depthValidations.some(v => v.name === 'invalid_indsats_combi')) {
      depthValidations.push({
        name: 'invalid_indsats_combi',
        validator: this.mySortissimoDepthValidator,
        message: 'BestikIndsatsCombiInvalid'
      });
    }
    depthValidations.forEach(valid => {
      validDepthList.push(valid.validator);
    });
    validDepthList.push(this.mySortissimoDepthValidator);
    this.form.getControl('dybde').setValidators(Validators.compose(validDepthList));

    const validWidthList = [];
    const widthValidations = this.form.rows.find(r => r.fields && r.fields.find(f => f.name === 'bredde')).fields.find(f => f.name === 'bredde').validations || [];

    if (!widthValidations.some(v => v.name === 'invalid_indsats_bredde')) {
      widthValidations.push({
        name: 'invalid_indsats_bredde',
        validator: this.mySortissimoWidthValidator,
        message: 'BestikIndsatsCombiInvalid'
      });
    }
    widthValidations.forEach(valid => {
      validWidthList.push(valid.validator);
    });
    validWidthList.push(this.mySortissimoWidthValidator);
    this.form.getControl('bredde').setValidators(Validators.compose(validWidthList));
  }

  public isSortissimoDepthValid() {
    if (this.form) {
      const sortissimoType = this.form.getControl('bstype').value;
      const depth = parseInt(this.form.getControl('dybde').value, null);

      if (sortissimoType && depth) {
        if (depth < sortissimoProducts[sortissimoType].minDepth) {
          return false;
        }
        if (sortissimoType === 'U6' || sortissimoType === 'U7' || sortissimoType === 'U8') {
          return (depth <= sortissimoProducts[sortissimoType].maxRegularDepth);
        }
      }
    }
    return true;
  }

  public isSortissimoWidthValid() {
    if (this.form) {
      const sortissimoType = this.form.getControl('bstype').value;
      const width = parseInt(this.form.getControl('bredde').value, null);

      if (sortissimoType && width) {
        return (width >= sortissimoProducts[sortissimoType].minWidth);
      }
    }
    return true;
  }

  setOptions = (field: FieldConfig, options: SelectOption[]) => {
    const control = this.form.getControl(field.name);
    if (!control)
      throw new Error(`Got field named ${field.name} but can't find control.`);

    field.options = options;
  };

  setDepth(dybde: number) {
    setValue(this.form.getControl('dybde'), dybde.toString());
  }

  setWidth(width: number) {
    setValue(this.form.getControl('bredde'), width.toString());
  }

  public getCutleryTrayOptions(): ICutleryTrayOptions {
    if (!this.form.valid) {
      return null;
    }

    const type = this.form.getControl('bstype').value;
    const width = parseInt(this.form.getControl('bredde').value, 10);
    const depth = parseInt(this.form.getControl('dybde').value, 10);

    return {
      type,
      [OptionProperty.depth]: depth,
      [OptionProperty.width]: width,
      [OptionProperty.height]: parseInt(this.form.getControl(OptionProperty.height).value, 10),
      [OptionProperty.typeOfWood]: this.form.getControl(OptionProperty.typeOfWood).value,
      [OptionProperty.surfaceTreatment]: this.form.getControl(OptionProperty.surfaceTreatment).value,
      special: (depth > sortissimoProducts[type].maxRegularDepth) || (width > sortissimoProducts[type].maxRegularWidth),
      bundLeveret: (this.form.getControl('levbund').value || false),
    };
  }

  public setDefaultValues(defaults: ICutleryTrayOptions) {
    if (defaults && defaults[OptionProperty.width] && defaults[OptionProperty.depth]) {
      setValue(this.form.getControl('bredde'), defaults[OptionProperty.width]);
      setValue(this.form.getControl('dybde'), defaults[OptionProperty.depth]);
    } else {
      this.form.getField('bredde').helpHTML = '';
      this.form.getField('dybde').helpHTML = '';
    }
    if (defaults) {
      if (defaults[OptionProperty.typeOfWood] && defaults[OptionProperty.surfaceTreatment] !== 'eg_rustik') {
        setValue(this.form.getControl(OptionProperty.typeOfWood), defaults[OptionProperty.typeOfWood]);
      }
      setValue(this.form.getControl(OptionProperty.surfaceTreatment), defaults[OptionProperty.surfaceTreatment]);
      setValue(this.form.getControl('bstype'), defaults.type);
      setValue(this.form.getControl(OptionProperty.height), defaults[OptionProperty.height] ? defaults[OptionProperty.height].toString() : null);
      setValue(this.form.getControl('levbund'), defaults.bundLeveret || false);
    }
  }

  loadOptions() {
    for (const row of this.model) {
      for (const field of row.fields || []) {
        if (field && field.name === OptionProperty.typeOfWood) {
          field.options = this.bsOptions
              .filter((o) => ((o.property === field.name) && (o.types.indexOf(this.sortissimoOrganiser) > -1)))
              .sort(this.utilities.abcSort);
        } else if (field && field.name === OptionProperty.surfaceTreatment) {
          const relOpts = this.bsOptions.filter((o) => ((o.property === field.name) && (o.types.indexOf(this.sortissimoOrganiser) > -1)));
          const surfaces: IOption[] = [];
          relOpts.forEach((o) => {
            surfaces.push(o);
          });
          surfaces.sort(this.utilities.abcSort);
          field.options = surfaces;
        } else if (field && field.name === 'bstype') {
          for (let i = 1; i < 9; i++) {
            this.bsTypes.push({ label: 'U' + i, value: 'U' + i });
          }
          field.options = this.bsTypes;
        } else if (field && field.name === OptionProperty.height) {
          field.options = heights;
        }
      }
    }
  }

  isActive(type: string): boolean {
    return this.form !== undefined && this.form.getControl('bstype').value === type;
  }

  setType(type: string) {
    setValue(this.form.getControl('bstype'), type);
  }

  async submit() {
    if (!this.form.valid || !this.form.touched)
      return;
  }

  async goto(url: string) {
    await this.router.navigateByUrl(url);
  }

}
