import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UserService } from '../services';


@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(
      private router: Router,
      private userService: UserService
  ) { }

  async ngOnInit() {
    sessionStorage.clear();
    this.userService.logoutUser();
    await this.router.navigateByUrl('/');
  }

}
