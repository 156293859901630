import { Component, OnInit } from '@angular/core';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';

import { APIService, Locale, LocaleService, SettingService } from '../../services';
import { defaultBreadcrumbs, productsUrl, specialsPrefix } from '../../utils/breadcrumbs/breadcrumbs';
import { Constants } from '../../../../../wdcommon/Constants';
import { IDrawerPostalInterval, IPrices } from '../../../../../wdcommon/IPrices';
import { IProductCategoryResponse } from '../../../../../wdcommon/IProduct';
import { IIFreightSetting } from '../../../../../wdcommon/ISetting';

@Component({
  selector: 'app-admin-prices',
  templateUrl: './admin-freight.component.html',
  styleUrls: ['./admin-freight.component.css']
})
export class AdminFreightComponent implements OnInit {
  countries = [
    {
      name: 'Danmark',
      tier: 'dk',
    }, {
      name: 'Norge',
      tier: 'no',
    }, {
      name: 'Sverige',
      tier: 'se',
    }
  ];
  locales: Locale[];

  freightSetting: IIFreightSetting;

  prices: IPrices;
  specialCategories: { [locale: string]: IProductCategoryResponse[] } = {};

  purewoodFrontExampleSmall = 5;
  purewoodFrontExampleLarge = 0;

  protected readonly faEdit = faEdit;
  protected readonly productsUrl = productsUrl;
  protected readonly specialsPrefix = specialsPrefix;
  protected readonly Constants = Constants;
  protected readonly adminFreightBreadcrumb = defaultBreadcrumbs.adminFreight;
  protected readonly IDrawerPostalInterval = IDrawerPostalInterval;
  protected readonly Math = Math;

  constructor(
      private apiService: APIService,
      private localesService: LocaleService,
      private settingService: SettingService,
  ) {
  }

  get examplePallets() {
    const minPallets = Math.ceil(
        (this.purewoodFrontExampleLarge * this.freightSetting.purewood.front.m2ToWeight)
        / (this.freightSetting.purewood.front.palletMaxWeight * 2)
    ) * 2;
    const weightPallets = Math.ceil(
        ((this.purewoodFrontExampleLarge + this.purewoodFrontExampleSmall) * this.freightSetting.purewood.front.m2ToWeight)
        / this.freightSetting.purewood.front.palletMaxWeight
    );
    return Math.max(minPallets, weightPallets);
  }

  ngOnInit() {
    this.locales = this.localesService.getSupportedLanguages();
    this.apiService.getPrices()
        .then((prices) => this.prices = prices);

    this.settingService.getFreightSetting()
        .then((freightSetting) => this.freightSetting = freightSetting);

    this.locales.forEach((locale) => {
      this.apiService.getCategories(null, locale.id)
          .then((categoriesForLocale) => this.specialCategories[locale.id] = categoriesForLocale);
    });
  }

  async saveFreightSettings() {
    return this.settingService.setFreightSetting(this.freightSetting);
  }
}
