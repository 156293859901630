<div *ngIf="bodyTypes" class="mx-3">
    <ng-container *ngFor="let bodyType of bodyTypes">
        <app-order-layout-body-type (repeatDrawer)="setRepeatDrawer($event)" (repeatFront)="setRepeatFront($event)" [items]="bodyType.items"
                                    [translateItemName]="bodyType.translateItemName" [typeName]="bodyType.typeName"
                                    [viewMode]="viewMode"></app-order-layout-body-type>
    </ng-container>

</div>

<app-repeat-drawer [selectedDrawer]="repeatDrawer"></app-repeat-drawer>
<app-repeat-purewood-front [selectedFront]="repeatFront"></app-repeat-purewood-front>
