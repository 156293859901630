import { Manufacturer, OtherProductShortName } from '../../../../../wdcommon/IProduct';

export const productsUrl = 'products';
export const productTypesUrl = 'product-types';
export const specialsPrefix = 'specials/';
export const productPrefix = 'product/';

export interface Breadcrumb {
  name?: string;
  nameId?: string;
  parentId?: string;
  url?: string;
}
export interface Breadcrumbs {
  [crumb: string]: Breadcrumb;
}

export const defaultBreadcrumbs: Breadcrumbs = {
  [Manufacturer.nothegger]: {
    nameId: 'HOME.NOTHEGGER_PRODUCTS',
    url: '/' + productsUrl + '/' + Manufacturer.nothegger
  },
  notheggerSortissimoOrganiser: {
    nameId: 'OTHER_NAMES.SORTISSIMO',
    parentId: Manufacturer.nothegger,
    url: '/' + productsUrl + '/' + Manufacturer.nothegger + '/sortissimo'
  },
  notheggerProfiles: {
    nameId: 'OTHER_NAMES.PROFILES',
    parentId: Manufacturer.nothegger,
    url: '/' + productsUrl + '/' + Manufacturer.nothegger + '/profiles'
  },
  notheggerSolidOrganiser: {
    nameId: 'OTHER_NAMES.SOLID_ORGANISER',
    parentId: Manufacturer.nothegger,
    url: '/' + productsUrl + '/' + Manufacturer.nothegger + '/' + OtherProductShortName.solidOrganiser
  },
  notheggerTrashcanDrawer: {
    nameId: 'OTHER_NAMES.TRASHCAN_DRAWER',
    parentId: Manufacturer.nothegger,
    url: '/' + productsUrl + '/' + Manufacturer.nothegger + '/' + OtherProductShortName.trashcanDrawer
  },
  notheggerSortissimoFastline: {
    nameId: 'OTHER_NAMES.SORTISSIMO_FASTLINE',
    parentId: Manufacturer.nothegger,
    url: '/' + productsUrl + '/' + Manufacturer.nothegger + '/' + OtherProductShortName.sortissimoFastline
  },
  notheggerSlacksDrawer: {
    nameId: 'OTHER_NAMES.SLACKS_DRAWER',
    parentId: Manufacturer.nothegger,
    url: '/' + productsUrl + '/' + Manufacturer.nothegger + '/' + OtherProductShortName.slacksDrawer
  },
  notheggerCarcass: {
    nameId: 'CABINET_CARCASS.NAME',
    parentId: Manufacturer.nothegger,
    url: '/' + productsUrl + '/' + Manufacturer.nothegger + '/cabinet-carcass'
  },
  notheggerCareSet: {
    nameId: 'OTHER_NAMES.CARE_SET',
    parentId: Manufacturer.nothegger,
    url: '/' + productsUrl + '/' + Manufacturer.nothegger + '/' + OtherProductShortName.careSet
  },
  [Manufacturer.purewood]: {
    nameId: 'HOME.PUREWOOD.PRODUCTS',
    url: '/' + productsUrl + '/' + Manufacturer.purewood,
  },
  purewoodDrawers: {
    nameId: 'HOME.PUREWOOD.DRAWERS',
    parentId: Manufacturer.purewood,
    url: '/' + productsUrl + '/' + Manufacturer.purewood + '/drawers'
  },
  purewoodLinoleumFronts: {
    nameId: 'HOME.PUREWOOD.LINOLEUM_FRONTS',
    parentId: Manufacturer.purewood,
    url: '/' + productsUrl + '/' + Manufacturer.purewood + '/fronts/linoleum'
  },
  purewoodPaintedFronts: {
    nameId: 'HOME.PUREWOOD.PAINTED_FRONTS',
    parentId: Manufacturer.purewood,
    url: '/' + productsUrl + '/' + Manufacturer.purewood + '/fronts/painted'
  },
  purewoodVeneerFronts: {
    nameId: 'HOME.PUREWOOD.VENEER_FRONTS',
    parentId: Manufacturer.purewood,
    url: '/' + productsUrl + '/' + Manufacturer.purewood + '/fronts/veneer'
  },
  purewoodWoodenFronts: {
    nameId: 'HOME.PUREWOOD.WOODEN_FRONTS',
    parentId: Manufacturer.purewood,
    url: '/' + productsUrl + '/' + Manufacturer.purewood + '/fronts/wooden'
  },
  [Manufacturer.ernstMair]: {
    nameId: 'HOME.ERNST-MAIR',
    url: '/' + productsUrl + '/' + Manufacturer.ernstMair
  },
  profile: {
    nameId: 'NAVBAR.PROFILE',
  },
  myProfile: {
    nameId: 'NAVBAR.MY_PROFILE',
    parentId: 'profile',
    url: '/profile/my-profile'
  },
  myOrders: {
    nameId: 'NAVBAR.ORDERS',
    parentId: 'profile',
    url: '/profile/my-orders'
  },
  companyAdmin: {
    nameId: 'CMPADM.Firma Administration',
    parentId: 'profile',
    url: '/profile/company-admin'
  },
  cart: {
    nameId: 'NAVBAR.CART',
    url: '/cart',
  },
  admin: {
    name: 'Admin Dashboard',
    url: '/admin',
  },
  adminOrders: {
    name: 'Ordrer',
    parentId: 'admin',
    url: '/admin/orders',
  },
  adminCompanies: {
    name: 'Firmaer',
    parentId: 'admin',
    url: '/admin/companies',
  },
  adminFreight: {
    name: 'Fragt',
    parentId: 'admin',
    url: '/admin/freight',
  },
  adminDrawers: {
    name: 'Tilgængelighed',
    parentId: 'admin',
    url: '/admin/drawers',
  },
  ['adminDrawers' + Manufacturer.nothegger]: {
    name: 'Nothegger tilgængelighed',
    parentId: 'adminDrawers',
    url: '/admin/drawers/' + Manufacturer.nothegger,
  },
  ['adminDrawers' + Manufacturer.ernstMair]: {
    name: 'Ernst Mair tilgængelighed',
    parentId: 'adminDrawers',
    url: '/admin/drawers/' + Manufacturer.ernstMair,
  },
  adminSpecialProducts: {
    name: 'Specialprodukter',
    parentId: 'admin',
    url: '/admin/products/specials/0',
  },
  adminAllProducts: {
    name: 'Alle produkter',
    parentId: 'adminSpecialProducts',
    url: '/admin/products/specials/all',
  },
  adminGlobalContent: {
    name: 'Globale indholdstekster',
    parentId: 'admin',
    url: '/admin/global-content',
  },
};
