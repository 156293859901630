<div class="container my-5">
    <div class="row mb-3">
        <div class="col-sm">
            <div class="text-center">
                <img alt="Unit-Partner Shop" class="up-logo-img" routerLink="/" src="assets/images/UniPartner_Shop-Logo.png">
            </div>
            <h3 class="text-center" translate="SIGNUP.Opret Bruger"></h3>
        </div>
    </div>

    <div class="row">
        <div class="col-sm">
            <div *blockUI="'blockCVRBtn'" class="card">
                <div class="card-body">
                    <div class="alert alert-info" translate="SIGNUP.StartMedCvr"></div>
                    <!-- <h6 class="card-subtitle mb-2 text-muted">Card subtitle</h6> -->
                    <div class="row">
                        <div class="col-sm-8">
                            <p class="card-text" translate="SIGNUP.IndtastCvrNummer"></p>

                            <div class="input-group mb-3">
                                <input (keydown.enter)="searchCVR()" [(ngModel)]="cvrNumber"
                                       [placeholder]="'SIGNUP.IndtastCvrNummerPlaceholder' | translate" class="form-control" type="text">
                                <div class="input-group-text">
                                    <button (click)="searchCVR()" class="btn btn-outline-secondary" type="button">
                                        <fa-icon [icon]="faSearch"></fa-icon>&nbsp;<span translate="SIGNUP.SøgCvr"></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="companyExists">
                        <div class="row">
                            <div class="col-sm">
                                <hr>
                                <div [innerHTML]="'SIGNUP.CvrFindesAllerede' | translate"
                                     class="alert alert-info"></div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="userExists">
                        <div class="row">
                            <div class="col-sm">
                                <hr>
                                <div [innerHTML]="'SIGNUP.EmailFindesAllerede'| translate"
                                     class="alert alert-info"></div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="unknownError">
                        <div class="row">
                            <div class="col-sm">
                                <hr>
                                <div [innerHTML]="'SIGNUP.UkendtFejl' | translate"
                                     class="alert alert-info"></div>
                            </div>
                        </div>
                    </div>
                    <button (click)=fillformManually()
                            class="btn btn-primary">
                        {{ 'SIGNUP.Manually' | translate }}</button>
                    <div *ngIf="companyInfo">
                        <div class="row">
                            <div class="col-sm">
                                <hr>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm">

                                <form #newUserForm="ngForm" (ngSubmit)="showConfirmUser(newUserForm)" autocomplete="off">
                                    <div class="row mb-3">
                                        <div class="col-md-3">
                                            <label class="form-label" for="userEmail" translate="SIGNUP.Email"></label>
                                            <input #formEmail="ngModel" [(ngModel)]="formData.email" [placeholder]="'SIGNUP.Email' | translate"
                                                   aria-describedby="validationServerUsernameFeedback" class="form-control" email
                                                   id="userEmail" name="email" required type="email">
                                            <div *ngIf="formEmail.invalid && (formEmail.dirty || formEmail.touched)" class="text-danger form-text">
                                                {{ 'SIGNUP.Email_validation' | translate }}
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <label class="form-label" for="userName" translate="SIGNUP.Navn"></label>
                                            <input [(ngModel)]="formData.name" [placeholder]="'SIGNUP.Navn' | translate" class="form-control"
                                                   id="userName" name="name" required type="text">
                                        </div>
                                        <div class="col-md-3">
                                            <label class="form-label" for="pw" translate="SIGNUP.Password"></label>
                                            <input [(ngModel)]="formData.password" [placeholder]="'SIGNUP.Password' | translate" class="form-control"
                                                   id="pw" name="pw" required type="password">
                                        </div>
                                        <div class="col-md-3">
                                            <label class="form-label" for="pw2" translate="SIGNUP.GentagPassword"></label>
                                            <input [placeholder]="'SIGNUP.GentagPassword' | translate" class="form-control" id="pw2" required
                                                   type="password">
                                        </div>
                                    </div>
                                    <div class="mb-3 row">
                                        <div class="col-md-3">
                                            <label class="form-label" for="cvrno" translate="SIGNUP.CvrNummer"></label>
                                            <input [(ngModel)]="formData.cvr" [placeholder]="'SIGNUP.CvrNummer' | translate" class="form-control"
                                                   id="cvrno" name="cvrno" onkeydown="return event.keyCode !== 69" required type="number">
                                        </div>
                                        <div class="col-md-3">
                                            <label class="form-label" for="firmanavn" translate="SIGNUP.Firmanavn"></label>
                                            <input [(ngModel)]="formData.companyName" [placeholder]="'SIGNUP.Firmanavn' | translate"
                                                   class="form-control" id="firmanavn" name="firmanavn" required type="text">
                                        </div>
                                        <div class="col-md-3">
                                            <label class="form-label" for="phone" translate="SIGNUP.Telefon"></label>
                                            <input [(ngModel)]="formData.phone" [placeholder]="'SIGNUP.Telefon' | translate" class="form-control"
                                                   id="phone" name="phone" required type="text" value="{{ companyInfo.phone }}">
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-6">
                                            <label class="form-label" for="adresse" translate="SIGNUP.Adresse"></label>
                                            <input [(ngModel)]="formData.address" [placeholder]="'SIGNUP.Adresse' | translate" class="form-control"
                                                   id="adresse" name="address" required type="text" value="{{ companyInfo.address }}">
                                        </div>
                                        <div class="col-md-6">
                                            <label class="form-label">&nbsp;</label>
                                            <input [(ngModel)]="formData.address2" [placeholder]="'SIGNUP.Adresse2' | translate" class="form-control"
                                                   id="adresse2" name="address2" type="text">
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-6">
                                            <label class="form-label" for="postnr" translate="SIGNUP.Postnr"></label>
                                            <input [(ngModel)]="formData.postcode" [placeholder]="'SIGNUP.Postnr' | translate" appPostalCode
                                                   class="form-control" id="postnr" name="postnr" required>
                                        </div>
                                        <div class="col-md-6">
                                            <label class="form-label" for="by" translate="SIGNUP.By"></label>
                                            <input [(ngModel)]="formData.city" [placeholder]="'SIGNUP.By' | translate" class="form-control" id="by"
                                                   name="city" required type="text">
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-6">
                                            <label class="form-label" for="country" translate="SIGNUP.Country"></label>
                                            <select [(ngModel)]='formData.country' class="form-control" id="country" name="country" required>
                                                <option *ngFor="let c of countries" [ngValue]="c">{{ c }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-12">
                                            <div class="form-check">
                                                <input [(ngModel)]="infoCorrect" class="form-check-input" id="infoCorrect" name="infoCorrect"
                                                       type="checkbox" value="">
                                                <label class="form-check-label" for="infoCorrect" translate="SIGNUP.InfoKorrekt"></label>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-check">
                                                <input [(ngModel)]="termsAccepted" class="form-check-input" id="acceptTerms" name="termsAccepted"
                                                       type="checkbox" value="">
                                                <label class="form-check-label" for="acceptTerms">
                                                    <a (click)="showTerms()" href="javascript:void(0)"
                                                       translate="SIGNUP.AccepterHandelsbetingelserne"></a>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-check">
                                                <input [(ngModel)]="formData.samples" class="form-check-input" id="samples" name="samples"
                                                       type="checkbox" value="false">
                                                <label class="form-check-label" for="samples" translate="SIGNUP.Samples"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <button class="btn btn-success float-end" type="submit">
                                            <span translate="SIGNUP.Færdiggør"></span>&nbsp;
                                            <fa-icon [icon]="faCheck"></fa-icon>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #confirmModal>
    <div class="modal-header">
        <h4 class="modal-title" translate="SIGNUP.OpretBrugerModal.title"></h4>
        <button (click)="modalRef.hide()" aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
    </div>
    <div [innerHTML]="'SIGNUP.OpretBrugerModal.text' | translate"
         class="modal-body"></div>
    <div class="modal-footer">
        <div class="modal-footer">
            <button (click)="createUser();"
                    class="btn btn-success"
                    translate="SIGNUP.OpretBrugerModal.Opret"
                    type="button"></button>
            <button (click)="modalRef.hide()"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                    translate="UTILITY.CANCEL"
                    type="button"></button>
        </div>
    </div>
</ng-template>

<ng-template #termsModal>
    <div class="modal-header">
        <h4 class="modal-title" translate="SIGNUP.Handelsbetingelserne.title"></h4>
        <button (click)="modalRef.hide()" aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
    </div>
    <div [innerHTML]="'SIGNUP.Handelsbetingelserne.text' | translate"
         class="modal-body"></div>
</ng-template>
