<div class="container">
    <div class="row mb-3">
        <div class="col-sm-3">
            <h3>Brugere</h3>
        </div>
        <div class="col-sm-6 text-end">
            <button (click)="filter('none')" class="btn btn-primary">Alle</button>&nbsp;
            <!-- <button class="btn btn-primary" (click)="filter('new')">Nye &nbsp;&nbsp;<fa-icon [icon]="faStar"></fa-icon></button>&nbsp; -->
            <button (click)="filter('notactive')" class="btn btn-primary">Venter godkendelse &nbsp;&nbsp;<fa-icon [icon]="faCheck"></fa-icon>
            </button>&nbsp;
            <button (click)="exportToExcel()" class="btn btn-primary">Exportér &nbsp;&nbsp;<fa-icon [icon]="faDownload"></fa-icon>
            </button>&nbsp;
        </div>
        <div class="col-sm-3">
            <input (keyup)="searchFilter()" [(ngModel)]="searchNeedle" aria-label="Search" class="form-control" placeholder="Søg" type="search">
        </div>
    </div>

    <div class="row">
        <div class="col-sm">
            <table class="table table-sm">
                <thead>
                <tr>
                    <th (click)="sortBy('email')" class="sortable">
                        Email <i class="fa fa-fw fa-sort-{{ sortOrder }}{{ sortProperty == 'email' ? '' : '-' }}"></i>
                    </th>
                    <th (click)="sortBy('name')" class="sortable">
                        Navn <i class="fa fa-fw fa-sort-{{ sortOrder }}{{ sortProperty == 'name' ? '' : '-' }}"></i>
                    </th>
                    <th (click)="sortBy('companyName')" class="sortable">
                        Firma <i class="fa fa-fw fa-sort-{{ sortOrder }}{{ sortProperty == 'companyName' ? '' : '-' }}"></i>
                    </th>
                    <th (click)="sortBy('lastLogin')" class="sortable">
                        Seneste Login <i class="fa fa-fw fa-sort-{{ sortOrder }}{{ sortProperty == 'lastLogin' ? '' : '-' }}"></i>
                    </th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let user of filteredUsers">
                    <td class="align-middle">
                        {{ user.email }}
                        <span *ngIf="user.isAdmin === true" class="text-info" tooltip="Unit-Partner Admin">⬢</span>
                        <span *ngIf="user.activated === false" class="text-warning" tooltip="Brugeren er ikke aktiveret">
                            <fa-icon [icon]="faExclamationTriangle"></fa-icon>
                        </span>
                    </td>
                    <td class="align-middle">{{ user.name }}</td>
                    <td class="align-middle"><a [routerLink]="['/admin/companies/'+user.company.id]">{{user.company.companyName }}</a></td>
                    <td class="align-middle">{{ user.lastLogin | date:'hh:mm:ss - dd. MMM yyyy' }}</td>
                    <td class="align-middle text-end text-nowrap">
                        <app-admin-approve-user (activated)="userActivated(user)" *ngIf="user.activated === false"
                                                [user]="user"></app-admin-approve-user>
                        <ng-container *ngIf="user.activated !== false">
                            <button (click)="setAccessToProducer(producers.ernstMair, false, user.id)" *ngIf="user.allowErnstMair === true"
                                    class="btn btn-secondary btn-sm" tooltip="Fjern adgang til Ernst Mair produkter">
                                <fa-icon [icon]="faStoreAltSlash"></fa-icon>
                            </button>&nbsp;
                            <button (click)="setAccessToProducer(producers.ernstMair, true, user.id)" *ngIf="user.allowErnstMair === false"
                                    class="btn btn-primary btn-sm" tooltip="Tillad adgang til Ernst Mair produkter">
                                <fa-icon [icon]="faStoreAlt"></fa-icon>
                            </button>&nbsp;
                            <button (click)="setAccessToProducer(producers.purewood, false, user.id)" *ngIf="user.allowPurewood === true"
                                    class="btn btn-secondary btn-sm" tooltip="Fjern adgang til visse Purewood produkter">
                                <fa-icon [icon]="faStoreSlash"></fa-icon>
                            </button>&nbsp;
                            <button (click)="setAccessToProducer(producers.purewood, true, user.id)" *ngIf="user.allowPurewood === false"
                                    class="btn btn-primary btn-sm" tooltip="Tillad adgang til alle Purewood produkter">
                                <fa-icon [icon]="faStore"></fa-icon>
                            </button>&nbsp;
                            <!-- <button tooltip="Redigér bruger" [routerLink]="['/admin/users/' + user.id]" class="btn btn-sm"><fa-icon [icon]="faEdit"></fa-icon></button>&nbsp;&nbsp; -->
                        </ng-container>

                        <button #pop="bs-popover" (onHidden)="deleteID = null;" (onShown)="deleteID = user.id;" [outsideClick]="true"
                                [popoverTitle]="" [popover]="delPopUser" class="btn btn-sm btn-danger" container="body" type="button">
                            <fa-icon [icon]="faTrash"></fa-icon>
                        </button>

                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>

</div>

<ng-template #delPopUser>
    <div *blockUI='delUserBlock' class="text-center">
        <div class="btn-group" role="group">
            <button (click)="deleteUser();" class="btn btn-sm btn-danger">Slet</button>
            <button (click)="hidePop();" class="btn btn-sm btn-secondary">Nej</button>
        </div>
    </div>
</ng-template>
