<div class="container">
    <div class="text-center my-4">
        <img alt="Unit-Partner Shop" class="up-logo-img" routerLink="/" src="assets/images/UniPartner_Shop-Logo.png">
    </div>

    <div class="row">
        <div class="col-sm-4 offset-sm-4">
            <h3 class="text-center" translate="resetPassword.title"></h3>
            <form (ngSubmit)="resetPassword()" [formGroup]="resetPasswordForm" class="form-signin">
                <label class="form-label" for="password" translate="resetPassword.newPassword.label"></label>
                <input class="form-control mb-3" formControlName="password" id="password"
                       [placeholder]="'resetPassword.newPassword.placeholder' | translate" required type="password">
                <label class="form-label" for="password2" translate="resetPassword.repeatPassword.label"></label>
                <input class="form-control mb-3" formControlName="password2" id="password2"
                       [placeholder]="'resetPassword.repeatPassword.placeholder' | translate" required type="password">
                <div class="d-grid">
                    <button *blockUI='blockLoginBtn' class="btn btn-lg btn-primary" type="submit" translate="resetPassword.submit"></button>
                </div>
            </form>
        </div>
    </div>
</div>
