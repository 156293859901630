import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';

import { CartItemType, CartSubType, ITypedAddCartItem } from '../../../../../wdcommon/ICartItem';
import { ExternalShopName } from '../../../../../wdcommon/IExternalShop';
import { OptionProperty, Manufacturer } from '../../../../../wdcommon/IProduct';
import { APIService, CartService } from '../../services';
import { SortissimoComponent } from '../sortissimo/sortissimo.component';
import { TranslatingBase } from '../../base-component/ComponentBase';
import { AddToCartWithAmountModalComponent } from '../../modals/add-to-cart-with-amount-modal/add-to-cart-with-amount-modal.component';


@Component({
  selector: 'app-bestikindsats',
  templateUrl: './bestikindsats.component.html',
  styleUrls: ['./bestikindsats.component.css']
})
export class BestikindsatsComponent extends TranslatingBase implements OnInit {
  @ViewChild(SortissimoComponent) bscomp: SortissimoComponent;

  faArrowRight = faArrowRight;
  faCheck = faCheck;

  modalRef: BsModalRef<AddToCartWithAmountModalComponent>;
  antal: number = 1;

  constructor(
      public modalService: BsModalService,
      private api: APIService,
      private cartService: CartService,
      private toastrService: ToastrService,
      private router: Router,
      private translateService: TranslateService,
  ) {
    super(translateService);
  }

  ngOnInit() {
  }

  approveBestikindsats() {
    Object.keys(this.bscomp.form.getAllControls()).forEach(key => {
      this.bscomp.form.getControl(key).markAsTouched();
    });
    return this.bscomp.form.valid;
  }

  displayAddToCartModal() {
    this.modalRef = this.modalService.show(AddToCartWithAmountModalComponent, {
      initialState: {
        amount: this.antal
      }
    });

    this.modalRef.content.amountChange.subscribe((amount: number) => {
      this.antal = amount;
    });

    this.modalRef.content.approved.subscribe((wasApproved) => {
      if (wasApproved)
        this.finalizeOrder();
    });
  }

  async finalizeOrder() {
    const bestikindsats = this.bscomp.getCutleryTrayOptions();
    if (!bestikindsats)
      return;

    const shortType = 'WD2BS-' + bestikindsats.type
        + bestikindsats[OptionProperty.height]
        + bestikindsats[OptionProperty.typeOfWood].substring(0, 2).toUpperCase()
        + bestikindsats[OptionProperty.surfaceTreatment].substring(0, 2).toUpperCase()
        + (bestikindsats.bundLeveret === true ? 'B' : '')
        + bestikindsats[OptionProperty.width] + 'x'
        + bestikindsats[OptionProperty.depth] + 'x'
        + bestikindsats[OptionProperty.height];

    const shortTypeEncoded = btoa(shortType);
    const vareNr: any = await this.api.getVarenr(shortTypeEncoded);

    const formattedType = this.translate('CART.BestikindsatsDescription',
        {
          type: bestikindsats.type,
          width: bestikindsats[OptionProperty.width],
          depth: bestikindsats[OptionProperty.depth],
          height: bestikindsats[OptionProperty.height],
          treesort: this.translate('TREESORTS.' + bestikindsats[OptionProperty.typeOfWood]),
          surface: this.translate('SURFACES.' + bestikindsats[OptionProperty.surfaceTreatment]),
          bottom: (bestikindsats.bundLeveret === true ? this.translate('CART.BestikindsatsDescriptionBottom') : '')
        });

    // DONE: Bind navngivning og formattering på på name service!
    const orderObj: ITypedAddCartItem = {
      brandId: Manufacturer.nothegger,
      type: CartItemType.extra,
      subType: CartSubType.bestikskuffe,
      name: 'Sortissimo',
      itemno: vareNr.varenr,
      description: formattedType,
      amount: this.antal,
      options: bestikindsats,
      externalShopName: ExternalShopName.nothegger
    };

    await this.cartService.addExtra(orderObj);
    this.modalRef.hide();
    this.toastrService.success('Varen er tilføjet til kurven');
    await this.router.navigateByUrl('/');
  }
}
