<div *blockUI="'blockModal'" class="modal-header">
    <h4 class="modal-title">Tilføj Logo</h4>
    <button (click)="modalRef.hide()" aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-sm">
            <h5>{{ 'Vælg logo placering på skuffen' | translate }}</h5>
            <small>{{ 'Logoet placeres på siden af skuffen hen mod fronten' | translate }}</small>
            <div class="input-group">
                <!-- BACKLOG Lav billeder af logo placeringen -->
                <select [(ngModel)]="placement" class="form-control">
                    <option value="left">{{ 'Venste side set fra front' | translate }}</option>
                    <option value="right">{{ 'Højre side set fra front' | translate }}</option>
                    <option value="both">{{ 'Begge sider' | translate }}</option>
                </select>
            </div>
        </div>
    </div>

    <div class="row" style="max-height: 50vh; overflow-x: hidden; overflow-y: auto;">
        <div class="col-sm">
            <div class="list-group">
                <button (click)="selectLogo(l.id, l.origFilename)" *ngFor="let l of logos" class="list-group-item list-group-item-action mx-auto"
                        type="button">
                    <img [alt]="l.origFilename" src="{{ l.imgBase64 }}" style="width: 80%"/>
                    {{ l.origFilename }}
                </button>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button (click)="closeModal()" class="btn btn-default" translate="UTILITY.CANCEL" type="button"></button>
</div>
