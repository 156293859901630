import { TranslateService } from '@ngx-translate/core';
import { FieldConfig, TranslatePropertyObject } from '../dynaform.interfaces';
import { UntypedFormGroup } from '@angular/forms';


export class FieldComponentBase {

  field: FieldConfig;
  group: UntypedFormGroup;

  constructor(private _translateService: TranslateService) { }

  public translate(source: keyof TranslatePropertyObject, key: string | any, interpolateParams?: Object): string | any {

    if (typeof (key) !== 'string')
      return key || '';

    let doTranslate = true;

    let translateProp = this.field ? this.field.translate : undefined;
    if (translateProp === undefined)
      translateProp = true;

    if (translateProp !== true) {
      if (translateProp === false)
        doTranslate = false;
      else if (typeof (translateProp) === 'function')
        doTranslate = translateProp(source, key);
      else if (typeof (translateProp) === 'object') {
        const sourceTranslateProp = translateProp[source];

        if (sourceTranslateProp === false)
          doTranslate = false;
        else if (typeof (sourceTranslateProp) === 'function')
          doTranslate = sourceTranslateProp(key);
      }
    }

    if (!doTranslate)
      return key || '';

    return this._translateService.instant(key, interpolateParams) || '';

  }
}
