import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { Locale, LocaleService } from '../../services';


@Injectable({
  providedIn: 'root'
})
export class AdminProductsLanguageService {

  private _currentLocale: BehaviorSubject<Locale> = new BehaviorSubject<Locale>(null);

  constructor(private localeService: LocaleService) {
    const languages = this.localeService.getSupportedLanguages();
    const currentLanguage = this.localeService.getLanguage();

    this.currentLocale = languages.find(locale => locale.id === currentLanguage);
  }

  get currentLocale() {
    return this._currentLocale.value;
  }

  set currentLocale(locale: Locale) {
    this._currentLocale.next(locale);
  }

  public connectCurrentLocale(): Observable<Locale> {
    return this._currentLocale.pipe();
  }

}
