import { Component, HostBinding, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FieldComponentBase } from '../FieldComponentBase';

@Component({
  selector: 'app-button',
  template: `
      <div [className]="field.containerClass + ' pt-3 d-grid'" [formGroup]="group">
          <button [ngClass]="field.classes" [type]="field.buttonType || button" class="btn">
              <!-- TODO-NOTICE: field.buttonIcon may not be usable in this way... -->
              <fa-icon *ngIf="field.buttonIcon" [icon]="field.buttonIcon"></fa-icon>
              <span *ngIf="field.buttonIcon">&nbsp;&nbsp;</span>
              {{ translate('fieldLabel', field.label) }}</button>
      </div>
  `,
  styles: []
})
export class ButtonComponent extends FieldComponentBase implements OnInit {

  @HostBinding('class') class;

  button: any;

  constructor(translateService: TranslateService) {
    super(translateService);
  }

  ngOnInit() {
    this.class = this.field.containerClass || 'col-sm';
  }

}
