import { Component, OnInit } from '@angular/core';
import { CartService } from '../../services/cart.service';

@Component({
  selector: 'app-can-purchase-view',
  template: `
    <ng-container *ngIf="isAuthorized; else notAuthorized">
      <ng-content select=".authorized"></ng-content>
    </ng-container>

    <ng-template #notAuthorized>
      <ng-content select=".not-authorized"></ng-content>
    </ng-template>
  `
})
export class CanPurchaseViewComponent implements OnInit {
  public isAuthorized = false;

  constructor(
    protected cartService: CartService,
  ) { }

  ngOnInit() {
    this.isAuthorized = this.cartService.checkCanAddToCart();
  }
}
