import { Component, Input, OnChanges, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { RepeatCartItem } from '../../../../wdcommon/ICartItem';
import { IDrawerHistory } from '../../../../wdcommon/IDrawer';
import { APIService, SessionService, UserService } from '../services';


@Component({
  selector: 'app-repeat-drawer',
  templateUrl: './repeat-drawer.component.html',
  styleUrls: ['./repeat-drawer.component.css']
})
export class RepeatDrawerComponent implements OnChanges {
  @Input() selectedDrawer: RepeatCartItem;
  @ViewChild('selectRepeatType') repeatModal: TemplateRef<any>;

  onlySelfRepeating = false;
  modalRef: BsModalRef;

  constructor(
      public userService: UserService,
      private api: APIService,
      private modalService: BsModalService,
      private router: Router,
      private session: SessionService,
  ) {
  }

  ngOnChanges(_: SimpleChanges) {
    this.onlySelfRepeating = (this.selectedDrawer && this.selectedDrawer.contents && ['innenlade_designcurve', 'ils', 'ernst-mair-ils', 'purewood-ils'].includes(this.selectedDrawer.contents.type));
  }

  async repeatDrawer(type: string) {
    const drawerHistory: IDrawerHistory = this.selectedDrawer;
    this.session.setValue('repeatDrawer', JSON.stringify(drawerHistory));
    this.modalRef.hide();
    await this.router.navigateByUrl('/order/repeat/' + type);
  }

  show() {
    this.modalRef = this.modalService.show(this.repeatModal);
  }
}
