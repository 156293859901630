export enum GlobalContentDesign {
  default = 'default',
  fullImage = 'fullImage',
}

export enum GlobalContentType {
  brandBullets = 'brandBullets',
  deliveryInfo = 'deliveryInfo',
  productType = 'productType',
  // faq = 'faq',
  news = 'news',
}

export interface IGlobalContentTranslations {
  [languageCode: string]: string | {
    [key: string]: string;
  };
}

export interface IGlobalContent {
  id: number;
  key: string;
  type: GlobalContentType;
  sortOrder: number;
  availableFrom?: string;
  availableTo?: string;
  design: GlobalContentDesign;
  draft: IGlobalContentTranslations;
  public: IGlobalContentTranslations;
  routerLink?: string;
  tags: string;
  lastPublished?: string;
}

export const getDatetimeString = (date?: Date) => {
  if (!date) {
    return null;
  }
  const newDate = new Date(date);
  return new Date(newDate.getTime() - newDate.getTimezoneOffset() * 60000).toISOString().slice(0, -1);
};
