import { Component, OnInit } from '@angular/core';
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons/faEnvelopeOpenText';

import { APIService, KlaviyoService, LocaleService, UserService } from '../../services';
import { SubscribedState } from '../../../../../wdcommon/KlaviyoNewsletter';
import { GlobalContentDesign, GlobalContentType } from '../../../../../wdcommon/IGlobalContent';


interface NewsContent {
  design: GlobalContentDesign;
  imgUrl?: string;
  routerLink?: string;
  sortOrder: number;
  header?: string;
  text?: string;
}


@Component({
  selector: 'app-home-carousel',
  templateUrl: './home-carousel.component.html',
  styleUrls: ['./home-carousel.component.css']
})
export class HomeCarouselComponent implements OnInit {
  faEnvelopeOpenText = faEnvelopeOpenText;
  globalContentDesign = GlobalContentDesign;

  unknownAtKlaviyo = false;
  newsletterSubscribing = false;
  newsArray: NewsContent[] = [];

  constructor(
      private apiService: APIService,
      private klaviyo: KlaviyoService,
      private localeService: LocaleService,
      public userService: UserService,
  ) {
  }

  ngOnInit() {
    if (this.userService.isLoggedIn) {
      this.klaviyo.getSubscribedState()
          .then((status) => {
            if (status.subscribed === SubscribedState.new) {
              this.unknownAtKlaviyo = true;
            }
          });
    }

    this.apiService.getGlobalContentOfType(GlobalContentType.news)
        .then((contents) => {
          const newsArray: NewsContent[] = [];
          const contentPromises: Promise<void>[] = [];
          contents.forEach((content) => {
            if (content.lastPublished &&
                (!content.availableFrom || new Date(content.availableFrom).valueOf() < Date.now()) &&
                (!content.availableTo || new Date(content.availableTo).valueOf() > Date.now())) {
              if (content.design === GlobalContentDesign.default && content.public[this.localeService.getLanguage()]) {
                newsArray.push({
                  design: content.design,
                  routerLink: content.routerLink,
                  sortOrder: content.sortOrder,
                  header: (content.public[this.localeService.getLanguage()] as {[key: string]: string }).header,
                  text: '<div>' + (content.public[this.localeService.getLanguage()] as {[key: string]: string }).text.split(/\n/).join('</div><div>') + '</div>',
                });
              } else if (content.design === GlobalContentDesign.fullImage) {
                contentPromises.push(
                    this.apiService.getGlobalContentImage(content.type, content.key)
                        .then((image) => {
                          if (image === null) {
                            return;
                          }
                          newsArray.push({
                            design: content.design,
                            imgUrl: image.encImg,
                            routerLink: content.routerLink,
                            sortOrder: content.sortOrder,
                          });
                          return;
                        })
                );
              }
            }
          });
          Promise.all(contentPromises)
              .then(() => this.newsArray = newsArray.sort((a, b) => a.sortOrder - b.sortOrder));
        });
  }

  async subscribeToNewsletter() {
    this.newsletterSubscribing = true;
    await this.klaviyo.subscribe();
  }

  async sayNoToNewsletter() {
    this.unknownAtKlaviyo = false;
    await this.klaviyo.unsubscribe();
  }

}
