<div class="container main-container">
    <div class="row">

        <div class="col-12 mb-5">
            <app-home-carousel></app-home-carousel>
        </div>

        <div *ngFor="let category of categories" [routerLink]="category.url" class="col-12 col-lg-6 col-xxl-4 mb-4">
            <div class="card up-card up-home-card h-100 clickable">
                <div class="row h-100">
                    <div [ngClass]="category.bulletPoints ? 'col-6' : 'col-12'">
                        <img *ngIf="category.imageUrl" [alt]="category.name" [src]="category.imageUrl" class="card-img rounded-0 px-4 pt-4">
                        <div class="card-body d-flex align-items-end justify-content-around">
                            <div>
                                <div [innerText]="category.name" class="card-title"></div>
                                <!--                        <div *ngIf="category.description" [innerHTML]="category.description" class="card-text"></div>-->
                            </div>
                        </div>
                    </div>
                    <div *ngIf="category.bulletPoints" class="col-6">
                        <div class="up-bullet-points h-100 d-flex">
                            <ul class="text-white mb-0 pt-2">
                                <li *ngFor="let bp of category.bulletPoints">{{bp}}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
