import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { Constants } from '../../../../../../../wdcommon/Constants';
import { FrontTypeOptions } from "../../../../../../../wdcommon/IFront";
import { IOption, OptionProperty } from "../../../../../../../wdcommon/IProduct";
import { APIService } from "../../../../services";

type Position = "left" | "right" | "other";
const FrontDrillTypeLeftPositionKey = 'Left';
const FrontDrillTypeRightPositionKey = 'Right';
const FrontGripLeftPositionKey = 'L';
const FrontGripRightPositionKey = 'R';
@Component({
  selector: 'app-front-type-options',
  templateUrl: './purewood-front-options.component.html',
  styleUrls: ['./purewood-front-options.component.css']
})
export class PurewoodFrontOptionsComponent implements OnInit {
  @Input() index: number;
  _gripEnabled: boolean;

  @Input() set gripEnabled(enabled: boolean) {
    this._gripEnabled = enabled;
    if (this.optionsForm) {
      this.toggleGrip();
    }
  };

  @Input() customGripEnabled: boolean;
  @Input() minimumSize: number;
  @Input() option: FrontTypeOptions;
  @Output() delete = new EventEmitter<number>();
  @Output() dimensionsValid = new EventEmitter<boolean>();
  @Output() triggerAddRow = new EventEmitter<number>();

  optionsForm: UntypedFormGroup;
  frontDrillTypes: IOption[];
  allFrontDrillTypes: IOption[];
  frontGrips: IOption[];
  allFrontGrips: IOption[];

  protected readonly Constants = Constants;
  protected readonly OptionProperty = OptionProperty;

  constructor(
      private apiService: APIService,
      private formBuilder: UntypedFormBuilder,
  ) {
  }

  get width() {
    return this.optionsForm.get(OptionProperty.width);
  }

  get height() {
    return this.optionsForm.get(OptionProperty.height);
  }

  get amount() {
    return this.optionsForm.get('amount');
  }

  get frontXGrip() {
    return this.optionsForm.get(OptionProperty.frontXGrip);
  }

  get showX() {
    return this.optionsForm && this.optionsForm.get(OptionProperty.frontGrip) && (this.optionsForm.get(OptionProperty.frontGrip).value.toString().indexOf('X') > -1);
  }

  async ngOnInit() {
    this.initializeForm();

    const productOptions = await this.apiService.getProductOptions();
    this.frontDrillTypes = this.allFrontDrillTypes = productOptions.filter((o) => (o.property === OptionProperty.frontDrillType));
    this.frontGrips = this.allFrontGrips =  productOptions.filter((o) => (o.property === OptionProperty.frontGrip));

    this.subscribeToValueChanges();

    this.toggleGrip();
    this.toggleX(this.option[OptionProperty.frontGrip]);

    if (typeof this.option[OptionProperty.width] === 'number') {
      await this.validateAllFormFields();
    }
  }

  private subscribeToValueChanges() {
    this.subscribeToGripChanges();
    this.subscribeToDrillingTypeChanges();
    this.subscribeToHeightChanges();
    this.subscribeToFormChanges();
  }

  private filterSides(targetPosition: Position, optionPositionResolver: (value: string) => Position) {
    return (option: IOption) => {
      const optionPosition = optionPositionResolver(option.value);
      if (optionPosition === "other")
        return true;

      if (targetPosition === "right")
        return optionPosition === "left";

      if (targetPosition === "left")
        return optionPosition === "right";

      return true
    }
  }

  private subscribeToDrillingTypeChanges() {
    const frontDrillingTypeControl = this.optionsForm.get(OptionProperty.frontDrillType);
    frontDrillingTypeControl.valueChanges.subscribe((value: string) => {
      const drillingTypePosition = this.getDrillingTypePosition(value);

      this.frontGrips = this.allFrontGrips.filter(this.filterSides(drillingTypePosition, this.getGripPosition));
      this.ensureValidValue(OptionProperty.frontGrip, this.frontGrips.map((grip) => grip.value), "none");
    });
  }

  private subscribeToGripChanges() {
    const frontGripControl = this.optionsForm.get(OptionProperty.frontGrip);
    frontGripControl.valueChanges.subscribe((value) => this.toggleX(value));

    frontGripControl.valueChanges.subscribe((value) => {
      const gripPosition = this.getGripPosition(value);

      this.frontDrillTypes = this.allFrontDrillTypes.filter(this.filterSides(gripPosition, this.getDrillingTypePosition));
      this.ensureValidValue(OptionProperty.frontDrillType, this.frontDrillTypes.map((drillType) => drillType.value), "none");
    });
  }

  private subscribeToFormChanges() {
    this.optionsForm.valueChanges.subscribe(async () => {
      Object.assign(this.option, this.optionsForm.value);
      await this.validateAllFormFields();
    });
  }

  private subscribeToHeightChanges() {
    const heightControl = this.optionsForm.get(OptionProperty.height);
    heightControl.valueChanges.subscribe((_) => {
      this.optionsForm.get(OptionProperty.frontXGrip).clearValidators();
      this.toggleX(this.optionsForm.get(OptionProperty.frontGrip).value);
    });
  }

  private getGripPosition(gripValue: string): Position {
    const positionKey = gripValue.charAt(1);

    if (positionKey === FrontGripRightPositionKey)
      return "right"

    if (positionKey === FrontGripLeftPositionKey)
      return "left"

    return "other"
  }

  private getDrillingTypePosition(drillingTypeValue: string): Position {
    if (drillingTypeValue.endsWith(FrontDrillTypeLeftPositionKey))
      return "left"

    if (drillingTypeValue.endsWith(FrontDrillTypeRightPositionKey))
      return "right"

    return "other"
  }

  private ensureValidValue(controlName: string, validValues: string[], fallbackValue?: string) {
    const control = this.optionsForm.get(controlName);

    const currentValue = control.value;
    const defaultValue = fallbackValue ?? validValues[0];

    const isCurrentlyDefaultValue = currentValue === defaultValue;
    const validValue = validValues.find((value) => value === currentValue);
    if (validValue || isCurrentlyDefaultValue)
      return;

    control.setValue(defaultValue);
  }

  private initializeForm() {
    this.optionsForm = this.formBuilder.group({
      [OptionProperty.width]: [this.option[OptionProperty.width], [Validators.required, Validators.pattern(/^\d+$/), Validators.min(this.minimumSize), Validators.max(Constants.purewoodFrontMaxSize)]],
      [OptionProperty.height]: [this.option[OptionProperty.height], [Validators.required, Validators.pattern(/^\d+$/), Validators.min(this.minimumSize), Validators.max(Constants.purewoodFrontMaxSize)]],
      [OptionProperty.frontDrillType]: [this.option[OptionProperty.frontDrillType], [Validators.required]],
      [OptionProperty.frontGrip]: [this.option[OptionProperty.frontGrip], []],
      [OptionProperty.frontXGrip]: [this.option[OptionProperty.frontXGrip], []],
      amount: [this.option.amount, [Validators.required, Validators.pattern(/^\d+$/), Validators.min(1)]],
    });
  }

  async validateAllFormFields(formGroup: UntypedFormGroup = this.optionsForm) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      control.markAsTouched({ onlySelf: true });
    });
    this.option.valid = this.optionsForm.valid;
    if (this.option.valid) {
      this.dimensionsValid.emit(this.option.valid);
    }
  }

  deleteOption() {
    this.delete.emit(this.index);
  }

  addRowOnTab(event: KeyboardEvent) {
    if (event.key === 'Tab' && !event.shiftKey) {
      this.triggerAddRow.emit(this.index);
    }
  }

  private toggleGrip() {
    const frontGripControl = this.optionsForm.get(OptionProperty.frontGrip);

    if (this._gripEnabled) {
      frontGripControl.addValidators([Validators.required]);
    } else {
      frontGripControl.clearValidators();
    }
    frontGripControl.updateValueAndValidity();
  }

  private toggleX(value: any) {
    const frontXGripControl = this.optionsForm.get(OptionProperty.frontXGrip);

    if (value.toString().indexOf('X') > -1) {
      frontXGripControl.addValidators([Validators.required, Validators.min(this.getMinimum()), Validators.max(this.getMaximum())]);
    } else {
      frontXGripControl.clearValidators();
    }
    frontXGripControl.updateValueAndValidity();
  }

  protected getMaximum() {
    return this.optionsForm.get(OptionProperty.height).value as number - 45;
  }

  protected getMinimum() {
    const frontGripSelection = this.optionsForm.get(OptionProperty.frontGrip).value.toString();
    let min = 45;
    if (frontGripSelection[0] === 'R') {
      min += 60;
    } else if (frontGripSelection[0] === 'S' && frontGripSelection[2] === 'V') {
      min += 100;
    } else if (frontGripSelection[0] === 'S' && frontGripSelection[2] !== 'V') {
      min += 30;
    }
    return min;
  }
}
