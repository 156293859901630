import { Validators } from '@angular/forms';

import { OptionProperty } from '../../../../../wdcommon/IProduct';
import { FormRow } from '../../dynamicForm/dynaform.interfaces';
import { createHrRow, createTypeOfWoodSurfaceTreatmentSelectionRow } from '../../dynamicForm/standard-rows';

const woodTypeAndSurfaceRow = createTypeOfWoodSurfaceTreatmentSelectionRow();
woodTypeAndSurfaceRow.fields[1].disabled = false;

export const MODEL: FormRow[] = [
  {
    id: 'type-row',
    show: true,
    fields: [
      {
        type: 'select',
        name: 'productType',
        label: 'Type',
        classes: 'col-sm-6',
        options: [],
        translate: {
          itemLabel: false
        },
        validations: [{
          name: 'required',
          validator: Validators.required,
          message: 'Organiser.required.Type'
        }]
      },
      {
        type: 'input',
        inputType: 'number',
        name: OptionProperty.thickness,
        label: 'Tykkelse',
        suffix: 'mm',
        disabled: true,
        show: true
      }
    ]
  },
  {
    id: 'dimensions-row',
    show: true,
    fields: [
      {
        type: 'input',
        inputType: 'number',
        name: OptionProperty.width,
        min: 220,
        max: 650,
        suffix: 'mm',
        label: 'Bredde',
        placeholder: 'Organiser.placeholder.Width',
        disabled: false,
        translate: { fieldLabel: true, placeholder: true },
        show: true,
        validations: [{
          name: 'required',
          validator: Validators.required,
          message: 'Organiser.required.Width'
        }, {
          name: 'pattern',
          validator: Validators.pattern('\\d*'),
          message: 'Bredde skal indtastes som et heltal'
        }
        ]
      },
      {
        type: 'input',
        inputType: 'number',
        name: OptionProperty.depth,
        label: 'Dybde',
        min: 260,
        max: 550,
        suffix: 'mm',
        placeholder: 'Organiser.placeholder.Depth',
        disabled: false,
        show: true,
        validations: [{
          name: 'required',
          validator: Validators.required,
          message: 'Organiser.required.Depth'
        }, {
          name: 'pattern',
          validator: Validators.pattern('\\d*'),
          message: 'Dybde skal indtastes som et heltal'
        }],
        translate: { helptext: false }
      }]
  },
  woodTypeAndSurfaceRow,
  createHrRow()
];
