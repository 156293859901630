import { Component, OnInit } from '@angular/core';

import { IPurewoodDrawer } from '../../../../../wdcommon/IDrawer';
import { defaultBreadcrumbs } from '../../utils/breadcrumbs/breadcrumbs';
import { APIService, UserService } from '../../services';
import { Router } from '@angular/router';


enum PurewoodSubcategoryType {
  drawer = 'drawer',
  placeholder = 'placeholder'
}

interface PurewoodSubcategory {
  enabled: boolean;
  name?: string;
  description?: string;
  img?: string;
  type: PurewoodSubcategoryType;
  drawer?: IPurewoodDrawer;
}


@Component({
  selector: 'app-purewood-drawers',
  templateUrl: './purewood-drawers.component.html',
  styleUrls: ['./purewood-drawers.component.css']
})
export class PurewoodDrawersComponent implements OnInit {
  drawersBreadcrumb = defaultBreadcrumbs['purewoodDrawers'];

  subcategoryRows: PurewoodSubcategory[][];
  rowSize = 4;

  constructor(
      private api: APIService,
      private router: Router,
      private userService: UserService,
  ) {
  }

  async ngOnInit() {
    let purewoodDrawers: IPurewoodDrawer[] = [];
    if (this.userService.isAllowedPurewood()) {
      purewoodDrawers = await this.api.getPurewoodDrawers();
    }
    // Line drawers up in rows
    this.subcategoryRows = [];
    const totalCells = purewoodDrawers.length;
    const rows = Math.ceil(totalCells / this.rowSize);
    for (let row = 0; row < rows; row++) {
      this.subcategoryRows.push([]);
    }

    // Drawers
    purewoodDrawers.forEach((drawer, i) => {
      const index = Math.floor(i / this.rowSize);
      this.subcategoryRows[index].push({
        enabled: drawer.enabled,
        type: PurewoodSubcategoryType.drawer,
        img: '/assets/images/drawers/purewood/' + drawer.shortname + '.png',
        name: 'DRAWER_NAMES.purewood-' + drawer.shortname,
        description: 'DRAWER_DESC.purewood-' + drawer.shortname,
        drawer: drawer
      });
    });

    // Top up with empty drawer-objects
    const remainder = this.rowSize * rows - totalCells;
    for (let extra = 0; extra < remainder; extra++) {
      this.subcategoryRows[rows - 1].push({
        type: PurewoodSubcategoryType.placeholder,
        enabled: false
      });
    }
  }

  async gotoSubcategory(subcategory: PurewoodSubcategory) {
    if (!subcategory.enabled) {
      return;
    }
    await this.goto(this.drawersBreadcrumb.url + '/purewood-' + subcategory.drawer.shortname);
  }

  async goto(url: string) {
    await this.router.navigateByUrl(url);
  }

}
