<div class="container">
    <div class="row mb-3">

        <app-breadcrumbs [currentBreadcrumb]="myOrdersBreadcrumb"></app-breadcrumbs>
        <div class="col-sm-5 mt-auto me-3 mb-1 ms-auto">
            <input (keyup)="searchFilter()" [(ngModel)]="searchNeedle" [placeholder]="translate('UTILITY.SEARCH')" aria-label="Search"
                   class="form-control" type="search">
        </div>

    </div>
    <div class="row">

        <table class="table table-striped table-hover orderTable">
            <thead>
            <tr>
                <th translate="ORDERLIST.Ordre ID"></th>
                <th translate="ORDERLIST.Ordre Dato"></th>
                <th translate="ORDERLIST.Leveringsdato"></th>
                <th translate="CART.Varer i alt"></th>
                <th translate="ORDERLIST.Requisition"></th>
                <th translate="CART.total_ex_vat"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let order of displayOrders | paginate: { itemsPerPage: 10, currentPage: currentOrderPage }"
                [routerLink]="'/profile/my-orders/order-details/' + order.orderID" class="clickable">
                <td>{{ order.orderID }}</td>
                <td>{{ order.orderDate | date }}</td>
                <td>{{ order.deliveryDate | date }}</td>
                <td>{{ countItems(order.contents) }}</td>
                <td>{{ order.rekvisition }}</td>
                <td class="text-end">{{ order.exVat | number: '1.2-2' }} {{'USED_CURRENCY' | translate }}</td>
            </tr>
            </tbody>
        </table>

    </div>
    <div class="row">

        <div class="col-sm text-end">
            <pagination-controls (pageChange)="currentOrderPage = $event" [nextLabel]="translate('UTILITY.NEXT')"
                                 [previousLabel]="translate('UTILITY.PREVIOUS')" class="order-pagination"></pagination-controls>
        </div>

    </div>
</div>
